import { Tooltip } from '@mui/material';
import React from 'react';
import { differenceInDays, format, isBefore, parse } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import ErrorIcon from '@mui/icons-material/ErrorOutline';

interface OwnProps {
    checkupDate: string;
}

export default function CheckupDateCell({ checkupDate }: OwnProps) {
    const timeZone = 'America/New_York';

    const parsedDate = parse(checkupDate, 'MM/dd/yyyy', new Date());
    const zonedDate = utcToZonedTime(parsedDate, timeZone);

    if (Number.isNaN(zonedDate.getTime())) {
        return <>{checkupDate}</>;
    }

    const now = utcToZonedTime(new Date(), timeZone);
    const isPast = isBefore(zonedDate, now);
    const daysDifference = differenceInDays(zonedDate, now);

    let displayText: string;
    if (daysDifference === 0) {
        displayText = 'today';
    } else if (daysDifference === 1) {
        displayText = 'tomorrow';
    } else if (daysDifference > 1) {
        displayText = `in ${daysDifference} days`;
    } else {
        displayText = format(zonedDate, 'MM/dd/yyyy');
    }

    return (
        <>
            {displayText}
            {isPast && (
                <Tooltip title="Checkup date is in the past">
                    <ErrorIcon
                        style={{
                            color: 'red',
                            marginLeft: 4,
                            fontSize: 'small',
                        }}
                    />
                </Tooltip>
            )}
        </>
    );
}
