import React from 'react';
import {RouterProvider} from 'react-router-dom';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { TssCacheProvider } from 'tss-react';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { Provider } from 'react-redux';

import theme from './styles/theme';
import { store } from './api/store';

import { RouterConfig } from './router/RouterConfig';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './styles/styles.css';

export default function App() {
    const muiCache = createCache({
        key: 'mui',
        prepend: true,
    });

    const tssCache = createCache({
        key: 'tss',
    });
    return (
        <Provider store={store}>
            <CacheProvider value={muiCache}>
                <TssCacheProvider value={tssCache}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <ThemeProvider theme={theme}>
                            <CssBaseline />
                            <RouterProvider router={RouterConfig} />
                        </ThemeProvider>
                    </LocalizationProvider>
                </TssCacheProvider>
            </CacheProvider>
            <ToastContainer />
        </Provider>
    );
}
