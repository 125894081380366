import React from 'react';
import { Box, Grid } from '@mui/material';
import GrowerInfoBox from './GrowerInfoBox';
import { useGetSharedGrowerTmpQuery } from '../../../../api/sharedData/sharedDataTempAccessAPI';
import Loader from '../../../../design/BaseLoader';
import UpcomingEventsPreview from '../Events/UpcomingEventsPreview';
import GrowerInviteForm from './GrowerInviteForm';

interface OwnProps {
    token: string;
    hideSwitchGrower?: boolean;
}

export default function AsideGrowerTab({ token, hideSwitchGrower }: OwnProps) {
    const { data: growerData, isLoading: isGrowerDataLoading } =
        useGetSharedGrowerTmpQuery({
            token,
        });

    if (isGrowerDataLoading) {
        return (
            <Grid
                container
                alignItems={'center'}
                justifyContent={'center'}
                flexDirection={'column'}>
                <Loader />
            </Grid>
        );
    }

    return (
        <Box sx={{ paddingTop: '20px', padding: '5px' }}>
            <GrowerInfoBox
                data={growerData}
                hideSwitchGrower={hideSwitchGrower}
            />
            <UpcomingEventsPreview />
            <GrowerInviteForm token={token} />
        </Box>
    );
}
