import { FormConfig } from '../../design/Forms/interfaces';
import {
    composeValidators,
    isDateGreaterThanToday,
    maxLength,
    minLength,
    required,
} from '../../utils/validation';
import { DecodedToken, priorityOptions, userListConvertor } from './utils';

import { useLazyGetUsersOfOrgQuery } from '../../api/users/organizationsAPI';
import { getTokenData } from '../../utils/jwtUtils';
import { useEffect } from 'react';
import FormPage from '../../reusable/FormUI/FormPage';
import { Box, Grid } from '@mui/material';
import Loader from '../../design/BaseLoader';
import RelatesToForm from './RelatesToForm';
import WatchersAndDocumentsUploadForm from './WatchersAndDocumentsUploadForm';

export interface UserList {
    id: string;
    firstName: string;
    lastName: string;
}

export default function TaskFormCreate() {
    const decoded = getTokenData() as DecodedToken;
    const organizationId = decoded ? decoded?.organizationId : null;
    const [getItems, { data: userList, isLoading }] =
        useLazyGetUsersOfOrgQuery();

    useEffect(() => {
        if (organizationId) {
            getItems({
                organizationId: organizationId,
                sort: `firstName,desc`,
            });
        }
    }, [organizationId]);

    if (isLoading) {
        return (
            <Grid
                container
                sx={{ height: '100%' }}
                alignItems={'center'}
                justifyContent={'center'}
                flexDirection={'column'}>
                <Loader />
            </Grid>
        );
    }

    return (
        <Box
            display={'flex'}
            flexDirection={'column'}
            rowGap={1}
            width={'100%'}>
            <FormPage formConfig={formTaskConfig(userList)} />
            <RelatesToForm />
            <WatchersAndDocumentsUploadForm userList={userList} />
        </Box>
    );
}

function formTaskConfig(userList: UserList[]): FormConfig[] {
    return [
        {
            formField: {
                scheme: 'half',
                name: 'name',
                type: 'input',
                label: 'Task Name',
                isRequired: true,
                validation: composeValidators(required, maxLength(256)),
            },
            renderProps: {
                placeholder: '',
                isDisabled: false,
                size: 'small',
            },
        },
        {
            formField: {
                scheme: 'half',
                name: 'dueDate',
                type: 'datepicker',
                label: 'Due Date',
                validation: composeValidators(
                    isDateGreaterThanToday(
                        'Date must be equal or greater than today',
                    ),
                ),
            },
            renderProps: {
                placeholder: '',
                isDisabled: false,
                size: 'small',
                format: 'MMM dd, yyyy',
            },
        },
        {
            formField: {
                scheme: 'half',
                name: 'assignTo',
                type: 'select',
                label: 'Assign To',
            },
            renderProps: {
                placeholder: 'Select from list',
                isDisabled: false,
                size: 'small',
                options: userListConvertor(userList),
            },
        },
        {
            formField: {
                scheme: 'half',
                name: 'priority',
                type: 'select',
                label: 'Priority',
            },
            renderProps: {
                placeholder: 'Select from list',
                isDisabled: false,
                size: 'small',
                options: priorityOptions,
            },
        },
        {
            formField: {
                name: 'description',
                type: 'textarea',
                label: 'Description',
                isRequired: true,
                validation: composeValidators(
                    required,
                    minLength(1),
                    maxLength(1024),
                ),
            },
            renderProps: {
                placeholder: '',
                resize: 'none',
                isDisabled: false,
            },
        },
    ];
}
