import { REDUX_CACHE } from './tags';

export const getListProvidesTags = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any[] | undefined,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    tag: any,
    deepFieldName = '',
) => {
    return data
        ? [
              ...data.map((item) => ({
                  type: tag,
                  id: deepFieldName
                      ? (item[deepFieldName] as { id: string }).id
                      : (item.id as string),
              })),
              { type: tag, id: REDUX_CACHE.LIST },
          ]
        : [{ type: tag, id: REDUX_CACHE.LIST }];
};
