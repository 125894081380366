import React from 'react';
import { Button } from '@mui/material';
import { ReactComponent as LinkIcon } from '../../../assets/icons/link_icon.svg';
import { NavLink } from 'react-router-dom';
import PATHS from '../../../router/paths';
import { makeStyles } from 'tss-react/mui';

interface OwnProps {
    landPartnerId: string;
    visibilityLevel: string;
    landPartnerName: string;
    onClick: (
        event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>,
    ) => void;
}

export default function SharedDataPreviewButton({
    landPartnerId,
    visibilityLevel,
    landPartnerName,
    onClick,
}: OwnProps) {
    const { classes } = useStyles();

    const previewLink = `/${PATHS.sharedData.root}/${PATHS.sharedData.preview}`;
    const stateParams = new URLSearchParams({
        landPartnerName,
        landPartnerId,
        visibilityLevel,
    }).toString();

    return (
        <NavLink
            to={`${previewLink}?${stateParams}`}
            target="_blank"
            className={classes.navLink}
            onClick={onClick}>
            <Button variant="text" color="primary" startIcon={<LinkIcon />}>
                <span className={classes.buttonText}>Preview</span>
            </Button>
        </NavLink>
    );
}

const useStyles = makeStyles()(() => ({
    navLink: {
        textDecoration: 'none',
    },
    buttonText: {
        color: 'inherit',
        textDecoration: 'none',
    },
}));
