import { FormConfig } from '../../../../design/Forms/interfaces';
import {
    composeValidators,
    maxLength,
    required,
} from '../../../../utils/validation';
import { Organization } from '../../Organizations/AllOrganizations/OrganizationsList';

export const formConfig: (organizations: Organization[]) => FormConfig[] = (
    organizations: Organization[],
) => {
    return [
        {
            formField: {
                scheme: 'half',
                name: 'name',
                type: 'input',
                label: 'Role Name',
                isRequired: true,
                validation: composeValidators(required, maxLength(256)),
            },
            renderProps: {
                placeholder: '',
                isDisabled: false,
                size: 'small',
            },
        },
        {
            formField: {
                scheme: 'half',
                name: 'organizationId',
                type: 'select',
                label: 'Organization',
                isRequired: true,
                validation: composeValidators(required),
            },
            renderProps: {
                placeholder: 'Organization',
                isDisabled: false,
                size: 'small',
                options: organizations,
            },
        },
        {
            formField: {
                scheme: 'full',
                name: 'description',
                type: 'textarea',
                label: 'Description',
                isRequired: true,
                validation: composeValidators(required, maxLength(1024)),
            },
            renderProps: {
                resize: 'none',
                isDisabled: false,
            },
        },
    ];
};
