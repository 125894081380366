import SearchAutocompleteField from '../../design/Fields/SearchAutocompleteField';
import { Box } from '@mui/material';
import AppButton from '../../design/Buttons/AppButton';
import AppTypography from '../../design/AppTypography';

export default function SearchContactField() {
    return (
        <Box display={'flex'} columnGap={2}>
            <Box display={'flex'} flexDirection={'column'} rowGap={2}>
                <AppTypography
                    variant="font12"
                    fontWeight={'medium'}
                    text={'Search Contacts'}
                    color={'text.neutral7'}
                />
                <Box minWidth={380}>
                    {/* eslint-disable-next-line @typescript-eslint/no-empty-function */}
                    <SearchAutocompleteField onChange={() => {}} />
                </Box>
            </Box>

            <Box display={'flex'} flexDirection={'column'} rowGap={2}>
                <AppTypography
                    variant="font12"
                    fontWeight={'medium'}
                    text={'Or create a new contact'}
                    color={'text.neutral7'}
                />
                <AppButton
                    variant={'outlined'}
                    onClick={
                        /* eslint-disable-next-line @typescript-eslint/no-empty-function */
                        () => {}
                    }
                    title={'Create Contact'}
                />
            </Box>
        </Box>
    );
}
