import { Button, ButtonGroup, Grid } from '@mui/material';
import { DocumentTypeContext } from './DocumentTypeContext';
import React, { useContext } from 'react';
import { useGetCurrentOrganizationConfigQuery } from '../../../api/users/organizationsAPI';
import Loader from '../../../design/BaseLoader';
import { DocumentCategory } from '../../ViewDashboard/RecentUploads';

interface FilterButton {
    name: DocumentCategory;
    label: string;
    keepOpen?: boolean;
}

export default function FilterList() {
    const { type, setType } = useContext(DocumentTypeContext);

    const { data, isLoading, isFetching } =
        useGetCurrentOrganizationConfigQuery();

    if (isLoading || isFetching) {
        return (
            <Grid
                width={'100%'}
                container
                alignItems={'center'}
                justifyContent={'center'}>
                <Loader />
            </Grid>
        );
    }

    const allowedCategories = data?.documentCategories || [];

    const filteredCategories = ButtonConfig.filter((button) => {
        return button.keepOpen || allowedCategories.includes(button.name);
    });

    return (
        <ButtonGroup>
            {filteredCategories.map((button) => (
                <Button
                    key={button.name}
                    onClick={() => setType(button.name)}
                    sx={{
                        textDecoration:
                            type === button.name ? 'underline' : 'none',
                    }}
                    variant={'text'}>
                    {button.label}
                </Button>
            ))}
        </ButtonGroup>
    );
}

const ButtonConfig: FilterButton[] = [
    {
        name: '',
        label: 'All',
        keepOpen: true,
    },
    {
        name: 'LEASE',
        label: 'Lease',
    },
    {
        name: 'FINANCIAL',
        label: 'Financial',
    },
    {
        name: 'FILINGS',
        label: 'Filings',
    },
    {
        name: 'TASK',
        label: 'Task',
    },
    {
        name: 'REPORTS',
        label: 'Reports',
    },
    {
        name: 'LAND',
        label: 'Land',
    },
    {
        name: 'OTHER',
        label: 'Other',
    },
];
