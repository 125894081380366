import FormScreenWrapper from './FormScreenWrapper';
import { Box } from '@mui/material';
import AppTypography from '../../design/AppTypography';
import FormPage from '../../reusable/FormUI/FormPage';
import { FormConfig } from '../../design/Forms/interfaces';

export default function ContinuityOfOperationsForm() {
    return (
        <FormScreenWrapper title="Continuity of Operations">
            <Box display={'flex'} flexDirection={'column'} rowGap={2}>
                <AppTypography
                    variant="font16"
                    fontWeight={'medium'}
                    text={
                        'What are the alternative markets or distribution channels if the primary markets are disrupted?'
                    }
                    color={'text.neutral9'}
                />

                <FormPage formConfig={alternativeMarketsFormConfig} />
            </Box>
        </FormScreenWrapper>
    );
}
const alternativeMarketsFormConfig: FormConfig[] = [
    {
        formField: {
            scheme: 'full',
            name: 'continuityOperations.alternativeMarketsPlan',
            type: 'textarea',
            label: '',
        },
        renderProps: {
            resize: 'none',
            isDisabled: false,
            minRows: 3,
        },
    },
];
