import { Box } from '@mui/material';
import React, { useState } from 'react';
import ActionButton, {
    ActionMenuItem,
} from '../../../design/Buttons/ActionButton';
import { useDownloadByIdsAsZipMutation } from '../../../api/documents/documentsAPI';
import { createDownloadLinkFromBlob } from '../../../utils/fileHandler';
import customToastWithAlert from '../../../design/CustomToastWithAlert';
import FilterList from './FilterList';
import RequestDocumentModal from './RequestDocumentModal';
import RequestESignatureModal from './RequestESignatureModal';

interface OwnProps {
    selectedDocuments: Record<string, string[]>;
}

export default function DocumentsViewActions({ selectedDocuments }: OwnProps) {
    const [isShowing, setIsShowing] = useState(false);
    const [isESignModalShowing, setIsESignModalShowing] = useState(false);
    const arrayOfDocuments = extractUniqueIdFromDocuments(selectedDocuments);
    const countOfDocuments = arrayOfDocuments.length;
    const [downloadDocumentsAsZip] = useDownloadByIdsAsZipMutation();

    function handleRequestClick() {
        setIsShowing(true);
    }
    function handleClose() {
        setIsShowing(false);
    }
    function handleRequestESignClick() {
        if (selectedDocuments?.documents?.length === 1) {
            setIsESignModalShowing(true);
        } else
            customToastWithAlert({
                type: 'error',
                message:
                    'Please select only one document to request electronic signature',
            });
    }
    function handleCloseESign() {
        setIsESignModalShowing(false);
    }

    const handleDownloadZip = () => {
        if (countOfDocuments === 0) {
            return customToastWithAlert({
                type: 'error',
                message: 'Please select the files to download',
            });
        }

        downloadDocumentsAsZip({
            documentIds: arrayOfDocuments,
        })
            .unwrap()
            .then((blob) => {
                createDownloadLinkFromBlob(blob, 'documents.zip');
            })
            .catch((error) => {
                return customToastWithAlert({
                    type: 'error',
                    message: error?.data?.description || 'Something went wrong',
                });
            });
    };

    const actionButtonItems: ActionMenuItem[] = [
        {
            label:
                countOfDocuments === 0
                    ? 'Download selected files as ZIP'
                    : `Download ${countOfDocuments} files as ZIP `,
            onClick: handleDownloadZip,
        },
        {
            label: 'Request a Document',
            onClick: handleRequestClick,
        },
        {
            label: 'Request Electronic Signature',
            color:
                selectedDocuments?.documents?.length !== 1
                    ? '#abadaf'
                    : undefined,
            onClick: handleRequestESignClick,
        },
    ];

    return (
        <Box
            display={'flex'}
            justifyContent={'space-between'}
            width={'100%'}
            alignItems={'center'}>
            <FilterList />
            <ActionButton label={'Actions'} items={actionButtonItems} />
            {isShowing && (
                <RequestDocumentModal
                    isShowing={isShowing}
                    handleClose={handleClose}
                />
            )}{' '}
            {isESignModalShowing && (
                <RequestESignatureModal
                    documentId={selectedDocuments?.documents?.[0]}
                    isShowing={isESignModalShowing}
                    handleClose={handleCloseESign}
                />
            )}
        </Box>
    );
}

function extractUniqueIdFromDocuments(data: Record<string, string[]>) {
    const filteredData = filterEmptyArrFromObj(data);
    return Object.keys(filteredData).flatMap((key) => filteredData[key]);
}

function filterEmptyArrFromObj(data: Record<string, string[]>) {
    return Object.keys(data).reduce(
        (acc, key) => {
            if (data[key].length) {
                acc[key] = data[key];
            }
            return acc;
        },
        {} as Record<string, string[]>,
    );
}
