import React from 'react';
import { Box, Typography } from '@mui/material';
import { LeaseType } from '../../../../features/CreateLeaseForm/LeaseWizard';
import GenericTable, {
    Table,
} from '../../../../design/GenericTable/GenericTable';
import BasedTooltip from '../../../../design/BasedTooltip';
import InfoTooltip from '../../../../design/Tooltip/InfoTooltip';

export interface SharedLease {
    id: string;
    type: LeaseType;
    leaseName: string;
    totalAcres: number;
    tillableAcres: number;
    endDate: string;
    fsaIds: { id: string; fsaId: string; landName: string }[];
}

export default function SharedLeasesShortTable({
    data,
}: {
    data: SharedLease[];
}) {
    const leasePreviewConfig: Table<SharedLease> = {
        columns: [
            {
                header: {
                    customizeKey: 'leaseName',
                    label: 'Lease Name',
                    sortable: false,
                },
                cellRender: (lease: SharedLease) => <>{lease?.leaseName}</>,
                maxColumnWidth: 200,
                format: { align: 'left', color: { color: 'primary' } },
                width: 'auto',
            },
            {
                header: {
                    customizeKey: 'totalAcres',
                    label: 'Total Acres',
                    sortable: false,
                },
                cellRender: (lease: SharedLease) => (
                    <BasedTooltip>{lease?.totalAcres}</BasedTooltip>
                ),
                format: { align: 'left', color: { color: 'primary' } },
                width: 'auto',
            },
            {
                header: {
                    customizeKey: 'tillableAcres',
                    label: 'Tillable Acres',
                    sortable: false,
                },
                cellRender: (lease: SharedLease) => <>{lease?.tillableAcres}</>,
                format: { align: 'left', color: { color: 'primary' } },
                width: 'auto',
            },
            {
                header: {
                    customizeKey: 'type',
                    label: 'Lease Type',
                    sortable: false,
                },
                cellRender: (lease: SharedLease) => <>{lease?.type}</>,
                format: { align: 'left', color: { color: 'primary' } },
                width: 'auto',
            },
            {
                header: {
                    customizeKey: 'endDate',
                    label: 'Lease Expiration',
                    sortable: false,
                },
                cellRender: (lease: SharedLease) => <>{lease?.endDate}</>,
                format: { align: 'left', color: { color: 'primary' } },
                width: 'auto',
            },
            {
                header: {
                    customizeKey: 'fsaIds',
                    label: 'FSA ID',
                    sortable: false,
                },
                cellRender: (lease: SharedLease) => <>{showFsaIdName(lease)}</>,
                maxColumnWidth: 200,
                format: { align: 'left', color: { color: 'primary' } },
                width: 'auto',
            },
        ],
        multiselect: false,
        expandable: false,
        pageable: false,
    };
    return <GenericTable data={data} tableConfig={leasePreviewConfig} />;
}

export const showFsaIdName = (lease: SharedLease) => {
    if (lease && lease.fsaIds && lease?.fsaIds.length > 0) {
        return (
            <BasedTooltip text={lease?.fsaIds.map((i) => i?.fsaId).join(' ,')}>
                <Box display={'flex'} gap={1}>
                    <Typography
                        sx={{ textAlign: 'center' }}
                        variant={'font14'}
                        color={'text.link'}
                        fontWeight={'medium'}>
                        {lease.fsaIds[0]?.fsaId}
                    </Typography>
                    <span>
                        {lease?.fsaIds.length > 1 &&
                            `+${lease?.fsaIds.length - 1}`}
                    </span>
                    {lease?.fsaIds.length > 1 && (
                        <InfoTooltip
                            text={lease?.fsaIds.map((i) => i?.fsaId).join(' ,')}
                            position={'top'}
                        />
                    )}
                </Box>
            </BasedTooltip>
        );
    }
    return lease && lease.fsaIds && lease.fsaIds[0]?.fsaId;
};
