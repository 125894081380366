import React, { useMemo } from 'react';
import DocumentsList from '../../../../reusable/DocumentsList/DocumentsList';
import { Grid } from '@mui/material';
import Loader from '../../../../design/BaseLoader';
import { useGetSharedDocumentsQuery } from '../../../../api/sharedData/sharedDataPermanentAccessAPI';

export default function SharedDocumentsPage() {
    const { data: documents, isLoading: isSharedDocumentsLoading } =
        useGetSharedDocumentsQuery({
            page: 0,
            size: 100,
        });

    const data = useMemo(() => {
        return documents?.data || [];
    }, [documents]);

    if (isSharedDocumentsLoading) {
        return (
            <Grid
                container
                alignItems={'center'}
                justifyContent={'center'}
                flexDirection={'column'}>
                <Loader />
            </Grid>
        );
    }

    return <DocumentsList cards={data || []} />;
}
