import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import useStyles from './LandPartners.styles';
import theme from '../../styles/theme';
import PATHS from '../../router/paths';

export default function LandPartners() {
    const { classes } = useStyles(theme);
    const { pathname } = useLocation();

    if (pathname === `/${PATHS.landpartners}`)
        return <Navigate to={`/${PATHS.landpartners}/${PATHS.profiles}`} />;

    return (
        <div className={classes.landPartnersWrapper}>
            <Outlet />
        </div>
    );
}
