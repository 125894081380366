import React from 'react';
import { Box, Typography } from '@mui/material';

import { makeStyles } from 'tss-react/mui';
import theme from '../../styles/theme';
import { dateTimeConverterFromUTC } from '../../utils/dateFormat';
import StatusTag, { Tag } from '../../design/StatusTag/StatusTag';
import PATHS from '../../router/paths';
import { useNavigate } from 'react-router-dom';
import SearchCardBreadcrumbs from './SearchCardBreadcrumbs';
import { useDownloadDocuments } from '../../hooks/useDownloadDocuments';
import PreviewModal from '../../reusable/Modal/PreviewModal';
import BasedTooltip from '../../design/BasedTooltip';

export type SearchType =
    | 'ALL'
    | 'LEASE'
    | 'NOTE'
    | 'PAYMENT'
    | 'TASK'
    | 'PROFILE'
    | 'LEASE_NOTE'
    | 'LAND_PARTNER'
    | 'GROWER'
    | 'DOCUMENT';

export interface SearchResult {
    entityId: string;
    name: string;
    type: SearchType;
    updatedAt: number;
    noteOwner?: string;
}

export default function SearchCard({
    entityId,
    name,
    type,
    updatedAt,
    noteOwner,
}: SearchResult) {
    const { classes } = useStyles();
    const navigate = useNavigate();
    const {
        isShowing,
        file,
        isLoading: isDocumentLoading,
        handleClose,
        extension,
        handlePreviewOpen,
    } = useDownloadDocuments();

    return (
        <Box
            className={classes.root}
            onClick={() =>
                navigate(linkMapperByType({ type, entityId, noteOwner }))
            }>
            <StatusTag {...statusMap[type]} />

            <Box
                display={'flex'}
                flexDirection={'column'}
                gap={1}
                justifyContent={'center'}
                width={'100%'}>
                <Box
                    display={'flex'}
                    flexDirection={'column'}
                    gap={1}
                    justifyContent={'center'}
                    width={'100%'}
                    onClick={() =>
                        type === 'DOCUMENT' && handlePreviewOpen(entityId)
                    }>
                    <Typography
                        sx={{
                            cursor: type === 'DOCUMENT' ? 'pointer' : 'default',
                            textDecoration:
                                type === 'DOCUMENT' ? 'underline' : 'none',
                        }}
                        noWrap
                        variant="font14"
                        color="text.link"
                        fontWeight="medium">
                        <BasedTooltip>{name}</BasedTooltip>
                    </Typography>
                </Box>
                <Box
                    display={'flex'}
                    gap={2}
                    width={'100%'}
                    alignItems={'center'}>
                    <SearchCardBreadcrumbs
                        type={type}
                        entityId={entityId}
                        name={name}
                        noteOwner={noteOwner}
                    />
                </Box>
                <Typography variant="font12" color="text.neutral6">
                    Last Update {dateTimeConverterFromUTC(updatedAt)}
                </Typography>
            </Box>

            {extension(name) && (
                <PreviewModal
                    extension={extension(name) as string}
                    fileName={name}
                    file={file}
                    isLoading={isDocumentLoading}
                    isShowing={isShowing}
                    onClose={handleClose}
                />
            )}
        </Box>
    );
}

const useStyles = makeStyles()(() => ({
    root: {
        width: '100%',
        display: 'flex',
        columnGap: theme.spacing(2),
        padding: theme.spacing(2),
        backgroundColor: theme.palette.backgroundB.backgroundB2,
        '&:hover': {
            backgroundColor: theme.palette.lightTurq.lightTurqTint2,
        },
    },
}));

function linkMapperByType({
    type,
    entityId,
    noteOwner,
}: {
    type: SearchType;
    entityId: string;
    noteOwner?: string;
}) {
    const typeToPathMap: Record<SearchType, string> = {
        ALL: '/',
        LEASE: `/${PATHS.landPortfolio}/${PATHS.manage}/${entityId}`,
        PROFILE: `/${PATHS.landpartners}/${PATHS.allProfiles}/${entityId}`,
        LAND_PARTNER: `/${PATHS.landpartners}/${PATHS.profiles}/${entityId}`,
        GROWER: `/${PATHS.landpartners}/${PATHS.growers}/${entityId}`,
        NOTE: `/${PATHS.landpartners}/${PATHS.allProfiles}/${noteOwner}/notes`,
        PAYMENT: `/`,
        TASK: `/${PATHS.tasks}`,
        LEASE_NOTE: `/${PATHS.landPortfolio}/${PATHS.manage}/${noteOwner}`,
        DOCUMENT: '/',
    };

    return typeToPathMap[type] || '/';
}

const statusMap: Record<SearchType, Tag> = {
    ALL: {
        status: 'primaryInfo',
        text: 'All',
    },
    PAYMENT: {
        status: 'success',
        text: 'Payment',
    },

    NOTE: {
        status: 'warning',
        text: 'Notes',
    },
    LEASE: {
        status: 'primaryInfo',
        text: 'Lease',
    },

    TASK: {
        status: 'disabled',
        text: 'Task',
    },
    PROFILE: {
        status: 'info',
        text: 'Profile',
    },
    LAND_PARTNER: {
        status: 'info',
        text: 'Land Partner',
    },
    LEASE_NOTE: {
        status: 'warning',
        text: 'Lease Notes',
    },
    GROWER: {
        status: 'info',
        text: 'Grower',
    },
    DOCUMENT: {
        status: 'info',
        text: 'Document',
    },
};
