import BaseModal from '../../../reusable/BaseModal';
import { convertBytesToMbOrKb } from '../../../utils/dataConvertor';
import { Box } from '@mui/material';
import theme from '../../../styles/theme';

import React from 'react';
import TextWithTitle from '../../../reusable/TextWithTitle';
import { useParams } from 'react-router-dom';
import useHandleSubmitRTK from '../../../hooks/useHandleSubmit';
import { useDocumentUploadMutation } from '../../../api/documents/documentsAPI';
import UploadOneDocumentForm from './UploadOneDocumentForm';
import { DocumentRequest } from './UploadDocument';
import BasedTooltip from '../../../design/BasedTooltip';

interface OwnProps {
    formReset: () => void;
    isShowing: boolean;
    handleClose: () => void;
    file?: File;
}

export default function UploadOneDocumentModal({
    formReset,
    isShowing,
    handleClose,
    file,
}: OwnProps) {
    const { id } = useParams();
    const { handleSubmit: handleUpload, isLoading: isDocumentUploading } =
        useHandleSubmitRTK({
            useRtkHook: useDocumentUploadMutation,
            successMessage: 'File uploaded successfully',
            onSuccess: () => {
                handleClose();
                formReset();
            },
        });
    const fileName = file?.name;
    const fileSizes = file?.size && convertBytesToMbOrKb(+file?.size);

    function handleSubmit(values: DocumentRequest) {
        handleUpload({
            documents: file,
            parentId: id,
            documentPurpose: values.documentPurpose,
            documentType: 'LAND_PARTNER',
            documentCategory: values.documentCategory,
            sharingAllowed: values.sharingAllowed,
        });
    }

    return (
        <BaseModal
            size={'xSmall'}
            isShowing={isShowing}
            onClose={handleClose}
            header={'Document Uploads'}>
            <Box display={'flex'} flexDirection={'column'} width={'100%'}>
                <Box
                    p={2}
                    display={'flex'}
                    columnGap={2}
                    justifyContent={'space-between'}
                    bgcolor={theme.palette.backgroundB.backgroundB1}>
                    <BasedTooltip text={fileName}>
                        <TextWithTitle title={'File Name'} value={fileName} />
                    </BasedTooltip>
                    <TextWithTitle title={'File Size'} value={fileSizes} />
                </Box>
                <UploadOneDocumentForm
                    initialValues={{
                        sharingAllowed: true,
                    }}
                    handleSubmit={handleSubmit}
                    isLoading={isDocumentUploading}
                />
            </Box>
        </BaseModal>
    );
}
