import React from 'react';

import { Avatar } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import clsx from 'clsx';

export interface AvatarFormat {
    name: string;
    avatar?: string;
    size?: AvatarSize;
}
export type AvatarSize = 'small' | 'preMedium' | 'medium' | 'large' | 'xlarge';
export default function UserAvatar({
    avatar,
    name,
    size = 'medium',
}: AvatarFormat) {
    const { classes } = useStyles();
    return (
        <Avatar
            className={clsx(
                size === 'small' && classes.smallAvatar,
                size === 'preMedium' && classes.preMediumAvatar,
                size === 'medium' && classes.mediumAvatar,
                size === 'large' && classes.largeAvatar,
                size === 'xlarge' && classes.xlargeAvatar,
            )}
            src={avatar}>
            {getInitials(name)}
        </Avatar>
    );
}

const useStyles = makeStyles()(() => ({
    smallAvatar: { width: 24, height: 24, fontSize: 12 },
    preMediumAvatar: { width: 32, height: 32 },
    mediumAvatar: { width: 40, height: 40 },
    largeAvatar: { width: 60, height: 60 },
    xlargeAvatar: { width: 90, height: 90 },
}));

function getInitials(name: string): string {
    const words = name.split(/\s+/).filter((word) => word !== '');
    const initials = words.map((word) => word[0].toUpperCase());
    return initials.join('');
}
