import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material/styles/createTheme';

const useStyles = makeStyles<Theme>()((theme) => ({
    widgetGreenBackground: {
        backgroundColor: theme.palette.limeGreen.limeGreenShade3,
    },
    widgetTurqBackground: {
        backgroundColor: theme.palette.lightTurq.lightTurqTint9,
    },
    widgetDarkTurqBackground: {
        backgroundColor: theme.palette.darkTurq.darkTurqTint6,
    },
    widgetDarkNeutralBackground: {
        backgroundColor: theme.palette.neutral.neutral7,
    },

    settingsWidgetWrapper: {
        display: 'flex',
        gap: '12px',
        width: 280,
        padding: '16px',
        borderRadius: '8px',
        height: '100%',
        minHeight: '140px',
    },
    settingsWidgetContent: {
        display: 'flex',
        flexDirection: 'column',
        gap: '4px',
    },
    settingsWidgetTitle: {
        fontSize: '20px',
        fontWeight: 500,
        lineHeight: 1.4,
        color: theme.palette.limeGreen.limeGreenTint3,
    },
    settingsWidgetInfo: {
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: 1.43,
        color: theme.palette.neutralWhite,
        flex: 1,
    },
    settingsWidgetLink: {
        paddingTop: theme.spacing(1.5),
        textDecoration: 'unset',
        fontSize: '11px',
        fontWeight: 600,
        lineHeight: 1.27,
        color: theme.palette.neutralWhite,
        cursor: 'pointer',
    },
}));

export default useStyles;
