import { createContext, Dispatch, SetStateAction } from 'react';

export type DocumentsType =
    | 'JOB_DESCRIPTION'
    | 'INSURANCE_POLICIES'
    | 'FARM_VISION'
    | 'DISASTER_PLANNING';
export const DocumentTypeContext = createContext<{
    type: DocumentsType;
    setType: Dispatch<SetStateAction<DocumentsType>>;
}>({
    type: 'JOB_DESCRIPTION',
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setType: () => {},
});
