import React from 'react';
import PostComment, { Comment } from './PostComment';
import { Box } from '@mui/material';
import CollapsedComments from './CollapsedComments';

interface OwnProps {
    comments: Comment[];
}
export default function PostCommentsList({ comments }: OwnProps) {
    return (
        <Box display={'flex'} flexDirection={'column'} rowGap={1}>
            <CollapsedComments
                numberOfComments={comments.length}
                isExpanded={false}>
                <Box>
                    {comments.map((comment, index) => (
                        <PostComment key={index} {...comment} />
                    ))}
                </Box>
            </CollapsedComments>
        </Box>
    );
}
