import React from 'react';
import { Box, Skeleton } from '@mui/material';

import PromptCard, { PromptCardProps } from '../../design/Cards/PromptCard';
import {
    useAssignedTasksQuery,
    useMarkTaskAsDoneMutation,
} from '../../api/tasks/tasksAPI';
import { TaskView } from '../Tasks/utils';
import useHandleSubmitRTK from '../../hooks/useHandleSubmit';
import SeeAllTasksButton from './SeeAllTasksButton';

export default function ListWithTasks() {
    const { data, isLoading: isTaskLoading } = useAssignedTasksQuery({
        page: 0,
        size: 5,
    });

    const { handleSubmit } = useHandleSubmitRTK({
        useRtkHook: useMarkTaskAsDoneMutation,
    });

    if (isTaskLoading) {
        return (
            <Box display={'flex'} flexDirection={'column'}>
                <Skeleton height={200} animation={'wave'} />
                <Skeleton height={200} animation={'wave'} />
            </Box>
        );
    }
    if (!data?.data || data?.data.length === 0) {
        return (
            <Box
                width={'100%'}
                display={'flex'}
                flexDirection={'column'}
                rowGap={2}>
                <PromptCard {...emptyPropmtCard} />
                <SeeAllTasksButton />
            </Box>
        );
    }
    return (
        <Box
            width={'100%'}
            display={'flex'}
            flexDirection={'column'}
            rowGap={2}>
            <Box display={'flex'} flexDirection={'column'} gap={1}>
                {data?.data.map((activity, index) => (
                    <PromptCard
                        key={index}
                        {...convertDataToPromptCard(activity, handleSubmit)}
                    />
                ))}
            </Box>
            <SeeAllTasksButton />
        </Box>
    );
}

function convertDataToPromptCard(
    data: TaskView,
    handleSubmit: (id: string) => void,
): PromptCardProps {
    return {
        title: data.name,
        type: typeMapperForDueDate(data.dueDate),
        description: data.description,
        date: textMapperForDueDate(data.dueDate),
        cardCheckbox: {
            label: 'Done',
            onChange: () => {
                handleSubmit(data.id);
            },
            checked: data.status === 'DONE',
        },
    };
}

function typeMapperForDueDate(dueDate: string | null) {
    if (!dueDate) return 'success';
    const due = new Date(dueDate);
    const today = new Date();
    const diff = due.getTime() - today.getTime();
    const days = Math.ceil(diff / (1000 * 60 * 60 * 24));
    if (days < 0) {
        return 'error';
    }
    if (days === 0) {
        return 'warning';
    }
    return 'success';
}
function textMapperForDueDate(dueDate: string | null) {
    if (!dueDate) return null;
    const due = new Date(dueDate);
    const today = new Date();
    const diff = due.getTime() - today.getTime();
    const days = Math.ceil(diff / (1000 * 60 * 60 * 24));
    if (days < 0) {
        return `Overdue: ${dueDate}`;
    }
    if (days === 0) {
        return `Due today - ${dueDate}`;
    }
    return `Due in ${days} days - ${dueDate}`;
}
const emptyPropmtCard: PromptCardProps = {
    title: 'No Tasks',
    type: 'default',
    description: 'Lets get going',
    date: '',
};
