import useLeaseHistoryTable from './useLeaseHistoryTable';

import GenericTable from '../../design/GenericTable/GenericTable';
import TableSkeleton from '../../design/Skeleton/TableSkeleton';

export default function LeaseHistoryTable() {
    const { data, totalCount, tableConfig, isLoading } = useLeaseHistoryTable();
    if (isLoading) {
        return <TableSkeleton />;
    }
    return (
        <GenericTable
            data={data}
            totalCount={totalCount}
            tableConfig={tableConfig}
        />
    );
}
