import theme from '../../../../styles/theme';
import useStyles from './AllInactiveUsers.styles';

import useModal from '../../../../hooks/useModal';
import AddUser from '../AddUser/AddUser';
import InactiveUsersList from './InactiveUsersList';

export default function AllInactiveUsers() {
    const { classes } = useStyles(theme);
    const { isShowing, setIsShowing } = useModal();
    return (
        <div className={classes.allUsersWrapper}>
            <InactiveUsersList />
            <AddUser isShowing={isShowing} setIsShowing={setIsShowing} />
        </div>
    );
}
