import useLPContactHistoryTable from './useLPContactHistoryTable';
import TableSkeleton from '../../design/Skeleton/TableSkeleton';
import GenericTable from '../../design/GenericTable/GenericTable';

export default function LPContactHistoryTable() {
    const { data, totalCount, tableConfig, isLoading } =
        useLPContactHistoryTable();
    if (isLoading) {
        return <TableSkeleton />;
    }
    return (
        <GenericTable
            data={data}
            totalCount={totalCount}
            tableConfig={tableConfig}
        />
    );
}
