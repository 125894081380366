import React from 'react';
import UserAvatar, { AvatarFormat } from '../../../design/Avatar/UserAvatar';

import { Box, Typography } from '@mui/material';
import { dateTimeConverterFromUTC } from '../../../utils/dateFormat';
import BasedTooltip from '../../../design/BasedTooltip';
import { Attachment } from '../LandPartnerActivity';

export interface Comment {
    id: string;
    name: string;
    date: string;
    comment: string;
    avatar?: AvatarFormat;
    attachments?: Attachment[];
}

export default function PostComment({ name, date, comment, avatar }: Comment) {
    return (
        <Box
            display={'flex'}
            gap={2}
            pt={1}
            rowGap={1}
            flexDirection={'column'}
            width={'100%'}>
            <Box display={'flex'} columnGap={2} alignItems={'center'}>
                <UserAvatar {...avatar} name={name} size={'small'} />
                <Box sx={{ maxWidth: '30%' }}>
                    <BasedTooltip text={name}>
                        <Typography noWrap variant="font12" fontWeight="medium">
                            {name}
                        </Typography>
                    </BasedTooltip>
                </Box>
                <Typography
                    sx={{ minWidth: '110px' }}
                    variant="font12"
                    fontWeight="medium"
                    color="text.neutral6">
                    {dateTimeConverterFromUTC(date)}
                </Typography>
            </Box>
            <Box pl={5} sx={{ maxWidth: '80%' }}>
                <BasedTooltip text={comment}>
                    <Typography
                        variant="font12"
                        fontWeight="medium"
                        color="text.neutral6">
                        {comment}
                    </Typography>
                </BasedTooltip>
            </Box>
        </Box>
    );
}
