import { Contact } from './LandPartnerWizard';
import { makeStyles } from 'tss-react/mui';
import theme from '../../styles/theme';
import { Box, Button, Divider, Grid, Typography } from '@mui/material';
import UserAvatar from '../../design/Avatar/UserAvatar';
import SocialMediaList from '../../reusable/SocialMediaList';
import InfoWithLabel from '../../reusable/InfoWithLabel';
import { ReactComponent as LocationIcon } from '../../assets/icons/pin_location_icon.svg';
import { ReactComponent as EmailIcon } from '../../assets/icons/communications_icon.svg';
import { ReactComponent as RelationshipIcon } from '../../assets/icons/calendar_icon.svg';
import PersonAvatarWithDescription from './PersonAvatarWithDescription';
import StatusTag, { Tag } from '../../design/StatusTag/StatusTag';
import React from 'react';
import {
    SocialMedia,
    Status,
} from '../ViewLandownersDetails/LandPartnerDetails';

export interface OrganizationCard {
    name: string;
    taxId: string;
    associatedAcres: number;
    email: string;
    relationshipStartDate: string;
    primaryContactAddress: string;
    primaryContact: Contact;
    address: string;
    status: Status;
    members?: Contact[];
    socialMedia?: SocialMedia;
    avatar?: File;
    onClick: () => void;
}

export default function PreviewOrganizationCard({
    name,
    primaryContact,
    address,
    taxId,
    associatedAcres,
    email,
    members,
    primaryContactAddress,
    relationshipStartDate,
    socialMedia,
    status,
    avatar,
    onClick,
}: OrganizationCard) {
    const { classes } = useStyles();

    const fileURL = avatar ? URL.createObjectURL(avatar) : '';
    return (
        <Box className={classes.container}>
            <Box>
                <Box display={'flex'} columnGap={1}>
                    <UserAvatar name={name} avatar={fileURL} size={'large'} />
                    <Box
                        display={'flex'}
                        flexDirection={'column'}
                        rowGap={1}
                        maxWidth={'40%'}>
                        <Typography
                            variant={'font20'}
                            fontWeight="medium"
                            color="text.secondary">
                            {name || 'N/A'}
                        </Typography>
                        <Typography
                            variant="font12"
                            fontWeight={'medium'}
                            color={'text.text.neutral7'}>
                            {`Tax Id: ${taxId || 'N/A'} `}
                        </Typography>
                        <Typography
                            variant="font12"
                            fontWeight={'medium'}
                            color={'text.text.neutral7'}>
                            {`Associated Acres: ${associatedAcres || 'N/A'} `}
                        </Typography>
                        <InfoWithLabel icon={EmailIcon} text={email || 'N/A'} />
                        <InfoWithLabel
                            icon={LocationIcon}
                            text={address || 'N/A'}
                        />

                        <InfoWithLabel
                            icon={RelationshipIcon}
                            text={relationshipStartDate || 'N/A'}
                        />

                        <SocialMediaList socialMedia={socialMedia} />
                        <Box display={'flex'} justifyContent={'flex-end'}>
                            <Button variant={'text'} onClick={onClick}>
                                Edit
                            </Button>
                        </Box>
                    </Box>
                    <Box
                        display={'flex'}
                        flexDirection={'column'}
                        rowGap={2}
                        width={'50%'}>
                        <Box display={'flex'} columnGap={3}>
                            <Box
                                display={'flex'}
                                flexDirection={'column'}
                                alignItems={'center'}
                                rowGap={2}>
                                <Typography
                                    fontWeight={'bold'}
                                    variant={'font14'}
                                    color={'text.link'}>
                                    PRIMARY CONTACT
                                </Typography>
                                <StatusTag {...statusMap[status]} />
                            </Box>
                            <PersonAvatarWithDescription
                                name={`${primaryContact?.firstName} ${primaryContact?.lastName}`}
                                description={''}
                            />
                        </Box>
                        <Divider orientation="vertical" flexItem />
                        <Box
                            display={'flex'}
                            flexDirection={'column'}
                            rowGap={1}>
                            <InfoWithLabel
                                icon={EmailIcon}
                                text={primaryContact?.primaryEmail}
                            />
                            <InfoWithLabel
                                icon={EmailIcon}
                                text={primaryContact?.secondaryEmail}
                            />
                            <Box width={'80%'}>
                                <InfoWithLabel
                                    icon={LocationIcon}
                                    text={primaryContactAddress || 'N/A'}
                                />
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
            {!!members?.length && (
                <Box display={'flex'} columnGap={4} pt={3}>
                    <Typography variant={'font16'} fontWeight="medium">
                        Members
                    </Typography>
                    <Box
                        display={'flex'}
                        flexDirection={'column'}
                        gap={1}
                        sx={{
                            flexGrow: 1,
                        }}>
                        <Grid container spacing={3}>
                            {members?.map((member, index: number) => (
                                <Grid item key={index} xs={4}>
                                    <PersonAvatarWithDescription
                                        name={`${member.firstName} ${member.lastName}`}
                                        description={
                                            member.addresses &&
                                            member.addresses[0].address1
                                        }
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                </Box>
            )}
        </Box>
    );
}
const useStyles = makeStyles()(() => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        backgroundColor: theme.palette.darkTurq.darkTurqTint1,
        gap: theme.spacing(1),
        margin: theme.spacing(2, 2, 0, 2),
        padding: theme.spacing(2),
    },
}));

const statusMap: Record<Status, Tag> = {
    ACTIVE: {
        status: 'success',
        text: 'Active',
    },

    INACTIVE: {
        status: 'warning',
        text: 'Inactive',
    },
    PROSPECT: {
        status: 'info',
        text: 'Prospect',
    },

    SUSPENDED: {
        status: 'error',
        text: 'Suspended',
    },
};
