import React from 'react';
import PDFViewer from '../PDFViewer';
import BaseModal from '../BaseModal';
import ImageViewer from '../ImageViewer';

interface OwnProps {
    file: string | null;
    isShowing: boolean;
    onClose: () => void;
    isLoading?: boolean;
    extension: string;
    fileName?: string;
}
const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg'];
export default function PreviewModal({
    isShowing,
    onClose,
    file,
    isLoading,
    extension,
    fileName,
}: OwnProps) {
    return (
        <BaseModal
            header={`Preview ${fileName}`}
            isLoading={isLoading}
            isShowing={isShowing}
            onClose={onClose}
            height={'large'}>
            <>
                {file && imageExtensions.includes(extension) && (
                    <ImageViewer imageURL={file} />
                )}
                {file &&
                    !imageExtensions.includes(extension) &&
                    extension === 'pdf' && <PDFViewer fileUrl={file} />}
                {(!file ||
                    (!imageExtensions.includes(extension) &&
                        extension !== 'pdf')) && (
                    <div>File type not supported</div>
                )}
            </>
        </BaseModal>
    );
}
