import { Typography, Box, Grid, Button } from '@mui/material';
import * as yup from 'yup';
import React, { useState } from 'react';
import { useFormik } from 'formik';

import { ReactComponent as ApproveIcon } from '../../assets/icons/approve_icon.svg';
import { ReactComponent as RejectIcon } from '../../assets/icons/reject_icon.svg';

import { useChangePasswordMutation } from '../../api/auth/authAPI';
import { makeStyles } from 'tss-react/mui';
import { passwordRegex } from '../../constant/regex';
import customToastWithAlert from '../../design/CustomToastWithAlert';
import FormTextField, { EndIconType } from '../../design/FormUI/FormTextField';
import theme from '../../styles/theme';
import SubmitButton from '../../design/Buttons/SubmitButton';

const INITIAL_FORM_STATE = {
    oldPassword: '',
    newPassword: '',
    repeatPassword: '',
};

interface ChangePasswordFormProps {
    oldPassword: string;
    newPassword: string;
}
const minPasswordLength = 12;

export const FORM_VALIDATION = yup.object({
    oldPassword: yup
        .string()
        .min(12, '* Password must have at least 8 characters')
        .required('* This field is required'),
    newPassword: yup
        .string()
        .min(12, '* Password must have at least 8 characters')
        .matches(passwordRegex, '* Please provide a correct password')
        .required('* This field is required'),
    repeatPassword: yup
        .string()
        .oneOf([yup.ref('newPassword')], '* Enter correct password')
        .required('* This field is required'),
});

export default function UserProfilePreferences() {
    const { classes } = useStyles();
    const [showChangePassword, setShowChangePassword] = useState(false);
    const [changePassword, { isLoading }] = useChangePasswordMutation();

    const onSubmit = ({
        newPassword,
        oldPassword,
    }: ChangePasswordFormProps) => {
        changePassword({ newPassword, oldPassword })
            .unwrap()
            .then(() => {
                formik.resetForm();
                return customToastWithAlert({
                    type: 'success',
                    message: 'Password updated successfully',
                });
            })
            .catch((error) => {
                return customToastWithAlert({
                    type: 'error',
                    message: error?.data?.description || 'Something went wrong',
                });
            });
    };
    const formik = useFormik({
        enableReinitialize: true,
        validateOnChange: true,
        initialValues: { ...INITIAL_FORM_STATE },
        validationSchema: FORM_VALIDATION,
        onSubmit,
    });

    return (
        <Grid
            xs={12}
            container
            columnGap={4}
            alignItems={'center'}
            sx={{
                padding: theme.spacing(2, 4, 10, 2),
                backgroundColor: theme.palette.neutralWhite,
            }}>
            <Grid item xs={3}>
                <Typography>Password Update</Typography>
            </Grid>
            <Grid item xs={8}>
                {!showChangePassword ? (
                    <Box pt={2}>
                        <Button
                            size={'large'}
                            variant={'contained'}
                            onClick={() => setShowChangePassword(true)}>
                            Change Password
                        </Button>
                    </Box>
                ) : (
                    <form onSubmit={formik.handleSubmit} noValidate>
                        <Box display={'flex'} gap={2}>
                            <Box
                                width={'40%'}
                                display={'flex'}
                                flexDirection={'column'}
                                gap={2}>
                                <FormTextField
                                    name={'oldPassword'}
                                    label={'Current Password'}
                                    required
                                    formik={formik}
                                    endIconType={EndIconType.password}
                                />
                                <FormTextField
                                    name={'newPassword'}
                                    label={'New Password'}
                                    required
                                    formik={formik}
                                    endIconType={EndIconType.password}
                                />

                                <FormTextField
                                    name={'repeatPassword'}
                                    label={'Repeat Password'}
                                    required
                                    formik={formik}
                                    endIconType={EndIconType.password}
                                />
                            </Box>

                            <Box
                                display={'flex'}
                                flexDirection={'column'}
                                gap={1}
                                width={'40%'}
                                justifyContent={'flex-end'}>
                                <div className={classes.validationItem}>
                                    {formik.values.newPassword?.length >=
                                    minPasswordLength ? (
                                        <ApproveIcon />
                                    ) : (
                                        <RejectIcon />
                                    )}
                                    At least 12 characters long but 14 or more
                                    is better.
                                </div>
                                <div className={classes.validationItem}>
                                    {formik.values.newPassword.match(
                                        passwordRegex,
                                    ) ? (
                                        <ApproveIcon />
                                    ) : (
                                        <RejectIcon />
                                    )}
                                    A combination of 1 uppercase letter minimum,
                                    1 lowercase letter minimum, 1 number and 1
                                    special character minimum.
                                </div>
                                <div className={classes.validationItem}>
                                    <ApproveIcon /> Not a word that can be found
                                    in a dictionary or the name of a person,
                                    character, product, or organization.
                                </div>
                            </Box>
                        </Box>
                        <Box pt={2} width={'100%'}>
                            <SubmitButton
                                text={'Update Password'}
                                variant={'contained'}
                                isLoading={isLoading}
                                isDisabled={!formik.isValid || !formik.dirty}
                            />
                        </Box>
                    </form>
                )}
            </Grid>
        </Grid>
    );
}

const useStyles = makeStyles()(() => ({
    validationItem: {
        display: 'flex',
        fontSize: '14px',
        lineHeight: '1.43',
        color: '#8f8f8f',

        '& svg': {
            minWidth: '16px',
            marginRight: '16px',
            marginTop: '2px',
        },
    },
}));
