import TabPanelWithoutNavigation from '../../design/TabsPanel/TabPanelWithoutNavigation';
import PaymentOverviewScheduled from './PaymentOverviewScheduled';
import PaymentOverviewPaid from './PaymentOverviewPaid';

const tabsConfig = [
    {
        label: 'Scheduled',
        content: <PaymentOverviewScheduled />,
        value: 0,
    },
    {
        label: 'Paid',
        content: <PaymentOverviewPaid />,
        value: 1,
    },
];

export default function PaymentOverviewTabs() {
    return <TabPanelWithoutNavigation tabItems={tabsConfig} />;
}
