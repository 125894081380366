import { Dispatch, SetStateAction } from 'react';

import { useAddOrganizationRoleMutation } from '../../../../api/users/rolesAPI';
import FormModal from '../../../../reusable/Modal/FormModal';
import { Role } from '../AllRoles/RoleList';
import { formConfig as orgRoleFormConfig } from '../config/addOrganizationRoleConfig';
import { useGetOrganizationsQuery } from '../../../../api/users/organizationsAPI';
import customToastWithAlert from '../../../../design/CustomToastWithAlert';

export interface ManageModal {
    isShowing: boolean;
    setIsShowing: Dispatch<SetStateAction<boolean>>;
}

export default function AddOrganizationRole({
    isShowing,
    setIsShowing,
}: ManageModal) {
    const [addOrganizationRole] = useAddOrganizationRoleMutation();
    const { data: orgData } = useGetOrganizationsQuery({
        sort: 'name,ASC',
        page: 0,
        size: 1000,
    });

    function handleSubmit(value: Role) {
        addOrganizationRole(value)
            .unwrap()
            .then(() => {
                return (
                    customToastWithAlert({
                        type: 'success',
                        message: 'Role added successfully',
                    }),
                    setIsShowing(false)
                );
            })
            .catch((error) => {
                return customToastWithAlert({
                    type: 'error',
                    message: error?.data?.description || 'Something went wrong',
                });
            });
    }

    return (
        <FormModal
            header={'Add role'}
            isShowing={isShowing}
            formConfig={orgRoleFormConfig(orgData?.data || [])}
            handleSubmit={handleSubmit}
            submitButtonText={'Add role'}
            onClose={() => setIsShowing(false)}
        />
    );
}
