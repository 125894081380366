import React from 'react';

import { Box, Typography } from '@mui/material';

import UserAvatar, { AvatarFormat } from '../../../design/Avatar/UserAvatar';
import LinkText from '../../../design/Fields/LinkText';
import BasedTooltip from '../../../design/BasedTooltip';
import PATHS from '../../../router/paths';
import { Avatar } from '../../../features/ViewLandownersDetails/LandPartnerDetails';

export interface Member {
    id: string;
    avatar: AvatarFormat;
    name: string;
    role: string;
}
export interface MemberListResp {
    primaryContact: PrimaryContact;
    members: MemberContact[];
    totalCount: number;
}

export interface PrimaryContact {
    id: string;
    firstName: string;
    lastName: string;
    primaryEmail: string;
    contactRole: string;
    avatar: Avatar;
}

export interface MemberContact {
    id: string;
    firstName: string;
    lastName: string;
    primaryEmail: string;
    contactRole: string;
    avatar: Avatar;
}

export default function MemberProfile({ id, avatar, name, role }: Member) {
    return (
        <Box display={'flex'} columnGap={1}>
            <UserAvatar size={'small'} {...avatar} />
            <Box display={'flex'} flexDirection={'column'} columnGap={1}>
                <BasedTooltip text={name}>
                    <LinkText
                        to={`/${PATHS.landpartners}/${PATHS.allProfiles}/${id}`}
                        text={name}
                    />
                </BasedTooltip>
                <Typography
                    variant={'font12'}
                    fontWeight={'medium'}
                    color={'text.secondaryContrast'}>
                    {role}
                </Typography>
            </Box>
        </Box>
    );
}
