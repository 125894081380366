import React, { useEffect, useState } from 'react';
import { GUEST_KEY } from '../../constant/localStorageKeys';
import { useLazyGuestsDocumentQuery } from '../../api/users/guestAPI';
import { useNavigate } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import { DecodedToken } from '../../features/Tasks/utils';
import { TableColumn } from '../../design/GenericTable/GenericTable';
import BasedTooltip from '../../design/BasedTooltip';
import { Typography } from '@mui/material';
import { checkingIfTokenExpired } from '../../api/OAKENApi';
import { RequestDocumentType } from '../../features/ViewLandownersDetails/LandPartnerDocuments/DocumentTypeContext';
import { useDownloadDocumentsGuest } from '../../hooks/useDownloadDocumentsGuest';

export interface DocumentTable {
    documentId: string;
    documentName: string;
    documentCategory: RequestDocumentType;
}

export default function useGuestDocumentsTable() {
    const navigate = useNavigate();
    const [getItems, { data, isLoading }] = useLazyGuestsDocumentQuery();

    const {
        isShowing: isShowingDocument,
        file,
        isLoading: isDocumentLoading,
        handleClose: closeDocumentModal,
        handlePreviewOpen,
        handleDownload,
    } = useDownloadDocumentsGuest();

    const [isShowing, setIsShowing] = useState(false);
    const [fileName, setFileName] = useState<string>('');
    const [documentData, setDocumentData] = useState<DocumentTable | undefined>(
        undefined,
    );

    function getDocuments({ guestId }: { guestId?: string }) {
        getItems({
            id: guestId,
            sort: 'createdAt,asc',
        });
    }

    useEffect(() => {
        const token = localStorage.getItem(GUEST_KEY) || null;
        if (!token || checkingIfTokenExpired(token)) {
            navigate('/guests/login');
        } else {
            const decodedToken = jwtDecode<DecodedToken>(token);
            getDocuments({ guestId: decodedToken.userId });
        }
    }, []);

    function handlePreview(documentId: string, fileName: string) {
        setFileName(fileName);
        handlePreviewOpen(documentId);
    }

    function handleClose() {
        setIsShowing(false);
        setDocumentData(undefined);
    }

    const columns: TableColumn<DocumentTable>[] = [
        {
            header: {
                label: 'Document',
                sortable: false,
            },
            maxColumnWidth: '35%',
            cellRender: (data) => (
                <BasedTooltip text={data?.documentName}>
                    <Typography
                        onClick={() =>
                            handlePreview(data.documentId, data?.documentName)
                        }
                        variant={'font14'}
                        color={'text.link'}
                        sx={{
                            cursor: 'pointer',
                            '&:hover': {
                                textDecoration: 'underline',
                            },
                        }}>
                        {data?.documentName}
                    </Typography>
                </BasedTooltip>
            ),
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
        },
        {
            header: {
                label: 'Document Category',
                sortable: false,
            },
            cellRender: (data) => (
                <Typography variant={'font14'}>
                    {documentCategoryMapper[data?.documentCategory]}
                </Typography>
            ),
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
        },
    ];
    const tableConfig = {
        columns,
        pageable: false,
        multiselect: false,
        expandable: false,
    };

    const documents = data || [];
    const fileExtension = fileName?.split('.').pop();
    return {
        isShowing,
        handleClose,
        documentData,
        setDocumentData,
        tableConfig,
        isLoading,
        documents,
        isShowingDocument,
        file,
        isDocumentLoading,
        closeDocumentModal,
        handleDownload,
        fileExtension,
        fileName,
    };
}

const documentCategoryMapper: Record<RequestDocumentType, string> = {
    LEASE: 'Lease',
    LAND: 'Land',
    TASK: 'Task',
    REPORTS: 'Reports',
    OTHER: 'Other',
    FILINGS: 'Filings',
    FINANCIAL: 'Financial',
    '': '',
};
