import { ColumnConfig } from '../api/users/customizeConfigAPI';

export const initCustomViewForLease: ColumnConfig[] = [
    { id: 'aveRentPerAcre', text: 'Average Rent per Acre', isHidden: false },
    { id: 'leaseName', text: 'Lease Name', isHidden: false },
    { id: 'landownerNames', text: 'Landowner', isHidden: false },
    { id: 'fieldName', text: 'Field Name', isHidden: false },
    { id: 'totalAcres', text: 'Total Acres', isHidden: false },
    {
        id: 'tillableAcres',
        text: 'Tillable Acres',
        isHidden: false,
    },
    { id: 'type', text: 'Lease Type', isHidden: false },
    { id: 'endDate', text: 'Lease Expiration', isHidden: false },
    { id: 'fsaIds', text: 'FSA ID', isHidden: false },
];

export const initCustomViewForLandPartners: ColumnConfig[] = [
    { id: 'name', text: 'Land Partner Name', isHidden: false },
    {
        id: 'primaryContactFirstName',
        text: 'Primary Contact Name',
        isHidden: false,
    },
    { id: 'leases', text: 'Associated Leases', isHidden: false },
    {
        id: 'status',
        text: 'Status',
        isHidden: false,
    },
    { id: 'actions', text: 'Actions', isHidden: false },
];
export const initCustomViewForGrowers: ColumnConfig[] = [
    { id: 'name', text: 'Grower Name', isHidden: false },
    {
        id: 'primaryContactFirstName',
        text: 'Primary Contact Name',
        isHidden: false,
    },
    {
        id: 'status',
        text: 'Status',
        isHidden: false,
    },
    { id: 'actions', text: 'Actions', isHidden: false },
];

export const initCustomViewForAllContacts: ColumnConfig[] = [
    {
        id: 'fullName.first_last',
        text: 'Full Name (First Last)',
        isHidden: false,
    },
    {
        id: 'landPartnerName',
        text: 'Land Partner',
        isHidden: false,
    },
    {
        id: 'phoneNumber',
        text: 'Primary Phone',
        isHidden: false,
    },
    { id: 'primaryEmail', text: 'Email', isHidden: false },
    { id: 'acres', text: 'Acres', isHidden: false },
    { id: 'notes', text: 'Notes', isHidden: false },
    { id: 'lastContactDate', text: 'Last Contact', isHidden: false },
    { id: 'status', text: 'Status', isHidden: false },
    { id: 'relationshipManager', text: 'Relationship Manager', isHidden: true },
    {
        id: 'fullName.last_first',
        text: 'Full Name (Last, First)',
        isHidden: true,
    },
];

export const initCustomViewForTasks: ColumnConfig[] = [
    { id: 'dueDate', text: 'Due Date', isHidden: false },
    { id: 'createdBy', text: 'Created By', isHidden: false },
    {
        id: 'assignedTo',
        text: 'Assigned to',
        isHidden: false,
    },
    {
        id: 'assignedTo',
        text: 'Assigned to',
        isHidden: false,
    },
    {
        id: 'name',
        text: 'Task Name',
        isHidden: false,
    },
    {
        id: 'priority',
        text: 'Priority',
        isHidden: false,
    },
    {
        id: 'status',
        text: 'Status',
        isHidden: false,
    },
    {
        id: 'description',
        text: 'Description',
        isHidden: false,
    },
    {
        id: 'edit',
        text: 'Edit',
        isHidden: false,
    },
];
