import { IntegrationSettings, IntegrationStatus } from './IntegrationCard';
import { Grid } from '@mui/material';
import AppTypography from '../../../design/AppTypography';
import StatusTag, { Tag } from '../../../design/StatusTag/StatusTag';

interface OwnProps {
    settings: IntegrationSettings;
}

export default function IntegrationSettingStatus({ settings }: OwnProps) {
    return (
        <Grid container alignItems={'center'} justifyContent={'space-between'}>
            <AppTypography
                variant={'font14'}
                fontWeight={'regular'}
                color={'text.secondary'}
                text={settings.name}
            />
            <StatusTag {...statusMapper[settings.status]} />
        </Grid>
    );
}

const statusMapper: Record<IntegrationStatus, Tag> = {
    ACTIVE: {
        status: 'success',
        text: 'Active',
        size: 'small',
    },
    PAUSED: {
        status: 'disabled',
        text: 'Paused',
        size: 'small',
    },
    INACTIVE: {
        status: 'error',
        text: 'Inactive',
        size: 'small',
    },
};
