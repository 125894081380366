import React from 'react';
import parse from 'html-react-parser';
import BaseModal from '../../reusable/BaseModal';
import { makeStyles } from 'tss-react/mui';
import theme from '../../styles/theme';

interface OwnProps {
    isShowing: boolean;
    onClose: () => void;
    data?: string;
    isLoading?: boolean;
}

export default function ModalBonusFieldPreview({
    isShowing,
    onClose,
    data,
    isLoading,
}: OwnProps) {
    const { classes } = useStyles();
    return (
        <BaseModal
            header={`Bonus Calculation Description`}
            isLoading={isLoading}
            isShowing={isShowing}
            onClose={onClose}>
            <div className={classes.container}>
                <style>{`
        td {
          border: solid 1px black;
          padding: 4px
        }
        img {
        max-width: 600px;
        }
    `}</style>
                {data && parse(data)}
            </div>
        </BaseModal>
    );
}
const useStyles = makeStyles()(() => ({
    container: {
        padding: theme.spacing(2, 4),
        height: '100%',
        width: '100%',
        '&::-webkit-scrollbar': {
            width: 10,
            backgroundColor: 'transparent',
            padding: 10,
            scrollbarHeight: 10,
        },
        '&::-webkit-scrollbar-thumb': {
            width: 5,
            height: 5,
            borderRadius: '5px',
            backgroundColor: 'silver',
        },
        '&::-webkit-scrollbar-button': {
            height: 35,
        },
        overflowY: 'auto',
        overflowX: 'hidden',
    },
}));
