import { useEffect, useState } from 'react';
import RolesDropdown from './RolesDropdown';
import { Box } from '@mui/material';
import PermissionTableList, { Permission } from './PermissionTableList';
import {
    useGetRolesQuery,
    useLazyPermissionsByRoleQuery,
} from '../../../api/users/rolesAPI';
import { Option } from '../../../features/CreateLeaseForm/WizardPageConfigs/landPartnersConfig';

export default function ManageRole() {
    const [role, setRole] = useState<string>('');
    const [options, setOptions] = useState<Option[]>([]);
    const [enabledPermissions, setEnabledPermissions] = useState<string[]>([]);
    const { data, isLoading: isOptionsLoading } = useGetRolesQuery({
        sort: `name,asc`,
        page: 0,
        size: 2000,
    });

    const [
        getItems,
        {
            data: permissionsData,
            isLoading: isPermissionsDataLoading,
            isFetching,
        },
    ] = useLazyPermissionsByRoleQuery();

    useEffect(() => {
        if (data) {
            setOptions(
                data.data.map((role) => ({ id: role.id, name: role.name })),
            );
            setRole(data.data.map((role) => role.id)[0]);
        }
    }, [data, setRole]);

    useEffect(() => {
        role && getItems({ id: role });
    }, [role]);

    useEffect(() => {
        const permissionsKeys = permissionsData && Object.keys(permissionsData);

        function idOfEnabledPermissions() {
            return permissionsKeys?.flatMap((key: string) => {
                return permissionsData[key]
                    .map((permission: Permission) => {
                        if (permission.enabled) {
                            return permission.id;
                        }
                    })
                    .filter(Boolean);
            });
        }

        setEnabledPermissions(idOfEnabledPermissions());
    }, [permissionsData]);

    return (
        <Box width="100%" display={'flex'} flexDirection={'column'} rowGap={2}>
            <Box
                minWidth={'15%'}
                display={'flex'}
                width={'100%'}
                justifyContent={'flex-end'}>
                <RolesDropdown
                    role={role}
                    options={options}
                    setRole={setRole}
                    isLoading={isOptionsLoading}
                />
            </Box>
            {permissionsData && (
                <PermissionTableList
                    roleId={role}
                    idOfEnabledPermissions={enabledPermissions}
                    permissions={permissionsData}
                    isLoading={isPermissionsDataLoading || isFetching}
                />
            )}
        </Box>
    );
}
