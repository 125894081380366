import React, { useState } from 'react';

import { Box } from '@mui/material';
import TwoColumnLayout from '../../../../layouts/TwoColumnLayout';
import Stepper from '../../../../design/Stepper/Stepper';
import OrganizationForm from './OrganizationForm';
import { useParams } from 'react-router-dom';

export default function OrganizationWizard() {
    const [pageNumber, setPageNumber] = useState(0);
    const [isValid, setIsValid] = useState(false);
    const { id } = useParams<{ id: string }>();
    const isEdit = !!id;

    return (
        <Box m={1} bgcolor={'white'}>
            <TwoColumnLayout
                header={headerTitle[pageNumber]}
                type={'third'}
                leftComponent={
                    <Box p={4}>
                        <Stepper
                            key={'organization'}
                            activeStep={pageNumber}
                            steps={organizationsSteps}
                            isNavigable={isValid && isEdit}
                            setActiveStep={setPageNumber}
                        />
                    </Box>
                }
                rightComponent={
                    <OrganizationForm
                        setIsValid={setIsValid}
                        pageNumber={pageNumber}
                        setPageNumber={setPageNumber}
                    />
                }
            />
        </Box>
    );
}

const headerTitle = [
    'Organization',
    'Subscription',
    'Billing',
    'Sub Entities',
    'Summary',
];
const organizationsSteps = [
    { name: 'Step 1: Organization', description: '' },
    { name: 'Step 2: Subscription', description: '' },
    { name: 'Step 3: Billing', description: '' },
    { name: 'Step 4: Sub Entities', description: '' },
    { name: 'Step 5: Review', description: '' },
];
