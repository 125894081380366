import { Option } from '../../CreateLeaseForm/WizardPageConfigs/leaseUploadConfig';
import { FormConfig } from '../../../design/Forms/interfaces';
import { DocumentCategory } from '../../ViewDashboard/RecentUploads';

export const documentCategoryOptions: Option[] = [
    {
        id: 'FINANCIAL',
        name: 'Financial',
    },
    {
        id: 'FILINGS',
        name: 'Filings',
    },
    {
        id: 'REPORTS',
        name: 'Reports',
    },
    {
        id: 'LAND',
        name: 'Land',
    },
    {
        id: 'OTHER',
        name: 'Other',
    },
];
export const documentCategoryFullOptions: Option[] = [
    { id: 'LEASE', name: 'Lease' },
    {
        id: 'TASK',
        name: 'Task',
    },
    {
        id: 'FINANCIAL',
        name: 'Financial',
    },
    {
        id: 'FILINGS',
        name: 'Filings',
    },
    {
        id: 'REPORTS',
        name: 'Reports',
    },
    {
        id: 'LAND',
        name: 'Land',
    },
    {
        id: 'OTHER',
        name: 'Other',
    },
];

interface Config {
    documentCategory: string;
    allowedCategories: DocumentCategory[];
}

export function fileDataConfig({
    documentCategory,
    allowedCategories,
}: Config): FormConfig[] {
    const filteredDocumentCategoryFullOptions =
        documentCategoryFullOptions.filter((option) =>
            allowedCategories.includes(option.id as DocumentCategory),
        );
    const filteredDocumentCategoryOptions = documentCategoryOptions.filter(
        (option) => allowedCategories.includes(option.id as DocumentCategory),
    );
    return [
        {
            formField: {
                name: 'sharingAllowed',
                type: 'switch',
                label: 'Visibility',
            },
            renderProps: {
                isDisabled: false,
                size: 'small',
            },
        },
        {
            formField: {
                name: 'documentCategory',
                type: 'select',
                label: 'Category',
            },
            renderProps: {
                isDisabled:
                    documentCategory === 'LEASE' || documentCategory === 'TASK',
                size: 'small',
                options:
                    documentCategory === 'LEASE' || documentCategory === 'TASK'
                        ? filteredDocumentCategoryFullOptions
                        : filteredDocumentCategoryOptions,
            },
        },
        {
            formField: {
                name: 'documentPurpose',
                type: 'input',
                label: 'Type',
            },
            renderProps: {
                isDisabled: false,
                size: 'small',
            },
        },
    ];
}
