import SubscriptionCard, { SubscriptionType } from './SubscriptionCard';
import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-final-form';
import ToggleTrialButton from './ToggleTrialButton';
import { returnFeatures } from './utils';

export interface Features {
    type: SubscriptionType;
    features: FeaturesOption;
    integrations?: FeaturesOption;
    isMostPopular?: boolean;
}

export interface FeaturesOption {
    [key: string]: boolean;
}

export default function OrganizationSubscriptionPage() {
    const [subscriptionConfig, setSubscriptionConfig] = useState<Features[]>();

    const form = useForm();
    const { values } = form.getState();

    useEffect(() => {
        if (values?.subscription?.type) {
            setSubscriptionConfig(
                returnFeatures(values?.type).map((plan) => {
                    if (plan.type !== values?.subscription?.type) {
                        return plan;
                    }
                    return Object.assign(
                        {},
                        plan,
                        { type: values?.subscription?.type },
                        { features: values?.subscription?.features },
                    );
                }),
            );
        } else {
            setSubscriptionConfig(returnFeatures(values?.type));
        }
    }, []);

    const [activePlan, setActivePlan] = useState<SubscriptionType>(
        values?.subscription?.type || 'PREMIUM',
    );

    function handleActivePlan(
        type: SubscriptionType,
        name?: string,
        value?: boolean,
    ) {
        setSubscriptionConfig((prevState) => {
            return prevState?.map((plan) => {
                if (plan.type !== type) {
                    return plan;
                }
                return Object.assign(
                    {},
                    plan,
                    { type },
                    name
                        ? { features: { ...plan.features, [name]: value } }
                        : {},
                );
            });
        });
        setActivePlan(type);
    }

    useEffect(() => {
        const subscription = subscriptionConfig?.find(
            (plan) => plan.type === activePlan,
        );
        if (subscription) {
            form.change('subscription', subscription);
        }
    }, [activePlan, subscriptionConfig]);

    return (
        <Box
            display={'flex'}
            width={'100%'}
            flexDirection={'column'}
            rowGap={2}>
            <Box display={'flex'} width={'100%'} justifyContent={'flex-end'}>
                <ToggleTrialButton isTrial={values?.subscription?.trial} />
            </Box>
            <Box
                width={'100%'}
                display={'flex'}
                columnGap={2}
                justifyContent={'center'}>
                {subscriptionConfig?.map((plan) => (
                    <SubscriptionCard
                        key={plan.type}
                        type={plan.type}
                        features={plan.features}
                        isMostPopular={plan.isMostPopular}
                        handleActivePlan={handleActivePlan}
                        isActive={plan.type === activePlan}
                    />
                ))}
            </Box>
        </Box>
    );
}
