import { Box, Grid, Typography } from '@mui/material';
import FormPage from '../../reusable/FormUI/FormPage';
import {
    relationshipContactRoles,
    relationshipTreeConfig,
} from './FormConfigs/relationshipTreeConfig';

import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useGetListSuborganizationsQuery } from '../../api/users/organizationsAPI';

import Loader from '../../design/BaseLoader';
import { Option } from '../CreateLeaseForm/WizardPageConfigs/leaseUploadConfig';

interface OwnProps {
    orgId: string;
}
export default function RelationshipTreePage({ orgId }: OwnProps) {
    const { id } = useParams<{ id: string }>();
    const { state } = useLocation();
    const isEdit = !!id;

    const { data: orgData, isLoading: isOrgDataLoading } =
        useGetListSuborganizationsQuery({
            id: orgId,
            sort: 'name,ASC',
            page: 0,
            size: 1000,
        });

    if (isOrgDataLoading) {
        return (
            <Grid
                container
                sx={{ height: '100vh' }}
                alignItems={'center'}
                justifyContent={'center'}
                flexDirection={'column'}>
                <Loader />
            </Grid>
        );
    }
    return (
        <Box display={'flex'} flexDirection={'column'} rowGap={2}>
            {!state?.isTree && (
                <>
                    <Typography variant={'font16'} fontWeight={'medium'}>
                        Which entity is this contact connected to?
                    </Typography>
                    <FormPage
                        formConfig={relationshipTreeConfig({
                            organization: orgData?.data as Option[],
                            isEdit,
                        })}
                    />
                </>
            )}
            <Typography variant={'font16'} fontWeight={'medium'}>
                What is this Contacts role?
            </Typography>
            <FormPage formConfig={relationshipContactRoles} />
        </Box>
    );
}
