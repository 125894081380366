export const statusOptions = [
    {
        id: 'ACTIVE',
        name: 'Active',
    },
    {
        id: 'INACTIVE',
        name: 'Inactive',
    },
    {
        id: 'PROSPECT',
        name: 'Prospect',
    },
    {
        id: 'SUSPENDED',
        name: 'Suspended',
    },
];
export const organizationTypeOptions = [
    {
        id: 'GROWER',
        name: 'Grower',
    },
    {
        id: 'LANDOWNER',
        name: 'Landowner',
    },
    {
        id: 'FARMLAND_MANAGER',
        name: 'Farmland Manager',
    },
    {
        id: 'SUCCESSION_PLANNERS',
        name: 'Succession Planners',
    },
];
