import { Button } from '@mui/material';

interface OwnProps {
    isChosen: boolean;
    setChosen: () => void;
    label: string;
}

export default function FilterOptionButton({
    isChosen,
    setChosen,
    label,
}: OwnProps) {
    return (
        <Button
            variant={isChosen ? 'contained' : 'text'}
            onClick={setChosen}
            sx={{
                fontSize: 12,
                height: 24,
            }}>
            {label}
        </Button>
    );
}
