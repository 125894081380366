import { Typography } from '@mui/material';
import React from 'react';
import { SharedLease } from '../Lease/SharedLeasesShortTable';
import { formatCurrency } from '../../../../utils/fieldMasks';
import GenericTable, {
    Table,
} from '../../../../design/GenericTable/GenericTable';
import StatusTag from '../../../../design/StatusTag/StatusTag';
import { paymentStatusMap } from '../../../../features/PaymentsOverview/usePaymentOverviewScheduled';

export interface SharedPayment {
    id: string;
    lease: SharedLease;
    paymentDate: string;
    amount: number;
    status: string;
}

interface OwnProps {
    data: SharedPayment[];
    totalCount: number;
    handlePageChange: (page: number) => void;
    handleItemsPerPageChange: (size: number) => void;
    currentPage: number;
    itemsPerPage: number;
    sortKey: string;
    sortOrder: 'asc' | 'desc';
    handleSort: (key?: string) => void;
}

export default function SharedPaymentsAllTable({
    data,
    totalCount,
    handlePageChange,
    handleItemsPerPageChange,
    currentPage,
    itemsPerPage,
    sortKey,
    sortOrder,
    handleSort,
}: OwnProps) {
    const paymentsPreviewConfig: Table<SharedPayment> = {
        columns: [
            {
                header: {
                    label: 'Lease Name',
                    sortable: true,
                    sorting: {
                        id: 'leaseName',
                        direction: sortKey === 'leaseName' ? sortOrder : 'desc',
                        isSorted: sortKey === 'leaseName',
                    },
                    onClick: handleSort,
                },
                cellRender: (item) => <>{item?.lease.leaseName}</>,
                format: { align: 'left', color: { color: 'primary' } },
                width: 'auto',
            },
            {
                header: {
                    label: 'Lease Type',
                    sortable: true,
                    sorting: {
                        id: 'type',
                        direction: sortKey === 'type' ? sortOrder : 'desc',
                        isSorted: sortKey === 'type',
                    },
                    onClick: handleSort,
                },
                cellRender: (item) => (
                    <Typography variant={'font14'} fontWeight={'medium'}>
                        {item.lease.type}
                    </Typography>
                ),
                format: { align: 'left', color: { color: 'primary' } },
                width: 'auto',
            },
            {
                header: {
                    label: 'Amount',
                    sortable: false,
                },
                cellRender: (item) => formatCurrency(item?.amount),
                format: { align: 'left', color: { color: 'primary' } },
                width: 'auto',
            },
            {
                header: {
                    label: 'Due date',
                    sortable: false,
                },
                cellRender: (item) => (
                    <Typography variant={'font14'}>
                        {item?.paymentDate}
                    </Typography>
                ),
                format: { align: 'left', color: { color: 'primary' } },
                width: 'auto',
            },
            {
                header: {
                    label: 'Status',
                    sortable: false,
                },
                cellRender: (item) => (
                    <>
                        <StatusTag {...paymentStatusMap[item?.status]} />
                    </>
                ),
                format: { align: 'left', color: { color: 'primary' } },
                width: 'auto',
            },
        ],
        expandable: false,
        handlePageChange: handlePageChange,
        handleItemsPerPageChange: handleItemsPerPageChange,
        initialPage: currentPage,
        initialPageSize: itemsPerPage,
    };
    return (
        <GenericTable
            data={data}
            tableConfig={paymentsPreviewConfig}
            totalCount={totalCount}
        />
    );
}
