import { useCallback, useState } from 'react';

export const useCenteredTree = (): [
    { x: number; y: number },
    (element: HTMLDivElement | null) => void,
] => {
    const [translate, setTranslate] = useState({ x: 0, y: 0 });
    const containerRef = useCallback((containerElem: HTMLDivElement | null) => {
        if (containerElem !== null) {
            const { width, height } = containerElem.getBoundingClientRect();
            setTranslate({ x: width / 2, y: height / 6 });
        }
    }, []);
    return [translate, containerRef];
};
