import { usePagination } from '../../design/GenericTable/useBackendPagination';
import { useSorting } from '../../design/GenericTable/useSorting';
import { useSettingsFromStorage } from '../../hooks/useSettingsFromStorage';
import { useSaveSettings } from '../../hooks/useSaveSettings';
import { TableColumn } from '../../design/GenericTable/GenericTable';
import { useGetPaymentPaidQuery } from '../../api/payment/paymentAPI';
import { PaymentScheduledData } from './interfaces';
import { dateConverterFromUTC } from '../../utils/dateFormat';
import { formatCurrency } from '../../utils/fieldMasks';
import BasedTooltip from '../../design/BasedTooltip';
import ExpandedPaymentPaid from './ExpandedPaymentPaid';

export function usePaymentOverviewPaid() {
    const { settings, saveSettings } = useSettingsFromStorage('PAYMENT_PAID');
    const { sort, page, sortDirection, size } = settings;
    const { sortKey, sortOrder, handleSort } = useSorting(
        sort || 'payee',
        sortDirection || 'asc',
    );
    const {
        currentPage,
        itemsPerPage,
        handlePageChange,
        handleItemsPerPageChange,
    } = usePagination(page || 1, size || 25);
    useSaveSettings({
        settings,
        saveSettings,
        sortKey,
        sortOrder,
        currentPage,
        itemsPerPage,
    });

    const { data, isLoading } = useGetPaymentPaidQuery({
        sort: `${sortKey},${sortOrder}`,
        page: currentPage - 1,
        size: itemsPerPage,
    });

    const columns: TableColumn<PaymentScheduledData>[] = [
        {
            header: {
                label: 'Payee Name',
                sortable: true,
                sorting: {
                    id: 'payee',
                    direction: sortKey === 'payee' ? sortOrder : 'desc',
                    isSorted: sortKey === 'payee',
                },
                onClick: handleSort,
            },
            cellRender: (data) => <BasedTooltip>{data?.payee}</BasedTooltip>,
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
        },
        {
            header: {
                label: 'Payment Date',
                sortable: true,
                sorting: {
                    id: 'paymentDate',
                    direction: sortKey === 'paymentDate' ? sortOrder : 'desc',
                    isSorted: sortKey === 'paymentDate',
                },
                onClick: handleSort,
            },
            cellRender: (data) => (
                <>
                    {data?.paymentDate &&
                        dateConverterFromUTC(data?.paymentDate)}
                </>
            ),
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
        },
        {
            header: {
                label: 'Rent Per Acre',
                sortable: false,
                sorting: {
                    id: 'rentPerAcre',
                    direction: sortKey === 'rentPerAcre' ? sortOrder : 'desc',
                    isSorted: sortKey === 'rentPerAcre',
                },
                onClick: handleSort,
            },
            cellRender: (data) => <>{formatCurrency(data?.rentPerAcre)}</>,
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
        },
        {
            header: {
                label: 'Total Acres',
                sortable: false,
            },
            cellRender: (data) => <>{data?.lease.totalAcres}</>,
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
        },
        {
            header: {
                label: 'Total Payment',
                sortable: true,
                sorting: {
                    id: 'total',
                    direction: sortKey === 'total' ? sortOrder : 'desc',
                    isSorted: sortKey === 'total',
                },
                onClick: handleSort,
            },
            cellRender: (data) => <>{formatCurrency(data?.paymentAmount)}</>,
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
        },
    ];
    const tableConfig = {
        columns,
        pageable: true,
        handlePageChange,
        handleItemsPerPageChange,
        initialPageSize: size,
        initialPage: page,
        expandable: true,
        ExpandComponent: ExpandedPaymentPaid,
    };
    const totalCount = data?.totalCount || 0;
    return { data: data?.data || [], totalCount, tableConfig, isLoading };
}
