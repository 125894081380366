import React from 'react';
import { Option } from '../../../features/CreateLeaseForm/WizardPageConfigs/landPartnersConfig';
import SelectField from '../../../design/Fields/SelectField';
import { Box, CircularProgress } from '@mui/material';

interface OwnProps {
    role?: string;
    setRole: (role: string) => void;
    options: Option[];
    isLoading: boolean;
    placeholder?: string;
}

export default function RolesDropdown({
    role,
    setRole,
    options,
    isLoading,
    placeholder,
}: OwnProps) {
    function handleChange(value: string) {
        setRole(value);
    }

    if (isLoading) {
        return <CircularProgress />;
    }
    return (
        <Box
            alignItems={'center'}
            display={'flex'}
            columnGap={2}
            sx={{ minWidth: '30%' }}>
            <SelectField
                size={'small'}
                onChange={handleChange}
                fullWidth={true}
                value={role || ''}
                placeholder={placeholder || 'Select Role'}
                options={options}
            />
        </Box>
    );
}
