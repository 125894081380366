import { matchPath, useLocation } from 'react-router-dom';
import { useMemo } from 'react';
import { BreadcrumbLinkProps } from '../design/Breadcrumbs/BreadcrumbLink';
import { ReactComponent as HomeIcon } from '../assets/icons/home_icon.svg';

interface OwnProps {
    routes: Record<string, string>;
    homePathName?: string;
    homePath?: string;
    dynamicName?: string;
}
export default function useBreadcrumbs({
    dynamicName,
    homePathName = 'Home',
    homePath = '/',
    routes,
}: OwnProps) {
    const { pathname } = useLocation();
    const pathnames = pathname.split('/').filter((x) => x);
    return useMemo(() => {
        const breadcrumbs: BreadcrumbLinkProps[] = [
            {
                name: homePathName,
                path: homePath,
                icon: <HomeIcon />,
            },
        ];
        pathnames.forEach((_, index) => {
            const url = `/${pathnames.slice(0, index + 1).join('/')}`;

            const matchedRoute = Object.keys(routes).find((path) =>
                matchPath({ path, end: true }, url),
            );

            if (matchedRoute) {
                const label =
                    isDynamicPartInTheEnd(matchedRoute) && dynamicName
                        ? dynamicName
                        : routes[matchedRoute];
                breadcrumbs.push({
                    name: label,
                    path: url,
                });
            }
        });

        return breadcrumbs;
    }, [pathname, routes, dynamicName]);
}

function isDynamicPartInTheEnd(path: string) {
    return path.split('/').pop()?.startsWith(':');
}
