import { getAccountLayoutsState } from '../api/users/accountAPI';

interface Permissions {
    [key: string]: {
        [action: string]: boolean;
    };
}

type PermissionsDomain =
    | 'landPartners'
    | 'settings'
    | 'contacts'
    | 'dashboard'
    | 'leases'
    | 'lpDashboardSharing'
    | 'notifications'
    | 'payments'
    | 'farmProfile'
    | 'farmlandManagerDashboard'
    | 'growers'
    | 'successionPlanerDashboard'
    | 'services'
    | 'fieldManagement'
    | 'insights';

type PermissionsAction = 'read' | 'modify';

export default function useManagePermission() {
    const permissions: Permissions = (getAccountLayoutsState({})?.data ??
        {}) as Permissions;

    const checkPermission = (
        domain: PermissionsDomain,
        action: PermissionsAction,
    ) => permissions[domain]?.[action] ?? false;

    return { permissions, checkPermission };
}
