import React from 'react';

import { Box } from '@mui/material';
import theme from '../../styles/theme';
import { LandPartnerTableView } from './LandPartnersTable';

interface ExpandComponentProps {
    data: LandPartnerTableView;
}

const ExpandComponent: React.FC<ExpandComponentProps> = ({
    data,
}: {
    data: LandPartnerTableView;
}) => {
    return (
        <Box
            sx={{
                display: 'flex',
                fontSize: 14,
                fontWeight: 500,
                bgccolor: theme.palette.neutral.neutral1,
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                p: 2,
                width: '100%',
            }}>
            <p>Land Partner Name: {data.name}</p>
            <p>
                Primary Contact: {data.primaryContact?.firstName}&nbsp;
                {data.primaryContact?.lastName}
            </p>
            {data?.billingAddress?.address1 && (
                <p>Address1: {data?.billingAddress?.address1}</p>
            )}
            {data?.billingAddress?.address2 && (
                <p>Address2: {data?.billingAddress?.address2}</p>
            )}
            {data?.phoneNumber && (
                <p>
                    <>Phone No: {data.phoneNumber}</>
                </p>
            )}
            {data?.primaryContact?.primaryEmail && (
                <p>Email: {data.primaryContact?.primaryEmail}</p>
            )}
        </Box>
    );
};

export default ExpandComponent;
