import React from 'react';
import { Box } from '@mui/material';
import FieldLabel from './FieldLabel';
import InfoTooltip, { GenericTooltip } from '../Tooltip/InfoTooltip';
import { LabelColor } from '../FormFields/GenericFormInput';

interface OwnProps {
    label?: string;
    tooltip?: GenericTooltip;
    isRequired?: boolean;
    labelColor?: LabelColor;
}

export default function FieldLabelWithTooltip({
    label,
    tooltip,
    isRequired,
    labelColor,
}: OwnProps) {
    return (
        <Box display={'flex'} gap={1}>
            {label && (
                <FieldLabel
                    label={label}
                    labelColor={labelColor}
                    isRequired={isRequired}
                />
            )}
            {tooltip && (
                <InfoTooltip text={tooltip.text} position={tooltip.position} />
            )}
        </Box>
    );
}
