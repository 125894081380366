import { Alert, AlertTitle, Box, Link, Typography } from '@mui/material';
import React from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import theme from '../../styles/theme';

export default function AlertUpgradeInfo() {
    return (
        <Alert severity="info" icon={<AlertIcon />}>
            <Box display={'flex'} gap={2} p={1} alignItems={'center'}>
                <AlertTitle>Ready to Upgrade?</AlertTitle>
                <Typography width="70%">
                    <Link href={'mailto:hopper@oaken.ag'} underline={'hover'}>
                        Upgrade today
                    </Link>{' '}
                    to unlock the complete capabilities and support Oaken
                    offers.
                </Typography>
            </Box>
        </Alert>
    );
}

function AlertIcon() {
    return (
        <Box
            display={'flex'}
            p={1}
            alignItems={'center'}
            bgcolor={theme.palette.statusBlue}>
            <InfoOutlinedIcon color={'secondary'} />
        </Box>
    );
}
