import clsx from 'clsx';
import { NavLink } from 'react-router-dom';
import { Box, Typography } from '@mui/material';

import useStyles from './SettingWidget.styles';
import theme from '../../../styles/theme';

export enum WidgetBackgroundColor {
    turq = 'turq',
    green = 'green',
    darkTurq = 'darkTurq',
    darkNeutral = 'darkNeutral',
}

export interface SettingsWidgetProps {
    icon: JSX.Element;
    title: string;
    info: string;
    path?: string;
    linkText: string;
    onClick?: () => void;
    isHidden?: boolean;
    color: WidgetBackgroundColor;
}

export default function SettingsWidget(props: SettingsWidgetProps) {
    const {
        icon,
        title,
        info,
        path,
        color = WidgetBackgroundColor.green,
        linkText,
        isHidden,
        onClick,
    } = props;
    const { classes } = useStyles(theme);
    const backgroundClass = {
        [WidgetBackgroundColor.turq]: classes.widgetTurqBackground,
        [WidgetBackgroundColor.darkTurq]: classes.widgetDarkTurqBackground,
        [WidgetBackgroundColor.green]: classes.widgetGreenBackground,
        [WidgetBackgroundColor.darkNeutral]:
            classes.widgetDarkNeutralBackground,
    };
    if (isHidden) return null;

    return (
        <div
            className={clsx(
                classes.settingsWidgetWrapper,
                backgroundClass[color],
            )}>
            <Box>{icon}</Box>
            <div className={classes.settingsWidgetContent}>
                <Typography className={classes.settingsWidgetTitle}>
                    {title}
                </Typography>
                <Typography className={classes.settingsWidgetInfo}>
                    {info}
                </Typography>
                {path && (
                    <NavLink to={path} className={classes.settingsWidgetLink}>
                        {linkText}
                    </NavLink>
                )}
                {!path && onClick && (
                    <Typography
                        className={classes.settingsWidgetLink}
                        onClick={onClick}>
                        {linkText}
                    </Typography>
                )}
            </div>
        </div>
    );
}
