import { ContactAvatar } from '../../../features/Notifications/NotificationCard';
import GenericTable, {
    Table,
    TableColumn,
} from '../../../design/GenericTable/GenericTable';
import { usePagination } from '../../../design/GenericTable/useBackendPagination';
import { useSorting } from '../../../design/GenericTable/useSorting';
import { Box, Grid, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import UserAvatar from '../../../design/Avatar/UserAvatar';
import {
    useGetAllContactsForGeneralManagerQuery,
    useLazyGetAllContactsForGeneralManagerQuery,
} from '../../../api/contacts/contactsAPI';
import React, { useEffect, useMemo } from 'react';
import useAvatar from '../../../hooks/useAvatar';
import LinkText from '../../../design/Fields/LinkText';
import PATHS from '../../../router/paths';
import { LandPartnerUpdate } from '../../../features/ViewLandownersDetails/LandPartnerDetails';
import Loader from '../../../design/BaseLoader';
import StatusTag from '../../../design/StatusTag/StatusTag';
import ShareDataSelectField, {
    Option,
    ShareDataOnChange,
} from './ShareDataSelectField';
import { ShareDataRequest } from './ManageLandPartnerSharing';
import SharedDataPreviewButton from '../../SharedData/Preview/SharedDataPreviewButton';
import customToastWithAlert from '../../../design/CustomToastWithAlert';

export interface LPSharingContact {
    id: string;
    contactId: string;
    firstName: string;
    lastName: string;
    landPartner: LandPartnerUpdate;
    otherLandPartners: LandPartnerUpdate[];
    firstLandPartner?: LandPartnerUpdate;
    primaryEmail: string;
    avatar?: ContactAvatar;
    status: Status;
    shareDataToken?: ShareDataTokenInfo;
}

type Status = 'ACTIVE' | 'SEND' | 'EXPIRED';

export interface ShareDataTokenInfo {
    tokenValue: string;
    tokenName: string;
    visibilityLevel: string;
    expiredAt: string;
    status: string;
}

interface OwnProps {
    isSelected: (items: LPSharingContact) => boolean;
    toggleSelection: (items: LPSharingContact) => void;
    toggleSelectionAll: (items: LPSharingContact[]) => void;
    isSelectedAll: (items: LPSharingContact[]) => boolean;
    selectedIds?: string[];
    shareDataRequest: ShareDataRequest;
    setShareDataRequest: (value: ShareDataRequest) => void;
}

export default function ManageSettingsTable({
    isSelected,
    toggleSelection,
    toggleSelectionAll,
    isSelectedAll,
    selectedIds,
    shareDataRequest,
    setShareDataRequest,
}: OwnProps) {
    const { createBlobUrl } = useAvatar();

    const {
        currentPage,
        itemsPerPage,
        handlePageChange,
        handleItemsPerPageChange,
    } = usePagination(1, 25);

    const { sortKey, sortOrder, handleSort } = useSorting(
        'firstName,lastName,landPartner.name',
        'asc',
    );

    const {
        data: initData,
        isLoading,
        isFetching,
    } = useGetAllContactsForGeneralManagerQuery({
        sort: `${sortKey},${sortOrder}`,
        page: currentPage - 1,
        size: itemsPerPage,
    });
    const [
        getItems,
        {
            data: sortedData,
            isLoading: isLazyLoading,
            isFetching: isLazyFetching,
        },
    ] = useLazyGetAllContactsForGeneralManagerQuery();

    useEffect(() => {
        getItems({
            sort: `${sortKey},${sortOrder}`,
            page: currentPage - 1,
            size: itemsPerPage,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sortKey, sortOrder, currentPage, itemsPerPage]);

    const totalCount = useMemo(() => {
        return sortedData?.totalCount || initData?.totalCount || 0;
    }, [sortedData, initData]);

    const data = useMemo(() => {
        return sortedData?.data || initData?.data || [];
    }, [sortedData, initData]);

    if (isLoading || isFetching || isLazyLoading || isLazyFetching) {
        return (
            <Grid
                container
                alignItems={'center'}
                justifyContent={'center'}
                flexDirection={'column'}>
                <Loader />
            </Grid>
        );
    }

    const findLandPartnerId = (id: string) => {
        const contact = data?.find(
            (contact: LPSharingContact) => contact.id === id,
        );
        return contact?.landPartner.id;
    };

    const findContactId = (id: string) => {
        const contact = data?.find(
            (contact: LPSharingContact) => contact.id === id,
        );
        return contact?.contactId;
    };

    if (
        shareDataRequest &&
        selectedIds &&
        shareDataRequest?.shareDataRequests.length > selectedIds?.length
    ) {
        // remove the deselected contacts
        const filteredRequests = shareDataRequest?.shareDataRequests.filter(
            (item) => selectedIds?.includes(item.id),
        );
        setShareDataRequest({
            shareDataRequests: filteredRequests || [],
        });
    }

    if (
        shareDataRequest &&
        selectedIds &&
        shareDataRequest?.shareDataRequests.length < selectedIds?.length
    ) {
        // add the selected contacts
        const initialRequestBody = selectedIds
            ?.filter(
                (id) =>
                    !shareDataRequest?.shareDataRequests
                        .map((item) => item.id)
                        .includes(id),
            )
            .map((id: string) => {
                const landPartnerId = findLandPartnerId(id);
                return {
                    id,
                    contactId: findContactId(id),
                    landPartnerId: landPartnerId ? landPartnerId : '',
                };
            });
        setShareDataRequest({
            shareDataRequests: [
                ...shareDataRequest.shareDataRequests,
                ...initialRequestBody,
            ],
        });
    }

    const onSharingTypeChange = (value: ShareDataOnChange) => {
        const findContactByRowId = shareDataRequest?.shareDataRequests?.find(
            (item) => item.id === value.rowId,
        );
        if (findContactByRowId) {
            findContactByRowId.visibilityLevel = value.value;
        }
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        setShareDataRequest((prevState: ShareDataRequest) => ({
            shareDataRequests: [
                ...prevState.shareDataRequests.filter(
                    (item) => item.id !== value.rowId,
                ),
                findContactByRowId,
            ],
        }));
    };

    const onDurationChange = (value: ShareDataOnChange) => {
        const findContactByRowId = shareDataRequest?.shareDataRequests?.find(
            (item) => item.id === value.rowId,
        );
        if (findContactByRowId) {
            findContactByRowId.period = value.value;
        }
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        setShareDataRequest((prevState: ShareDataRequest) => ({
            shareDataRequests: [
                ...prevState.shareDataRequests.filter(
                    (item) => item.id !== value.rowId,
                ),
                findContactByRowId,
            ],
        }));
    };

    const isContactSelected = (id: string) => {
        return selectedIds?.includes(id) || false;
    };

    const renderPreviewButton = (contact: LPSharingContact) => {
        const isSelected = isContactSelected(contact.id);
        if (isSelected || contact.shareDataToken) {
            const visibilityLevel = findVisibilityLevel(
                contact.id,
                shareDataRequest,
            );

            const handleClick = (
                event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>,
            ) => {
                if (isSelected && !visibilityLevel) {
                    event.preventDefault();
                    customToastWithAlert({
                        type: 'warning',
                        message:
                            'Please select a Sharing Type to preview Shared Data',
                    });
                }
            };

            return (
                <SharedDataPreviewButton
                    landPartnerName={contact.landPartner.name}
                    landPartnerId={contact.landPartner.id}
                    onClick={handleClick}
                    visibilityLevel={
                        visibilityLevel ||
                        contact.shareDataToken?.visibilityLevel ||
                        ''
                    }
                />
            );
        }
        return '';
    };

    const columns: TableColumn<LPSharingContact>[] = [
        {
            header: {
                label: 'Full Name',
                sortable: true,
                sorting: {
                    id: 'firstName,lastName',
                    direction:
                        sortKey === 'firstName,lastName' ? sortOrder : 'desc',
                    isSorted: sortKey === 'firstName,lastName',
                },
                onClick: handleSort,
            },
            cellRender: (item) => (
                <Typography
                    variant={'font14'}
                    color={'text.link'}
                    fontWeight={'medium'}>
                    <Box display={'flex'} columnGap={1} alignItems={'center'}>
                        <UserAvatar
                            name={`${item.firstName} ${item.lastName}`}
                            avatar={
                                item.avatar?.contactAvatar &&
                                item.avatar?.fileType &&
                                createBlobUrl(
                                    item.avatar?.contactAvatar,
                                    item.avatar?.fileType,
                                )
                            }
                            size={'small'}
                        />
                        <LinkText
                            to={`/${PATHS.landpartners}/${PATHS.allProfiles}/${item.contactId}`}
                            text={`${item.firstName} ${item.lastName}`}
                            variant={'font14'}
                        />
                    </Box>
                </Typography>
            ),
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
        },
        {
            header: {
                label: 'Land Partner',
                sortable: true,
                sorting: {
                    id: 'landPartner.name',
                    direction:
                        sortKey === 'landPartner.name' ? sortOrder : 'desc',
                    isSorted: sortKey === 'landPartner.name',
                },
                onClick: handleSort,
            },
            cellRender: (item) => showLandPartner(item?.landPartner),
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
        },
        {
            header: {
                label: 'Email',
                // eslint-disable-next-line max-lines
                sortable: true,
                sorting: {
                    id: 'primaryEmail',
                    direction: sortKey === 'primaryEmail' ? sortOrder : 'desc',
                    isSorted: sortKey === 'primaryEmail',
                },
                onClick: handleSort,
            },
            cellRender: (item) => (
                <Link to={`mailto:${item.primaryEmail}`}>
                    <Typography
                        variant={'font14'}
                        color={'text.link'}
                        fontWeight={'medium'}>
                        {item.primaryEmail}
                    </Typography>
                </Link>
            ),
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
        },

        {
            header: {
                label: 'Status',
                sortable: true,
                sorting: {
                    // eslint-disable-next-line max-lines
                    id: 'shareDataToken.status',
                    direction:
                        sortKey === 'shareDataToken.status'
                            ? sortOrder
                            : 'desc',
                    isSorted: sortKey === 'shareDataToken.status',
                },
                onClick: handleSort,
            },

            cellRender: (contact) =>
                !isContactSelected(contact.id) && (
                    <Typography
                        variant={'font14'}
                        color={'text.link'}
                        fontWeight={'medium'}>
                        {statusOptions.find(
                            (i) => i.id === contact?.shareDataToken?.status,
                        )?.name || ''}
                    </Typography>
                ),
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
        },
        {
            header: {
                label: 'Sharing Type',
                sortable: true,
                sorting: {
                    id: 'shareDataToken.visibilityLevel',
                    direction:
                        sortKey === 'shareDataToken.visibilityLevel'
                            ? sortOrder
                            : 'desc',
                    isSorted: sortKey === 'shareDataToken.visibilityLevel',
                },
                onClick: handleSort,
            },
            cellRender: (contact: LPSharingContact) =>
                showSharingType(
                    contact,
                    onSharingTypeChange,
                    !selectedIds?.includes(contact.id),
                    isContactSelected(contact.id),
                ),
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
        },
        {
            header: {
                label: 'Duration',
                sortable: true,
                sorting: {
                    id: 'shareDataToken.expiredAt',
                    direction:
                        sortKey === 'shareDataToken.expiredAt'
                            ? sortOrder
                            : 'desc',
                    isSorted: sortKey === 'shareDataToken.expiredAt',
                },
                onClick: handleSort,
            },
            cellRender: (contact: LPSharingContact) =>
                showDuration(
                    contact,
                    onDurationChange,
                    !selectedIds?.includes(contact.id),
                    isContactSelected(contact.id),
                ),
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
        },
        {
            header: {
                label: 'Preview',
                sortable: false,
            },
            cellRender: (contact) => renderPreviewButton(contact),
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
        },
    ];
    const tableConfig: Table<LPSharingContact> = {
        columns: columns,
        multiselect: true,
        expandable: false,
        isSelected: isSelected,
        toggleSelection: toggleSelection,
        toggleSelectionAll: toggleSelectionAll,
        isSelectedAll: isSelectedAll,
        handlePageChange: handlePageChange,
        handleItemsPerPageChange: handleItemsPerPageChange,
        initialPage: currentPage,
        initialPageSize: itemsPerPage,
    };

    return (
        <>
            <GenericTable
                data={data}
                tableConfig={tableConfig}
                totalCount={totalCount}
            />
        </>
    );
}

const sharingTypeOptions: Option[] = [
    { id: 'METRICS_ONLY', name: 'Metrics Only' },
    { id: 'ALL', name: 'Leases, Documents, Payments' },
];

const durationOptions: Option[] = [
    { id: 'ONE_DAY', name: '24 hours' },
    { id: 'THREE_DAYS', name: '72 hours' },
    { id: 'PERMANENT', name: 'Permanent' },
];

const statusOptions: Option[] = [
    { id: 'ACTIVE', name: 'Active' },
    { id: 'SENT', name: 'Sent' },
    { id: 'EXPIRED', name: 'Expired' },
];

const showLandPartner = (landPartner: LandPartnerUpdate | undefined) => {
    if (!landPartner) {
        return '';
    }
    return (
        <Box display={'flex'} alignItems={'center'} gap={1}>
            <LinkText
                to={`/${PATHS.landpartners}/${PATHS.profiles}/${landPartner.id}`}
                text={landPartner.name}
                sx={{ textAlign: 'center' }}
                variant={'font14'}
            />
        </Box>
    );
};

const showDuration = (
    contact: LPSharingContact,
    onChange: (value: ShareDataOnChange) => void,
    shouldResetOptions: boolean | undefined,
    isContactSelected: boolean,
    // eslint-disable-next-line max-params
) => {
    if (isContactSelected) {
        return (
            <ShareDataSelectField
                options={durationOptions}
                onChange={onChange}
                reset={shouldResetOptions || false}
                isHidden={!isContactSelected}
                rowId={contact.id}
            />
        );
    }

    if (!contact.shareDataToken) {
        return '';
    }

    const expiredAt = contact.shareDataToken.expiredAt;
    const expiredAtDate: '' | undefined | Date =
        expiredAt && new Date(expiredAt);
    const now = new Date();
    if (!expiredAtDate) {
        return <StatusTag status={'info'} text={'Permanent'} />;
    }
    if (expiredAtDate > now) {
        const diffTime = Math.abs(expiredAtDate.getTime() - now.getTime());
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        if (expiredAtDate.getDay() === now.getDay()) {
            return <StatusTag status={'warning'} text={'Today'} />;
        }
        return (
            <StatusTag
                status={'info'}
                text={`${diffDays} ${diffDays === 1 ? 'day' : 'days'} left`}
            />
        );
    }
    return <StatusTag status={'error'} text={'Expired'} />;
};

const showSharingType = (
    contact: LPSharingContact,
    onChange: (value: ShareDataOnChange) => void,
    shouldResetOptions: boolean | undefined,
    isContactSelected: boolean,
    // eslint-disable-next-line max-params
) => {
    if (isContactSelected) {
        return (
            <ShareDataSelectField
                options={sharingTypeOptions}
                onChange={onChange}
                reset={shouldResetOptions || false}
                isHidden={!isContactSelected}
                rowId={contact.id}
            />
        );
    }

    if (!contact.shareDataToken) {
        return '';
    }

    return (
        <Typography
            variant={'font14'}
            color={'text.link'}
            fontWeight={'medium'}>
            {sharingTypeOptions.find(
                (i) => i.id === contact.shareDataToken?.visibilityLevel,
            )?.name || ''}
        </Typography>
    );
};

const findVisibilityLevel = (
    id: string,
    shareDataRequest: ShareDataRequest,
) => {
    // Find the contact in the shareDataRequest by matching the id
    const contact = shareDataRequest?.shareDataRequests.find(
        (item) => item.id === id,
    );

    // If contact is found, return the visibilityLevel, otherwise return undefined
    return contact?.visibilityLevel;
};
