import { useEffect, useState } from 'react';
import { createDownloadLinkFromBlob } from '../utils/fileHandler';
import { useLazyDownloadDocumentCustomTokenQuery } from '../api/documents/documentsAPI';
import { GUEST_KEY } from '../constant/localStorageKeys';

export function useDownloadDocumentsGuest() {
    const [isShowing, setIsShowing] = useState(false);
    const [file, setFile] = useState<string | null>(null);
    const [
        getItems,
        { data: responsePromise, isLoading: isDocumentLoading, isFetching },
    ] = useLazyDownloadDocumentCustomTokenQuery();
    const handleClose = () => {
        setIsShowing(false);
    };
    const token = localStorage.getItem(GUEST_KEY) || null;

    useEffect(() => {
        if (!responsePromise) return;
        const { data }: { data: Blob; headers: string } = responsePromise;
        const fileData = data && URL.createObjectURL(data);
        fileData && setFile(fileData);
    }, [responsePromise]);

    const handlePreviewOpen = (documentId: string) => {
        getItems({ documentId, token });
        setIsShowing(true);
    };
    const handleDownload = (documentId: string, fileName: string) => {
        getItems({ documentId, token })
            .unwrap()
            .then(({ data }) => {
                createDownloadLinkFromBlob(data, fileName);
            });
    };
    const extension = (fileName: string) => fileName.split('.').pop();
    const isLoading = isDocumentLoading || isFetching;
    return {
        isShowing,
        file,
        isLoading,
        handleClose,
        handlePreviewOpen,
        handleDownload,
        extension,
    };
}
