import { Field, useFormState } from 'react-final-form';
import { Box, Grid } from '@mui/material';
import EmergencyContactCard from './EmergencyContactCard';

export default function EmergencyContactCardList() {
    const { values } = useFormState();
    const emergencyContacts = values?.emergencyContacts || [];

    return (
        <Box width={'100%'} pt={2}>
            <Field
                name={'emergencyContactsGroup'}
                render={({ input }) => (
                    <Grid container spacing={2}>
                        {emergencyContacts.map(
                            (
                                contact: // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                any,
                                index: number,
                            ) => (
                                <Grid key={index} item xs={6}>
                                    <EmergencyContactCard
                                        id={contact.id}
                                        input={input}
                                    />
                                </Grid>
                            ),
                        )}
                    </Grid>
                )}
            />
        </Box>
    );
}
