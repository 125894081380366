import FormScreenWrapper from './FormScreenWrapper';
import { Box } from '@mui/material';
import AppTypography from '../../design/AppTypography';
import { FormConfig } from '../../design/Forms/interfaces';
import GenericArrayField, { Config } from './GenericArrayField';
import { composeValidators } from '../../utils/validation';
import {
    invalidFileType,
    tooLargeFile,
    tooManyFiles,
} from '../../utils/fileValidation';
import { MAX_FILE_SIZE } from '../../constants';
import FormPage from '../../reusable/FormUI/FormPage';

export default function CropProtectionForm() {
    return (
        <FormScreenWrapper title="Crop Protection">
            <Box display={'flex'} flexDirection={'column'} rowGap={2}>
                <AppTypography
                    variant="font16"
                    fontWeight={'medium'}
                    text={
                        'What measures can be taken to protect crops from various types of disasters?'
                    }
                    color={'text.neutral9'}
                />
                <GenericArrayField
                    fieldName={'measuresToTake'}
                    config={({ name }: Config) =>
                        measuresToTakeConfig({ name })
                    }
                    addButtonLabel={'Add another crop'}
                />
                <AppTypography
                    variant="font16"
                    fontWeight={'medium'}
                    text={'Crop Insurance Information'}
                    color={'text.neutral9'}
                />
                <FormPage formConfig={insuranceFormConfig} />
            </Box>
        </FormScreenWrapper>
    );
}

function measuresToTakeConfig({ name }: Config): FormConfig[] {
    return [
        {
            formField: {
                scheme: 'third',
                name: `cropProtection.${name}.crop`,
                type: 'select',
                label: 'Crop',
            },
            renderProps: {
                placeholder: 'Select from list',
                isDisabled: false,
                size: 'small',
                options: [],
            },
        },
        {
            formField: {
                scheme: 'third',
                name: `cropProtection.${name}.protectionMeasures`,
                type: 'select',
                label: 'Protection',
            },
            renderProps: {
                placeholder: 'Select from list',
                isDisabled: false,
                size: 'small',
                options: [],
            },
        },
        {
            formField: {
                scheme: 'third',
                name: `cropProtection.${name}.field`,
                type: 'input',
                label: 'Field',
            },
            renderProps: {
                isDisabled: false,
                size: 'small',
            },
        },
    ];
}
const insuranceFormConfig: FormConfig[] = [
    {
        formField: {
            scheme: 'half',
            name: 'cropProtection.insuranceFile',
            type: 'fileupload',
            validation: composeValidators(
                tooLargeFile(MAX_FILE_SIZE),
                tooManyFiles(1),
                invalidFileType([
                    'image/png',
                    'image/jpg',
                    'image/jpeg',
                    'image/bmp',
                    'application/pdf',
                ]),
            ),
        },
        renderProps: {
            filesAccepted: ['.png', '.jpg', '.jpeg', '.bmp', '.pdf'],
            placeholder: 'Or select from your computer',
            helperText: 'Add your policy by dragging it here',
            multipleFiles: false,
            isDisabled: false,
        },
    },
];
