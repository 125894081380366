import { makeStyles } from 'tss-react/mui';
import { Box, Typography } from '@mui/material';
import theme from '../../styles/theme';
import { ReactComponent as WhiteLogo } from '../../assets/icons/logo_white.svg';
import AlertUpgradeInfo from './AlertUpgradeInfo';

export default function TrialBanner() {
    const { classes } = useStyles();
    return (
        <Box className={classes.background}>
            <Box
                width={'100%'}
                p={theme.spacing(3, 2)}
                display={'flex'}
                alignItems={'center'}
                gap={8}
                zIndex={3}>
                <Typography variant="font24" color={'text.neutralWhite'}>
                    Oaken Trial Account
                </Typography>
                <AlertUpgradeInfo />
            </Box>
            <div className={classes.backgroundLogo}>
                <WhiteLogo />
            </div>
        </Box>
    );
}

const useStyles = makeStyles()(() => ({
    background: {
        display: 'flex',
        width: `100%`,
        height: 105,
        backgroundImage: `linear-gradient(138deg, #2f7f8f 40%, #002f3f 54%)`,
    },
    backgroundLogo: {
        position: 'absolute',
        right: 0,
        top: 0,
        zIndex: 1,
    },
}));
