import React, { ReactNode } from 'react';
import MaskedInput, { Mask } from 'react-text-mask';
import InputField from './InputField';

export interface Input {
    onChange: (value: number | string) => void;
    //eslint-disable-next-line
    onBlur?: (e: any) => void;
    value?: string;
    placeholder?: string;
    isDisabled?: boolean;
    isValidationError?: boolean;
    errorText?: string;
    size?: 'small' | 'medium';
    postfix?: ReactNode | string;
    isHidden?: boolean;
    fieldType?: string;
    mask?: Mask | ((value: string) => Mask);
}

export interface MaskedInputProps {
    mask: Mask | ((value: string) => Mask);
}

export default function MaskedInputField({
    mask,
    ...props
}: MaskedInputProps & Input) {
    return (
        <MaskedInput
            guide={true}
            showMask={false}
            mask={mask}
            render={(ref, maskedProps) => {
                return <InputField ref={ref} {...maskedProps} {...props} />;
            }}
        />
    );
}
