import { get } from 'lodash';

export function getLodashValue(
    // eslint-disable-next-line
    obj: any,
    path: string,
    defaultValue?: never,
) {
    return get(obj, path, defaultValue);
}
