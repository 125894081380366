import React, { useEffect } from 'react';
import useStyles from './SignIn.styles';
import theme from '../../styles/theme';
import { ReactComponent as Logo } from '../../assets/icons/logo2.svg';

import loginImage from '../../assets/images/loginPicture.png';

import { NavLink, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Box, Grid, Typography } from '@mui/material';
import { useValidateTokenMutation } from '../../api/auth/authAPI';
import useHandleSubmitRTK from '../../hooks/useHandleSubmit';
import PATHS from '../../router/paths';
import Loader from '../../design/BaseLoader';

export default function SignIn() {
    const { pathname, search } = useLocation();
    const navigate = useNavigate();
    const token = new URLSearchParams(search).get('token');
    const { classes } = useStyles(theme);
    const currentYear = new Date().getFullYear();
    const {
        handleSubmit: handleSubmit,
        isLoading,
        data,
        error,
    } = useHandleSubmitRTK({
        useRtkHook: useValidateTokenMutation,
        isShowSuccessToast: false,
        isShowErrorToast: false,
        onSuccess: () =>
            data?.token !== token && navigate(`/${PATHS.auth}/${PATHS.logIn}`),
        onError: () =>
            error?.status !== 400
                ? navigate(`/${PATHS.auth}/${PATHS.expiredLink}`)
                : navigate(`/${PATHS.auth}/${PATHS.logIn}`),
    });

    useEffect(() => {
        if (pathname === `/${PATHS.auth}/${PATHS.userActivation}`) {
            handleSubmit({ token });
        }
    }, [pathname]);

    if (isLoading)
        return (
            <Grid container justifyContent={'center'}>
                <Loader />
            </Grid>
        );

    return (
        <div className={classes.signInWrapper}>
            <div className={classes.mainContainer}>
                <Logo className={classes.logo} />
                <div className={classes.formContainer}>
                    <Outlet />
                </div>
                <Box
                    pt={10}
                    pb={5}
                    display={'flex'}
                    width={'100%'}
                    justifyContent={'space-between'}>
                    <Typography variant={'font12'} color={'text.neutral6'}>
                        © {currentYear} OAKEN, INC. ALL RIGHTS RESERVED.
                    </Typography>
                    <NavLink
                        target={'_blank'}
                        to={'https://www.oaken.ag/end-user-terms-1'}
                        className={classes.link}>
                        PRIVACY POLICY
                    </NavLink>
                </Box>
            </div>
            <div className={classes.imageContainer}>
                <img
                    className={classes.loginImage}
                    src={loginImage}
                    alt={'background'}
                />
            </div>
        </div>
    );
}
