import FormScreenWrapper from './FormScreenWrapper';
import { Box } from '@mui/material';
import AppTypography from '../../design/AppTypography';
import { FormConfig } from '../../design/Forms/interfaces';
import FormPage from '../../reusable/FormUI/FormPage';

export default function RecoveryProceduresForm() {
    return (
        <FormScreenWrapper title="Recovery Procedures">
            <Box display={'flex'} flexDirection={'column'} rowGap={2}>
                <AppTypography
                    variant="font16"
                    fontWeight={'medium'}
                    text={
                        'What are the steps to assess damage after a disaster?'
                    }
                    color={'text.neutral9'}
                />
                <FormPage formConfig={recoveryPlanConfig} />
                <AppTypography
                    variant="font16"
                    fontWeight={'medium'}
                    text={
                        'Who will be responsible for coordinating recovery efforts?'
                    }
                    color={'text.neutral9'}
                />
                <FormPage formConfig={recoveryCoordinatorsConfig} />
            </Box>
        </FormScreenWrapper>
    );
}

const recoveryPlanConfig: FormConfig[] = [
    {
        formField: {
            scheme: 'full',
            name: 'recoveryProcedures.recoveryPlan',
            type: 'textarea',
            label: '',
        },
        renderProps: {
            resize: 'none',
            isDisabled: false,
            minRows: 3,
        },
    },
];

const recoveryCoordinatorsConfig: FormConfig[] = [
    {
        formField: {
            scheme: 'full',
            name: 'recoveryProcedures.recoveryCoordinators',
            type: 'autocomplete',
            label: '',
        },
        renderProps: {
            isDisabled: false,
            options: [],
        },
    },
];
