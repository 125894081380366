import React from 'react';

import { Box } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import clsx from 'clsx';

import palette from '../../styles/palette';
import { ReactComponent as DoneIcon } from '../../assets/icons/done_icon.svg';
interface OwnProps {
    active: boolean;
    completed: boolean;
}
export default function CustomStepIcon({ active, completed }: OwnProps) {
    const { classes } = useStyles();
    const isDisabled = !active && !completed;
    return (
        <Box
            className={clsx(
                classes.outerRadius,
                completed && classes.completeOuterRadius,
                active && classes.activeOuterRadius,
                isDisabled && classes.disabledOuterRadius,
            )}>
            <Box
                className={clsx(
                    classes.innerEllipse,
                    completed && classes.completedEllipse,
                    active && classes.activeEllipse,
                    isDisabled && classes.disabledEllipse,
                )}>
                {completed && <DoneIcon className={classes.doneIcon} />}
            </Box>
        </Box>
    );
}

const useStyles = makeStyles()((theme) => ({
    stepperContainer: {
        margin: theme.spacing(4),
        padding: theme.spacing(4),
        backgroundColor: theme.palette.backgroundB.backgroundB2,
        borderRadius: theme.spacing(2),
    },

    stepLabel: {
        '& .MuiStepLabel-label': {
            fontSize: 20,
            fontWeight: 600,
        },
    },
    outerRadius: {
        borderRadius: '50%',
    },
    innerEllipse: {
        width: 22,
        height: 22,
        textAlign: 'center',
        borderRadius: '50%',
    },
    doneIcon: {
        width: 14,
    },
    disabledEllipse: {
        backgroundColor: `${palette.neutral.neutral4}`,
    },
    activeEllipse: {
        backgroundColor: `${palette.limeGreen.limeGreenTint2}`,
        border: `3px solid ${palette.brandDarkTurq}`,
    },
    completedEllipse: {
        backgroundColor: `${palette.brandDarkTurq}`,
    },
    completeOuterRadius: {
        border: `3px solid ${palette.lightTurq.lightTurqTint6}`,
    },
    activeOuterRadius: {
        border: `3px solid ${palette.brandLightTurq}`,
    },
    disabledOuterRadius: {
        border: `3px solid ${palette.neutral.neutral2}`,
    },
}));
