import React from 'react';

import { Grid, Typography } from '@mui/material';
import theme from '../../styles/theme';
import { Notification } from './NotificationCard';
import { dateTimeConverterFromUTC } from '../../utils/dateFormat';

interface ExpandComponentProps {
    data: Notification;
}

export default function ExpandComponent({ data }: ExpandComponentProps) {
    return (
        <Grid
            container
            justifyContent={'space-between'}
            width={'100%'}
            xs={12}
            sx={{
                padding: theme.spacing(2, 3),
                backgroundColor: theme.palette.backgroundB.backgroundB2,
            }}>
            {/*<Grid xs={2} item display={'flex'} flexDirection={'column'}>*/}
            {/*    <Typography*/}
            {/*        variant={'font14'}*/}
            {/*        fontWeight={'medium'}*/}
            {/*        color={'text.secondary'}>*/}
            {/*        CREATED BY*/}
            {/*    </Typography>*/}
            {/*    <Typography variant={'font14'} color={'text.neutral6'}>*/}
            {/*        {data.createdBy}*/}
            {/*    </Typography>*/}
            {/*</Grid>*/}
            <Grid xs={2} item display={'flex'} flexDirection={'column'}>
                <Typography
                    variant={'font14'}
                    fontWeight={'medium'}
                    color={'text.secondary'}>
                    DATE
                </Typography>

                <Typography variant={'font14'} color={'text.neutral6'}>
                    {dateTimeConverterFromUTC(data.createdAt)}
                </Typography>
            </Grid>
            {/*<Grid xs={2} item display={'flex'} flexDirection={'column'}>*/}
            {/*    <Typography*/}
            {/*        variant={'font14'}*/}
            {/*        fontWeight={'medium'}*/}
            {/*        color={'text.secondary'}>*/}
            {/*        RELATED TO*/}
            {/*    </Typography>*/}

            {/*    <Typography variant={'font14'} color={'text.neutral6'}>*/}
            {/*        {data.relatedTo}*/}
            {/*    </Typography>*/}
            {/*</Grid>*/}
            {/*<Grid xs={6} item display={'flex'} flexDirection={'column'}>*/}
            {/*    <Typography*/}
            {/*        variant={'font14'}*/}
            {/*        fontWeight={'medium'}*/}
            {/*        color={'text.secondary'}>*/}
            {/*        NOTES*/}
            {/*    </Typography>*/}

            {/*    <Typography variant={'font14'} color={'text.neutral6'}>*/}
            {/*        {data.notes}*/}
            {/*    </Typography>*/}
            {/*</Grid>*/}
        </Grid>
    );
}
