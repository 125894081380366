import { FormConfig } from '../../../design/Forms/interfaces';
import { composeValidators, maxLength } from '../../../utils/validation';

export const socialMediaConfig: FormConfig[] = [
    {
        formField: {
            scheme: 'half',
            name: 'socialMedia.linkedin',
            type: 'input',
            label: 'LinkedIn Profile',
            validation: composeValidators(maxLength(256)),
        },
        renderProps: {
            placeholder: 'None',
            isDisabled: false,
            size: 'small',
        },
    },
    {
        formField: {
            scheme: 'half',
            name: 'socialMedia.twitter',
            type: 'input',
            label: 'Twitter / X Profile',
            validation: composeValidators(maxLength(256)),
        },
        renderProps: {
            placeholder: 'None',
            isDisabled: false,
            size: 'small',
        },
    },
    {
        formField: {
            scheme: 'half',
            name: 'socialMedia.facebook',
            type: 'input',
            label: 'Facebook Profile',
            validation: composeValidators(maxLength(256)),
        },
        renderProps: {
            placeholder: 'None',
            isDisabled: false,
            size: 'small',
        },
    },
    {
        formField: {
            scheme: 'half',
            name: 'socialMedia.instagram',
            type: 'input',
            label: 'Instagram',
            validation: composeValidators(maxLength(256)),
        },
        renderProps: {
            placeholder: 'None',
            isDisabled: false,
            size: 'small',
        },
    },
];
