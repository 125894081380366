import { makeStyles } from 'tss-react/mui';
import {
    HeaderHeight,
    HeaderWithBannerHeight,
    NavigationWidth,
} from '../../styles/constants';

const useStyles = makeStyles<boolean>()((theme, isTrial) => ({
    pageWrapper: {
        display: 'flex',
    },
    containerWrapper: {
        width: `calc(100vw - ${NavigationWidth})`,
        height: `calc(100vh - ${
            isTrial ? HeaderWithBannerHeight : HeaderHeight
        })`,
        padding: theme.spacing(3),
        backgroundColor: theme.palette.neutral.neutral1,
        overflowY: 'auto',
    },
    loader: {
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
    },
    contentWrapper: {
        flex: 1,
    },
}));

export default useStyles;
