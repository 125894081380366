import { TableColumn } from '../../../design/GenericTable/GenericTable';
import { approachingYearlyCheckupsMockData } from './approachingYearlyCheckupsMock';
import customToastWithAlert from '../../../design/CustomToastWithAlert';
import { Button } from '@mui/material';
import React from 'react';
import CheckupDateCell from './CheckupDateCell';

export interface ApproachingYearlyCheckups {
    id: string;
    name: string;
    phone: string;
    solution: string;
    completedOnDate: string;
    checkupDate: string;
}

export default function useApproachingYearlyCheckupsTable() {
    function renderSummaryButton() {
        return (
            <Button
                onClick={() =>
                    customToastWithAlert({
                        type: 'error',
                        message: 'This feature is not available yet',
                    })
                }
                sx={{ textDecoration: 'underline' }}>
                Summary
            </Button>
        );
    }

    const columns: TableColumn<ApproachingYearlyCheckups>[] = [
        {
            header: {
                label: 'Name',
                sortable: false,
            },
            cellRender: (data) => <>{data?.name}</>,
            format: { align: 'left', color: { color: 'primary' } },
            width: '20%',
        },
        {
            header: {
                label: 'Phone',
                sortable: false,
            },
            cellRender: (data) => <>{data?.phone}</>,
            format: { align: 'left', color: { color: 'primary' } },
            width: '19%',
        },
        {
            header: {
                label: 'Solution',
                sortable: false,
            },
            cellRender: (data) => <>{data?.solution}</>,
            format: { align: 'left', color: { color: 'primary' } },
            width: '16%',
        },
        {
            header: {
                label: 'Completed On',
                sortable: false,
            },
            cellRender: (data) => <>{data?.completedOnDate}</>,
            format: { align: 'left', color: { color: 'primary' } },
            width: '19%',
        },
        {
            header: {
                label: 'Checkup Date',
                sortable: false,
            },
            cellRender: (data) =>
                data?.checkupDate && (
                    <CheckupDateCell checkupDate={data.checkupDate} />
                ),
            format: { align: 'left', color: { color: 'primary' } },
            width: '16%',
        },
        {
            cellRender: renderSummaryButton,
            format: { align: 'left', color: { color: 'primary' } },
            width: '10%',
        },
    ];
    const tableConfig = {
        columns,
        pageable: false,
        expandable: false,
    };
    return { data: approachingYearlyCheckupsMockData || [], tableConfig };
}
