import React, { useState } from 'react';
import { Badge, Button, MenuItem, Menu, Box } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Option } from '../CreateLeaseForm/WizardPageConfigs/leaseUploadConfig';

interface OwnProps {
    options: Option[];
    setSelectedOption: (option: Option) => void;
}
export default function ContactLandPartnerTiles({
    options = [],
    setSelectedOption,
}: OwnProps) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleSelectOption = (option: Option) => {
        setSelectedOption(option);
        handleClose();
    };

    return (
        <Box minWidth={275}>
            <Badge badgeContent={options.length} color="error">
                <Button
                    fullWidth
                    aria-haspopup="true"
                    variant={'contained'}
                    size={'medium'}
                    onClick={handleClick}
                    endIcon={<KeyboardArrowDownIcon />}>
                    Affiliated Land Partners
                </Button>
            </Badge>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}>
                {options.map((option, index) => (
                    <MenuItem
                        key={index}
                        onClick={() => handleSelectOption(option)}>
                        {option.name}
                    </MenuItem>
                ))}
            </Menu>
        </Box>
    );
}
