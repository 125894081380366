import { Grid } from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import { useSorting } from '../../../../design/GenericTable/useSorting';
import { usePagination } from '../../../../design/GenericTable/useBackendPagination';
import Loader from '../../../../design/BaseLoader';
import SharedPaymentsAllTable from '../../TemporaryAccess/Payment/SharedPaymentsAllTable';
import {
    useGetSharedPaymentsQuery,
    useLazyGetSharedPaymentsQuery,
} from '../../../../api/sharedData/sharedDataPermanentAccessAPI';

export default function SharedPaymentsPage() {
    const { sortKey, sortOrder, handleSort } = useSorting('leaseName', 'asc');

    const {
        currentPage,
        itemsPerPage,
        handlePageChange,
        handleItemsPerPageChange,
    } = usePagination(1, 25);

    const {
        data: payments,
        isLoading,
        isFetching,
    } = useGetSharedPaymentsQuery({
        sort: `${sortKey},${sortOrder}`,
        page: currentPage - 1,
        size: itemsPerPage,
    });

    const [
        getItems,
        {
            isFetching: isFetchingLazy,
            isLoading: isLoadingLazy,
            data: sortedPayments,
        },
    ] = useLazyGetSharedPaymentsQuery();

    useEffect(() => {
        getItems({
            sort: `${sortKey},${sortOrder}`,
            page: currentPage - 1,
            size: itemsPerPage,
        });
    }, [sortKey, sortOrder, currentPage, itemsPerPage]);

    const totalCount = useMemo(() => {
        return sortedPayments?.totalCount || payments?.totalCount || 0;
    }, [sortedPayments, payments]);

    const data = useMemo(() => {
        return sortedPayments?.data || payments?.data || [];
    }, [sortedPayments, payments]);

    if (isLoading || isFetching || isFetchingLazy || isLoadingLazy) {
        return (
            <Grid
                container
                alignItems={'center'}
                justifyContent={'center'}
                flexDirection={'column'}>
                <Loader />
            </Grid>
        );
    }
    return (
        <SharedPaymentsAllTable
            data={data}
            totalCount={totalCount}
            handlePageChange={handlePageChange}
            handleItemsPerPageChange={handleItemsPerPageChange}
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
            sortKey={sortKey}
            sortOrder={sortOrder}
            handleSort={handleSort}
        />
    );
}
