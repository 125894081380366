import { useState } from 'react';

export interface Settings {
    sort: string;
    sortDirection: 'asc' | 'desc';
    page: number;
    size: number;
}

export function useSettingsFromStorage(key: string) {
    const [settings, setSettings] = useState<Settings>(() => {
        const settingsString = localStorage.getItem(key);
        return settingsString ? JSON.parse(settingsString) : ({} as Settings);
    });

    const saveSettings = (newSettings: Settings) => {
        localStorage.setItem(key, JSON.stringify(newSettings));
        setSettings(newSettings);
    };

    return { settings, saveSettings };
}
