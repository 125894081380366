import { Alert, AlertTitle, Box, Typography } from '@mui/material';
import React from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import theme from '../../../styles/theme';

export default function SharedDataBannerAlert() {
    return (
        <Alert
            severity="info"
            icon={<AlertIcon />}
            style={{ padding: '4px 8px', width: '600px', marginRight: '40px' }}>
            <Box display={'flex'} gap={1} alignItems={'center'}>
                <AlertTitle
                    style={{
                        marginBottom: 0,
                        fontSize: '12px',
                        width: '200px',
                    }}>
                    Dashboard Preview
                </AlertTitle>
                <Typography style={{ marginBottom: 0, fontSize: '12px' }}>
                    A live preview of the information a Land Partner will see
                    based on provided settings
                </Typography>
            </Box>
        </Alert>
    );
}

function AlertIcon() {
    return (
        <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
            bgcolor={theme.palette.statusBlue}
            style={{ padding: 4 }}>
            <InfoOutlinedIcon color={'secondary'} fontSize="small" />
        </Box>
    );
}
