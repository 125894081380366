import React from 'react';
import { Grid, Typography } from '@mui/material';
import theme from '../../styles/theme';
import { FSA } from './LeaseDetailForm';

interface OwnProps {
    data: FSA;
}

export default function ExpandedLeaseDetails({ data }: OwnProps) {
    const { landName, description } = data;
    return (
        <Grid xs={12} container columnSpacing={2}>
            <Grid
                item
                xs={6}
                display={'flex'}
                flexDirection={'column'}
                spacing={2}
                sx={{
                    padding: theme.spacing(2, 3),
                    backgroundColor: theme.palette.backgroundB.backgroundB2,
                }}>
                {landName && (
                    <>
                        <Typography
                            variant={'font14'}
                            fontWeight={'medium'}
                            color={'text.secondary'}>
                            LAND NAME
                        </Typography>
                        <Typography variant={'font14'} color={'text.neutral6'}>
                            {landName}
                        </Typography>
                    </>
                )}
            </Grid>
            <Grid
                item
                xs={6}
                display={'flex'}
                flexDirection={'column'}
                spacing={2}
                sx={{
                    padding: theme.spacing(2),
                    backgroundColor: theme.palette.backgroundB.backgroundB2,
                }}>
                <Typography
                    variant={'font14'}
                    fontWeight={'medium'}
                    color={'text.secondary'}>
                    DESCRIPTION
                </Typography>
                {description && (
                    <Typography variant={'font14'} color={'text.neutral6'}>
                        {description}
                    </Typography>
                )}
            </Grid>
        </Grid>
    );
}
