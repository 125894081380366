import React from 'react';

import { Box, Button, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import MemberProfile, { Member } from './MemberProfile';
import BasedTooltip from '../../../design/BasedTooltip';
import PATHS from '../../../router/paths';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useNavigate } from 'react-router-dom';
interface OwnProps {
    members?: Member[];
    title?: string;
    landPartnerId?: string;
    membersCount?: number;
}
export default function MembersList({
    members,
    title = 'Members',
    membersCount,
    landPartnerId,
}: OwnProps) {
    const { classes } = useStyles();
    const navigate = useNavigate();
    function handleNavigate() {
        navigate(`./${PATHS.relationship}`);
    }
    return (
        <Box display={'flex'} columnGap={4} rowGap={2} flexDirection={'column'}>
            <Typography
                sx={{ width: 105 }}
                variant={'font12'}
                color={'text.neutral9'}
                fontWeight={'medium'}>
                <BasedTooltip>{title}</BasedTooltip>
            </Typography>
            <div className={classes.infoWrapper}>
                {members?.map((item, index) => (
                    <MemberProfile key={index} {...item} />
                ))}
                {membersCount && landPartnerId && membersCount > 6 && (
                    <Box display={'flex'} justifyContent={'flex-end'}>
                        <Button
                            variant={'text'}
                            onClick={handleNavigate}
                            startIcon={<MoreHorizIcon />}>
                            More
                        </Button>
                    </Box>
                )}
            </div>
        </Box>
    );
}

const useStyles = makeStyles()((theme) => ({
    infoWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        columnGap: theme.spacing(4),
        rowGap: theme.spacing(2),
        maxHeight: 100,
    },
}));
