import TitleWithValue, { TitleWithValueProps } from './TitleWithValue';
import { Stack } from '@mui/material';

interface OwnProps {
    data: TitleWithValueProps[];
    width?: string;
    isHidden?: boolean;
}

export default function TitleWithValueList({
    data,
    width = '100%',
    isHidden,
}: OwnProps) {
    if (isHidden) return null;

    return (
        <Stack
            flexDirection={'row'}
            rowGap={2}
            columnGap={2}
            width={width}
            flexWrap={'wrap'}>
            {data.map((field, index) => (
                <TitleWithValue key={index} {...field} />
            ))}
        </Stack>
    );
}
