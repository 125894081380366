import React from 'react';
import InfoWithLabel from '../../../reusable/InfoWithLabel';
import { ReactComponent as EmailIcon } from '../../../assets/icons/communications_icon.svg';
import { Box, Typography } from '@mui/material';
import LinkText from '../../../design/Fields/LinkText';
import EmailVerificationButton from '../../../pages/AllProfiles/EmailVerificationButton';
import { SecondaryContactData } from './PrimaryContactInfoPanel';

interface OwnProps {
    secondaryContactData: SecondaryContactData;
}

export default function SecondaryContactInfo({
    secondaryContactData,
}: OwnProps) {
    return (
        <Box display={'flex'} flexDirection={'column'} rowGap={1}>
            <Typography variant={'font12'} color={'text.neutral9'}>
                Secondary
            </Typography>
            <InfoWithLabel
                icon={EmailIcon}
                text={
                    secondaryContactData.email ? (
                        <Box
                            display={'flex'}
                            columnGap={1}
                            alignItems={'center'}>
                            <LinkText
                                to={`mailto:${secondaryContactData.email}`}
                                text={secondaryContactData.email}
                                fontWeight={'normal'}
                            />
                            <EmailVerificationButton
                                id={secondaryContactData.id}
                                isSecondaryEmail={true}
                                isHidden={!secondaryContactData.email}
                                emailStatus={secondaryContactData.emailStatus}
                            />
                        </Box>
                    ) : (
                        'N/A'
                    )
                }
            />
        </Box>
    );
}
