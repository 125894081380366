import { Box, Grid } from '@mui/material';
import React from 'react';
import theme from '../../../../styles/theme';
import BasePieChart from '../../../../design/Charts/PieChart/BasePieChart';

interface OwnProps {
    dashboardData: {
        totalAcres: number;
        averageRentPerAcre: number;
    };
}

export default function SharedDataDashboardMetrics({
    dashboardData,
}: OwnProps) {
    return (
        <Box sx={{ padding: '20px', paddingTop: '5px', paddingLeft: '5px' }}>
            <Box
                bgcolor={theme.palette.neutralWhite}
                borderRadius={2}
                padding={2}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <BasePieChart
                            data={totalAcresData(dashboardData?.totalAcres)}
                            config={totalAcresConfig(dashboardData?.totalAcres)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <BasePieChart
                            data={averageRentData(
                                dashboardData?.averageRentPerAcre,
                            )}
                            config={averageRentConfig(
                                dashboardData?.averageRentPerAcre,
                            )}
                        />
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}

const totalAcresData = (totalAcres: number) => [
    {
        name: 'Summary',
        value: totalAcres,
    },
];

const totalAcresConfig = (totalAcres: number) => ({
    summaryTitle: 'Total Acres',
    showLegend: true,
    dataKey: 'value',
    chartTitle: 'Total Acres',
    showTooltip: true,
    summaryValue: totalAcres,
});

const averageRentData = (averageRent: number) => [
    {
        name: 'Summary',
        value: averageRent,
    },
];

const averageRentConfig = (averageRentPerAcre: number) => ({
    summaryTitle: 'Average Rent Per Acre',
    showLegend: true,
    dataKey: 'value',
    chartTitle: 'Average Rent Per Acre',
    showTooltip: true,
    summaryValue: averageRentPerAcre,
});
