import { OAKENApi } from '../OAKENApi';
import { ENDPOINTS } from '../endpoints';
import { HTTP_METHOD } from '../auth/http';
import { SharedLease } from '../../pages/SharedData/TemporaryAccess/Lease/SharedLeasesShortTable';
import { SharedPayment } from '../../pages/SharedData/TemporaryAccess/Payment/SharedPaymentsAllTable';
import { DocumentResponseItem } from '../../reusable/DocumentsList/DocumentsList';

export const sharedDataPermanentAccessAPI = OAKENApi.injectEndpoints({
    endpoints: (builder) => ({
        getSharedGrower: builder.query({
            query: (payload) => {
                return {
                    url:
                        process.env.REACT_APP_USER_API_HOST +
                        ENDPOINTS.sharedData.permanent.grower,
                    method: HTTP_METHOD.GET,
                    params: payload.params,
                };
            },
        }),
        getDashboard: builder.query({
            query: (payload) => {
                return {
                    url:
                        process.env.REACT_APP_LEASE_API_HOST +
                        ENDPOINTS.sharedData.permanent.dashboard,
                    method: HTTP_METHOD.GET,
                    params: payload.params,
                };
            },
        }),
        getSharedLeases: builder.query({
            query: (payload) => {
                return {
                    url:
                        process.env.REACT_APP_LEASE_API_HOST +
                        ENDPOINTS.sharedData.permanent.leases,
                    method: HTTP_METHOD.GET,
                    params: payload.params,
                };
            },
            transformResponse: (
                data: SharedLease[],
                meta,
            ): { data: SharedLease[]; totalCount: number } => {
                const xTotalCount =
                    meta?.response?.headers.get('X-Total-Count');
                return { data, totalCount: xTotalCount ? +xTotalCount : 0 };
            },
        }),
        getSharedPayments: builder.query({
            query: (payload) => {
                return {
                    url:
                        process.env.REACT_APP_PAYMENT_API_HOST +
                        ENDPOINTS.sharedData.permanent.payments,
                    method: HTTP_METHOD.GET,
                    params: payload.params,
                };
            },
            transformResponse: (
                data: SharedPayment[],
                meta,
            ): { data: SharedPayment[]; totalCount: number } => {
                const xTotalCount =
                    meta?.response?.headers.get('X-Total-Count');
                return { data, totalCount: xTotalCount ? +xTotalCount : 0 };
            },
        }),
        getSharedDocuments: builder.query({
            query: (params) => {
                return {
                    url:
                        process.env.REACT_APP_USER_API_HOST +
                        ENDPOINTS.sharedData.permanent.documents.root,
                    method: HTTP_METHOD.GET,
                    params: params,
                };
            },
            transformResponse: (
                data: DocumentResponseItem[],
                meta,
            ): { data: DocumentResponseItem[]; totalCount: number } => {
                const xTotalCount =
                    meta?.response?.headers.get('X-Total-Count');
                return { data, totalCount: xTotalCount ? +xTotalCount : 0 };
            },
        }),
        inviteGrower: builder.mutation({
            query: (payload) => {
                return {
                    url:
                        process.env.REACT_APP_USER_API_HOST +
                        ENDPOINTS.sharedData.permanent.inviteGrower,
                    method: HTTP_METHOD.POST,
                    body: payload.body,
                };
            },
        }),
    }),
});

export const {
    useGetDashboardQuery,
    useGetSharedGrowerQuery,
    useGetSharedDocumentsQuery,
    useGetSharedLeasesQuery,
    useLazyGetSharedLeasesQuery,
    useGetSharedPaymentsQuery,
    useLazyGetSharedPaymentsQuery,
    useInviteGrowerMutation,
} = sharedDataPermanentAccessAPI;
