import React, { ReactNode, useState } from 'react';
import { LastOutreach, OutreachType } from './LastOutreachTable';
import { ReactComponent as EmailIcon } from '../../assets/icons/communications_icon.svg';
import { ReactComponent as NotesIcon } from '../../assets/icons/edit_notes_icon.svg';
import theme from '../../styles/theme';
import { makeStyles } from 'tss-react/mui';
import clsx from 'clsx';
import AddNotesModal, { ContactNotesData } from '../../reusable/AddNotesModal';
import NotesButton from '../AllProfiles/NotesButton';

interface OwnProps {
    item: LastOutreach;
}

interface Mapper {
    icon: ReactNode;
}

export default function LastOutreachTypeLabels({ item }: OwnProps) {
    const { classes } = useStyles();

    const [openNotesModal, setOpenNotesModal] = useState(false);
    const [currentContact, setCurrentContact] =
        useState<ContactNotesData | null>(null);

    function handleModalOpen(item: LastOutreach) {
        setCurrentContact(convertContactData(item));
        setOpenNotesModal(true);
    }

    const handleNotesClose = () => {
        setCurrentContact(null);
        setOpenNotesModal(false);
    };

    let content;
    if (item.type === 'NOTES') {
        content = (
            <NotesButton
                handleModalOpen={() => handleModalOpen(item)}
                isHideAttachment
            />
        );
    } else {
        content = iconMapper[item.type].icon;
    }

    return (
        <>
            <div
                className={clsx(
                    classes.iconContainer,
                    item.type === 'EMAIL' && classes.emailIcon,
                    item.type === 'NOTES' && classes.notesIcon,
                )}>
                {content}
            </div>
            {currentContact && currentContact.id && (
                <AddNotesModal
                    id={currentContact.id}
                    contact={currentContact}
                    isShowing={openNotesModal}
                    onClose={handleNotesClose}
                />
            )}
        </>
    );
}

const iconMapper: Record<OutreachType, Mapper> = {
    EMAIL: {
        icon: <EmailIcon />,
    },
    NOTES: {
        icon: <NotesIcon />,
    },
};

const useStyles = makeStyles()(() => ({
    iconContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 24,
        height: 24,
        borderRadius: '50%',
        '& svg': {
            height: 14,
            color: theme.palette.neutralWhite,
            fill: theme.palette.neutralWhite,
        },
    },
    emailIcon: {
        backgroundColor: `${theme.palette.limeGreen.limeGreenShade1}`,
    },
    notesIcon: {
        backgroundColor: `${theme.palette.lightTurq.lightTurqTint8}`,
        color: `${theme.palette.neutralWhite}`,
        '& svg': {
            height: 14,
            color: theme.palette.neutralWhite,
            fill: theme.palette.neutralWhite,
        },
    },
}));

function convertContactData(item: LastOutreach) {
    return {
        id: item.contact?.id,
        firstName: item.contact?.firstName,
        lastName: item.contact?.lastName,
        organization: item.landPartner?.name,
        avatar: item.contact?.avatar,
    };
}
