//TODO remove all eslint comments
import { TableColumn } from '../design/GenericTable/GenericTable';
import { ColumnConfig } from '../api/users/customizeConfigAPI';

export function sortColumnsByOrder<T>(
    columns: TableColumn<T>[],
    config: ColumnConfig[],
) {
    const indexMap = config.reduce((acc, { id }, index) => {
        //eslint-disable-next-line
        //@ts-ignore
        acc[id] = index;
        return acc;
    }, {});

    return [...columns].sort((a, b) => {
        const indexA =
            //eslint-disable-next-line
            //@ts-ignore
            (a.header?.customizeKey && indexMap[a.header?.customizeKey]) || 0;
        const indexB =
            //eslint-disable-next-line
            //@ts-ignore
            (b.header?.customizeKey && indexMap[b.header?.customizeKey]) || 0;

        return indexA - indexB;
    });
}
export function isColumnHidden(
    config: ColumnConfig[],
    id: string,
    defaultValue: boolean,
): boolean {
    const configItem = config.find((item) => item.id === id);
    return configItem?.isHidden ?? defaultValue;
}

export const phoneTypeMapper: Record<string, string> = {
    CELL_PHONE: 'Cell',
    OFFICE_PHONE: 'Office',
    HOME_PHONE: 'Home',
    FAX: 'Fax',
};
