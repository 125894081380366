import React, { useState } from 'react';
import {
    Box,
    Collapse,
    Grid,
    IconButton,
    ThemeProvider,
    Typography,
    useTheme,
} from '@mui/material';
import ActionButton, {
    ActionMenuItem,
} from '../../design/Buttons/ActionButton';
import GenericTable, { Table } from '../../design/GenericTable/GenericTable';
import { ReactComponent as ExpandIcon } from '../../assets/icons/expand_icon.svg';

import { useGetPaymentScheduleQuery } from '../../api/payment/paymentAPI';
import { useParams } from 'react-router-dom';
import Loader from '../../design/BaseLoader';
import {
    extractMonthsAndDayFromDateAmount,
    showLandPartnersName,
} from './utils';
import clsx from 'clsx';
import { formatCurrency } from '../../utils/fieldMasks';
import { tableTheme } from '../../styles/tableTheme';
import UploadPaymentFile from './actions/UploadPaymentFile';
import { useStyles } from './LeasePayment.styles';
import ExpandedPaymentComponent from './ExpandedPaymentComponent';
import { useLeasePayment } from './useLeasePayment';

export interface PaymentSchedule {
    rentPerAcreRate: number;
    year: number;
    fsaIdPaymentDetails: FsaIdPaymentDetail[];
    yearlyTotal: DateAmount[];
    totalPayment: number;
}

export interface TransformedPaymentData {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    landowners: string[];
    id: string;
    leaseId: string;
    fsaId: string;
    tfpNumber: string[];
    landName: string;
    totalAcres: number;
    tillableAcres: number;
    rentPerAcreRate: number;
    totalAnnualRent: number;
    description: string;
    [key in string]: number;
    splitPaymentData: {
        [key in string]: string;
    };
    year: number;
    total: number;
}

export interface FsaIdPaymentDetail {
    paymentDistributionDateAmounts: DistributionDateAmounts[];
    fsaId: FsaId;
    dateAmounts: DateAmount[];
}
export interface DistributionDateAmounts {
    amount: number;
    landownerId: string;
    landownerName: string;
    paymentDate: string;
}
export interface FsaId {
    id: string;
    leaseId: string;
    fsaId: string;
    tfpNumber: string[];
    landName: string;
    totalAcres: number;
    tillableAcres: number;
    rentPerAcreRate: number;
    totalAnnualRent: number;
    description: string;
}

export interface DateAmount {
    paymentDate: number;
    amount: number;
}

export default function LeasePayments() {
    const theme = useTheme();
    const { id } = useParams();
    const [expanded, setExpanded] = useState(true);
    const { classes } = useStyles(theme);
    const [isImportCSVShowing, setIsImportCSVShowing] = useState(false);
    const { data, totalCount, tableConfig, isLoading } = useLeasePayment();

    const { data: paymentsData, isLoading: isPaymentsDataLoading } =
        useGetPaymentScheduleQuery({
            leaseId: id,
        });

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    if (isPaymentsDataLoading || isLoading) {
        return (
            <Grid
                container
                sx={{ height: '100vh' }}
                alignItems={'center'}
                justifyContent={'center'}
                flexDirection={'column'}>
                <Loader />
            </Grid>
        );
    }

    const { paymentData: tableData, keys } = convertResponseToModel(
        paymentsData || [],
    );

    const actionButtonItems: ActionMenuItem[] = [
        {
            label: 'Upload Payment File',
            onClick: () => {
                setIsImportCSVShowing(true);
            },
        },
    ];

    return (
        <div>
            <Grid
                container
                gap={1}
                p={2}
                sx={{ backgroundColor: theme.palette.neutralWhite }}>
                <Typography
                    variant={'font20'}
                    fontWeight="medium"
                    color={'text.secondaryContrast'}>
                    Payment Schedule
                </Typography>
                <Box className={classes.expandHeader}>
                    <Typography
                        variant={'font12'}
                        color={'text.link'}
                        fontWeight={'bold'}>
                        Rent Schedule:
                    </Typography>
                    <div>
                        <Typography
                            variant={'font12'}
                            color={'text.link'}
                            fontWeight={'medium'}>
                            {expanded ? 'Collapse' : 'Expand'}
                        </Typography>
                        <IconButton onClick={handleExpandClick}>
                            <ExpandIcon
                                className={clsx(expanded && classes.expanded)}
                            />
                        </IconButton>
                    </div>
                </Box>
                <Collapse
                    in={expanded}
                    timeout="auto"
                    unmountOnExit
                    sx={{ width: '100%', paddingBottom: theme.spacing(4) }}>
                    <ThemeProvider theme={tableTheme}>
                        <GenericTable
                            data={tableData}
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            tableConfig={tableScheduleSummaryConfig(keys)}
                        />
                    </ThemeProvider>
                </Collapse>
            </Grid>

            <div className={classes.titleWrapper}>
                <Typography variant="font32" fontWeight="regular">
                    Payments
                </Typography>
                <ActionButton label={'Actions'} items={actionButtonItems} />
            </div>
            <GenericTable
                data={data}
                totalCount={totalCount}
                tableConfig={tableConfig}
            />
            <UploadPaymentFile
                leaseId={id}
                isShowing={isImportCSVShowing}
                setIsShowing={setIsImportCSVShowing}
            />
        </div>
    );
}

export const tableScheduleSummaryConfig = (
    // eslint-disable-next-line
    headerConf: any,
): Table<TransformedPaymentData> => {
    return {
        columns: [
            {
                header: {
                    label: 'Year',
                },
                cellRender: (tableData) => (
                    <Typography variant={'font14'} fontWeight={'medium'}>
                        {tableData.year}
                    </Typography>
                ),
                format: { align: 'left', color: { color: 'primary' } },
                width: 'auto',
            },
            {
                header: {
                    // eslint-disable-next-line max-lines
                    label: 'Field Name',
                },
                cellRender: (tableData) => (
                    <Typography variant={'font14'} fontWeight={'medium'}>
                        {tableData.landName}
                    </Typography>
                ),
                format: { align: 'left', color: { color: 'primary' } },
                width: 'auto',
            },
            {
                header: {
                    label: 'FSA Number',
                },
                cellRender: (tableData) => (
                    <Typography variant={'font14'} fontWeight={'medium'}>
                        {tableData.fsaId}
                    </Typography>
                ),
                format: { align: 'left', color: { color: 'primary' } },
                width: 'auto',
            },
            {
                header: {
                    label: 'Land Partners',
                },
                cellRender: (tableData) => {
                    return (
                        <Box
                            width={'100%'}
                            display={'flex'}
                            justifyContent={'center'}>
                            {showLandPartnersName(tableData?.landowners)}
                        </Box>
                    );
                },
                format: { align: 'left', color: { color: 'primary' } },
                width: 'auto',
            },
            // eslint-disable-next-line max-lines
            {
                header: {
                    label: 'Total Acres',
                },
                cellRender: (tableData) => (
                    <Typography variant={'font14'} fontWeight={'medium'}>
                        {tableData.totalAcres}
                    </Typography>
                ),
                format: { align: 'left', color: { color: 'primary' } },
                width: 'auto',
            },
            {
                header: {
                    label: 'Tillable Acres',
                },
                cellRender: (tableData) => (
                    <Typography variant={'font14'} fontWeight={'medium'}>
                        {tableData.tillableAcres}
                    </Typography>
                ),
                format: { align: 'left', color: { color: 'primary' } },
                width: 'auto',
            },
            {
                header: {
                    label: 'Rent Per Acre',
                },
                cellRender: (tableData) => (
                    <Typography variant={'font14'} fontWeight={'medium'}>
                        {formatCurrency(tableData.rentPerAcreRate) === '$0.00'
                            ? null
                            : formatCurrency(tableData.rentPerAcreRate)}
                    </Typography>
                ),

                format: { align: 'left', color: { color: 'primary' } },
                width: 'auto',
            },
            //eslint-disable-next-line
            ...headerConf.map((columns: any) => {
                return {
                    header: {
                        label: columns,
                    },

                    cellRender: (tableData: PaymentSchedule) => (
                        <Typography variant={'font14'} fontWeight={'medium'}>
                            {
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                formatCurrency(tableData[columns])
                            }
                        </Typography>
                    ),
                    format: {
                        align: 'left',
                        color: { color: 'primary' },
                    },
                    width: 'auto',
                };
            }),
            // eslint-disable-next-line max-lines
            {
                header: {
                    label: 'Annual Rent',
                },
                cellRender: (tableData) => (
                    <Typography variant={'font14'} fontWeight={'medium'}>
                        {formatCurrency(tableData.totalAnnualRent)}
                    </Typography>
                ),
                format: { align: 'left', color: { color: 'primary' } },
                width: 'auto',
            },
            {
                header: {
                    label: 'Total Payment',
                },
                cellRender: (tableData) => (
                    <Typography variant={'font14'} fontWeight={'medium'}>
                        {formatCurrency(tableData.totalAnnualRent)}
                    </Typography>
                ),
                format: { align: 'left', color: { color: 'primary' } },
                width: 'auto',
            },
        ],
        multiselect: false,
        pageable: false,
        expandable: true,
        ExpandComponent: ExpandedPaymentComponent,
    };
};

export function convertResponseToModel(data: PaymentSchedule[]) {
    const uniqueKeys: string[] = [];
    const uniqueLandownerNames: string[] = [];
    const uniqueFsaIds: string[] = [];
    const splitPaymentData: {
        [fsaId: string]: { [paymentDate: string]: DistributionDateAmounts[] };
    } = {};
    const paymentData = data.flatMap((item) =>
        item.fsaIdPaymentDetails.map((fsaIdPaymentDetail, index) => {
            const { data, keys } = extractMonthsAndDayFromDateAmount(
                fsaIdPaymentDetail.dateAmounts,
            );
            const { data: yearlyData } = extractMonthsAndDayFromDateAmount(
                item.yearlyTotal,
            );

            keys.forEach((key) => {
                if (!uniqueKeys.includes(key)) {
                    uniqueKeys.push(key);
                }
            });

            if (!uniqueFsaIds.includes(fsaIdPaymentDetail.fsaId.fsaId)) {
                uniqueFsaIds.push(fsaIdPaymentDetail.fsaId.fsaId);
            }

            fsaIdPaymentDetail?.paymentDistributionDateAmounts?.forEach(
                (paymentDetail) => {
                    const { paymentDate, ...rest } = paymentDetail;

                    if (!splitPaymentData[fsaIdPaymentDetail.fsaId.fsaId]) {
                        splitPaymentData[fsaIdPaymentDetail.fsaId.fsaId] = {};
                    }
                    if (
                        !splitPaymentData[fsaIdPaymentDetail.fsaId.fsaId][
                            paymentDate
                        ]
                    ) {
                        splitPaymentData[fsaIdPaymentDetail.fsaId.fsaId][
                            paymentDate
                        ] = [{ paymentDate, ...rest }];
                    } else {
                        splitPaymentData[fsaIdPaymentDetail.fsaId.fsaId][
                            paymentDate
                        ].push({ paymentDate, ...rest });
                    }
                    if (
                        !uniqueLandownerNames.includes(
                            paymentDetail.landownerName,
                        )
                    ) {
                        uniqueLandownerNames.push(paymentDetail.landownerName);
                    }
                },
            );

            const commonProperties = {
                id: fsaIdPaymentDetail.fsaId.id,
                leaseId: fsaIdPaymentDetail.fsaId.leaseId,
                fsaId: fsaIdPaymentDetail.fsaId.fsaId,
                tfpNumber: fsaIdPaymentDetail.fsaId.tfpNumber,
                landName: fsaIdPaymentDetail.fsaId.landName,
                totalAcres: fsaIdPaymentDetail.fsaId.totalAcres,
                tillableAcres: fsaIdPaymentDetail.fsaId.tillableAcres,
                rentPerAcreRate: fsaIdPaymentDetail.fsaId.rentPerAcreRate,
                totalAnnualRent: fsaIdPaymentDetail.fsaId.totalAnnualRent,
                description: fsaIdPaymentDetail.fsaId.description,
                ...data,
                landowners: index === 0 ? uniqueLandownerNames : '---',
                year: index === 0 ? item.year : '',
                splitPaymentData,
            };

            if (
                item.fsaIdPaymentDetails.length > 0 &&
                index === item.fsaIdPaymentDetails.length - 1
            ) {
                return [
                    commonProperties,
                    {
                        year: 'Yearly Total',

                        ...yearlyData,

                        totalAnnualRent: item.totalPayment,
                    },
                ];
            }

            return commonProperties;
        }),
    );
    const flattenedPaymentData = paymentData.flat();

    return { paymentData: flattenedPaymentData, keys: uniqueKeys };
}
