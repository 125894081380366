import { ReactNode } from 'react';
import { Box, Typography } from '@mui/material';

import BaseModal from '../../reusable/BaseModal';
import { EmailVerificationResponse } from './useVerificateEmail';

import VerificationAlert from './VerificationAlert';
import theme from '../../styles/theme';
import SendVerifyButton from './SendVerifyButton';
import { emailIconTableMapper } from './EmailVerificationButton';
import { dateTimeConverterFromUTC } from '../../utils/dateFormat';

interface OwnProps {
    id: string;
    emailData?: EmailVerificationResponse;
    isShowing: boolean;
    isLoading: boolean;
    handleClose: () => void;
    isSecondaryEmail?: boolean;
}

export default function VerificationModal({
    id,
    isShowing,
    handleClose,
    isLoading,
    isSecondaryEmail,
    emailData,
}: OwnProps) {
    const IconComponent = emailData?.status
        ? emailIconTableMapper[emailData?.status].icon
        : null;
    return (
        <BaseModal
            isLoading={isLoading}
            size={'small'}
            isShowing={isShowing}
            onClose={handleClose}
            header={'Email Verification Status'}>
            <Box
                pb={4}
                width={'100%'}
                bgcolor={theme.palette.neutralWhite}
                borderRadius={theme.spacing(0, 0, 12, 12)}>
                <Box
                    pt={2}
                    pl={2}
                    pr={2}
                    width={'100%'}
                    display={'flex'}
                    flexDirection={'column'}
                    rowGap={2}
                    bgcolor={theme.palette.backgroundB.backgroundB1}>
                    {emailData?.status && (
                        <VerificationAlert emailStatus={emailData?.status} />
                    )}
                    <Box display={'flex'} flexWrap={'wrap'} pl={2} pb={3}>
                        <TitleAndValue
                            title={'Email Type'}
                            value={
                                <Box
                                    display={'flex'}
                                    columnGap={2}
                                    alignItems={'center'}>
                                    {emailData?.email}
                                    {IconComponent && <IconComponent />}
                                </Box>
                            }
                            isHidden={!emailData?.emailType}
                        />
                        <TitleAndValue
                            title={'Requested By'}
                            value={
                                emailData?.requestedBy?.firstName &&
                                `${emailData?.requestedBy?.firstName} ${emailData?.requestedBy?.lastName}`
                            }
                        />
                        <TitleAndValue
                            title={'Requested Date'}
                            value={
                                emailData?.createdAt &&
                                dateTimeConverterFromUTC(emailData?.createdAt)
                            }
                        />
                        <TitleAndValue
                            title={'Verification Date'}
                            value={
                                emailData?.updatedAt &&
                                dateTimeConverterFromUTC(emailData?.updatedAt)
                            }
                            isHidden={emailData?.status === 'PENDING'}
                        />
                    </Box>

                    <SendVerifyButton
                        id={id}
                        status={emailData?.status}
                        isHidden={
                            emailData?.status === 'VERIFIED' ||
                            emailData?.status === 'PENDING'
                        }
                        isSecondaryEmail={isSecondaryEmail}
                    />
                </Box>
            </Box>
        </BaseModal>
    );
}

function TitleAndValue({
    title,
    value,
    isHidden,
}: {
    title: string;
    value: ReactNode;
    isHidden?: boolean;
}) {
    if (isHidden) return null;
    return (
        <Box
            display={'flex'}
            flexDirection={'column'}
            rowGap={1}
            width={'50%'}
            pb={3}>
            <Typography fontWeight={'regular'}>{title}</Typography>
            <Typography variant="font12" fontWeight={'medium'}>
                {value || 'N/A'}
            </Typography>
        </Box>
    );
}
