import {
    composeValidators,
    isDateLessThanToday,
    isEmail,
    isNumber,
    maxLength,
    required,
} from '../../../utils/validation';

import { FormConfig } from '../../../design/Forms/interfaces';

const relationshipList = [
    { id: 'SON', name: 'Son' },
    { id: 'DAUGHTER', name: 'Daughter' },
    { id: 'MOTHER', name: 'Mother' },
    { id: 'FATHER', name: 'Father' },
    { id: 'SPOUSE', name: 'Spouse' },
    { id: 'OTHER', name: 'Other' },
];

export const advocateList = [
    { id: 'BLOCKER', name: 'Blocker' },
    { id: 'CHAMPION', name: 'Champion' },
    { id: 'INFLUENCER', name: 'Influencer' },
    { id: 'KEY_DECISION_MAKER', name: 'Key Decision Maker' },
    { id: 'DO_NOT_CONTACT', name: 'Do Not Contact' },
];

export interface PreferredContactMethodOptions {
    id: PreferredContactMethod;
    name: string;
}

type PreferredContactMethod =
    | 'PRIMARY_EMAIL'
    | 'SECONDARY_EMAIL'
    | 'CELL_PHONE'
    | 'OFFICE_PHONE'
    | 'HOME_PHONE'
    | 'FAX';

const powerOfAttorneyChoice = [
    {
        id: true,
        name: 'Yes',
    },
    {
        id: false,
        name: 'No',
    },
];
export const getAdvocateNameById = (id: string | undefined) => {
    const item = advocateList.find((entry) => entry.id === id);
    return item && item.name;
};

interface Props {
    isEdit: boolean;
    preferredContactMethodOptions: PreferredContactMethodOptions[];
}

export const contactDetailsConfig = ({
    isEdit,
    preferredContactMethodOptions,
}: Props): FormConfig[] => [
    {
        formField: {
            scheme: 'half',
            name: 'firstName',
            type: 'input',
            label: 'First Name',
            isRequired: true,
            validation: composeValidators(required, maxLength(256)),
        },
        renderProps: {
            placeholder: '',
            isDisabled: false,
            size: 'small',
        },
    },
    {
        formField: {
            scheme: 'half',
            name: 'lastName',
            type: 'input',
            label: 'Last Name',
            isRequired: true,
            validation: composeValidators(required, maxLength(256)),
        },
        renderProps: {
            placeholder: '',
            isDisabled: false,
            size: 'small',
        },
    },
    {
        formField: {
            scheme: 'half',
            name: 'primaryEmail',
            type: 'input',
            label: 'Primary Email Address',
            validation: composeValidators(isEmail, maxLength(256)),
        },
        renderProps: {
            placeholder: 'Enter Email Address',
            isDisabled: false,
            size: 'small',
        },
    },
    {
        formField: {
            scheme: 'half',
            name: 'secondaryEmail',
            type: 'input',
            label: 'Secondary Email Address',
            validation: composeValidators(isEmail, maxLength(256)),
        },
        renderProps: {
            placeholder: 'Enter Email Address',
            isDisabled: false,
            size: 'small',
        },
    },
    {
        formField: {
            scheme: 'half',
            name: 'relationship',
            type: 'select',
            label: !isEdit ? 'Relationship' : '',
        },
        renderProps: {
            placeholder: 'Select from list',
            isDisabled: false,
            size: 'small',
            options: relationshipList,
            isHidden: isEdit,
        },
    },
    {
        formField: {
            scheme: 'half',
            name: 'relationshipDescription',
            type: 'input',
            label: !isEdit ? 'Description' : '',
            validation: composeValidators(maxLength(1024)),
        },
        renderProps: {
            placeholder: 'Please describe relationship',
            isDisabled: false,
            size: 'small',
            isHidden: isEdit,
        },
    },
    {
        formField: {
            scheme: 'third',
            name: 'keyDates.birthDate',
            type: 'datepicker',
            label: 'Birthday',
            validation: composeValidators(
                isDateLessThanToday('Date must be equal or less than today'),
            ),
        },
        renderProps: {
            placeholder: 'Birthday',
            isDisabled: false,
            size: 'small',
            format: 'MMM dd, yyyy',
        },
    },
    {
        formField: {
            scheme: 'third',
            name: 'age',
            type: 'input',
            label: 'Age',
            validation: composeValidators(isNumber, maxLength(256)),
        },
        renderProps: {
            placeholder: 'Age',
            isDisabled: false,
            size: 'small',
        },
    },
    {
        formField: {
            scheme: 'third',
            name: 'keyDates.anniversary',
            type: 'datepicker',
            label: 'Anniversary',
            validation: composeValidators(
                isDateLessThanToday('Date must be equal or less than today'),
            ),
        },
        renderProps: {
            placeholder: 'Anniversary',
            isDisabled: false,
            size: 'small',
            format: 'MMM dd, yyyy',
        },
    },
    {
        formField: {
            scheme: 'half',
            name: 'keyDates.relationshipStartDate',
            type: 'datepicker',
            label: 'Relationship Start Date',
        },
        renderProps: {
            placeholder: 'Enter Relationship Start Date',
            isDisabled: false,
            size: 'small',
            format: 'MMM dd, yyyy',
        },
    },
    {
        formField: {
            scheme: 'half',
            name: 'keyDates.dateOfDeath',
            type: 'datepicker',
            label: 'Date of Death',
            validation: composeValidators(
                isDateLessThanToday('Date must be equal or less than today'),
            ),
        },
        renderProps: {
            placeholder: 'Enter Date of Death',
            isDisabled: false,
            size: 'small',
            format: 'MMM dd, yyyy',
        },
    },
    {
        formField: {
            scheme: 'half',
            name: 'advocate',
            type: 'checkbox',
            label: 'Advocate',
            tooltip: {
                text: 'Advocate',
                position: 'top',
            },
        },
        renderProps: {
            size: 'half',
            options: advocateList,
            isDisabled: false,
        },
    },
    {
        formField: {
            scheme: 'half',
            name: 'preferredContactMethod',
            type: 'radio',
            label: 'Preferred Contact Method',
        },
        renderProps: {
            size: 'half',
            options: preferredContactMethodOptions,
            isDisabled: false,
        },
    },
    {
        formField: {
            scheme: 'half',
            name: 'powerOfAttorney',
            type: 'radio',
            label: 'Power of attorney',
        },
        renderProps: {
            size: 'half',
            options: powerOfAttorneyChoice,
            isDisabled: false,
        },
    },
];
