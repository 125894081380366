import React from 'react';
import {
    useGetOrganizationConfigByIdQuery,
    useSaveOrganizationConfigMutation,
} from '../../../../api/users/organizationsAPI';
import { FormConfig } from '../../../../design/Forms/interfaces';
import { composeValidators, required } from '../../../../utils/validation';
import FormModal from '../../../../reusable/Modal/FormModal';
import { documentCategoryFullOptions } from '../../../../features/ViewLandownersDetails/LandPartnerDocuments/utils';
import { Grid } from '@mui/material';
import Loader from '../../../../design/BaseLoader';
import useHandleSubmitRTK from '../../../../hooks/useHandleSubmit';

interface OrganizationConfigModalProps {
    organizationId: string;
    isShowing: boolean;
    onClose: () => void;
}

interface OrganizationConfigFormValues {
    documentCategories: string[];
}

const OrganizationConfigModal: React.FC<OrganizationConfigModalProps> = ({
    organizationId,
    isShowing,
    onClose,
}) => {
    const { data, isLoading, isFetching } = useGetOrganizationConfigByIdQuery({
        organizationId,
    });

    const { handleSubmit: saveConfig } = useHandleSubmitRTK({
        useRtkHook: useSaveOrganizationConfigMutation,
        onSuccess: onClose,
        successMessage: 'Organization configuration saved successfully',
    });

    if (isLoading || isFetching) {
        return (
            <Grid
                width={'100%'}
                container
                alignItems={'center'}
                justifyContent={'center'}>
                <Loader />
            </Grid>
        );
    }

    const handleSubmit = (values: OrganizationConfigFormValues) => {
        saveConfig({
            organizationId,
            requestBody: values,
        });
    };

    return (
        <FormModal
            header="Organization Configuration"
            initialValues={
                data || {
                    documentCategories: documentCategoryFullOptions.map(
                        (category) => category.id,
                    ),
                }
            }
            isShowing={isShowing}
            formConfig={organizationConfigFormConfig()}
            handleSubmit={handleSubmit}
            submitButtonText="Save"
            onClose={onClose}
        />
    );
};

export default OrganizationConfigModal;

const organizationConfigFormConfig = (): FormConfig[] => {
    return [
        {
            formField: {
                scheme: 'full',
                name: 'documentCategories',
                type: 'checkbox',
                label: 'Document Categories',
                isRequired: true,
                validation: composeValidators(required),
            },
            renderProps: {
                isDisabled: false,
                size: 'full',
                options: documentCategoryFullOptions,
            },
        },
    ];
};
