import { useNavigate, useParams } from 'react-router-dom';
import useAvatar from '../../hooks/useAvatar';

import { Grid } from '@mui/material';
import Loader from '../../design/BaseLoader';

import WizardForm from '../../design/Forms/WizardForm';
import arrayMutators from 'final-form-arrays';
import { Address, ContactProfile } from '../../pages/AllProfiles/AllProfiles';
import { format } from 'date-fns';

import useHandleSubmitRTK from '../../hooks/useHandleSubmit';

import PATHS from '../../router/paths';
import {
    useAddRelationContactMutation,
    useLazyGetOneContactQuery,
    useUpdateRelationContactMutation,
} from '../../api/contacts/contactsAPI';
import ContactDetailsPage from './ContactDetailsPage';
import React, { useEffect } from 'react';
import AddressFormTab from './AddressesFormPage';
import SocialMediaAndAgridataPage from './SocialMediaAndAgridataPage';
import RelationshipTreePage from './RelationshipTreePage';
import PreviewContact from './PreviewContact';
import AddNotes from './AddNotes';
import { DATE_FORMAT } from '../../utils/dateFormat';
import { useGetAccountQuery } from '../../api/users/accountAPI';

interface OwnProps {
    pageNumber: number;
    setPageNumber: (page: number) => void;
    setIsValid: (isValid: boolean) => void;
    treeData?: {
        landPartnerId: string;
        parentContactId: string;
    };
}

export default function LandPartnerForm({
    pageNumber,
    setPageNumber,
    setIsValid,
    treeData,
}: OwnProps) {
    const navigate = useNavigate();
    const { id } = useParams<{ id: string }>();
    const isEdit = !!id;

    const [getItems, { isFetching: fetchingOne, isLoading, data: initData }] =
        useLazyGetOneContactQuery();
    const { data: accountData, isLoading: isAccLoading } = useGetAccountQuery(
        {},
    );
    const {
        handleSubmit: updateRelationContact,
        isLoading: isUpdating,
        data: updatedData,
    } = useHandleSubmitRTK({
        useRtkHook: useUpdateRelationContactMutation,
        onSuccess: () =>
            navigate(`/${PATHS.landpartners}/${PATHS.allProfiles}`),
        successMessage: 'Contact updated successfully',
    });
    const { handleSubmit: addRelationContact, isLoading: isCreating } =
        useHandleSubmitRTK({
            useRtkHook: useAddRelationContactMutation,
            onSuccess: () =>
                navigate(`/${PATHS.landpartners}/${PATHS.allProfiles}`),
            successMessage: 'Contact created successfully',
        });
    const { createFile } = useAvatar();
    const data = updatedData || initData;

    useEffect(() => {
        id && getItems(id);
    }, [id]);

    const formData = data && {
        ...data,
        contactAvatar:
            data.avatar &&
            createFile(
                data.avatar.contactAvatar,
                data.avatar.fileName,
                data.avatar.fileType,
            ),
        subEntityId: data?.subEntity?.id || accountData?.organization?.id,
        keyDates: {
            birthDate:
                data.keyDates?.birthDate && new Date(data.keyDates?.birthDate),
            anniversary:
                data.keyDates?.anniversary &&
                new Date(data.keyDates?.anniversary),
            relationshipStartDate:
                data.keyDates?.relationshipStartDate &&
                new Date(data.keyDates?.relationshipStartDate),
            dateOfDeath:
                data.keyDates?.dateOfDeath &&
                new Date(data.keyDates?.dateOfDeath),
        },
    };

    function handleSubmit(value: ContactProfile) {
        const submitValue = value && {
            ...value,
            subEntityId:
                accountData?.organization?.id === value?.subEntityId
                    ? null
                    : value?.subEntityId,
            addresses:
                value.addresses && filterEmptyObjFromArr(value.addresses),
            keyDates: {
                birthDate:
                    value.keyDates?.birthDate &&
                    format(new Date(value.keyDates?.birthDate), DATE_FORMAT),
                anniversary:
                    value.keyDates?.anniversary &&
                    format(new Date(value.keyDates?.anniversary), DATE_FORMAT),
                relationshipStartDate:
                    value.keyDates?.relationshipStartDate &&
                    format(
                        new Date(value.keyDates?.relationshipStartDate),
                        DATE_FORMAT,
                    ),
                dateOfDeath:
                    value.keyDates?.dateOfDeath &&
                    format(new Date(value.keyDates?.dateOfDeath), DATE_FORMAT),
            },
            contactRoles: value?.contactRoles?.map((role) =>
                typeof role === 'string' ? role : role.id,
            ),
        };
        if (isEdit) {
            updateRelationContact(submitValue);
        } else {
            addRelationContact(submitValue);
        }
    }

    if (isLoading || fetchingOne || isAccLoading) {
        return (
            <Grid
                width={'100%'}
                container
                height={'100vh'}
                alignItems={'center'}
                justifyContent={'center'}>
                <Loader />
            </Grid>
        );
    }

    return (
        <WizardForm
            isLoading={isCreating || isUpdating}
            setIsValid={setIsValid}
            mutators={arrayMutators as never}
            initialValues={
                isEdit
                    ? formData
                    : {
                          ...treeData,
                          subEntityId: accountData?.organization?.id,
                          addresses: [],
                          preferredContactMethod: 'PRIMARY_EMAIL',
                          powerOfAttorney: false,
                      }
            }
            onSubmit={handleSubmit}
            setStep={setPageNumber}
            step={pageNumber}>
            <RelationshipTreePage orgId={accountData?.organization?.id || ''} />
            <ContactDetailsPage />
            <AddressFormTab />
            <SocialMediaAndAgridataPage />
            {isEdit && <AddNotes id={id} />}
            <PreviewContact setPageNumber={setPageNumber} />
        </WizardForm>
    );
}

function filterEmptyObjFromArr(arr: Address[]) {
    return arr.filter((item) => {
        return Object.keys(item).length > 0;
    });
}
