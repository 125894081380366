import { useParams } from 'react-router-dom';
import PATHS from '../../router/paths';
import React from 'react';
import BasicTabsPanel from '../../design/TabsPanel/BasicTabsPanel';
import Breadcrumbs from '../../design/Breadcrumbs/Breadcrumbs';
import useBreadcrumbs from '../../hooks/useBreadcrumbs';
import { mockFieldsData } from '../../pages/Fields/useFieldsTable';
import FieldsDashboard from './FieldsDashboard/FieldsDashboard';
import { Stack } from '@mui/material';
import FieldsDetailsTab from './FieldsDetailsTab/FieldsDetailsTab';

export default function FieldsDetails() {
    const { id } = useParams();

    const detailsData = mockFieldsData.find((item) => item.id === id);

    const breadcrumbs = useBreadcrumbs({
        dynamicName: detailsData?.fieldName?.toUpperCase() || '',
        routes,
    });

    return (
        <div>
            <Breadcrumbs collapsed={false} links={breadcrumbs} />
            <Stack rowGap={3}>
                <FieldsDashboard data={detailsData} />
                <BasicTabsPanel tabItems={tabsConfig} />
            </Stack>
        </div>
    );
}
const tabsConfig = [
    {
        label: 'Detail ',
        content: <FieldsDetailsTab />,
        value: 0,
        to: '',
    },
    {
        label: 'Leases',
        content: <p>Leases</p>,
        value: `${PATHS.leases}`,
        to: `${PATHS.leases}`,
    },
    {
        label: 'Documents',
        content: <p>Documents</p>,
        value: `${PATHS.documents}`,
        to: `${PATHS.documents}`,
    },
    {
        label: 'History',
        content: <p>History</p>,
        value: `${PATHS.history}`,
        to: `${PATHS.history}`,
    },
];
const routes = {
    'landPortfolio/fields/:id': '',
    'landPortfolio/fields/:id/leases': 'Leases',
    'landPortfolio/fields/:id/documents': 'Documents',
    'landPortfolio/fields/:id/history': 'History',
};
