// src/layouts/NavigationPanel/NavigationPanel.tsx
import * as _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import useStyles from './NavigationPanel.styles';
import theme from '../../styles/theme';
import { ReactComponent as Logo } from '../../assets/icons/logo.svg';
import { useNavigationConfig, useNavigationSettingsConfig } from './config';
import PATHS from '../../router/paths';
import { ShortResponse } from '../../pages/Settings/Users/AllUsers/UsersList';
import NavigationItem from './NavigationItem';

export default function NavigationPanel({
    userRole,
}: {
    userRole: ShortResponse;
}) {
    const { pathname } = useLocation();
    const { classes } = useStyles(theme);

    const roleName = userRole?.name;
    const { config: navigationConfig } = useNavigationConfig();
    const [activePath, setActivePath] = useState(pathname);

    useEffect(() => {
        setActivePath(pathname);
    }, [pathname]);

    function isActiveItem(path: string) {
        if (path === PATHS.dashboard) return PATHS.dashboard === activePath;
        return activePath.indexOf(path) !== -1;
    }

    return (
        <nav className={classes.navigationWrapper}>
            <div className={classes.logoWrapper}>
                <NavLink
                    to={
                        roleName === 'ADMIN' ? PATHS.settings : PATHS.dashboard
                    }>
                    <Logo />
                </NavLink>
            </div>
            <div className={classes.navigationItemsContainer}>
                {roleName !== 'ADMIN' && (
                    <div>
                        {_.map(navigationConfig, (item) => (
                            <NavigationItem
                                key={item.label}
                                item={item}
                                activePath={activePath}
                                setActivePath={setActivePath}
                                isActivePath={isActiveItem}
                            />
                        ))}
                    </div>
                )}

                <NavigationItem
                    item={useNavigationSettingsConfig({ role: roleName })}
                    activePath={activePath}
                    setActivePath={setActivePath}
                    isActivePath={isActiveItem}
                />
            </div>
        </nav>
    );
}
