import { ACCOUNT, REDUX_CACHE } from '../tags';
import { HTTP_METHOD } from '../auth/http';
import { ENDPOINTS } from '../endpoints';
import { OAKENApi } from '../OAKENApi';

export const accountAPI = OAKENApi.injectEndpoints({
    endpoints: (builder) => ({
        getAccount: builder.query({
            query: () => {
                return {
                    url:
                        process.env.REACT_APP_USER_API_HOST +
                        ENDPOINTS.account.root,
                    method: HTTP_METHOD.GET,
                };
            },
            providesTags: () => [{ type: ACCOUNT.ACCOUNT }],
        }),
        finishResetPassword: builder.mutation({
            query: ({ body }) => {
                return {
                    url:
                        process.env.REACT_APP_USER_API_HOST +
                        ENDPOINTS.account.finishResetPassword,
                    method: HTTP_METHOD.POST,
                    body,
                };
            },
            invalidatesTags: (error) => {
                if (error) {
                    return [REDUX_CACHE.ABORT_INVALIDATION] as never;
                }
                return [{ type: ACCOUNT.ACCOUNT }];
            },
        }),
        updateAccount: builder.mutation({
            query: (value) => {
                const formData = new FormData();
                if (value.profilePhoto && value.profilePhoto.length > 0) {
                    formData.append('profilePhoto', value.profilePhoto[0]);
                }
                formData.append(
                    'request',
                    new Blob(
                        [
                            JSON.stringify({
                                firstName: value.firstName,
                                lastName: value.lastName,
                                email: value.email,
                                phoneNumber: value.phoneNumber,
                                socialMedia: value.socialMedia,
                                keyDates: value.keyDates,
                            }),
                        ],
                        {
                            type: 'application/json',
                        },
                    ),
                );

                return {
                    url:
                        process.env.REACT_APP_USER_API_HOST +
                        `${ENDPOINTS.account.root}`,
                    method: HTTP_METHOD.PUT,
                    body: formData,
                    formData: true,
                };
            },
            invalidatesTags: [ACCOUNT.ACCOUNT],
        }),
        getAccountLayouts: builder.query({
            query: () => {
                return {
                    url:
                        process.env.REACT_APP_USER_API_HOST +
                        ENDPOINTS.account.layouts,
                    method: HTTP_METHOD.GET,
                };
            },
            providesTags: () => [{ type: ACCOUNT.ACCOUNT_LAYOUTS }],
        }),
    }),
});

export const {
    useGetAccountQuery,
    useUpdateAccountMutation,
    useGetAccountLayoutsQuery,
} = accountAPI;
export const getAccountState = accountAPI.endpoints.getAccount.useQueryState;
export const getAccountLayoutsState =
    accountAPI.endpoints.getAccountLayouts.useQueryState;
