import {
    Box,
    Button,
    Checkbox,
    FormControlLabel,
    Grid,
    IconButton,
    Typography,
} from '@mui/material';
import theme from '../../../styles/theme';
import AlertLabel from '../../../design/Labels/AlertLabel';
import SelectField from '../../../design/Fields/SelectField';
import React, { useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { useParams } from 'react-router-dom';
import {
    useRemoveContactFromRelationshipTreeMutation,
    useSearchContactRelationsQuery,
} from '../../../api/contacts/contactsAPI';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close_icon.svg';

interface OwnProps {
    onClose: () => void;
    currentId: string;
}

export default function RemoveTreeNodeWithChildren({
    currentId,
    onClose,
}: OwnProps) {
    const { id: landPartnerId } = useParams();

    const [newContact, setNewContact] = useState<string | undefined>('');
    const [isChecked, setIsChecked] = useState<boolean>(false);

    const { data } = useSearchContactRelationsQuery({
        landPartnerId: landPartnerId,
        contactId: currentId,
        sort: 'asc,firstName',
    });
    const [removeContactFromTree] =
        useRemoveContactFromRelationshipTreeMutation();
    const handleChecked = () => {
        setIsChecked(!isChecked);
        setNewContact('');
    };
    const handleSelect = (value: string) => {
        setNewContact(value);
        setIsChecked(false);
    };
    const options = data?.map(
        (contact: { id: string; firstName: string; lastName: string }) => ({
            id: contact.id,
            name: `${contact.firstName} ${contact.lastName}`,
        }),
    );
    const onProcess = () => {
        if (isChecked) {
            removeContactFromTree({
                landPartnerId: landPartnerId,
                contactId: currentId,
                reassignAllChildren: false,
            });
            onClose();
            return;
        }
        if (newContact !== '') {
            removeContactFromTree({
                landPartnerId: landPartnerId,
                contactId: currentId,
                reassignAllChildren: true,
                newParentContactId: newContact,
            });
            return;
        }
        onClose();
    };
    const { classes } = useStyles();
    return (
        <Grid className={classes.modal}>
            <Grid className={classes.modalHeader}>
                <Typography className={classes.modalHeaderTitle}>
                    Delete a Contact
                </Typography>
                <IconButton
                    className={classes.modalCloseButton}
                    onClick={() => onClose()}
                    size="large">
                    <CloseIcon />
                </IconButton>
            </Grid>
            <Box
                display={'flex'}
                flexDirection={'column'}
                width={'100%'}
                justifyContent={'center'}
                alignItems={'center'}
                borderRadius={1.5}>
                <Box
                    display={'flex'}
                    flexDirection={'column'}
                    rowGap={2}
                    sx={{
                        padding: theme.spacing(2, 6, 0),
                        backgroundColor: theme.palette.neutralWhite,
                    }}>
                    <AlertLabel />
                    <Typography variant={'font20'} fontWeight={'medium'}>
                        Deleting a key individual will collapse this structure.
                        Before proceeding, please select another individual to
                        take over their responsibilities.
                    </Typography>
                    <Box
                        display={'flex'}
                        columnGap={1}
                        alignItems={'center'}
                        justifyContent={'center'}>
                        <Box
                            display={'flex'}
                            alignItems={'center'}
                            justifyContent={'center'}
                            flexDirection={'column'}
                            rowGap={3}
                            width={290}
                            borderRadius={1}
                            sx={{
                                backgroundColor:
                                    theme.palette.lightTurq.lightTurqTint2,
                                padding: theme.spacing(4.5, 3),
                            }}>
                            <SelectField
                                size={'small'}
                                onChange={handleSelect}
                                fullWidth={true}
                                value={newContact}
                                placeholder={'Select'}
                                options={options}
                            />
                        </Box>
                        <Typography fontWeight={'medium'}>- OR -</Typography>
                        <Box
                            display={'flex'}
                            alignItems={'center'}
                            flexDirection={'column'}
                            rowGap={3}
                            width={290}
                            borderRadius={1}
                            sx={{
                                backgroundColor:
                                    theme.palette.limeGreen.limeGreenTint3,
                                padding: theme.spacing(4.5, 3),
                            }}>
                            {' '}
                            <FormControlLabel
                                onChange={handleChecked}
                                checked={isChecked}
                                control={<Checkbox />}
                                label={'I understand, proceed with removal'}
                            />
                        </Box>
                    </Box>
                </Box>
            </Box>
            <div className={classes.bottomContainer}>
                <Button variant={'outlined'} onClick={() => onClose()}>
                    Cancel
                </Button>
                <Button
                    variant={'contained'}
                    onClick={() => onProcess()}
                    disabled={!isChecked && !newContact}>
                    Proceed
                </Button>
            </div>
        </Grid>
    );
}
const useStyles = makeStyles()((theme) => ({
    modal: {
        display: 'flex',
        height: '50vh',
        flexDirection: 'column',
        alignItems: 'center',
        width: 880,
        zIndex: 100,
        position: 'relative',
        borderRadius: 12,
    },
    modalHeader: {
        height: 70,
        position: 'sticky',
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '24px',
        borderRadius: '12px 12px 0 0',
        backgroundColor: theme.palette.neutralWhite,
    },
    modalHeaderTitle: {
        fontWeight: 600,
        fontSize: '14px',
        fontFamily: 'Montserrat',
        color: theme.palette.neutral.neutral10,
    },
    modalCloseButton: {
        padding: 0,
        color: theme.palette.background.default,
        cursor: 'pointer',
        border: 'none',
        width: '18px',
        height: '18px',
    },
    bottomContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        gap: '16px',
        width: '100%',
        padding: theme.spacing(3),
        borderRadius: '0 0 12px 12px',
        backgroundColor: theme.palette.neutralWhite,
    },
}));
