import { FormConfig } from '../../design/Forms/interfaces';
import { composeValidators, maxLength, required } from '../../utils/validation';
import { relationOptions } from './utils';
import FormPage from '../../reusable/FormUI/FormPage';
import { useForm, useFormState } from 'react-final-form';
import { Box } from '@mui/material';
import { useLazySearchLeasesQuery } from '../../api/leases/leaseAPI';
import { useLazySearchLPQuery } from '../../api/landpartners/landPartnersAPI';
import { useEffect } from 'react';
import { Option } from '../CreateLeaseForm/WizardPageConfigs/leaseUploadConfig';
import { useIsMount } from '../../hooks/useIsMount';

export default function RelatesToForm() {
    const form = useForm();
    const isMount = useIsMount();
    const { values, dirtyFields } = useFormState();
    const [getLeases, { data: leaseOptions }] = useLazySearchLeasesQuery();
    const [getLPs, { data: landPartnerOptions }] = useLazySearchLPQuery();

    useEffect(() => {
        if (values.relatesTo?.type?.id === 'LEASE') {
            getLeases({
                landPartnerId: '',
                page: 0,
                size: 2000,
            });
        } else if (values.relatesTo?.type?.id === 'LAND_PARTNER') {
            getLPs({ page: 0, size: 2000 });
        }
    }, [values.relatesTo?.type, getLeases, getLPs]);

    useEffect(() => {
        if (isMount) return;
        if (dirtyFields['relatesTo.type']) {
            form.change('relatesTo.id', undefined);
        }
    }, [values.relatesTo?.type, dirtyFields['relatesTo.type']]);

    return (
        <Box width={'100%'}>
            <FormPage
                formConfig={relatesToConfig({
                    relatesTo: values.relatesTo?.type?.id,
                    landPartners: landPartnerOptions,
                    leases: leaseOptions || [],
                })}
            />
        </Box>
    );
}

interface RelatesTo {
    relatesTo: 'LEASE' | 'LAND_PARTNER';
    landPartners: Option[];
    leases: Option[];
}

function relatesToConfig({
    relatesTo,
    landPartners,
    leases,
}: RelatesTo): FormConfig[] {
    return [
        {
            formField: {
                scheme: 'half',
                name: 'relatesTo.type',
                type: 'searchAutocomplete',
                label: 'Relates To',
            },
            renderProps: {
                isDisabled: false,
                size: 'small',
                options: relationOptions,
            },
        },
        {
            formField: {
                scheme: 'half',
                name: 'relatesTo.id',
                type: 'searchAutocomplete',
                label: 'Lease Name',
                isRequired: true,
                validation: composeValidators(maxLength(256), required),
                isHidden: relatesTo !== 'LEASE',
            },
            renderProps: {
                placeholder: '',
                isDisabled: false,
                size: 'small',
                options: leases,
            },
        },
        {
            formField: {
                scheme: 'half',
                name: 'relatesTo.id',
                type: 'searchAutocomplete',
                label: 'Land Partner Name',
                isRequired: true,
                validation: composeValidators(maxLength(256), required),
                isHidden: relatesTo !== 'LAND_PARTNER',
            },
            renderProps: {
                placeholder: '',
                isDisabled: false,
                size: 'small',
                options: landPartners,
            },
        },
    ];
}
