import { FormConfig } from '../../../../design/Forms/interfaces';
import {
    composeValidators,
    isEmail,
    isNumber,
    maxLength,
    required,
} from '../../../../utils/validation';
import { usaStates } from '../../../../utils/vocabulary';
import { organizationTypeOptions } from '../config/organizationEnums';

export const formSubEntitiesConfig = (name: string): FormConfig[] => [
    {
        formField: {
            scheme: 'half',
            name: `${name}.name`,
            type: 'input',
            label: 'Organization Name',
            isRequired: true,
            validation: composeValidators(required, maxLength(256)),
        },
        renderProps: {
            placeholder: '',
            isDisabled: false,
            size: 'small',
        },
    },
    {
        formField: {
            scheme: 'half',
            name: `${name}.type`,
            type: 'select',
            label: 'Organization Type',
            isRequired: true,
            validation: composeValidators(required),
        },
        renderProps: {
            placeholder: '',
            isDisabled: false,
            size: 'small',
            options: organizationTypeOptions,
        },
    },
    {
        formField: {
            scheme: 'half',
            name: `${name}.primaryContact.firstName`,
            type: 'input',
            label: 'Primary Contact First Name',
            isRequired: true,
            validation: composeValidators(required, maxLength(256)),
        },
        renderProps: {
            isDisabled: false,
            size: 'small',
        },
    },
    {
        formField: {
            scheme: 'half',
            name: `${name}.primaryContact.lastName`,
            type: 'input',
            label: 'Primary Contact Last Name',
            isRequired: true,
            validation: composeValidators(required, maxLength(256)),
        },
        renderProps: {
            isDisabled: false,
            size: 'small',
        },
    },
    {
        formField: {
            scheme: 'half',
            name: `${name}.primaryAddress.address1`,
            type: 'input',
            label: 'Address Line 1',
            validation: composeValidators(maxLength(256)),
        },
        renderProps: {
            placeholder: 'Street',
            isDisabled: false,
            size: 'small',
        },
    },
    {
        formField: {
            scheme: 'half',
            name: `${name}.primaryAddress.address2`,
            type: 'input',
            label: 'Address Line 2',
            validation: composeValidators(maxLength(256)),
        },
        renderProps: {
            placeholder: 'Street',
            isDisabled: false,
            size: 'small',
        },
    },
    {
        formField: {
            scheme: 'half',
            name: `${name}.primaryAddress.city`,
            type: 'input',
            label: 'City',
            validation: composeValidators(maxLength(256)),
        },
        renderProps: {
            placeholder: 'City',
            isDisabled: false,
            size: 'small',
        },
    },
    {
        formField: {
            scheme: 'half',
            name: `${name}.primaryAddress.state`,
            type: 'select',
            label: 'State',
        },
        renderProps: {
            isDisabled: false,
            size: 'small',
            options: usaStates,
        },
    },
    {
        formField: {
            scheme: 'half',
            name: `${name}.primaryAddress.zipCode`,
            type: 'input',
            label: 'Zip Code/Postal Code',
            validation: composeValidators(isNumber, maxLength(256)),
        },
        renderProps: {
            placeholder: '00000',
            isDisabled: false,
            size: 'small',
        },
    },
    {
        formField: {
            scheme: 'half',
            name: `${name}.email`,
            type: 'input',
            label: 'Primary Email Address',
            validation: composeValidators(isEmail, maxLength(256)),
        },
        renderProps: {
            placeholder: 'Enter email address',
            isDisabled: false,
            size: 'small',
        },
    },
];
