import { MouseEvent, useState } from 'react';
import { Button, Card, CardContent, CardHeader } from '@mui/material';
import { ReactComponent as ChevronIcon } from '../../assets/icons/chevron_down_icon.svg';
import BasePopover from '../../reusable/BasePopover';
import BaseFilter from '../../design/Filter/BaseFilter';
import { FormConfig } from '../../design/Forms/interfaces';
import { ActionFilterButtons } from '../../design/Filter/ActionButtons';
import { makeStyles } from 'tss-react/mui';
import theme from '../../styles/theme';

interface OwnProps {
    setFilterSettings: (value: string) => void;
}

export default function OtherServicesFilter({ setFilterSettings }: OwnProps) {
    const { classes } = useStyles();
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const open = Boolean(anchorEl);
    const id = open ? 'search-popover' : undefined;

    const handlePopoverOpen = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const handleSaveFilter = (values: string) => {
        // eslint-disable-next-line no-console
        console.log('values', values);
    };
    const handleUpdateResults = (values: string) => {
        setFilterSettings(values);
    };

    const buttonsConfig: ActionFilterButtons<string | undefined>[] = [
        {
            label: 'Save Query',
            type: 'submit',
            variant: 'outlined',
        },
        {
            label: 'Update Results',
            onClick: (values) => values && handleUpdateResults(values),
            variant: 'contained',
        },
    ];
    return (
        <>
            <Button
                endIcon={
                    <ChevronIcon
                        style={{
                            fill: theme.palette.brandLightTurq,
                        }}
                    />
                }
                aria-describedby={id}
                variant={'outlined'}
                onClick={handlePopoverOpen}>
                All Services
            </Button>
            <BasePopover
                open={open}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}>
                <Card className={classes.root}>
                    <CardHeader
                        title={'Select all that apply'}
                        className={classes.header}
                    />
                    <CardContent>
                        <BaseFilter
                            initialFilters={{}}
                            id={'payment-filter'}
                            config={filterConfig}
                            onSubmit={handleSaveFilter}
                            buttonsConfig={buttonsConfig}
                        />
                    </CardContent>
                </Card>
            </BasePopover>
        </>
    );
}
const useStyles = makeStyles()(() => ({
    root: {
        width: 420,
        padding: theme.spacing(2),
    },
    header: {
        backgroundColor: theme.palette.backgroundB.backgroundB4,
        color: theme.palette.neutral.neutral7,
        display: 'flex',
        justifyContent: 'space-between',
        '& .MuiCardHeader-title': {
            fontSize: 14,
            fontWeight: 600,
        },
    },
}));

const dateRangeList = [
    { id: '', name: 'All' },
    { id: 'FINANCIAL_SERVICES', name: 'Financial Services' },
    { id: 'INSURANCE', name: 'Insurance' },
    { id: 'COMMUNICATIONS', name: 'Communications' },
];

const filterConfig: FormConfig[] = [
    {
        formField: {
            name: `services`,
            type: 'radio',
            label: 'Select Services',
        },
        renderProps: {
            options: dateRangeList,
            isDisabled: false,
            size: 'third',
        },
    },
];
