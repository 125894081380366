import { FieldData } from './CollapsedDetails';
import ExpandedFieldData from './FieldsCollapsedList';

export default function FieldsDetailsTab() {
    return <ExpandedFieldData data={mockFieldData} />;
}
export const mockFieldData: FieldData[] = [
    {
        id: '121212121',
        year: '2020',
        cropData: [
            {
                crop: 'Corn',
                pesticideApplication: '05/10/2020',
                windSpeed: '12 mph',
                yield: '180 bushels/acre',
                pesticideTradeName: 'Roundup Ready',
                epaRegulation: 'EPA Reg. No. 524-537',
                plantingStart: '04/15/2020',
                licenseName: 'AgriPro',
                rate: '1.5 oz/acre',
                plantingEnd: '04/20/2020',
                temperature: '72°F',
                operatorName: 'John Smith',
                harvest: '10/10/2020',
                windDirection: 'Northwest',
            },
            {
                crop: 'Soybeans',
                pesticideApplication: '05/15/2020',
                windSpeed: '10 mph',
                yield: '50 bushels/acre',
                pesticideTradeName: 'Liberty Link',
                epaRegulation: 'EPA Reg. No. 264-829',
                plantingStart: '04/25/2020',
                licenseName: 'Pioneer',
                rate: '2.0 oz/acre',
                plantingEnd: '04/30/2020',
                temperature: '70°F',
                operatorName: 'John Smith',
                harvest: '10/20/2020',
                windDirection: 'West',
            },
        ],
        soilSample: {
            name: 'SoilSample_2020.pdf',
            size: 500000,
            type: 'application/pdf',
        } as File,
        boundary: {
            name: 'Boundary_2020.kml',
            size: 250000,
            type: 'application/vnd.google-earth.kml+xml',
        } as File,
    },
    {
        id: '1212eee12121',
        year: '2021',
        cropData: [
            {
                crop: 'Wheat',
                pesticideApplication: '05/08/2021',
                windSpeed: '15 mph',
                yield: '70 bushels/acre',
                pesticideTradeName: 'Fusilade DX',
                epaRegulation: 'EPA Reg. No. 100-1072',
                plantingStart: '03/25/2021',
                licenseName: 'Dekalb',
                rate: '1.8 oz/acre',
                plantingEnd: '03/30/2021',
                temperature: '68°F',
                operatorName: 'Emily Davis',
                harvest: '08/15/2021',
                windDirection: 'East',
            },
            {
                crop: 'Corn',
                pesticideApplication: '05/18/2021',
                windSpeed: '14 mph',
                yield: '185 bushels/acre',
                pesticideTradeName: 'Roundup PowerMax',
                epaRegulation: 'EPA Reg. No. 524-549',
                plantingStart: '04/12/2021',
                licenseName: 'AgriGold',
                rate: '1.6 oz/acre',
                plantingEnd: '04/18/2021',
                temperature: '73°F',
                operatorName: 'Emily Davis',
                harvest: '10/12/2021',
                windDirection: 'Northwest',
            },
        ],
        soilSample: {
            name: 'SoilSample_2021.pdf',
            size: 520000,
            type: 'application/pdf',
        } as File,
        boundary: {
            name: 'Boundary_2021.kml',
            size: 255000,
            type: 'application/vnd.google-earth.kml+xml',
        } as File,
    },
    {
        id: '1212eee1ww2121',
        year: '2022',
        cropData: [
            {
                crop: 'Soybeans',
                pesticideApplication: '05/12/2022',
                windSpeed: '13 mph',
                yield: '52 bushels/acre',
                pesticideTradeName: 'Liberty Link',
                epaRegulation: 'EPA Reg. No. 264-829',
                plantingStart: '04/22/2022',
                licenseName: 'Asgrow',
                rate: '2.1 oz/acre',
                plantingEnd: '04/27/2022',
                temperature: '71°F',
                operatorName: 'Michael Brown',
                harvest: '10/18/2022',
                windDirection: 'Southwest',
            },
            {
                crop: 'Wheat',
                pesticideApplication: '05/14/2022',
                windSpeed: '16 mph',
                yield: '75 bushels/acre',
                pesticideTradeName: 'Axial XL',
                epaRegulation: 'EPA Reg. No. 100-1232',
                plantingStart: '03/20/2022',
                licenseName: 'Pioneer',
                rate: '1.7 oz/acre',
                plantingEnd: '03/25/2022',
                temperature: '69°F',
                operatorName: 'Michael Brown',
                harvest: '08/20/2022',
                windDirection: 'East',
            },
        ],
        soilSample: {
            name: 'SoilSample_2022.pdf',
            size: 540000,
            type: 'application/pdf',
        } as File,
        boundary: {
            name: 'Boundary_2022.kml',
            size: 260000,
            type: 'application/vnd.google-earth.kml+xml',
        } as File,
    },
    {
        id: '1212eee1wwewewew2121',
        year: '2023',
        cropData: [
            {
                crop: 'Corn',
                pesticideApplication: '05/16/2023',
                windSpeed: '12 mph',
                yield: '190 bushels/acre',
                pesticideTradeName: 'Roundup WeatherMAX',
                epaRegulation: 'EPA Reg. No. 524-537',
                plantingStart: '04/10/2023',
                licenseName: 'Channel',
                rate: '1.5 oz/acre',
                plantingEnd: '04/15/2023',
                temperature: '74°F',
                operatorName: 'James Wilson',
                harvest: '10/05/2023',
                windDirection: 'North',
            },
            {
                crop: 'Soybeans',
                pesticideApplication: '05/19/2023',
                windSpeed: '11 mph',
                yield: '54 bushels/acre',
                pesticideTradeName: 'Liberty',
                epaRegulation: 'EPA Reg. No. 264-828',
                plantingStart: '04/22/2023',
                licenseName: 'Stine',
                rate: '2.2 oz/acre',
                plantingEnd: '04/27/2023',
                temperature: '72°F',
                operatorName: 'James Wilson',
                harvest: '10/15/2023',
                windDirection: 'West',
            },
        ],
        soilSample: {
            name: 'SoilSample_2023.pdf',
            size: 530000,
            type: 'application/pdf',
        } as File,
        boundary: {
            name: 'Boundary_2023.kml',
            size: 258000,
            type: 'application/vnd.google-earth.kml+xml',
        } as File,
    },
    {
        id: '1212eee1wwewewe2121',
        year: '2024',
        cropData: [
            {
                crop: 'Wheat',
                pesticideApplication: '05/07/2024',
                windSpeed: '14 mph',
                yield: '72 bushels/acre',
                pesticideTradeName: 'Osprey Xtra',
                epaRegulation: 'EPA Reg. No. 432-1549',
                plantingStart: '03/18/2024',
                licenseName: 'WestBred',
                rate: '1.9 oz/acre',
                plantingEnd: '03/23/2024',
                temperature: '70°F',
                operatorName: 'Olivia Green',
                harvest: '08/10/2024',
                windDirection: 'East',
            },
            {
                crop: 'Corn',
                pesticideApplication: '05/17/2024',
                windSpeed: '13 mph',
                yield: '195 bushels/acre',
                pesticideTradeName: 'Durango DMA',
                epaRegulation: 'EPA Reg. No. 62719-556',
                plantingStart: '04/09/2024',
                licenseName: 'NK',
                rate: '1.7 oz/acre',
                plantingEnd: '04/14/2024',
                temperature: '75°F',
                operatorName: 'Olivia Green',
                harvest: '10/07/2024',
                windDirection: 'Northwest',
            },
        ],
        soilSample: {
            name: 'SoilSample_2024.pdf',
            size: 550000,
            type: 'application/pdf',
        } as File,
        boundary: {
            name: 'Boundary_2024.kml',
            size: 265000,
            type: 'application/vnd.google-earth.kml+xml',
        } as File,
    },
];
