import React, { FunctionComponent, SVGProps } from 'react';
import { EmailStatus } from './AllProfilesTable';
import { Alert, Box, Typography } from '@mui/material';
import { ReactComponent as IconYes } from '../../assets/icons/check_yes_icon.svg';
import { ReactComponent as IconNo } from '../../assets/icons/check_no_icon.svg';
import { ReactComponent as IconTimer } from '../../assets/icons/timer_icon.svg';
import { ReactComponent as IconHand } from '../../assets/icons/hand_icon.svg';
import theme from '../../styles/theme';
import { makeStyles } from 'tss-react/mui';

interface OwnProps {
    emailStatus: EmailStatus;
}
interface EmailVerificationMapper {
    icon: FunctionComponent<SVGProps<SVGSVGElement>>;
    title: string;
    color: string;
}
export default function VerificationAlert({ emailStatus }: OwnProps) {
    const { classes } = useStyles();
    return (
        <Alert
            icon={<AlertIcon emailStatus={emailStatus} />}
            className={classes.alert}>
            <Typography variant={'font16'} fontWeight={'medium'} p={3}>
                {emailAlertVerificationMapper[emailStatus].title}
            </Typography>
        </Alert>
    );
}

function AlertIcon({ emailStatus }: OwnProps) {
    const { classes } = useStyles();
    const Icon = emailAlertVerificationMapper[emailStatus].icon;
    return (
        <Box
            display={'flex'}
            p={1}
            alignItems={'center'}
            bgcolor={emailAlertVerificationMapper[emailStatus].color}>
            <Icon className={classes.icon} />
        </Box>
    );
}

const useStyles = makeStyles()(() => ({
    alert: {
        width: '100%',
        borderRadius: 4,
        backgroundColor: theme.palette.neutralWhite,
    },
    icon: {
        width: 24,
        height: 24,
        cursor: 'pointer',
        fill: theme.palette.neutralWhite,
    },
}));

const emailAlertVerificationMapper: Record<
    EmailStatus,
    EmailVerificationMapper
> = {
    VERIFIED: {
        icon: IconYes,
        title: 'Email address verified',
        color: theme.palette.brandLimeGreen,
    },
    UNVERIFIED: {
        icon: IconNo,
        title: 'Verification: Unverified',
        color: theme.palette.statusRed,
    },
    PENDING: {
        icon: IconTimer,
        title: 'Verification: Pending',
        color: theme.palette.statusYellow,
    },
    FAILED: {
        icon: IconHand,
        title: 'Verification: Failed',
        color: theme.palette.statusRed,
    },
};
