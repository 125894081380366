import React, { useMemo } from 'react';
import DocumentsList from '../../../../reusable/DocumentsList/DocumentsList';
import { Grid } from '@mui/material';
import Loader from '../../../../design/BaseLoader';
import { useGetSharedDocumentsPreviewQuery } from '../../../../api/sharedData/sharedDataTempAccessAPI';

interface OwnProps {
    landPartnerId: string;
    visibilityLevel: string;
}

export default function SharedDocumentsPreviewPage({
    landPartnerId,
    visibilityLevel,
}: OwnProps) {
    const { data: documents, isLoading: isSharedDocumentsLoading } =
        useGetSharedDocumentsPreviewQuery({
            page: 0,
            size: 100,
            landPartnerId,
            visibilityLevel,
        });

    const data = useMemo(() => {
        return documents?.data || [];
    }, [documents]);

    if (isSharedDocumentsLoading) {
        return (
            <Grid
                container
                alignItems={'center'}
                justifyContent={'center'}
                flexDirection={'column'}>
                <Loader />
            </Grid>
        );
    }

    return <DocumentsList cards={data || []} />;
}
