import React, { ReactNode } from 'react';

import { Grid, IconButton, Typography, Modal as MUIModal } from '@mui/material';

import { ReactComponent as CloseIcon } from '../../assets/icons/close_icon.svg';
import useStyles from './Modal.styles';

export interface ModalProps {
    isShowing: boolean;
    hide: () => void;
    component: ReactNode;
    header?: string;
}

export default function Modal({
    isShowing,
    hide,
    component,
    header,
}: ModalProps) {
    const { classes } = useStyles();

    return (
        <MUIModal open={isShowing} onClose={hide}>
            <Grid className={classes.modalWrapper}>
                <Grid className={classes.modal}>
                    <Grid className={classes.modalHeader}>
                        {header && (
                            <Typography className={classes.modalHeaderTitle}>
                                {header}
                            </Typography>
                        )}
                        <IconButton
                            className={classes.modalCloseButton}
                            onClick={hide}
                            size="large">
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                    <Grid
                        container
                        spacing={2}
                        className={classes.wrapperComponent}>
                        {component}
                    </Grid>
                </Grid>
            </Grid>
        </MUIModal>
    );
}
