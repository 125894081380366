import IntegrationDataCard, { IntegrationData } from './IntegrationDataCard';
import { FormConfig } from '../../design/Forms/interfaces';
import AppTypography from '../../design/AppTypography';
import React from 'react';
import { Box, Divider } from '@mui/material';
import SyncLabel from '../../design/Labels/SyncLabel';
import AppFormCard from './AppFormCard';
import theme from '../../styles/theme';

export interface Integration {
    title: string;
    data: IntegrationData;
    formConfig: FormConfig[];
}

export default function IntegrationRow({
    title,
    data,
    formConfig,
}: Integration) {
    return (
        <Box display="flex" flexDirection="column" rowGap={1} width="100%">
            <AppTypography
                variant="font14"
                fontWeight="medium"
                color="text.neutral10"
                text={title}
            />
            <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width="100%">
                <IntegrationDataCard data={data} />
                <Divider
                    orientation={'horizontal'}
                    sx={{
                        borderColor: theme.palette.limeGreen.limeGreenShade2,
                        height: '2px',
                        minWidth: '190px',
                    }}
                />
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    mx={2}>
                    <SyncLabel />
                </Box>
                <Divider
                    orientation={'horizontal'}
                    sx={{
                        borderColor: theme.palette.limeGreen.limeGreenShade2,
                        height: '2px',
                        minWidth: '190px',
                    }}
                />
                <AppFormCard formConfig={formConfig} />
            </Box>
        </Box>
    );
}
