import { makeStyles } from 'tss-react/mui';
import { Box, Typography } from '@mui/material';
import theme from '../../../styles/theme';
import SharedDataBannerAlert from './SharedDataBannerAlert';

export default function SharedDataPreviewBanner({
    landPartnerName,
}: {
    landPartnerName: string;
}) {
    const { classes } = useStyles();
    return (
        <Box className={classes.background}>
            <Box
                width={'100%'}
                p={theme.spacing(2, 2)}
                display={'flex'}
                alignItems={'center'}
                justifyContent={'space-between'}
                zIndex={2}>
                <Typography
                    variant="font14"
                    color={'text.neutralBlack'}
                    fontWeight={'bold'}
                    style={{ flexGrow: 1 }}>
                    {`Preview prepared for ${landPartnerName}`}
                </Typography>
                <SharedDataBannerAlert />
            </Box>
        </Box>
    );
}

const useStyles = makeStyles()(() => ({
    background: {
        display: 'flex',
        width: `100%`,
        height: 75,
        backgroundColor: theme.palette.limeGreen.limeGreenTint9,
    },
    backgroundLogo: {
        position: 'absolute',
        right: 0,
        top: 0,
        zIndex: 1,
    },
}));
