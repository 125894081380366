import GenericTable from '../../../design/GenericTable/GenericTable';
import { Typography } from '@mui/material';
import React from 'react';
import usePotentialClientsTable from './usePotentialClientsTable';

export default function PotentialClientsTable() {
    const { tableConfig, data } = usePotentialClientsTable();
    return (
        <>
            <Typography variant={'font16'} fontWeight={'bold'}>
                Potential Clients
            </Typography>
            <GenericTable data={data} tableConfig={tableConfig} />
        </>
    );
}
