import React, { useState } from 'react';
import {
    Stepper as MUIStepper,
    Step,
    Box,
    Typography,
    Collapse,
    Paper,
    IconButton,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import theme from '../../styles/theme';
import clsx from 'clsx';
import { ReactComponent as ExpandIcon } from '../../assets/icons/chevron_down_icon.svg';
interface StepGroup {
    groupLabel: string;
    steps: string[];
}
interface OwnProps {
    stepNumber: number;
    stepGroups: StepGroup[];
}

export default function ExpandableGroupStepper({
    stepNumber,
    stepGroups,
}: OwnProps) {
    const { classes } = useStyles();

    const [expandedGroup, setExpandedGroup] = useState<number | null>(0);

    const handleGroupClick = (groupIndex: number) => {
        setExpandedGroup(expandedGroup === groupIndex ? null : groupIndex);
    };

    return (
        <Box display={'flex'} flexDirection="column" rowGap={2}>
            {stepGroups.map((group, groupIndex) => (
                <Box key={group.groupLabel}>
                    <Paper
                        elevation={3}
                        sx={{
                            mb: 2,
                            p: 2,
                            display: 'flex',
                            justifyContent: 'space-between',
                            backgroundColor:
                                expandedGroup === groupIndex
                                    ? theme.palette.backgroundB.backgroundB4
                                    : theme.palette.backgroundB.backgroundB1,
                        }}>
                        <Typography
                            variant="font16"
                            fontWeight={'medium'}
                            color={'text.neutral7'}
                            onClick={() => handleGroupClick(groupIndex)}
                            style={{ cursor: 'pointer' }}>
                            {group.groupLabel}
                        </Typography>
                        <IconButton
                            onClick={() => handleGroupClick(groupIndex)}>
                            <ExpandIcon
                                className={clsx(
                                    expandedGroup === groupIndex &&
                                        classes.expanded,
                                )}
                            />
                        </IconButton>
                    </Paper>

                    <Collapse
                        in={expandedGroup === groupIndex}
                        timeout="auto"
                        unmountOnExit>
                        <MUIStepper
                            activeStep={stepNumber}
                            orientation="vertical"
                            className={classes.stepper}>
                            {group.steps.map((step, index) => (
                                <Step
                                    key={step}
                                    completed={index < stepNumber}
                                    className={classes.step}>
                                    {step}
                                </Step>
                            ))}
                        </MUIStepper>
                    </Collapse>
                </Box>
            ))}
        </Box>
    );
}

const useStyles = makeStyles()(() => ({
    stepper: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: 12,
        '& .MuiStepConnector-line': {
            display: 'none',
        },
    },
    step: {
        fontWeight: 600,
        fontSize: 16,
        color: theme.palette.brandLightTurq,
        paddingLeft: theme.spacing(4),
    },
    expanded: {
        width: 24,
        height: 24,
        transform: 'rotate(90deg)',
    },
}));
