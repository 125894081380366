import React from 'react';
import TabPanelWithoutNavigation from '../../../design/TabsPanel/TabPanelWithoutNavigation';
import SharedLeasesPreviewPage from './Lease/SharedLeasesPreviewPage';
import SharedPaymentsPreviewPage from './Payment/SharedPaymentsPreviewPage';
import SharedDocumentsPreviewPage from './Document/SharedDocumentsPreviewPage';
import { Box } from '@mui/material';

const tabsConfig = (landPartnerId: string, visibilityLevel: string) => [
    {
        label: 'Leases',
        content: (
            <SharedLeasesPreviewPage
                landPartnerId={landPartnerId}
                visibilityLevel={visibilityLevel}
            />
        ),
        value: 0,
    },
    {
        label: 'Payments',
        content: (
            <SharedPaymentsPreviewPage
                landPartnerId={landPartnerId}
                visibilityLevel={visibilityLevel}
            />
        ),
        value: 1,
    },
    {
        label: 'Documents',
        content: (
            <SharedDocumentsPreviewPage
                landPartnerId={landPartnerId}
                visibilityLevel={visibilityLevel}
            />
        ),
        value: 2,
    },
];

interface OwnProps {
    landPartnerId: string;
    visibilityLevel: string;
}

export default function SharedDataAllPreviewTabs({
    landPartnerId,
    visibilityLevel,
}: OwnProps) {
    return (
        <Box sx={{ padding: '20px', paddingLeft: '5px' }} borderRadius={2}>
            <TabPanelWithoutNavigation
                tabItems={tabsConfig(landPartnerId, visibilityLevel)}
            />
        </Box>
    );
}
