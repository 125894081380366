import React, { useState, useEffect } from 'react';
import Confetti from 'react-confetti';
import { useWindowSize } from 'react-use';
import theme from '../../../styles/theme';
import { Box, Button } from '@mui/material';
import AppTypography from '../../../design/AppTypography';
import LikeLabel from '../../../design/Labels/LikeLabel';
import FileIconView from '../../../reusable/FileIconView';
import ImageOAKEN from '../../../assets/images/ways.png';
import { NavLink, useLocation } from 'react-router-dom';

export default function SuccessUploadPage() {
    const { width, height } = useWindowSize();
    const { state } = useLocation();
    const [showConfetti, setShowConfetti] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowConfetti(false);
        }, 12000);

        return () => clearTimeout(timer);
    }, []);

    return (
        <Box
            width={'100wv'}
            height={'100vh'}
            display={'flex'}
            flexDirection={'column'}
            rowGap={3}
            bgcolor={theme.palette.backgroundB.backgroundB3}>
            {showConfetti && <Confetti width={width} height={height} />}
            <Box
                rowGap={3}
                mt={10}
                ml={2}
                p={theme.spacing(3, 4, 2)}
                display={'flex'}
                flexDirection={'column'}
                width={'1100px'}
                bgcolor={theme.palette.neutralWhite}
                borderRadius={'16px'}>
                <Box
                    display={'flex'}
                    columnGap={4}
                    width={'100%'}
                    alignItems={'center'}>
                    <LikeLabel />
                    <AppTypography
                        variant="font36"
                        fontWeight={'light'}
                        color="text.neutral9"
                        text={`Hi, ${state?.name} `}
                    />
                </Box>
                <AppTypography
                    variant="font20"
                    fontWeight={'regular'}
                    color="text.neutral9"
                    text={`Thank you, your documents have been successfully uploaded.`}
                />
                <Box
                    width={'50%'}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '90px',
                        padding: theme.spacing(2, 4),
                        border: `2px solid ${theme.palette.neutral.neutral2}`,
                    }}>
                    <AppTypography
                        variant="font16"
                        fontWeight={'regular'}
                        color="text.secondary"
                        text={`Upload Successful`}
                    />
                    <Box
                        sx={{
                            display: 'flex',
                            columnGap: 1,
                        }}>
                        <FileIconView name={state?.fileName} />
                        <AppTypography
                            variant="font12"
                            fontWeight={'medium'}
                            color="text.light"
                            text={state?.fileName}
                        />
                    </Box>
                </Box>
            </Box>
            <Box
                p={theme.spacing(3, 4, 2)}
                display={'flex'}
                columnGap={2}
                ml={2}
                width={'1100px'}
                alignItems={'center'}
                bgcolor={theme.palette.neutralWhite}
                borderRadius={'16px'}>
                <Box width={300} height={231}>
                    <img
                        src={ImageOAKEN}
                        width={300}
                        height={231}
                        alt={'OAKEN-5ways'}
                    />
                </Box>
                <Box display={'flex'} flexDirection={'column'} rowGap={3}>
                    <AppTypography
                        variant="font20"
                        fontWeight={'regular'}
                        color="text.neutral9"
                        text={'Did you know?'}
                    />
                    <AppTypography
                        variant="font20"
                        flexWrap={true}
                        fontWeight={'regular'}
                        color="text.link"
                        text={
                            'About 40% of all agricultural land in the U.S. is being rented, one of the most important jobs within many farmers’ operations is landowner relationship management.'
                        }
                    />{' '}
                    <Box pt={4}>
                        <AppTypography
                            variant="font14"
                            flexWrap={true}
                            fontWeight={'regular'}
                            color="text.neutral9"
                            text={
                                'Learn the modern strategies to building land partner relationships.'
                            }
                        />
                    </Box>
                    <Box
                        width={'100%'}
                        display={'flex'}
                        justifyContent={'flex-end'}>
                        <NavLink
                            target={'_blank'}
                            to={
                                'https://www.oaken.ag/case-studies-ebooks/blog-post-title-two-25wj4'
                            }>
                            <Button variant={'contained'}>
                                Download Our Free Guide (PDF)
                            </Button>
                        </NavLink>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}
