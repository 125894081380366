import React from 'react';

import { Box, Typography } from '@mui/material';

import { Table } from '../../design/GenericTable/GenericTable';
import { formatCurrency } from '../../utils/fieldMasks';
import { FsaId } from './LeasePayments';

export const tableConfig: Table<FsaId> = {
    columns: [
        {
            header: {
                label: 'Field Name',
                sortable: true,
                sorting: { id: 'landName', direction: 'asc' },
            },
            cellRender: (land) => (
                <Typography
                    variant={'font14'}
                    color={'text.link'}
                    fontWeight={'medium'}>
                    {land.landName}
                </Typography>
            ),
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
        },
        {
            header: {
                label: 'FSA ID',
                sortable: true,
                sorting: { id: 'fsaId', direction: 'asc' },
            },
            cellRender: (land) => (
                <Typography
                    variant={'font14'}
                    color={'text.link'}
                    fontWeight={'medium'}>
                    {land.fsaId}
                </Typography>
            ),
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
        },
        {
            header: {
                label: 'Tract/TFP Number(s)',
                sortable: true,
                sorting: { id: 'ftpNumber', direction: 'asc' },
            },
            cellRender: (tableData) => (
                <>
                    {tableData.tfpNumber?.map((number, index) => (
                        <Box key={index}>
                            <Typography
                                variant={'font14'}
                                color={'text.link'}
                                fontWeight={'medium'}>
                                {number}
                            </Typography>
                        </Box>
                    ))}
                </>
            ),
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
        },
        {
            header: {
                label: 'Total Acres',
                sortable: true,
                sorting: { id: 'totalAcres', direction: 'desc' },
            },
            cellRender: (land) => <Typography>{land.totalAcres}</Typography>,
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
        },
        {
            header: {
                label: 'Tillable Acres',
                sortable: true,
                sorting: { id: 'tillableAcres', direction: 'desc' },
            },
            cellRender: (land) => <Typography>{land.tillableAcres}</Typography>,
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
        },
        {
            header: {
                label: 'Rent Per Acre',
                sortable: true,
                sorting: { id: 'rentPerAcreRate', direction: 'desc' },
            },
            cellRender: (land) => (
                <Typography>{formatCurrency(land.rentPerAcreRate)}</Typography>
            ),
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
        },
        {
            header: {
                label: 'Total Annual Rent',
                sortable: true,
                sorting: { id: 'totalAnnualRent', direction: 'desc' },
            },
            cellRender: (land) => (
                <Typography>{formatCurrency(land.totalAnnualRent)}</Typography>
            ),
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
        },
    ],
    multiselect: false,
    expandable: false,
};
