import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import TaskCalendar from '../../../../design/Calendar/TaskCalendar';
import PromptCard, {
    PromptCardProps,
} from '../../../../design/Cards/PromptCard';
import theme from '../../../../styles/theme';

export default function UpcomingEventsPreview() {
    const [eventsDate, setEventsDate] = useState<Date>(new Date());
    return (
        <Box marginTop={theme.spacing(5)}>
            <Typography pl={3} variant="font16" fontWeight="bold">
                Upcoming Events
            </Typography>
            <Box
                display={'flex'}
                flexDirection={'column'}
                rowGap={1}
                p={2}
                borderRadius={2}
                marginTop={theme.spacing(2)}
                bgcolor={theme.palette.neutralWhite}>
                <TaskCalendar
                    handleDate={() =>
                        // eslint-disable-next-line @typescript-eslint/no-empty-function
                        {}
                    }
                    highlightedDays={[]}
                    onChange={(newValue) => setEventsDate(newValue)}
                    value={eventsDate}
                />
                <Box display={'flex'} flexDirection={'column'} gap={2} pl={3}>
                    {promptCardConfigEvents.map((activity, index) => (
                        <PromptCard key={index} {...activity} />
                    ))}
                </Box>
            </Box>
        </Box>
    );
}
const promptCardConfigEvents: PromptCardProps[] = [
    {
        type: 'info',
        title: 'Birthday: Jim Thompson',
        description: 'All day',
    },
    {
        type: 'info',
        title: 'Internal meeting',
        description: '6:00 PM',
    },
];
