import React from 'react';
import { useSorting } from '../../design/GenericTable/useSorting';
import { usePagination } from '../../design/GenericTable/useBackendPagination';
import { Table, TableColumn } from '../../design/GenericTable/GenericTable';
import { Box, IconButton } from '@mui/material';

import LinkText from '../../design/Fields/LinkText';
import StatusTag, { Tag } from '../../design/StatusTag/StatusTag';
import PATHS from '../../router/paths';
import { ReactComponent as EditIcon } from '../../assets/icons/edit_icon.svg';
import { useNavigate } from 'react-router-dom';
import { useSettingsFromStorage } from '../../hooks/useSettingsFromStorage';
import { useSaveSettings } from '../../hooks/useSaveSettings';
import ExpandComponent from './ExpandedComponent';
import { LandPartnerTableView } from '../LandPartners/LandPartnersTable';
import useManagePermission from '../../hooks/useManagePermission';
import { useGetGrowersQuery } from '../../api/growers/growersAPI';
import { useSelection } from '../../design/GenericTable/useSelection';
import { isColumnHidden } from '../utils';
import { ColumnConfig } from '../../api/users/customizeConfigAPI';

export interface GrowersTable {
    grower: Grower;
    farmName: string;
    contactNumber: string;
    status: GrowerStatus;
}
interface Grower {
    firstName: string;
    lastName: string;
    avatar: string;
}
type GrowerStatus = 'ACTIVE' | 'INACTIVE' | 'PROSPECT' | 'SUSPENDED';
interface OwnProps {
    config: ColumnConfig[];
}
export default function useGrowersTable({ config }: OwnProps) {
    const {
        selected: selectedRows,
        isSelected,
        toggleSelection,
        toggleSelectionAll,
        isSelectedAll,
    } = useSelection<LandPartnerTableView>();

    const navigate = useNavigate();
    const { checkPermission } = useManagePermission();
    const { settings, saveSettings } = useSettingsFromStorage('GROWERS');
    const { sort, page, sortDirection, size } = settings;

    const { sortKey, sortOrder, handleSort } = useSorting(
        sort || 'createdAt',
        sortDirection || 'desc',
    );
    const {
        currentPage,
        itemsPerPage,
        handlePageChange,
        handleItemsPerPageChange,
    } = usePagination(page || 1, size || 25);

    useSaveSettings({
        settings,
        saveSettings,
        sortKey,
        sortOrder,
        currentPage,
        itemsPerPage,
    });

    const { data, isLoading } = useGetGrowersQuery({
        sort: `${sortKey},${sortOrder}`,
        page: currentPage - 1,
        size: itemsPerPage,
    });

    const columns: TableColumn<LandPartnerTableView>[] = [
        {
            header: {
                customizeKey: 'name',
                label: 'Grower Name',
                sortable: true,
                sorting: {
                    id: 'name',
                    direction: sortKey === 'name' ? sortOrder : 'desc',
                    isSorted: sortKey === 'name',
                },
                onClick: handleSort,
                isHidden: isColumnHidden(config, 'name', false),
            },
            cellRender: (grower) => (
                <LinkText
                    to={`/${PATHS.landpartners}/${PATHS.growers}/${grower?.id}`}
                    text={grower?.name}
                    variant={'font14'}
                />
            ),
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
            isHidden: isColumnHidden(config, 'name', false),
        },
        {
            header: {
                customizeKey: 'primaryContactFirstName',
                label: 'Primary Contact Name',
                sortable: true,
                sorting: {
                    id: 'primaryContact.firstName',
                    direction:
                        sortKey === 'primaryContact.firstName'
                            ? sortOrder
                            : 'desc',
                    isSorted: sortKey === 'primaryContact.firstName',
                },
                onClick: handleSort,
                isHidden: isColumnHidden(
                    config,
                    'primaryContactFirstName',
                    false,
                ),
            },
            cellRender: (grower) => (
                <LinkText
                    to={`/${PATHS.landpartners}/${PATHS.allProfiles}/${grower?.primaryContact?.id}`}
                    text={`${grower?.primaryContact.firstName} ${grower?.primaryContact.lastName}`}
                    variant={'font14'}
                />
            ),
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
            isHidden: isColumnHidden(config, 'primaryContactFirstName', false),
        },

        {
            header: {
                customizeKey: 'status',
                label: 'Status',
                sortable: true,
                sorting: {
                    id: 'status',
                    direction: sortKey === 'status' ? sortOrder : 'desc',
                    isSorted: sortKey === 'status',
                },
                onClick: handleSort,
                isHidden: isColumnHidden(config, 'status', false),
            },
            cellRender: (grower) => <StatusTag {...statusMap[grower.status]} />,
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
            isHidden: isColumnHidden(config, 'status', false),
        },
        {
            header: {
                customizeKey: 'actions',
                label: 'Actions',
                sortable: false,
                isHidden:
                    !checkPermission('growers', 'modify') ||
                    isColumnHidden(config, 'actions', false),
            },
            cellRender: (grower) => (
                <Box>
                    <IconButton
                        onClick={() =>
                            navigate(`${grower.id}/${PATHS.update}`)
                        }>
                        <EditIcon />
                    </IconButton>
                </Box>
            ),
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
            isHidden:
                !checkPermission('growers', 'modify') ||
                isColumnHidden(config, 'actions', false),
        },
    ];

    const tableConfig: Table<LandPartnerTableView> = {
        columns,
        multiselect: true,
        expandable: true,
        ExpandComponent: ExpandComponent,
        handlePageChange: handlePageChange,
        handleItemsPerPageChange: handleItemsPerPageChange,
        isSelected,
        toggleSelection,
        toggleSelectionAll,
        isSelectedAll,
        initialPage: page,
        initialPageSize: size,
    };
    const totalCount = data?.totalCount || 0;
    return {
        data: data?.data || [],
        totalCount,
        tableConfig,
        isLoading,
        selectedRows,
        toggleSelectionAll,
    };
}
const statusMap: Record<GrowerStatus, Tag> = {
    ACTIVE: {
        status: 'success',
        text: 'Active',
    },
    INACTIVE: {
        status: 'disabled',
        text: 'Inactive',
    },
    PROSPECT: {
        status: 'warning',
        text: 'Prospect',
    },
    SUSPENDED: {
        status: 'error',
        text: 'Do not contact',
    },
};
