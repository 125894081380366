import FormScreenWrapper from '../FormScreenWrapper';
import { Typography } from '@mui/material';
import {
    composeValidators,
    maxLengthForArrayField,
    minLengthForArrayField,
} from '../../../utils/validation';

import FormPage from '../../../reusable/FormUI/FormPage';
import { FormConfig } from '../../../design/Forms/interfaces';
import { useGetLandPartnersShortQuery } from '../../../api/landpartners/landPartnersAPI';
import EmergencyContactCardList from './EmergencyContactCardList';

export default function EmergencyContactsForm() {
    const { data: initData } = useGetLandPartnersShortQuery({
        sort: `name,asc`,
        page: 0,
        size: 1000,
    });

    const formConfig: FormConfig[] = [
        {
            formField: {
                name: 'emergencyContacts',
                label: '',
                type: 'autocomplete',
                scheme: 'full',
                validation: composeValidators(
                    maxLengthForArrayField(256),
                    minLengthForArrayField(1),
                ),
            },
            renderProps: {
                freeSolo: false,
                isDisabled: false,
                size: 'small',
                options: initData?.data || [],
            },
        },
    ];

    return (
        <FormScreenWrapper title="Emergency Contacts">
            <Typography variant="font16" color="color.neutral9">
                When the unexpected strikes, clarity is key. From your daily
                operations team to your insurance agent, local emergency
                services, agronomist, attorney, equipment tech, and government
                agents—these are the people who will help you navigate the
                challenges and get you back on track. Take a moment to gather
                and update your list of these critical contacts.
            </Typography>
            <FormPage formConfig={formConfig} />
            <EmergencyContactCardList />
        </FormScreenWrapper>
    );
}
