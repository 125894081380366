import React from 'react';

import { Navigate, Outlet, useLocation } from 'react-router-dom';
import PATHS from '../../router/paths';
import { Box, useTheme } from '@mui/material';
export default function Settings() {
    const { pathname } = useLocation();
    const theme = useTheme();

    if (pathname === `/${PATHS.settings}`)
        return <Navigate to={`/${PATHS.settings}/${PATHS.orgAndUsers}`} />;

    return (
        <Box sx={{ padding: theme.spacing(1, 2) }}>
            <Outlet />
        </Box>
    );
}
