import { Button, Typography } from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import {
    useGetUsersByPOSTQuery,
    useLazyGetUsersByPOSTQuery,
} from '../../../../api/users/usersAPI';
import GenericTable, {
    Table,
} from '../../../../design/GenericTable/GenericTable';
import { useSorting } from '../../../../design/GenericTable/useSorting';
import { usePagination } from '../../../../design/GenericTable/useBackendPagination';
import { useSettingsFromStorage } from '../../../../hooks/useSettingsFromStorage';
import { useSaveSettings } from '../../../../hooks/useSaveSettings';
import useHandleSubmitRTK from '../../../../hooks/useHandleSubmit';
import { useReInviteMutation } from '../../../../api/auth/authAPI';
import { dateTimeConverterFromUTC } from '../../../../utils/dateFormat';
import { User } from '../AllUsers/UsersList';

export default function InactiveUsersList() {
    const { settings, saveSettings } =
        useSettingsFromStorage('ALL_INACTIVE_USERS');
    const { handleSubmit } = useHandleSubmitRTK({
        useRtkHook: useReInviteMutation,
    });
    const { sort, page, sortDirection, size } = settings;

    const { sortKey, sortOrder, handleSort } = useSorting(
        sort || 'firstName',
        sortDirection || 'asc',
    );

    function handleResendLink(id: string) {
        handleSubmit({ id });
    }

    const {
        currentPage,
        itemsPerPage,
        handlePageChange,
        handleItemsPerPageChange,
    } = usePagination(page || 1, size || 25);
    useSaveSettings({
        settings,
        saveSettings,
        sortKey,
        sortOrder,
        currentPage,
        itemsPerPage,
    });
    const { data: initData } = useGetUsersByPOSTQuery({
        body: {
            active: false,
        },
        params: {
            sort: `${sortKey},${sortOrder}`,
            page: currentPage - 1,
            size: itemsPerPage,
        },
    });

    const [getItems, { data: sortedData }] = useLazyGetUsersByPOSTQuery();

    useEffect(() => {
        getItems({
            body: {
                active: false,
            },
            params: {
                sort: `${sortKey},${sortOrder}`,
                page: currentPage - 1,
                size: itemsPerPage,
            },
        });
    }, [sortKey, sortOrder, currentPage, itemsPerPage]);

    const data = useMemo(() => {
        return sortedData?.data || initData?.data || [];
    }, [sortedData, initData]);

    const totalCount = useMemo(() => {
        return sortedData?.totalCount || 0;
    }, [sortedData]);

    const userTableConfig: Table<User> = {
        columns: [
            {
                header: {
                    label: 'First, Last',
                    sortable: true,
                    sorting: {
                        id: 'firstName',
                        direction: sortKey === 'firstName' ? sortOrder : 'desc',
                        isSorted: sortKey === 'firstName',
                    },
                    onClick: handleSort,
                },
                cellRender: (user: User) => (
                    <Typography
                        variant={'font14'}
                        color={'text.link'}
                        fontWeight={'medium'}>
                        {user.firstName}&nbsp;{user.lastName}
                    </Typography>
                ),
                format: { align: 'left', color: { color: 'primary' } },
                width: 'auto',
            },
            {
                header: {
                    label: 'Organization',
                    sortable: true,
                    sorting: {
                        id: 'organizationName',
                        direction:
                            sortKey === 'organizationName' ? sortOrder : 'desc',
                        isSorted: sortKey === 'organizationName',
                    },
                    onClick: handleSort,
                },
                cellRender: (user: User) => (
                    <Typography
                        variant={'font14'}
                        color={'text.link'}
                        fontWeight={'medium'}>
                        {user.organization?.name}
                    </Typography>
                ),
                format: { align: 'left', color: { color: 'primary' } },
                width: 'auto',
            },
            {
                header: {
                    label: 'Email',
                    sortable: true,
                    sorting: {
                        id: 'primaryEmail',
                        direction:
                            sortKey === 'primaryEmail' ? sortOrder : 'desc',
                        isSorted: sortKey === 'primaryEmail',
                    },
                    onClick: handleSort,
                },
                cellRender: (user: User) => (
                    <Typography
                        variant={'font14'}
                        color={'text.link'}
                        fontWeight={'medium'}>
                        {user.primaryEmail}
                    </Typography>
                ),
                format: { align: 'left', color: { color: 'primary' } },
                width: 'auto',
            },
            {
                header: {
                    label: 'Role',
                    sortable: true,
                    sorting: {
                        id: 'roleName',
                        direction: sortKey === 'roleName' ? sortOrder : 'desc',
                        isSorted: sortKey === 'roleName',
                    },
                    onClick: handleSort,
                },
                cellRender: (user: User) => (
                    <Typography
                        variant={'font14'}
                        color={'text.link'}
                        fontWeight={'medium'}>
                        {user.role && user.role.name}
                    </Typography>
                ),
                format: { align: 'left', color: { color: 'primary' } },
                width: 'auto',
            },
            {
                header: {
                    label: 'Create Date',
                    sortable: true,
                    sorting: {
                        id: 'createdAt',
                        direction: sortKey === 'createdAt' ? sortOrder : 'desc',
                        isSorted: sortKey === 'createdAt',
                    },
                    onClick: handleSort,
                },
                cellRender: (user: User) => (
                    <Typography
                        variant={'font14'}
                        color={'text.link'}
                        fontWeight={'medium'}>
                        {dateTimeConverterFromUTC(user.createdAt)}
                    </Typography>
                ),
                format: { align: 'left', color: { color: 'primary' } },
                width: 'auto',
            },
            {
                header: {
                    label: '',
                    sortable: false,
                },
                cellRender: (user: User) => (
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleResendLink(user.id)}>
                        Resend Link
                    </Button>
                ),
                format: { align: 'left', color: { color: 'primary' } },
                width: 'auto',
            },
        ],
        multiselect: false,
        expandable: false,
        handlePageChange: handlePageChange,
        handleItemsPerPageChange: handleItemsPerPageChange,
        initialPage: page,
        initialPageSize: size,
    };

    return (
        <>
            <GenericTable
                data={data}
                totalCount={totalCount}
                tableConfig={userTableConfig}
            />
        </>
    );
}
