import { FormConfig } from '../../design/Forms/interfaces';
import {
    composeValidators,
    isEmail,
    maxLength,
    required,
} from '../../utils/validation';

import { useNavigate } from 'react-router-dom';
import SubmitButton from '../../design/Buttons/SubmitButton';
import { OAKENApi } from '../../api/OAKENApi';
import { GUEST_KEY, REFRESH_GUEST_KEY } from '../../constant/localStorageKeys';
import { useDispatch } from 'react-redux';
import { useLoginGuestMutation } from '../../api/users/guestAPI';
import { Box } from '@mui/material';
import customToastWithAlert from '../../design/CustomToastWithAlert';
import LinkText from '../../design/Fields/LinkText';
import FormTextField, { EndIconType } from '../../design/FormUI/FormTextField';
import React from 'react';
import { useFormik } from 'formik';

import {
    clearLocalStorage,
    clearSessionStorage,
} from '../../utils/localStorageUtils';
import * as yup from 'yup';
import { emailRegex } from '../../constant/regex';
import useStyles from '../SignIn/Forms/LogIn.styles';
import theme from '../../styles/theme';

const INITIAL_FORM_STATE = {
    email: '',
    password: '',
};
export const FORM_VALIDATION = yup.object({
    email: yup
        .string()
        .matches(emailRegex, '* Please provide a correct email address')
        .required('* This field is required'),
    password: yup.string().required('* This field is required'),
});

export default function LoginGuestForm() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [handleSubmit, { isLoading }] = useLoginGuestMutation();

    const formik = useFormik({
        enableReinitialize: true,
        validateOnChange: true,
        initialValues: { ...INITIAL_FORM_STATE },
        validationSchema: FORM_VALIDATION,
        onSubmit: handleLogIn,
    });

    function handleLogIn(values: { email: string; password: string }) {
        clearLocalStorage();
        clearSessionStorage();
        handleSubmit(values)
            .unwrap()
            .then((data) => {
                dispatch(OAKENApi.util?.resetApiState());
                localStorage.setItem(GUEST_KEY, data.accessToken);
                localStorage.setItem(REFRESH_GUEST_KEY, data.refreshToken);
                navigate(`/shared/documents`);
            })
            .catch((error) => {
                formik.resetForm();
                customToastWithAlert({
                    type: 'error',
                    message: error?.data?.description,
                });
            });
    }

    const { classes } = useStyles(theme);
    return (
        <form
            onSubmit={formik.handleSubmit}
            style={{
                width: '100%',
            }}>
            <Box display={'flex'} width={'100%'} flexDirection={'column'}>
                <FormTextField
                    name={'email'}
                    label={'Email Address'}
                    required
                    className={classes.textField}
                    formik={formik}
                />
                <FormTextField
                    name={'password'}
                    required
                    label={'Password'}
                    formik={formik}
                    endIconType={EndIconType.password}
                />
                <Box
                    display={'flex'}
                    width={'100%'}
                    pt={5}
                    justifyContent={'space-between'}
                    alignItems={'center'}>
                    <LinkText
                        variant={'font16'}
                        to={'/guests/setPassword'}
                        text={'Set Password'}
                    />
                    <SubmitButton
                        isLoading={isLoading}
                        variant={'contained'}
                        text={'Log In'}
                        size={'large'}
                    />
                </Box>
            </Box>
        </form>
    );
}
export const loginFormConfig: FormConfig[] = [
    {
        formField: {
            scheme: 'full',
            name: 'email',
            type: 'input',
            label: 'Email Address',
            isRequired: true,
            validation: composeValidators(required, isEmail, maxLength(256)),
        },
        renderProps: {
            placeholder: 'Enter email address',
            isDisabled: false,
            size: 'small',
        },
    },

    {
        formField: {
            scheme: 'full',
            name: 'password',
            type: 'input',
            label: 'Password',
            validation: composeValidators(required),
        },
        renderProps: {
            placeholder: 'Password',
            isDisabled: false,
            size: 'small',
        },
    },
];
