import React, { useContext } from 'react';
import { Box } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { SelectedRowContext } from './SelectedRowContext';

const useStyles = makeStyles()((theme) => ({
    stickyPreviewInfo: {
        position: 'sticky',
        top: '10px',
        zIndex: 1000,
        padding: theme.spacing(2),
        marginLeft: theme.spacing(2),
        borderRadius: theme.spacing(1),
        backgroundColor: theme.palette.lightTurq.lightTurqTint2,
        display: 'flex',
        flexDirection: 'column',
        rowGap: theme.spacing(1),
    },
    infoWrapper: {
        display: 'flex',
        flexWrap: 'wrap',
        columnGap: theme.spacing(2),
        width: '100%',
    },
    infoItem: {
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: theme.spacing(2),
        width: '45%',
    },
}));

export default function PreviewInfo() {
    const { classes } = useStyles();
    const { selectedRow } = useContext(SelectedRowContext);

    if (selectedRow && !selectedRow.id) return null;
    if (!selectedRow) return null;

    return (
        <Box className={classes.stickyPreviewInfo}>
            <Box className={classes.infoWrapper}>
                {selectedRow &&
                    Object.entries(selectedRow).map(([key, value]) => {
                        if (key === 'id') return null;
                        return (
                            <Box className={classes.infoItem} key={key}>
                                <>
                                    <strong>{fieldNamesMapper[key]}</strong>
                                    {Array.isArray(value) ? (
                                        value.map((val, index) => (
                                            <span key={index}>{val}</span>
                                        ))
                                    ) : (
                                        <span>{value}</span>
                                    )}
                                </>
                            </Box>
                        );
                    })}
            </Box>
        </Box>
    );
}

const fieldNamesMapper: Record<string, string> = {
    name: 'Name',
    content: 'Content',
    note: 'Content',
    communicationType: 'Communication Type',
    actualContactDate: 'Actual Contact Date',
    fromUser: 'From User',
    createdAt: 'Created At',
    primaryEmail: 'Primary Email',
    email: 'Email',
    leaseName: 'Lease Name',
    leaseType: 'Lease Type',
    archiveDate: 'Archive Date',
    archivedBy: 'Archived By',
    fieldNames: 'Field Names',
    startDate: 'Start Date',
    endDate: 'End Date',
    contactName: 'Contact Name',
    primaryAddress: 'Primary Address',
    landPartnerName: 'Land Partner Name',
    primaryContact: 'Primary Contact',
    createdBy: 'Created By',
    createdDate: 'Created Date',
    landownersNames: 'Land Partners Names',
    relationshipStartDate: 'Relationship Start Date',
};
