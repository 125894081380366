import { Box, Button } from '@mui/material';
import theme from '../../../styles/theme';
import SubmitButton from '../../../design/Buttons/SubmitButton';
import React from 'react';

interface OwnProps {
    onClose: () => void;
    isLoading: boolean;
}
export default function UpdateLablesModalButtons({
    onClose,
    isLoading,
}: OwnProps) {
    return (
        <Box
            p={theme.spacing(2, 1.5)}
            display="flex"
            columnGap={2}
            justifyContent="flex-end"
            mt={2}>
            <Button variant={'outlined'} onClick={onClose} color={'secondary'}>
                Cancel
            </Button>
            <SubmitButton
                isLoading={isLoading}
                variant={'contained'}
                text={'Update'}
            />
        </Box>
    );
}
