import React, { useContext, useEffect } from 'react';
import Loader from '../../design/BaseLoader';
import { Box } from '@mui/material';
import SearchCard, { SearchResult } from './SearchCard';
import SearchAlert from './SearchAlert';
import { SearchContext } from './SearchContext';

interface OwnProps {
    isLoading: boolean;
    query: string;
}

export default function SearchContent({ isLoading, query }: OwnProps) {
    const { view, data, setView, setType } = useContext(SearchContext);
    useEffect(() => {
        setView(data);
        setType('ALL');
    }, [data]);
    if (isLoading) {
        return (
            <Box display={'flex'} width={'100%'} justifyContent={'center'}>
                <Loader size={'medium'} />
            </Box>
        );
    }
    if (view?.length) {
        return (
            <>
                {view.map((card: SearchResult) => (
                    <SearchCard
                        key={card.entityId}
                        entityId={card.entityId}
                        name={card.name}
                        type={card.type}
                        updatedAt={card.updatedAt}
                        noteOwner={card?.noteOwner}
                    />
                ))}
            </>
        );
    }

    return <SearchAlert query={query} />;
}
