import React from 'react';
import { Box, Typography } from '@mui/material';
import theme from '../../../styles/theme';

interface OwnProps {
    name: string;
}

export default function TableName({ name }: OwnProps) {
    return (
        <Box
            padding={theme.spacing(1.5, 2)}
            alignItems="center"
            display="flex"
            width="100%"
            borderRadius={theme.spacing(1, 1, 0, 0)}
            bgcolor={theme.palette.backgroundB.backgroundB4}>
            <Typography variant="font16" fontWeight="medium">
                {name}
            </Typography>
        </Box>
    );
}
