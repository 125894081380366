import { ReactComponent as EditIcon } from '../../assets/icons/edit_icon.svg';

import AppButton from '../../design/Buttons/AppButton';

import React from 'react';
import theme from '../../styles/theme';

interface OwnProps {
    onClick: () => void;
    isDisabled?: boolean;
    isHidden?: boolean;
    title?: string;
}

export default function EditButton({
    onClick,
    isDisabled,
    isHidden,
    title = 'Edit',
}: OwnProps) {
    if (isHidden) return null;

    return (
        <AppButton
            sx={{
                color: theme.palette.brandLightTurq,
                fontWeight: 600,
                fontSize: 12,
            }}
            onClick={onClick}
            isDisabled={isDisabled}
            title={title}
            endIcon={<EditIcon />}
            variant={'outlined'}
        />
    );
}
