import { Table } from '../../design/GenericTable/GenericTable';
import { Box, Typography } from '@mui/material';
import ExpandedLeaseDetails from './ExpandedComponent';
import React from 'react';
import { FSA } from './LeaseDetailForm';

import TableActions from '../../design/GenericTable/TableActions';
import { formatCurrency, formatNumber } from '../../utils/fieldMasks';

export const useTableFsaIdConfig = (
    isExpandable = true,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    handleDelete: (id: string) => void = () => {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    handleEdit: (id: string) => void = () => {},
    isActionsHidden = false,
    // eslint-disable-next-line max-params
): Table<FSA> => {
    return {
        columns: [
            {
                header: {
                    label: 'FSA ID',
                },
                cellRender: (tableData) => (
                    <Typography
                        variant={'font14'}
                        color={'text.link'}
                        fontWeight={'medium'}>
                        {tableData.fsaId}
                    </Typography>
                ),
                format: { align: 'left', color: { color: 'primary' } },
                width: 'auto',
            },
            {
                header: {
                    label: 'Tract/TFP Number(s)',
                },
                cellRender: (tableData) => (
                    <>
                        {tableData.tfpNumber?.map((number, index) => (
                            <Box key={index}>
                                <Typography
                                    variant={'font14'}
                                    color={'text.link'}
                                    fontWeight={'medium'}>
                                    {number}
                                </Typography>
                            </Box>
                        ))}
                    </>
                ),
                format: { align: 'left', color: { color: 'primary' } },
                width: 'auto',
            },
            {
                header: {
                    label: 'Total Acres',
                },
                cellRender: (tableData) => (
                    <Typography
                        variant={'font14'}
                        color={'text.link'}
                        fontWeight={'medium'}>
                        {formatNumber(tableData.totalAcres)}
                    </Typography>
                ),
                format: { align: 'left', color: { color: 'primary' } },
                width: 'auto',
            },
            {
                header: {
                    label: 'Rent Per Acre',
                },
                cellRender: (tableData) => (
                    <Typography
                        variant={'font14'}
                        color={'text.link'}
                        fontWeight={'medium'}>
                        {tableData.rentPerAcreRate &&
                            formatCurrency(tableData.rentPerAcreRate)}
                    </Typography>
                ),
                format: { align: 'left', color: { color: 'primary' } },
                width: 'auto',
            },
            {
                header: {
                    label: 'Annual Rent',
                },
                cellRender: (tableData) => (
                    <Typography
                        variant={'font14'}
                        color={'text.link'}
                        fontWeight={'medium'}>
                        {tableData.totalAnnualRent &&
                            formatCurrency(tableData.totalAnnualRent)}
                    </Typography>
                ),
                format: { align: 'left', color: { color: 'primary' } },
                width: 'auto',
            },
            {
                header: {
                    label: 'Actions',
                    sortable: false,
                    isHidden: isActionsHidden,
                },
                cellRender: (tableData) => (
                    <TableActions
                        onDelete={() => handleDelete(tableData?.tableId)}
                        onEdit={() => handleEdit(tableData?.tableId)}
                    />
                ),
                isHidden: isActionsHidden,
                format: { align: 'left', color: { color: 'primary' } },
                width: 'auto',
            },
        ],
        multiselect: false,
        pageable: false,
        expandable: isExpandable,
        ExpandComponent: ExpandedLeaseDetails,
    };
};
