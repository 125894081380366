import React from 'react';
import { Box, Skeleton } from '@mui/material';
import theme from '../../styles/theme';
import { PaymentScheduledData } from './interfaces';
import { useGetOneLandPartnerQuery } from '../../api/landpartners/landPartnersAPI';
import ExpandComponentColumn from './ExpandComponentColumn';

interface OwnProps {
    data: PaymentScheduledData;
}

export default function ExpandedPayment({ data }: OwnProps) {
    const { data: landPartnerData, isLoading } = useGetOneLandPartnerQuery(
        data?.landowner?.id,
    );
    const landPartnerDataBillingAddress = landPartnerData?.billingAddress
        ? [
              landPartnerData?.billingAddress.address1,
              landPartnerData?.billingAddress.address2,
              landPartnerData?.billingAddress.city,
              landPartnerData?.billingAddress.state,
              landPartnerData?.billingAddress.zipCode,
          ]
              .filter(Boolean)
              .join(', ')
        : 'N/A';

    if (isLoading)
        return (
            <Box
                display={'flex'}
                flexDirection={'column'}
                alignItems={'center'}>
                <Skeleton animation="wave" width="80%" />
                <Skeleton animation="wave" width="80%" />
                <Skeleton animation="wave" width="80%" />
            </Box>
        );

    function findPaymentPercent(paymentDate: string) {
        const paymentObj = data?.lease?.leasePaymentDistributions?.find(
            (payment) => {
                if (payment.paymentDate === paymentDate) {
                    return payment;
                }
            },
        );
        return paymentObj?.paymentPercent
            ? paymentObj.paymentPercent
            : undefined;
    }

    return (
        <Box
            display={'flex'}
            width={'100%'}
            sx={{
                backgroundColor: theme.palette.backgroundB.backgroundB2,
                '& :last-child': {
                    borderRight: 'none',
                },
            }}>
            <ExpandComponentColumn
                title={'DUE DATE'}
                text={data.paymentDate}
                isHidden={!data.paymentDate}
            />{' '}
            <ExpandComponentColumn
                title={'PAYEES'}
                text={data?.landowner?.name}
                isHidden={!data?.landowner?.name}
            />{' '}
            <ExpandComponentColumn
                title={'ADDRESS'}
                text={landPartnerDataBillingAddress}
                isHidden={!landPartnerData?.billingAddress}
            />
            <ExpandComponentColumn
                title={'PAYMENT CALCULATION'}
                text={`Lease: ${data?.lease.leaseName}`}
                secondaryText={
                    !data.paymentDate || !findPaymentPercent(data.paymentDate)
                        ? ''
                        : `Payment: ${data?.paymentDate && findPaymentPercent(data?.paymentDate) + '%'}`
                }
                isHidden={!data?.lease?.leaseName}
            />
        </Box>
    );
}
