import { useEffect, useState } from 'react';

interface PaginationState<T> {
    currentPage: number;
    pageSize: number;
    handlePageChange: (page: number) => void;
    handlePageSizeChange: ({
        page,
        pageSize,
    }: {
        page: number;
        pageSize: number;
    }) => void;
    visibleData: T[];
}

export default function usePagination<T>(
    data: T[],
    initialPage = 1,
    initialPageSize = 25,
): PaginationState<T> {
    const [currentPage, setCurrentPage] = useState(initialPage);
    const [pageSize, setPageSize] = useState(initialPageSize);
    const [visibleData, setVisibleData] = useState<T[]>([]);

    const handlePageChange = (page: number): void => {
        setCurrentPage(page);
    };

    function handlePageSizeChange({
        page,
        pageSize,
    }: {
        page: number;
        pageSize: number;
    }) {
        setCurrentPage(page);
        setPageSize(pageSize);
    }

    useEffect(() => {
        const startIndex = (currentPage - 1) * pageSize;
        const endIndex = Math.min(startIndex + pageSize, data.length);

        const newVisibleData = data.slice(startIndex, endIndex);

        !!data.length && setVisibleData(newVisibleData);
    }, [currentPage, pageSize, data]);

    return {
        currentPage,
        pageSize,
        handlePageChange,
        handlePageSizeChange,
        visibleData,
    };
}
