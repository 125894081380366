import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';

import { organizationsAPI } from './users/organizationsAPI';
import { usersAPI } from './users/usersAPI';
import { accountAPI } from './users/accountAPI';
import { rolesAPI } from './users/rolesAPI';
import { OAKENApi } from './OAKENApi';
import { fileApi } from './fileApiSlice';
import { tasksAPI } from './tasks/tasksAPI';
import { customizeConfigAPI } from './users/customizeConfigAPI';
import { notificationAPI } from './notifications/notificationAPI';
import { searchAPI } from './search/searchAPI';
import { calendarAPI } from './calendar/calendarAPI';

export const store = configureStore({
    reducer: {
        [accountAPI.reducerPath]: accountAPI.reducer,
        [calendarAPI.reducerPath]: calendarAPI.reducer,
        [tasksAPI.reducerPath]: tasksAPI.reducer,
        [searchAPI.reducerPath]: searchAPI.reducer,
        [customizeConfigAPI.reducerPath]: customizeConfigAPI.reducer,
        [usersAPI.reducerPath]: usersAPI.reducer,
        [notificationAPI.reducerPath]: notificationAPI.reducer,
        [organizationsAPI.reducerPath]: organizationsAPI.reducer,
        [rolesAPI.reducerPath]: rolesAPI.reducer,
        [OAKENApi.reducerPath]: OAKENApi.reducer,
        [fileApi.reducerPath]: fileApi.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }).concat(
            customizeConfigAPI.middleware,
            notificationAPI.middleware,
            searchAPI.middleware,
            accountAPI.middleware,
            usersAPI.middleware,
            organizationsAPI.middleware,
            rolesAPI.middleware,
            OAKENApi.middleware,
            fileApi.middleware,
            tasksAPI.middleware,
            calendarAPI.middleware,
        ),
});

setupListeners(store.dispatch);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
