import React from 'react';

import TwoColumnLayout from '../../layouts/TwoColumnLayout';
import ViewDashboard from '../../features/ViewDashboard/ViewDashboard';
import ActionsSidebar from '../../features/ViewDashboard/ActionsSidebar';
import useManagePermission from '../../hooks/useManagePermission';
import LandPartnerPortalDashboard from '../SharedData/PermanentAccess/LandPartnerPortalDashboard';
import ViewFarmLandDashboard from '../../features/ViewDashboard/ViewFarmLandDashboard';
import ViewSuccessionManagerDashboard from '../../features/ViewDashboard/ViewSuccessionManagerDashboard';

interface PermissionObject {
    lpDashboardSharing?: boolean;
    dashboard: boolean;
    farmlandManagerDashboard: boolean;
    successionPlanerDashboard: boolean;
}

export default function Dashboard() {
    const { checkPermission } = useManagePermission();

    const permissions: PermissionObject = {
        lpDashboardSharing: checkPermission('lpDashboardSharing', 'read'),
        dashboard: checkPermission('dashboard', 'read'),
        farmlandManagerDashboard: checkPermission(
            'farmlandManagerDashboard',
            'read',
        ),
        successionPlanerDashboard: checkPermission(
            'successionPlanerDashboard',
            'read',
        ),
    };

    const dashboardComponents: Record<keyof PermissionObject, React.ReactNode> =
        {
            lpDashboardSharing: <LandPartnerPortalDashboard />,
            dashboard: (
                <TwoColumnLayout
                    type="oneThirds"
                    leftComponent={<ViewDashboard />}
                    rightComponent={<ActionsSidebar />}
                />
            ),
            farmlandManagerDashboard: (
                <TwoColumnLayout
                    type="oneThirds"
                    leftComponent={<ViewFarmLandDashboard />}
                    rightComponent={<ActionsSidebar />}
                />
            ),
            successionPlanerDashboard: (
                <TwoColumnLayout
                    type="oneThirds"
                    leftComponent={<ViewSuccessionManagerDashboard />}
                    rightComponent={<ActionsSidebar />}
                />
            ),
        };

    return <>{dashboardComponents[getFirstKeyWithTrueValue(permissions)]}</>;
}

function getFirstKeyWithTrueValue(
    permissions: PermissionObject,
): keyof PermissionObject {
    for (const key in permissions) {
        if (permissions[key as keyof PermissionObject]) {
            return key as keyof PermissionObject;
        }
    }
    return 'dashboard';
}
