import {
    useDocumentDataByIdQuery,
    useRevokeDocumentMutation,
} from '../../../api/documents/documentsAPI';
import Loader from '../../../design/BaseLoader';
import { Box, Divider, MenuItem, Select } from '@mui/material';
import AppTypography from '../../../design/AppTypography';
import React from 'react';

interface OwnProps {
    documentId: string;
}
interface DocumentData {
    id: string;
    permissionType: string;
    documentId: string;
    contact: {
        id: string;
        firstName: string;
        lastName: string;
        primaryEmail: string;
    };
}
export default function HasAccessList({ documentId }: OwnProps) {
    const { data, isLoading } = useDocumentDataByIdQuery({ documentId });
    const [handleRevoke] = useRevokeDocumentMutation();
    if (isLoading) {
        return (
            <Box display={'flex'} width={'100%'} justifyContent={'center'}>
                <Loader />
            </Box>
        );
    }

    if (!data || !data?.length) {
        return null;
    }

    return (
        <Box display={'flex'} flexDirection={'column'} rowGap={2}>
            <AppTypography
                component={'span'}
                variant={'font12'}
                fontWeight={'bold'}
                color={'text.neutral9'}
                text={'Already has access:'}
            />
            <Box
                display={'flex'}
                flexDirection={'column'}
                rowGap={2}
                sx={{
                    height: '15vh',
                    overflowY: 'auto',
                    overflowX: 'hidden',
                }}>
                {data.map((doc: DocumentData) => (
                    <Box
                        key={doc.id}
                        display={'flex'}
                        width={'100%'}
                        justifyContent={'space-between'}>
                        <Box width={'40%'}>
                            <AppTypography
                                component={'span'}
                                variant={'font12'}
                                fontWeight={'regular'}
                                color={'text.neutral9'}
                                text={`${doc?.contact?.firstName} ${doc?.contact?.lastName} -(${doc?.contact?.primaryEmail})`}
                            />
                        </Box>
                        <Box width={'40%'}>
                            <Select
                                fullWidth
                                size={'small'}
                                value={doc?.permissionType}
                                sx={{ height: '30px' }}>
                                <MenuItem disabled value={'VIEW'}>
                                    View{' '}
                                </MenuItem>
                                <Divider
                                    orientation="horizontal"
                                    variant={'middle'}
                                    flexItem
                                />
                                <MenuItem
                                    value={'REVOKE'}
                                    onClick={() =>
                                        handleRevoke({ permissionId: doc?.id })
                                    }>
                                    Revoke Access
                                </MenuItem>
                            </Select>
                        </Box>
                    </Box>
                ))}
            </Box>
        </Box>
    );
}
