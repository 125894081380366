import React from 'react';

import { Grid, IconButton, Modal as MUIModal, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { ReactComponent as CloseIcon } from '../../../assets/icons/close_icon.svg';
import RelationContactForm from './RelationContactForm';

interface OwnProps {
    isShowing: boolean;
    onClose: () => void;
    parentId: string;
}

export default function AddRelationContact({
    isShowing,
    parentId,
    onClose,
}: OwnProps) {
    const { classes } = useStyles();

    return (
        <MUIModal
            open={isShowing}
            onClose={onClose}
            className={classes.modalWrapper}>
            <Grid className={classes.modal}>
                <Grid className={classes.modalHeader}>
                    <Typography className={classes.modalHeaderTitle}>
                        Add a Contact
                    </Typography>
                    <IconButton
                        className={classes.modalCloseButton}
                        onClick={() => onClose()}
                        size="large">
                        <CloseIcon />
                    </IconButton>
                </Grid>
                <RelationContactForm parentContactId={parentId} />
            </Grid>
        </MUIModal>
    );
}

const useStyles = makeStyles()((theme) => ({
    modalWrapper: {
        display: 'flex',
        position: 'fixed',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 1102,
    },
    modal: {
        display: 'flex',
        height: '60vh',
        flexDirection: 'column',
        alignItems: 'center',
        width: 880,
        zIndex: 100,
        background: theme.palette.background.default,
        position: 'relative',
        borderRadius: 12,
        padding: 2,
    },
    modalHeader: {
        height: 70,
        position: 'sticky',
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '24px',
        borderRadius: '12px 12px 0 0',
        backgroundColor: theme.palette.neutralWhite,
    },
    modalHeaderTitle: {
        fontWeight: 600,
        fontSize: '14px',
        fontFamily: 'Montserrat',
        color: theme.palette.neutral.neutral10,
    },
    modalCloseButton: {
        padding: 0,
        color: theme.palette.background.default,
        cursor: 'pointer',
        // eslint-disable-next-line max-lines
        border: 'none',
        width: '18px',
        height: '18px',
    },
}));
