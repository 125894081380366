import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { ReactComponent as EmailIcon } from '../../../../assets/icons/communications_icon.svg';
import { ReactComponent as PhoneIcon } from '../../../../assets/icons/phone_icon.svg';
import { ReactComponent as LocationIcon } from '../../../../assets/icons/pin_location_icon.svg';
import { ReactComponent as StarIcon } from '../../../../assets/icons/star_icon.svg';
import InfoWithLabel from '../../../../reusable/InfoWithLabel';

export interface PreviewCardProps {
    onClick: () => void;
    label: string;
    name?: string;
    firstName?: string;
    lastName?: string;
    phoneNumber?: string;
    email?: string;
    address1?: string;
    address2?: string;
    city?: string;
    state?: string;
    zipCode?: string;
    isLandOwner?: boolean;
}
export default function PreviewCard({
    onClick,
    label,
    name,
    firstName,
    lastName,
    phoneNumber,
    email,
    address1,
    address2,
    city,
    state,
    zipCode,
    isLandOwner,
}: PreviewCardProps) {
    const { classes } = useStyles();
    const isAddress = address1 || address2 || city || state || zipCode;
    const isContact = firstName || lastName;
    const address = `${address1 ? address1 : ''} ${
        address2 ? ',' + address2 : ''
    } ${city ? ',' + city : ''} ${state ? ',' + state : ''} ${
        zipCode ? ' ' + zipCode : ''
    }`;
    const fullName = `${firstName ? firstName : ''} ${
        lastName ? lastName : ''
    }`;

    return (
        <div className={classes.viewWrapper}>
            <Box
                display={'flex'}
                width={'100%'}
                alignItems={'center'}
                justifyContent={'space-between'}>
                <Typography
                    variant={'font12'}
                    fontWeight={'bold'}
                    color={'text.secondary'}>
                    {label}
                </Typography>
                <Button variant={'text'} onClick={onClick}>
                    Modify
                </Button>
            </Box>
            <Box
                display={'flex'}
                flexDirection={'column'}
                rowGap={1}
                pt={3}
                pl={4}
                pb={5}>
                {name && (
                    <Typography
                        variant={'font20'}
                        fontWeight={'medium'}
                        color={'text.secondary'}>
                        {name}
                    </Typography>
                )}
                {isLandOwner && (
                    <Typography
                        fontWeight={'medium'}
                        color={'text.secondaryContrast'}>
                        Landowner
                    </Typography>
                )}
                <Box
                    display={'flex'}
                    flexDirection={'column'}
                    rowGap={1}
                    pl={2}>
                    {isContact && (
                        <InfoWithLabel icon={StarIcon} text={fullName} />
                    )}
                    {email && <InfoWithLabel icon={EmailIcon} text={email} />}
                    {phoneNumber && (
                        <InfoWithLabel icon={PhoneIcon} text={phoneNumber} />
                    )}
                    {isAddress && (
                        <InfoWithLabel icon={LocationIcon} text={address} />
                    )}
                </Box>
            </Box>
        </div>
    );
}
const useStyles = makeStyles()((theme) => ({
    viewWrapper: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        width: '100%',
        backgroundColor: theme.palette.darkTurq.darkTurqTint1,
        padding: theme.spacing(2),
    },
}));
