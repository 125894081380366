import { Box, Button, Link, Typography } from '@mui/material';
import { ReactComponent as ExternalLinkIcon } from '../../assets/icons/link_external_icon.svg';
import theme from '../../styles/theme';

export default function InfoOAKENBlock() {
    return (
        <Box
            width={'100%'}
            display={'flex'}
            flexDirection={'column'}
            rowGap={1}
            border={`4px solid ${theme.palette.brandLimeGreen}`}
            pt={2}
            pb={2}
            pl={3}
            pr={3}>
            <Typography variant={'font24'} color={'text.link'}>
                Oaken Strengthens Relationships
            </Typography>
            <Box display={'flex'} justifyContent={'space-between'}>
                <Typography variant={'font12'} color={'text.neutral9'}>
                    Learn how on our blog
                </Typography>
                <Link href={'https://oaken.ag'} target={'_blank'}>
                    <Button variant="contained" endIcon={<ExternalLinkIcon />}>
                        Oaken.ag
                    </Button>
                </Link>
            </Box>
        </Box>
    );
}
