import CardWrapper from './CardWrapper';

import React from 'react';
import { Box } from '@mui/material';
import TextWithTitle from './TextWithTitle';

export interface IntegrationData {
    payee: string;
    date: string;
    value: string;
    notes?: string;
}
interface OwnProps {
    data: IntegrationData;
}
export default function IntegrationDataCard({ data }: OwnProps) {
    return (
        <CardWrapper>
            <Box display={'flex'} flexWrap={'wrap'} width={540} gap={2}>
                {convertDataRoFieldView(data).map(
                    ({ title, value, isHidden }, index) => (
                        <TextWithTitle
                            key={index}
                            title={title}
                            value={value}
                            isHidden={isHidden}
                        />
                    ),
                )}
            </Box>
        </CardWrapper>
    );
}

export function convertDataRoFieldView(data: IntegrationData) {
    return [
        {
            title: 'Payee',
            value: data?.payee,
            isHidden: !data?.payee,
        },
        {
            title: 'Date',
            value: data?.date,
        },
        {
            title: 'Amount',
            value: data?.value || 'N/A',
        },
        {
            title: 'Notes',
            value: data?.notes,
            isHidden: !data?.notes,
        },
    ];
}
