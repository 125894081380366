import React from 'react';
import { Box, Button } from '@mui/material';
import { ReactComponent as EditIcon } from '../../assets/icons/edit_icon.svg';
import { makeStyles } from 'tss-react/mui';

interface OwnProps {
    onClick: () => void;
    isHidden?: boolean;
}

export default function EditButton({ onClick, isHidden }: OwnProps) {
    const { classes } = useStyles();
    if (isHidden) return null;

    return (
        <Box width={'100%'}>
            <Button
                onClick={onClick}
                endIcon={<EditIcon />}
                className={classes.button}>
                Edit
            </Button>
        </Box>
    );
}

const useStyles = makeStyles()((theme) => ({
    button: {
        fontSize: 12,
        color: theme.palette.brandLightTurq,
        minWidth: 'fit-content',
        textTransform: 'capitalize',
    },
}));
