import { Grid } from '@mui/material';
import BasePieChart, {
    PieChartConfig,
} from '../../design/Charts/PieChart/BasePieChart';
import LastOutreachTable from '../LandPartners/LastOutreachTable';
import React from 'react';
import { chartValue } from '../LandPartners/LandPartnerProfilesPage';

import Loader from '../../design/BaseLoader';
import { useGetSummaryQuery } from '../../api/growers/growersAPI';
export default function GrowersDashboard() {
    const { data: chartData, isLoading: isDashboardsLoading } =
        useGetSummaryQuery({});

    if (isDashboardsLoading) {
        return (
            <Grid
                container
                alignItems={'center'}
                justifyContent={'center'}
                flexDirection={'column'}>
                <Loader />
            </Grid>
        );
    }
    return (
        <Grid container item xs={12} justifyContent={'space-between'}>
            <BasePieChart
                data={totalGrowers(chartData)}
                config={chartConfigTotalGrowers(chartData)}
            />
            <BasePieChart
                data={totalContacts(chartData)}
                config={chartConfigTotalContacts(chartData)}
            />
            <Grid item xs={5}>
                <LastOutreachTable />
            </Grid>
        </Grid>
    );
}
function totalGrowers(summaryData: chartValue) {
    return [
        { name: 'Active', value: summaryData?.activeLandPartners || 0 },
        { name: 'Inactive', value: summaryData?.inactiveLandPartners || 0 },
        { name: 'Prospect', value: summaryData?.prospectLandPartners || 0 },
    ];
}

function totalContacts(summaryData: chartValue) {
    return [
        { name: 'Active', value: summaryData?.activeRelationships || 0 },
        { name: 'Inactive', value: summaryData?.inactiveRelationships || 0 },
        { name: 'Prospect', value: summaryData?.prospectRelationships || 0 },
    ];
}

function chartConfigTotalGrowers(summaryData: chartValue): PieChartConfig {
    return {
        summaryTitle: `Total Growers`,
        summaryValue: `${summaryData?.totalLandPartners || 0}`,
        showLegend: true,
        dataKey: 'value',
        chartTitle: 'GROWERS',
        showTooltip: true,
    };
}

function chartConfigTotalContacts(summaryData: chartValue): PieChartConfig {
    return {
        summaryTitle: 'Total Contacts',
        summaryValue: `${summaryData?.totalRelationships || 0}`,
        showLegend: true,
        dataKey: 'value',
        chartTitle: 'RELATIONSHIPS',
        showTooltip: true,
    };
}
