import React, { ReactNode, useEffect, useState } from 'react';
import { IconButton, Tooltip as MUITooltip } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { makeStyles } from 'tss-react/mui';
import { Link } from 'react-router-dom';
import {
    LeaseSearchResult,
    useLazySearchLeasesQuery,
} from '../../api/leases/leaseAPI';
import PATHS from '../../router/paths';

interface LandPartnerLeasesTooltipProps {
    landPartnerId: string;
    position?: 'top' | 'bottom' | 'left' | 'right';
}

export default function LandPartnerLeasesTooltip({
    landPartnerId,
    position = 'bottom',
}: LandPartnerLeasesTooltipProps) {
    const { classes } = useStyles();
    const [getItems, { data, isLoading, isFetching }] =
        useLazySearchLeasesQuery();
    const [hasFetched, setHasFetched] = useState(false);

    useEffect(() => {
        if (hasFetched) {
            getItems({ page: 0, size: 2000, landPartnerId });
        }
    }, [hasFetched, getItems, landPartnerId]);

    const handleMouseEnter = (): void => {
        if (!hasFetched) {
            setHasFetched(true);
        }
    };

    if (isLoading || isFetching) {
        return <></>;
    }

    const leasesInColumns = data ? chunkArray(data, 20) : [];

    const renderLeases = (leases: LeaseSearchResult[]): ReactNode =>
        leases.map((lease) => (
            <Link
                key={lease.id}
                to={`/${PATHS.landPortfolio}/${PATHS.manage}/${lease.id}`}
                className={classes.link}>
                {lease.name}
            </Link>
        ));

    const leaseColumns = leasesInColumns.map((column, colIndex) => (
        <div key={colIndex} className={classes.column}>
            {renderLeases(column)}
        </div>
    ));

    return (
        <MUITooltip
            title={
                <div className={classes.tooltipContent}>
                    <div className={classes.title}>Leases</div>
                    <div className={classes.columnsContainer}>
                        {leaseColumns}
                    </div>
                </div>
            }
            arrow
            placement={position}
            classes={{ tooltip: classes.customTooltip }}>
            <IconButton
                className={classes.buttonTooltip}
                onClick={handleMouseEnter}
                onMouseEnter={handleMouseEnter}>
                <InfoOutlinedIcon className={classes.iconTooltip} />
            </IconButton>
        </MUITooltip>
    );
}

const useStyles = makeStyles()((theme) => ({
    buttonTooltip: {
        padding: 0,
    },
    iconTooltip: {
        color: theme.palette.brandLightTurq,
        height: 14,
        width: 14,
    },
    tooltipContent: {
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(2),
    },
    title: {
        fontWeight: 'bold',
        marginBottom: theme.spacing(1),
    },
    columnsContainer: {
        display: 'flex',
        flexDirection: 'row',
        gap: theme.spacing(2),
    },
    column: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(0.5),
    },
    link: {
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    customTooltip: {
        maxWidth: 'none',
        borderRadius: theme.shape.borderRadius,
    },
}));

const chunkArray = <T,>(array: T[], size: number): T[][] => {
    const result = [];
    for (let i = 0; i < array.length; i += size) {
        result.push(array.slice(i, i + size));
    }
    return result;
};
