import { useState } from 'react';

export function useSelection<T>() {
    const [selected, setSelected] = useState<T[]>([]);

    const isSelected = (item: T): boolean => selected.includes(item);
    const isSelectedAll = (items: T[]): boolean =>
        selected.length === items.length;
    const toggleSelection = (item: T): void => {
        if (isSelected(item)) {
            setSelected(
                selected.filter((selectedItem) => selectedItem !== item),
            );
        } else {
            setSelected([...selected, item]);
        }
    };
    const selectAll = (items: T[]): void => {
        setSelected(items);
    };

    const clearSelection = (): void => {
        setSelected([]);
    };
    const toggleSelectionAll = (items: T[]): void => {
        if (isSelectedAll(items)) {
            clearSelection();
        } else {
            selectAll(items);
        }
    };

    return {
        selected,
        isSelected,
        toggleSelection,
        selectAll,
        clearSelection,
        toggleSelectionAll,
        isSelectedAll,
    };
}
