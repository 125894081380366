import React from 'react';
import { ReactComponent as DeleteIcon } from '../../assets/icons/remove_icon.svg';
import AppButton from '../../design/Buttons/AppButton';
import theme from '../../styles/theme';

interface OwnProps {
    onClick: () => void;
    isDisabled?: boolean;
    isHidden?: boolean;
    title?: string;
}

export default function DeleteButton({
    onClick,
    isDisabled,
    isHidden,
    title = 'Remove',
}: OwnProps) {
    if (isHidden) return null;

    return (
        <AppButton
            sx={{
                color: theme.palette.brandLightTurq,
                fontWeight: 600,
                fontSize: 12,
            }}
            onClick={onClick}
            isDisabled={isDisabled}
            title={title}
            endIcon={
                <DeleteIcon
                    style={{
                        fill: theme.palette.brandLightTurq,
                    }}
                />
            }
            variant={'outlined'}
        />
    );
}
