import React, { useEffect, useRef, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import clsx from 'clsx';

import NavigationPanel from '../NavigationPanel/NavigationPanel';
import useStyles from './PageWrapper.styles';
import Header from '../Header/Header';
import PATHS from '../../router/paths';
import { getTokenData } from '../../utils/jwtUtils';
import {
    useGetAccountLayoutsQuery,
    useGetAccountQuery,
} from '../../api/users/accountAPI';
import { useIdleTimer } from 'react-idle-timer';
import Loader from '../../design/BaseLoader';
import { Box, Grid } from '@mui/material';
import { OAKENApi } from '../../api/OAKENApi';
import { clearLocalStorage } from '../../utils/localStorageUtils';
import { useDispatch } from 'react-redux';
import useAvatar from '../../hooks/useAvatar';
import { useGetCurrentSubscriptionQuery } from '../../api/subscriptions/subscriptionsAPI';
import mixpanel from '../../mixpanel';

const THREE_HOURS = 3 * 60 * 60 * 1000;

const GENERAL_DEBOUNCE_TIME = 500;
export default function PageWrapper() {
    const { data: subscription } = useGetCurrentSubscriptionQuery();
    const isTrial = subscription?.trial || false;
    const { classes } = useStyles(isTrial);
    const [loading, setLoading] = useState(true);

    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const containerRef = useRef<HTMLDivElement>(null); // Typed the ref correctly

    const {
        data,
        isLoading: isUserDataLoading,
        isFetching: isUserDataFetching,
    } = useGetAccountQuery({});

    const { isLoading: isLayoutLoading, isFetching: isLayoutFetching } =
        useGetAccountLayoutsQuery({});

    const { avatarUrl } = useAvatar(
        data?.profilePhoto?.profilePhoto,
        data?.profilePhoto?.fileType,
    );

    function logOutUser() {
        clearLocalStorage();
        dispatch(OAKENApi.util?.resetApiState());
        navigate(`/${PATHS.auth}/${PATHS.logIn}`);
    }

    useIdleTimer({
        timeout: THREE_HOURS,
        onIdle: logOutUser,
        debounce: GENERAL_DEBOUNCE_TIME,
    });

    useEffect(() => {
        if (!getTokenData()) {
            navigate(`/${PATHS.auth}/${PATHS.logIn}`);
        } else {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        mixpanel.track(location.pathname, {
            page: location.pathname,
        });
    }, [location]);

    useEffect(() => {
        if (containerRef.current) {
            containerRef.current.scrollTop = 0;
        }
    }, [location.pathname]);

    useEffect(() => {
        if (data) {
            mixpanel.identify(data.id);
            mixpanel.people.set({
                $name: data.firstName,
                $email: data.email,
                data: JSON.stringify(data),
            });
        }
    }, [data]);

    useEffect(() => {
        if (data?.role?.name === `ADMIN`)
            navigate(`${PATHS.settings}/${PATHS.orgAndUsers}`);
    }, [data]);

    if (
        loading ||
        isUserDataLoading ||
        isUserDataFetching ||
        isLayoutLoading ||
        isLayoutFetching
    ) {
        return (
            <Grid
                container
                sx={{ height: '100vh' }}
                alignItems={'center'}
                justifyContent={'center'}
                flexDirection={'column'}>
                <Loader />
            </Grid>
        );
    }
    const isAdmin = data?.role?.name === `ADMIN`;
    return (
        <div className={clsx(classes.pageWrapper, loading && classes.loader)}>
            <>
                <NavigationPanel userRole={data?.role} />
                <Box className={classes.contentWrapper}>
                    <Header
                        isTrial={isTrial}
                        isAdmin={isAdmin}
                        firstName={data?.firstName}
                        lastName={data?.lastName}
                        profilePhoto={avatarUrl || ''}
                    />
                    <div
                        className={classes.containerWrapper}
                        ref={containerRef}>
                        <Outlet />
                    </div>
                </Box>
            </>
        </div>
    );
}
