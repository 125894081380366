import { Box, LinearProgress, Typography } from '@mui/material';

interface OwnProps {
    percentage: number;
    label: string;
    color?: string;
}

export default function ProgressBarWithPercentage({
    percentage,
    color,
}: OwnProps) {
    return (
        <Box
            display={'flex'}
            width={'100%'}
            columnGap={2}
            alignItems={'center'}>
            <Box sx={{ minWidth: 35 }}>
                <Typography variant="font12">
                    {percentage.toFixed(1)}%
                </Typography>
            </Box>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress
                    variant="determinate"
                    value={percentage}
                    sx={{
                        '& .MuiLinearProgress-bar': {
                            backgroundColor: color,
                        },
                    }}
                />
            </Box>
        </Box>
    );
}
