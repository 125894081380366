import { Button } from '@mui/material';
import { ReactComponent as RestoreIcon } from '../../../assets/icons/rotate_icon.svg';
import theme from '../../../styles/theme';

interface OwnProps {
    onClick: () => void;
    isDisabled?: boolean;
    isHidden?: boolean;
}

export default function RestoreButton({
    onClick,
    isDisabled,
    isHidden,
}: OwnProps) {
    if (isHidden) return null;

    return (
        <Button
            variant="outlined"
            onClick={onClick}
            sx={{
                color: theme.palette.brandLightTurq,
                fontWeight: 600,
                fontSize: 12,
            }}
            endIcon={<RestoreIcon />}
            disabled={isDisabled}>
            Restore
        </Button>
    );
}
