import palette from './palette';

const alertOverrides = {
    MuiAlert: {
        styleOverrides: {
            root: {
                fontWeight: 500,
                fontSize: '16px',
                color: palette.neutral.neutral10,
                borderRadius: 5,
                padding: 0,
            },
            standardInfo: {
                backgroundColor: palette.neutral.neutral1,
            },
            message: {
                display: 'flex',
                paddingRight: 16,
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
            },
            icon: { padding: 0 },
            action: {
                paddingTop: 0,
                paddingLeft: 0,
                paddingRight: 8,
                alignItems: 'center',
            },
        },
    },
};

export default alertOverrides;
