import React from 'react';

import theme from '../../styles/theme';
import { ReactComponent as Logo } from '../../assets/icons/logo2.svg';

import loginImage from '../../assets/images/loginPicture.png';

import { NavLink, Outlet } from 'react-router-dom';
import { Box, Typography } from '@mui/material';

import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material/styles/createTheme';

export default function Guests() {
    const { classes } = useStyles(theme);
    const currentYear = new Date().getFullYear();

    return (
        <div className={classes.signInWrapper}>
            <div className={classes.mainContainer}>
                <Logo className={classes.logo} />
                <div className={classes.formContainer}>
                    <Outlet />
                </div>
                <Box
                    pt={10}
                    pb={5}
                    display={'flex'}
                    width={'100%'}
                    justifyContent={'space-between'}>
                    <Typography variant={'font12'} color={'text.neutral6'}>
                        © {currentYear} OAKEN, INC. ALL RIGHTS RESERVED.
                    </Typography>
                    <NavLink
                        target={'_blank'}
                        to={'https://www.oaken.ag/end-user-terms-1'}
                        className={classes.link}>
                        PRIVACY POLICY
                    </NavLink>
                </Box>
            </div>
            <div className={classes.imageContainer}>
                <img
                    className={classes.loginImage}
                    src={loginImage}
                    alt={'background'}
                />
            </div>
        </div>
    );
}

const useStyles = makeStyles<Theme>()((theme) => ({
    signInWrapper: {
        overflow: 'hidden',
        display: 'flex',
        height: '100vh',
        width: '100vw',
    },
    mainContainer: {
        padding: '120px 130px 0 110px',
        display: 'flex',
        flexDirection: 'column',
        width: '50vw',
    },
    formContainer: {
        flex: 1,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
    },
    logo: {
        width: '225px',
        height: '75px',
    },
    link: {
        fontSize: '11px',
        fontWeight: 500,
        lineHeight: '1.27',
        color: theme.palette.brandLightTurq,
        textDecoration: 'unset',
    },
    imageContainer: {
        height: '100vh',
        overflowY: 'hidden',
        width: '50vw',
        display: 'flex',
        alignItems: 'flex-end',
    },
    loginImage: {
        width: '100%',
        maxWidth: '50vw',
    },
}));
