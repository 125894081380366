import TwoColumnLayout from '../../../layouts/TwoColumnLayout';
import AsideGrowerTab from './Grower/AsideGrowerTab';
import { useSearchParams } from 'react-router-dom';
import { useActivateShareDataLinkMutation } from '../../../api/sharedData/sharedDataTempAccessAPI';
import { useEffect } from 'react';
import SharedDataDashboard from './Dashboard/SharedDataDashboard';
import { Box } from '@mui/material';
import MetricsOnly from './MetricsOnly';
import StubDummyComponent from '../../../reusable/StubDummyComponent';

export default function SharedDataMetricsPage() {
    const [searchParams] = useSearchParams();
    const token = searchParams.get('token');
    const [activateToken] = useActivateShareDataLinkMutation();

    useEffect(() => {
        token && activateToken({ token });
    }, [token]);

    if (!token) {
        return <StubDummyComponent title={'Share Data Token is required'} />;
    }

    return (
        <Box bgcolor={'backgroundB.backgroundB2'}>
            <TwoColumnLayout
                type={'fiveSixths'}
                leftComponent={
                    <Box
                        display={'flex'}
                        pr={2}
                        flexDirection={'column'}
                        rowGap={2}>
                        <SharedDataDashboard token={token} />
                        <MetricsOnly token={token} />
                    </Box>
                }
                rightComponent={
                    <AsideGrowerTab token={token} hideSwitchGrower={true} />
                }
            />
        </Box>
    );
}
