import { FormConfig } from '../../design/Forms/interfaces';
import IntegrationRow, { Integration } from './IntegrationRow';
import { Box } from '@mui/material';
import AppTypography from '../../design/AppTypography';

export default function IntegrationList() {
    return (
        <Box
            display={'flex'}
            flexDirection={'column'}
            rowGap={2}
            width={'100%'}>
            <Box
                display={'flex'}
                justifyContent={'space-between'}
                rowGap={2}
                width={'100%'}>
                <AppTypography
                    variant={'font20'}
                    fontWeight={'regular'}
                    color={'text.neutral9'}
                    text={'QuickBooks'}
                />
                <Box mr={'510px'}>
                    <AppTypography
                        variant={'font20'}
                        fontWeight={'regular'}
                        color={'text.neutral9'}
                        text={'Oaken'}
                    />
                </Box>
            </Box>
            {integrationListMockData.map((integration) => (
                <IntegrationRow
                    key={integration.title}
                    title={integration.title}
                    data={integration.data}
                    formConfig={integration.formConfig}
                />
            ))}
        </Box>
    );
}

const formConfig: FormConfig[] = [
    {
        formField: {
            scheme: 'half',
            name: 'landPartner',
            type: 'select',
            label: 'Land Partner',
        },
        renderProps: {
            placeholder: '',
            isDisabled: false,
            size: 'small',
            options: [
                {
                    id: '1',
                    name: 'John Doe',
                },
                {
                    id: '2',
                    name: 'Jane Doe',
                },
            ],
        },
    },
    {
        formField: {
            scheme: 'half',
            name: 'lease',
            type: 'select',
            label: 'Lease Name',
        },
        renderProps: {
            placeholder: '',
            isDisabled: false,
            size: 'small',
            options: [
                {
                    id: '1',
                    name: 'Lease',
                },
                {
                    id: '2',
                    name: 'Lease 2',
                },
            ],
        },
    },
];

const form2PaymentConfig: FormConfig[] = [
    {
        formField: {
            scheme: 'half',
            name: 'landPartner',
            type: 'select',
            label: 'Land Partner',
        },
        renderProps: {
            placeholder: '',
            isDisabled: false,
            size: 'small',
            options: [
                {
                    id: '1',
                    name: 'John Doe',
                },
                {
                    id: '2',
                    name: 'Jane Doe',
                },
            ],
        },
    },
    {
        formField: {
            scheme: 'half',
            name: 'lease',
            type: 'select',
            label: 'Lease Name',
        },
        renderProps: {
            placeholder: '',
            isDisabled: false,
            size: 'small',
            options: [
                {
                    id: '1',
                    name: 'Lease',
                },
                {
                    id: '2',
                    name: 'Lease 2',
                },
            ],
        },
    },
    {
        formField: {
            scheme: 'half',
            name: 'paymentDate',
            type: 'datepicker',
            label: 'SCHEDULE PAYMENT DATE',
        },
        renderProps: {
            placeholder: '',
            isDisabled: false,
            size: 'small',
            format: 'MM/dd/yyyy',
        },
    },
    {
        formField: {
            scheme: 'half',
            name: 'scheduledPayment',
            type: 'input',
            label: 'SCHEDULE PAYMENT',
        },
        renderProps: {
            placeholder: '',
            isDisabled: false,
            size: 'small',
        },
    },
];
const integrationListMockData: Integration[] = [
    {
        title: 'Vendor Reference',
        data: {
            payee: 'William D Haung',
            date: '12.03.2023',
            value: '$954.80',
        },
        formConfig: formConfig,
    },
    {
        title: 'TxnDate',
        data: {
            payee: 'William D Haung',
            date: '12.03.2023',
            value: '$20,535.68',
            notes: 'It received positive reviews and numerous accolades, including seven nominations at the 88th Academy Awards. Grossing over $630 million worldwide',
        },
        formConfig: form2PaymentConfig,
    },
    {
        title: 'SalesTermRef',
        data: {
            payee: 'William D Haung',
            date: '12.03.2023',
            value: '$401.50',
        },
        formConfig: formConfig,
    },
    {
        title: 'DueDate',
        data: {
            payee: 'William D Haung',
            date: '12.03.2023',
            value: '$9,982.50',
        },
        formConfig: formConfig,
    },
    {
        title: 'Balance',
        data: {
            payee: 'William D Haung',
            date: '12.03.2023',
            value: '$5,000.00',
        },
        formConfig: formConfig,
    },
    {
        title: 'ID',
        data: {
            payee: 'William D Haung',
            date: '12.03.2023',
            value: '$3,300.00',
        },
        formConfig: formConfig,
    },
];
