import React from 'react';
import ConfirmModal from '../../../layouts/ConfirmModal/ConfirmModal';
import useHandleSubmitRTK from '../../../hooks/useHandleSubmit';
import { useDeactivateContactMutation } from '../../../api/contacts/contactsAPI';
import PATHS from '../../../router/paths';
import { useNavigate } from 'react-router-dom';

interface OwnProps {
    isShowing: boolean;
    onClose: () => void;
    contactId: string;
}

export default function DeactivateContactModal({
    isShowing,
    contactId,
    onClose,
}: OwnProps) {
    const navigate = useNavigate();

    const { handleSubmit: deactivateContact } = useHandleSubmitRTK({
        useRtkHook: useDeactivateContactMutation,
        onSuccess: () =>
            navigate(`/${PATHS.landpartners}/${PATHS.allProfiles}`),
        successMessage: 'Contact archived successfully',
    });

    const handleDeactivateContact = () => {
        deactivateContact(contactId);
    };

    return (
        <ConfirmModal
            isShowing={isShowing}
            hide={onClose}
            title={`Please confirm you want to deactivate this contact?`}
            info={''}
            onSubmit={handleDeactivateContact}
        />
    );
}
