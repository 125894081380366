import React from 'react';
import { Box, Grid } from '@mui/material';
import BasicTabsPanel from '../../design/TabsPanel/BasicTabsPanel';
import LandPartnerDetails from '../../features/ViewLandownersDetails/LandPartnerDetails';
import StubDummyComponent from '../../reusable/StubDummyComponent';
import LandPartnerRelationship from '../../features/ViewLandownersDetails/LandPartnerRelationship/LandPartnerRelationship';
import LandPartnerDocuments from '../../features/ViewLandownersDetails/LandPartnerDocuments/LandPartnerDocuments';
import LandPartnerActivity from '../../features/ViewLandownersDetails/LandPartnerActivity';
import { useParams } from 'react-router-dom';
import PATHS from '../../router/paths';
import LPContactHistory from '../../features/ViewLPContactHistory/LPContactHistory';
import LandPartnerDetailPanel from './LandPartnerDetailPanel/LandPartnerDetailPanel';
import { useGetOneLandPartnerQuery } from '../../api/landpartners/landPartnersAPI';
import Loader from '../../design/BaseLoader';
import Breadcrumbs from '../../design/Breadcrumbs/Breadcrumbs';
import useBreadcrumbs from '../../hooks/useBreadcrumbs';

export default function LandPartnersDetailPage() {
    const { id } = useParams<{ id: string | undefined }>();
    const {
        data: landPartnerData,
        isLoading: isProfileDataLoading,
        isFetching: isProfileDataFetching,
    } = useGetOneLandPartnerQuery(id);

    const routes = {
        '/': 'Home',
        'landpartners/profiles/': 'Land Partners',
        'landpartners/profiles/:id': '',
        'landpartners/profiles/:id/activity': 'Activity',
        'landpartners/profiles/:id/payments': 'Payments',
        'landpartners/profiles/:id/relationship': 'Relationship',
        'landpartners/profiles/:id/documents': 'Documents',
        'landpartners/profiles/:id/history': 'History',
        'landpartners/profiles/:id/goals': 'Goals',
    };

    const breadcrumbs = useBreadcrumbs({
        dynamicName: landPartnerData?.name?.toUpperCase() || '',
        routes,
    });

    const tabsConfig = [
        {
            label: 'Detail',
            content: <LandPartnerDetails landPartnerData={landPartnerData} />,
            value: 0,
            to: ``,
        },
        {
            label: 'Activity ',
            content: <LandPartnerActivity />,
            value: `${PATHS.activity}`,
            to: `${PATHS.activity}`,
        },
        {
            label: 'Payments',
            content: (
                <StubDummyComponent
                    title={
                        'This feature will be available once Payment is integrated!'
                    }
                />
            ),
            value: `${PATHS.payments}`,
            to: `${PATHS.payments}`,
        },
        {
            label: 'Relationships',
            content: <LandPartnerRelationship landPartnerIdFromPath={id} />,
            value: `${PATHS.relationship}`,
            to: `${PATHS.relationship}`,
        },
        {
            label: 'Documents',
            content: <LandPartnerDocuments />,
            value: `${PATHS.documents}`,
            to: `${PATHS.documents}`,
        },
        {
            label: 'History',
            content: <LPContactHistory />,
            value: `${PATHS.history}`,
            to: `${PATHS.history}`,
        },
        {
            label: 'Goals',
            content: (
                <StubDummyComponent
                    title={'This feature will be available soon!'}
                />
            ),
            value: `${PATHS.goals}`,
            to: `${PATHS.goals}`,
        },
    ];
    if (isProfileDataLoading || isProfileDataFetching) {
        return (
            <Grid
                container
                sx={{ height: '100vh' }}
                alignItems={'center'}
                justifyContent={'center'}
                flexDirection={'column'}>
                <Loader />
            </Grid>
        );
    }
    return (
        <Box display={'flex'} flexDirection={'column'} rowGap={2}>
            <Breadcrumbs collapsed={false} links={breadcrumbs} />
            <LandPartnerDetailPanel landPartner={landPartnerData} />
            <BasicTabsPanel tabItems={tabsConfig} />
        </Box>
    );
}
