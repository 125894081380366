import { Box } from '@mui/material';
import parse from 'html-react-parser';

interface Attachment {
    name: string;
    link: string;
}

interface OwnProps {
    message: string;
    attachments?: Attachment[];
    isHidden?: boolean;
}

export default function EventPreview({
    message,
    attachments,
    isHidden,
}: OwnProps) {
    if (isHidden) {
        return null;
    }

    const replaceImageLinksWithTags = (
        msg: string,
        attachments: Attachment[],
    ) => {
        let formattedMessage = msg.replace(/\r\n|\r|\n/g, '<br />');
        formattedMessage = formattedMessage.replace(
            /\[image:\s*(.*?)\]/g,
            (match, fileName) => {
                const attachment = attachments.find(
                    (att) => att.name === fileName,
                );
                if (attachment) {
                    return `<img src="${attachment.link}" alt="${fileName}" style="max-width: 100%; height: auto;" />`;
                }
                return match;
            },
        );

        return formattedMessage;
    };

    const formattedMessage = attachments
        ? replaceImageLinksWithTags(message, attachments)
        : message;

    return (
        <Box
            display={'flex'}
            flexWrap={'wrap'}
            maxWidth={'35%'}
            flexDirection={'column'}
            rowGap={1}>
            <Box sx={{ wordBreak: 'break-word' }}>
                {parse(formattedMessage)}
            </Box>
        </Box>
    );
}
