import React, { ChangeEvent, useState } from 'react';

import {
    Box,
    Button,
    CircularProgress,
    TextField,
    Typography,
} from '@mui/material';

import { makeStyles } from 'tss-react/mui';
import UploadAttachments from '../../../reusable/NotesField/UploadAttachments';

interface OwnProps {
    addComment: (note: string, file: File | null) => void;
    size?: 'small' | 'medium' | 'large';
    isLoading?: boolean;
}

export default function CommentInput({
    size = 'small',
    addComment,
    isLoading,
}: OwnProps) {
    const { classes } = useStyles();
    const [comment, setComment] = useState('');
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [fileName, setFileName] = useState<string>('');
    const [fileValidation, setFileValidation] = useState<string>('');

    const resetState = () => {
        setComment('');
        setSelectedFile(null);
        setFileName('');
        setFileValidation('');
    };

    const handleAddComment = () => {
        addComment(comment, selectedFile);
        resetState();
    };

    const validateFile = (file: File) => {
        const fileSizeInMB = file.size / (1024 * 1024);
        const fileType = file.type;
        const supportedFormats = ['application/pdf', 'image/jpeg', 'image/png'];
        if (fileSizeInMB > 10) {
            return 'Max file size supported is 10 Mb';
        } else if (!supportedFormats.includes(fileType)) {
            return 'Invalid file format. Supported formats are .pdf, .jpeg, .png';
        }
        return '';
    };

    const handleFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files && event.target.files[0];
        if (file) {
            const validationMessage = validateFile(file);
            if (validationMessage) {
                setFileValidation(validationMessage);
                setFileName('');
            } else {
                setFileValidation('');
                setSelectedFile(file);
                setFileName(file.name);
            }
        }
    };

    return (
        <div className={classes.inputContainer}>
            <Box display={'flex'} columnGap={2} alignItems={'center'}>
                <TextField
                    id="note"
                    fullWidth
                    variant="outlined"
                    placeholder={'Add comment'}
                    className={classes.inputWrapper}
                    onChange={(e) => setComment(e.target.value)}
                    value={comment}
                    multiline
                    minRows={
                        (size === 'small' && 1) ||
                        (size === 'medium' && 6) ||
                        12
                    }
                    maxRows={12}
                    inputProps={{ maxLength: 1024 }}
                />
                <Button
                    disabled={isLoading || comment.length === 0}
                    variant="contained"
                    size="large"
                    onClick={handleAddComment}>
                    {!isLoading ? (
                        'Add'
                    ) : (
                        <CircularProgress size={20} color="primary" />
                    )}
                </Button>
            </Box>
            <Box
                display={'flex'}
                columnGap={6}
                pr={12}
                justifyContent={'flex-end'}
                alignItems={'center'}>
                <Box
                    display={'flex'}
                    columnGap={2}
                    alignItems={'center'}
                    maxWidth={'70%'}>
                    {fileName && (
                        <Typography noWrap variant={'font12'} color="text.link">
                            {fileName}
                        </Typography>
                    )}
                    {fileValidation && (
                        <Typography variant={'font12'} color="text.error">
                            {fileValidation}
                        </Typography>
                    )}
                    <UploadAttachments handleClick={handleFileUpload} />
                </Box>
            </Box>
        </div>
    );
}

const useStyles = makeStyles()((theme) => ({
    inputContainer: {
        display: 'flex',
        flexDirection: 'column',
        '& .MuiOutlinedInput-root': {
            padding: 0,
        },
    },
    inputWrapper: {
        borderRadius: 8,
        backgroundColor: theme.palette.background.paper,
    },
}));
