import React from 'react';
import BaseModal from '../../../reusable/BaseModal';
import { Box } from '@mui/material';
import AppTypography from '../../../design/AppTypography';
import { FormConfig } from '../../../design/Forms/interfaces';
import theme from '../../../styles/theme';
import FormPage from '../../../reusable/FormUI/FormPage';
import SubmitButton from '../../../design/Buttons/SubmitButton';
import { Form } from 'react-final-form';

import useHandleSubmitRTK from '../../../hooks/useHandleSubmit';
import { useRequestDocumentMutation } from '../../../api/documents/documentsAPI';

import { useGetAllContactsQuery } from '../../../api/contacts/contactsAPI';
import Loader from '../../../design/BaseLoader';
import { ContactLandPartnerView } from '../../../pages/AllProfiles/AllProfilesTable';
import { Option } from '../../../pages/Settings/SharingSettings/ShareDataSelectField';
import { AutocompleteOption } from '../../../design/Fields/AutocompleteField';
import { composeValidators, required } from '../../../utils/validation';
import { convertDateToString } from '../../../utils/dateFormat';
import { useParams } from 'react-router-dom';

interface OwnProps {
    isShowing: boolean;
    handleClose: () => void;
}

interface FormValues {
    contacts: {
        id: string;
        email: string;
    }[];
    description: string;
    expireOn: Date;
}
export default function RequestDocumentModal({
    handleClose,
    isShowing,
}: OwnProps) {
    const { data, isLoading } = useGetAllContactsQuery({
        page: 0,
        size: 2000,
    });
    const { id: landPartnerId } = useParams();
    const { handleSubmit: handleRequest, isLoading: isRequesting } =
        useHandleSubmitRTK({
            useRtkHook: useRequestDocumentMutation,
            onSuccess: handleClose,
            successMessage: 'Document requested successfully',
        });

    function handleSubmit(values: FormValues) {
        handleRequest({
            contacts: values.contacts.map((contact) => {
                return {
                    id: contact.id,
                    email: contact.email,
                };
            }),
            description: values.description,
            landPartnerId,
            expireOn: values.expireOn && convertDateToString(values.expireOn),
        });
    }

    return (
        <BaseModal
            size={'small'}
            isShowing={isShowing}
            onClose={handleClose}
            header={'Request a document'}>
            <Box display={'flex'} flexDirection={'column'} width={'100%'}>
                <Form initialValues={{}} onSubmit={handleSubmit}>
                    {({ handleSubmit, invalid }) => (
                        <form onSubmit={handleSubmit}>
                            <Box
                                p={2}
                                display={'flex'}
                                flexDirection={'column'}
                                alignItems={'center'}
                                rowGap={2}
                                bgcolor={
                                    theme.palette.backgroundB.backgroundB1
                                }>
                                {!isLoading ? (
                                    <FormPage
                                        formConfig={formConfig({
                                            contacts: data?.data || [],
                                        })}
                                    />
                                ) : (
                                    <Loader />
                                )}
                                <AppTypography
                                    flexWrap={true}
                                    component={'span'}
                                    variant={'font12'}
                                    text={
                                        'When the document will be send to you through Oaken, \n' +
                                        'you will find it in the tab Documents and Recent Uploads'
                                    }
                                    fontWeight={'bold'}
                                />
                            </Box>
                            <Box
                                display={'flex'}
                                justifyContent={'flex-end'}
                                p={3}
                                borderRadius={'0px 0px 12px 12px'}
                                bgcolor={theme.palette.neutralWhite}>
                                <SubmitButton
                                    isDisabled={invalid}
                                    isLoading={isRequesting}
                                    text={'Send'}
                                />
                            </Box>
                        </form>
                    )}
                </Form>
            </Box>
        </BaseModal>
    );
}

interface Config {
    contacts: ContactLandPartnerView[];
}
function formConfig({ contacts }: Config): FormConfig[] {
    return [
        {
            formField: {
                scheme: 'full',
                name: 'contacts',
                type: 'autocomplete',
                label: 'Request the document from:',
                isRequired: true,
                validation: composeValidators(required),
            },
            renderProps: {
                isDisabled: false,
                getOptionDisabled: (option: AutocompleteOption) => {
                    return !option?.email;
                },
                options: convertContactToOption(contacts),
            },
        },
        {
            formField: {
                scheme: 'full',
                name: 'description',
                type: 'textarea',
                label: 'Please describe the document you are requesting',
                isRequired: true,
                validation: composeValidators(required),
            },
            renderProps: {
                isDisabled: false,
                resize: 'none',
                minRows: 3,
            },
        },
        {
            formField: {
                scheme: 'full',
                name: 'expireOn',
                type: 'datepicker',
                label: 'Complete by',
                isRequired: true,
                validation: composeValidators(required),
            },
            renderProps: {
                isDisabled: false,
                size: 'small',
                format: 'MMM dd, yyyy',
            },
        },
    ];
}

function convertContactToOption(contacts: ContactLandPartnerView[]): Option[] {
    return contacts.map((contact) => ({
        id: contact.id,
        name: `${contact.firstName} ${contact.lastName} - ${contact?.primaryEmail || 'Email address not available'}`,
        email: contact.primaryEmail,
    }));
}
