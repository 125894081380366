import {
    TypographyCase,
    TypographyVariant,
    TypographyWeight,
} from '../styles/typography';
import { Typography } from '@mui/material';
import { ElementType } from 'react';

interface TypographyProps {
    text?: string | number;
    isHidden?: boolean;
    textAlign?: 'left' | 'center' | 'right';
    variant?: TypographyVariant;
    fontWeight?: TypographyWeight;
    textTransform?: TypographyCase;
    flexWrap?: boolean;
    color?: string;
    component?: 'span' | 'div' | 'p' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
}

export default function AppTypography({
    text,
    isHidden,
    variant,
    fontWeight,
    color,
    textTransform,
    flexWrap = false,
    component,
    textAlign,
}: TypographyProps) {
    if (isHidden) return null;
    if (!text) return null;
    return (
        <Typography
            sx={{ wordBreak: 'break-word' }}
            textAlign={textAlign}
            component={component as ElementType}
            noWrap={!flexWrap}
            textTransform={textTransform}
            variant={variant}
            fontWeight={fontWeight}
            color={color}
            hidden={isHidden}>
            {text}
        </Typography>
    );
}
