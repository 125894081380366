import { Box, Button, Link, Typography } from '@mui/material';
import React from 'react';
import useAvatar from '../../../../hooks/useAvatar';
import theme from '../../../../styles/theme';
import UserAvatar from '../../../../design/Avatar/UserAvatar';
import { phoneMaskForString } from '../../../../utils/fieldMasks';
import { ReactComponent as EmailIcon } from '../../../../assets/icons/communications_icon.svg';
import { ProfilePhoto } from '../../../../features/Tasks/utils';

interface OwnProps {
    id: string;
    name: string;
    phoneNumber?: string;
    primaryEmail: string;
    profilePhoto?: ProfilePhoto;
}

export default function SharedDataGrowerInfo({
    name,
    phoneNumber,
    primaryEmail,
    profilePhoto,
}: OwnProps) {
    const { createBlobUrl } = useAvatar();

    return (
        <Box
            display={'flex'}
            flexDirection={'column'}
            rowGap={1}
            p={2}
            width={'100%'}>
            <Typography align={'left'} variant={'font14'} fontWeight={'bold'}>
                Your Contact
            </Typography>
            <Box
                display={'flex'}
                flexDirection={'column'}
                rowGap={1}
                p={2}
                width={'100%'}
                borderRadius={2}
                bgcolor={theme.palette.backgroundB.backgroundB2}
                alignItems={'flex-end'}>
                <Box display={'flex'} width={'100%'} columnGap={1} rowGap={1}>
                    <UserAvatar
                        name={name}
                        avatar={
                            profilePhoto?.profilePhoto &&
                            profilePhoto?.fileType &&
                            createBlobUrl(
                                profilePhoto?.profilePhoto,
                                profilePhoto?.fileType,
                            )
                        }
                        size={'small'}
                    />
                    <Box
                        display={'flex'}
                        columnGap={1}
                        flexDirection={'column'}>
                        <Typography variant={'font16'} fontWeight={'bold'}>
                            {name}
                        </Typography>
                        <Typography variant={'font14'} fontWeight={'lighter'}>
                            General Manager
                        </Typography>
                        {phoneNumber && (
                            <Typography
                                variant={'font14'}
                                fontWeight={'normal'}>
                                {phoneMaskForString(phoneNumber)}
                            </Typography>
                        )}
                    </Box>
                </Box>
                <Button
                    startIcon={<EmailIcon />}
                    size={'small'}
                    variant={'contained'}>
                    <Link
                        href={`mailto:${primaryEmail}`}
                        underline={'none'}
                        color={'inherit'}>
                        Send Email
                    </Link>
                </Button>
            </Box>
        </Box>
    );
}
