import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';

import UploadDocument from './UploadDocument';
import DocumentsView from './DocumentsView';
import {
    RequestDocumentType,
    DocumentTypeContext,
} from './DocumentTypeContext';

export default function LandPartnerDocuments() {
    const [type, setType] = useState<RequestDocumentType | null>('');

    return (
        <DocumentTypeContext.Provider value={{ type, setType }}>
            <Box display={'flex'} width={'100%'} flexDirection={'column'}>
                <Box m={1} bgcolor={'white'}>
                    <DocumentsView />
                </Box>

                <Box m={1} bgcolor={'white'}>
                    <Typography variant={'font20'} fontWeight={'medium'}>
                        Placeholder
                    </Typography>
                    <UploadDocument />
                </Box>
            </Box>
        </DocumentTypeContext.Provider>
    );
}
