import React from 'react';
import { Box, Grid } from '@mui/material';
import { useFormState } from 'react-final-form';
import PreviewMemberCard, { MemberCard } from './PreviewMemberCard';
import { Contact, LandPartner } from './LandPartnerWizard';
import { format } from 'date-fns';
import PreviewOrganizationCard, {
    OrganizationCard,
} from './PreviewOrganizationCard';
import { PrimaryContactUpdate } from '../ViewLandownersDetails/LandPartnerDetails';
import { useParams } from 'react-router-dom';
import { DATE_FORMAT } from '../../utils/dateFormat';

interface OwnProps {
    setPageNumber: (page: number) => void;
}

export default function PreviewLandPartner({ setPageNumber }: OwnProps) {
    const { values: data } = useFormState();
    const { id } = useParams<{ id: string }>();
    const isEdit = !!id;
    const handleModify = (page: number) => {
        setPageNumber(page);
    };
    const primaryContactCreate =
        data?.primaryContact?.newPrimaryContact || data?.draftName;
    const primaryContactUpdate = data?.primaryContact;
    const primaryContactConvertorCreate: MemberCard = primaryContactCreate && {
        firstName: primaryContactCreate?.firstName,
        lastName: primaryContactCreate?.lastName,
        primaryEmail: primaryContactCreate?.primaryEmail,
        secondaryEmail: primaryContactCreate?.secondaryEmail,
        advocacy: primaryContactCreate?.advocate,
        phoneNumber: primaryContactCreate?.contactNumbers,
        preferredContactMethod: data?.preferredContactMethod,
        address:
            primaryContactCreate?.addresses &&
            [
                primaryContactCreate.addresses[0].address1,
                primaryContactCreate.addresses[0].address2,
                primaryContactCreate.addresses[0].city,
                primaryContactCreate.addresses[0].state,
                primaryContactCreate.addresses[0].zipCode,
            ]
                .filter(Boolean)
                .join(', '),

        birthday:
            primaryContactCreate?.keyDates?.birthDate &&
            format(primaryContactCreate?.keyDates?.birthDate, DATE_FORMAT),
        relationshipStartDate:
            primaryContactCreate?.keyDates?.relationshipStartDate &&
            format(
                primaryContactCreate?.keyDates?.relationshipStartDate,
                DATE_FORMAT,
            ),
        anniversary:
            primaryContactCreate?.keyDates?.anniversary &&
            format(primaryContactCreate?.keyDates?.anniversary, DATE_FORMAT),
        dateOfDeath:
            primaryContactCreate?.keyDates?.dateOfDeath &&
            format(primaryContactCreate?.keyDates?.dateOfDeath, DATE_FORMAT),
        socialMedia: primaryContactCreate?.socialMedia,
        isPrimaryContact: true,
        onClick: () => handleModify(1),
    };
    const childContacts = data.primaryContact.childContacts;

    function childContactsConvertor(data: Contact): MemberCard {
        return {
            firstName: data.firstName,
            lastName: data.lastName,
            primaryEmail: data.primaryEmail,
            secondaryEmail: data?.secondaryEmail,
            advocacy: data.advocate,
            phoneNumber: data.contactNumbers,
            preferredContactMethod: data?.preferredContactMethod,
            address:
                data?.addresses &&
                [
                    data.addresses[0].address1,
                    data.addresses[0].address2,
                    data.addresses[0].city,
                    data.addresses[0].state,
                    data.addresses[0].zipCode,
                ]
                    .filter(Boolean)
                    .join(', '),

            birthday:
                data?.keyDates?.birthDate &&
                format(new Date(data.keyDates.birthDate), DATE_FORMAT),
            dateOfDeath:
                data?.keyDates?.dateOfDeath &&
                format(new Date(data.keyDates.dateOfDeath), DATE_FORMAT),
            relationshipStartDate:
                data?.keyDates?.relationshipStartDate &&
                format(
                    new Date(data.keyDates.relationshipStartDate),
                    DATE_FORMAT,
                ),
            anniversary:
                data?.keyDates?.anniversary &&
                format(new Date(data.keyDates.anniversary), DATE_FORMAT),
            socialMedia: data.socialMedia,
            isPrimaryContact: false,
            onClick: () => handleModify(2),
        };
    }

    function organizationConvertor(data: LandPartner): OrganizationCard {
        const addressData = isEdit
            ? primaryContactUpdate.primaryAddress
            : primaryContactCreate.addresses &&
              primaryContactCreate.addresses[0];

        return {
            name: data?.name,
            taxId: data?.taxId,
            associatedAcres: data?.associatedAcres,
            relationshipStartDate:
                data?.relationshipStartDate &&
                format(new Date(data?.relationshipStartDate), DATE_FORMAT),
            avatar: data?.landPartnerLogo && data?.landPartnerLogo[0],
            //eslint-disable-next-line
            // @ts-ignore
            primaryContact: isEdit
                ? data?.primaryContact
                : primaryContactCreate,
            status: data.status,
            email: data?.email,
            address:
                data?.billingAddress &&
                [
                    data?.billingAddress?.address1,
                    data?.billingAddress?.address2,
                    data?.billingAddress?.city,
                    data?.billingAddress?.state,
                    data?.billingAddress?.zipCode,
                ]
                    ?.filter(Boolean)
                    .join(', '),
            primaryContactAddress:
                addressData &&
                [
                    addressData.address1,
                    addressData.address2,
                    addressData.city,
                    addressData.state,
                    addressData.zipCode,
                ]
                    .filter(Boolean)
                    .join(', '),
            members: data?.primaryContact?.childContacts,
            socialMedia: data?.socialMedia,
            onClick: () => handleModify(0),
        };
    }

    function primaryContactConvertorUpdate(
        data: PrimaryContactUpdate,
    ): MemberCard {
        return {
            firstName: data?.firstName,
            lastName: data?.lastName,
            primaryEmail: data?.primaryEmail,
            secondaryEmail: data?.secondaryEmail,
            advocacy: data?.advocate,
            phoneNumber: data?.contactNumbers,
            preferredContactMethod: data?.preferredContactMethod,
            address:
                data?.primaryAddress &&
                [
                    data.primaryAddress.address1,
                    data.primaryAddress.address2,
                    data.primaryAddress.city,
                    data.primaryAddress.state,
                    data.primaryAddress.zipCode,
                ]
                    .filter(Boolean)
                    .join(', '),

            birthday:
                data?.keyDates?.birthDate &&
                format(new Date(data?.keyDates?.birthDate), DATE_FORMAT),
            dateOfDeath:
                data?.keyDates?.dateOfDeath &&
                format(new Date(data?.keyDates?.dateOfDeath), DATE_FORMAT),
            anniversary:
                data?.keyDates?.anniversary &&
                format(new Date(data?.keyDates?.anniversary), DATE_FORMAT),
            relationshipStartDate:
                data?.keyDates?.relationshipStartDate &&
                format(
                    new Date(data?.keyDates?.relationshipStartDate),
                    DATE_FORMAT,
                ),
            socialMedia: data.socialMedia,
            isPrimaryContact: true,
            onClick: () => handleModify(1),
        };
    }

    return (
        <Box display={'flex'} flexDirection={'column'} p={2}>
            <PreviewOrganizationCard
                {...organizationConvertor(data as LandPartner)}
            />
            <Box
                display={'flex'}
                flexDirection={'column'}
                rowGap={2}
                p={2}
                mb={4}
                sx={{
                    flexGrow: 1,
                }}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        {isEdit ? (
                            <PreviewMemberCard
                                {...primaryContactConvertorUpdate(
                                    primaryContactUpdate as PrimaryContactUpdate,
                                )}
                            />
                        ) : (
                            <PreviewMemberCard
                                {...primaryContactConvertorCreate}
                            />
                        )}
                    </Grid>

                    {!!childContacts?.length &&
                        childContacts.map((contact: Contact, index: number) => {
                            return (
                                <Grid item xs={6} key={index}>
                                    <PreviewMemberCard
                                        {...childContactsConvertor(contact)}
                                    />
                                </Grid>
                            );
                        })}
                </Grid>
            </Box>
        </Box>
    );
}
export const phoneTypeMapper: Record<string, string> = {
    CELL_PHONE: 'Cell',
    OFFICE_PHONE: 'Office',
    HOME_PHONE: 'Home',
    FAX: 'Fax',
};
