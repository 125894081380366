import SelectField from '../../../../design/Fields/SelectField';
import React from 'react';
import customToastWithAlert from '../../../../design/CustomToastWithAlert';
import { Box, Typography } from '@mui/material';

interface OwnProps {
    id: string;
    name: string;
}

export default function SwitchGrowerInfoBox({ id, name }: OwnProps) {
    const growerOption = [
        {
            id,
            name,
        },
    ];

    const handleGrowerChange = () => {
        customToastWithAlert({
            type: 'error',
            message: 'Not implemented yet.',
        });
    };

    return (
        <Box
            display={'flex'}
            flexDirection={'column'}
            rowGap={1}
            p={2}
            width={'80%'}>
            <Typography align={'left'} variant={'font14'} fontWeight={'bold'}>
                Switch Grower
            </Typography>
            <SelectField
                placeholder={'Switch Grower'}
                value={id}
                options={growerOption}
                onChange={handleGrowerChange}
                isDisabled={true}
                size={'small'}
            />
        </Box>
    );
}
