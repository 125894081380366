import React, { ReactNode } from 'react';
import { Grid, Typography } from '@mui/material';

interface ThreeColumn {
    leftComponent: ReactNode;
    middleComponent: ReactNode;
    rightComponent: ReactNode;
    leftColumnWidth: Scheme;
    rightColumnWidth: Scheme;
    header?: string;
}
type Scheme = 'compact' | 'medium' | 'large' | 'equal';
const schemeMapping: Record<Scheme, number> = {
    compact: 1,
    medium: 2,
    large: 3,
    equal: 4,
};
export default function ThreeColumnLayout({
    leftComponent,
    middleComponent,
    rightComponent,
    leftColumnWidth,
    rightColumnWidth,
    header,
}: ThreeColumn) {
    const rightColumnSize = schemeMapping[rightColumnWidth];
    const leftColumnSize = schemeMapping[leftColumnWidth];
    const middleColumnSize = 12 - rightColumnSize - leftColumnSize;

    return (
        <Grid container p={1}>
            {header && (
                <Typography
                    variant="font32"
                    color={'text.light'}
                    fontWeight={'regular'}>
                    {header}
                </Typography>
            )}
            <Grid container item>
                <Grid item xs={leftColumnSize}>
                    {leftComponent}
                </Grid>
                <Grid item xs={middleColumnSize}>
                    {middleComponent}
                </Grid>
                <Grid item xs={rightColumnSize}>
                    {rightComponent}
                </Grid>
            </Grid>
        </Grid>
    );
}
