import React from 'react';
import { Stack, Typography } from '@mui/material';

import RecentUploads from './RecentUploads';
import DashboardOverviewCharts from './DashboardOverviewCharts';

export default function ViewFarmLandDashboard() {
    return (
        <Stack rowGap={4} paddingLeft={2} paddingRight={2}>
            <DashboardOverviewCharts />

            <Typography variant={'font16'} fontWeight={'bold'}>
                Recent Uploads
            </Typography>
            <RecentUploads />
        </Stack>
    );
}
