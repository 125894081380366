export const inputBaseOverrides = {
    MuiInputBase: {
        styleOverrides: {
            root: {
                fontSize: '16px',
                textTransform: 'unset',
                fontWeight: 500,
                lineHeight: '1.33',
            },
            inputSizeSmall: {
                padding: '8px 16px !important',
            },
        },
    },
};
export const outlinedInputOverrides = {
    MuiOutlinedInput: {
        styleOverrides: {
            input: {
                padding: '12px 16px',
            },
        },
    },
};
