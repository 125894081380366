import React from 'react';
import { ConnectableElement, useDrag, useDrop } from 'react-dnd';
import { Box, Typography } from '@mui/material';
import theme from '../../../styles/theme';
import DragIndicatorOutlinedIcon from '@mui/icons-material/DragIndicatorOutlined';
import CloseButton from '../../../design/Buttons/CloseButton';

interface OwnProps {
    text: string;
    index: number;
    moveItem: (dragIndex: number, hoverIndex: number) => void;
    handleRemoveItem: (text: string) => void;
}

export default function DndItem({
    text,
    index,
    moveItem,
    handleRemoveItem,
}: OwnProps) {
    const [{ isDragging }, drag] = useDrag({
        type: 'ITEM',
        item: { text, index },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    const [, drop] = useDrop({
        accept: 'ITEM',
        hover: (draggedItem: { text: string; index: number }) => {
            if (draggedItem.index !== index) {
                moveItem(draggedItem.index, index);
                draggedItem.index = index;
            }
        },
    });

    return (
        <Box
            ref={(node) => drag(drop(node as ConnectableElement))}
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                opacity: isDragging ? 0.5 : 1,
                backgroundColor: theme.palette.yellowGreen.yellowGreenTint8,
                margin: '4px',
                border: `1px solid ${theme.palette.strokeS1}`,
                cursor: 'pointer',
            }}>
            <Box
                display={'flex'}
                rowGap={1}
                sx={{
                    color: theme.palette.neutral.neutral6,
                }}>
                <DragIndicatorOutlinedIcon />
                <Typography color="text.neutral6" textTransform={'capitalize'}>
                    {text}
                </Typography>
            </Box>
            <CloseButton onClick={() => handleRemoveItem(text)} />
        </Box>
    );
}
