import SelectField from '../../../design/Fields/SelectField';
import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';

export interface Option {
    id: string;
    name: string;
}

interface OwnProps {
    isHidden?: boolean;
    options: Option[];
    onChange: (value: ShareDataOnChange) => void;
    reset: boolean;
    rowId: string;
}

export interface ShareDataOnChange {
    value: string;
    rowId: string;
}

export default function ShareDataSelectField({
    isHidden = false,
    options,
    onChange,
    reset,
    rowId,
}: OwnProps) {
    const [selectedOption, setSelectedOption] = useState<Option | null>(null);

    useEffect(() => {
        if (reset) {
            setSelectedOption(null);
        }
    }, [reset]);

    if (isHidden) return null;

    function handleChange(value: string) {
        const selected = options.find((option) => option.id === value) || null;
        setSelectedOption(selected);

        if (selected?.id) {
            onChange({ value: selected.id, rowId });
        }
    }

    return (
        <Box width={200}>
            <SelectField
                size={'small'}
                fullWidth
                value={selectedOption?.id || ''}
                name={'period'}
                placeholder={'Select'}
                options={options}
                onChange={handleChange}
                height={30}
            />
        </Box>
    );
}
