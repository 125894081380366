import { createContext, Dispatch, SetStateAction } from 'react';

export type RequestDocumentType =
    | 'LEASE'
    | 'LAND'
    | 'FINANCIAL'
    | 'TASK'
    | 'FILINGS'
    | 'REPORTS'
    | 'OTHER'
    | '';
export const DocumentTypeContext = createContext<{
    type: RequestDocumentType | null;
    setType: Dispatch<SetStateAction<RequestDocumentType | null>>;
}>({
    type: '',
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setType: () => {},
});
