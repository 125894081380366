import React from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import UserAvatar from '../../design/Avatar/UserAvatar';
import { ReactComponent as SnoozeIcon } from '../../assets/icons/snooze_icon.svg';
import { ReactComponent as Alert } from '../../assets/icons/alert_icon.svg';
import { ReactComponent as RemoveIcon } from '../../assets/icons/remove_icon.svg';
import { ReactComponent as AlarmIcon } from '../../assets/icons/alarm_icon.svg';
import BadgeForNotificationAvatar from './BadgeForNotificationAvatar';
import { makeStyles } from 'tss-react/mui';
import theme from '../../styles/theme';
import { dateTimeConverterFromUTC, isToday } from '../../utils/dateFormat';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import PATHS from '../../router/paths';

export type NotificationType =
    | 'REMINDER'
    | 'BIRTHDAY'
    | 'ANNIVERSARY'
    | 'PAYMENT'
    | 'TASKS'
    | 'ACTIVITY_FAILED'
    | 'LEASE_RENEWALS';

export interface Notification {
    id: string;
    message: string;
    type: NotificationType;
    referenceId?: string;
    contact?: Contact;
    snoozed: boolean;
    read: boolean;
    overdue: boolean;
    eventDate: string;
    updatedAt: string;
    createdAt: string;
}

export interface Contact {
    id: string;
    createdAt: string;
    updatedAt: string;
    firstName: string;
    lastName: string;
    contactAvatar: ContactAvatar;
}

export interface ContactAvatar {
    id: string;
    fileName: string;
    fileType: string;
    contactAvatar: string;
}

interface OwnProps extends Notification {
    onSnooze: (id: string) => void;
    onDelete: (id: string) => void;
}

export default function NotificationCard({
    id,
    message,
    type,
    snoozed,
    createdAt,
    contact,
    overdue,
    eventDate,
    onSnooze,
    onDelete,
}: OwnProps) {
    const { classes } = useStyles();
    const { firstName, lastName, contactAvatar } = contact || {};
    const navigate = useNavigate();
    return (
        <div className={classes.root}>
            <div>
                {type !== 'ACTIVITY_FAILED' ? (
                    <BadgeForNotificationAvatar type={type}>
                        <UserAvatar
                            name={
                                firstName || lastName
                                    ? `${firstName} ${lastName} `
                                    : 'No / Avatar'
                            }
                            avatar={contactAvatar?.contactAvatar}
                        />
                    </BadgeForNotificationAvatar>
                ) : (
                    <Grid className={classes.alertIconCircle1}>
                        <Grid className={classes.alertIconCircle2}>
                            <Alert className={classes.alarmIcon} />
                        </Grid>
                    </Grid>
                )}
            </div>

            <Box display={'flex'} flexDirection={'column'} gap={1}>
                <Typography
                    variant="font14"
                    color="text.link"
                    fontWeight="medium">
                    {message}
                </Typography>
                <Box display={'flex'} gap={2} alignItems={'center'}>
                    <Typography
                        variant="font12"
                        color="text.secondaryContrast"
                        fontWeight="medium">
                        {dateTimeConverterFromUTC(createdAt)}
                    </Typography>
                    {!isToday(eventDate) && overdue && (
                        <Box display={'flex'} columnGap={1}>
                            <Alert className={classes.alertIcon} />
                            <Typography
                                variant="font12"
                                color="text.secondaryContrast"
                                fontWeight="medium">
                                Overdue
                            </Typography>
                        </Box>
                    )}
                </Box>
            </Box>
            <Box display={'flex'} columnGap={1}>
                {type !== 'ACTIVITY_FAILED' &&
                    !overdue &&
                    !isToday(eventDate) && (
                        <Button
                            className={clsx(
                                classes.button,
                                snoozed && classes.snoozeIcon,
                            )}
                            disabled={snoozed}
                            onClick={() => {
                                onSnooze(id);
                            }}
                            startIcon={<SnoozeIcon />}
                            variant={'outlined'}>
                            {snoozed ? 'Snoozed' : 'Snooze'}
                        </Button>
                    )}
                {type !== 'ACTIVITY_FAILED' && (
                    <Button
                        className={classes.button}
                        variant={'outlined'}
                        onClick={() => {
                            onDelete(id);
                        }}
                        startIcon={<RemoveIcon />}>
                        Delete
                    </Button>
                )}
                {type === 'ACTIVITY_FAILED' && (
                    <Button
                        className={classes.button}
                        variant={'outlined'}
                        onClick={() => {
                            navigate(`/${PATHS.notifications}`);
                        }}
                        startIcon={<AlarmIcon />}>
                        Review
                    </Button>
                )}
            </Box>
        </div>
    );
}

const useStyles = makeStyles()(() => ({
    root: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        padding: theme.spacing(2),
        backgroundColor: theme.palette.backgroundB.backgroundB2,
        gap: theme.spacing(1),
        '&:hover': {
            backgroundColor: theme.palette.lightTurq.lightTurqTint2,
        },
    },
    button: {
        padding: theme.spacing(0.5, 1),
        color: theme.palette.brandLightTurq,
        '& svg': {
            fill: theme.palette.brandLightTurq,
        },
    },
    alertIconCircle1: {
        width: 40,
        height: 40,
        backgroundColor: theme.palette.statusRed,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50%',
    },
    alertIconCircle2: {
        width: 30,
        height: 30,
        backgroundColor: theme.palette.brandDarkTurq,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingBottom: theme.spacing(0.5),
        borderRadius: '50%',
    },
    snoozeIcon: {
        '& svg': {
            fill: theme.palette.neutral.neutral3,
        },
    },
    alertIcon: {
        width: 16,
        height: 16,
        fill: theme.palette.neutral.neutral6,
    },
    alarmIcon: {
        width: 25,
        height: 25,
        fill: theme.palette.neutralWhite,
    },
}));
