import React from 'react';
import { useSettingsFromStorage } from '../../hooks/useSettingsFromStorage';
import { useSorting } from '../../design/GenericTable/useSorting';
import { usePagination } from '../../design/GenericTable/useBackendPagination';
import { useSaveSettings } from '../../hooks/useSaveSettings';
import { TableColumn } from '../../design/GenericTable/GenericTable';
import BasedTooltip from '../../design/BasedTooltip';
import { formatCurrency } from '../../utils/fieldMasks';
import ExpandedComponent from './ExpandedComponent';
import PATHS from '../../router/paths';
import LinkText from '../../design/Fields/LinkText';

export interface FieldsData {
    id: string;
    landownerNames: string;
    fieldName: string;
    location: string;
    leaseName: string;
    documents: File;
    tractNumber: number[];
    cluid: string;
    state: string;
    totalAnnualRent: number;
    parcelId: string;
    stateFips: string;
    countyFips: string;
    countyName: string;
    fsaId: string;
    landowners: { id: string; name: string };
    totalAcres: number;
    rentPerAcre: number;
    tillableAcres: number;
    createdBy: string;
    updatedBy: string;
    updatedByName: string;
    createdByName: string;
    createdAt: string;
    updatedAt: string;
}

export function useFieldsTable() {
    const { settings, saveSettings } = useSettingsFromStorage('FIELDS');
    const { sort, page, sortDirection, size } = settings;
    const { sortKey, sortOrder, handleSort } = useSorting(
        sort || 'createdAt',
        sortDirection || 'asc',
    );
    const {
        currentPage,
        itemsPerPage,
        handlePageChange,
        handleItemsPerPageChange,
    } = usePagination(page || 1, size || 25);

    useSaveSettings({
        settings,
        saveSettings,
        sortKey,
        sortOrder,
        currentPage,
        itemsPerPage,
    });

    const columns: TableColumn<FieldsData>[] = [
        {
            header: {
                label: 'Field Name',
                sortable: true,
                sorting: {
                    id: 'fieldName',
                    direction: sortKey === 'fieldName' ? sortOrder : 'desc',
                    isSorted: sortKey === 'fieldName',
                },
                onClick: handleSort,
            },
            cellRender: (data) => (
                <BasedTooltip>
                    <LinkText
                        to={`/${PATHS.landPortfolio}/${PATHS.fields}/${data?.id}`}
                        text={data?.fieldName || ''}
                        variant={'font14'}
                    />
                </BasedTooltip>
            ),
            format: { align: 'left', color: { color: 'primary' } },
            maxColumnWidth: '25%',
        },
        {
            header: {
                label: 'FSA ID',
                sortable: true,
                sorting: {
                    id: 'fsaId',
                    direction: sortKey === 'fsaId' ? sortOrder : 'desc',
                    isSorted: sortKey === 'fsaId',
                },
                onClick: handleSort,
            },
            cellRender: (data) => <>{data?.fsaId}</>,
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
        },
        {
            header: {
                label: 'Landowner',
                sortable: true,
                sorting: {
                    id: 'landowners',
                    direction: sortKey === 'landowners' ? sortOrder : 'desc',
                    isSorted: sortKey === 'landowners',
                },
                onClick: handleSort,
            },
            cellRender: (data) => <>{data?.landowners.name}</>,
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
        },
        {
            header: {
                label: 'Total Acres',
                sortable: true,
                sorting: {
                    id: 'totalAcres',
                    direction: sortKey === 'totalAcres' ? sortOrder : 'desc',
                    isSorted: sortKey === 'totalAcres',
                },
                onClick: handleSort,
            },
            cellRender: (data) => <>{data?.totalAcres}</>,
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
        },
        {
            header: {
                label: 'Tillable',
                sortable: true,
                sorting: {
                    id: 'tillableAcres',
                    direction: sortKey === 'tillableAcres' ? sortOrder : 'desc',
                    isSorted: sortKey === 'tillableAcres',
                },
                onClick: handleSort,
            },
            cellRender: (data) => <>{data?.tillableAcres}</>,
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
        },
        {
            header: {
                label: 'Rent Per Acre',
                sortable: true,
                sorting: {
                    id: 'rentPerAcre',
                    direction: sortKey === 'rentPerAcre' ? sortOrder : 'desc',
                    isSorted: sortKey === 'rentPerAcre',
                },
                onClick: handleSort,
            },
            cellRender: (data) => <>{formatCurrency(data?.rentPerAcre)}</>,
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
        },
    ];
    const tableConfig = {
        columns,
        pageable: true,
        handlePageChange,
        handleItemsPerPageChange,
        initialPageSize: size,
        initialPage: page,
        expandable: true,
        ExpandComponent: ExpandedComponent,
    };
    const totalCount = mockFieldsData?.length || 0;
    return { data: mockFieldsData || [], totalCount, tableConfig };
}

export const mockFieldsData: FieldsData[] = [
    {
        id: '1',
        landownerNames: 'Michael Green, Laura Green',
        fieldName: 'Sunrise Valley',
        location: 'Near Highway 54, Des Moines, IA',
        leaseName: 'Lease Alpha',
        documents: {
            name: 'LeaseAlphaDocument.pdf',
            size: 3500000,
            type: 'application/pdf',
        } as File,
        tractNumber: [110, 111],
        cluid: 'CLUID001',
        state: 'Iowa',
        totalAnnualRent: 54190.1,
        parcelId: 'PID001',
        stateFips: '19',
        countyFips: '153',
        countyName: 'Polk County',
        fsaId: 'FSA1001',
        landowners: { id: 'LO001', name: 'Michael Green' },
        totalAcres: 180.2,
        rentPerAcre: 300.5,
        tillableAcres: 160,
        createdBy: 'userA',
        updatedBy: 'userB',
        updatedByName: 'David Parker',
        createdByName: 'Emily Carter',
        createdAt: '2023-03-10T08:22:13Z',
        updatedAt: '2023-05-15T11:35:45Z',
    },
    {
        id: '2',
        landownerNames: 'Benjamin King',
        fieldName: 'Maple Grove',
        location: 'East of County Road 23, Kansas City, KS',
        leaseName: 'Lease Bravo',
        documents: {
            name: 'LeaseBravoDocument.pdf',
            size: 2750000,
            type: 'application/pdf',
        } as File,
        tractNumber: [115],
        cluid: 'CLUID002',
        state: 'Kansas',
        totalAnnualRent: 26262.5,
        parcelId: 'PID002',
        stateFips: '20',
        countyFips: '209',
        countyName: 'Wyandotte County',
        fsaId: 'FSA1002',
        landowners: { id: 'LO002', name: 'Benjamin King' },
        totalAcres: 95.5,
        rentPerAcre: 275.0,
        tillableAcres: 90,
        createdBy: 'userC',
        updatedBy: 'userD',
        updatedByName: 'Olivia Brooks',
        createdByName: 'Sophia Davis',
        createdAt: '2023-01-22T10:15:30Z',
        updatedAt: '2023-02-18T14:18:22Z',
    },
    {
        id: '3',
        landownerNames: 'Emma White',
        fieldName: 'Cedar Ridge',
        location: 'West of Route 66, Springfield, IL',
        leaseName: 'Lease Charlie',
        documents: {
            name: 'LeaseCharlieDocument.pdf',
            size: 5000000,
            type: 'application/pdf',
        } as File,
        tractNumber: [120, 121, 122],
        cluid: 'CLUID003',
        state: 'Illinois',
        totalAnnualRent: 67200.0,
        parcelId: 'PID003',
        stateFips: '17',
        countyFips: '021',
        countyName: 'Sangamon County',
        fsaId: 'FSA1003',
        landowners: { id: 'LO003', name: 'Emma White' },
        totalAcres: 210.0,
        rentPerAcre: 320.0,
        tillableAcres: 180,
        createdBy: 'userE',
        updatedBy: 'userF',
        updatedByName: 'James Wilson',
        createdByName: 'Ella Turner',
        createdAt: '2023-04-05T14:45:00Z',
        updatedAt: '2023-06-07T16:55:40Z',
    },
    {
        id: '4',
        landownerNames: 'William Harris',
        fieldName: 'Blue River',
        location: 'Near Interstate 65, Indianapolis, IN',
        leaseName: 'Lease Delta',
        documents: {
            name: 'LeaseDeltaDocument.pdf',
            size: 3100000,
            type: 'application/pdf',
        } as File,
        tractNumber: [130],
        cluid: 'CLUID004',
        state: 'Indiana',
        totalAnnualRent: 36453.0,
        parcelId: 'PID004',
        stateFips: '18',
        countyFips: '097',
        countyName: 'Marion County',
        fsaId: 'FSA1004',
        landowners: { id: 'LO004', name: 'William Harris' },
        totalAcres: 125.7,
        rentPerAcre: 290.0,
        tillableAcres: 115,
        createdBy: 'userG',
        updatedBy: 'userH',
        updatedByName: 'Ava Roberts',
        createdByName: 'Mason Mitchell',
        createdAt: '2023-07-14T09:12:00Z',
        updatedAt: '2023-08-01T11:45:30Z',
    },
    {
        id: '5',
        landownerNames: 'Sophia Martinez',
        fieldName: 'Redwood Plains',
        location: 'South of Route 20, Boise, ID',
        leaseName: 'Lease Echo',
        documents: {
            name: 'LeaseEchoDocument.pdf',
            size: 3700000,
            type: 'application/pdf',
        } as File,
        tractNumber: [140, 141],
        cluid: 'CLUID005',
        state: 'Idaho',
        totalAnnualRent: 53458.5,
        parcelId: 'PID005',
        stateFips: '16',
        countyFips: '001',
        countyName: 'Ada County',
        fsaId: 'FSA1005',
        landowners: { id: 'LO005', name: 'Sophia Martinez' },
        totalAcres: 175.3,
        rentPerAcre: 305.25,
        tillableAcres: 165,
        createdBy: 'userI',
        updatedBy: 'userJ',
        updatedByName: 'Liam Reed',
        createdByName: 'Charlotte Walker',
        createdAt: '2023-02-28T07:30:45Z',
        updatedAt: '2023-04-10T13:40:55Z',
    },
    {
        id: '6',
        landownerNames: 'Lucas Anderson, Grace Anderson',
        fieldName: 'Golden Meadow',
        location: 'North of Route 50, Honolulu, HI',
        leaseName: 'Lease Foxtrot',
        documents: {
            name: 'LeaseFoxtrotDocument.pdf',
            size: 2600000,
            type: 'application/pdf',
        } as File,
        tractNumber: [150],
        cluid: 'CLUID006',
        state: 'Hawaii',
        totalAnnualRent: 23400.0,
        parcelId: 'PID006',
        stateFips: '15',
        countyFips: '003',
        countyName: 'Honolulu County',
        fsaId: 'FSA1006',
        landowners: { id: 'LO006', name: 'Lucas Anderson' },
        totalAcres: 90.0,
        rentPerAcre: 260.0,
        tillableAcres: 85,
        createdBy: 'userK',
        updatedBy: 'userL',
        updatedByName: 'Amelia Adams',
        createdByName: 'Henry Carter',
        createdAt: '2023-03-16T15:18:20Z',
        updatedAt: '2023-05-21T16:35:05Z',
    },
    {
        id: '7',
        landownerNames: 'Ethan Cooper',
        fieldName: 'Riverbend',
        location: 'Along Route 92, Atlanta, GA',
        leaseName: 'Lease Golf',
        documents: {
            name: 'LeaseGolfDocument.pdf',
            size: 4200000,
            type: 'application/pdf',
        } as File,
        tractNumber: [160, 161, 162],
        cluid: 'CLUID007',
        state: 'Georgia',
        totalAnnualRent: 62486.1,
        parcelId: 'PID007',
        stateFips: '14',
        countyFips: '089',
        countyName: 'DeKalb County',
        fsaId: 'FSA1007',
        landowners: { id: 'LO007', name: 'Ethan Cooper' },
        totalAcres: 200.8,
        rentPerAcre: 310.75,
        tillableAcres: 180,
        createdBy: 'userM',
        updatedBy: 'userN',
        updatedByName: 'Zoe Foster',
        createdByName: 'Logan Perry',
        createdAt: '2023-05-10T08:45:55Z',
        updatedAt: '2023-07-20T10:20:40Z',
    },
    {
        id: '8',
        landownerNames: 'Olivia Hill',
        fieldName: 'Pine Ridge',
        location: 'Near Interstate 80, Lincoln, NE',
        leaseName: 'Lease Hotel',
        documents: {
            name: 'LeaseHotelDocument.pdf',
            size: 4800000,
            type: 'application/pdf',
        } as File,
        tractNumber: [170],
        cluid: 'CLUID008',
        state: 'Nebraska',
        totalAnnualRent: 52864.0,
        parcelId: 'PID008',
        stateFips: '31',
        countyFips: '055',
        countyName: 'Lancaster County',
        fsaId: 'FSA1008',
        landowners: { id: 'LO008', name: 'Olivia Hill' },
        totalAcres: 176.0,
        rentPerAcre: 300.5,
        tillableAcres: 150,
        createdBy: 'userO',
        updatedBy: 'userP',
        updatedByName: 'Jackson Harris',
        createdByName: 'Lucas Wood',
        createdAt: '2023-04-22T07:00:30Z',
        updatedAt: '2023-06-15T09:30:50Z',
    },
    {
        id: '9',
        landownerNames: 'Ava Clark',
        fieldName: 'Willow Creek',
        location: 'North of Route 22, Helena, MT',
        leaseName: 'Lease India',
        documents: {
            name: 'LeaseIndiaDocument.pdf',
            size: 5200000,
            type: 'application/pdf',
        } as File,
        tractNumber: [180],
        cluid: 'CLUID009',
        state: 'Montana',
        totalAnnualRent: 48675.0,
        parcelId: 'PID009',
        stateFips: '30',
        countyFips: '043',
        countyName: 'Jefferson County',
        fsaId: 'FSA1009',
        landowners: { id: 'LO009', name: 'Ava Clark' },
        totalAcres: 162.5,
        rentPerAcre: 299.5,
        tillableAcres: 140,
        createdBy: 'userQ',
        updatedBy: 'userR',
        updatedByName: 'Mia Ward',
        createdByName: 'Harper Cook',
        createdAt: '2023-06-08T11:12:50Z',
        updatedAt: '2023-08-05T14:45:25Z',
    },
    {
        id: '10',
        landownerNames: 'Isabella Walker',
        fieldName: 'Oak Grove',
        location: 'South of Route 24, Nashville, TN',
        leaseName: 'Lease Juliet',
        documents: {
            name: 'LeaseJulietDocument.pdf',
            size: 3150000,
            type: 'application/pdf',
        } as File,
        tractNumber: [190],
        cluid: 'CLUID010',
        state: 'Tennessee',
        totalAnnualRent: 46473.75,
        parcelId: 'PID010',
        stateFips: '47',
        countyFips: '037',
        countyName: 'Davidson County',
        fsaId: 'FSA1010',
        landowners: { id: 'LO010', name: 'Isabella Walker' },
        totalAcres: 157.5,
        rentPerAcre: 295.25,
        tillableAcres: 150,
        createdBy: 'userS',
        updatedBy: 'userT',
        updatedByName: 'William King',
        createdByName: 'Olivia White',
        createdAt: '2023-06-05T12:10:30Z',
        updatedAt: '2023-08-15T13:50:45Z',
    },
];
