import React, { useState } from 'react';
import DocumentsViewActions from './DocumentsViewActions';
import { Box } from '@mui/material';
import DocumentTable from './DocumentTable';

export default function DocumentsView() {
    const [selectedDocuments, setSelectedDocuments] = useState<
        Record<string, string[]>
    >({});
    return (
        <Box display={'flex'} flexDirection={'column'} rowGap={2}>
            <DocumentsViewActions selectedDocuments={selectedDocuments} />
            <DocumentTable setSelectedDocuments={setSelectedDocuments} />
        </Box>
    );
}
