import React, { useState } from 'react';
import ToggleButtons from './ToggleButtons';
import SuccessionCompletedTable from './SuccessionCompletedTable';

import { Box } from '@mui/material';
import SuccessionInProgressTable from './SuccessionInProgressTable';
import ActionButton, {
    ActionMenuItem,
} from '../../design/Buttons/ActionButton';
import useManagePermission from '../../hooks/useManagePermission';
import EmergencyReadinessModal from './EmergencyReadinessModal';

export default function EmergencyReadiness() {
    const [tableView, setTableView] = useState('completed');
    const { checkPermission } = useManagePermission();
    const [
        isEmergencyReadinessModalShowing,
        setIsEmergencyReadinessModalShowing,
    ] = useState(false);

    const actionButtonItems: ActionMenuItem[] = [
        {
            label: 'Start Emergency Readiness',
            onClick: () => {
                setIsEmergencyReadinessModalShowing(true);
            },
            hidden: !checkPermission('services', 'read'),
        },
    ];
    return (
        <Box display={'flex'} flexDirection={'column'} rowGap={2}>
            <Box display={'flex'} justifyContent={'space-between'}>
                <ToggleButtons view={tableView} handleView={setTableView} />
                <ActionButton label={'Actions'} items={actionButtonItems} />
            </Box>
            {tableView === 'completed' ? (
                <SuccessionCompletedTable />
            ) : (
                <SuccessionInProgressTable />
            )}
            {isEmergencyReadinessModalShowing && (
                <EmergencyReadinessModal
                    isShowing={isEmergencyReadinessModalShowing}
                    onClose={() => setIsEmergencyReadinessModalShowing(false)}
                />
            )}
        </Box>
    );
}
