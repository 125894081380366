import React from 'react';
import SideBarImage from '../../assets/images/farmMachine.png';
import { makeStyles } from 'tss-react/mui';
import { Box, Typography, Link as NavLink } from '@mui/material';
import { ReactComponent as Logo } from '../../assets/icons/logo2.svg';
import theme from '../../styles/theme';

export default function ExpiredLinkScreen() {
    const { classes } = useStyles();
    const currentYear = new Date().getFullYear();
    return (
        <Box
            boxSizing={'border-box'}
            display={'flex'}
            width={'100%'}
            height={'100vh'}>
            <div className={classes.mainContainer}>
                <Logo className={classes.logo} />
                <Box pt={10} pb={5} display={'flex'} flexDirection={'column'}>
                    <Typography
                        variant={'font36'}
                        color={'text.neutral10'}
                        fontWeight={'light'}>
                        Your activation link has expired.
                    </Typography>
                    <Typography
                        variant={'font36'}
                        color={'text.neutral10'}
                        fontWeight={'light'}>
                        Please contact OAKEN administrator via
                        <NavLink
                            variant={'font36'}
                            underline={'hover'}
                            href={'mailto:hopper@oaken.ag'}
                            color={'text.link'}>
                            {' '}
                            hopper@oaken.ag
                        </NavLink>
                    </Typography>
                </Box>
                <Box
                    display={'flex'}
                    flex={1}
                    justifyContent={'space-between'}
                    alignItems={'flex-end'}>
                    <Typography variant={'font12'} color={'text.neutral6'}>
                        © {currentYear} OAKEN, INC. ALL RIGHTS RESERVED.
                    </Typography>
                    <NavLink
                        target={'_blank'}
                        href={'https://www.oaken.ag/end-user-terms-1'}
                        className={classes.link}>
                        PRIVACY POLICY
                    </NavLink>
                </Box>
            </div>
            <Box boxSizing={'border-box'} width={'50%'}>
                <img
                    src={SideBarImage}
                    alt={'background'}
                    className={classes.image}
                />
            </Box>
        </Box>
    );
}

const useStyles = makeStyles()(() => ({
    mainContainer: {
        padding: '120px 130px 40px 110px',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '50%',
        justifyContent: 'space-between',
    },
    logo: {
        width: '225px',
        height: '75px',
    },
    link: {
        fontSize: '11px',
        fontWeight: 500,
        lineHeight: '1.27',
        color: theme.palette.brandLightTurq,
        textDecoration: 'unset',
    },
    imageContainer: {
        height: '100vh',
        overflowY: 'hidden',
        width: '50vw',
        display: 'flex',
        alignItems: 'flex-end',
    },
    image: {
        display: 'block',
        width: '100%',
        height: 'calc(100% - 7px)',
        objectFit: 'cover',
        overflowY: 'hidden',
    },
}));
