import React from 'react';
import PostView, { Post } from './PostView';

import { ActivityPost } from '../LandPartnerActivity';
import PostCommentsList from './PostCommentsList';

import { Comment } from './PostComment';
import { Box, Grid } from '@mui/material';
import theme from '../../../styles/theme';

import Loader from '../../../design/BaseLoader';
import CommentInput from './CommentInput';
import customToastWithAlert from '../../../design/CustomToastWithAlert';
import {
    useAddCommentAttachmentMutation,
    useAddCommentMutation,
} from '../../../api/landpartners/activityAPI';

interface OwnProps {
    activity: ActivityPost;
    isLoading: boolean;
}

export default function Activity({ activity, isLoading }: OwnProps) {
    const isEmail = activity?.type === 'EMAIL';
    const [addComment, { isLoading: isLoadingComment }] =
        useAddCommentMutation();
    const [addCommentAttachment, { isLoading: isLoadingAttach }] =
        useAddCommentAttachmentMutation();

    async function handleAddComment(comment: string, file: File | null) {
        try {
            const { data } = (await addComment({
                activityId: activity.id,
                comment: {
                    landPartnerId: activity.landPartnerId,
                    comment,
                },
            })) as { data: Comment };

            if (file && data.id) {
                await addCommentAttachment({
                    id: data.id,
                    attachment: file,
                });
            }
        } catch (error) {
            customToastWithAlert({
                type: 'error',
                message: 'Comment attachment upload failed',
            });
        }
    }
    if (isLoading) {
        return (
            <Grid
                container
                sx={{ height: '100vh' }}
                alignItems={'center'}
                justifyContent={'center'}
                flexDirection={'column'}>
                <Loader />
            </Grid>
        );
    }
    return (
        <Box
            width={'100%'}
            display={'flex'}
            justifyContent={'flex-end'}
            p={theme.spacing(0, 3, 2)}
            bgcolor={theme.palette.backgroundB.backgroundB2}
            rowGap={2}>
            <Box
                display={'flex'}
                flexDirection={'column'}
                width={'70%'}
                rowGap={2}>
                <PostView
                    isLoading={isLoading}
                    {...convertDataToPostView(activity)}
                />
                {isEmail && (
                    <Box display={'flex'} flexDirection={'column'} rowGap={3}>
                        <PostCommentsList
                            comments={convertDataToPostCommentsList(activity)}
                        />
                        <CommentInput
                            addComment={handleAddComment}
                            isLoading={isLoadingComment || isLoadingAttach}
                        />
                    </Box>
                )}
            </Box>
        </Box>
    );
}

function convertDataToPostCommentsList(data: ActivityPost): Comment[] {
    if (!data) return [];
    return data?.comments
        ? data.comments
              .map((comment) => ({
                  id: comment.id,
                  name: comment.from,
                  date: comment.createdAt,
                  comment: comment.comment,
                  attachments: comment.attachments,
              }))
              .sort(
                  (a, b) =>
                      new Date(b.date).getTime() - new Date(a.date).getTime(),
              )
        : [];
}

function convertDataToPostView(data: ActivityPost): Post {
    if (!data) return {} as Post;
    return {
        id: data.id,
        metadata: null,
        landPartnerId: data.landPartnerId,
        location: data.location,
        eventDate: data.eventDate,
        message: data.body,
        guests: data.guests,
        attachments: data.attachments,
        type: data.type,
    };
}
