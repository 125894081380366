import React from 'react';
import { Box, Grid } from '@mui/material';
import BasicTabsPanel from '../../design/TabsPanel/BasicTabsPanel';

import TaskFormTabHistory from './TaskFormTabHistory';
import TaskFormUpdate from './TaskFormUpdate';
import Loader from '../../design/BaseLoader';

interface OwnProps {
    isLoading: boolean;
}
const tabsConfig = [
    {
        label: 'Edit Task',
        content: <TaskFormUpdate />,
        value: 0,
        to: '',
    },
    {
        label: 'Task History',
        content: <TaskFormTabHistory />,
        value: 1,
        to: '',
    },
];

export default function TaskFormTabsUpdate({ isLoading }: OwnProps) {
    if (isLoading) {
        return (
            <Grid
                container
                sx={{ height: '100%' }}
                alignItems={'center'}
                justifyContent={'center'}
                flexDirection={'column'}>
                <Loader />
            </Grid>
        );
    }
    return (
        <Box display={'flex'} width={'100%'}>
            <BasicTabsPanel tabItems={tabsConfig} />
        </Box>
    );
}
