import React from 'react';

import { Box, Typography } from '@mui/material';

import PATHS from '../../router/paths';
import InfoTooltip from '../../design/Tooltip/InfoTooltip';
import LinkText from '../../design/Fields/LinkText';
import BasedTooltip from '../../design/BasedTooltip';
import { LeaseType } from '../../features/CreateLeaseForm/LeaseWizard';
import { Expenses } from '../../features/CreateLeaseForm/ExpensesDetailForm';

export interface Lease {
    bonusCriteria: string | undefined;
    leaseType: LeaseType;
    id: string;
    agent: { id: string; name: string };
    landownerNames?: string;
    fieldName?: string;
    leaseName: string;
    leaseFile?: File;
    expenses: Expenses[];
    supportingFile?: File[];
    landowners: { id: string; name: string }[];
    grower: { id: string; name: string };
    fsaIds: LeaseFsaId[];
    paymentDistributions: PaymentDistribution;
    cropShareDistributions: CropShareDistribution[];
    term: string;
    startDate: string;
    endDate: string;
    totalAcres: number;
    aveRentPerAcre: number;
    tillableAcres: number;
    type: string;
    aph: string;
    lumpSum: number;
    createdBy: string;
    createdAt: string;
    updatedBy: string;
    updatedByName: string;
    createdByName: string;
    totalPayment: string;
    fieldPercent: string;
    cropStorage: string;
    updatedAt: string;
    cropDelivery: string;
    fsaPoa: boolean;
    verbalLease: boolean;
    autoRenewal: boolean;
    gracePeriodDate: string;
    numberOfPaymentsPerYear: string;
    bonusSchedule?: string;
    isCopy?: boolean;
    paymentSchedule: string[];
}
export interface LeaseFsaId {
    id: string;
    fsaId: string;
    type: string;
    landName: string;
    tillableAcres: number;
}
export interface CropShareDistribution {
    id: string;
    crop: string;
    growerSharePercent: number;
    landownerSharePercent: number;
}
interface PaymentDistribution {
    landowners: PaymentDistributionsLandowners[];
    paymentDates: PaymentDistributionsDates[];
}

export interface PaymentDistributionsLandowners {
    landownerId: string;
    paymentPercent: string;
}
export interface PaymentDistributionsDates {
    paymentDate: string;
    paymentPercent: string;
}
export const showLandownerName = (lease: Lease) => {
    const landownerNamesArray = lease?.landownerNames?.split(', ');
    return landownerNamesArray?.[0];
};
export const showFieldName = (lease: Lease) => {
    const fieldNameArray = lease.fsaIds.map((i) => i?.landName);
    const fieldNameArrayString = fieldNameArray.join('; ');
    const shortFieldName = fieldNameArray[0] || 'Not Available';
    return (
        <Box display={'flex'} alignItems={'center'} gap={1}>
            <LinkText
                to={`/${PATHS.landPortfolio}/${PATHS.manage}/${lease.id}`}
                text={shortFieldName}
                sx={{ textAlign: 'center' }}
                variant={'font14'}
            />
            <span>
                {fieldNameArray &&
                    fieldNameArray.length > 1 &&
                    `+${fieldNameArray.length - 1}`}
            </span>
            {fieldNameArray && fieldNameArray.length > 1 && (
                <InfoTooltip
                    text={fieldNameArrayString || ''}
                    position={'top'}
                />
            )}
        </Box>
    );
};
export const showFsaIdName = (lease: Lease) => {
    if (lease && lease.fsaIds && lease?.fsaIds.length > 0) {
        return (
            <BasedTooltip text={lease?.fsaIds.map((i) => i?.fsaId).join(' ,')}>
                <Box display={'flex'} gap={1}>
                    <Typography
                        sx={{ textAlign: 'center' }}
                        variant={'font14'}
                        color={'text.link'}
                        fontWeight={'medium'}>
                        {lease.fsaIds[0]?.fsaId}
                    </Typography>
                    <span>
                        {lease?.fsaIds.length > 1 &&
                            `+${lease?.fsaIds.length - 1}`}
                    </span>
                    {lease?.fsaIds.length > 1 && (
                        <InfoTooltip
                            text={lease?.fsaIds.map((i) => i?.fsaId).join(' ,')}
                            position={'top'}
                        />
                    )}
                </Box>
            </BasedTooltip>
        );
    }
    return lease && lease.fsaIds && lease.fsaIds[0]?.fsaId;
};
