import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material/styles/createTheme';

const useStyles = makeStyles<Theme>()((theme) => ({
    settingsWrapper: {
        padding: '40px 24px 24px',
    },
    settingsInfoTitle: {
        fontSize: '32px',
        lineHeight: 1.25,
        color: theme.palette.neutral.neutral4,
    },
    settingsInfo: {
        fontSize: '16px',
        lineHeight: 1.5,
        color: theme.palette.neutral.neutral10,
    },
    widgetGroupTitle: {
        margin: '16px 0 8px',
        fontSize: '24px',
        fontWeight: 300,
        lineHeight: 1.33,
        color: theme.palette.neutral.neutral4,
    },
}));

export default useStyles;
