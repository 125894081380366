import { OAKENApi } from '../OAKENApi';
import { ENDPOINTS } from '../endpoints';
import { HTTP_METHOD } from '../auth/http';
import { getListProvidesTags } from '../generic';
import { CONTACTS, DASHBOARD, EMAIL, REDUX_CACHE } from '../tags';
import { ContactLandPartnerView } from '../../pages/AllProfiles/AllProfilesTable';
import { LPSharingContact } from '../../pages/Settings/SharingSettings/ManageSettingsTable';
import { ContactArchiveResponse } from '../../pages/Settings/ArchiveObjects/Contacts/useTableArchiveContactConfig';

export const contactsAPI = OAKENApi.injectEndpoints({
    endpoints: (builder) => ({
        getAllContacts: builder.query({
            query: (params) => {
                return {
                    url:
                        process.env.REACT_APP_USER_API_HOST +
                        ENDPOINTS.contacts.root,
                    method: HTTP_METHOD.GET,
                    params,
                };
            },
            transformResponse: (
                data: ContactLandPartnerView[],
                meta,
            ): { data: ContactLandPartnerView[]; totalCount: number } => {
                const xTotalCount =
                    meta?.response?.headers.get('X-Total-Count');
                return { data, totalCount: xTotalCount ? +xTotalCount : 0 };
            },
            providesTags: (result) =>
                getListProvidesTags(result?.data, CONTACTS.CONTACTS),
        }),
        getAllContactsForGeneralManager: builder.query({
            query: (params) => {
                return {
                    url:
                        process.env.REACT_APP_USER_API_HOST +
                        ENDPOINTS.shareData.contacts,
                    method: HTTP_METHOD.GET,
                    params,
                };
            },
            transformResponse: (
                data: LPSharingContact[],
                meta,
            ): { data: LPSharingContact[]; totalCount: number } => {
                const xTotalCount =
                    meta?.response?.headers.get('X-Total-Count');
                return { data, totalCount: xTotalCount ? +xTotalCount : 0 };
            },
            providesTags: (result) =>
                getListProvidesTags(result?.data, CONTACTS.CONTACTS),
        }),
        getRelationshipByLandPartnerId: builder.query({
            query: (id) => {
                return {
                    url:
                        process.env.REACT_APP_USER_API_HOST +
                        ENDPOINTS.relationshipTree.root +
                        `/${id}`,
                    method: HTTP_METHOD.GET,
                };
            },
            providesTags: (result) =>
                getListProvidesTags(result?.data, CONTACTS.CONTACTS),
        }),
        getContactsArray: builder.query({
            query: (id) => {
                return {
                    url: `${
                        process.env.REACT_APP_USER_API_HOST +
                        ENDPOINTS.contacts.root
                    }/landpartner/${id}`,
                    method: HTTP_METHOD.GET,
                };
            },
            providesTags: (result) =>
                getListProvidesTags(result?.data, CONTACTS.CONTACTS),
        }),
        getOneContact: builder.query({
            query: (id) => {
                return {
                    url:
                        process.env.REACT_APP_USER_API_HOST +
                        ENDPOINTS.contacts.root +
                        `/${id}`,
                    method: HTTP_METHOD.GET,
                };
            },
            providesTags: (
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                result,
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                error,
                id,
            ) => [{ type: CONTACTS.CONTACTS, id: id }],
        }),
        addRelationContact: builder.mutation({
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            query: ({ contactAvatar, avatar, ...values }) => {
                const formData = new FormData();
                if (contactAvatar && contactAvatar.length > 0) {
                    formData.append('contactAvatar', contactAvatar[0]);
                }
                formData.append(
                    'request',
                    new Blob([JSON.stringify(values)], {
                        type: 'application/json',
                    }),
                );
                return {
                    url:
                        process.env.REACT_APP_USER_API_HOST +
                        ENDPOINTS.contacts.root,
                    method: HTTP_METHOD.POST,
                    body: formData,
                };
            },
            invalidatesTags: [CONTACTS.CONTACTS, DASHBOARD.DASHBOARD],
        }),
        updateRelationContact: builder.mutation({
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            query: ({ contactAvatar, avatar, ...values }) => {
                const formData = new FormData();
                if (contactAvatar && contactAvatar.length > 0) {
                    formData.append('contactAvatar', contactAvatar[0]);
                }
                formData.append(
                    'request',
                    new Blob([JSON.stringify(values)], {
                        type: 'application/json',
                    }),
                );

                return {
                    url:
                        process.env.REACT_APP_USER_API_HOST +
                        ENDPOINTS.contacts.root +
                        `/${values.id}`,
                    method: HTTP_METHOD.PUT,
                    body: formData,
                };
            },
            invalidatesTags: (result, error, arg) => [
                { type: CONTACTS.CONTACTS, id: REDUX_CACHE.LIST },
                { type: CONTACTS.CONTACTS, id: arg },
                { type: CONTACTS.CONTACTS },
                { type: DASHBOARD.DASHBOARD },
            ],
        }),
        findExistingContact: builder.query({
            query: (params) => {
                return {
                    url:
                        process.env.REACT_APP_USER_API_HOST +
                        ENDPOINTS.contacts.root +
                        `/search`,
                    method: HTTP_METHOD.GET,
                    params,
                };
            },
        }),
        assignContact: builder.mutation({
            query: (params) => {
                return {
                    url:
                        process.env.REACT_APP_USER_API_HOST +
                        ENDPOINTS.relationshipTree.assignContact,
                    method: HTTP_METHOD.POST,
                    body: params,
                };
            },
            invalidatesTags: [CONTACTS.CONTACTS, DASHBOARD.DASHBOARD],
        }),
        searchContactRelations: builder.query({
            query: ({ landPartnerId, contactId, ...params }) => {
                return {
                    url:
                        process.env.REACT_APP_USER_API_HOST +
                        ENDPOINTS.relationshipTree.root +
                        `/${landPartnerId}/${contactId}`,
                    method: HTTP_METHOD.GET,
                    params,
                };
            },
        }),
        removeContactFromRelationshipTree: builder.mutation({
            query: (body) => {
                return {
                    url:
                        process.env.REACT_APP_USER_API_HOST +
                        ENDPOINTS.relationshipTree.removeContact,
                    method: HTTP_METHOD.POST,
                    body,
                };
            },
            invalidatesTags: [CONTACTS.CONTACTS],
        }),
        deactivateContact: builder.mutation({
            query: (contactId) => {
                return {
                    url:
                        process.env.REACT_APP_USER_API_HOST +
                        ENDPOINTS.contacts.root +
                        `/archive/${contactId}`,
                    method: HTTP_METHOD.POST,
                };
            },
        }),
        shareLandPartnerData: builder.mutation({
            query: (payload) => {
                return {
                    url:
                        process.env.REACT_APP_USER_API_HOST +
                        ENDPOINTS.shareData.root,
                    method: HTTP_METHOD.POST,
                    body: payload,
                };
            },
            invalidatesTags: [CONTACTS.CONTACTS],
        }),
        revokeShareDataAccess: builder.mutation({
            query: (payload) => {
                return {
                    url:
                        process.env.REACT_APP_USER_API_HOST +
                        ENDPOINTS.shareData.root,
                    method: HTTP_METHOD.DELETE,
                    body: payload,
                };
            },
            invalidatesTags: [CONTACTS.CONTACTS],
        }),
        getArchiveContacts: builder.query({
            query: (params) => {
                return {
                    url:
                        process.env.REACT_APP_USER_API_HOST +
                        ENDPOINTS.contacts.archive,
                    method: HTTP_METHOD.GET,
                    params,
                };
            },
            transformResponse: (
                data: ContactArchiveResponse[],
                meta,
            ): { data: ContactArchiveResponse[]; totalCount: number } => {
                const xTotalCount =
                    meta?.response?.headers.get('X-Total-Count');
                return { data, totalCount: xTotalCount ? +xTotalCount : 0 };
            },
            providesTags: (result) =>
                getListProvidesTags(result?.data, CONTACTS.CONTACTS),
        }),
        restoreContact: builder.mutation({
            query: (id) => {
                return {
                    url:
                        process.env.REACT_APP_USER_API_HOST +
                        ENDPOINTS.contacts.restore +
                        `/${id}`,
                    method: HTTP_METHOD.POST,
                };
            },
            invalidatesTags: [CONTACTS.CONTACTS],
        }),
        deleteContact: builder.mutation({
            query: (id) => {
                return {
                    url:
                        process.env.REACT_APP_USER_API_HOST +
                        ENDPOINTS.contacts.archive +
                        `/${id}`,
                    method: HTTP_METHOD.DELETE,
                };
            },
            invalidatesTags: [CONTACTS.CONTACTS],
        }),
        verifyPrimaryEmail: builder.mutation({
            query: (id) => {
                return {
                    url:
                        process.env.REACT_APP_USER_API_HOST +
                        ENDPOINTS.contacts.verifyPrimaryEmail +
                        `/${id}`,
                    method: HTTP_METHOD.POST,
                };
            },
            invalidatesTags: [EMAIL.EMAIL],
        }),
        verifySecondaryEmail: builder.mutation({
            query: (id) => {
                return {
                    url:
                        process.env.REACT_APP_USER_API_HOST +
                        ENDPOINTS.contacts.verifySecondaryEmail +
                        `/${id}`,
                    method: HTTP_METHOD.POST,
                };
            },
            invalidatesTags: [EMAIL.EMAIL],
        }),
        getPrimaryEmailVerificationStatus: builder.query({
            query: (id) => {
                return {
                    url:
                        process.env.REACT_APP_USER_API_HOST +
                        ENDPOINTS.contacts.verifyPrimaryEmail +
                        `/${id}`,
                    method: HTTP_METHOD.GET,
                };
            },
            providesTags: (result) =>
                getListProvidesTags(result?.data, EMAIL.EMAIL),
        }),
        getSecondaryEmailVerificationStatus: builder.query({
            query: (id) => {
                return {
                    url:
                        process.env.REACT_APP_USER_API_HOST +
                        ENDPOINTS.contacts.verifySecondaryEmail +
                        `/${id}`,
                    method: HTTP_METHOD.GET,
                };
            },
            providesTags: (result) =>
                getListProvidesTags(result?.data, EMAIL.EMAIL),
        }),
        verifyEmailByToken: builder.mutation({
            query: (token) => {
                return {
                    url:
                        process.env.REACT_APP_USER_API_HOST +
                        ENDPOINTS.contacts.verifyEmail,
                    method: HTTP_METHOD.POST,
                    params: { token },
                };
            },
        }),
    }),
});

export const {
    useVerifyEmailByTokenMutation,
    useVerifyPrimaryEmailMutation,
    useVerifySecondaryEmailMutation,
    useLazyGetPrimaryEmailVerificationStatusQuery,
    // eslint-disable-next-line id-length
    useLazyGetSecondaryEmailVerificationStatusQuery,
    useDeleteContactMutation,
    useRestoreContactMutation,
    useGetArchiveContactsQuery,
    useRemoveContactFromRelationshipTreeMutation,
    useSearchContactRelationsQuery,
    useAssignContactMutation,
    useLazyFindExistingContactQuery,
    useGetAllContactsQuery,
    useLazyGetAllContactsQuery,
    useGetAllContactsForGeneralManagerQuery,
    useLazyGetAllContactsForGeneralManagerQuery,
    useGetOneContactQuery,
    useLazyGetOneContactQuery,
    useLazyGetRelationshipByLandPartnerIdQuery,
    useAddRelationContactMutation,
    useUpdateRelationContactMutation,
    useDeactivateContactMutation,
    useShareLandPartnerDataMutation,
    useRevokeShareDataAccessMutation,
} = contactsAPI;
