import { DocumentTable } from './useDocumentsTable';
import AppButton from '../../../design/Buttons/AppButton';
import { useState } from 'react';
import SharingDocumentModal from './SharingDocumentModal';
import LinkSharpIcon from '@mui/icons-material/LinkSharp';
import theme from '../../../styles/theme';

interface OwnProps {
    data: DocumentTable;
    isHidden?: boolean;
}

export default function ShareButton({ data, isHidden }: OwnProps) {
    const [isShowing, setIsShowing] = useState(false);

    function handleClick() {
        setIsShowing(true);
    }
    function handleClose() {
        setIsShowing(false);
    }

    if (isHidden) return null;

    return (
        <>
            <AppButton
                sx={{
                    color: theme.palette.brandLightTurq,
                    fontWeight: 600,
                    fontSize: 12,
                    border: `1px solid ${theme.palette.brandLightTurq}`,
                }}
                endIcon={<LinkSharpIcon />}
                onClick={handleClick}
                title={'Share'}
                variant={'outlined'}
            />
            {isShowing && (
                <SharingDocumentModal
                    id={data?.id}
                    fileName={data?.fileName}
                    isShowing={isShowing}
                    handleClose={handleClose}
                />
            )}
        </>
    );
}
