import React, { ComponentType, useState } from 'react';

import { makeStyles } from 'tss-react/mui';
import {
    TableCell,
    TableRow,
    Checkbox,
    IconButton,
    Collapse,
} from '@mui/material';

import { TableColumn } from './GenericTable';

import { ReactComponent as ExpandIcon } from '../../assets/icons/expand_icon.svg';

interface GenericTableRowProps<T> {
    data: T;
    columns: TableColumn<T>[];
    multiselect?: boolean;
    expandable?: boolean;
    ExpandComponent?: ComponentType<{ data: T }>;
    selected?: boolean;
    onSelect?: (row: T, selected: boolean) => void;
    onClick?: (row: T) => void;
}

export default function GenericTableRow<T>({
    data,
    columns,
    multiselect,
    selected,
    onSelect,
    expandable,
    ExpandComponent,
    onClick,
}: GenericTableRowProps<T>) {
    const { classes } = useStyles();
    const [open, setOpen] = useState(false);

    return (
        <>
            <TableRow
                className={classes.row}
                onClick={onClick && (() => onClick(data))}>
                {multiselect && (
                    <TableCell padding="checkbox">
                        <Checkbox
                            checked={selected}
                            onChange={
                                onSelect && (() => onSelect(data, !selected))
                            }
                        />
                    </TableCell>
                )}
                {/*TODO handling format props*/}
                {columns.map(
                    (column, index) =>
                        !column.isHidden && (
                            <TableCell
                                sx={{
                                    maxWidth: column?.maxColumnWidth,
                                    width: column?.width,
                                }}
                                className={classes.cell}
                                key={index}>
                                {column.cellRender(data)}
                            </TableCell>
                        ),
                )}
                {expandable && (
                    <TableCell>
                        <IconButton onClick={() => setOpen((prev) => !prev)}>
                            <ExpandIcon
                                className={open ? classes.expanded : undefined}
                            />
                        </IconButton>
                    </TableCell>
                )}
            </TableRow>
            {expandable && open && (
                <TableRow>
                    <TableCell
                        colSpan={
                            columns.length +
                            (multiselect || expandable ? 1 : 0) +
                            1
                        }>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            {ExpandComponent && <ExpandComponent data={data} />}
                        </Collapse>
                    </TableCell>
                </TableRow>
            )}
        </>
    );
}

const useStyles = makeStyles()((theme) => ({
    row: {
        border: 'none',
        height: 40,
        color: theme.palette.text.primary,
        '&:hover': {
            backgroundColor: `${theme.palette.backgroundB.backgroundB3} !important`,
        },
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.neutralWhite,
        },
        '&:nth-of-type(even)': {
            backgroundColor: theme.palette.neutral.neutral1,
        },
    },
    cell: {
        fontSize: 14,
        fontWeight: 500,
        padding: theme.spacing(0, 2),
        overflow: 'hidden',
        color: '#2C2E30',
    },
    expanded: {
        transform: 'rotate(180deg)',
    },
}));
