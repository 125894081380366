import jwtDecode from 'jwt-decode';

import { clearLocalStorage } from './localStorageUtils';
import { TOKEN_KEY } from '../constant/localStorageKeys';

export function getTokenData() {
    const token = localStorage.getItem(TOKEN_KEY);

    if (!token) return null;

    try {
        return jwtDecode(token);
    } catch (e) {
        clearLocalStorage();
        return null;
    }
}
