import { ChangeEvent } from 'react';
import { Box, Switch, Typography } from '@mui/material';
import { messagesFieldLabels } from './utils';

interface OwnProps {
    feature: string;
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
    checked: boolean;
}

export default function FeatureSwitch({
    feature,
    onChange,
    checked,
}: OwnProps) {
    return (
        <Box
            key={feature}
            display={'flex'}
            flexDirection={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}>
            <Typography variant={'font14'} color={'text.secondaryContrast'}>
                {messagesFieldLabels[feature]}
            </Typography>
            <Switch
                checked={checked}
                onChange={onChange}
                color="primary"
                name={feature}
                inputProps={{ 'aria-label': 'controlled' }}
            />
        </Box>
    );
}
