import React from 'react';
import { Box, Button } from '@mui/material';
import { ReactComponent as GridIcon } from '../../assets/icons/grid_icon.svg';
import { ReactComponent as ListIcon } from '../../assets/icons/list_icon.svg';

import { makeStyles } from 'tss-react/mui';
import theme from '../../styles/theme';

interface OwnProps {
    type: 'grid' | 'list';
    setType: (type: 'grid' | 'list') => void;
}

export default function GridOrListGroupButton({ type, setType }: OwnProps) {
    const { classes } = useStyles();
    return (
        <Box display={'flex'}>
            <Button
                className={classes.buttonLeft}
                endIcon={
                    <GridIcon
                        style={{
                            fill:
                                type === 'list'
                                    ? `${theme.palette.neutral.neutral8}`
                                    : `${theme.palette.brandLightTurq}`,
                        }}
                    />
                }
                sx={{
                    color:
                        type === 'list'
                            ? `${theme.palette.neutral.neutral8}`
                            : `${theme.palette.brandLightTurq}`,
                    backgroundColor:
                        type === 'list'
                            ? `${theme.palette.neutralWhite}`
                            : `${theme.palette.neutral.neutral2}`,
                    height: 20,
                }}
                variant={type === 'grid' ? 'contained' : 'outlined'}
                onClick={() => setType('grid')}>
                Grid View
            </Button>
            <Button
                endIcon={
                    <ListIcon
                        style={{
                            fill:
                                type === 'grid'
                                    ? `${theme.palette.neutral.neutral8}`
                                    : `${theme.palette.brandLightTurq}`,
                        }}
                    />
                }
                sx={{
                    color:
                        type === 'grid'
                            ? `${theme.palette.neutral.neutral8}`
                            : `${theme.palette.brandLightTurq}`,
                    backgroundColor:
                        type === 'grid'
                            ? `${theme.palette.neutralWhite}`
                            : `${theme.palette.neutral.neutral2}`,
                    height: 20,
                }}
                className={classes.buttonRight}
                variant={type === 'list' ? 'contained' : 'outlined'}
                onClick={() => setType('list')}>
                List View
            </Button>
        </Box>
    );
}
const useStyles = makeStyles()(() => ({
    buttonLeft: {
        transition: 'all 0.2s 0.1s ease',
        borderRadius: '8px 0px 0px 8px',
    },
    buttonRight: {
        transition: 'all 0.2s 0.1s ease',
        borderRadius: '0px 8px 8px 0px',
    },
}));
