import { composeValidators, required } from '../../../utils/validation';

import { FormConfig } from '../../../design/Forms/interfaces';

const numberOfPaymentsPerYear = [
    { id: '1', name: '1' },
    { id: '2', name: '2' },
    { id: '3', name: '3' },
    { id: '4', name: '4' },
    { id: '5', name: '5' },
    { id: '6', name: '6' },
];

export const paymentFrequencyConfig: FormConfig[] = [
    {
        formField: {
            scheme: 'quarter',
            name: 'numberOfPaymentsPerYear',
            type: 'select',
            label: 'Number of Payments',
            isRequired: true,
            validation: composeValidators(required),
        },
        renderProps: {
            placeholder: 'Select from list',
            isDisabled: false,
            size: 'small',
            options: numberOfPaymentsPerYear,
        },
    },
];
