import React, {
    Children,
    cloneElement,
    isValidElement,
    PropsWithChildren,
    ReactNode,
    useEffect,
    useState,
} from 'react';
import { Form, FormRenderProps, FormSpy } from 'react-final-form';
import { Box, Button } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import clsx from 'clsx';
import theme from '../../styles/theme';
import { useBlocker } from 'react-router-dom';
import SubmitButton from '../Buttons/SubmitButton';
import ConfirmModal from '../../layouts/ConfirmModal/ConfirmModal';

interface WizardProps<T> {
    onSubmit: (values: T) => void;

    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    initialValues?: Record<string, any>;
    setStep: (step: number) => void;
    step: number;
    isDraft?: boolean;
    onDraftSubmit?: (values: T) => void;
    children: ((props: FormRenderProps) => ReactNode) | ReactNode;
    isLoading?: boolean;
    onStepValidate?: () => boolean;
    setIsValid?: (isValid: boolean) => void;
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    mutators?: any;
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    decorators?: any;
}

export default function WizardForm<T>({
    onSubmit,
    initialValues,
    children,
    setStep,
    step,
    isLoading,
    onStepValidate,
    setIsValid,
    decorators,
    mutators,
    isDraft,
    onDraftSubmit,
}: PropsWithChildren<WizardProps<T>>) {
    const [isFormChanged, setIsFormChanged] = useState(false);
    const [isConfirmModalShowing, setIsConfirmModalShowing] = useState(false);
    const [values, setValues] = useState<T | NonNullable<unknown>>(
        initialValues || {},
    );
    const { classes } = useStyles();

    const next = (newValues: T) => {
        setStep(step + 1);
        setValues(newValues);
    };

    const previous = () => {
        setStep(step - 1);
    };
    const validChildren = Children.toArray(children).filter(isValidElement);
    const ActivePage = validChildren[step];
    const isLastPage = step === validChildren.length - 1;
    const handleSubmit = (newValues: T) => {
        if (isLastPage) {
            setIsFormChanged(false);
            onSubmit(newValues);
        } else if (typeof onStepValidate === 'function') {
            onStepValidate && onStepValidate() && next(newValues);
        } else {
            next(newValues);
        }
    };

    const blocker = useBlocker(
        ({ currentLocation, nextLocation }) =>
            isFormChanged && currentLocation.pathname !== nextLocation.pathname,
    );
    useEffect(() => {
        if (blocker.state === 'blocked') {
            setIsConfirmModalShowing(true);
        }
    }, [blocker]);
    function handleConfirm() {
        setIsConfirmModalShowing(false);
        blocker.state === 'blocked' && blocker?.proceed();
        blocker.state === 'blocked' && blocker?.reset();
    }

    return (
        <Form
            mutators={mutators}
            initialValues={values}
            onSubmit={handleSubmit}
            decorators={[].concat(decorators || [])}>
            {({ handleSubmit, submitting, values, ...other }) => (
                <form onSubmit={handleSubmit} className={classes.form}>
                    <FormSpy
                        onChange={({ pristine, valid }) => {
                            setIsValid && setIsValid(valid);
                            isFormChanged || setIsFormChanged(!pristine);
                        }}
                    />
                    {isValidElement(ActivePage)
                        ? cloneElement(ActivePage, {
                              ...other,
                          })
                        : null}
                    <div
                        className={clsx(
                            step > 0
                                ? classes.buttonsWrapper
                                : classes.firstStep,
                        )}>
                        {step > 0 && (
                            <Button
                                type="button"
                                variant={'contained'}
                                onClick={previous}>
                                Previous
                            </Button>
                        )}
                        <Box display={'flex'} columnGap={2}>
                            {isDraft && (
                                <Button
                                    type="button"
                                    variant={'contained'}
                                    onClick={() => {
                                        onDraftSubmit && onDraftSubmit(values);
                                    }}>
                                    Save as Draft
                                </Button>
                            )}
                            {!isLastPage && (
                                <Button type="submit" variant={'contained'}>
                                    Next
                                </Button>
                            )}
                            {isLastPage && (
                                <SubmitButton
                                    isLoading={isLoading || submitting}
                                    text={'Submit'}
                                />
                            )}
                        </Box>
                    </div>
                    <ConfirmModal
                        isShowing={isConfirmModalShowing}
                        hide={() => setIsConfirmModalShowing(false)}
                        title="Unsaved Changes"
                        info="Do you want to discard the changes you made?"
                        onSubmit={handleConfirm}
                        submitButtonText={'Discard'}
                    />
                </form>
            )}
        </Form>
    );
}

const useStyles = makeStyles()(() => ({
    form: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
    },
    buttonsWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingTop: theme.spacing(2),
    },
    firstStep: {
        display: 'flex',
        justifyContent: 'end',
        paddingTop: theme.spacing(2),
    },
}));
