import { useSorting } from '../../design/GenericTable/useSorting';
import { usePagination } from '../../design/GenericTable/useBackendPagination';
import { TableColumn } from '../../design/GenericTable/GenericTable';
import { Box } from '@mui/material';
import UserAvatar from '../../design/Avatar/UserAvatar';
import LinkText from '../../design/Fields/LinkText';
import StatusTag, { Tag } from '../../design/StatusTag/StatusTag';
import { successionCompleteMockData } from './utils';
export interface SuccessionCompleted {
    contact: Contact;
    dateCompleted: string;
    farmName: string;
    activeServices: ActiveServices[];
    lastOutreachDate: string;
}
interface Contact {
    firstName: string;
    lastName: string;
    avatar: string;
}
type ActiveServices = 'SUCCESSION' | 'BUSINESS_PLAN' | 'SAFETY_PLAN';
export default function useCompletedTable() {
    const { sortKey, sortOrder, handleSort } = useSorting('grower', 'asc');
    const { handlePageChange, handleItemsPerPageChange } = usePagination(1, 25);
    const columns: TableColumn<SuccessionCompleted>[] = [
        {
            header: {
                label: 'Name',
                sortable: true,
                sorting: {
                    id: 'name',
                    direction: sortKey === 'name' ? sortOrder : 'desc',
                    isSorted: sortKey === 'name',
                },
                onClick: handleSort,
            },
            cellRender: (data) => (
                <Box display={'flex'} alignItems={'center'} columnGap={1}>
                    <UserAvatar
                        name={`${data.contact.firstName} ${data.contact.lastName}`}
                        avatar={data.contact.avatar}
                        size={'small'}
                    />
                    <LinkText
                        to={`/`}
                        text={`${data.contact.firstName} ${data.contact.lastName}`}
                        variant={'font14'}
                    />
                </Box>
            ),
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
        },
        {
            header: {
                label: 'Date Completed',
                sortable: false,
                sorting: {
                    id: 'dateCompleted',
                    direction: sortKey === 'dateCompleted' ? sortOrder : 'desc',
                    isSorted: sortKey === 'dateCompleted',
                },
                onClick: handleSort,
            },
            cellRender: (data) => <>{data?.dateCompleted}</>,
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
        },
        {
            header: {
                label: 'Active Services',
                sortable: false,
                sorting: {
                    id: 'activeServices',
                    direction:
                        sortKey === 'activeServices' ? sortOrder : 'desc',
                    isSorted: sortKey === 'activeServices',
                },
                onClick: handleSort,
            },
            cellRender: (data) => (
                <Box display="flex" columnGap={1}>
                    {data.activeServices.map((service) => (
                        <StatusTag
                            key={service}
                            {...activeServicesMap[service]}
                        />
                    ))}
                </Box>
            ),
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
        },
        {
            header: {
                label: 'Farm Name',
                sortable: true,
                sorting: {
                    id: 'farmName',
                    direction: sortKey === 'farmName' ? sortOrder : 'desc',
                    isSorted: sortKey === 'farmName',
                },
                onClick: handleSort,
            },
            cellRender: (data) => <>{data?.farmName}</>,
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
        },
        {
            header: {
                label: 'Last Outreach',
                sortable: false,
                sorting: {
                    id: 'last',
                    direction:
                        sortKey === 'lastOutreachDate' ? sortOrder : 'desc',
                    isSorted: sortKey === 'lastOutreachDate',
                },
                onClick: handleSort,
            },
            cellRender: (data) => <>{data?.lastOutreachDate}</>,
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
        },
    ];
    const tableConfig = {
        columns,
        pageable: true,
        handlePageChange,
        handleItemsPerPageChange,
        initialPageSize: 25,
        initialPage: 1,
        expandable: false,
    };
    const totalCount = successionCompleteMockData?.length || 0;
    return { data: successionCompleteMockData || [], totalCount, tableConfig };
}
const activeServicesMap: Record<ActiveServices, Tag> = {
    SUCCESSION: {
        status: 'success',
        text: 'Succession',
    },
    BUSINESS_PLAN: {
        status: 'warning',
        text: 'Business Plan',
    },
    SAFETY_PLAN: {
        status: 'primaryInfo',
        text: 'Safety PLan',
    },
};
