import React from 'react';
import TreeCardContact, { TreeCardElement } from './TreeCardContact';
import ReusableTree from '../../reusable/Tree/ReusableTree';
import { TreeNodeDatum } from 'react-d3-tree';

export type CombinedContactNodeDatum = TreeNodeDatum & TreeCardElement;
interface ContactOrganizationTreeProps {
    cardData: CombinedContactNodeDatum;
}

export default function ContactOrganizationTree({
    cardData,
}: ContactOrganizationTreeProps) {
    return (
        <ReusableTree
            data={cardData}
            renderNode={(rd3tProps) => (
                <TreeCardContact
                    {...rd3tProps}
                    nodeDatum={rd3tProps.nodeDatum as CombinedContactNodeDatum}
                />
            )}
        />
    );
}
