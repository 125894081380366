import React, { useEffect } from 'react';
import { makeStyles } from 'tss-react/mui';
import RelationshipsOrganizationTree from './RelationshipsOrganizationTree';
import { Grid } from '@mui/material';
import Loader from '../../../design/BaseLoader';
import { useLazyGetRelationshipByLandPartnerIdQuery } from '../../../api/contacts/contactsAPI';
import { useLocation, useParams } from 'react-router-dom';

interface LandownersRelationshipProps {
    isContact?: boolean;
    landPartnerIdFromPath?: string;
}

export default function LandPartnerRelationship({
    isContact = false,
    landPartnerIdFromPath,
}: LandownersRelationshipProps) {
    const { id } = useParams<{ id: string }>();

    const contactId =
        isContact && id !== landPartnerIdFromPath ? id : undefined;
    const { classes } = useStyles();
    const { state: landPartnerId } = useLocation();

    const [
        getRelationships,
        {
            isFetching: fetchingRelationshipTree,
            isLoading: loadingRelationshipTree,
            data: relationshipData,
        },
    ] = useLazyGetRelationshipByLandPartnerIdQuery();

    useEffect(() => {
        landPartnerIdFromPath && getRelationships(landPartnerIdFromPath);
    }, [landPartnerIdFromPath]);

    if (!relationshipData) return null;

    if (fetchingRelationshipTree || loadingRelationshipTree) {
        return (
            <Grid
                container
                sx={{ height: '100vh' }}
                alignItems={'center'}
                justifyContent={'center'}
                flexDirection={'column'}>
                <Loader />
            </Grid>
        );
    }

    return (
        <div className={classes.container}>
            <RelationshipsOrganizationTree
                cardData={{
                    ...relationshipData,
                    isRoot: true,
                }}
                landPartnerId={landPartnerIdFromPath}
                contactIdForMarking={contactId || landPartnerId}
            />
        </div>
    );
}
const useStyles = makeStyles()((theme) => ({
    container: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        minHeight: '70vh',
        backgroundColor: theme.palette.neutralWhite,
        padding: theme.spacing(2, 4, 2, 5),
    },
}));
