import { useEffect } from 'react';
import { useLazyProfilePhotoQuery } from '../api/users/usersAPI';

interface OwnProps {
    id: string;
}
export default function useProfilePhoto({ id }: OwnProps) {
    const [getPhoto, { data, isLoading }] = useLazyProfilePhotoQuery();
    useEffect(() => {
        id && getPhoto(id);
    }, [id, getPhoto]);
    return { data, isLoading };
}
