import React from 'react';
import { usePagination } from '../../design/GenericTable/useBackendPagination';
import { TableColumn } from '../../design/GenericTable/GenericTable';
import AppTypography from '../../design/AppTypography';
import { useSelection } from '../../design/GenericTable/useSelection';

export interface SyncData {
    id: string;
    paymentName: string;
    paymentDate: string;
    payeeName: string;
}

export function useImportPaymentsTable() {
    const {
        // currentPage,
        // itemsPerPage,
        handlePageChange,
        handleItemsPerPageChange,
    } = usePagination(1, 25);
    const { isSelected, toggleSelection, toggleSelectionAll, isSelectedAll } =
        useSelection<SyncData>();
    const columns: TableColumn<SyncData>[] = [
        {
            header: {
                label: 'Select All',
                sortable: true,
            },
            cellRender: (data) => (
                <AppTypography
                    variant={'font14'}
                    fontWeight={'regular'}
                    text={data.paymentName}
                />
            ),
            format: { align: 'left', color: { color: 'primary' } },
        },
        {
            header: {
                label: 'Date',
                sortable: true,
            },
            cellRender: (data) => (
                <AppTypography
                    variant={'font14'}
                    fontWeight={'regular'}
                    text={data.paymentDate}
                />
            ),
            format: { align: 'left', color: { color: 'primary' } },
        },
        {
            header: {
                label: 'Amount',
                sortable: true,
            },
            cellRender: (data) => (
                <AppTypography
                    variant={'font14'}
                    fontWeight={'regular'}
                    text={data.payeeName}
                />
            ),
            format: { align: 'left', color: { color: 'primary' } },
        },
    ];
    const tableConfig = {
        columns,
        multiselect: true,
        pageable: true,
        handlePageChange,
        handleItemsPerPageChange,
        initialPageSize: 25,
        initialPage: 1,
        expandable: false,
        isSelected: isSelected,
        toggleSelection: toggleSelection,
        toggleSelectionAll: toggleSelectionAll,
        isSelectedAll: isSelectedAll,
    };
    const totalCount = mockSyncData?.length || 0;
    return { data: mockSyncData || [], totalCount, tableConfig };
}

export const mockSyncData: SyncData[] = [
    {
        id: '7f24c1cb-4e2a-4563-a480-757ea39fff40',
        paymentName: 'William D Huang',
        paymentDate: '2024-08-06',
        payeeName: '$954.80',
    },
    {
        id: '48fc3dcb-1a8e-40b4-91e1-14d764b90178',
        paymentName: 'William D Huang',
        paymentDate: '2024-07-23',
        payeeName: '$20,535.68',
    },
    {
        id: '6d81366f-dc87-4fbc-a183-99abfb2a5a56',
        paymentName: 'William D Huang',
        paymentDate: '2024-02-12',
        payeeName: '$401.50',
    },
    {
        id: '15388d22-8ac8-4b8e-b73c-a0d0f009bace',
        paymentName: 'William D Huang',
        paymentDate: '2024-07-05',
        payeeName: '$9,982.50',
    },
    {
        id: '406cce1b-856d-45bd-9e70-fa09c9c04f9a',
        paymentName: 'William D Huang',
        paymentDate: '2024-07-24',
        payeeName: '$5,000.00',
    },
    {
        id: 'a125c2de-556e-4d9a-86e1-f85f3072c5e1',
        paymentName: 'Incubate Granular Vortals',
        paymentDate: '2024-03-02',
        payeeName: '$3,300.00',
    },
    {
        id: 'b5a499f6-6f99-476a-aa7b-64b73331e46b',
        paymentName: 'Whiteboard User-Centric Content',
        paymentDate: '2024-06-07',
        payeeName: '$50,000.00',
    },
    {
        id: 'c6f0d8cb-c474-4373-9387-d418b182be19',
        paymentName: 'Architect Customized Metrics',
        paymentDate: '2024-07-21',
        payeeName: '$3,300.00',
    },
    {
        id: '0918c1f5-71bf-4bd0-9f07-9118c1161cba',
        paymentName: 'Productize Web-Enabled Partnerships',
        paymentDate: '2024-01-25',
        payeeName: '$50,000.00',
    },
    {
        id: '4c383521-5fc0-4c5e-99cd-4f074a722171',
        paymentName: 'Brand Front-End Users',
        paymentDate: '2024-07-18',
        payeeName: '$3,300.00',
    },
    {
        id: '04c8dd98-b374-4cf4-88bb-495792fe20fd',
        paymentName: 'Whiteboard 24/365 Solutions',
        paymentDate: '2024-01-02',
        payeeName: '$5,000.00',
    },
    {
        id: '08a13d73-17fc-40d4-b8c5-caea7daa749c',
        paymentName: 'Drive Cutting-Edge Eyeballs',
        paymentDate: '2024-07-16',
        payeeName: '$50,000.00',
    },
    {
        id: '8566d157-98df-4956-9cf3-5b4399b8f0c6',
        paymentName: 'Synthesize Impactful Solutions',
        paymentDate: '2024-04-23',
        payeeName: '9,900.00',
    },
    {
        id: 'fc37c08e-ed58-4731-90a3-c089d7542bad',
        paymentName: 'Engineer Global Paradigms',
        paymentDate: '2024-03-11',
        payeeName: '$5,000.00',
    },
];
