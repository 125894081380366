import { useSorting } from '../../design/GenericTable/useSorting';
import { usePagination } from '../../design/GenericTable/useBackendPagination';
import { TableColumn } from '../../design/GenericTable/GenericTable';
import { Box } from '@mui/material';
import UserAvatar from '../../design/Avatar/UserAvatar';
import LinkText from '../../design/Fields/LinkText';
import { successionInProgressData } from './utils';
import InProgressStepper from './InProgressStepper';
export interface SuccessionInProgress {
    farm: Farm;
    startDate: string;
    lastUpdated: string;
    primaryContact: Contact;
    stepNumber: number;
    stepName: string;
}
interface Farm {
    name: string;
    avatar: string;
}
interface Contact {
    firstName: string;
    lastName: string;
}
export default function useInProgressTable() {
    const { sortKey, sortOrder, handleSort } = useSorting('grower', 'asc');
    const { handlePageChange, handleItemsPerPageChange } = usePagination(1, 25);
    const columns: TableColumn<SuccessionInProgress>[] = [
        {
            header: {
                label: 'Farm Name',
                sortable: true,
                sorting: {
                    id: 'farm',
                    direction: sortKey === 'farm' ? sortOrder : 'desc',
                    isSorted: sortKey === 'farm',
                },
                onClick: handleSort,
            },
            cellRender: (data) => (
                <Box display={'flex'} alignItems={'center'} columnGap={1}>
                    <UserAvatar
                        name={data.farm.name}
                        avatar={data.farm.avatar}
                        size={'small'}
                    />
                    <LinkText
                        to={`/`}
                        text={data.farm.name}
                        variant={'font14'}
                    />
                </Box>
            ),
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
        },
        {
            header: {
                label: 'Start Date',
                sortable: true,
                sorting: {
                    id: 'startDate',
                    direction: sortKey === 'startDate' ? sortOrder : 'desc',
                    isSorted: sortKey === 'startDate',
                },
                onClick: handleSort,
            },
            cellRender: (data) => <>{data?.startDate}</>,
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
        },
        {
            header: {
                label: 'Primary Contact',
                sortable: false,
                sorting: {
                    id: 'primaryContact',
                    direction:
                        sortKey === 'primaryContact' ? sortOrder : 'desc',
                    isSorted: sortKey === 'primaryContact',
                },
                onClick: handleSort,
            },
            cellRender: (data) => (
                <>
                    {data.primaryContact.firstName}{' '}
                    {data.primaryContact.lastName}
                </>
            ),
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
        },
        {
            header: {
                label: 'Last Update',
                sortable: true,
                sorting: {
                    id: 'lastUpdated',
                    direction: sortKey === 'lastUpdated' ? sortOrder : 'desc',
                    isSorted: sortKey === 'lastUpdated',
                },
                onClick: handleSort,
            },
            cellRender: (data) => <>{data?.lastUpdated}</>,
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
        },
        {
            header: {
                label: 'Submission Progress',
                sortable: false,
            },
            cellRender: (data) => (
                <InProgressStepper
                    stepNumber={data.stepNumber}
                    stepName={data.stepName}
                />
            ),
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
        },
    ];
    const tableConfig = {
        columns,
        pageable: true,
        handlePageChange,
        handleItemsPerPageChange,
        initialPageSize: 25,
        initialPage: 1,
        expandable: false,
    };
    const totalCount = successionInProgressData?.length || 0;
    return { data: successionInProgressData || [], totalCount, tableConfig };
}
