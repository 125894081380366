import { Dispatch, SetStateAction, useState } from 'react';

export interface IUseModal {
    isShowing: boolean;
    toggle: () => void;
    setIsShowing: Dispatch<SetStateAction<boolean>>;
    additionalData: IUseModalAdditionalData | undefined;
    setAdditionalData: Dispatch<
        SetStateAction<IUseModalAdditionalData | undefined>
    >;
}

export interface IUseModalAdditionalData {
    component: JSX.Element;
    header: string;
}

const useModal = (): IUseModal => {
    const [isShowing, setIsShowing] = useState(false);
    const [additionalData, setAdditionalData] =
        useState<IUseModalAdditionalData>();

    function toggle() {
        setIsShowing(!isShowing);
    }

    return {
        isShowing: isShowing,
        toggle: toggle,
        setIsShowing: setIsShowing,
        additionalData: additionalData,
        setAdditionalData: setAdditionalData,
    };
};

export default useModal;
