import { format, utcToZonedTime } from 'date-fns-tz';
import { parseISO } from 'date-fns';

const DATE_TIME_FORMAT = 'MM/dd/yyyy hh:mm a';
export const DATE_FORMAT = 'MM/dd/yyyy';

function getTimezoneValue() {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

const CURRENT_TIME_ZONE = getTimezoneValue();

export const dateTimeConverterFromTimestamp = (date: number | string) => {
    const localDate = utcToZonedTime(new Date(+date * 1000), CURRENT_TIME_ZONE);
    return format(localDate, DATE_TIME_FORMAT, {
        timeZone: CURRENT_TIME_ZONE,
    });
};

export const dateConverterFromUTC = (date: string | number) => {
    const localDate = utcToZonedTime(new Date(date), CURRENT_TIME_ZONE);
    return format(localDate, DATE_FORMAT, {
        timeZone: CURRENT_TIME_ZONE,
    });
};

export const dateTimeConverterFromISO = (date: string) => {
    const utcDate = parseISO(date);
    const localDate = utcToZonedTime(new Date(utcDate), CURRENT_TIME_ZONE);
    return format(localDate, DATE_TIME_FORMAT, {
        timeZone: CURRENT_TIME_ZONE,
    });
};
export const convertDateFromStr = (date: string | null) => {
    if (!date) return '';
    const localDate = utcToZonedTime(new Date(date), CURRENT_TIME_ZONE);
    return format(localDate, DATE_FORMAT, {
        timeZone: CURRENT_TIME_ZONE,
    });
};
export const dateTimeConverterFromUTC = (date: string | number) => {
    const localDate = utcToZonedTime(new Date(date), CURRENT_TIME_ZONE);
    return format(localDate, DATE_TIME_FORMAT, {
        timeZone: CURRENT_TIME_ZONE,
    });
};

export const convertDateToString = (date: Date) => {
    return format(date, DATE_FORMAT);
};

export const isToday = (date: string) => {
    const today = new Date();
    const eventDate = new Date(date);
    return today.toDateString() === eventDate.toDateString();
};
