import React from 'react';
import { Box, Grid } from '@mui/material';
import { useGetSharedGrowerPreviewQuery } from '../../../../api/sharedData/sharedDataTempAccessAPI';
import Loader from '../../../../design/BaseLoader';
import GrowerInfoBox from '../../TemporaryAccess/Grower/GrowerInfoBox';
import UpcomingEventsPreview from '../../TemporaryAccess/Events/UpcomingEventsPreview';
import GrowerInviteForm from '../../TemporaryAccess/Grower/GrowerInviteForm';

interface OwnProps {
    hideSwitchGrower?: boolean;
}

export default function AsideGrowerPreviewTab({ hideSwitchGrower }: OwnProps) {
    const { data: growerData, isLoading: isGrowerDataLoading } =
        useGetSharedGrowerPreviewQuery({});

    if (isGrowerDataLoading) {
        return (
            <Grid
                container
                alignItems={'center'}
                justifyContent={'center'}
                flexDirection={'column'}>
                <Loader />
            </Grid>
        );
    }

    return (
        <Box sx={{ paddingTop: '20px', padding: '5px' }}>
            <GrowerInfoBox
                data={growerData}
                hideSwitchGrower={hideSwitchGrower}
            />
            <UpcomingEventsPreview />
            <GrowerInviteForm />
        </Box>
    );
}
