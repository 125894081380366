import { Typography, Button } from '@mui/material';
import * as yup from 'yup';
import React from 'react';
import { useFormik } from 'formik';
import clsx from 'clsx';
import { NavLink } from 'react-router-dom';

import useStyles from './LogIn.styles';
import theme from '../../../styles/theme';
import FormTextField, {
    EndIconType,
} from '../../../design/FormUI/FormTextField';

import PATHS from '../../../router/paths';
import { emailRegex } from '../../../constant/regex';

const INITIAL_FORM_STATE = {
    name: '',
    email: '',
    password: '',
};
interface SubmitValue {
    name: string;
    email: string;
    password: string;
}
export const FORM_VALIDATION = yup.object({
    name: yup.string().required('* This field is required'),
    email: yup
        .string()
        .matches(emailRegex, '* Please provide a correct email address')
        .required('* This field is required'),
    password: yup.string().required('* This field is required'),
});

export default function RequestInviteForm() {
    const { classes } = useStyles(theme);

    const formik = useFormik({
        enableReinitialize: true,
        validateOnChange: true,
        initialValues: { ...INITIAL_FORM_STATE },
        validationSchema: FORM_VALIDATION,
        onSubmit,
    });

    //TODO not implemented yet

    function onSubmit(values: SubmitValue) {
        //eslint-disable-next-line
        console.log(values);
    }

    return (
        <div className={classes.formWrapper}>
            <Typography
                className={classes.title}
                variant="font32"
                fontWeight="regular">
                Request an invite
            </Typography>
            <Typography className={classes.details}>
                Please provide some information to get started
            </Typography>
            <form onSubmit={formik.handleSubmit} noValidate>
                <FormTextField
                    name={'name'}
                    label={'Name'}
                    required
                    className={classes.textField}
                    formik={formik}
                />
                <FormTextField
                    name={'email'}
                    label={'Email Address'}
                    required
                    className={classes.textField}
                    formik={formik}
                />
                <FormTextField
                    name={'password'}
                    label={'Password'}
                    required
                    formik={formik}
                    endIconType={EndIconType.password}
                />

                <div className={classes.bottomContainer}>
                    <Button className={classes.button} type="submit">
                        Register
                    </Button>
                    <NavLink
                        to={`/${PATHS.auth}/${PATHS.logIn}`}
                        className={clsx(classes.link, classes.fontSize14)}>
                        Login
                    </NavLink>
                </div>
                <div className={classes.policyPrivacy}>
                    By clicking “Register” you accept {` `}
                    <NavLink
                        to={'https://www.oaken.ag/end-user-terms-1'}
                        target={'_blank'}
                        className={classes.link}>
                        Terms of Use and Policy Privacy
                    </NavLink>
                </div>
            </form>
        </div>
    );
}
