import React from 'react';
import useHandleSubmitRTK from '../../../../hooks/useHandleSubmit';
import { useInviteGrowerMutation } from '../../../../api/sharedData/sharedDataPermanentAccessAPI';
import GrowerInviteStaticForm, {
    InviteGrowerRequest,
} from '../../Common/GrowerInviteStaticForm';

export default function GrowerInviteForm() {
    const { handleSubmit: inviteGrower, isLoading } = useHandleSubmitRTK({
        useRtkHook: useInviteGrowerMutation,
        successMessage: 'Invite sent successfully',
    });

    const handleSendInvite = (values: InviteGrowerRequest) => {
        inviteGrower({ body: { ...values } });
    };
    return (
        <GrowerInviteStaticForm
            handleSendInvite={handleSendInvite}
            isLoading={isLoading}
        />
    );
}
