import React, { useState } from 'react';

import { Grid } from '@mui/material';

import BasePieChart, {
    PieChartConfig,
} from '../../design/Charts/PieChart/BasePieChart';
import ActionButton, {
    ActionMenuItem,
} from '../../design/Buttons/ActionButton';

import { useGetLeaseDashboardQuery } from '../../api/leases/leaseAPI';
import { Lease } from './TableConfig';
import SelectLeaseTypeModal from './SelectLeaseTypeModal';
import Loader from '../../design/BaseLoader';

import { useSelection } from '../../design/GenericTable/useSelection';
import { useLazyGetBulkDocumentsQuery } from '../../api/fileApiSlice';
import {
    createDownloadLinkFromBlob,
    getFilenameFromContentDisposition,
} from '../../utils/fileHandler';

import CustomizeViewModal from '../CustomizeViewModal';
import LeaseTable from './LeaseTable';
import {
    useGetOneConfigQuery,
    useLazyGetOneConfigQuery,
} from '../../api/users/customizeConfigAPI';
import { initCustomViewForLease } from '../customizeViewInitialStates';
import ToggleLeaseTableButtons from './ToggleLeaseTableButtons';
import DraftLeaseTable, { DraftLeases } from './DraftLeaseTable';
import ExpiredLeaseTable from './ExpiredLeaseTable';
import useScrollToElement from '../../hooks/useScrollTo';
import useManagePermission from '../../hooks/useManagePermission';
import useCustomizeView from '../../hooks/useCustomizeView';

export interface LeaseDashboard {
    activeLeases: number;
    inactiveLeases: number;
    ninetyPlusDaysUntilRenewal: number;
    sixtyDaysUntilRenewal: number;
    thirtyDaysUntilRenewal: number;
    tillableAcres: number;
    totalAcres: number;
    totalLeases: number;
    totalInactiveAcres: number;
    totalActiveTillableAcres: number;
    totalInactiveTillableAcres: number;
    totalActiveAcres: number;
    landPartnersCount: number;
    growersCount: number;
    totalCount: number;
}

export default function ManageLeasesPage() {
    const [isShowing, setIsShowing] = useState(false);
    const [isShowingCustomizeView, setIsShowingCustomizeView] = useState(false);
    const [getDocuments] = useLazyGetBulkDocumentsQuery();
    const [tableView, setTableView] = useState('active_leases');
    const { scrollToRef, scrollTo } = useScrollToElement();

    const { data: customizeConfig, isLoading: isLoadingCustomizeConfig } =
        useGetOneConfigQuery({ viewType: 'LEASES' });
    const [
        getData,
        { data: customizeConfigLazy, isLoading: isLoadingLazyCustomizeConfig },
    ] = useLazyGetOneConfigQuery();
    const { configData, isLoading, isFetched } = useCustomizeView({
        fetchedData: customizeConfigLazy || customizeConfig,
        isLoading: isLoadingLazyCustomizeConfig || isLoadingCustomizeConfig,
        initialTableSettings: initCustomViewForLease,
        viewType: 'LEASES',
    });

    function handleScroll(name?: string) {
        if (name === 'Active') {
            setTableView('active_leases');
        }
        if (name === 'Expired') {
            setTableView('expired_leases');
        }
        scrollTo();
    }

    const {
        selected: selectedRows,
        isSelected,
        toggleSelection,
        toggleSelectionAll,
        isSelectedAll,
    } = useSelection<Lease>();
    const {
        isSelected: isSelectedDraft,
        toggleSelection: toggleSelectionDraft,
        toggleSelectionAll: toggleSelectionAllDraft,
        isSelectedAll: isSelectedAllDraft,
    } = useSelection<DraftLeases>();
    const { checkPermission } = useManagePermission();
    const handleDownload = async () => {
        getDocuments(documentsIds())
            .unwrap()
            .then(({ data, headers }) => {
                createDownloadLinkFromBlob(
                    data,
                    getFilenameFromContentDisposition(headers),
                );
            });
    };
    const documentsIds = () => {
        const ids = selectedRows.map((i) => `leaseIds=${i.id}`);
        return ids.join('&');
    };
    const actionButtonItems: ActionMenuItem[] = [
        {
            label: `Download Leases (${selectedRows.length})`,
            onClick: () => handleDownload(),
            hidden: !selectedRows.length,
        },
        {
            label: 'Add a Lease',
            onClick: () => {
                setIsShowing(true);
            },
            hidden: !checkPermission('leases', 'modify'),
        },
        {
            label: 'Customize View',
            onClick: () => {
                setIsShowingCustomizeView(true);
            },
        },
        {
            label: 'Create a Task',
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            onClick: () => {},
        },
        {
            label: 'Set a Reminder',
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            onClick: () => {},
        },
    ];

    const { data: initDashboardData, isLoading: isDashboardsLoading } =
        useGetLeaseDashboardQuery(null);

    if (
        isDashboardsLoading ||
        isLoadingCustomizeConfig ||
        isLoadingLazyCustomizeConfig ||
        isLoading
    ) {
        return (
            <Grid
                container
                sx={{ height: '100vh' }}
                alignItems={'center'}
                justifyContent={'center'}
                flexDirection={'column'}>
                <Loader />
            </Grid>
        );
    }

    return (
        <Grid container flexDirection={'column'}>
            <Grid container item justifyContent={'space-between'}>
                <BasePieChart
                    data={createTotalLeases(initDashboardData)}
                    config={pieChartConfigTotalLeases(
                        initDashboardData,
                        handleScroll,
                    )}
                />
                <BasePieChart
                    data={createTotalAcres(initDashboardData)}
                    config={pieChartConfigTotalAcres(initDashboardData)}
                />
                <BasePieChart
                    data={createTillableAcres(initDashboardData)}
                    config={pieChartConfigTillableAcres(initDashboardData)}
                />
                <BasePieChart
                    data={createLeaseRenewal(initDashboardData)}
                    config={pieChartConfigLeaseRenewal}
                />
            </Grid>
            <Grid
                container
                item
                alignItems={'center'}
                justifyContent={'space-between'}
                width={'100%'}
                p={2}>
                <ToggleLeaseTableButtons
                    view={tableView}
                    handleView={setTableView}
                />
                <ActionButton label={'Actions'} items={actionButtonItems} />
            </Grid>
            <div ref={scrollToRef}>
                {tableView === 'active_leases' && (
                    <LeaseTable
                        config={configData}
                        isSelected={isSelected}
                        toggleSelection={toggleSelection}
                        toggleSelectionAll={toggleSelectionAll}
                        isSelectedAll={isSelectedAll}
                    />
                )}

                {tableView === 'expired_leases' && (
                    <ExpiredLeaseTable
                        config={configData}
                        isSelected={isSelected}
                        toggleSelection={toggleSelection}
                        toggleSelectionAll={toggleSelectionAll}
                        isSelectedAll={isSelectedAll}
                    />
                )}
                {tableView === 'draft' && (
                    <DraftLeaseTable
                        isSelected={isSelectedDraft}
                        toggleSelection={toggleSelectionDraft}
                        toggleSelectionAll={toggleSelectionAllDraft}
                        isSelectedAll={isSelectedAllDraft}
                    />
                )}
            </div>
            <SelectLeaseTypeModal
                isShowing={isShowing}
                onClose={() => setIsShowing(false)}
            />
            <CustomizeViewModal
                config={configData}
                viewType={'LEASES'}
                isShowing={isShowingCustomizeView}
                onClose={() => {
                    setIsShowingCustomizeView(false);
                    getData({ viewType: 'LEASES' });
                }}
                isFetched={isFetched}
            />
        </Grid>
    );
}

const currentYear = new Date().getFullYear();

function createLeaseRenewal(dashboardData: LeaseDashboard) {
    return [
        { name: '90+', value: dashboardData?.ninetyPlusDaysUntilRenewal || 0 },
        { name: '60', value: dashboardData?.sixtyDaysUntilRenewal || 0 },
        { name: '30', value: dashboardData?.thirtyDaysUntilRenewal || 0 },
    ];
}

function createTillableAcres(dashboardData: LeaseDashboard) {
    return [
        {
            name: 'Active',
            value: dashboardData?.totalActiveTillableAcres || 0,
        },
        {
            name: 'Expired',
            value: dashboardData?.totalInactiveTillableAcres || 0,
        },
    ];
}

function createTotalLeases(dashboardData: LeaseDashboard) {
    return [
        { name: 'Active', value: dashboardData?.activeLeases || 0 },
        { name: 'Expired', value: dashboardData?.inactiveLeases || 0 },
    ];
}

function createTotalAcres(dashboardData: LeaseDashboard) {
    return [
        { name: 'Active', value: dashboardData?.totalActiveAcres || 0 },
        { name: 'Expired', value: dashboardData?.totalInactiveAcres || 0 },
    ];
}

function pieChartConfigTotalLeases(
    dashboardData: LeaseDashboard,
    handleClick: (name?: string) => void,
): PieChartConfig {
    return {
        summaryTitle: 'Total Leases',
        summaryValue: dashboardData?.totalLeases || 0,
        showLegend: true,
        dataKey: 'value',
        chartTitle: 'Total Leases',
        showTooltip: true,
        handleClick: handleClick,
    };
}

function pieChartConfigTotalAcres(
    dashboardData: LeaseDashboard,
): PieChartConfig {
    return {
        summaryTitle: `${currentYear}`,
        summaryValue: dashboardData?.totalAcres || 0,
        showLegend: true,
        dataKey: 'value',
        chartTitle: 'Total Acres',
        showTooltip: true,
    };
}

function pieChartConfigTillableAcres(
    dashboardData: LeaseDashboard,
): PieChartConfig {
    return {
        summaryTitle: `${currentYear}`,
        summaryValue: dashboardData?.tillableAcres,
        showLegend: true,
        dataKey: 'value',
        chartTitle: 'Tillable Acres',
        showTooltip: true,
    };
    // eslint-disable-next-line max-lines
}

const pieChartConfigLeaseRenewal: PieChartConfig = {
    summaryTitle: 'Days Remaining',
    summaryValue: 'Count',
    showLegend: true,
    dataKey: 'value',
    chartTitle: 'Lease Renewals',
    showTooltip: true,
};
