import React from 'react';
import { SecondaryFarmInfo } from './FarmOtherInfo';
import { Box, Stack } from '@mui/material';
import AppTypography from '../../../design/AppTypography';
import { ReactComponent as BuildingIcon } from '../../../assets/icons/building_icon.svg';
import FarmContactInfo from './FarmContactInfo';
import InfoWithLabel from '../../../reusable/InfoWithLabel';

interface OwnProps {
    secondaryFarmInfo: SecondaryFarmInfo;
    billingAddress: string;
}

export default function RightColumnFarmInfo({
    secondaryFarmInfo,
    billingAddress,
}: OwnProps) {
    return (
        <Stack rowGap={3}>
            <FarmContactInfo
                title={'Secondary'}
                email={secondaryFarmInfo.secondaryEmail}
                {...secondaryFarmInfo}
            />
            <Box>
                <AppTypography
                    text={'Billing'}
                    variant={'font11'}
                    fontWeight={'bold'}
                    color={'text.neutral9'}
                />
                <InfoWithLabel icon={BuildingIcon} text={billingAddress} />
            </Box>
        </Stack>
    );
}
