import { Form } from 'react-final-form';
import { Box } from '@mui/material';
import theme from '../../../styles/theme';

import AppButton from '../../../design/Buttons/AppButton';
import HazardousFormFields from './HazardousFormFields';

export interface Guideline {
    eventName: string;
    safetyVideo1?: string;
    safetyVideo2?: string;
    safetyVideo3?: string;
    description?: string;
}
interface OwnProps {
    guidelines: Guideline[];
    setGuidelines: (guidelines: Guideline[]) => void;
}
export default function HazardousForm({ guidelines, setGuidelines }: OwnProps) {
    function addGuideline(values: Guideline) {
        setGuidelines([...guidelines, values]);
    }

    return (
        <Box
            p={theme.spacing(2)}
            bgcolor={theme.palette.lightTurq.lightTurqTint2}
            borderRadius={theme.spacing(1)}>
            <Form
                onSubmit={addGuideline}
                render={({ handleSubmit, form }) => (
                    <form
                        onSubmit={(e) => {
                            e.preventDefault();
                            handleSubmit();
                            form.reset();
                        }}>
                        <Box
                            display={'flex'}
                            flexDirection={'column'}
                            rowGap={2}>
                            <HazardousFormFields />
                            <AppButton
                                sx={{
                                    alignSelf: 'flex-end',
                                }}
                                variant={'outlined'}
                                onClick={handleSubmit}
                                title={'Add Guideline'}
                            />
                        </Box>
                    </form>
                )}
            />
        </Box>
    );
}
