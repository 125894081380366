import { FormConfig } from '../../../design/Forms/interfaces';
import {
    composeValidators,
    maxLength,
    required,
} from '../../../utils/validation';
import { usaStates } from '../../../utils/vocabulary';
const addressesTypeList = [
    { id: 'PRIMARY_ADDRESS', name: 'Primary Address' },
    { id: 'MAIL_ADDRESS', name: 'Mail Address' },
    { id: 'PAYMENT_ADDRESS', name: 'Payment Address' },
];

const filterAddressesType = (addressesType: string[], index: number) => {
    return addressesTypeList.filter(
        (address) => !addressesType.slice(0, index).includes(address.id),
    );
};

export const addressesConfig = (
    name: string,
    addressesType: string[],
    index: number,
): FormConfig[] => [
    {
        formField: {
            scheme: 'half',
            name: `${name}.type`,
            type: 'radio',
            label: 'Address Type',
            isRequired: true,
            validation: composeValidators(required),
            tooltip: {
                text: 'Only one address type can be selected',
                position: 'top',
            },
        },
        renderProps: {
            options: filterAddressesType(addressesType, index),
            isDisabled: false,
            size: 'half',
        },
    },

    {
        formField: {
            scheme: 'half',
            name: `${name}.address1`,
            type: 'input',
            label: 'Address Line 1',
            validation: composeValidators(maxLength(256)),
        },
        renderProps: {
            placeholder: 'Street',
            isDisabled: false,
            size: 'small',
        },
    },
    {
        formField: {
            scheme: 'half',
            name: `${name}.address2`,
            type: 'input',
            label: 'Address Line 2',
            validation: composeValidators(maxLength(256)),
        },
        renderProps: {
            placeholder: 'Street',
            isDisabled: false,
            size: 'small',
        },
    },
    {
        formField: {
            scheme: 'half',
            name: `${name}.city`,
            type: 'input',
            label: 'City',
            validation: composeValidators(maxLength(256)),
        },
        renderProps: {
            placeholder: 'City',
            isDisabled: false,
            size: 'small',
        },
    },
    {
        formField: {
            scheme: 'half',
            name: `${name}.state`,
            type: 'select',
            label: 'State',
        },
        renderProps: {
            placeholder: '',
            isDisabled: false,
            size: 'small',
            options: usaStates,
        },
    },
    {
        formField: {
            scheme: 'half',
            name: `${name}.zipCode`,
            type: 'input',
            label: 'Zip Code/Postal Code',
            isRequired: true,
            validation: composeValidators(maxLength(256)),
        },
        renderProps: {
            placeholder: '00000',
            isDisabled: false,
            size: 'small',
        },
    },
];
