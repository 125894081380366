import React from 'react';
import { Box } from '@mui/material';
import { Form } from 'react-final-form';
import FormPage from '../../../reusable/FormUI/FormPage';
import { makeStyles } from 'tss-react/mui';
import { FormConfig } from '../../../design/Forms/interfaces';
import { composeValidators, required } from '../../../utils/validation';
import {
    invalidFileType,
    tooLargeFile,
    tooManyFiles,
} from '../../../utils/fileValidation';
import { MAX_FILE_SIZE } from '../../../constants';
import SubmitButton from '../../../design/Buttons/SubmitButton';
import AppTypography from '../../../design/AppTypography';

export default function FarmUploadDocument() {
    const { classes } = useStyles();

    return (
        <Box className={classes.uploadDocumentWrapper}>
            <Box width={'100%'}>
                <AppTypography
                    variant={'font12'}
                    fontWeight={'bold'}
                    textTransform={'uppercase'}
                    color={'text.secondary'}
                    text={'UPLOAD DOCUMENT'}
                />
                <Form
                    initialValues={{}}
                    onSubmit={(values: { documents: File[] }) => {
                        //eslint-disable-next-line
                        console.log(values);
                    }}>
                    {({ handleSubmit, pristine, values }) => (
                        <form onSubmit={handleSubmit}>
                            <FormPage formConfig={uploadFile} />
                            <Box
                                display={'flex'}
                                justifyContent={'flex-end'}
                                pt={2}>
                                <SubmitButton
                                    isDisabled={
                                        pristine || !values?.documents.length
                                    }
                                    text={'Select file(s) to upload'}
                                />
                            </Box>
                        </form>
                    )}
                </Form>
            </Box>
        </Box>
    );
}
const uploadFile: FormConfig[] = [
    {
        formField: {
            name: 'documents',
            type: 'fileupload',
            validation: composeValidators(
                tooLargeFile(MAX_FILE_SIZE),
                tooManyFiles(10),
                required,
                invalidFileType([
                    'image/png',
                    'image/jpg',
                    'image/jpeg',
                    'image/bmp',
                    'application/pdf',
                ]),
            ),
        },
        renderProps: {
            filesAccepted: ['.png', '.jpg', '.jpeg', '.bmp', '.pdf'],
            placeholder: 'Drag a document here',
            helperText: 'Attach Document',
            multipleFiles: true,
            isDisabled: false,
        },
    },
];
const useStyles = makeStyles()((theme) => ({
    uploadDocumentWrapper: {
        width: '100%',
        display: 'flex',
        columnGap: theme.spacing(2),
        padding: theme.spacing(3, 2),
        backgroundColor: theme.palette.backgroundB.backgroundB2,
        borderRadius: 12,
        rowGap: theme.spacing(2),
        flex: 1,
    },
}));
