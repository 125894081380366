import { Typography } from '@mui/material';
import React from 'react';
import { formatCurrency } from '../../../../utils/fieldMasks';
import GenericTable, {
    Table,
} from '../../../../design/GenericTable/GenericTable';
import { SharedPayment } from './SharedPaymentsAllTable';
import StatusTag from '../../../../design/StatusTag/StatusTag';
import { paymentStatusMap } from '../../../../features/PaymentsOverview/usePaymentOverviewScheduled';

export default function SharedPaymentsShortTable({
    data,
}: {
    data: SharedPayment[];
}) {
    const paymentsPreviewConfig: Table<SharedPayment> = {
        columns: [
            {
                header: {
                    label: 'Lease Name',
                    sortable: false,
                },
                cellRender: (item) => <>{item?.lease.leaseName}</>,
                format: { align: 'left', color: { color: 'primary' } },
                width: 'auto',
            },
            {
                header: {
                    label: 'Lease Type',
                    sortable: false,
                },
                cellRender: (item) => (
                    <Typography variant={'font14'} fontWeight={'medium'}>
                        {item.lease.type}
                    </Typography>
                ),
                format: { align: 'left', color: { color: 'primary' } },
                width: 'auto',
            },
            {
                header: {
                    label: 'Amount',
                    sortable: false,
                },
                cellRender: (item) => formatCurrency(item?.amount),
                format: { align: 'left', color: { color: 'primary' } },
                width: 'auto',
            },
            {
                header: {
                    label: 'Due date',
                    sortable: false,
                },
                cellRender: (item) => (
                    <Typography variant={'font14'}>
                        {item?.paymentDate}
                    </Typography>
                ),
                format: { align: 'left', color: { color: 'primary' } },
                width: 'auto',
            },
            {
                header: {
                    label: 'Status',
                    sortable: false,
                },
                cellRender: (item) => (
                    <>
                        <StatusTag {...paymentStatusMap[item?.status]} />
                    </>
                ),
                format: { align: 'left', color: { color: 'primary' } },
                width: 'auto',
            },
        ],
        multiselect: false,
        expandable: false,
        pageable: false,
    };
    return <GenericTable data={data} tableConfig={paymentsPreviewConfig} />;
}
