import { Box, Button, CircularProgress } from '@mui/material';
import React from 'react';
import { makeStyles } from 'tss-react/mui';
interface OwnProps {
    handleCancel: () => void;
    handleAddNote: () => void;
    isLoading?: boolean;
    isDisabled?: boolean;
    submitButtonLabel?: string;
}
export default function ActionButtons({
    isLoading,
    handleCancel,
    handleAddNote,
    isDisabled,
    submitButtonLabel = 'Add Note',
}: OwnProps) {
    const { classes } = useStyles();
    return (
        <Box display={'flex'} columnGap={6} p={2} alignItems={'center'}>
            <Button
                variant="outlined"
                size="medium"
                onClick={handleCancel}
                className={classes.button}>
                Cancel
            </Button>
            <Button
                disabled={isDisabled}
                variant="contained"
                size="medium"
                onClick={handleAddNote}
                className={classes.button}>
                {!isLoading ? (
                    submitButtonLabel
                ) : (
                    <CircularProgress size={20} color="primary" />
                )}
            </Button>
        </Box>
    );
}

const useStyles = makeStyles()(() => ({
    button: {
        alignSelf: 'flex-end',
        textTransform: 'capitalize',
    },
}));
