import { Grid } from '@mui/material';
import React from 'react';
import Loader from '../../../../design/BaseLoader';
import SharedDataDashboardMetrics from '../../TemporaryAccess/Dashboard/SharedDataDashboardMetrics';
import { useGetDashboardQuery } from '../../../../api/sharedData/sharedDataPermanentAccessAPI';

export default function SharedDataDashboard() {
    const { data: dashboardData, isLoading: isDashboardDataLoading } =
        useGetDashboardQuery({});

    if (isDashboardDataLoading) {
        return (
            <Grid
                container
                alignItems={'center'}
                justifyContent={'center'}
                flexDirection={'column'}>
                <Loader />
            </Grid>
        );
    }

    return <SharedDataDashboardMetrics dashboardData={dashboardData} />;
}
