import GenericTable from '../../../../design/GenericTable/GenericTable';
import { Box } from '@mui/material';
import TableName from '../TableName';
import useTableArchiveContactConfig from './useTableArchiveContactConfig';
import TableSkeleton from '../../../../design/Skeleton/TableSkeleton';
import ConfirmDeleteModal from '../ConfirmDeleteModal';
import React from 'react';

export default function ArchiveContactTable() {
    const {
        data,
        tableConfig,
        setIsShowing,
        deleteData,
        handleDelete,
        isLoading,
        isProcessing,
        isShowing,
        totalCount,
    } = useTableArchiveContactConfig();
    if (isLoading) {
        return <TableSkeleton />;
    }
    return (
        <Box>
            <TableName name="Contacts" />
            <GenericTable
                data={data}
                totalCount={totalCount}
                tableConfig={tableConfig}
            />
            <ConfirmDeleteModal
                isShowing={isShowing}
                onClose={() => setIsShowing(false)}
                handleConfirm={() => {
                    handleDelete(deleteData?.id);
                }}
                title={`Are you sure you want to delete ${deleteData?.contactName}`}
                info={`This action cannot be undone`}
                isProcessing={isProcessing}
            />
        </Box>
    );
}
