import { Stack } from '@mui/material';
import AppTypography from '../../design/AppTypography';

export interface TitleWithValueProps {
    title: string;
    value: string | number;
    isHidden?: boolean;
}
export default function TitleWithValue({
    title,
    value = 'N/A',
    isHidden,
}: TitleWithValueProps) {
    if (isHidden) return null;

    return (
        <Stack rowGap={1} width={200}>
            <AppTypography
                variant={'font12'}
                fontWeight={'medium'}
                color={'text.secondary'}
                text={title}
            />
            <AppTypography
                variant={'font14'}
                fontWeight={'medium'}
                color={'text.neutral9'}
                text={value}
            />
        </Stack>
    );
}
