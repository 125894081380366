import { Box, Button, CircularProgress } from '@mui/material';

interface OwnProps {
    onCancel: () => void;
    onSubmit: () => void;
    isLoading?: boolean;
}

export default function ActionButtons({
    onCancel,
    onSubmit,
    isLoading,
}: OwnProps) {
    return (
        <Box display={'flex'} justifyContent={'flex-end'} pt={2} columnGap={2}>
            <Button onClick={onCancel} variant={'outlined'}>
                Cancel
            </Button>
            <Button
                disabled={isLoading}
                onClick={onSubmit}
                variant={'contained'}
                color={'primary'}>
                {isLoading ? <CircularProgress /> : 'Save'}
            </Button>
        </Box>
    );
}
