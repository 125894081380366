import TasksTable from '../../features/Tasks/TasksTable';
import WidgetPanel from '../../features/Tasks/WidgetPanel';
import { Box, Grid } from '@mui/material';
import ActionButton, {
    ActionMenuItem,
} from '../../design/Buttons/ActionButton';
import React, { useState } from 'react';
import TaskFormModalCreate from '../../features/Tasks/TaskFormModalCreate';

export default function AllTasks() {
    const [isShowing, setIsShowing] = useState(false);

    const actionButtonItems: ActionMenuItem[] = [
        {
            label: 'Create a Task',
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            onClick: () => {
                setIsShowing(true);
            },
        },
    ];
    return (
        <Box
            display={'flex'}
            flexDirection={'column'}
            width={'100%'}
            rowGap={7}>
            <WidgetPanel />
            <Grid
                container
                justifyContent={'flex-end'}
                width={'100%'}
                sx={{
                    padding: 2,
                }}>
                <ActionButton label={'Actions'} items={actionButtonItems} />
            </Grid>
            <TasksTable />
            {isShowing && (
                <TaskFormModalCreate
                    isShowing={isShowing}
                    setIsShowing={setIsShowing}
                />
            )}
        </Box>
    );
}
