import { Box, Typography } from '@mui/material';
import React, { useContext } from 'react';
import SearchFilter from './SearchFilter';
import CollapsedComponent from '../../design/CollapsedComponent';
import { SearchContext } from './SearchContext';

interface OwnProps {
    resultLength: number;
}

export default function SearchToolbar({ resultLength }: OwnProps) {
    const { data } = useContext(SearchContext);
    return (
        <Box
            display={'flex'}
            flexDirection={'column'}
            width={'100%'}
            rowGap={2}
            pb={2}>
            <Typography variant={'font12'} color={'text.link'}>
                {resultLength > 0 && `Showing ${resultLength} Results`}
            </Typography>
            <Box display={'flex'} flexDirection={'column'}>
                <CollapsedComponent
                    title={'Filter Results'}
                    isHidden={!data?.length}>
                    <SearchFilter />
                </CollapsedComponent>
            </Box>
        </Box>
    );
}
