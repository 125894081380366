import UserAvatarWithName from '../../../reusable/UserAvatarWithName';
import { Box, Typography } from '@mui/material';

interface OwnProps {
    isHidden?: boolean;
    guests: string[];
}

export default function GuestsList({ guests, isHidden }: OwnProps) {
    if (isHidden) return null;

    return (
        <Box
            display={'flex'}
            flexWrap={'wrap'}
            maxWidth={'35%'}
            flexDirection={'column'}
            rowGap={1}>
            <Typography variant={'font12'} color={'text.neutral9'}>
                Guests
            </Typography>
            <Box display={'flex'} flexWrap={'wrap'} columnGap={2.5} rowGap={1}>
                {guests.map((guest, index) => (
                    <UserAvatarWithName
                        key={index}
                        name={guest}
                        size={'small'}
                        color={'text.neutral9'}
                        typographyType={'font14'}
                    />
                ))}
            </Box>
        </Box>
    );
}
