import { Box, Card, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import IntegrationCardHeader from './IntegrationCardHeader';
import AppTypography from '../../../design/AppTypography';
import IntegrationsSettingStatusList from './IntegrationsSettingStatusList';
import theme from '../../../styles/theme';
import ConfigureButton from './ConfigureButton';
import CurrentIntegrationStatus from './CurrentIntegrationStatus';

export type IntegrationStatus = 'ACTIVE' | 'PAUSED' | 'INACTIVE';

export interface IntegrationSettings {
    name: string;
    status: IntegrationStatus;
}

export interface IntegrationCardProps {
    id: string;
    title: string;
    logo: string;
    settingsList: IntegrationSettings[];
    isDisabled: boolean;
    onInstall: () => void;
    onSwitch: () => void;
}

export default function IntegrationCard({
    id,
    title,
    logo,
    settingsList,
    isDisabled,
    onInstall,
    onSwitch,
}: IntegrationCardProps) {
    const { classes } = useStyles();

    const status = statusName(settingsList);
    const isConnected = isIntegrationConnected(status);

    return (
        <Card
            className={`${classes.root} ${isDisabled ? classes.disabled : ''}`}>
            {isDisabled && (
                <Typography
                    variant="font14"
                    color="error"
                    className={classes.disabledMessage}>
                    {`You don't have a subscription for this integration. Please
                    contact the Oaken admin.`}
                </Typography>
            )}
            <IntegrationCardHeader
                logo={logo}
                isConnected={isConnected}
                onInstall={onInstall}
                onSwitch={onSwitch}
                condition={status === 'ACTIVE'}
                isDisabled={isDisabled}
            />
            {!isConnected && (
                <AppTypography
                    text={title}
                    variant={'font20'}
                    fontWeight={'regular'}
                    color={'text.secondary'}
                />
            )}
            {isConnected && (
                <>
                    <CurrentIntegrationStatus
                        title={title}
                        status={status as IntegrationStatus}
                    />
                    <IntegrationsSettingStatusList
                        settingsList={settingsList}
                    />
                    <Box
                        width="100%"
                        display={'flex'}
                        justifyContent={'center'}
                        pt={2}>
                        <ConfigureButton id={id} isDisabled={isDisabled} />
                    </Box>
                </>
            )}
        </Card>
    );
}

const useStyles = makeStyles()(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        width: 300,
        height: 'fit-content',
        borderRadius: 16,
        padding: theme.spacing(2),
        rowGap: theme.spacing(2),
        position: 'relative',
    },
    disabled: {
        opacity: 0.5,
        pointerEvents: 'none',
    },
    disabledMessage: {
        top: theme.spacing(2),
        left: theme.spacing(2),
        right: theme.spacing(2),
        zIndex: 1,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(1),
        borderRadius: 8,
    },
}));

function isIntegrationConnected(status: IntegrationStatus | undefined) {
    return status !== 'INACTIVE';
}

function statusName(settingsList: IntegrationSettings[]) {
    const statusSetting = settingsList.find(
        (setting) => setting.name === 'Status',
    );
    return statusSetting ? statusSetting.status : undefined;
}
