import React from 'react';
import useEmergencyContactsConfig from './useTableEmergencyContactsConfig';
import GenericTable from '../../../design/GenericTable/GenericTable';

export default function EmergencyTable() {
    const { data, tableConfig, totalCount } = useEmergencyContactsConfig();

    return (
        <GenericTable
            data={data}
            tableConfig={tableConfig}
            totalCount={totalCount}
        />
    );
}
