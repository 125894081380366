import React from 'react';
import { Grid } from '@mui/material';
import CopiedReadFieldList from '../../design/CopiedReadField/CopiedReadFieldList';
import { makeStyles } from 'tss-react/mui';
import { ContactProfile } from '../../pages/AllProfiles/AllProfiles';

interface OwnProps {
    contactData: ContactProfile;
}

export default function AllProfilesDetails({ contactData }: OwnProps) {
    const { classes } = useStyles();
    const FIELDS = (contactData: ContactProfile) => [
        {
            title: 'Age',
            value: calculateAge(
                contactData?.age,
                contactData?.keyDates?.birthDate,
            ),
        },
        {
            title: 'Preferred Contact Method',
            value: contactData?.preferredContactMethod
                ? preferredContactMethodList[
                      contactData?.preferredContactMethod
                  ]
                : 'N/A',
        },
        {
            title: 'Associated Acres',
            value: contactData?.agriData?.associatedAcres || 'N/A',
        },
        {
            title: 'Contact Roles',
            value: contactData?.contactRoles
                ? contactData?.contactRoles.join(', ')
                : 'N/A',
        },
        {
            title: 'Power of Attorney  ',
            value: contactData?.powerOfAttorney === false ? 'No' : 'Yes',
        },

        {
            title: 'Associated Farms',
            value:
                contactData?.agriData?.associatedFarms?.length > 0
                    ? contactData?.agriData?.associatedFarms
                          .map((i) => i.name)
                          .join(' ,')
                    : 'N/A',
        },
        {
            title: 'Primary Lease Type',
            value: contactData?.agriData?.primaryLeaseType || 'N/A',
        },
        {
            title: 'Primary Crop',
            value: contactData?.agriData?.primaryCrop || 'N/A',
        },
    ];

    return (
        <Grid className={classes.viewWrapper}>
            <CopiedReadFieldList items={FIELDS(contactData)} />
        </Grid>
    );
}

const useStyles = makeStyles()((theme) => ({
    viewWrapper: {
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%',
        columnGap: theme.spacing(2),
        backgroundColor: theme.palette.neutralWhite,
        padding: theme.spacing(2),
    },
}));
const preferredContactMethodList: Record<string, string> = {
    PRIMARY_EMAIL: 'Primary Email',
    SECONDARY_EMAIL: 'Secondary Email',
    PHONE: 'Phone',
};

const calculateAge = (age?: string, birthDate?: string): string | number => {
    if (birthDate) {
        const today = new Date();
        const birthday = new Date(birthDate);
        const yearDifference = today.getFullYear() - birthday.getFullYear();
        const isBirthdayPassedThisYear =
            today.getMonth() > birthday.getMonth() ||
            (today.getMonth() === birthday.getMonth() &&
                today.getDate() >= birthday.getDate());

        return isBirthdayPassedThisYear ? yearDifference : yearDifference - 1;
    }
    return age || 'N/A';
};
