import { SocialMedia } from '../../ViewLandownersDetails/LandPartnerDetails';
import { Box, Divider } from '@mui/material';
import UserAvatarWithName from '../../../reusable/UserAvatarWithName';
import SocialMediaList from '../../../reusable/SocialMediaList';
import AppTypography from '../../../design/AppTypography';
import StatusTag, { Tag } from '../../../design/StatusTag/StatusTag';
import React from 'react';

type FarmStatus = 'ACTIVE' | 'INACTIVE' | 'PROSPECT' | 'SUSPENDED';
export interface MainInfo {
    avatar: string;
    name: string;
    relationshipStartDate: string;
    taxId: string;
    status: FarmStatus;
    socialMedia: SocialMedia;
}

export default function FarmMainInfo({
    avatar,
    name,
    relationshipStartDate,
    taxId,
    status,
    socialMedia,
}: MainInfo) {
    const partnershipYear = relationshipStartDate
        ? new Date(relationshipStartDate).getFullYear()
        : null;
    return (
        <Box display={'flex'} columnGap={1}>
            <Box
                display={'flex'}
                flexDirection={'column'}
                rowGap={3}
                width={312}
                alignItems={'center'}>
                <Box display={'flex'} flexDirection={'column'}>
                    <UserAvatarWithName
                        size={'xlarge'}
                        name={name}
                        gap={3}
                        avatar={avatar}
                        fontWeight={'medium'}
                        typographyType={'font20'}
                        color={'text.secondary'}
                    />
                    <Box
                        display={'flex'}
                        flexDirection={'column'}
                        rowGap={1}
                        pl={15}>
                        <AppTypography
                            text={name}
                            variant={'font12'}
                            fontWeight={'medium'}
                            color={'text.secondaryContrast'}
                        />
                        <AppTypography
                            isHidden={!taxId}
                            text={`Tax ID: ${taxId}`}
                            variant={'font12'}
                            fontWeight={'medium'}
                            color={'text.secondaryContrast'}
                        />
                        <AppTypography
                            isHidden={!relationshipStartDate}
                            text={`Partner Since ${partnershipYear}`}
                            variant={'font12'}
                            fontWeight={'medium'}
                            color={'text.secondaryContrast'}
                        />
                        <Box
                            display={'flex'}
                            rowGap={1}
                            flexDirection={'column'}
                            pt={3}>
                            <SocialMediaList socialMedia={socialMedia} />
                            <StatusTag {...statusMap[status]} />
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Divider orientation={'vertical'} flexItem variant="middle" />
        </Box>
    );
}
export const statusMap: Record<FarmStatus, Tag> = {
    ACTIVE: {
        status: 'success',
        text: 'Active',
    },
    INACTIVE: {
        status: 'warning',
        text: 'Inactive',
    },
    PROSPECT: {
        status: 'info',
        text: 'Pending',
    },
    SUSPENDED: {
        status: 'error',
        text: 'Suspended',
    },
};
