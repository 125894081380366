import { FormConfig } from '../Forms/interfaces';
import { Form } from 'react-final-form';
import FormPage from '../../reusable/FormUI/FormPage';
import { Box } from '@mui/material';
import ActionButtons, { ActionFilterButtons } from './ActionButtons';

interface FilterConfig<T, Y> {
    id: string;
    config: FormConfig[];
    onSubmit: (values: Y) => void;
    buttonsConfig: ActionFilterButtons<Y>[];
    initialFilters?: T;
    gap?: number;
}

export default function BaseFilter<T, Y>({
    id,
    initialFilters,
    config,
    gap = 1,
    buttonsConfig,
    onSubmit,
}: FilterConfig<T, Y>) {
    return (
        <Form
            id={id}
            onSubmit={onSubmit}
            initialValues={initialFilters}
            keepDirtyOnReinitialize>
            {({ handleSubmit, values }) => (
                <form onSubmit={handleSubmit}>
                    <FormPage formConfig={config} gap={gap} />
                    <Box
                        display={'flex'}
                        justifyContent={'flex-end'}
                        width={'100%'}>
                        <ActionButtons
                            buttons={buttonsConfig}
                            values={values}
                        />
                    </Box>
                </form>
            )}
        </Form>
    );
}
