import BaseModal from '../../reusable/BaseModal';
import { Box, Typography } from '@mui/material';
import theme from '../../styles/theme';
import React from 'react';
import { PaymentScheduledData } from './interfaces';
import LogPaymentsForm from './LogPaymentsForm';

interface OwnProps {
    isShowing: boolean;
    handleClose: () => void;
    data: PaymentScheduledData;
}

export default function LogPaymentModal({
    isShowing,
    handleClose,
    data,
}: OwnProps) {
    return (
        <BaseModal
            size={'small'}
            isShowing={isShowing}
            onClose={handleClose}
            header={'Log Payment'}>
            <Box display={'flex'} flexDirection={'column'} width={'100%'}>
                <Box
                    p={2}
                    display={'flex'}
                    columnGap={2}
                    justifyContent={'space-between'}
                    bgcolor={theme.palette.backgroundB.backgroundB1}>
                    <Typography variant={'font16'} fontWeight={'regular'}>
                        To: {data.landowner.name}
                    </Typography>
                </Box>
                <LogPaymentsForm
                    data={data}
                    id={data.id}
                    handleClose={handleClose}
                />
            </Box>
        </BaseModal>
    );
}
