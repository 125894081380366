import { FormConfig } from '../../../design/Forms/interfaces';
import {
    composeValidators,
    isNumber,
    notMoreThan,
    required,
} from '../../../utils/validation';

export const cropsTypeList = [
    { id: 'Corn', name: 'Corn' },
    { id: 'Soybean', name: 'Soybean' },
    { id: 'Rice', name: 'Rice' },
    { id: 'Wheat', name: 'Wheat' },
    { id: 'Cotton', name: 'Cotton' },
    { id: 'Beans', name: 'Beans' },
    { id: 'Legume', name: 'Legume' },
    { id: 'Brassica', name: 'Brassica' },
];

function filterCropsType(cropsType: string[], index: number) {
    return cropsTypeList.filter(
        (crop) => !cropsType?.slice(0, index).includes(crop.id),
    );
}
export const leaseCropSplitConfig = ({
    name,
    cropsType,
    handleGrowerShareChange,
    handleLandownerShareChange,
    index,
}: {
    name: string;
    cropsType: string[];
    handleGrowerShareChange: (index: number, value: string) => void;
    handleLandownerShareChange: (index: number, value: string) => void;
    index: number;
}): FormConfig[] => [
    {
        formField: {
            scheme: 'third',
            name: `${name}.crop`,
            type: 'select',
            label: 'Crop',
            isRequired: true,
            validation: composeValidators(required),
            tooltip: {
                text: 'Only one crop type can be selected',
                position: 'top',
            },
            onChange: () => {
                handleGrowerShareChange(index, '50');
                handleLandownerShareChange(index, '50');
            },
        },
        renderProps: {
            options: filterCropsType(cropsType, index),
            isDisabled: false,
            size: 'small',
        },
    },
    {
        formField: {
            scheme: 'third',
            name: `${name}.growerSharePercent`,
            type: 'input',
            label: 'Grower Share',
            validation: composeValidators(required, isNumber, notMoreThan(100)),
            onChange: (e) => handleGrowerShareChange(index, e),
        },
        renderProps: {
            isDisabled: false,
            size: 'small',
            fieldType: 'number',
            postfix: '%',
        },
    },
    {
        formField: {
            scheme: 'third',
            name: `${name}.landownerSharePercent`,
            type: 'input',
            label: 'Landowner Share',
            validation: composeValidators(required, isNumber, notMoreThan(100)),
            onChange: (e) => handleLandownerShareChange(index, e),
        },
        renderProps: {
            isDisabled: false,
            size: 'small',
            fieldType: 'number',
            postfix: '%',
        },
    },
];
