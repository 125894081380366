import LandPartnerDetailContainer from './LandPartnerDetailContainer';
import { LandPartnerUpdate } from '../../../features/ViewLandownersDetails/LandPartnerDetails';

import { makeStyles } from 'tss-react/mui';

interface OwnProps {
    landPartner?: LandPartnerUpdate;
}

export default function LandPartnerDetailPanel({ landPartner }: OwnProps) {
    const { classes } = useStyles();

    return (
        <div className={classes.containerWrapper}>
            <LandPartnerDetailContainer landPartner={landPartner} />
        </div>
    );
}

const useStyles = makeStyles()((theme) => ({
    containerWrapper: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2, 3),
        backgroundColor: theme.palette.neutralWhite,
        borderRadius: 16,
    },
}));
