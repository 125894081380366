import React from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    Grid,
    Typography,
    useTheme,
} from '@mui/material';

import useStyles from './ConfirmModal.styles';

import { ReactComponent as AlertIcon } from '../../assets/icons/alert_icon.svg';

export interface ConfirmModalProps {
    isShowing: boolean;
    hide: () => void;
    title: string;
    info: string;
    onSubmit: () => void;
    submitButtonText?: string;
    isDisabled?: boolean;
}

export default function ConfirmModal({
    isShowing,
    hide,
    title,
    info,
    onSubmit,
    submitButtonText = 'Confirm',
    isDisabled,
}: ConfirmModalProps) {
    const theme = useTheme();
    const { classes } = useStyles();
    const handleClose = (event: NonNullable<unknown>, reason: string) => {
        if (reason !== 'backdropClick') {
            hide();
        }
    };
    return (
        <Dialog open={isShowing} onClose={handleClose}>
            <DialogContent sx={{ padding: theme.spacing(3) }}>
                <Box display={'flex'} flexDirection={'column'} gap={3}>
                    <Grid className={classes.alertIconCircle1}>
                        <Grid className={classes.alertIconCircle2}>
                            <AlertIcon />
                        </Grid>
                    </Grid>
                    <Typography variant={'font20'} fontWeight={'medium'}>
                        {title}
                    </Typography>
                    <Typography variant={'font14'} fontWeight={'medium'}>
                        {info}
                    </Typography>
                </Box>
            </DialogContent>
            <DialogActions className={classes.bottomContainer}>
                <Button variant={'outlined'} onClick={hide} color={'secondary'}>
                    Cancel
                </Button>
                <Button
                    variant={'contained'}
                    type="submit"
                    disabled={isDisabled}
                    onClick={() => onSubmit()}>
                    {submitButtonText}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
