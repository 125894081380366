import React from 'react';

import { ActivityCard } from './LandPartnerActivity';

import CollapsedActivity from './ActivityPost/CollapsedActivity';
import { Box } from '@mui/material';

interface OwnProps {
    activities: ActivityCard[];
}

export default function MessageList({ activities }: OwnProps) {
    return (
        <Box display={'flex'} flexDirection={'column'} rowGap={1}>
            {activities.map((activity, index) => (
                <CollapsedActivity
                    isExpanded={index <= 4}
                    key={activity.id}
                    activity={activity}
                />
            ))}
        </Box>
    );
}
