import React from 'react';
import { FormControl, FormHelperText, MenuItem, Select } from '@mui/material';

import { makeStyles } from 'tss-react/mui';
import theme from '../../styles/theme';

export interface SelectInput {
    onChange: (value: string) => void;
    //eslint-disable-next-line
    onBlur?: (e: any) => void;
    value?: string;
    placeholder?: string;
    isDisabled?: boolean;
    errorText?: string;
    defaultValue?: string;
    size?: 'small' | 'medium';
    isHidden?: boolean;
    name?: string;
    options?: Array<{ id: string | number; name: string }>;
    fullWidth?: boolean;
    height?: number;
}

export default function SelectField({
    onChange,
    onBlur,
    value,
    placeholder,
    isDisabled,
    errorText,
    size,
    defaultValue,
    isHidden,
    options,
    name,
    fullWidth = false,
    height,
}: SelectInput) {
    const { classes } = useStyles();

    if (isHidden) return null;
    return (
        <FormControl
            variant="outlined"
            size={size}
            fullWidth={fullWidth}
            error={!!errorText}
            disabled={isDisabled}>
            <Select
                id={name}
                fullWidth
                sx={{
                    backgroundColor: theme.palette.neutralWhite,
                }}
                value={value}
                defaultValue={defaultValue}
                onChange={(e) => onChange(e.target.value)}
                onBlur={onBlur}
                displayEmpty
                classes={{ root: classes.select, icon: classes.icon }}
                style={{ height: height || 'auto' }}>
                <MenuItem disabled value="">
                    <p className={classes.placeholder}>{placeholder}</p>
                </MenuItem>
                {options?.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                        {option.name}
                    </MenuItem>
                ))}
            </Select>
            {!!errorText && <FormHelperText>{errorText}</FormHelperText>}
        </FormControl>
    );
}

const useStyles = makeStyles()((theme) => ({
    select: {
        paddingRight: '12px',
        '& > div': {
            paddingRight: '12px',
        },
    },
    icon: {
        right: '6px',
    },
    placeholder: {
        margin: 0,
        color: theme.palette.grey[400],
    },
}));
