import { TableColumn } from '../../design/GenericTable/GenericTable';
import {
    Lease,
    showFieldName,
    showFsaIdName,
    showLandownerName,
} from './TableConfig';
import LinkText from '../../design/Fields/LinkText';
import PATHS from '../../router/paths';
import BasedTooltip from '../../design/BasedTooltip';
import { formatCurrency } from '../../utils/fieldMasks';
import { sortColumnsByOrder } from '../utils';
import React from 'react';
import { ColumnConfig } from '../../api/users/customizeConfigAPI';

interface OwnProps {
    config: ColumnConfig[];
    sortKey: string;
    sortOrder: 'asc' | 'desc';
    handleSort: (key?: string) => void;
}

export default function useTableLeaseConfig({
    handleSort,
    config,
    sortKey,
    sortOrder,
}: OwnProps) {
    const columns: TableColumn<Lease>[] = [
        {
            header: {
                customizeKey: 'leaseName',
                label: 'Lease Name',
                sortable: true,
                sorting: {
                    id: 'leaseName',
                    direction: sortKey === 'leaseName' ? sortOrder : 'desc',
                    isSorted: sortKey === 'leaseName',
                },
                onClick: handleSort,
                isHidden:
                    config?.find((item) => item?.id === 'leaseName')
                        ?.isHidden || false,
            },
            cellRender: (lease: Lease) => (
                <LinkText
                    to={`/${PATHS.landPortfolio}/${PATHS.manage}/${lease?.id}`}
                    text={lease?.leaseName || ''}
                    variant={'font14'}
                />
            ),
            maxColumnWidth: 200,
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
            isHidden:
                config?.find((item) => item.id === 'leaseName')?.isHidden ||
                false,
        },
        {
            header: {
                customizeKey: 'fieldName',
                label: 'Field Name',
                sortable: true,
                sorting: {
                    id: 'fieldName',
                    direction: sortKey === 'fieldName' ? sortOrder : 'desc',
                    isSorted: sortKey === 'fieldName',
                },
                onClick: handleSort,
                isHidden:
                    config.find((item) => item.id === 'fieldName')?.isHidden ||
                    false,
            },
            cellRender: (lease: Lease) => showFieldName(lease),
            maxColumnWidth: 200,
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
            isHidden:
                config.find((item) => item.id === 'fieldName')?.isHidden ||
                false,
        },
        {
            header: {
                customizeKey: 'totalAcres',
                label: 'Total Acres',
                sortable: true,
                sorting: {
                    id: 'totalAcres',
                    direction: sortKey === 'totalAcres' ? sortOrder : 'desc',
                    isSorted: sortKey === 'totalAcres',
                },
                onClick: handleSort,
                isHidden:
                    config.find((item) => item.id === 'totalAcres')?.isHidden ||
                    false,
            },
            cellRender: (lease: Lease) => (
                <BasedTooltip>{lease?.totalAcres}</BasedTooltip>
            ),
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
            isHidden:
                config.find((item) => item.id === 'totalAcres')?.isHidden ||
                false,
        },
        {
            header: {
                customizeKey: 'aveRentPerAcre',
                label: 'Average Rent per Acre',
                sortable: false,
                isHidden:
                    config?.find((item) => item?.id === 'aveRentPerAcre')
                        ?.isHidden || false,
            },
            cellRender: (lease: Lease) => (
                <>
                    {' '}
                    {formatCurrency(lease?.aveRentPerAcre) === '$0.00'
                        ? null
                        : formatCurrency(lease?.aveRentPerAcre)}
                </>
            ),
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
            isHidden:
                config?.find((item) => item.id === 'aveRentPerAcre')
                    ?.isHidden || false,
        },
        {
            header: {
                customizeKey: 'tillableAcres',
                label: 'Tillable Acres',
                sortable: true,
                sorting: {
                    id: 'tillableAcres',
                    direction: sortKey === 'tillableAcres' ? sortOrder : 'desc',
                    isSorted: sortKey === 'tillableAcres',
                },
                onClick: handleSort,
                isHidden:
                    config.find((item) => item.id === 'tillableAcres')
                        ?.isHidden || false,
            },
            cellRender: (lease: Lease) => <>{lease?.tillableAcres}</>,
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
            isHidden:
                config.find((item) => item.id === 'tillableAcres')?.isHidden ||
                false,
        },
        {
            header: {
                customizeKey: 'landownerNames',
                label: 'Landowner',
                sortable: true,
                sorting: {
                    id: 'landownerNames',
                    direction:
                        sortKey === 'landownerNames' ? sortOrder : 'desc',
                    isSorted: sortKey === 'landownerNames',
                },
                onClick: handleSort,
                isHidden:
                    config.find((item) => item.id === 'landownerNames')
                        ?.isHidden || false,
            },
            cellRender: (lease: Lease) => <>{showLandownerName(lease)}</>,
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
            isHidden:
                config.find((item) => item.id === 'landownerNames')?.isHidden ||
                false,
        },
        {
            header: {
                customizeKey: 'type',
                label: 'Lease Type',
                sortable: true,
                sorting: {
                    id: 'type',
                    direction: sortKey === 'type' ? sortOrder : 'desc',
                    isSorted: sortKey === 'type',
                },
                onClick: handleSort,
                isHidden:
                    config.find((item) => item.id === 'type')?.isHidden ||
                    false,
            },
            cellRender: (lease: Lease) => <>{lease.type}</>,
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
            isHidden:
                config.find((item) => item.id === 'type')?.isHidden || false,
        },
        {
            header: {
                customizeKey: 'endDate',
                label: 'Lease Expiration',
                sortable: true,
                sorting: {
                    id: 'endDate',
                    direction: sortKey === 'endDate' ? sortOrder : 'desc',
                    isSorted: sortKey === 'endDate',
                },
                onClick: handleSort,
                isHidden:
                    config.find((item) => item.id === 'endDate')?.isHidden ||
                    false,
            },
            cellRender: (lease: Lease) => <>{lease.endDate}</>,
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
            isHidden:
                config.find((item) => item.id === 'endDate')?.isHidden || false,
        },
        {
            header: {
                customizeKey: 'fsaIds',
                label: 'FSA ID',
                sortable: false,
                onClick: handleSort,
                isHidden:
                    config.find((item) => item.id === 'fsaIds')?.isHidden ||
                    false,
            },
            cellRender: (lease: Lease) => <>{showFsaIdName(lease)}</>,
            maxColumnWidth: 200,
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
            isHidden:
                config.find((item) => item.id === 'fsaIds')?.isHidden || false,
        },
    ];

    const orderedColumns = sortColumnsByOrder<Lease>(columns, config);
    return { orderedColumns };
}
