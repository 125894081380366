import { FormConfig } from '../../../../design/Forms/interfaces';
import {
    composeValidators,
    maxLength,
    required,
} from '../../../../utils/validation';

export const formConfig: FormConfig[] = [
    {
        formField: {
            scheme: 'half',
            name: 'name',
            type: 'input',
            label: 'Role Name',
            isRequired: true,
            validation: composeValidators(required, maxLength(256)),
        },
        renderProps: {
            placeholder: 'Enter Role Name',
            isDisabled: false,
            size: 'small',
        },
    },
    {
        formField: {
            scheme: 'half',
            name: 'organizationName',
            type: 'input',
            label: 'Organization',
            isRequired: true,
        },
        renderProps: {
            placeholder: 'Organization',
            isDisabled: true,
            size: 'small',
        },
    },
    {
        formField: {
            scheme: 'full',
            name: 'description',
            type: 'textarea',
            label: 'Description',
            isRequired: true,
            validation: composeValidators(required, maxLength(1024)),
        },
        renderProps: {
            resize: 'none',
            isDisabled: false,
        },
    },
];
