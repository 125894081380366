import { Box, Collapse } from '@mui/material';
import { useState } from 'react';
import ShortCollapsedData from './ShortCollapsedData';
import ExpandedFieldData from './ExpandedFieldData';

export interface FieldDataPerCrop {
    crop: string;
    pesticideApplication: string;
    windSpeed: string;
    yield: string;
    pesticideTradeName: string;
    epaRegulation: string;
    plantingStart: string;
    licenseName: string;
    rate: string;
    plantingEnd: string;
    temperature: string;
    operatorName: string;
    harvest: string;
    windDirection: string;
}

export interface FieldData {
    id: string;
    year: string;
    cropData: FieldDataPerCrop[];
    soilSample: File;
    boundary: File;
}

interface OwnProps {
    data: FieldData;
    isExpanded?: boolean;
    onClick?: () => void;
    isHidden?: boolean;
}

export default function CollapsedDetails({
    data,
    isExpanded,
    isHidden,
}: OwnProps) {
    const [expanded, setExpanded] = useState(isExpanded || false);

    const handleCollapse = () => {
        setExpanded(!expanded);
    };

    if (isHidden) return null;

    return (
        <Box display={'flex'} flexDirection={'column'}>
            <ShortCollapsedData
                year={data.year}
                data={data.cropData[0]}
                isCollapsed={expanded}
                handleCollapse={handleCollapse}
            />
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <ExpandedFieldData data={data} isCollapsed={expanded} />
            </Collapse>
        </Box>
    );
}
