import React from 'react';
import { Link } from 'react-router-dom';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';
import { OverridableStringUnion } from '@mui/types';
import { Variant } from '@mui/material/styles/createTypography';
import { TypographyPropsVariantOverrides } from '@mui/material/Typography/Typography';
import BasedTooltip from '../BasedTooltip';
import { Typography } from '@mui/material';

interface LinkButtonProps {
    to: string;
    text: string;
    sx?: SxProps<Theme>;
    fontWeight?: string;
    children?: React.ReactNode;
    state?: unknown;
    variant?: OverridableStringUnion<
        Variant | 'inherit',
        TypographyPropsVariantOverrides
    >;
}

export default function LinkText({
    to,
    text,
    sx,
    fontWeight,
    state,
    variant,
}: LinkButtonProps) {
    return (
        <BasedTooltip text={text}>
            <Link to={to} state={state}>
                <Typography
                    variant={variant || 'font12'}
                    color={'text.link'}
                    fontWeight={fontWeight || 'medium'}
                    sx={sx || {}}>
                    {text}
                </Typography>
            </Link>
        </BasedTooltip>
    );
}
