import { useState } from 'react';
import { Button } from '@mui/material';
import { ReactComponent as PaymentIcon } from '../../assets/icons/payment_icon.svg';
import { PaymentScheduledData } from './interfaces';
import LogPaymentModal from './LogPaymentModal';
import theme from '../../styles/theme';

interface OwnProps {
    data: PaymentScheduledData;
}
export default function LogPaymentButton({ data }: OwnProps) {
    const [open, setOpen] = useState(false);
    function handleClick() {
        setOpen(true);
    }
    function handleClose() {
        setOpen(false);
    }
    return (
        <>
            <Button
                sx={{
                    fontSize: 12,
                    color: theme.palette.brandLightTurq,
                    fontWeight: 'medium',
                }}
                endIcon={<PaymentIcon />}
                variant={'outlined'}
                color={'primary'}
                onClick={handleClick}>
                Mark as Paid
            </Button>
            {open && (
                <LogPaymentModal
                    isShowing={open}
                    handleClose={handleClose}
                    data={data}
                />
            )}
        </>
    );
}
