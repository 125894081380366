import AppButton from '../../../design/Buttons/AppButton';
import IntegrationSwitch from './IntegrationSwitch';

interface OwnProps {
    isConnected: boolean;
    isDisabled: boolean;
    onInstall: () => void;
    onSwitch: () => void;
    condition?: boolean;
}

export default function IntegrationCardActions({
    isConnected,
    isDisabled,
    onInstall,
    onSwitch,
    condition,
}: OwnProps) {
    if (!isConnected) {
        return (
            <AppButton
                isDisabled={isDisabled}
                onClick={onInstall}
                variant="outlined"
                title={'Connect'}
            />
        );
    }
    return (
        <IntegrationSwitch
            isDisabled={isDisabled}
            condition={condition}
            onSwitch={onSwitch}
        />
    );
}
