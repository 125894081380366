import React from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';

import useStyles from './CompareRoles.styles';
import theme from '../../../../styles/theme';
import { ReactComponent as YesIcon } from '../../../../assets/icons/yes_icon.svg';
import { ReactComponent as NoIcon } from '../../../../assets/icons/no_icon.svg';
import { Permission, Role, RolePermissions } from './CompareRoles';

interface PermissionsTableProps {
    selectedRoles: RolePermissions[];
    options: Role[];
}

export default function PermissionsTable({
    selectedRoles,
    options,
}: PermissionsTableProps) {
    const { classes } = useStyles(theme);

    const allHeaders = React.useMemo(() => {
        const headersSet = new Set<string>();
        Object.keys(selectedRoles[0].data).forEach((header) => {
            headersSet.add(header);
        });
        return Array.from(headersSet);
    }, [selectedRoles]);

    return (
        <TableContainer>
            <Table className={classes.table} size="small">
                <PermissionsTableHeader
                    selectedRoles={selectedRoles}
                    options={options}
                />
                <TableBody>
                    {allHeaders.map((header) => {
                        const permissions: Permission[] =
                            selectedRoles[0].data[header] || [];
                        return (
                            <PermissionsTableRow
                                key={header}
                                header={header}
                                selectedRoles={selectedRoles}
                                permissions={permissions}
                            />
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

interface PermissionsTableHeaderProps {
    selectedRoles: RolePermissions[];
    options: Role[];
}

function PermissionsTableHeader({
    selectedRoles,
    options,
}: PermissionsTableHeaderProps) {
    const { classes, cx } = useStyles(theme);
    return (
        <TableHead>
            <TableRow
                className={cx(classes.headerRow, classes.additionalPadding)}>
                <TableCell>Access Type</TableCell>
                {selectedRoles.map((role) => (
                    <TableCell
                        key={role.roleId}
                        className={classes.centredCell}>
                        {options.find((opt) => opt.id === role.roleId)?.name}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

interface PermissionsTableRowProps {
    header: string;
    selectedRoles: RolePermissions[];
    permissions: Permission[];
}

function PermissionsTableRow({
    header,
    selectedRoles,
    permissions,
}: PermissionsTableRowProps) {
    const { classes } = useStyles(theme);
    return (
        <>
            <TableRow>
                <TableCell className={classes.groupHeaderCell}>
                    {header}
                </TableCell>
                {selectedRoles.map((role) => (
                    <TableCell
                        key={role.roleId}
                        className={classes.groupHeaderCell}></TableCell>
                ))}
            </TableRow>
            {permissions.map((permission, index) => {
                const isEvenRow = index % 2 === 0;
                return (
                    <TableRow
                        key={permission.id}
                        className={
                            isEvenRow
                                ? classes.alternatingRowWhite
                                : classes.alternatingRowBlue
                        }>
                        <TableCell>{permission.displayName}</TableCell>
                        {selectedRoles.map((role) => {
                            const rolePermission = role.data[header]?.find(
                                (p) => p.id === permission.id,
                            );
                            return (
                                <TableCell
                                    key={role.roleId}
                                    className={classes.centredCell}>
                                    {rolePermission?.enabled ? (
                                        <YesIcon />
                                    ) : (
                                        <NoIcon />
                                    )}
                                </TableCell>
                            );
                        })}
                    </TableRow>
                );
            })}
        </>
    );
}
