const breakpoints = {
    values: {
        xs: 0,
        sm: 420,
        md: 768,
        lg: 1024,
        xl: 1280,
    },
};
export default breakpoints;
