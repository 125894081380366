import { IntegrationStatus } from './IntegrationCard';
import { Box, Grid } from '@mui/material';
import AppTypography from '../../../design/AppTypography';
import { ReactNode } from 'react';
import TransferLabel from '../../../design/Labels/TransferLabel';
import PauseLabel from '../../../design/Labels/PauseLabel';

interface OwnProps {
    title: string;
    status: IntegrationStatus;
}

export default function CurrentIntegrationStatus({ title, status }: OwnProps) {
    const StatusComponent = statusMapper[status];
    return (
        <Grid
            container
            justifyContent={'space-between'}
            pt={1}
            flexWrap={'nowrap'}
            width={'100%'}
            maxWidth={'100%'}>
            <Grid item flexWrap={'wrap'} width={'100%'} maxWidth={'100%'}>
                <AppTypography
                    flexWrap={true}
                    text={title}
                    variant={'font20'}
                    fontWeight={'regular'}
                    color={'text.neutral6'}
                />
            </Grid>
            <Box pl={2} pr={2}>
                {StatusComponent}
            </Box>
            <Grid item flexWrap={'wrap'} width={'100%'} maxWidth={'100%'}>
                <AppTypography
                    text={'OAKEN'}
                    variant={'font20'}
                    fontWeight={'regular'}
                    color={'text.neutral6'}
                />
            </Grid>
        </Grid>
    );
}

const statusMapper: Record<IntegrationStatus, ReactNode> = {
    ACTIVE: <TransferLabel />,
    PAUSED: <PauseLabel />,
    INACTIVE: <></>,
};
