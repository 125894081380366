import React, { useState } from 'react';
import { Box, Grid } from '@mui/material';
import UserProfileForm from './UserProfileForm';
import ReadOnlyUserProfile from './ReadOnlyUserProfile';
import { useGetAccountQuery } from '../../api/users/accountAPI';
import Loader from '../../design/BaseLoader';
import { SocialMedia } from '../ViewLandownersDetails/LandPartnerDetails';

export interface Account {
    id: string;
    firstName: string;
    lastName: string;
    primaryEmail: string;
    secondaryEmail?: string;
    phoneNumber?: string;
    organization?: Organization;
    role: Role;
    profilePhoto?: ProfilePhoto;
    socialMedia?: SocialMedia;
    keyDates?: KeyDates;
}

export interface ProfilePhoto {
    id: string;
    fileName: string;
    fileType: string;
    profilePhoto: string;
}

export interface Organization {
    id: string;
    name: string;
    type: string;
    primaryContactFirstName: string;
    primaryContactLastName: string;
    address1: string;
    zipCode: string;
    phoneNumber?: string;
    email: string;
    status: string;
}

export interface Role {
    id: string;
    name: string;
}

export interface KeyDates {
    birthDate?: string;
    relationshipStartDate?: string;
}

export default function UserProfile() {
    const [isEdit, setIsEdit] = useState(false);
    const {
        data,
        isLoading: isProfileDataLoading,
        isFetching: isProfileDataFetching,
    } = useGetAccountQuery({});

    if (isProfileDataLoading || isProfileDataFetching) {
        return (
            <Grid
                container
                sx={{ height: '100vh' }}
                alignItems={'center'}
                justifyContent={'center'}
                flexDirection={'column'}>
                <Loader />
            </Grid>
        );
    }
    return (
        <Box>
            {isEdit ? (
                <UserProfileForm data={data} onClick={() => setIsEdit(false)} />
            ) : (
                <ReadOnlyUserProfile
                    data={data}
                    onClick={() => setIsEdit(true)}
                />
            )}
        </Box>
    );
}
