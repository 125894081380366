import React from 'react';
import { Stack } from '@mui/material';
import AppTypography from '../../../design/AppTypography';
import theme from '../../../styles/theme';

interface OwnProps {
    fieldName: string;
    landPartnerName: string;
    location: string;
}

export default function FieldsDashboardMainInfo({
    fieldName,
    landPartnerName,
    location,
}: OwnProps) {
    return (
        <Stack
            width={312}
            p={theme.spacing(2, 3)}
            borderLeft={`solid 2px ${theme.palette.brandLimeGreen}`}
            borderRight={`solid 2px ${theme.palette.brandLimeGreen}`}>
            <AppTypography
                flexWrap={true}
                text={fieldName}
                variant={'font24'}
                fontWeight={'medium'}
                color={'text.secondary'}
            />
            <AppTypography
                flexWrap={true}
                isHidden={!location}
                text={`[${location}]`}
                variant={'font14'}
                fontWeight={'regular'}
                color={'text.neutral7'}
            />
            <AppTypography
                flexWrap={true}
                isHidden={!landPartnerName}
                text={landPartnerName}
                variant={'font14'}
                fontWeight={'medium'}
                color={'text.link'}
            />
        </Stack>
    );
}
