import React, { useMemo } from 'react';
import DocumentsList from '../../../../reusable/DocumentsList/DocumentsList';
import { useGetSharedDocumentsTmpQuery } from '../../../../api/sharedData/sharedDataTempAccessAPI';
import { Grid } from '@mui/material';
import Loader from '../../../../design/BaseLoader';

interface OwnProps {
    token: string;
}

export default function SharedDocumentsPage({ token }: OwnProps) {
    const { data: documents, isLoading: isSharedDocumentsLoading } =
        useGetSharedDocumentsTmpQuery({
            token,
            params: {
                page: 0,
                size: 100,
            },
        });

    const data = useMemo(() => {
        return documents?.data || [];
    }, [documents]);

    if (isSharedDocumentsLoading) {
        return (
            <Grid
                container
                alignItems={'center'}
                justifyContent={'center'}
                flexDirection={'column'}>
                <Loader />
            </Grid>
        );
    }

    return <DocumentsList cards={data || []} token={token} />;
}
