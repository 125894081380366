import FormScreenWrapper from './FormScreenWrapper';
import { Box } from '@mui/material';
import AppTypography from '../../design/AppTypography';
import { FormConfig } from '../../design/Forms/interfaces';
import GenericArrayField, { Config } from './GenericArrayField';
import FormPage from '../../reusable/FormUI/FormPage';

export default function ResourceManagementForm() {
    return (
        <FormScreenWrapper title="Resource Management">
            <Box display={'flex'} flexDirection={'column'} rowGap={2}>
                <AppTypography
                    variant="font16"
                    fontWeight={'medium'}
                    text={'What are the backup power sources available?'}
                    color={'text.neutral9'}
                />
                <GenericArrayField
                    fieldName={'backupPowerSources'}
                    config={({ name }: Config) => backupPowerConfig({ name })}
                    addButtonLabel={'Add another power source'}
                />
                <AppTypography
                    variant="font16"
                    fontWeight={'medium'}
                    text={
                        'What is the average amount of fuel, water, and other essential supplies stored on-site?'
                    }
                    color={'text.neutral9'}
                />
                <GenericArrayField
                    fieldName={'essentialSupplies'}
                    config={({ name }: Config) =>
                        essentialSuppliesConfig({ name })
                    }
                    addButtonLabel={'Add another essential supply'}
                />
                <AppTypography
                    variant="font16"
                    fontWeight={'medium'}
                    text={
                        'What is the plan for accessing additional resources if needed?'
                    }
                    color={'text.neutral9'}
                />
                <FormPage formConfig={additionalResourcesConfig} />
            </Box>
        </FormScreenWrapper>
    );
}

function backupPowerConfig({ name }: Config): FormConfig[] {
    return [
        {
            formField: {
                scheme: 'half',
                name: `backupPowerSources.${name}.type`,
                type: 'select',
                label: 'Type',
            },
            renderProps: {
                placeholder: 'Select from list',
                isDisabled: false,
                size: 'small',
                options: [],
            },
        },
        {
            formField: {
                scheme: 'half',
                name: `backupPowerSources.${name}.location`,
                type: 'input',
                label: 'Location',
            },
            renderProps: {
                isDisabled: false,
                size: 'small',
            },
        },
    ];
}
function essentialSuppliesConfig({ name }: Config): FormConfig[] {
    return [
        {
            formField: {
                scheme: 'third',
                name: `essentialSupplies.${name}.crop`,
                type: 'select',
                label: 'Crop',
            },
            renderProps: {
                placeholder: 'Select from list',
                isDisabled: false,
                size: 'small',
                options: [],
            },
        },
        {
            formField: {
                scheme: 'third',
                name: `essentialSupplies.${name}.protectionMeasures`,
                type: 'select',
                label: 'Protection',
            },
            renderProps: {
                placeholder: 'Select from list',
                isDisabled: false,
                size: 'small',
                options: [],
            },
        },
        {
            formField: {
                scheme: 'third',
                name: `essentialSupplies.${name}.field`,
                type: 'input',
                label: 'Field',
            },
            renderProps: {
                isDisabled: false,
                size: 'small',
            },
        },
    ];
}

const additionalResourcesConfig: FormConfig[] = [
    {
        formField: {
            scheme: 'full',
            name: 'resourceManagement.additionalResourcesPlan',
            type: 'textarea',
            label: '',
        },
        renderProps: {
            resize: 'none',
            isDisabled: false,
            minRows: 3,
        },
    },
];
