import { Button } from '@mui/material';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/remove_icon.svg';
import theme from '../../../styles/theme';

interface OwnProps {
    onClick: () => void;
    isDisabled?: boolean;
    isHidden?: boolean;
}

export default function DeleteButton({
    onClick,
    isDisabled,
    isHidden,
}: OwnProps) {
    if (isHidden) return null;

    return (
        <Button
            sx={{
                color: theme.palette.brandLightTurq,
                fontWeight: 600,
                fontSize: 12,
            }}
            variant="outlined"
            onClick={onClick}
            endIcon={
                <DeleteIcon
                    style={{
                        fill: theme.palette.brandLightTurq,
                    }}
                />
            }
            disabled={isDisabled}>
            Permanently Delete
        </Button>
    );
}
