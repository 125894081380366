import { HTTP_METHOD } from '../auth/http';
import { ENDPOINTS } from '../endpoints';
import { OAKENApi } from '../OAKENApi';
import { getListProvidesTags } from '../generic';
import { ACTIVITY, DOCUMENTS } from '../tags';

export const landPartnersActivitiesAPI = OAKENApi.injectEndpoints({
    endpoints: (builder) => ({
        getActivitiesList: builder.query({
            query: ({ id }) => {
                return {
                    url:
                        process.env.REACT_APP_USER_API_HOST +
                        ENDPOINTS.activity.landpartner +
                        `/${id}`,
                    method: HTTP_METHOD.GET,
                };
            },
            providesTags: (result) =>
                getListProvidesTags(result?.data, DOCUMENTS.DOCUMENTS),
        }),
        getActivityById: builder.query({
            query: ({ id, type }) => {
                return {
                    url:
                        process.env.REACT_APP_USER_API_HOST +
                        ENDPOINTS.activity.root +
                        `/${id}/${type}`,
                    method: HTTP_METHOD.GET,
                };
            },
            providesTags: (result) => [
                { type: ACTIVITY.ACTIVITY, id: result?.id },
            ],
        }),
        addComment: builder.mutation({
            query: ({ activityId, comment }) => {
                return {
                    url:
                        process.env.REACT_APP_USER_API_HOST +
                        ENDPOINTS.activity.root +
                        `/${activityId}/comment`,
                    method: HTTP_METHOD.POST,
                    body: comment,
                };
            },
            invalidatesTags: (res) => [
                { type: ACTIVITY.ACTIVITY, id: res?.activityId },
            ],
        }),
        addCommentAttachment: builder.mutation({
            query: ({ id, attachment }) => {
                const formData = new FormData();
                if (attachment) {
                    formData.append('attachment', attachment);
                }
                return {
                    url: `${process.env.REACT_APP_USER_API_HOST}${ENDPOINTS.activity.root}/attachments/${id}`,
                    method: HTTP_METHOD.POST,
                    body: formData,
                };
            },
            invalidatesTags: (res) => [
                { type: ACTIVITY.ACTIVITY, id: res?.activityId },
            ],
        }),
    }),
});

export const {
    useGetActivitiesListQuery,
    useAddCommentAttachmentMutation,
    useAddCommentMutation,
    useGetActivityByIdQuery,
    useLazyGetActivityByIdQuery,
    useLazyGetActivitiesListQuery,
} = landPartnersActivitiesAPI;
