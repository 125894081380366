import { TaskPriority, TaskRelatesToType, TaskStatus } from './TasksTable';

export interface TaskView {
    id: string;
    name: string;
    status: TaskStatus;
    dueDate: string;
    assignTo: AssignTo;
    relatesTo: TaskRelatesToType;
    relatesToName: string;
    priority: TaskPriority;
    description: string;
    createdByUser: CreatedBy;
    watchers: Watcher[];
    overdue?: boolean;
}

export interface AssignTo {
    id: string;
    firstName: string;
    lastName: string;
    profilePhoto: ProfilePhoto;
}

export interface ProfilePhoto {
    id: string;
    fileName: string;
    fileType: string;
    profilePhoto: string;
}
export interface CreatedBy {
    id: string;
    firstName: string;
    lastName: string;
}
export interface Watcher {
    id: string;
    firstName: string;
    lastName: string;
    profilePhoto: ProfilePhoto;
}
export interface DecodedToken {
    organizationId: string;
    role: string;
    userId: string;
    exp?: number;
}

export const relationOptions = [
    {
        id: 'LEASE',
        name: 'Lease',
    },
    {
        id: 'LAND_PARTNER',
        name: 'Land Partner',
    },
];
export const priorityOptions = [
    {
        id: 'HIGH',
        name: 'High',
    },
    {
        id: 'MEDIUM',
        name: 'Medium',
    },
    {
        id: 'LOW',
        name: 'Low',
    },
];
export const statusOptions = [
    {
        id: 'TO_DO',
        name: 'New',
    },
    {
        id: 'IN_PROGRESS',
        name: 'In Progress',
    },
    {
        id: 'SUSPENDED',
        name: 'Suspended',
    },
    {
        id: 'DONE',
        name: 'Complete',
    },
];
export function userListConvertor(
    userList: {
        id: string;
        firstName: string;
        lastName: string;
    }[],
) {
    return userList?.map((i) => ({
        id: i.id,
        name: `${i.firstName} ${i.lastName}`,
    }));
}
