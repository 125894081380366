import { Alert, AlertTitle, Box, Typography } from '@mui/material';
import React from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import theme from '../../styles/theme';

interface OwnProps {
    query: string;
}
export default function SearchAlert({ query }: OwnProps) {
    const title = query
        ? `Sorry we didn’t find any results for ${query}`
        : 'Please enter at least 3 characters to search.';
    const message = query
        ? 'No worries, You can ensure better results by checking your spelling or trying broader search terms.'
        : '';
    return (
        <Alert severity="info" icon={<AlertIcon />}>
            <Box
                display={'flex'}
                gap={2}
                p={1}
                alignItems={'center'}
                flexDirection={'column'}>
                <AlertTitle>{title}</AlertTitle>
                <Typography variant={'font16'}>{message}</Typography>
            </Box>
        </Alert>
    );
}

function AlertIcon() {
    return (
        <Box
            display={'flex'}
            p={1}
            alignItems={'center'}
            bgcolor={theme.palette.statusRed}>
            <InfoOutlinedIcon color={'secondary'} />
        </Box>
    );
}
