import React, { useState } from 'react';

import { Badge, Box, IconButton } from '@mui/material';

import theme from '../../styles/theme';
import UserAvatar from '../../design/Avatar/UserAvatar';
import IconButtonsWithActions from '../../design/Buttons/IconButtonsWithActions';

import { ReactComponent as HelpIcon } from '../../assets/icons/help_icon.svg';
import { ReactComponent as BugIcon } from '../../assets/icons/bug_icon.svg';

import PATHS from '../../router/paths';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { ActionMenuItem } from '../../design/Buttons/ActionButton';
import BugReportForm from '../../features/BugReportForm';

import { clearLocalStorage } from '../../utils/localStorageUtils';
import { OAKENApi } from '../../api/OAKENApi';
import NotificationPopover from '../../features/Notifications/NotificationPopover';
import {
    useGetNotificationsInfoQuery,
    useGetNotificationsListQuery,
} from '../../api/notifications/notificationAPI';
import { makeStyles } from 'tss-react/mui';

import { HeaderHeight } from '../../styles/constants';
import SearchPopover from '../../features/Search/SearchPopover';
import TrialBanner from '../TrialBanner/TrialBanner';
import useManagePermission from '../../hooks/useManagePermission';

interface OwnProps {
    firstName: string;
    lastName: string;
    profilePhoto?: string;
    isAdmin: boolean;
    isTrial?: boolean;
}

export default function Header({
    firstName,
    lastName,
    profilePhoto,
    isAdmin,
    isTrial,
}: OwnProps) {
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const { data } = useGetNotificationsInfoQuery({});

    const { data: initData } = useGetNotificationsListQuery({
        sort: `createdAt,desc`,
        page: 0,
        size: 5,
    });

    const [open, setOpen] = useState(false);
    const { checkPermission } = useManagePermission();
    const { classes } = useStyles();
    const cards = initData?.data || [];

    function logOutUser() {
        clearLocalStorage();
        dispatch(OAKENApi.util?.resetApiState());
        navigate(`/${PATHS.auth}/${PATHS.logIn}`);
    }

    const userButtonItems: ActionMenuItem[] = [
        {
            label: 'User Profile',
            onClick: () => navigate(`/${PATHS.myProfile}`),
        },
        {
            label: 'Farm Profile',
            onClick: () => navigate(`/${PATHS.farmProfile}`),
            hidden: !checkPermission('farmProfile', 'read'),
        },
        {
            label: 'Data Privacy',
            onClick: () =>
                window.open('https://www.oaken.ag/end-user-terms-1', '_blank'),
        },

        { label: 'Sign Out', onClick: logOutUser },
    ];

    return (
        <>
            {isTrial && <TrialBanner />}
            <header className={classes.headerWrapper}>
                <div className={classes.pageName}>
                    {isAdmin ? 'Settings' : `Welcome ${firstName} ${lastName}`}
                </div>
                <Box
                    display={'flex'}
                    flexDirection={'row'}
                    gap={1}
                    alignItems={'center'}>
                    {!checkPermission('lpDashboardSharing', 'read') && (
                        <SearchPopover />
                    )}
                    <IconButton
                        onClick={() => setOpen(true)}
                        className={classes.iconButton}>
                        <BugIcon />
                    </IconButton>
                    <IconButton disabled className={classes.iconButton}>
                        <HelpIcon />
                    </IconButton>
                    {(isAdmin || checkPermission('notifications', 'read')) && (
                        <Badge
                            showZero={false}
                            badgeContent={data?.totalUnreadNotifications}
                            className={classes.badge}>
                            <NotificationPopover cards={cards} />
                        </Badge>
                    )}
                    <IconButtonsWithActions items={userButtonItems}>
                        <UserAvatar
                            name={`${firstName} ${lastName}`}
                            avatar={profilePhoto || ''}
                        />
                    </IconButtonsWithActions>
                </Box>
                <BugReportForm open={open} setOpen={setOpen} />
            </header>
        </>
    );
}

const useStyles = makeStyles()(() => ({
    headerWrapper: {
        display: 'flex',
        alignItems: 'center',
        padding: '16px 24px',
        height: HeaderHeight,
        width: '100%',
    },
    pageName: {
        flex: 1,
        fontSize: '16px',
        fontWeight: 600,
        color: theme.palette.neutral.neutral10,
    },
    iconButton: {
        '&:hover': {
            backgroundColor: theme.palette.brandLightTurq,
            padding: theme.spacing(1),
            borderRadius: 12,
            fill: theme.palette.neutralWhite,
            transition: 'all 0.2s ease-in-out',
            '& svg': {
                cursor: 'pointer',
                fill: theme.palette.neutralWhite,
            },
        },
    },
    searchInput: {
        '& .MuiInputBase-input': {
            padding: theme.spacing(1.5, 1, 1.5, 2),
        },
    },
    badge: {
        '& .MuiBadge-badge': {
            backgroundColor: theme.palette.limeGreen.limeGreenShade1,
            color: theme.palette.neutralWhite,
            fontSize: '12px',
            height: '20px',
            minWidth: '30px',
            border: 'solid 2px white',
        },
    },
}));
