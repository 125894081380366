import React, { useState } from 'react';

import { Card, Divider, IconButton, Typography } from '@mui/material';

import { makeStyles } from 'tss-react/mui';
import clsx from 'clsx';

import { ReactComponent as NotesIcon } from '../../assets/icons/edit_notes_icon.svg';
import AddNotesModal from '../../reusable/AddNotesModal';
import TreeCardAvatar from '../../reusable/Tree/TreeCardAvatar';
import useAvatar from '../../hooks/useAvatar';
import { useParams } from 'react-router-dom';
import TreeNode from '../../reusable/Tree/TreeNode';
import { CombinedContactNodeDatum } from './ContactOrganizationTree';

export interface TreeCardElement {
    id?: string;
    firstName: string;
    lastName: string;
    name?: string;
    isRoot?: boolean;
    primaryContact: boolean;
    relationship?: string;
    isDisabled?: boolean;
    avatar: TreeCardAvatar;
    landPartnerLogo?: LandPartnerLogo;
    children?: TreeCardElement[];
}

export interface TreeCardAvatar {
    fileName: string;
    fileType: string;
    contactAvatar: string;
}
export interface LandPartnerLogo {
    id: string;
    createdBy: string;
    updatedBy: string;
    createdAt: string;
    updatedAt: string;
    fileName: string;
    fileType: string;
    companyLogo: string;
    organizationId: string;
}

export interface OwnProps {
    nodeDatum: CombinedContactNodeDatum;
}

export default function TreeCardContact({ nodeDatum }: OwnProps) {
    const { classes } = useStyles();

    const [currentId, setCurrentId] = useState<string | undefined>();
    const isOrganization = !!nodeDatum?.name;
    const [isNotesShowing, setIsNotesShowing] = useState(false);

    const { id: contactIdForMarking } = useParams();
    const handleNotesClose = () => {
        setCurrentId(undefined);
        setIsNotesShowing(false);
    };
    const handleNotesOpen = () => {
        setCurrentId(nodeDatum.id);
        setIsNotesShowing(true);
    };

    const { avatarUrl: contactLogo } = useAvatar(
        nodeDatum.avatar?.contactAvatar,
        nodeDatum.avatar?.fileType,
    );
    const { avatarUrl: landPartnerLogo } = useAvatar(
        nodeDatum?.landPartnerLogo?.companyLogo,
        nodeDatum?.landPartnerLogo?.fileType,
    );

    return (
        <>
            <TreeNode isDisabled={nodeDatum.isDisabled} nodeDatum={nodeDatum}>
                <TreeCardAvatar
                    isMarked={nodeDatum?.primaryContact}
                    avatar={landPartnerLogo || contactLogo}
                    name={
                        nodeDatum?.name ||
                        `${nodeDatum.firstName} ${nodeDatum.lastName}`
                    }
                />
                <Card
                    className={clsx(
                        classes.cardContainer,
                        contactIdForMarking === nodeDatum.id &&
                            classes.markedCardContainer,
                        isOrganization && classes.markedCardContainerAsOrg,
                    )}>
                    {
                        <div className={classes.actionButtonContainer}>
                            <IconButton onClick={handleNotesOpen}>
                                {!isOrganization && <NotesIcon />}
                            </IconButton>
                        </div>
                    }
                    <Typography
                        variant={'font16'}
                        color={'text.secondary'}
                        fontWeight={'medium'}>
                        {nodeDatum.name ||
                            `${nodeDatum.firstName} ${nodeDatum.lastName}`}
                    </Typography>
                    <Typography
                        variant={'font12'}
                        textTransform={'capitalize'}
                        color={'text.secondary'}>
                        {nodeDatum.relationship?.toLowerCase()}
                    </Typography>
                </Card>
                <Divider orientation={'vertical'} className={classes.divider} />
            </TreeNode>

            {isNotesShowing && currentId && (
                <AddNotesModal
                    id={currentId}
                    contact={convertContactData(nodeDatum)}
                    isShowing={isNotesShowing}
                    onClose={handleNotesClose}
                />
            )}
        </>
    );
}

const useStyles = makeStyles()((theme) => ({
    actionButtonContainer: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
    },
    divider: {
        height: '12px',
        color: theme.palette.brandLightTurq,
    },
    cardContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        backgroundColor: theme.palette.neutral.neutral2,
        padding: theme.spacing(0, 2, 2),
        borderRadius: '12px',
        borderStyle: 'none !important',
    },
    markedCardContainer: {
        backgroundColor: theme.palette.lightTurq.lightTurqTint5,
    },
    markedCardContainerAsOrg: {
        backgroundColor: theme.palette.lightTurq.lightTurqTint4,
    },
}));
function convertContactData(item: CombinedContactNodeDatum) {
    return {
        id: item.id,
        avatar: item.avatar,
        firstName: item.firstName,
        lastName: item.lastName,
    };
}
