import React, { useEffect } from 'react';
import WizardForm from '../../design/Forms/WizardForm';
import arrayMutators from 'final-form-arrays';
import MembersFormPage from './MembersFormPage';
import PreviewLandPartner from './PreviewLandPartner';
import {
    useAddLandPartnerMutation,
    useLazyGetOneLandPartnerQuery,
    useUpdateLandPartnerMutation,
} from '../../api/landpartners/landPartnersAPI';
import useHandleSubmitRTK from '../../hooks/useHandleSubmit';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import PATHS from '../../router/paths';
import { LandPartner } from './LandPartnerWizard';
import { format } from 'date-fns';
import OrganizationForm from './OrganizationForm';
import { Grid } from '@mui/material';
import Loader from '../../design/BaseLoader';
import useAvatar from '../../hooks/useAvatar';
import { LandPartnerUpdate } from '../ViewLandownersDetails/LandPartnerDetails';
import PrimaryContactFormPage from './PrimaryContactFormPage';
import BillingFormPage from './BillingFormPage';
import { DATE_FORMAT } from '../../utils/dateFormat';

interface OwnProps {
    pageNumber: number;
    setPageNumber: (page: number) => void;
    setIsValid: (isValid: boolean) => void;
}

export default function LandPartnerForm({
    pageNumber,
    setPageNumber,
    setIsValid,
}: OwnProps) {
    const navigate = useNavigate();
    const { state, pathname } = useLocation();
    const isGrower = pathname.includes('growers');
    const { id } = useParams<{ id: string }>();
    const { createFile } = useAvatar();
    const isEdit = !!id;

    const [
        getItems,
        { isFetching: isFetchingOne, isLoading: isLoadingOne, data },
    ] = useLazyGetOneLandPartnerQuery();

    useEffect(() => {
        id && getItems(id);
    }, [id]);

    const { handleSubmit: handleCreate, isLoading: isCreating } =
        useHandleSubmitRTK({
            useRtkHook: useAddLandPartnerMutation,
            onSuccess: (data) =>
                navigate(
                    data?.type === 'LAND_PARTNER'
                        ? `/${PATHS.landpartners}/${PATHS.profiles}`
                        : `/${PATHS.landpartners}/${PATHS.growers}`,
                ),
            successMessage: `${isGrower ? 'Grower' : 'Land Partner'} added successfully`,
        });
    const { handleSubmit: handleUpdate, isLoading: isUpdating } =
        useHandleSubmitRTK({
            useRtkHook: useUpdateLandPartnerMutation,
            onSuccess: (data) =>
                navigate(
                    data?.type === 'LAND_PARTNER'
                        ? `/${PATHS.landpartners}/${PATHS.profiles}`
                        : `/${PATHS.landpartners}/${PATHS.growers}`,
                ),
            successMessage: `${isGrower ? 'Grower' : 'Land Partner'} updated successfully`,
        });

    if (isFetchingOne || isLoadingOne) {
        return (
            <Grid
                width={'100%'}
                container
                alignItems={'center'}
                justifyContent={'center'}>
                <Loader />
            </Grid>
        );
    }

    function handleSubmitLandPartner(value: LandPartner | LandPartnerUpdate) {
        if (isEdit) {
            handleUpdate(convertDataForUpdate(value as LandPartnerUpdate));
        } else {
            handleCreate(convertDataForCreate(value as LandPartner));
        }
    }

    const formData = data && {
        ...data,
        relationshipStartDate:
            data?.relationshipStartDate &&
            new Date(data?.relationshipStartDate),
        primaryContact: {
            ...data.primaryContact,
            keyDates: {
                birthDate:
                    data?.primaryContact?.keyDates?.birthDate &&
                    new Date(data?.primaryContact?.keyDates?.birthDate),
                anniversary:
                    data?.primaryContact?.keyDates?.anniversary &&
                    new Date(data?.primaryContact?.keyDates?.anniversary),
                relationshipStartDate:
                    data?.primaryContact?.keyDates?.relationshipStartDate &&
                    new Date(
                        data?.primaryContact?.keyDates?.relationshipStartDate,
                    ),
                dateOfDeath:
                    data?.primaryContact?.keyDates?.dateOfDeath &&
                    new Date(data?.primaryContact.keyDates?.dateOfDeath),
            },
        },
        landPartnerLogo:
            data.landPartnerLogo &&
            createFile(
                data?.landPartnerLogo?.companyLogo,
                data?.landPartnerLogo?.fileName,
                data?.landPartnerLogo?.fileType,
            ),
    };

    return (
        <WizardForm
            isLoading={isCreating || isUpdating}
            setIsValid={setIsValid}
            mutators={arrayMutators as never}
            initialValues={
                isEdit
                    ? formData
                    : {
                          type: state,
                          status: 'ACTIVE',
                          primaryContact: {
                              newPrimaryContact: {
                                  preferredContactMethod: 'PRIMARY_EMAIL',
                              },
                          },
                      }
            }
            onSubmit={handleSubmitLandPartner}
            setStep={setPageNumber}
            step={pageNumber}>
            <OrganizationForm />
            <PrimaryContactFormPage isEdit={isEdit} />
            {!isEdit ? <MembersFormPage /> : null}
            <BillingFormPage />
            <PreviewLandPartner setPageNumber={setPageNumber} />
        </WizardForm>
    );
}

function convertDataForCreate(value: LandPartner) {
    return {
        ...value,
        relationshipStartDate:
            value?.relationshipStartDate &&
            format(new Date(value?.relationshipStartDate), DATE_FORMAT),
        primaryContact: {
            ...value.primaryContact,
            newPrimaryContact: value?.primaryContact?.existingPrimaryContactId
                ? null
                : {
                      ...value.primaryContact.newPrimaryContact,
                      keyDates: {
                          relationshipStartDate:
                              value?.primaryContact?.newPrimaryContact?.keyDates
                                  ?.relationshipStartDate &&
                              format(
                                  new Date(
                                      value?.primaryContact?.newPrimaryContact?.keyDates?.relationshipStartDate,
                                  ),
                                  DATE_FORMAT,
                              ),
                          anniversary:
                              value?.primaryContact?.newPrimaryContact?.keyDates
                                  ?.anniversary &&
                              format(
                                  new Date(
                                      value?.primaryContact?.newPrimaryContact?.keyDates?.anniversary,
                                  ),
                                  DATE_FORMAT,
                              ),
                          birthDate:
                              value?.primaryContact?.newPrimaryContact?.keyDates
                                  ?.birthDate &&
                              format(
                                  new Date(
                                      value?.primaryContact?.newPrimaryContact?.keyDates?.birthDate,
                                  ),
                                  DATE_FORMAT,
                              ),
                          dateOfDeath:
                              value?.primaryContact?.newPrimaryContact?.keyDates
                                  ?.dateOfDeath &&
                              format(
                                  new Date(
                                      value?.primaryContact?.newPrimaryContact?.keyDates?.dateOfDeath,
                                  ),
                                  DATE_FORMAT,
                              ),
                      },
                  },
            childContacts: value.primaryContact.childContacts?.map(
                (contact) => ({
                    ...contact,
                    keyDates: {
                        relationshipStartDate:
                            contact?.keyDates?.relationshipStartDate &&
                            format(
                                new Date(
                                    contact?.keyDates?.relationshipStartDate,
                                ),
                                DATE_FORMAT,
                            ),
                        anniversary:
                            contact?.keyDates?.anniversary &&
                            format(
                                new Date(contact?.keyDates?.anniversary),
                                DATE_FORMAT,
                            ),
                        birthDate:
                            contact?.keyDates?.birthDate &&
                            format(
                                new Date(contact?.keyDates?.birthDate),
                                DATE_FORMAT,
                            ),
                        dateOfDeath:
                            contact?.keyDates?.dateOfDeath &&
                            format(
                                new Date(contact.keyDates?.dateOfDeath),
                                DATE_FORMAT,
                            ),
                    },
                }),
            ),
        },
    };
}

function convertDataForUpdate(value: LandPartnerUpdate) {
    return {
        ...value,
        relationshipStartDate:
            value?.relationshipStartDate &&
            format(new Date(value?.relationshipStartDate), DATE_FORMAT),
        primaryContact: {
            ...value.primaryContact,
            keyDates: {
                relationshipStartDate:
                    value?.primaryContact?.keyDates?.relationshipStartDate &&
                    format(
                        new Date(
                            value?.primaryContact?.keyDates?.relationshipStartDate,
                        ),
                        DATE_FORMAT,
                    ),
                anniversary:
                    value?.primaryContact?.keyDates?.anniversary &&
                    format(
                        new Date(value?.primaryContact?.keyDates?.anniversary),
                        DATE_FORMAT,
                    ),
                birthDate:
                    value?.primaryContact?.keyDates?.birthDate &&
                    format(
                        new Date(value?.primaryContact?.keyDates?.birthDate),
                        DATE_FORMAT,
                    ),
                dateOfDeath:
                    value?.primaryContact?.keyDates?.dateOfDeath &&
                    format(
                        new Date(value?.primaryContact?.keyDates?.dateOfDeath),
                        DATE_FORMAT,
                    ),
            },
        },
    };
}
