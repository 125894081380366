import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
    bottomContainer: {
        backgroundColor: theme.palette.neutralWhite,
        display: 'flex',
        justifyContent: 'flex-end',
        gap: '16px',
        padding: theme.spacing(3),
    },
    alertIconCircle1: {
        width: '72px',
        height: '72px',
        backgroundColor: theme.palette.statusRed,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50%',
    },
    alertIconCircle2: {
        width: '56px',
        height: '56px',
        backgroundColor: theme.palette.brandDarkTurq,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50%',
        paddingBottom: '6px',
    },
}));

export default useStyles;
