import React from 'react';
import GenericTable, {
    Table,
} from '../../../../design/GenericTable/GenericTable';
import BasedTooltip from '../../../../design/BasedTooltip';
import { SharedLease, showFsaIdName } from './SharedLeasesShortTable';

interface OwnProps {
    data: SharedLease[];
    totalCount: number;
    handlePageChange: (page: number) => void;
    handleItemsPerPageChange: (size: number) => void;
    currentPage: number;
    itemsPerPage: number;
    sortKey: string;
    sortOrder: 'asc' | 'desc';
    handleSort: (key?: string) => void;
}

export default function SharedLeasesAllTable({
    data,
    totalCount,
    handlePageChange,
    handleItemsPerPageChange,
    currentPage,
    itemsPerPage,
    sortKey,
    sortOrder,
    handleSort,
}: OwnProps) {
    const leasePreviewConfig: Table<SharedLease> = {
        columns: [
            {
                header: {
                    label: 'Lease Name',
                    sortable: true,
                    sorting: {
                        id: 'leaseName',
                        direction: sortKey === 'leaseName' ? sortOrder : 'desc',
                        isSorted: sortKey === 'leaseName',
                    },
                    onClick: handleSort,
                },
                cellRender: (lease: SharedLease) => <>{lease?.leaseName}</>,
                maxColumnWidth: 200,
                format: { align: 'left', color: { color: 'primary' } },
                width: 'auto',
            },
            {
                header: {
                    customizeKey: 'totalAcres',
                    label: 'Total Acres',
                    sortable: true,
                    sorting: {
                        id: 'totalAcres',
                        direction:
                            sortKey === 'totalAcres' ? sortOrder : 'desc',
                        isSorted: sortKey === 'totalAcres',
                    },
                    onClick: handleSort,
                },
                cellRender: (lease: SharedLease) => (
                    <BasedTooltip>{lease?.totalAcres}</BasedTooltip>
                ),
                format: { align: 'left', color: { color: 'primary' } },
                width: 'auto',
            },
            {
                header: {
                    customizeKey: 'tillableAcres',
                    label: 'Tillable Acres',
                    sortable: true,
                    sorting: {
                        id: 'tillableAcres',
                        direction:
                            sortKey === 'tillableAcres' ? sortOrder : 'desc',
                        isSorted: sortKey === 'tillableAcres',
                    },
                    onClick: handleSort,
                },
                cellRender: (lease: SharedLease) => <>{lease?.tillableAcres}</>,
                format: { align: 'left', color: { color: 'primary' } },
                width: 'auto',
            },
            {
                header: {
                    customizeKey: 'type',
                    label: 'Lease Type',
                    sortable: true,
                    sorting: {
                        id: 'type',
                        direction: sortKey === 'type' ? sortOrder : 'desc',
                        isSorted: sortKey === 'type',
                    },
                    onClick: handleSort,
                },
                cellRender: (lease: SharedLease) => <>{lease?.type}</>,
                format: { align: 'left', color: { color: 'primary' } },
                width: 'auto',
            },
            {
                header: {
                    customizeKey: 'endDate',
                    label: 'Lease Expiration',
                    sortable: true,
                    sorting: {
                        id: 'endDate',
                        direction: sortKey === 'endDate' ? sortOrder : 'desc',
                        isSorted: sortKey === 'endDate',
                    },
                    onClick: handleSort,
                },
                cellRender: (lease: SharedLease) => <>{lease?.endDate}</>,
                format: { align: 'left', color: { color: 'primary' } },
                width: 'auto',
            },
            {
                header: {
                    customizeKey: 'fsaIds',
                    label: 'FSA ID',
                    sortable: false,
                },
                cellRender: (lease: SharedLease) => <>{showFsaIdName(lease)}</>,
                maxColumnWidth: 200,
                format: { align: 'left', color: { color: 'primary' } },
                width: 'auto',
            },
        ],
        expandable: false,
        handlePageChange: handlePageChange,
        handleItemsPerPageChange: handleItemsPerPageChange,
        initialPage: currentPage,
        initialPageSize: itemsPerPage,
    };
    return (
        <GenericTable
            data={data}
            tableConfig={leasePreviewConfig}
            totalCount={totalCount}
        />
    );
}
