import React, { useState } from 'react';
import { Box, Grid, Typography, IconButton } from '@mui/material';
import { ReactComponent as DownloadIcon } from '../../assets/icons/download_icon.svg';
import { ReactComponent as RemoveIcon } from '../../assets/icons/remove_icon.svg';
import { dateTimeConverterFromUTC } from '../../utils/dateFormat';

import {
    createDownloadLinkFromBlob,
    getFilenameFromContentDisposition,
} from '../../utils/fileHandler';
import { useDownloadDocuments } from '../../hooks/useDownloadDocuments';
import PreviewModal from '../../reusable/Modal/PreviewModal';
import { useLazyDownloadDocumentQuery } from '../../api/documents/documentsAPI';

export interface TaskAttachment {
    id: string;
    fileName: string;
    createdAt: string;
    type: string;
    createdBy: CreatedBy;
    link: string;
}

export interface CreatedBy {
    id: string;
    firstName: string;
    lastName: string;
    profilePhoto: ProfilePhoto;
}

export interface ProfilePhoto {
    id: string;
    fileName: string;
    fileType: string;
    profilePhoto: string[];
}

interface OwnProps {
    attachment: TaskAttachment;
    onDelete: (id: string) => void;
}

export default function TaskDocumentListItem({
    attachment,
    onDelete,
}: OwnProps) {
    const [getItems] = useLazyDownloadDocumentQuery();
    const [fileName, setFileName] = useState<string>('');

    const {
        isShowing,
        file,
        isLoading: isDocumentLoading,
        handleClose,
        extension,
        handlePreviewOpen,
    } = useDownloadDocuments();

    function handlePreview(documentId: string, fileName: string) {
        setFileName(fileName);
        handlePreviewOpen(documentId);
    }
    const handleDownload = async (id: string) => {
        getItems(id)
            .unwrap()
            .then(({ data, headers }) => {
                createDownloadLinkFromBlob(
                    data,
                    getFilenameFromContentDisposition(headers),
                );
            });
    };
    return (
        <>
            <Grid
                display={'flex'}
                alignItems={'center'}
                width={'100%'}
                columnGap={1}>
                <Box
                    maxWidth={'30%'}
                    display={'flex'}
                    onClick={() =>
                        handlePreview(attachment?.id, attachment?.fileName)
                    }>
                    <Typography
                        sx={{
                            '&:hover': {
                                cursor: 'pointer',
                                textDecoration: 'underline',
                            },
                        }}
                        noWrap={true}
                        variant="font14"
                        color="text.link"
                        fontWeight={'regular'}>
                        {attachment.fileName}
                    </Typography>
                </Box>

                <Typography variant="font14" color="text.neutral8">
                    {attachment.createdAt &&
                        dateTimeConverterFromUTC(attachment.createdAt)}
                </Typography>

                <Box display={'flex'} columnGap={1} alignItems={'center'}>
                    <IconButton onClick={() => handleDownload(attachment.id)}>
                        <DownloadIcon />
                    </IconButton>
                    <IconButton onClick={() => onDelete(attachment.id)}>
                        <RemoveIcon />
                    </IconButton>
                </Box>
            </Grid>
            {extension(fileName) && (
                <PreviewModal
                    extension={extension(fileName) as string}
                    fileName={fileName}
                    file={file}
                    isLoading={isDocumentLoading}
                    isShowing={isShowing}
                    onClose={handleClose}
                />
            )}
        </>
    );
}
