import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import { Form, FormSpy } from 'react-final-form';
import FormPage from '../../../reusable/FormUI/FormPage';
import { makeStyles } from 'tss-react/mui';
import { FormConfig } from '../../../design/Forms/interfaces';
import { composeValidators, required } from '../../../utils/validation';
import {
    invalidFileType,
    tooLargeFile,
    tooManyFiles,
} from '../../../utils/fileValidation';
import { MAX_FILE_SIZE } from '../../../constants';
import UploadOneDocumentModal from './UploadOneDocumentModal';
import SubmitButton from '../../../design/Buttons/SubmitButton';
import { DocumentCategory, ResponseDocumentType } from './useDocumentsTable';
import UploadBulkDocumentsModal from './UploadBulkDocumentsModal';
import { FormApi } from 'final-form';

export interface DocumentRequest {
    documents: File;
    parentId: string;
    year: number;
    documentType: ResponseDocumentType;
    documentCategory: DocumentCategory;
    documentPurpose: string;
    sharingAllowed: boolean;
}
export default function UploadDocument() {
    const [file, setFile] = useState<File[] | undefined>(undefined);
    const [isShowing, setIsShowing] = useState(false);
    const [formValues, setFormValues] = useState<FormApi | undefined>(
        undefined,
    );
    const handleClose = () => {
        setIsShowing(false);
    };
    const handleSubmit = (values: { documents: File[] }) => {
        setFile(values.documents);
        setIsShowing(true);
    };

    function formReset() {
        formValues && formValues.reset();
    }
    function removeFile(name: string) {
        formValues &&
            formValues.change(
                'documents',
                file?.filter((file) => file.name !== name),
            );
    }
    const { classes } = useStyles();

    return (
        <Box className={classes.uploadDocumentWrapper}>
            <Box width={'100%'}>
                <Typography
                    variant={'font12'}
                    fontWeight={'bold'}
                    textTransform={'uppercase'}
                    color={'text.secondary'}>
                    UPLOAD DOCUMENTS
                </Typography>

                <Form
                    initialValues={{}}
                    onSubmit={(values: { documents: File[] }) => {
                        handleSubmit(values);
                    }}>
                    {({ handleSubmit, form, pristine, values }) => (
                        <form onSubmit={handleSubmit}>
                            <FormSpy
                                onChange={() =>
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-ignore
                                    setFormValues(form)
                                }
                            />
                            <FormPage formConfig={uploadFile} />
                            <Box
                                display={'flex'}
                                justifyContent={'flex-end'}
                                pt={2}>
                                <SubmitButton
                                    isDisabled={
                                        pristine || !values?.documents.length
                                    }
                                    text={'Select file(s) to upload'}
                                />
                            </Box>
                        </form>
                    )}
                </Form>
            </Box>
            <UploadOneDocumentModal
                formReset={formReset}
                file={file?.[0]}
                isShowing={!!(file && file.length === 1) && isShowing}
                handleClose={handleClose}
            />
            <UploadBulkDocumentsModal
                removeFile={removeFile}
                formReset={formReset}
                files={file}
                isShowing={!!(file && file?.length > 1) && isShowing}
                handleClose={handleClose}
            />
        </Box>
    );
}
const useStyles = makeStyles()((theme) => ({
    uploadDocumentWrapper: {
        width: '100%',
        display: 'flex',
        columnGap: theme.spacing(2),
        padding: theme.spacing(3, 2),
        backgroundColor: theme.palette.backgroundB.backgroundB2,
        borderRadius: 12,
        rowGap: theme.spacing(2),
        flex: 1,
    },
}));

const uploadFile: FormConfig[] = [
    {
        formField: {
            name: 'documents',
            type: 'fileupload',
            validation: composeValidators(
                tooLargeFile(MAX_FILE_SIZE),
                tooManyFiles(10),
                required,
                invalidFileType([
                    'image/png',
                    'image/jpg',
                    'image/jpeg',
                    'image/bmp',
                    'application/pdf',
                ]),
            ),
        },
        renderProps: {
            filesAccepted: ['.png', '.jpg', '.jpeg', '.bmp', '.pdf'],
            placeholder: 'Drag a document here',
            helperText: 'Attach Document',
            multipleFiles: true,
            isDisabled: false,
        },
    },
];
