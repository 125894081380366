import {
    composeValidators,
    maxLength,
    required,
} from '../../../utils/validation';
import {
    invalidFileType,
    tooLargeFile,
    tooManyFiles,
} from '../../../utils/fileValidation';
import { FormConfig } from '../../../design/Forms/interfaces';
import { MAX_FILE_SIZE } from '../../../constants';

const yesNoOptions = [
    {
        id: true,
        name: 'Yes',
    },
    {
        id: false,
        name: 'No',
    },
];

export interface Option {
    id: string;
    name: string;
    status?: string;
}
interface OwnProps {
    organization: Option[];
    isEdit: boolean;
}
export const formUploadConfig = ({
    organization,
    isEdit,
}: OwnProps): FormConfig[] => [
    {
        formField: {
            scheme: 'half',
            name: 'leaseName',
            type: 'input',
            label: 'Lease Name',
            isRequired: true,
            validation: composeValidators(required, maxLength(256)),
        },
        renderProps: {
            placeholder: 'Enter Lease Name',
            size: 'small',
            isDisabled: false,
        },
    },
    {
        formField: {
            scheme: 'half',
            name: 'verbalLease',
            type: 'radio',
            label: 'Is this a verbal lease?',
        },
        renderProps: {
            size: 'third',
            options: yesNoOptions,
            isDisabled: false,
        },
    },

    {
        formField: {
            scheme: 'half',
            name: 'leaseFile',
            type: 'fileupload',
            validation:
                !isEdit &&
                composeValidators(
                    tooLargeFile(MAX_FILE_SIZE),
                    tooManyFiles(1),
                    requiredIfVerbalLease,
                    invalidFileType([
                        'image/png',
                        'image/jpg',
                        'image/jpeg',
                        'image/bmp',
                        'application/pdf',
                    ]),
                ),
        },
        renderProps: {
            filesAccepted: ['.png', '.jpg', '.jpeg', '.bmp', '.pdf'],
            placeholder: 'Drag the lease document here',
            helperText: 'Attach Lease Document',
            multipleFiles: false,
            isDisabled: false,
            isHidden: isEdit,
        },
    },
    {
        formField: {
            scheme: 'half',
            name: 'supportingFile',
            type: 'fileupload',
            validation: composeValidators(
                tooLargeFile(MAX_FILE_SIZE),
                invalidFileType([
                    'image/png',
                    'image/jpg',
                    'image/jpeg',
                    'image/bmp',
                    'application/pdf',
                ]),
            ),
        },
        renderProps: {
            filesAccepted: ['.png', '.jpg', '.jpeg', '.bmp', '.pdf'],
            placeholder: 'Drag supporting document here (optional)',
            helperText: 'Supporting Documents (optional)',
            multipleFiles: true,
            isDisabled: false,
            isHidden: isEdit,
        },
    },

    {
        formField: {
            name: 'grower',
            type: 'select',
            label: 'Tenant',
        },
        renderProps: {
            placeholder: 'Select from list',
            isDisabled: false,
            size: 'small',
            options: organization,
        },
    },
];

function requiredIfVerbalLease(
    value: string,
    allValues: Record<string, string>,
) {
    const isVerbalLease = allValues?.verbalLease.toString() === 'true';

    if (
        !isVerbalLease &&
        (value === null ||
            value === undefined ||
            value === '' ||
            value.length === 0)
    ) {
        return 'This field is required';
    }
    return undefined;
}
