import React, { useContext, useState } from 'react';
import { TableColumn } from '../../../../design/GenericTable/GenericTable';
import { SelectedRowContext } from '../SelectedRowContext';
import { useSorting } from '../../../../design/GenericTable/useSorting';
import { usePagination } from '../../../../design/GenericTable/useBackendPagination';

import { Typography } from '@mui/material';
import ActionsColumn from '../ActionsColumn';
import useHandleSubmitRTK from '../../../../hooks/useHandleSubmit';

import {
    useDeleteLeaseMutation,
    useGetArchivedLeasesQuery,
    useRestoreLeaseMutation,
} from '../../../../api/leases/leaseAPI';
import { Lease } from '../../../LandPortfolio/TableConfig';
import { dateTimeConverterFromUTC } from '../../../../utils/dateFormat';

export interface LeaseArchive {
    id: string;
    leaseName: string;
    leaseType: string;
    startDate: string;
    landownersNames?: string;
    endDate: string;
    archiveDate: string;
    archivedBy: string;
    fieldNames: string[];
    createdBy: string;
    createdDate: string;
}

export default function useTableArchiveLeaseConfig() {
    const [isShowing, setIsShowing] = useState(false);
    const [deleteData, setDeleteData] = useState<LeaseArchive | null>(null);
    const { sortKey, sortOrder, handleSort } = useSorting('lease_name', 'asc');
    const { setSelectedRow } = useContext(SelectedRowContext);
    const {
        currentPage,
        itemsPerPage,
        handlePageChange,
        handleItemsPerPageChange,
    } = usePagination(1, 100);

    const { handleSubmit, isLoading: isRestoreProcessing } = useHandleSubmitRTK(
        {
            useRtkHook: useRestoreLeaseMutation,
            onSuccess: () => setSelectedRow(null),
            successMessage: 'Lease restored successfully',
        },
    );
    const { handleSubmit: handleDelete, isLoading: isDeleteProcessing } =
        useHandleSubmitRTK({
            useRtkHook: useDeleteLeaseMutation,
            onSuccess: () => {
                setIsShowing(false);
                setSelectedRow(null);
            },
            successMessage: 'Lease deleted successfully',
        });
    const { data, isLoading } = useGetArchivedLeasesQuery({
        sort: `${sortKey},${sortOrder}`,
        page: currentPage - 1,
        size: itemsPerPage,
    });

    const handleRowClick = (rowData: LeaseArchive) => {
        setSelectedRow(rowData);
    };

    function handleDeleteClick(data: LeaseArchive) {
        setIsShowing(true);
        setDeleteData(data);
    }

    const columns: TableColumn<LeaseArchive>[] = [
        {
            header: {
                label: 'Name',
                sortable: true,
                sorting: {
                    id: 'lease_name',
                    direction: sortKey === 'lease_name' ? sortOrder : 'desc',
                    isSorted: sortKey === 'lease_name',
                },
                onClick: handleSort,
            },
            cellRender: (lease) => (
                <Typography color={'text.link'}>{lease.leaseName}</Typography>
            ),
            maxColumnWidth: 200,
            format: { align: 'left', color: { color: 'primary' } },
            width: '20%',
        },
        {
            header: {
                label: 'Archived Date',
                sortable: false,
                sorting: {
                    id: 'archiveDate',
                    direction: sortKey === 'archiveDate' ? sortOrder : 'desc',
                    isSorted: sortKey === 'archiveDate',
                },
                onClick: handleSort,
            },
            cellRender: (lease) => <>{lease.archiveDate}</>,
            format: { align: 'left', color: { color: 'primary' } },
            width: '20%',
        },
        {
            header: {
                label: 'Archived By',
                sortable: false,
                sorting: {
                    id: 'archivedBy',
                    direction: sortKey === 'archivedBy' ? sortOrder : 'desc',
                    isSorted: sortKey === 'archivedBy',
                },
                onClick: handleSort,
            },
            cellRender: (lease) => <>{lease?.archivedBy}</>,
            format: { align: 'left', color: { color: 'primary' } },
            width: '20%',
        },
        {
            header: {
                customizeKey: 'actions',
                label: 'Actions',
                sortable: false,
            },
            cellRender: (lease) => (
                <ActionsColumn
                    id={lease.id}
                    onDelete={() => handleDeleteClick(lease)}
                    onRestore={() => handleSubmit(lease.id)}
                    isProcessing={isRestoreProcessing || isDeleteProcessing}
                />
            ),
            format: { align: 'left', color: { color: 'primary' } },
            width: '40%',
        },
    ];

    const tableConfig = {
        columns,
        pageable: false,
        handlePageChange,
        onClick: handleRowClick,
        handleItemsPerPageChange,
        initialPageSize: 5,
        initialPage: 1,
    };
    const totalCount = data?.totalCount || 0;
    return {
        data: data?.data ? data?.data.map(dataConvertorFromResponse) : [],
        totalCount,
        tableConfig,
        isLoading,
        isProcessing: isRestoreProcessing || isDeleteProcessing,
        deleteData,
        isShowing,
        setIsShowing,
        handleDelete,
    };
}

function dataConvertorFromResponse(data: Lease): LeaseArchive {
    return {
        id: data.id,
        leaseName: data?.leaseName,
        leaseType: data.type,
        startDate: data.startDate,
        endDate: data.endDate,
        archiveDate:
            (data.updatedAt && dateTimeConverterFromUTC(data.updatedAt)) ||
            'N/A',
        archivedBy: data.updatedByName || 'N/A',
        fieldNames: data.fsaIds.map((fsa) => fsa.landName),
        landownersNames: data.landownerNames,
        createdBy: data.createdByName,
        createdDate: dateTimeConverterFromUTC(data.createdAt),
    };
}
