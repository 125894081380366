import FormScreenWrapper from './FormScreenWrapper';
import { Box } from '@mui/material';
import AppTypography from '../../design/AppTypography';
import FormPage from '../../reusable/FormUI/FormPage';
import { FormConfig } from '../../design/Forms/interfaces';
import {
    composeValidators,
    maxLengthForArrayField,
    minLengthForArrayField,
} from '../../utils/validation';

import { Option } from '../../features/CreateLeaseForm/WizardPageConfigs/landPartnersConfig';

export default function TrainingPreparednessForm() {
    return (
        <FormScreenWrapper title="Training and Preparedness">
            <Box display={'flex'} flexDirection={'column'} rowGap={2}>
                <AppTypography
                    variant="font16"
                    fontWeight={'medium'}
                    text={'Would you like to schedule a emergency drill?'}
                    color={'text.neutral9'}
                />
                <FormPage formConfig={emergencyDrillConfig} />
                <AppTypography
                    variant="font16"
                    fontWeight={'medium'}
                    text={
                        'How often should this disaster response plan be reviewed and updated?'
                    }
                    color={'text.neutral9'}
                />
                <FormPage formConfig={frequencyConfig} />
            </Box>
        </FormScreenWrapper>
    );
}
const frequencyOptions: Option[] = [
    { id: '1', name: 'Daily' },
    { id: '7', name: 'Weekly' },
    { id: '30', name: 'Monthly' },
    { id: '90', name: 'Every 90 Days' },
    { id: '180', name: ' Every 180 Days' },
];
const emergencyDrillConfig: FormConfig[] = [
    {
        formField: {
            scheme: 'half',
            name: 'trainingPreparedness.emergencyDrill.scheduledEvent',
            type: 'datepicker',
            label: 'Create event',
            isRequired: true,
        },
        renderProps: {
            isDisabled: false,
            size: 'small',
            format: 'MMM dd, yyyy',
        },
    },
    {
        formField: {
            scheme: 'half',
            name: 'trainingPreparedness.emergencyDrill.attendees',
            type: 'autocomplete',
            label: 'Who should attend?',
            validation: composeValidators(
                maxLengthForArrayField(256),
                minLengthForArrayField(1),
            ),
        },
        renderProps: {
            freeSolo: true,
            isDisabled: false,
            size: 'small',
            options: [],
        },
    },
];
const frequencyConfig: FormConfig[] = [
    {
        formField: {
            scheme: 'quarter',
            name: 'trainingPreparedness.reviewFrequency.days',
            type: 'select',
            label: 'Who should attend?',
        },
        renderProps: {
            isDisabled: false,
            size: 'small',
            options: frequencyOptions,
        },
    },
    {
        formField: {
            scheme: 'quarter',
            name: 'trainingPreparedness.reviewFrequency.exactDate',
            type: 'datepicker',
            label: 'Set an Exact Date',
            isRequired: true,
        },
        renderProps: {
            isDisabled: false,
            size: 'small',
            format: 'MMM dd, yyyy',
        },
    },
];
