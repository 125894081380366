import React from 'react';
import { Box, Button } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
interface OwnProps {
    view: string;
    handleView: (newView: string) => void;
}
export default function ToggleButtons({ view, handleView }: OwnProps) {
    const { classes } = useStyles();
    return (
        <Box display={'flex'}>
            <Button
                className={classes.buttonLeft}
                disableRipple
                variant={view === 'completed' ? 'contained' : 'outlined'}
                onClick={() => handleView('completed')}>
                Completed
            </Button>
            <Button
                className={classes.buttonRight}
                disableRipple
                variant={view === 'in_progress' ? 'contained' : 'outlined'}
                onClick={() => handleView('in_progress')}>
                In Progress
            </Button>
        </Box>
    );
}
const useStyles = makeStyles()(() => ({
    mask: {
        width: 100,
        height: 40,
        background: '#8400ed',
        position: 'absolute',
        boxShadow: '1px 0 2px #b473ff',
        transition: 'all 0.5s ease',
    },
    buttonLeft: {
        transition: 'all 0.2s 0.1s ease',
        borderRadius: '8px 0px 0px 8px',
    },
    buttonRight: {
        transition: 'all 0.2s 0.1s ease',
        borderRadius: '0px 8px 8px 0px',
    },
}));
