import React from 'react';
import { Box } from '@mui/material';
import WizardForm from '../../design/Forms/WizardForm';
import theme from '../../styles/theme';
import ProgressBarWithPercentage from '../../design/ProgressBarWithPercentage';
import EmergencyContactsForm from './EmergencyContactPage/EmergencyContactsForm';
import RiskAssessmentForm from './RiskAssessmentForm';
import CriticalResourcesProcessesForm from './CriticalResourcesProcessesForm';
import EvacuationSafetyForm from './EvacuationSafetyForm';
import CommunicationForm from './CommunicationForm';
import CropProtectionForm from './CropProtectionForm';
import arrayMutators from 'final-form-arrays';
import ResourceManagementForm from './ResourceManagementForm';
import RecoveryProceduresForm from './RecoveryProceduresForm';
import EnvironmentalConcernsForm from './EnvironmentalConcernsForm';
import InsuranceFinancialPreparednessForm from './InsuranceFinancialPreparednessForm';
import ExternalAssistanceForm from './ExternalAssistanceForm';
import TrainingPreparednessForm from './TrainingPreparednessForm';
import ContinuityOfOperationsForm from './ContinuityOfOperationsForm';

interface OwnProps {
    pageNumber: number;
    setPageNumber: (pageNumber: number) => void;
    totalSteps: number;
}

export default function DisasterPlanningForm({
    pageNumber,
    setPageNumber,
    totalSteps,
}: OwnProps) {
    return (
        <Box
            display={'flex'}
            flexDirection={'column'}
            bgcolor={theme.palette.neutralWhite}>
            <ProgressBarWithPercentage
                color={theme.palette.statusYellow}
                percentage={(pageNumber / totalSteps) * 100}
                label={`${pageNumber} of ${totalSteps}`}
            />
            <WizardForm
                isLoading={false}
                onSubmit={() =>
                    /* eslint-disable-next-line @typescript-eslint/no-empty-function */
                    {}
                }
                mutators={arrayMutators as never}
                setStep={setPageNumber}
                step={pageNumber}>
                <EmergencyContactsForm />
                <RiskAssessmentForm />
                <CriticalResourcesProcessesForm />
                <EvacuationSafetyForm />
                <CommunicationForm />
                <CropProtectionForm />
                <ResourceManagementForm />
                <RecoveryProceduresForm />
                <EnvironmentalConcernsForm />
                <InsuranceFinancialPreparednessForm />
                <ExternalAssistanceForm />
                <TrainingPreparednessForm />
                <ContinuityOfOperationsForm />
            </WizardForm>
        </Box>
    );
}
