import { NodeDatum } from './TreeCard';
import { Box, Divider } from '@mui/material';
import BaseModal from '../../../reusable/BaseModal';
import theme from '../../../styles/theme';
import { Form } from 'react-final-form';

import React from 'react';
import FormPage from '../../../reusable/FormUI/FormPage';
import { FormConfig } from '../../../design/Forms/interfaces';
import { composeValidators, maxLengthOfArray } from '../../../utils/validation';
import { Option } from '../../CreateLeaseForm/WizardPageConfigs/leaseUploadConfig';
import useHandleSubmitRTK from '../../../hooks/useHandleSubmit';
import { useUpdateLabelsMutation } from '../../../api/landpartners/landPartnersAPI';
import { makeStyles } from 'tss-react/mui';
import TextWithTitle from '../../../reusable/TextWithTitle';
import UpdateLablesModalButtons from './UpdateLablesModalButtons';

interface OwnProps {
    nodeDatum: NodeDatum;
    isShowing: boolean;
    landPartnerId?: string;
    onClose: () => void;
}

export default function UpdateLabelModal({
    nodeDatum,
    isShowing,
    landPartnerId,
    onClose,
}: OwnProps) {
    const { classes } = useStyles();
    const { handleSubmit: handleLabelUpdate, isLoading } = useHandleSubmitRTK({
        useRtkHook: useUpdateLabelsMutation,
        onSuccess: () => onClose(),
        successMessage: 'Labels updated successfully',
    });

    function handleSubmit(values: NodeDatum) {
        handleLabelUpdate({
            contactId: nodeDatum?.id,
            landPartnerId,
            labels: values.labels.map((label) =>
                typeof label === 'string' ? label : label.id,
            ),
        });
    }

    const initialValues = { labels: nodeDatum?.labels };
    return (
        <BaseModal
            size={'xSmall'}
            isShowing={isShowing}
            onClose={onClose}
            header={'Change Profile Label'}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <Form onSubmit={handleSubmit} initialValues={initialValues}>
                {({ handleSubmit }) => (
                    <form className={classes.form} onSubmit={handleSubmit}>
                        <Divider orientation={'horizontal'} />
                        <Box
                            width={'100%'}
                            display={'flex'}
                            rowGap={2}
                            p={theme.spacing(2, 1.5)}
                            flexDirection={'column'}
                            bgcolor={theme.palette.backgroundB.backgroundB1}>
                            <TextWithTitle
                                title={'Name'}
                                value={`${nodeDatum?.firstName} ${nodeDatum?.lastName}`}
                            />
                            <FormPage formConfig={formConfig} />
                        </Box>
                        <Divider orientation={'horizontal'} />
                        <UpdateLablesModalButtons
                            onClose={onClose}
                            isLoading={isLoading}
                        />
                    </form>
                )}
            </Form>
        </BaseModal>
    );
}
const initialLabels: Option[] = [
    { id: 'Father', name: 'Father' },
    { id: 'Mother', name: 'Mother' },
    { id: 'Son', name: 'Son' },
    { id: 'Daughter', name: 'Daughter' },
    { id: 'Aunt', name: 'Aunt' },
    { id: 'Uncle', name: 'Uncle' },
    { id: 'Sister', name: 'Sister' },
    { id: 'Brother', name: 'Brother' },
    { id: 'Spouse', name: 'Spouse' },
    { id: 'Husband', name: 'Husband' },
    { id: 'Wife', name: 'Wife' },
    { id: 'Grandfather', name: 'Grandfather' },
    { id: 'Grandmother', name: 'Grandmother' },
    { id: 'Cousin', name: 'Cousin' },
    { id: 'Nephew', name: 'Nephew' },
    { id: 'Niece', name: 'Niece' },
    { id: 'Half-Brother', name: 'Half-Brother' },
    { id: 'Half-Sister', name: 'Half-Sister' },
];
const formConfig: FormConfig[] = [
    {
        formField: {
            name: 'labels',
            type: 'autocomplete',
            label: 'Choose Labels',
            isRequired: false,
            validation: composeValidators(maxLengthOfArray(2)),
            tooltip: {
                text: 'Select and start typing to find contact type',
                position: 'top',
            },
        },
        renderProps: {
            freeSolo: true,
            isDisabled: false,
            size: 'small',
            options: initialLabels,
        },
    },
];

const useStyles = makeStyles()(() => ({
    form: {
        width: '100%',
    },
}));
