import React, { ReactNode } from 'react';
import { Box, Stack, Typography, useTheme } from '@mui/material';

interface OwnProps {
    title?: string;
    children: ReactNode;
}
export default function SidebarItemsWrapper({ title, children }: OwnProps) {
    const theme = useTheme();
    return (
        <Stack rowGap={1}>
            <Typography variant="font16" fontWeight="bold">
                {title}
            </Typography>
            <Box
                display={'flex'}
                flexDirection={'column'}
                width={'100%'}
                gap={1}
                sx={{
                    padding: theme.spacing(2),
                    backgroundColor: theme.palette.neutralWhite,
                    borderRadius: 6,
                }}>
                {children}
            </Box>
        </Stack>
    );
}
