import React, { useEffect, useState } from 'react';
import { Grid, Stack, Typography } from '@mui/material';
import SelectField from '../../../design/Fields/SelectField';

import { useGetOrganizationsQuery } from '../../../api/users/organizationsAPI';
import Loader from '../../../design/BaseLoader';
import { useLazyGetNotificationsSettingsQuery } from '../../../api/notifications/notificationAPI';

import NotificationsSettingsList from './NotificationsSettingsList';
import useBreadcrumbs from '../../../hooks/useBreadcrumbs';
import Breadcrumbs from '../../../design/Breadcrumbs/Breadcrumbs';

export interface SettingsForNotification {
    id: string;
    name: string;
    description: string;
    enabled: boolean;
    channels: string[];
    frequency: Frequency;
    organizationId: string;
    default: boolean;
}

export interface EmailDigestSettings {
    id: string;
    name: string;
    dailyDigest: boolean;
    dailyDigestTime: 'EVENING' | 'MORNING';
}
export interface Frequency {
    firstNotificationDaysBefore: string;
    secondNotificationDaysBefore: string;
}
const routes = {
    '/settings/notifications-settings': 'Notifications Settings',
};
export default function NotificationsSettings() {
    const { data: initData, isLoading: isOrganizationsLoading } =
        useGetOrganizationsQuery({
            sort: 'name,ASC',
            page: 0,
            size: 1000,
        });

    const organizationData = initData?.data;
    const breadcrumbs = useBreadcrumbs({
        homePath: '/settings/orgAndUsers',
        homePathName: 'Settings',
        routes,
    });
    const [
        getItems,
        {
            data: notificationsData,
            isLoading: isNotificationsDataLoading,
            isFetching,
        },
    ] = useLazyGetNotificationsSettingsQuery();

    const [organization, setOrganization] = useState<string | undefined>(
        organizationData?.[0].id || '',
    );

    useEffect(() => {
        initData && setOrganization(organizationData?.[0].id);
        initData && getItems(organizationData?.[0].id);
    }, [initData, getItems]);

    if (isOrganizationsLoading) {
        return (
            <Grid
                width={'100%'}
                height={'100vh'}
                container
                alignItems={'center'}
                justifyContent={'center'}>
                <Loader />
            </Grid>
        );
    }
    return (
        <Stack spacing={2}>
            <Breadcrumbs collapsed={false} links={breadcrumbs} />
            <Typography variant="font20">Notifications</Typography>
            <Grid container flexDirection={'column'} width={'100%'}>
                <Grid
                    container
                    item
                    xs={12}
                    alignItems={'center'}
                    justifyContent={'flex-end'}
                    display={'flex'}
                    columnGap={2}>
                    Organization:
                    <Grid
                        item
                        xs={3}
                        alignItems={'center'}
                        display={'flex'}
                        columnGap={2}>
                        <SelectField
                            size={'small'}
                            onChange={(value) => {
                                setOrganization(value);
                                getItems(value);
                            }}
                            fullWidth={true}
                            value={organization}
                            placeholder={'Select Organization'}
                            options={organizationData}
                        />
                    </Grid>
                </Grid>
                {organization && (
                    <NotificationsSettingsList
                        organizationId={organization}
                        notificationsData={notificationsData}
                        isLoading={isNotificationsDataLoading || isFetching}
                    />
                )}
            </Grid>
        </Stack>
    );
}
