import { Table } from '../../design/GenericTable/GenericTable';
import { Expenses } from './ExpensesDetailForm';
import { Typography } from '@mui/material';
import React from 'react';
import TableActions from '../../design/GenericTable/TableActions';
interface OwnProps {
    handleDelete?: (id: string) => void;
    handleEdit?: (id: string) => void;
    isActionsHidden?: boolean;
}
export const tableExpensesConfig = ({
    handleEdit,
    handleDelete,
    isActionsHidden = false,
}: OwnProps): Table<Expenses> => {
    return {
        columns: [
            {
                header: {
                    label: 'Description',
                },
                cellRender: (tableData) => (
                    <Typography
                        variant={'font14'}
                        color={'text.link'}
                        fontWeight={'medium'}>
                        {tableData.description}
                    </Typography>
                ),
                format: { align: 'left', color: { color: 'primary' } },
                width: 'auto',
            },
            {
                header: {
                    label: 'Landowner %',
                },
                cellRender: (tableData) => (
                    <Typography
                        variant={'font14'}
                        color={'text.link'}
                        fontWeight={'medium'}>
                        {tableData.landownerSharePercent &&
                            `${tableData.landownerSharePercent} %`}
                    </Typography>
                ),
                format: { align: 'left', color: { color: 'primary' } },
                width: 'auto',
            },
            {
                header: {
                    label: 'Grower %',
                },
                cellRender: (tableData) => (
                    <Typography
                        variant={'font14'}
                        color={'text.link'}
                        fontWeight={'medium'}>
                        {tableData.growerSharePercent &&
                            `${tableData.growerSharePercent} %`}
                    </Typography>
                ),
                format: { align: 'left', color: { color: 'primary' } },
                width: 'auto',
            },
            {
                header: {
                    label: 'Actions',
                    sortable: false,
                    isHidden: isActionsHidden,
                },
                cellRender: (tableData) => (
                    <TableActions
                        onDelete={() =>
                            handleDelete && handleDelete(tableData?.tableId)
                        }
                        onEdit={() =>
                            handleEdit && handleEdit(tableData?.tableId)
                        }
                    />
                ),
                isHidden: isActionsHidden,
                format: { align: 'left', color: { color: 'primary' } },
                width: 'auto',
            },
        ],
        multiselect: false,
        pageable: false,
        expandable: false,
    };
};
