import React, {
    ComponentProps,
    ComponentType,
    createElement,
    useState,
} from 'react';

import { Box } from '@mui/material';
import TwoColumnLayout from '../../layouts/TwoColumnLayout';
import LeaseWizardFixedForm from './LeaseWizardFixedForm';
import Stepper from '../../design/Stepper/Stepper';
import PATHS from '../../router/paths';
import { StepProps } from '../../design/Stepper/CustomStep';
import { Navigate, useLocation, useParams } from 'react-router-dom';
import LeaseWizardCropForm from './LeaseWizardCropForm';
import LeaseWizardFlexForm from './LeaseWizardFlexForm';

export type LeaseType = 'FIXED' | 'CROPSHARE' | 'FLEX';
export default function LeaseWizard() {
    const { state } = useLocation();
    const { id } = useParams();
    const isEdit = !!id;
    const [pageNumber, setPageNumber] = useState(0);
    const [leaseType, setLeaseType] = useState<LeaseType>(state.type);
    const [isValid, setIsValid] = useState(false);
    const [isNavigable, setIsNavigable] = useState(false);

    if (!leaseType) {
        return <Navigate to={`/${PATHS.landPortfolio}/${PATHS.manage}`} />;
    }
    const formComponent = formMapper[leaseType];

    if (!formComponent) {
        return <div>Form not found for the selected LeaseType.</div>;
    }
    return (
        <Box m={1} bgcolor={'white'}>
            <TwoColumnLayout
                header={`${isEdit ? 'Edit' : 'Create'} a Lease: ${
                    headerTitle[leaseType][pageNumber]
                }`}
                type={'third'}
                leftComponent={
                    <Box p={4}>
                        <Stepper
                            isNavigable={isValid && isNavigable && isEdit}
                            key={leaseType}
                            setActiveStep={setPageNumber}
                            activeStep={pageNumber}
                            steps={stepMapper[leaseType]}
                        />
                    </Box>
                }
                rightComponent={createElement(formComponent, {
                    setLeaseType,
                    pageNumber,
                    setPageNumber,
                    setIsValid,
                    setIsNavigable,
                } as ComponentProps<typeof formComponent>)}
            />
        </Box>
    );
}

const headerTitle = {
    FIXED: ['Lease Basic', 'Land and Other Details', 'Summary'],
    CROPSHARE: [
        'Lease Basic',
        'Land and Other Details',
        'Crop Share',
        'Expenses Details',
        'Summary',
    ],
    FLEX: ['Lease Basic', 'Land and Other Details', 'Bonus Details', 'Summary'],
};

const flexSteps = [
    { name: 'Step 1: Upload', description: '' },
    { name: 'Step 2: Lease Detail', description: '' },
    { name: 'Step 3: Bonus', description: '' },
    { name: 'Step 4: Review', description: '' },
];
const cropShareStep = [
    { name: 'Step 1: Upload', description: '' },
    { name: 'Step 2: Lease Detail', description: '' },
    { name: 'Step 3: Crop Split', description: '' },
    { name: 'Step 4: Expenses', description: '' },
    { name: 'Step 5: Review', description: '' },
];
const fixedStep = [
    { name: 'Step 1: Upload', description: '' },
    { name: 'Step 2: Lease Detail', description: '' },
    { name: 'Step 3: Review', description: '' },
];

const stepMapper: Record<LeaseType, StepProps[]> = {
    FIXED: fixedStep,
    CROPSHARE: cropShareStep,
    FLEX: flexSteps,
};
// eslint-disable-next-line
const formMapper: Record<LeaseType, ComponentType<any>> = {
    FIXED: LeaseWizardFixedForm,
    CROPSHARE: LeaseWizardCropForm,
    FLEX: LeaseWizardFlexForm,
};
