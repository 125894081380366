import React from 'react';
import {
    Box,
    Step,
    StepLabel,
    StepProps as MUIStepProps,
    Typography,
} from '@mui/material';
import CustomStepIcon from './CustomStepIcon';
import { makeStyles } from 'tss-react/mui';
import theme from '../../styles/theme';

export interface StepProps {
    name?: string;
    description?: string;
    onClickStep?: (step: number) => void;
    isNavigable?: boolean;
}
interface OwnProps extends StepProps, MUIStepProps {
    isActive?: boolean;
    isCompleted?: boolean;
    completed?: boolean;
}
export default function CustomStep({
    name,
    description,
    onClickStep,
    isNavigable,
    isActive,
    isCompleted,
    ...props
}: OwnProps) {
    const { classes } = useStyles();

    return (
        <Step
            {...props}
            className={isNavigable ? classes.navigableStep : undefined}
            onClick={
                isNavigable
                    ? () => {
                          onClickStep && onClickStep(props.index as number);
                      }
                    : undefined
            }>
            <StepLabel
                className={classes.stepLabel}
                StepIconComponent={CustomStepIcon}>
                <Box display={'flex'} flexDirection={'column'}>
                    <Typography
                        sx={{
                            color:
                                isActive || isCompleted
                                    ? theme.palette.brandDarkTurq
                                    : theme.palette.neutral.neutral4,
                        }}
                        variant={'font20'}
                        fontWeight={'medium'}>
                        {name}
                    </Typography>
                    <Typography variant={'font12'}>{description}</Typography>
                </Box>
            </StepLabel>
        </Step>
    );
}

const useStyles = makeStyles()(() => ({
    stepLabel: {
        '& .MuiStepLabel-label': {
            fontSize: 20,
            fontWeight: 600,
        },
    },
    navigableStep: {
        color: theme.palette.brandLightTurq,
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
        },
    },
}));
