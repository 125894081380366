import React, { useState } from 'react';
import TwoColumnLayout from '../../../layouts/TwoColumnLayout';
import ArchiveTables from './ArchiveTables';
import { SelectedRowContext } from './SelectedRowContext';
import { LandPartnerArchive } from './LandPartners/useTableArchiveLPConfig';
import { LeaseArchive } from './Leases/useTableArchiveLeaseConfig';
import { ContactArchive } from './Contacts/useTableArchiveContactConfig';
import PreviewInfo from './PreviewInfo';
import Breadcrumbs from '../../../design/Breadcrumbs/Breadcrumbs';
import useBreadcrumbs from '../../../hooks/useBreadcrumbs';
import { ArchivedContactNoteData } from './ContactNotes/useTableArchivedContactNotesConfig';
import { ArchivedLeaseNoteData } from './LeaseNotes/useTableArchivedLeaseNotesConfig';

export default function ArchiveObjects() {
    const [selectedRow, setSelectedRow] = useState<
        | LandPartnerArchive
        | LeaseArchive
        | ContactArchive
        | ArchivedContactNoteData
        | ArchivedLeaseNoteData
        | null
    >(
        {} as
            | LandPartnerArchive
            | LeaseArchive
            | ContactArchive
            | ArchivedContactNoteData
            | ArchivedLeaseNoteData
            | null,
    );
    const routes = {
        'generalManagerSettings/archive/': 'Archive',
    };
    const breadcrumbs = useBreadcrumbs({
        routes,
        homePathName: 'Settings',
        homePath: '/generalManagerSettings',
    });
    return (
        <SelectedRowContext.Provider value={{ selectedRow, setSelectedRow }}>
            <Breadcrumbs collapsed={false} links={breadcrumbs} />
            <TwoColumnLayout
                type={'twoThirds'}
                leftComponent={<ArchiveTables />}
                rightComponent={<PreviewInfo />}
            />
        </SelectedRowContext.Provider>
    );
}
