import PaymentOverviewTabs from './PaymentOverviewTabs';
import PaymentOverviewWidgets from './PaymentOverviewWidgets';
import { Stack } from '@mui/material';
import React from 'react';

export default function PaymentOverview() {
    return (
        <Stack direction={'column'} useFlexGap={true} spacing={2}>
            <PaymentOverviewWidgets />
            <PaymentOverviewTabs />
        </Stack>
    );
}
