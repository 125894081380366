import React from 'react';
import { useGetOneContactQuery } from '../../api/contacts/contactsAPI';
import { ReactComponent as PhoneIcon } from '../../assets/icons/phone_icon.svg';
import { Box, Link } from '@mui/material';
import Loader from '../../design/BaseLoader';
import useAvatar from '../../hooks/useAvatar';
import AppTypography from '../../design/AppTypography';
import { LandPartner } from '../../features/CreateLandPartner/LandPartnerWizard';
import UserAvatar from '../../design/Avatar/UserAvatar';
import { PhoneType } from '../FormUI/PhoneNumberField';
import InfoWithLabel from '../InfoWithLabel';
import { phoneMaskForString } from '../../utils/fieldMasks';

interface OwnProps {
    contactId?: string;
    isHidden: boolean;
}

export default function ContactInfoBox({ contactId, isHidden }: OwnProps) {
    const { data, isLoading } = useGetOneContactQuery(contactId);
    const { avatarUrl } = useAvatar(
        data?.avatar?.contactAvatar,
        data?.avatar?.fileType,
    );
    if (isHidden) {
        return null;
    }

    if (isLoading) {
        return (
            <Box width={'100%'} display={'flex'} justifyContent={'center'}>
                <Loader />
            </Box>
        );
    }

    return (
        <Box
            display={'flex'}
            width={'100%'}
            columnGap={2}
            justifyContent={'center'}>
            <UserAvatar
                avatar={avatarUrl}
                size={'medium'}
                name={`${data?.firstName} ${data?.lastName}`}
            />
            <Box
                display={'flex'}
                flexDirection={'column'}
                rowGap={1}
                justifyContent={'center'}>
                <Box display={'flex'} columnGap={1}>
                    <AppTypography
                        variant={'font16'}
                        fontWeight="regular"
                        color="text.link"
                        text={`${data?.firstName} ${data?.lastName}`}
                    />
                    {data?.landPartners.map((landPartner: LandPartner) => (
                        <AppTypography
                            key={landPartner.id}
                            variant={'font16'}
                            fontWeight="regular"
                            color="text.neutral7"
                            text={landPartner.name}
                        />
                    ))}
                </Box>
                <Box display={'flex'} columnGap={1}>
                    {data?.contactNumbers.map((contactNumber: PhoneType) => (
                        <InfoWithLabel
                            key={contactNumber.type}
                            icon={PhoneIcon}
                            text={
                                <Link
                                    sx={{
                                        textDecoration: 'none',
                                        cursor: 'pointer',
                                    }}
                                    href={`tel:${contactNumber.number}`}
                                    color="inherit">
                                    {phoneMaskForString(contactNumber.number)}
                                </Link>
                            }
                        />
                    ))}
                </Box>
            </Box>
        </Box>
    );
}
