import { FormConfig } from '../../../../design/Forms/interfaces';
import {
    composeValidators,
    isEmail,
    isNumber,
    maxLength,
    notMoreThan,
    OnlyDigitsAndSpecialChars,
    required,
} from '../../../../utils/validation';
import { usaStates } from '../../../../utils/vocabulary';
import {
    organizationTypeOptions,
    statusOptions,
} from '../config/organizationEnums';

export const formOrganizationConfig: FormConfig[] = [
    {
        formField: {
            scheme: 'half',
            name: 'name',
            type: 'input',
            label: 'Organization Name',
            isRequired: true,
            validation: composeValidators(required, maxLength(256)),
        },
        renderProps: {
            placeholder: '',
            isDisabled: false,
            size: 'small',
        },
    },
    {
        formField: {
            scheme: 'half',
            name: 'type',
            type: 'select',
            label: 'Organization Type',
            isRequired: true,
            validation: composeValidators(required),
        },
        renderProps: {
            placeholder: '',
            isDisabled: false,
            size: 'small',
            options: organizationTypeOptions,
        },
    },
    {
        formField: {
            scheme: 'half',
            name: 'primaryContact.firstName',
            type: 'input',
            label: 'Primary Contact First Name',
            isRequired: true,
            validation: composeValidators(required, maxLength(256)),
        },
        renderProps: {
            isDisabled: false,
            size: 'small',
        },
    },
    {
        formField: {
            scheme: 'half',
            name: 'primaryContact.lastName',
            type: 'input',
            label: 'Primary Contact Last Name',
            isRequired: true,
            validation: composeValidators(required, maxLength(256)),
        },
        renderProps: {
            isDisabled: false,
            size: 'small',
        },
    },
    {
        formField: {
            scheme: 'half',
            name: 'primaryAddress.address1',
            type: 'input',
            label: 'Address Line 1',
            validation: composeValidators(maxLength(256)),
        },
        renderProps: {
            placeholder: 'Street',
            isDisabled: false,
            size: 'small',
        },
    },
    {
        formField: {
            scheme: 'half',
            name: 'primaryAddress.address2',
            type: 'input',
            label: 'Address Line 2',
            validation: composeValidators(maxLength(256)),
        },
        renderProps: {
            placeholder: 'Street',
            isDisabled: false,
            size: 'small',
        },
    },
    {
        formField: {
            scheme: 'half',
            name: 'primaryAddress.city',
            type: 'input',
            label: 'City',
            validation: composeValidators(maxLength(256)),
        },
        renderProps: {
            placeholder: 'City',
            isDisabled: false,
            size: 'small',
        },
    },

    {
        formField: {
            scheme: 'half',
            name: 'primaryAddress.state',
            type: 'select',
            label: 'State',
        },
        renderProps: {
            placeholder: 'State',
            isDisabled: false,
            size: 'small',
            options: usaStates,
        },
    },
    {
        formField: {
            scheme: 'half',
            name: 'primaryAddress.zipCode',
            type: 'input',
            label: 'Zip Code/Postal Code',
            validation: composeValidators(
                OnlyDigitsAndSpecialChars(
                    'Only digits and special characters are allowed',
                ),
                maxLength(256),
            ),
        },
        renderProps: {
            placeholder: '00000',
            isDisabled: false,
            size: 'small',
        },
    },
    {
        formField: {
            scheme: 'half',
            name: 'primaryContact.primaryEmail',
            type: 'input',
            label: 'Primary Email Address',
            isRequired: true,
            validation: composeValidators(required, isEmail, maxLength(256)),
        },
        renderProps: {
            placeholder: 'Enter email address',
            isDisabled: false,
            size: 'small',
        },
    },

    {
        formField: {
            scheme: 'half',
            name: 'maxUsersCount',
            type: 'input',
            label: 'Maximum number of Users',
            validation: composeValidators(isNumber, notMoreThan(999)),
        },
        renderProps: {
            placeholder: 'Insert count',
            isDisabled: false,
            size: 'small',
        },
    },

    {
        formField: {
            scheme: 'half',
            name: 'status',
            type: 'select',
            label: 'Status',
            isRequired: true,
            validation: composeValidators(required),
        },
        renderProps: {
            placeholder: 'Select',
            isDisabled: false,
            size: 'small',
            options: statusOptions,
        },
    },
];
