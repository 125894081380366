import { Button, Typography } from '@mui/material';

import theme from '../../../../styles/theme';
import useStyles from './AllRoles.styles';

import useModal from '../../../../hooks/useModal';
import RoleList from './RoleList';
import AddOrganizationRole from '../AddRole/AddOrganizationRole';

export default function AllRoles() {
    const { classes } = useStyles(theme);
    const { isShowing, setIsShowing } = useModal();
    return (
        <div className={classes.allRolesWrapper}>
            <Typography variant={'font16'}>
                Modify access based on your teams roles, or modify permissions
                for specific users. [additional descriptive text to be
                determinded]
            </Typography>
            <div className={classes.controlPanel}>
                <Button
                    onClick={() => setIsShowing(true)}
                    color={'secondary'}
                    size={'medium'}>
                    Add Role +
                </Button>
            </div>
            <RoleList />
            <AddOrganizationRole
                isShowing={isShowing}
                setIsShowing={setIsShowing}
            />
        </div>
    );
}
