import { ReactNode } from 'react';
import { Box, Button, CircularProgress } from '@mui/material';

export interface ActionFilterButtons<T> {
    label?: string;
    variant?: 'text' | 'contained' | 'outlined';
    isLoading?: boolean;
    isDisabled?: boolean;
    startIcon?: ReactNode;
    endIcon?: ReactNode;
    type?: 'submit' | 'button';
    onClick?: (value: T | undefined) => void;
}
interface OwnProps<T> {
    buttons: ActionFilterButtons<T>[];
    values: T;
}
export default function ActionButtons<T>({ buttons, values }: OwnProps<T>) {
    return (
        <Box display={'flex'} columnGap={2} p={1}>
            {buttons.map((button, index) => (
                <Button
                    key={index}
                    startIcon={button.startIcon}
                    endIcon={button.endIcon}
                    disabled={button.isDisabled || button.isLoading}
                    variant={button.variant}
                    type={button.type}
                    onClick={() => button.onClick && button.onClick(values)}>
                    {!button.isLoading ? (
                        button.label
                    ) : (
                        <CircularProgress
                            size={30}
                            color="primary"
                            style={{ position: 'absolute' }}
                        />
                    )}
                </Button>
            ))}
        </Box>
    );
}
