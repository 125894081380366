import { Box } from '@mui/material';
import { FormConfig } from '../../design/Forms/interfaces';

import GenericFormInput from '../../design/FormFields/GenericFormInput';
import React from 'react';
import TaskListDocuments from './TaskListDocuments';
import { composeValidators } from '../../utils/validation';
import { invalidFileType, tooLargeFile } from '../../utils/fileValidation';
import { MAX_FILE_SIZE } from '../../constants';

export default function TaskDocuments() {
    return (
        <Box width={'100%'} display={'flex'} columnGap={2}>
            <Box width={'50%'} display={'flex'}>
                <GenericFormInput
                    {...formUploadDocConfig.formField}
                    {...formUploadDocConfig.renderProps}
                />
            </Box>
            <Box width={'50%'} display={'flex'}>
                <TaskListDocuments />
            </Box>
        </Box>
    );
}

const formUploadDocConfig: FormConfig = {
    formField: {
        scheme: 'full',
        name: 'documents',
        type: 'fileupload',
        validation: composeValidators(
            tooLargeFile(MAX_FILE_SIZE),
            invalidFileType([
                'image/png',
                'image/jpg',
                'image/jpeg',
                'image/bmp',
                'application/pdf',
            ]),
        ),
    },
    renderProps: {
        filesAccepted: ['.png', '.jpg', '.jpeg', '.bmp', '.pdf'],
        placeholder: 'Or select from your computer (Max size 10MB)',
        helperText: 'Drag supporting document here (optional)',
        multipleFiles: true,
        isDisabled: false,
        isHidden: false,
    },
};
