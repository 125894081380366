import React, { useEffect } from 'react';
import {
    Button,
    Divider,
    Grid,
    IconButton,
    Modal as MUIModal,
    Typography,
} from '@mui/material';
import { ReactComponent as CloseIcon } from '../../assets/icons/close_icon.svg';
import { Form } from 'react-final-form';
import SubmitButton from '../../design/Buttons/SubmitButton';
import { makeStyles } from 'tss-react/mui';
import TaskFormTabsUpdate from './TaskFormTabsUpdate';
import useHandleSubmitRTK from '../../hooks/useHandleSubmit';
import {
    useLazyGetOneTaskQuery,
    useUpdateTaskMutation,
} from '../../api/tasks/tasksAPI';
import { dateConverterFromUTC } from '../../utils/dateFormat';
import { TaskPriority, TaskRelatesToType } from './TasksTable';
import { Option } from '../CreateLeaseForm/WizardPageConfigs/leaseUploadConfig';
import { RelatesToType } from './TaskFormModalCreate';

interface OwnProps {
    id?: string;
    isShowing: boolean;
    onClose: () => void;
}

export interface TaskFormValue {
    name: string;
    dueDate: string;
    assignTo: string;
    priority: TaskPriority;
    description: string;
    relatesTo: {
        type: RelatesToType;
        id: Option;
    };
    relatesToName?: string;
    watchers?: { id: string; firstName: string; lastName: string }[];
    documents?: File[];
}

export default function TaskFormModalUpdate({
    id,
    isShowing,
    onClose,
}: OwnProps) {
    const { classes } = useStyles();

    const { handleSubmit: handleSubmitUpdate, isLoading: isUpdating } =
        useHandleSubmitRTK({
            useRtkHook: useUpdateTaskMutation,
            onSuccess: onClose,
            successMessage: 'Task updated successfully',
        });

    const [
        getItems,
        {
            data: taskData,
            isLoading: isTaskDataLoading,
            isFetching: isTaskDataFetching,
        },
    ] = useLazyGetOneTaskQuery();

    useEffect(() => {
        id && getItems(id);
    }, [id]);

    const initData = {
        ...taskData,
        assignTo: taskData?.assignTo?.id,
        dueDate: taskData?.dueDate ? new Date(taskData?.dueDate) : null,
        relatesTo: {
            type: taskData?.relatesTo?.type
                ? {
                      id: taskData?.relatesTo?.type,
                      name: relatesToMapper[
                          taskData?.relatesTo?.type as TaskRelatesToType
                      ],
                  }
                : null,
            id: {
                id: taskData?.relatesTo?.id,
                name: taskData?.relatesTo?.name,
            },
        },
        watchers: taskData?.watchers?.map(
            (watcher: { id: string; firstName: string; lastName: string }) => {
                return {
                    id: watcher.id,
                    name: `${watcher.firstName} ${watcher.lastName}`,
                };
            },
        ),
    };
    const handleSubmit = (values: TaskFormValue) => {
        const watchersId = values.watchers?.map((watcher) => watcher.id);
        const dueDateToStringinFormat = values.dueDate
            ? dateConverterFromUTC(values.dueDate)
            : null;
        const convertedData = {
            ...values,
            relatesTo: {
                type: values.relatesTo?.type?.id,
                id: values.relatesTo?.id?.id,
            },
            dueDate: dueDateToStringinFormat,
            watchers: watchersId,
        };
        handleSubmitUpdate(convertedData);
    };

    return (
        <MUIModal
            open={isShowing}
            onClose={onClose}
            className={classes.modalWrapper}>
            <Grid className={classes.modal}>
                <Grid className={classes.modalHeader}>
                    <Typography className={classes.modalHeaderTitle}>
                        {'Edit Task'}
                    </Typography>
                    <IconButton
                        className={classes.modalCloseButton}
                        onClick={() => onClose()}
                        size="large">
                        <CloseIcon />
                    </IconButton>
                </Grid>
                <Form initialValues={initData} onSubmit={handleSubmit}>
                    {({ handleSubmit }) => (
                        <form onSubmit={handleSubmit} className={classes.form}>
                            <Grid
                                container
                                columnSpacing={2}
                                className={classes.formContainer}>
                                <TaskFormTabsUpdate
                                    isLoading={isTaskDataLoading}
                                />
                            </Grid>
                            <Divider orientation="horizontal" />
                            <div className={classes.bottomContainer}>
                                <Button
                                    onClick={() => onClose()}
                                    color={'secondary'}>
                                    Cancel
                                </Button>

                                <SubmitButton
                                    isDisabled={
                                        isUpdating ||
                                        isTaskDataLoading ||
                                        isTaskDataFetching
                                    }
                                    variant={'text'}
                                    isLoading={
                                        isUpdating ||
                                        isTaskDataLoading ||
                                        isTaskDataFetching
                                    }
                                    text={'Save'}
                                />
                            </div>
                        </form>
                    )}
                </Form>
            </Grid>
        </MUIModal>
    );
}
const relatesToMapper: Record<TaskRelatesToType, string> = {
    LEASE: 'Lease',
    LAND_PARTNER: 'Land Partner',
};

const useStyles = makeStyles()((theme) => ({
    modalWrapper: {
        display: 'flex',
        position: 'fixed',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 1102,
    },
    modal: {
        display: 'flex',
        height: '90vh',
        flexDirection: 'column',
        alignItems: 'center',
        width: 950,
        zIndex: 100,
        background: theme.palette.background.default,
        position: 'relative',
        borderRadius: 12,
        padding: 2,
    },
    modalHeader: {
        height: 70,
        position: 'sticky',
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '24px',
        borderRadius: '12px 12px 0 0',
        backgroundColor: theme.palette.neutralWhite,
    },

    modalCloseButton: {
        padding: 0,
        color: theme.palette.background.default,
        cursor: 'pointer',
        border: 'none',
        width: '18px',
        height: '18px',
    },
    modalHeaderTitle: {
        fontWeight: 600,
        fontSize: '14px',
        fontFamily: 'Montserrat',
        color: theme.palette.neutral.neutral10,
    },
    form: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        padding: theme.spacing(1, 0),
    },
    formContainer: {
        height: 'calc(90vh - 170px)',
        width: '100%',
        backgroundColor: theme.palette.neutral.neutral1,
        padding: theme.spacing(4),
        marginLeft: theme.spacing(0),
        overflowY: 'auto',
        overflowX: 'hidden',
    },

    bottomContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        gap: '16px',
        height: 70,
        width: '100%',
        padding: theme.spacing(3),
        backgroundColor: theme.palette.neutralWhite,
    },
}));
