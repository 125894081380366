import { useFieldsTable } from './useFieldsTable';
import GenericTable from '../../design/GenericTable/GenericTable';

export default function FieldsTable() {
    const { data, tableConfig, totalCount } = useFieldsTable();
    return (
        <GenericTable
            data={data}
            tableConfig={tableConfig}
            totalCount={totalCount}
        />
    );
}
