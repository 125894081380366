import { FormConfig } from '../../design/Forms/interfaces';

import {
    composeValidators,
    isDateLessThanToday,
    isEmail,
    maxLength,
    required,
} from '../../utils/validation';
import { usaStates } from '../../utils/vocabulary';
import {
    advocateList,
    PreferredContactMethodOptions,
} from '../CreateContact/FormConfigs/contactDetailsConfig';

export const formMembersConfig = (
    name: string,
    preferredContactMethodOptions: PreferredContactMethodOptions[],
): FormConfig[] => [
    {
        formField: {
            scheme: 'half',
            name: `${name}.firstName`,
            type: 'input',
            label: 'First Name',
            isRequired: true,
            validation: composeValidators(required, maxLength(256)),
        },
        renderProps: {
            isDisabled: false,
            size: 'small',
        },
    },
    {
        formField: {
            scheme: 'half',
            name: `${name}.lastName`,
            type: 'input',
            label: 'Last Name',
            isRequired: true,
            validation: composeValidators(required, maxLength(256)),
        },
        renderProps: {
            isDisabled: false,
            size: 'small',
        },
    },
    {
        formField: {
            scheme: 'half',
            name: `${name}.primaryEmail`,
            type: 'input',
            label: 'Primary Email Address',
            validation: composeValidators(maxLength(256)),
        },
        renderProps: {
            isDisabled: false,
            size: 'small',
        },
    },
    {
        formField: {
            scheme: 'half',
            name: `${name}.secondaryEmail`,
            type: 'input',
            label: 'Secondary Email Address',
            validation: composeValidators(isEmail, maxLength(256)),
        },
        renderProps: {
            isDisabled: false,
            size: 'small',
        },
    },
    {
        formField: {
            scheme: 'half',
            name: `${name}.addresses[0].address1`,
            type: 'input',
            label: 'Address Line 1',
            validation: composeValidators(maxLength(256)),
        },
        renderProps: {
            isDisabled: false,
            size: 'small',
        },
    },
    {
        formField: {
            scheme: 'half',
            name: `${name}.addresses[0].address2`,
            type: 'input',
            label: 'Address Line 2',
            validation: composeValidators(maxLength(256)),
        },
        renderProps: {
            isDisabled: false,
            size: 'small',
        },
    },
    {
        formField: {
            scheme: 'half',
            name: `${name}.addresses[0].city`,
            type: 'input',
            label: 'City',
            validation: composeValidators(maxLength(256)),
        },
        renderProps: {
            isDisabled: false,
            size: 'small',
        },
    },
    {
        formField: {
            scheme: 'half',
            name: `${name}.addresses[0].state`,
            type: 'select',
            label: 'State',
        },
        renderProps: {
            placeholder: '',
            isDisabled: false,
            size: 'small',
            options: usaStates,
        },
    },
    {
        formField: {
            scheme: 'half',
            name: `${name}.addresses[0].zipCode`,
            type: 'input',
            label: 'Zip Code/Postal Code',
            validation: composeValidators(maxLength(256)),
        },
        renderProps: {
            isDisabled: false,
            size: 'small',
        },
    },
    {
        formField: {
            scheme: 'half',
            name: `${name}.preferredContactMethod`,
            type: 'radio',
            label: 'Preferred Contact Method',
        },
        renderProps: {
            size: 'half',
            options: preferredContactMethodOptions,
            isDisabled: false,
        },
    },
    {
        formField: {
            scheme: 'half',
            name: `${name}.advocate`,
            type: 'checkbox',
            label: 'Advocate',
            tooltip: {
                text: 'Advocate',
                position: 'top',
            },
        },
        renderProps: {
            size: 'half',
            options: advocateList,
            isDisabled: false,
        },
    },
    {
        formField: {
            scheme: 'half',
            name: `${name}.keyDates.birthDate`,
            type: 'datepicker',
            label: 'Birthday',
            validation: composeValidators(
                isDateLessThanToday('Date must be equal or less than today'),
            ),
        },
        renderProps: {
            placeholder: 'Birthday',
            isDisabled: false,
            size: 'small',
            format: 'MMM dd, yyyy',
        },
    },
    {
        formField: {
            scheme: 'half',
            name: `${name}.keyDates.dateOfDeath`,
            type: 'datepicker',
            label: 'Date of Death',
            validation: composeValidators(
                isDateLessThanToday('Date must be equal or less than today'),
            ),
        },
        renderProps: {
            placeholder: 'Enter Date of Death',
            isDisabled: false,
            size: 'small',
            format: 'MMM dd, yyyy',
        },
    },
    {
        formField: {
            scheme: 'half',
            name: `${name}.keyDates.anniversary`,
            type: 'datepicker',
            label: 'Anniversary',
        },
        renderProps: {
            placeholder: 'Anniversary',
            isDisabled: false,
            size: 'small',
            format: 'MMM dd, yyyy',
        },
    },
    {
        formField: {
            scheme: 'half',
            name: `${name}.keyDates.relationshipStartDate`,
            type: 'datepicker',
            label: 'Relationship Start Date',
        },
        renderProps: {
            placeholder: 'Relationship Start Date',
            isDisabled: false,
            size: 'small',
            format: 'MMM dd, yyyy',
        },
    },
    {
        formField: {
            scheme: 'half',
            name: `${name}.socialMedia.linkedin`,
            type: 'input',
            label: 'LinkedIn (Company)',
            validation: composeValidators(maxLength(256)),
        },
        renderProps: {
            placeholder: 'Enter LinkedIn Profile',
            isDisabled: false,
            size: 'small',
        },
    },
    {
        formField: {
            scheme: 'half',
            name: `${name}.socialMedia.twitter`,
            type: 'input',
            label: 'Twitter / X Profile (Company)',
            validation: composeValidators(maxLength(256)),
        },
        renderProps: {
            placeholder: 'Enter Twitter Profile',
            isDisabled: false,
            size: 'small',
        },
    },
    {
        formField: {
            scheme: 'half',
            name: `${name}.socialMedia.facebook`,
            type: 'input',
            label: 'Facebook (Company)',
            validation: composeValidators(maxLength(256)),
        },
        renderProps: {
            placeholder: 'Enter Facebook Profile',
            isDisabled: false,
            size: 'small',
        },
    },
    {
        formField: {
            scheme: 'half',
            name: `${name}.socialMedia.instagram`,
            type: 'input',
            label: 'Instagram (Company)',
            validation: composeValidators(maxLength(256)),
        },
        renderProps: {
            placeholder: 'Enter Instagram Profile',
            isDisabled: false,
            size: 'small',
        },
    },
];
