import palette from './palette';

const chipOverrides = {
    MuiChip: {
        styleOverrides: {
            root: {
                width: '120px',
                textTransform: 'capitalize',
                borderRadius: '30px',
                fontWeight: 500,
                fontSize: '11px',
                lineHeight: '14px',
                color: palette.neutral.neutral10,
            },
            colorError: {
                backgroundColor: palette.statusRedTint,
            },
            colorWarning: {
                backgroundColor: palette.statusYellow,
            },
            colorSuccess: {
                backgroundColor: palette.limeGreen.limeGreenShade2,
            },
            colorSecondary: {
                backgroundColor: palette.neutral.neutral4,
            },
        },
    },
};

export default chipOverrides;
