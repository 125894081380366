import FormScreenWrapper from './FormScreenWrapper';
import { Box } from '@mui/material';
import AppTypography from '../../design/AppTypography';
import FormPage from '../../reusable/FormUI/FormPage';
import { FormConfig } from '../../design/Forms/interfaces';
import { composeValidators } from '../../utils/validation';
import {
    invalidFileType,
    tooLargeFile,
    tooManyFiles,
} from '../../utils/fileValidation';
import { MAX_FILE_SIZE } from '../../constants';

export default function CommunicationForm() {
    return (
        <FormScreenWrapper title="Communication">
            <Box display={'flex'} flexDirection={'column'} rowGap={2}>
                <AppTypography
                    variant="font16"
                    fontWeight={'medium'}
                    text={
                        'What is the team communication plan during a disaster?'
                    }
                    color={'text.neutral9'}
                />
                <FormPage formConfig={teamCommunicationFormConfig} />
                <AppTypography
                    variant="font16"
                    fontWeight={'medium'}
                    text={
                        'How will you communicate with suppliers, and customers?'
                    }
                    color={'text.neutral9'}
                />{' '}
                <FormPage formConfig={supplierCommunicationFormConfig} />
            </Box>
        </FormScreenWrapper>
    );
}
const teamCommunicationFormConfig: FormConfig[] = [
    {
        formField: {
            scheme: 'twoThird',
            name: 'communicationPlan.teamCommunication',
            type: 'textarea',
            label: '',
        },
        renderProps: {
            resize: 'none',
            isDisabled: false,
            minRows: 3,
        },
    },
    {
        formField: {
            scheme: 'third',
            name: 'teamCommunicationFile',
            type: 'fileupload',
            validation: composeValidators(
                tooLargeFile(MAX_FILE_SIZE),
                tooManyFiles(1),
                invalidFileType([
                    'image/png',
                    'image/jpg',
                    'image/jpeg',
                    'image/bmp',
                    'application/pdf',
                ]),
            ),
        },
        renderProps: {
            filesAccepted: ['.png', '.jpg', '.jpeg', '.bmp', '.pdf'],
            placeholder: 'Drag a document here',
            helperText: 'Attach Document',
            multipleFiles: false,
            isDisabled: false,
        },
    },
];
const supplierCommunicationFormConfig: FormConfig[] = [
    {
        formField: {
            scheme: 'twoThird',
            name: 'communicationPlan.supplierCommunication',
            type: 'textarea',
            label: '',
        },
        renderProps: {
            resize: 'none',
            isDisabled: false,
            minRows: 3,
        },
    },
    {
        formField: {
            scheme: 'third',
            name: 'supplierCommunicationFile',
            type: 'fileupload',
            validation: composeValidators(
                tooLargeFile(MAX_FILE_SIZE),
                tooManyFiles(1),
                invalidFileType([
                    'image/png',
                    'image/jpg',
                    'image/jpeg',
                    'image/bmp',
                    'application/pdf',
                ]),
            ),
        },
        renderProps: {
            filesAccepted: ['.png', '.jpg', '.jpeg', '.bmp', '.pdf'],
            placeholder: 'Drag a document here',
            helperText: 'Attach Document',
            multipleFiles: false,
            isDisabled: false,
        },
    },
];
