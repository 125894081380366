import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material/styles/createTheme';
import '../../styles/palette';

export const useStyles = makeStyles<Theme>()((theme: Theme) => ({
    wrapper: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        padding: theme.spacing(3),
        backgroundColor: theme.palette.neutralWhite,
    },
    expandHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        padding: theme.spacing(0, 1),
        backgroundColor: theme.palette.backgroundB.backgroundB2,
    },
    expanded: {
        width: 24,
        height: 24,
        transform: 'rotate(180deg)',
    },
    titleWrapper: {
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: theme.palette.neutralWhite,
        justifyContent: 'space-between',
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    wrapperHistory: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(1),
        flex: 1,
        maxHeight: '60vh',
        overflowY: 'auto',
        width: '100%',
        padding: theme.spacing(1, 2),
        borderBottom: `solid 1px ${theme.palette.strokeS1}`,
        backgroundColor: theme.palette.backgroundB.backgroundB1,
    },
    wrapperHistoryView: {
        display: 'flex',
        flexDirection: 'column',
        flex: 2,
        gap: theme.spacing(1),
        overflowY: 'auto',
        width: '100%',
        padding: theme.spacing(1, 2),
        borderBottom: `solid 1px ${theme.palette.strokeS1}`,
        borderRadius: theme.spacing(1),
    },
    historyTitle: {
        paddingBottom: theme.spacing(3),
    },
}));
