import { Button, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';
import { makeStyles } from 'tss-react/mui';

import { ReactComponent as PdfIcon } from '../../assets/icons/file_pdf.svg';
import { ReactComponent as ExcelIcon } from '../../assets/icons/spreadsheet_icon.svg';
import { ReactComponent as ImageIcon } from '../../assets/icons/image_icon.svg';
import { extractExtensionFromFileName } from '../../utils/fileHandler';

interface OwnProps {
    file: File;
    onRemoveFile: (file: File) => void;
}
export default function FilePreview({ file, onRemoveFile }: OwnProps) {
    const { classes } = useStyles();

    return (
        <Typography
            key={file.name}
            className={classes.fileNameContainer}
            variant="font12"
            fontWeight={'medium'}
            color={'text.light'}>
            <IconMapper file={file} />
            {file.name}
            <Button
                color={'primary'}
                variant={'text'}
                onClick={() => onRemoveFile(file)}
                endIcon={<CloseIcon />}>
                Remove
            </Button>
        </Typography>
    );
}
const useStyles = makeStyles()((theme) => ({
    fileNameContainer: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: theme.spacing(1),
        gap: theme.spacing(1),
    },
}));

function IconMapper({ file }: { file: File }) {
    const extension = extractExtensionFromFileName(file.name);
    switch (extension) {
        case 'pdf':
            return <PdfIcon />;
        case 'xls':
        case 'xlsx':
            return <ExcelIcon />;
        case 'png':
        case 'jpg':
        case 'jpeg':
            return <ImageIcon />;
        default:
            return <PdfIcon />;
    }
}
