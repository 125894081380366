import React, { useState } from 'react';

import { Card, Divider, Fab, IconButton, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ActionTreeCardButton from './ActionTreeCardButton';

import { makeStyles } from 'tss-react/mui';
import clsx from 'clsx';

import { TreeNodeDatum } from 'react-d3-tree';
import useModal from '../../../hooks/useModal';
import AddRelationContact from './AddRelationContact';
import useAvatar from '../../../hooks/useAvatar';
import AddNotesModal from '../../../reusable/AddNotesModal';
import TreeCardAvatar from '../../../reusable/Tree/TreeCardAvatar';
import { ReactComponent as NotesIcon } from '../../../assets/icons/edit_notes_icon.svg';

import { useNavigate } from 'react-router-dom';
import PATHS from '../../../router/paths';
import RemoveTreeNode from './RemoveTreeNode';
import MakeContactPrimaryModal from './MakeContactPrimaryModal';
import UpdateLabelModal from './UpdateLableModal';
import { Option } from '../../CreateLeaseForm/WizardPageConfigs/leaseUploadConfig';

export interface TreeCardElement {
    id: string;
    firstName: string;
    lastName: string;
    name?: string;
    labels: Option[] | string[];
    isRoot?: boolean;
    relationship?: string;
    isDisabled?: boolean;
    primaryContact: boolean;
    avatar: TreeCardAvatar;
    landPartnerLogo?: LandPartnerLogo;
    children?: TreeCardElement[];
}

export interface TreeCardAvatar {
    fileName: string;
    fileType: string;
    contactAvatar: string;
}

export interface LandPartnerLogo {
    id: string;
    createdBy: string;
    updatedBy: string;
    createdAt: string;
    updatedAt: string;
    fileName: string;
    fileType: string;
    companyLogo: string;
    organizationId: string;
}

export type NodeDatum = TreeNodeDatum & TreeCardElement;

export interface OwnProps {
    nodeDatum: NodeDatum;
    contactIdForMarking?: string;
    landPartnerId?: string;
}

export default function TreeCard({
    nodeDatum,
    contactIdForMarking,
    landPartnerId,
}: OwnProps) {
    const { classes } = useStyles();
    const navigate = useNavigate();
    const [currentId, setCurrentId] = useState<string | undefined>();
    const isOrganization = !!nodeDatum?.name;
    const [updateLabelModal, setUpdateLabelModal] = useState(false);
    const { isShowing, setIsShowing } = useModal();
    const { isShowing: isDeleteModal, setIsShowing: setIsDeleteModal } =
        useModal();
    const {
        isShowing: isMakePrimaryModal,
        setIsShowing: setIsMakePrimaryModal,
    } = useModal();

    const [isNotesShowing, setIsNotesShowing] = useState(false);
    const handleNotesClose = () => {
        setCurrentId(undefined);
        setIsNotesShowing(false);
    };
    function handleUpdateLabelModal() {
        setUpdateLabelModal(true);
    }
    function handleCloseUpdateLabelModal() {
        setUpdateLabelModal(false);
    }
    const handleNotesOpen = () => {
        setCurrentId(nodeDatum.id);
        setIsNotesShowing(true);
    };

    const { avatarUrl: contactLogo } = useAvatar(
        nodeDatum.avatar?.contactAvatar,
        nodeDatum.avatar?.fileType,
    );
    const { avatarUrl: landPartnerLogo } = useAvatar(
        nodeDatum?.landPartnerLogo?.companyLogo,
        nodeDatum?.landPartnerLogo?.fileType,
    );
    const buttonMenuItems = [
        {
            label: 'Edit',
            onClick: () => {
                if (!nodeDatum?.isRoot) {
                    navigate(
                        `/${PATHS.landpartners}/${PATHS.allProfiles}/${nodeDatum.id}/${PATHS.update}`,
                        {
                            state: {
                                isTree: true,
                            },
                        },
                    );
                }
            },
        },
        {
            label: 'Change Label',
            onClick: handleUpdateLabelModal,
        },
    ];

    if (!nodeDatum?.isRoot && !nodeDatum?.primaryContact) {
        buttonMenuItems.push(
            {
                label: 'Remove',
                onClick: () => setIsDeleteModal(true),
            },
            {
                label: 'Make Primary',
                onClick: () => setIsMakePrimaryModal(true),
            },
        );
    }

    return (
        <>
            <g style={{ fill: 'none', stroke: 'none' }}>
                <foreignObject className={classes.treeNode}>
                    <div
                        className={clsx(
                            classes.container,
                            nodeDatum?.isDisabled && classes.disabled,
                        )}>
                        <TreeCardAvatar
                            isMarked={nodeDatum?.primaryContact}
                            avatar={landPartnerLogo || contactLogo}
                            name={
                                nodeDatum?.name ||
                                `${nodeDatum.firstName} ${nodeDatum.lastName}`
                            }
                        />
                        <Card
                            className={clsx(
                                classes.cardContainer,
                                contactIdForMarking === nodeDatum.id &&
                                    classes.markedCardContainer,
                                isOrganization &&
                                    classes.markedCardContainerAsOrg,
                            )}>
                            <div className={classes.actionButtonContainer}>
                                <IconButton onClick={handleNotesOpen}>
                                    {!isOrganization && <NotesIcon />}
                                </IconButton>
                                <ActionTreeCardButton
                                    items={buttonMenuItems}
                                    hidden={nodeDatum?.isRoot}
                                />
                            </div>
                            <Typography
                                variant={'font16'}
                                color={'text.secondary'}
                                fontWeight={'medium'}>
                                {nodeDatum.name ||
                                    `${nodeDatum.firstName} ${nodeDatum.lastName}`}
                            </Typography>
                            <Typography
                                variant={'font12'}
                                textTransform={'capitalize'}
                                color={'text.secondary'}>
                                {nodeDatum.labels &&
                                    nodeDatum?.labels.length > 0 &&
                                    nodeDatum?.labels.join(', ')}
                            </Typography>
                        </Card>
                        <Divider
                            orientation={'vertical'}
                            className={classes.divider}
                        />

                        <Fab
                            size="small"
                            className={classes.button}
                            onClick={() => {
                                setCurrentId(undefined);
                                setIsShowing(true);
                            }}>
                            <AddIcon />
                        </Fab>
                    </div>
                </foreignObject>
            </g>
            {isShowing && (
                <AddRelationContact
                    parentId={nodeDatum.id}
                    isShowing={isShowing}
                    onClose={() => setIsShowing(false)}
                />
            )}
            {isDeleteModal && (
                <RemoveTreeNode
                    hasChildren={!!nodeDatum.children?.length}
                    currentId={nodeDatum.id}
                    isShowing={isDeleteModal}
                    onClose={() => setIsDeleteModal(false)}
                />
            )}
            {isMakePrimaryModal && (
                <MakeContactPrimaryModal
                    currentId={nodeDatum.id}
                    isShowing={isMakePrimaryModal}
                    onClose={() => setIsMakePrimaryModal(false)}
                />
            )}
            {isNotesShowing && currentId && (
                <AddNotesModal
                    id={currentId}
                    contact={convertContactData(nodeDatum)}
                    isShowing={isNotesShowing}
                    onClose={handleNotesClose}
                />
            )}
            {updateLabelModal && (
                <UpdateLabelModal
                    landPartnerId={landPartnerId}
                    isShowing={updateLabelModal}
                    onClose={handleCloseUpdateLabelModal}
                    nodeDatum={nodeDatum}
                />
            )}
        </>
    );
}

const useStyles = makeStyles()((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        borderStyle: 'none',
    },
    disabled: {
        opacity: 0.4,
    },
    actionButtonContainer: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
    },

    divider: {
        height: '12px',
        color: theme.palette.brandLightTurq,
    },
    button: {
        color: theme.palette.neutralWhite,
        backgroundColor: theme.palette.brandLightTurq,
        '&:hover': {
            backgroundColor: theme.palette.brandDarkTurq,
        },
    },
    cardContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        backgroundColor: theme.palette.neutral.neutral2,
        padding: theme.spacing(0, 2, 2),
        borderRadius: '12px',
        borderStyle: 'none !important',
    },
    markedCardContainer: {
        backgroundColor: theme.palette.lightTurq.lightTurqTint5,
    },
    markedCardContainerAsOrg: {
        backgroundColor: theme.palette.lightTurq.lightTurqTint4,
    },
    treeNode: {
        width: 220,
        height: 250,
        y: -110,
        x: -110,
    },
}));

function convertContactData(item: NodeDatum) {
    return {
        id: item.id,
        avatar: item.avatar,
        firstName: item.firstName,
        lastName: item.lastName,
    };
}
