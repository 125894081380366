import { Theme } from '@mui/material/styles/createTheme';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles<Theme>()(() => ({
    allUsersWrapper: {
        padding: '32px 0',
    },
    controlPanel: {
        padding: '16px',
        gap: '16px',
        display: 'flex',
        justifyContent: 'flex-end',
    },
}));

export default useStyles;
