import React from 'react';
import { Box, Button, Divider } from '@mui/material';
import { FieldArray } from 'react-final-form-arrays';
import FormPage from '../reusable/FormUI/FormPage';
import { Add } from '@mui/icons-material';
import { useForm, useFormState } from 'react-final-form';
import {
    cropsTypeList,
    leaseCropSplitConfig,
} from './CreateLeaseForm/WizardPageConfigs/leaseCropSplitConfig';
import { CropShareDistribution } from '../pages/LandPortfolio/TableConfig';

export default function CropShareArrayForm() {
    const { mutators, change } = useForm();
    const { values } = useFormState();
    const cropsType = values?.cropShareDistributions?.map(
        (crop: CropShareDistribution) => crop?.crop,
    );

    const handleGrowerShareChange = (index: number, value: string) => {
        const landownerValue = isNaN(+value)
            ? 0
            : 100 - Math.min(Math.max(+value, 0), 100);
        change(
            `cropShareDistributions[${index}].landownerSharePercent`,
            landownerValue,
        );
    };
    const handleLandownerShareChange = (index: number, value: string) => {
        const growerValue = isNaN(+value)
            ? 0
            : 100 - Math.min(Math.max(+value, 0), 100);
        change(
            `cropShareDistributions[${index}].growerSharePercent`,
            growerValue,
        );
    };

    return (
        <Box
            display={'flex'}
            flexDirection={'column'}
            gap={2}
            p={2}
            width={'100%'}>
            <FieldArray name="cropShareDistributions">
                {({ fields }) => {
                    return fields.map((name, index) => {
                        return (
                            <Box
                                key={name}
                                display={'flex'}
                                gap={2}
                                alignItems={'flex-end'}>
                                <FormPage
                                    key={name}
                                    formConfig={leaseCropSplitConfig({
                                        name,
                                        cropsType,
                                        index,
                                        handleGrowerShareChange,
                                        handleLandownerShareChange,
                                    })}
                                />

                                <Button onClick={() => fields.remove(index)}>
                                    Remove
                                </Button>
                            </Box>
                        );
                    });
                }}
            </FieldArray>
            {((values.cropShareDistributions &&
                values.cropShareDistributions.length < cropsTypeList.length) ||
                !values.cropShareDistributions) && (
                <Button
                    startIcon={<Add />}
                    type="button"
                    onClick={() => mutators.push('cropShareDistributions')}>
                    Add
                </Button>
            )}
            <Divider />
        </Box>
    );
}
