import { IconButton, Menu, MenuItem } from '@mui/material';
import { useState, useRef, ReactNode } from 'react';
import { ActionMenuItem } from './ActionButton';

interface OwnProps {
    children: ReactNode;
    items: ActionMenuItem[];
    disabled?: boolean;
    hidden?: boolean;
}

export default function IconButtonsWithActions({
    children,
    items,
    hidden,
    disabled,
}: OwnProps) {
    const buttonRef = useRef<HTMLButtonElement>(null);
    const [isOpen, setIsOpen] = useState(false);

    if (hidden) return null;

    const handleToggle = () => {
        setIsOpen((prevOpen) => !prevOpen);
    };
    const handleClose = () => {
        setIsOpen(false);
    };
    return (
        <div>
            <IconButton
                ref={buttonRef}
                aria-haspopup="true"
                size={'medium'}
                disabled={disabled}
                onClick={handleToggle}>
                {children}
            </IconButton>
            <Menu
                anchorEl={buttonRef?.current}
                open={isOpen}
                onClose={handleClose}>
                {items.map(
                    ({ hidden, label, onClick }, index) =>
                        !hidden && (
                            <MenuItem
                                key={index}
                                onClick={() => {
                                    onClick();
                                    handleClose();
                                }}>
                                {label}
                            </MenuItem>
                        ),
                )}
            </Menu>
        </div>
    );
}
