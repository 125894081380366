import { Button, Typography } from '@mui/material';
import React from 'react';

import { useNavigate } from 'react-router-dom';

import useStyles from './LogIn.styles';
import theme from '../../../styles/theme';
import PATHS from '../../../router/paths';
import { ReactComponent as EditIcon } from '../../../assets/icons/edit_icon.svg';
import AddIcon from '@mui/icons-material/Add';
import { FormConfig } from '../../../design/Forms/interfaces';
import {
    composeValidators,
    isEmail,
    maxLength,
    required,
} from '../../../utils/validation';
import { Form } from 'react-final-form';
import FormPage from '../../../reusable/FormUI/FormPage';
import { useResetPasswordMutation } from '../../../api/auth/authAPI';
import SubmitButton from '../../../design/Buttons/SubmitButton';
import customToastWithAlert from '../../../design/CustomToastWithAlert';

interface submitValue {
    primaryEmail: string;
}
export default function ForgotPasswordForm() {
    const { classes } = useStyles(theme);
    const navigate = useNavigate();
    const [resetPassword, { isLoading }] = useResetPasswordMutation();
    function handleSubmit(values: submitValue) {
        resetPassword(values)
            .unwrap()
            .then(() => {
                return customToastWithAlert({
                    type: 'success',
                    message: 'Password reset link sent to your email',
                });
            })
            .catch((error) => {
                return customToastWithAlert({
                    type: 'error',
                    message: error?.data?.description || 'Something went wrong',
                });
            });
    }

    return (
        <div className={classes.formWrapper}>
            <Typography
                className={classes.title}
                variant="font32"
                fontWeight="regular">
                Forgot password?
            </Typography>
            <Typography className={classes.details}>
                It happens, but we can get you back quickly. Just enter your
                email below and within minutes you should get a link to reset in
                your inbox. If you still do not have an email please check your
                spam folder.
            </Typography>
            <Form onSubmit={handleSubmit}>
                {({ handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                        <FormPage formConfig={forgotPassword} />
                        <div className={classes.bottomContainer}>
                            <SubmitButton
                                startIcon={<EditIcon fill={'#ffffff'} />}
                                endIcon={<AddIcon />}
                                variant={'contained'}
                                text={'Email a recovery link'}
                                isLoading={isLoading}
                            />
                            <Button
                                startIcon={<EditIcon />}
                                endIcon={<AddIcon />}
                                onClick={() =>
                                    navigate(`/${PATHS.auth}/${PATHS.logIn}`)
                                }
                                variant={'text'}>
                                Login
                            </Button>
                        </div>
                    </form>
                )}
            </Form>
        </div>
    );
}

const forgotPassword: FormConfig[] = [
    {
        formField: {
            name: 'primaryEmail',
            type: 'input',
            label: 'Email address',
            isRequired: true,
            validation: composeValidators(required, maxLength(256), isEmail),
        },
        renderProps: {
            placeholder: '',
            isDisabled: false,
            size: 'small',
        },
    },
];
