import React from 'react';
import { ReactComponent as OAKENLogo } from '../assets/icons/logo2.svg';
import { Box, Typography } from '@mui/material';
interface OwnProps {
    title?: string;
}

export default function StubDummyComponent({ title }: OwnProps) {
    return (
        <Box
            p={3}
            display={'flex'}
            gap={2}
            height={'100%'}
            flexDirection={'column'}
            justifyContent={'center'}
            alignItems={'center'}>
            <OAKENLogo />
            <Typography
                variant={'font20'}
                fontWeight={'medium'}
                color={'text.secondaryContrast'}>
                {title}
            </Typography>
        </Box>
    );
}
