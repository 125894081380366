import { PotentialClient } from './usePotentialClientsTable';

export const potentialClientsMockData: PotentialClient[] = [
    {
        id: '1',
        name: 'Lewis Carroll',
        phone: '(917) 331-5555',
        email: 'lewiscalroll@farm.com',
        solution: 'Disaster Planning',
        lastContactedDate: '9/24/24, 20 days ago',
        notesCount: 2,
        attachmentCount: 1,
    },
    {
        id: '2',
        name: 'Alice Liddell',
        phone: '(555) 777-8899',
        email: 'lewiscalroll@farm.com',
        solution: 'Disaster Planning',
        lastContactedDate: '9/24/24, 20 days ago',
    },
    {
        id: '3',
        name: 'Charles Dodgson',
        phone: '(917) 331-5555',
        email: 'lewiscalroll@farm.com',
        solution: 'Disaster Planning',
        lastContactedDate: '9/25/2024, 38 days ago',
    },
    {
        id: '4',
        name: 'Frances Jane Lutwidge',
        phone: '(917) 331-5555',
        email: 'lewiscalroll@farm.com',
        solution: 'Disaster Planning',
        lastContactedDate: '9/25/2024, 38 days ago',
    },
    {
        id: '5',
        name: 'John Ruskin',
        phone: '(917) 331-5555',
        email: 'lewiscalroll@farm.com',
        solution: 'Disaster Planning',
        lastContactedDate: '9/25/2024, 38 days ago',
    },
];
