import React, { ReactNode } from 'react';
import { Button, Box, Typography } from '@mui/material';

import { makeStyles } from 'tss-react/mui';

interface OwnProps {
    pageNumber: number;
    children: ReactNode;
    label?: string;
    onClick: (page: number) => void;
}
export default function PreviewFormWrapper({
    pageNumber,
    children,
    onClick,
    label,
}: OwnProps) {
    const { classes } = useStyles();
    return (
        <div className={classes.viewWrapper}>
            <Box
                display={'flex'}
                width={'100%'}
                alignItems={'center'}
                justifyContent={'space-between'}>
                <Typography
                    variant={'font12'}
                    fontWeight={'bold'}
                    color={'text.secondary'}>
                    {label}
                </Typography>
                <Button variant={'text'} onClick={() => onClick(pageNumber)}>
                    Modify
                </Button>
            </Box>
            <div className={classes.itemsWrapper}>{children}</div>
        </div>
    );
}

const useStyles = makeStyles()((theme) => ({
    viewWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'space-between',
        alignItems: 'flex-end',
        flexWrap: 'wrap',
        width: '100%',
        backgroundColor: theme.palette.darkTurq.darkTurqTint1,
        padding: theme.spacing(2),
    },
    itemsWrapper: {
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%',
        justifyContent: 'space-between',
    },
}));
