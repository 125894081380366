import { FieldsData } from '../../../pages/Fields/useFieldsTable';
import TitleWithValue, { TitleWithValueProps } from '../TitleWithValue';
import { Stack } from '@mui/material';

interface OwnProps {
    data: FieldsData;
}

export default function FieldsDashboardOtherInfo({ data }: OwnProps) {
    return (
        <Stack maxHeight={270} flexWrap={'wrap'} rowGap={2} columnGap={9}>
            {convertFieldsDataToView(data).map((field) => (
                <TitleWithValue key={field.title} {...field} />
            ))}
        </Stack>
    );
}

function convertFieldsDataToView(data: FieldsData): TitleWithValueProps[] {
    return [
        { title: 'FSA ID', value: data.fsaId },
        { title: 'Landowner', value: data.landownerNames },
        { title: 'Lease', value: data.leaseName },
        { title: 'County FIPS', value: data.countyFips },
        { title: 'County', value: data.countyName },
        { title: 'Total Acres', value: data.totalAcres },
        { title: 'Rent Per Acre', value: data.rentPerAcre },
        { title: 'Tract/TFP', value: data.tractNumber.join(',') },
        { title: 'CLUID', value: data.cluid },
        { title: 'State', value: data.state },
        { title: 'Tillable', value: data.tillableAcres },
        { title: 'Total Annual Rent', value: data.totalAnnualRent },
        { title: 'State FIPS', value: data.stateFips },
        { title: 'Parcel ID', value: data.parcelId },
    ];
}
