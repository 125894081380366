import { FormConfig } from '../../../design/Forms/interfaces';
import {
    composeValidators,
    isNumber,
    maxLength,
    maxLengthForArrayField,
    minLengthForArrayField,
} from '../../../utils/validation';
import { AutocompleteOption } from '../../../design/Fields/AutocompleteField';

const leaseTypeList = [
    { id: 'CASH', name: 'Cash/Fixed' },
    { id: 'CROP_SHARE', name: 'Crop Share' },
    { id: 'FLEX', name: 'Flex/Variable' },
    { id: null, name: 'None' },
];
export const agriDataConfig = (
    farms: AutocompleteOption[] = [],
): FormConfig[] => [
    {
        formField: {
            scheme: 'half',
            name: 'agriData.associatedFarms',
            type: 'autocomplete',
            label: 'Associated Farms',
            validation: composeValidators(
                maxLengthForArrayField(256),
                minLengthForArrayField(1),
            ),
        },
        renderProps: {
            freeSolo: false,
            isDisabled: true,
            size: 'small',
            options: farms,
        },
    },
    {
        formField: {
            scheme: 'half',
            name: `agriData.associatedAcres`,
            type: 'input',
            label: 'Associated Acres',
            validation: composeValidators(isNumber, maxLength(256)),
        },
        renderProps: {
            placeholder: 'None',
            isDisabled: false,
            size: 'small',
        },
    },
    {
        formField: {
            scheme: 'half',
            name: `agriData.primaryLeaseType`,
            type: 'radio',
            label: 'Primary Lease Type',
        },
        renderProps: {
            options: leaseTypeList,
            isDisabled: false,
            size: 'half',
        },
    },
    {
        formField: {
            scheme: 'half',
            name: `agriData.primaryCrop`,
            type: 'input',
            label: 'Primary Crop',
            validation: composeValidators(maxLength(256)),
        },
        renderProps: {
            isDisabled: false,
            size: 'small',
        },
    },
];
