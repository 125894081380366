import { FormConfig } from '../../../design/Forms/interfaces';
import { composeValidators, required } from '../../../utils/validation';

export const leaseBonusConfig: FormConfig[] = [
    {
        formField: {
            name: 'bonusCriteria',
            type: 'richText',
            label: 'Bonus Calculation Description',
            isRequired: true,
            validation: composeValidators(required),
        },
        renderProps: {
            placeholder: 'Bonus description here',
            resize: 'none',
            isDisabled: false,
        },
    },
];
