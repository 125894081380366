import { MouseEvent, useState } from 'react';
import { Button, Card, CardContent, CardHeader } from '@mui/material';
import { ReactComponent as FilterIcon } from '../../assets/icons/filter_icon.svg';
import BasePopover from '../../reusable/BasePopover';
import BaseFilter from '../../design/Filter/BaseFilter';
import { FormConfig } from '../../design/Forms/interfaces';
import { ActionFilterButtons } from '../../design/Filter/ActionButtons';
import { makeStyles } from 'tss-react/mui';
import theme from '../../styles/theme';
import useHandleSubmitRTK from '../../hooks/useHandleSubmit';
import { useAddPaymentFilterMutation } from '../../api/payment/paymentAPI';
import { useGetLandPartnersShortQuery } from '../../api/landpartners/landPartnersAPI';
import { Option } from '../CreateLeaseForm/WizardPageConfigs/landPartnersConfig';

interface OwnProps {
    initialFilters?: FilterItem | '';
    setFilterSettings: (value: InitialFilters) => void;
    setFilter: (value: string) => void;
}
export interface InitialFilters {
    landPartnerId?: string;
    landPartnerName?: string;
    leaseType?: string;
    dateRange?: string;
    minPayment: number;
    maxPayment: number;
}
export interface SubmitFilter {
    landPartnerId?: string;
    leaseType?: string;
    dateRange?: string;
    paymentRange: number[];
}
export interface FilterItem {
    id: string;
    name: string;
    filterData: InitialFilters;
}
export default function PaymentFilter({
    initialFilters,
    setFilter,
    setFilterSettings,
}: OwnProps) {
    const { classes } = useStyles();
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const open = Boolean(anchorEl);
    const id = open ? 'search-popover' : undefined;
    const { isLoading, data: landPartnersList } = useGetLandPartnersShortQuery({
        sort: `name,asc`,
        page: 0,
        size: 1000,
    });

    const { handleSubmit, data: createdData } = useHandleSubmitRTK({
        useRtkHook: useAddPaymentFilterMutation,
        onSuccess: () => {
            setFilter(createdData?.id);
            setAnchorEl(null);
        },
        successMessage: 'Filters saved successfully',
    });

    const handlePopoverOpen = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handlePopoverClose = () => {
        setAnchorEl(null);
    };
    const initialFilterValues = initialFilters
        ? initialFilters?.filterData &&
          convertInitialFiltersData(initialFilters?.filterData)
        : {
              paymentRange: [0, 1000000],
          };
    const handleSaveFilter = (values: SubmitFilter) => {
        handleSubmit(
            convertSubmitFilterData(values, landPartnersList?.data as Option[]),
        );
    };
    const handleUpdateResults = (values: SubmitFilter) => {
        setFilterSettings(
            values &&
                (convertSubmitFilterData(
                    values,
                    landPartnersList?.data as Option[],
                ) as InitialFilters),
        );
        setFilter('');
    };

    const buttonsConfig: ActionFilterButtons<SubmitFilter | undefined>[] = [
        {
            label: 'Save Query',
            type: 'submit',
            variant: 'outlined',
        },
        {
            label: 'Update Results',
            onClick: (values) => values && handleUpdateResults(values),
            variant: 'contained',
        },
    ];
    return (
        <>
            <Button
                disabled={isLoading}
                endIcon={<FilterIcon />}
                aria-describedby={id}
                variant={'text'}
                onClick={handlePopoverOpen}>
                New Filter
            </Button>
            <BasePopover
                open={open}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}>
                <Card className={classes.root}>
                    <CardHeader
                        title={'Select all that apply'}
                        className={classes.header}
                    />
                    <CardContent>
                        <BaseFilter
                            initialFilters={initialFilterValues}
                            id={'payment-filter'}
                            config={filterConfig({
                                landPartnersList: landPartnersList?.data || [],
                            })}
                            onSubmit={handleSaveFilter}
                            buttonsConfig={buttonsConfig}
                        />
                    </CardContent>
                </Card>
            </BasePopover>
        </>
    );
}
const useStyles = makeStyles()(() => ({
    root: {
        width: 420,
        padding: theme.spacing(2),
    },
    header: {
        backgroundColor: theme.palette.backgroundB.backgroundB4,
        color: theme.palette.neutral.neutral7,
        display: 'flex',
        justifyContent: 'space-between',
        '& .MuiCardHeader-title': {
            fontSize: 14,
            fontWeight: 600,
        },
    },
}));

const leaseTypeList = [
    { id: 'FLEX', name: 'Flex' },
    { id: 'FIXED', name: 'Fixed' },
    { id: 'CROPSHARE', name: 'Crop Share' },
];
const dateRangeList = [
    { id: '', name: 'All' },
    { id: 3, name: ' 3 Months' },
    { id: 6, name: ' 6 Months' },
    { id: 12, name: ' 12 Months' },
];

const filterConfig = ({
    landPartnersList,
}: {
    landPartnersList: Option[];
}): FormConfig[] => [
    {
        formField: {
            name: 'landPartnerId',
            type: 'select',
            label: 'Payee Name',
        },
        renderProps: {
            size: 'small',
            options: landPartnersList,
        },
    },
    {
        formField: {
            name: `leaseType`,
            type: 'select',
            label: 'Lease Type',
        },
        renderProps: {
            options: leaseTypeList,
            isDisabled: false,
            size: 'half',
        },
    },
    {
        formField: {
            name: `dateRange`,
            type: 'radio',
            label: 'Date Range',
        },
        renderProps: {
            options: dateRangeList,
            isDisabled: false,
            size: 'third',
        },
    },
    {
        formField: {
            name: `paymentRange`,
            type: 'range',
            label: 'Payment Size',
        },
        renderProps: {
            min: 0,
            max: 1000000,
            marks: [
                { value: 0, label: '$0' },
                { value: 1000000, label: '$1M' },
            ],
            step: 1000,
            isDisabled: false,
            size: 'half',
        },
    },
];

function convertSubmitFilterData(
    values: SubmitFilter,
    landPartnerList: Option[],
): InitialFilters {
    return {
        landPartnerId: values.landPartnerId,
        landPartnerName:
            landPartnerList.find((value) => value.id === values?.landPartnerId)
                ?.name || '',
        leaseType: values.leaseType,
        dateRange: values.dateRange,
        minPayment: values?.paymentRange[0],
        maxPayment: values?.paymentRange[1],
    };
}
export function convertInitialFiltersData(
    values: InitialFilters,
): SubmitFilter {
    return {
        landPartnerId: values?.landPartnerId,
        leaseType: values?.leaseType,
        dateRange: values?.dateRange,
        paymentRange: [values?.minPayment, values?.maxPayment],
    };
}
