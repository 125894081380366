import React from 'react';

import { makeStyles } from 'tss-react/mui';

import { CommunicationContainer } from '../../reusable/NotesField/NotesField';
import {
    useAddNoteAttachmentMutation,
    useAddNoteMutation,
    useGetAllNotesQuery,
    useLazyGetAllNotesQuery,
} from '../../api/contacts/contactNotesAPI';

import customToastWithAlert from '../../design/CustomToastWithAlert';

import Chat from '../../reusable/Chat/Chat';

import { NoteDetailsProps } from '../../reusable/Chat/NoteDetails';
import { Note } from '../../reusable/AddNotesModal';
import { dateTimeConverterFromUTC } from '../../utils/dateFormat';

interface OwnProps {
    id?: string;
}

export default function AddNotes({ id }: OwnProps) {
    const { classes } = useStyles();

    const { data: notes, isLoading: isLoadingNotes } = useGetAllNotesQuery({
        sort: `createdAt,desc`,
        contactId: id,
    });
    const [addNotes, { isLoading: isAddingNote }] = useAddNoteMutation();
    const [addNoteAttachment] = useAddNoteAttachmentMutation();
    const [lazyGetNotes] = useLazyGetAllNotesQuery();

    async function handleAddNote(
        note: string,
        file: File | null,
        communicationContainer: CommunicationContainer,
    ) {
        try {
            //eslint-disable-next-line
            // @ts-ignore
            const { data } = await addNotes({
                contactId: id,
                content: note,
                communicationContainer,
            });

            if (file && data.id) {
                await addNoteAttachment({
                    id: data.id,
                    attachment: file,
                });
            }

            lazyGetNotes({
                sort: `createdAt,desc`,
                contactId: id,
            });
        } catch {
            customToastWithAlert({
                type: 'error',
                message: 'Note attachment upload failed',
            });
        }
    }

    return (
        <div className={classes.root}>
            <Chat
                addNote={handleAddNote}
                cards={
                    (notes?.data && convertResponseToNote(notes?.data)) || []
                }
                contactId={id}
                autoFocus={true}
                type={'CONTACT'}
                isLoadingNotes={isLoadingNotes}
                isAddingNote={isAddingNote}
            />
        </div>
    );
}
const useStyles = makeStyles()((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        padding: theme.spacing(2),
        gap: 8,
        borderRadius: 12,
        backgroundColor: theme.palette.neutral.neutral1,
    },
}));

export function convertResponseToNote(response: Note[]): NoteDetailsProps[] {
    return response?.map((note) => ({
        id: note.id,
        pinned: note.pinned,
        content: note.content,
        name: `${note.fromUser.firstName} ${note.fromUser.lastName}`,
        actualContactDate: note.communicationContainer?.actualContactDate,
        communicationType: note.communicationContainer?.communicationType,
        createdAt: dateTimeConverterFromUTC(note.createdAt),
        updatedAt: dateTimeConverterFromUTC(note.updatedAt),
        attachment: note.attachments?.[0],
    }));
}
