import React from 'react';
import { Box, Typography } from '@mui/material';
interface OwnProps {
    title: string;
    data: string;
}
export default function UploadInfo({ title, data }: OwnProps) {
    return (
        <Box
            display={'flex'}
            flexDirection={'column'}
            rowGap={1}
            justifyContent={'center'}>
            <Typography
                variant="font12"
                color={'text.secondary'}
                fontWeight={'bold'}>
                {title}
            </Typography>
            <Typography
                variant="font12"
                color={'text.neutral9'}
                fontWeight={'medium'}>
                {data}
            </Typography>
        </Box>
    );
}
