import { ChartColors } from '../styles/palette';

export function convertDataToBarChartProps<T extends object>(
    arrayOfObjects: T[],
) {
    const chartColorsArr: ChartColors[] = ['#a1eba1', '#eaf7c8', '#ccd7da'];

    const uniqID = arrayOfObjects.reduce((keys: string[], obj) => {
        Object.keys(obj)
            .filter((key) => key !== 'name')
            .forEach((key) => {
                if (!keys.includes(key)) {
                    keys.push(key);
                }
            });
        return keys;
    }, []);

    const chartColors = convertToObj(uniqID, chartColorsArr);
    return {
        chartColors: chartColors,
        uniqID,
    };
}
export function convertToObj(a: string[], b: string[]): Record<string, string> {
    return a.reduce((acc, element, index) => {
        return {
            ...acc,
            [element]: b[index],
        };
    }, {});
}
export function base64ToBlobImage(byteStream: string, fileType: string) {
    const binaryData = byteStream && atob(byteStream);
    const arrayBuffer = new ArrayBuffer(binaryData.length);
    const uint8Array = new Uint8Array(arrayBuffer);

    for (let i = 0; i < binaryData.length; i++) {
        uint8Array[i] = binaryData.charCodeAt(i);
    }

    return new Blob([uint8Array], { type: fileType });
}
export function convertBytesToMbOrKb(size: string | number) {
    const bytes = typeof size === 'string' ? parseInt(size, 10) : size;

    const kb = 1024;
    const mb = kb * kb;

    if (isNaN(bytes) || bytes < 0) {
        return 'Invalid input';
    }

    if (bytes >= mb) {
        return `${(bytes / mb).toFixed(2)} MB`;
    } else if (bytes >= kb) {
        return `${(bytes / kb).toFixed(2)} KB`;
    }

    return `${bytes} Bytes`;
}

export function createBlobUrl(byteStream: string, fileType: string) {
    const blob = base64ToBlobImage(byteStream, fileType);
    return URL.createObjectURL(blob);
}
export function createArrayOfNumbers(number: number) {
    return Array.from({ length: number }, (_, i) => i + 1);
}
