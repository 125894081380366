import React from 'react';
import { makeStyles } from 'tss-react/mui';
import theme from '../styles/theme';

interface OwnProps {
    imageURL: string;
}

export default function ImageViewer({ imageURL }: OwnProps) {
    const { classes } = useStyles();
    return (
        <div className={classes.viewerContainer}>
            <img
                style={{
                    height: '100%',
                    width: 'auto',
                }}
                src={imageURL}
                alt={'Preview'}
            />
        </div>
    );
}

const useStyles = makeStyles()(() => ({
    viewerContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%',
        color: 'white',
        padding: theme.spacing(2),
        borderBottomRightRadius: 12,
        borderBottomLeftRadius: 12,
        '&::-webkit-scrollbar': {
            width: 10,
            backgroundColor: 'transparent',
            padding: 10,
            scrollbarHeight: 10,
        },
        '&::-webkit-scrollbar-thumb': {
            width: 5,
            height: 5,
            borderRadius: '5px',
            backgroundColor: 'silver',
        },
        '&::-webkit-scrollbar-button': {
            height: 35,
        },
        overflowY: 'auto',
        overflowX: 'hidden',
    },
}));
