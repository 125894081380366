import React from 'react';
import {
    Cell,
    Label,
    Legend,
    Pie,
    PieChart,
    ResponsiveContainer,
    Tooltip,
} from 'recharts';
import { makeStyles } from 'tss-react/mui';
import theme from '../../../styles/theme';
import { Box, Typography } from '@mui/material';
import { defaultColors } from '../utils';

interface DataEntry {
    name: string;
    value: number;
}

export interface PieChartConfig {
    summaryTitle?: string;
    summaryValue?: string | number;
    showLegend: boolean;
    showTooltip: boolean;
    dataKey: string;
    chartTitle?: string;
    handleClick?: (name: string) => void;
    colors?: string[];
}

interface BasePieChartProps {
    data: DataEntry[];
    config: PieChartConfig;
}

export default function BasePieChart({ data, config }: BasePieChartProps) {
    const {
        summaryTitle,
        summaryValue,
        dataKey,
        showLegend,
        chartTitle,
        showTooltip,
        handleClick,
        colors = defaultColors,
    } = config;

    const { classes } = useStyles();

    return (
        <Box display="flex" flexDirection={'column'} width={'auto'} gap={1}>
            <Typography
                className={classes.title}
                textTransform={'uppercase'}
                variant="font16"
                fontWeight="bold"
                color={'text.secondary'}>
                {chartTitle}
            </Typography>
            <ResponsiveContainer width={300} height={300}>
                <PieChart>
                    <Pie
                        data={
                            !summaryValue || summaryValue === 0
                                ? [{ name: 'No Data', value: 1 }]
                                : data
                        }
                        startAngle={0}
                        endAngle={360}
                        innerRadius={80}
                        outerRadius={100}
                        paddingAngle={1}
                        legendType={'circle'}
                        dataKey={dataKey}>
                        {
                            <>
                                <Label
                                    className={classes.summaryTitle}
                                    value={summaryTitle}
                                    position="center"
                                />
                                <Label
                                    className={classes.summaryValue}
                                    value={summaryValue}
                                    position="center"
                                />
                            </>
                        }
                        {data.map((entry, index) => {
                            return (
                                <Cell
                                    onClick={() =>
                                        handleClick && handleClick(entry.name)
                                    }
                                    key={`cell-${index}`}
                                    fill={colors[index % colors.length]}
                                />
                            );
                        })}
                    </Pie>
                    {showLegend && (
                        <Legend verticalAlign={'bottom'} height={50} />
                    )}
                    {showTooltip && <Tooltip />}
                </PieChart>
            </ResponsiveContainer>
        </Box>
    );
}

const useStyles = makeStyles()(() => ({
    title: {
        marginTop: theme.spacing(1),
        marginLeft: theme.spacing(3),
    },
    summaryTitle: {
        fontSize: 12,
        fontWeight: 500,
        width: 100,
        transform: 'translateY(-25px)',
        color: theme.palette.brandDarkTurq,
    },
    summaryValue: {
        fontSize: 24,
        fontWeight: 600,
        transform: 'translateY(10px)',
        color: theme.palette.brandDarkTurq,
    },
}));
