import { Box, Grid, Typography } from '@mui/material';
import SharedLeasesShortTable from './Lease/SharedLeasesShortTable';
import DocumentsList from '../../../reusable/DocumentsList/DocumentsList';
import React from 'react';
import {
    useGetSharedDocumentsTmpQuery,
    useGetSharedLeasesTmpQuery,
    useGetSharedPaymentsTmpQuery,
} from '../../../api/sharedData/sharedDataTempAccessAPI';
import Loader from '../../../design/BaseLoader';
import SharedPaymentsShortTable from './Payment/SharedPaymentsShortTable';
import theme from '../../../styles/theme';

interface OwnProps {
    token: string;
}

export default function MetricsOnly({ token }: OwnProps) {
    const { data: leases, isLoading: isSharedLeasesLoading } =
        useGetSharedLeasesTmpQuery({
            token,
        });
    const { data: payments, isLoading: isSharedPaymentsLoading } =
        useGetSharedPaymentsTmpQuery({
            token,
        });
    const { data: documents, isLoading: isSharedDocumentsLoading } =
        useGetSharedDocumentsTmpQuery({
            token,
        });

    if (
        isSharedLeasesLoading ||
        isSharedPaymentsLoading ||
        isSharedDocumentsLoading
    ) {
        return (
            <Grid
                container
                alignItems={'center'}
                justifyContent={'center'}
                flexDirection={'column'}>
                <Loader />
            </Grid>
        );
    }

    return (
        <Box sx={{ padding: '20px', paddingLeft: '5px' }} borderRadius={2}>
            <Box sx={{ marginBottom: '20px' }}>
                <Typography variant={'font16'} fontWeight={'bold'}>
                    Leases
                </Typography>
            </Box>
            <SharedLeasesShortTable data={leases?.data || []} />
            <br />
            <Box sx={{ marginBottom: '20px' }}>
                <Typography variant={'font16'} fontWeight={'bold'}>
                    Payments
                </Typography>
            </Box>
            <SharedPaymentsShortTable data={payments?.data || []} />
            <br />
            <Box sx={{ marginBottom: '20px' }}>
                <Typography variant={'font16'} fontWeight={'bold'}>
                    Recent Uploads
                </Typography>
            </Box>
            <Box bgcolor={theme.palette.neutralWhite} padding={2}>
                <DocumentsList cards={documents?.data || []} />
            </Box>
        </Box>
    );
}
