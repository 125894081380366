import React from 'react';
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    Tooltip,
    Legend,
    ResponsiveContainer,
    CartesianGrid,
} from 'recharts';
import { Typography, Box } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import theme from '../../../styles/theme';

export interface DataEntry {
    name: string;
    [key: string]: number | string;
}
interface ChartLabel {
    value: string;
    position: 'insideLeft' | 'insideBottomRight';
}
export interface BarChartConfig<T extends string> {
    chartTitle?: string;
    bars: T[];
    colors: Record<T, string>;
    showLegend?: boolean;
    showTooltip?: boolean;
    barChartOptions: {
        width: number;
        height: number;
    };
    isHidden?: boolean;
    maxBarSize?: number;
    isStacked?: boolean;
    labelX?: ChartLabel;
    labelY?: ChartLabel;
}
interface OwnProps<T extends string> {
    data: DataEntry[];
    config: BarChartConfig<T>;
}
export default function BaseBarChart<T extends string>({
    config: {
        showLegend,
        showTooltip,
        barChartOptions,
        bars,
        colors,
        labelX,
        labelY,
        chartTitle,
        isStacked,
        maxBarSize,
        isHidden,
    },
    data,
}: OwnProps<T>) {
    const { classes } = useStyles();
    if (isHidden) return null;
    return (
        <Box
            display="flex"
            width="auto"
            flexDirection={'column'}
            justifyContent={'space-between'}>
            <Typography
                className={classes.title}
                textTransform={'uppercase'}
                variant="font16"
                fontWeight="bold"
                color={'text.secondary'}>
                {chartTitle}
            </Typography>
            <ResponsiveContainer
                width={barChartOptions.width}
                height={barChartOptions.height}>
                <BarChart data={data}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                        dataKey="name"
                        label={{
                            value: labelX?.value,
                            position: labelX?.position,
                        }}
                    />
                    <YAxis
                        label={{
                            value: labelY?.value,
                            angle: -90,
                            position: labelY?.position,
                        }}
                    />
                    {showTooltip && <Tooltip />}
                    {showLegend && (
                        <Legend
                            layout={'vertical'}
                            iconType={'circle'}
                            align={'right'}
                            wrapperStyle={{ paddingLeft: 15 }}
                            verticalAlign={'top'}
                        />
                    )}

                    {bars.map((bar) => (
                        <Bar
                            maxBarSize={maxBarSize}
                            key={bar}
                            dataKey={bar}
                            stackId={isStacked ? 'stacked' : undefined}
                            fill={colors[bar]}
                        />
                    ))}
                </BarChart>
            </ResponsiveContainer>
        </Box>
    );
}

const useStyles = makeStyles()(() => ({
    title: {
        marginTop: theme.spacing(1),
        marginLeft: theme.spacing(3),
        textTransform: 'uppercase',
        paddingBottom: theme.spacing(1),
    },
}));
