/* eslint-disable */
export const getInitialValue = (value: any) => {
    if (![null, undefined].includes(value)) {
        return value;
    }
    return null;
};

export const getFormikValue = (value: any) => {
    if (![null, undefined].includes(value)) {
        return value;
    }
    return '';
};

export const isDisplayShrink = (value: any) => {
    return ![null, undefined, ''].includes(value);
};

export const onChangeWithInitReturn =
    (name: string, formik: any) => (e: any) => {
        const initValue = formik.initialValues[name];
        formik.setFieldValue(
            name,
            e.target.value === '' && (!initValue || initValue === '')
                ? initValue
                : e.target.value,
        );
    };
