import { usePaymentOverviewScheduled } from './usePaymentOverviewScheduled';
import GenericTable from '../../design/GenericTable/GenericTable';
import { Box, Button, Grid } from '@mui/material';
import Loader from '../../design/BaseLoader';
import React, { useEffect, useState } from 'react';
import {
    createDownloadLinkFromBlob,
    getFilenameFromContentDisposition,
} from '../../utils/fileHandler';
import {
    useGetPaymentFilterQuery,
    useLazyGetCsvPaymentsQuery,
} from '../../api/payment/paymentAPI';
import ActionButton, {
    ActionMenuItem,
} from '../../design/Buttons/ActionButton';
import PaymentFilter, { FilterItem, InitialFilters } from './PaymentFilter';
import FilterList from './FilterList';
import { ReactComponent as RotateIcon } from '../../assets/icons/rotate_icon.svg';
import theme from '../../styles/theme';
import ImportPaymentsModal from './ImportPaymentsModal';

export default function PaymentOverviewScheduled() {
    const { data: filterList, isLoading: isFilterLoading } =
        useGetPaymentFilterQuery({});
    const [paymentImportOpen, setPaymentImportOpen] = useState<boolean>(false);
    const [filterSettings, setFilterSettings] = useState<
        InitialFilters | undefined
    >(undefined);
    const [filter, setFilter] = useState<string>('none');

    const [downloadPaymentCSV] = useLazyGetCsvPaymentsQuery();

    const { data, totalCount, tableConfig, isLoading } =
        usePaymentOverviewScheduled({
            filters: (filterSettings && filterEmptyKeys(filterSettings)) || {},
        });

    useEffect(() => {
        filter &&
            setFilterSettings(findFilterById(filter, filterList)?.filterData);
    }, [filter, filterList]);

    function handleOpenImportModal() {
        setPaymentImportOpen(true);
    }
    function handleCloseImportModal() {
        setPaymentImportOpen(false);
    }
    const handleDownload = async () => {
        downloadPaymentCSV('no-param')
            .unwrap()
            .then(({ data, headers }) => {
                createDownloadLinkFromBlob(
                    data,
                    getFilenameFromContentDisposition(headers),
                );
            });
    };
    const handleReset = () => {
        setFilterSettings(undefined);
        setFilter('');
    };
    if (isLoading || isFilterLoading) {
        return (
            <Grid
                container
                sx={{ height: '100vh' }}
                alignItems={'center'}
                justifyContent={'center'}
                flexDirection={'column'}>
                <Loader />
            </Grid>
        );
    }

    const actionButtonItems: ActionMenuItem[] = [
        {
            label: 'Download CSV',
            onClick: handleDownload,
        },
        {
            label: 'Import Payments',
            onClick: handleOpenImportModal,
        },
    ];

    return (
        <>
            <Grid
                container
                justifyContent={'space-between'}
                width={'100%'}
                sx={{
                    padding: 2,
                }}>
                <FilterList
                    filterList={filterList}
                    setFilter={setFilter}
                    filter={filter}
                />
                <Box display={'flex'} columnGap={2}>
                    <Button
                        variant={'text'}
                        onClick={handleReset}
                        disabled={!filterSettings}
                        endIcon={
                            <RotateIcon
                                style={{
                                    fill: !filterSettings
                                        ? theme.palette.neutral.neutral3
                                        : theme.palette.primary.main,
                                }}
                            />
                        }>
                        Reset View
                    </Button>
                    <PaymentFilter
                        setFilterSettings={setFilterSettings}
                        setFilter={setFilter}
                        initialFilters={
                            filter && findFilterById(filter, filterList)
                        }
                    />
                    <ActionButton label={'Actions'} items={actionButtonItems} />
                </Box>
            </Grid>
            <GenericTable
                data={data}
                totalCount={totalCount}
                tableConfig={tableConfig}
            />
            {paymentImportOpen && (
                <ImportPaymentsModal
                    isShowing={paymentImportOpen}
                    handleClose={handleCloseImportModal}
                />
            )}
        </>
    );
}
function findFilterById(id: string, filterList: FilterItem[]) {
    return filterList && filterList?.find((filter) => filter.id === id);
}
function filterEmptyKeys(obj: InitialFilters): InitialFilters {
    return Object.fromEntries(
        Object.entries(obj).filter(
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            ([_, v]) => v !== null && v !== '' && v !== undefined,
        ),
    ) as InitialFilters;
}
