import { FormHelperText, Slider } from '@mui/material';
import React from 'react';

export interface RangeSlider {
    onChange: (event: Event, newValue: number | number[]) => void;
    value: number | number[];
    min: number;
    max: number;
    step: number;
    errorText?: string;
    isDisabled?: boolean;
    marks?: Array<{ value: number; label: string }>;
    isHidden?: boolean;
}

export default function RangeSliderField({
    onChange,
    value,
    min,
    max,
    step,
    errorText,
    isDisabled,
    isHidden,
    marks,
}: RangeSlider) {
    if (isHidden) return null;
    return (
        <>
            <Slider
                value={value}
                onChange={onChange}
                valueLabelDisplay="auto"
                aria-labelledby="range-slider"
                getAriaValueText={(value) => value.toString()}
                min={min}
                max={max}
                marks={marks}
                step={step}
                disabled={isDisabled}
            />
            {!!errorText && <FormHelperText>{errorText}</FormHelperText>}
        </>
    );
}
