import { Box, Typography } from '@mui/material';

import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { DatePicker } from '@mui/x-date-pickers';
import SelectField from '../../design/Fields/SelectField';
import theme from '../../styles/theme';

const typeOptions = [
    { id: 'IN_PERSON', name: 'In Person' },
    { id: 'PHONE_CALL', name: 'Phone Call' },
    { id: 'TEXT_MESSAGE', name: 'Text Message' },
    { id: 'GENERAL_NOTE', name: 'General Note' },
    { id: 'EMAIL', name: 'Email' },
    { id: 'POSTAL_MAIL', name: 'Postal Mail' },
];

interface OwnProps {
    setDate: (date: Date | null) => void;
    setType: (type: string) => void;
    type: string;
    date: Date | null;
}

export default function NotesControls({
    setDate,
    setType,
    type,
    date,
}: OwnProps) {
    const { classes } = useStyles();
    return (
        <Box display={'flex'} flexDirection={'column'} rowGap={1}>
            <Box
                className={classes.inputWrapper}
                alignItems={'center'}
                display={'flex'}
                flexDirection={'row'}
                columnGap={2}
                pl={1}>
                <Typography variant={'font14'} fontWeight={'bold'}>
                    Communication Type
                </Typography>
            </Box>
            <SelectField
                size={'small'}
                onChange={(value) => setType(value as string)}
                fullWidth={true}
                value={type}
                options={typeOptions}
            />
            <Box
                display={'flex'}
                flexDirection={'row'}
                columnGap={2}
                alignItems={'center'}>
                <Typography variant={'font14'} fontWeight={'bold'} pl={1}>
                    Date:
                </Typography>
                <DatePicker
                    value={date}
                    onChange={(date) => setDate(date)}
                    slotProps={{
                        calendarHeader: {
                            sx: {
                                fontSize: 24,
                                color: theme.palette.neutralWhite,
                                margin: theme.spacing(1, 0),
                                padding: theme.spacing(3, 2),
                                backgroundColor: theme.palette.brandLightTurq,
                                minHeight: 60,
                                maxHeight: 60,
                            },
                        },

                        layout: {
                            sx: {
                                height: 360,
                            },
                        },
                        switchViewIcon: {
                            sx: { color: theme.palette.neutralWhite },
                        },
                        nextIconButton: {
                            sx: { color: theme.palette.neutralWhite },
                        },
                        previousIconButton: {
                            sx: { color: theme.palette.neutralWhite },
                        },
                    }}
                    sx={{
                        backgroundColor: theme.palette.neutralWhite,
                        '& .MuiInputBase-root': {
                            height: 40,
                        },
                    }}
                />
            </Box>
        </Box>
    );
}

const useStyles = makeStyles()(() => ({
    inputWrapper: {
        borderRadius: 12,
        '& .Mui-focused': {
            boxShadow: 'none',
            border: 'none',
        },
        '& .MuiInput-root': {
            '&::after': {
                padding: 0,
                border: 'none',
            },
            '&:hover:not(.Mui-disabled, .Mui-error):before': {
                border: '0',
            },
            '&:before': {
                padding: 0,
                border: 'none',
            },
        },
    },
}));
