import React from 'react';
import GenericTable, { Table } from '../../design/GenericTable/GenericTable';
import { Grid, Typography } from '@mui/material';

import { useGetUpcomingLeaseRenewalsQuery } from '../../api/leases/leaseAPI';
import {
    Lease,
    showFieldName,
    showFsaIdName,
    showLandownerName,
} from '../../pages/LandPortfolio/TableConfig';
import Loader from '../../design/BaseLoader';

export default function LeaseRenewals() {
    const { isLoading, data } = useGetUpcomingLeaseRenewalsQuery({});

    if (isLoading) {
        return (
            <Grid
                width={'100%'}
                container
                alignItems={'center'}
                justifyContent={'center'}>
                <Loader />
            </Grid>
        );
    }

    const renewalsTableConfig: Table<Lease> = {
        columns: [
            {
                header: {
                    label: 'Field Name',
                    sortable: false,
                },
                cellRender: (renewal) => showFieldName(renewal),
                format: { align: 'left', color: { color: 'primary' } },
                width: 'auto',
            },
            {
                header: {
                    label: 'Total Acres',
                    sortable: false,
                },
                cellRender: (renewal) => (
                    <Typography variant={'font14'} fontWeight={'medium'}>
                        {renewal.totalAcres}
                    </Typography>
                ),
                format: { align: 'left', color: { color: 'primary' } },
                width: 'auto',
            },
            {
                header: {
                    label: 'Tillable Acres',
                    sortable: false,
                },
                cellRender: (renewal) => (
                    <Typography variant={'font14'} fontWeight={'medium'}>
                        {renewal.tillableAcres}
                    </Typography>
                ),
                format: { align: 'left', color: { color: 'primary' } },
                width: 'auto',
            },
            {
                header: {
                    label: 'Landowner',
                    sortable: false,
                },
                cellRender: (renewal) => showLandownerName(renewal),
                format: { align: 'left', color: { color: 'primary' } },
                width: 'auto',
            },
            {
                header: {
                    label: 'Lease Type',
                    sortable: false,
                },
                cellRender: (renewal) => (
                    <Typography variant={'font14'}>{renewal.type}</Typography>
                ),
                format: { align: 'left', color: { color: 'primary' } },
                width: 'auto',
            },
            {
                header: {
                    label: 'Lease Expiration',
                    sortable: false,
                },
                cellRender: (renewal) => (
                    <Typography variant={'font14'}>
                        {renewal.endDate}
                    </Typography>
                ),
                format: { align: 'left', color: { color: 'primary' } },
                width: 'auto',
            },
            {
                header: {
                    label: 'FSA ID',
                    sortable: false,
                },
                cellRender: (renewal) => showFsaIdName(renewal),
                format: { align: 'left', color: { color: 'primary' } },
                width: 'auto',
            },
        ],
        multiselect: false,
        expandable: false,
        pageable: false,
    };
    return <GenericTable data={data} tableConfig={renewalsTableConfig} />;
}
