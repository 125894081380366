import React from 'react';
import { socialMediaConfig } from './FormConfigs/socialMediaConfig';
import FormPage from '../../reusable/FormUI/FormPage';
import { agriDataConfig } from './FormConfigs/agriDataConfig';
import { Box, Divider } from '@mui/material';

export default function SocialMediaAndAgridataPage() {
    return (
        <Box display={'flex'} flexDirection={'column'} rowGap={2}>
            <FormPage formConfig={socialMediaConfig} />
            <Divider orientation={'horizontal'} variant={'fullWidth'} />
            <FormPage formConfig={agriDataConfig([])} />
        </Box>
    );
}
