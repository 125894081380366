import { createContext, Dispatch, SetStateAction } from 'react';
import { LandPartnerArchive } from './LandPartners/useTableArchiveLPConfig';
import { LeaseArchive } from './Leases/useTableArchiveLeaseConfig';
import { ContactArchive } from './Contacts/useTableArchiveContactConfig';
import { ArchivedContactNoteData } from './ContactNotes/useTableArchivedContactNotesConfig';
import { ArchivedLeaseNoteData } from './LeaseNotes/useTableArchivedLeaseNotesConfig';

export const SelectedRowContext = createContext<{
    selectedRow:
        | LandPartnerArchive
        | LeaseArchive
        | ContactArchive
        | ArchivedContactNoteData
        | ArchivedLeaseNoteData
        | null;
    setSelectedRow: Dispatch<
        SetStateAction<
            | LandPartnerArchive
            | LeaseArchive
            | ContactArchive
            | ArchivedContactNoteData
            | ArchivedLeaseNoteData
            | null
        >
    >;
    // eslint-disable-next-line @typescript-eslint/no-empty-function
}>({
    selectedRow: null,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setSelectedRow: () => {},
});
