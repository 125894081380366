import React from 'react';
import { Box, Divider, Grid, Typography } from '@mui/material';
import theme from '../../styles/theme';
import {
    DistributionDateAmounts,
    TransformedPaymentData,
} from './LeasePayments';
import { formatCurrency } from '../../utils/fieldMasks';

interface ExpandComponentProps {
    data: TransformedPaymentData;
}

export default function ExpandedPaymentComponent({
    data,
}: ExpandComponentProps) {
    const { fsaId, splitPaymentData, tillableAcres, rentPerAcreRate } = data;
    const currentFsaIDPayment = splitPaymentData?.[fsaId];
    const paymentDateArr =
        currentFsaIDPayment && Object.keys(currentFsaIDPayment);

    return (
        <Grid
            container
            width={'100%'}
            columnGap={4}
            sx={{
                padding: theme.spacing(2, 3),
                backgroundColor: theme.palette.backgroundB.backgroundB2,
            }}>
            <Box display={'flex'} flexDirection={'column'} rowGap={1}>
                <Typography
                    variant={'font12'}
                    color={'text.secondary'}
                    fontWeight={'medium'}>
                    TILLABLE ACRES
                </Typography>
                {tillableAcres && (
                    <Typography
                        variant={'font14'}
                        color={'text.neutral6'}
                        fontWeight={'medium'}>
                        Tillable Acres: {tillableAcres}
                    </Typography>
                )}

                <Typography
                    variant={'font14'}
                    color={'text.neutral6'}
                    fontWeight={'medium'}>
                    Rent per Acre: {formatCurrency(rentPerAcreRate)}
                </Typography>
            </Box>
            <Divider
                variant={'fullWidth'}
                orientation={'vertical'}
                flexItem
                sx={{ borderColor: theme.palette.neutralWhite, borderWidth: 3 }}
            />
            <Box display={'flex'} columnGap={4}>
                {Array.isArray(paymentDateArr) &&
                    paymentDateArr.map((i: string, index: number) => {
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        const landowners = currentFsaIDPayment?.[i];
                        return (
                            <>
                                <Box
                                    key={index}
                                    display={'flex'}
                                    flexDirection={'column'}
                                    rowGap={1}>
                                    <Typography
                                        variant={'font12'}
                                        color={'text.secondary'}
                                        fontWeight={'medium'}>
                                        PAYMENT {index + 1}
                                    </Typography>
                                    {landowners?.map(
                                        (
                                            landowner: DistributionDateAmounts,
                                        ) => (
                                            <Typography
                                                variant={'font14'}
                                                color={'text.neutral6'}
                                                fontWeight={'medium'}
                                                key={landowner.landownerName}>
                                                {landowner.landownerName} -{' '}
                                                {formatCurrency(
                                                    +landowner.amount,
                                                )}
                                            </Typography>
                                        ),
                                    )}
                                </Box>
                                {index < paymentDateArr.length - 1 && (
                                    <Divider
                                        variant={'fullWidth'}
                                        orientation={'vertical'}
                                        flexItem
                                        sx={{
                                            borderColor:
                                                theme.palette.neutralWhite,
                                            borderWidth: 3,
                                        }}
                                    />
                                )}
                            </>
                        );
                    })}
            </Box>
        </Grid>
    );
}
