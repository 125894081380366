import { Option } from '../CreateLeaseForm/WizardPageConfigs/leaseUploadConfig';
import SelectField from '../../design/Fields/SelectField';
import React, { useState } from 'react';
import { Box } from '@mui/material';

interface OwnProps {
    isHidden: boolean;
    options: Option[];
    onChange: (value: Option) => void;
}

export default function AssignedToField({
    isHidden,
    options,
    onChange,
}: OwnProps) {
    const [selectedOption, setSelectedOption] = useState<Option | null>(null);

    if (isHidden) return null;

    function handleChange(value: string) {
        const selected = options.find((option) => option.id === value) || null;
        setSelectedOption(selected);

        if (selected?.id) {
            onChange(selected);
        }
    }

    return (
        <Box width={250}>
            <SelectField
                size={'small'}
                fullWidth
                value={selectedOption?.id || ''}
                name={'assignedTo'}
                placeholder={'Select a Land Partner'}
                options={options}
                onChange={handleChange}
            />
        </Box>
    );
}
