import React from 'react';

import GenericFormInput from '../../design/FormFields/GenericFormInput';
import { Box, Grid, Typography } from '@mui/material';
import { FormConfig } from '../../design/Forms/interfaces';

interface OwnProps {
    formConfig: FormConfig[];
    title?: string;
    gap?: number;
}
export default function FormPage({ formConfig, gap = 1, title }: OwnProps) {
    return (
        <Box display={'flex'} flexDirection={'column'} rowGap={1}>
            {title && (
                <Typography variant={'font14'} fontWeight={'medium'}>
                    {title}
                </Typography>
            )}
            <Grid container spacing={1} rowGap={gap}>
                {formConfig.map(({ formField, renderProps }) => {
                    return (
                        <GenericFormInput
                            key={formField.name}
                            {...formField}
                            {...renderProps}
                        />
                    );
                })}
            </Grid>
        </Box>
    );
}
