import { usePagination } from '../../design/GenericTable/useBackendPagination';
import { useSorting } from '../../design/GenericTable/useSorting';
import { useSettingsFromStorage } from '../../hooks/useSettingsFromStorage';
import { useSaveSettings } from '../../hooks/useSaveSettings';
import { TableColumn } from '../../design/GenericTable/GenericTable';
import { useGetPaymentScheduledQuery } from '../../api/payment/paymentAPI';
import React from 'react';
import { PaymentScheduledData } from './interfaces';
import { formatCurrency } from '../../utils/fieldMasks';
import BasedTooltip from '../../design/BasedTooltip';
import ExpandedPayment from './ExpandedPayment';
import StatusTag, { Tag } from '../../design/StatusTag/StatusTag';
import { InitialFilters } from './PaymentFilter';
import LogPaymentButton from './LogPaymentButton';

export function usePaymentOverviewScheduled({
    filters,
}: {
    filters: InitialFilters | Record<string, never>;
}) {
    const { settings, saveSettings } =
        useSettingsFromStorage('PAYMENT_SCHEDULED');
    const { sort, page, sortDirection, size } = settings;
    const { sortKey, sortOrder, handleSort } = useSorting(
        sort || 'paymentDate',
        sortDirection || 'asc',
    );
    const {
        currentPage,
        itemsPerPage,
        handlePageChange,
        handleItemsPerPageChange,
    } = usePagination(page || 1, size || 25);
    useSaveSettings({
        settings,
        saveSettings,
        sortKey,
        sortOrder,
        currentPage,
        itemsPerPage,
    });

    const { data, isLoading } = useGetPaymentScheduledQuery({
        sort: `${sortKey},${sortOrder}`,
        page: currentPage - 1,
        size: itemsPerPage,
        ...filters,
    });

    const columns: TableColumn<PaymentScheduledData>[] = [
        {
            header: {
                label: 'Land Partner Name',
                sortable: true,
                sorting: {
                    id: 'landowner.name',
                    direction:
                        sortKey === 'landowner.name' ? sortOrder : 'desc',
                    isSorted: sortKey === 'landowner.name',
                },
                onClick: handleSort,
            },
            cellRender: (data) => (
                <BasedTooltip>{data?.landowner.name}</BasedTooltip>
            ),
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
        },

        {
            header: {
                label: 'Lease Type',
                sortable: true,
                sorting: {
                    id: 'lease.type',
                    direction: sortKey === 'lease.type' ? sortOrder : 'desc',
                    isSorted: sortKey === 'lease.type',
                },
                onClick: handleSort,
            },
            cellRender: (data) => (
                <BasedTooltip>{data?.lease?.type}</BasedTooltip>
            ),
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
        },
        {
            header: {
                label: 'Amount',
                sortable: true,
                sorting: {
                    id: 'amount',
                    direction: sortKey === 'amount' ? sortOrder : 'desc',
                    isSorted: sortKey === 'amount',
                },
                onClick: handleSort,
            },
            cellRender: (data) => <>{formatCurrency(data?.amount)}</>,
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
        },
        {
            header: {
                label: 'Due Date',
                sortable: true,
                sorting: {
                    id: 'paymentDate',
                    direction: sortKey === 'paymentDate' ? sortOrder : 'desc',
                    isSorted: sortKey === 'paymentDate',
                },
                onClick: handleSort,
            },
            cellRender: (data) => <>{data?.paymentDate}</>,
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
        },
        {
            header: {
                label: 'Status',
                sortable: true,
                sorting: {
                    id: 'status',
                    direction: sortKey === 'status' ? sortOrder : 'desc',
                    isSorted: sortKey === 'status',
                },
                onClick: handleSort,
            },
            cellRender: (data) => (
                <>
                    <StatusTag {...paymentStatusMap[data.status]} />
                </>
            ),
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
        },
        {
            header: {
                label: '',
                sortable: false,
            },
            cellRender: (data) => (
                <>
                    <LogPaymentButton data={data} />
                </>
            ),
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
        },
    ];
    const tableConfig = {
        columns,
        pageable: true,
        handlePageChange,
        handleItemsPerPageChange,
        initialPageSize: size,
        initialPage: page,
        expandable: true,
        ExpandComponent: ExpandedPayment,
    };
    const totalCount = data?.totalCount || 0;
    return { data: data?.data || [], totalCount, tableConfig, isLoading };
}

export const paymentStatusMap: Record<string, Tag> = {
    UNPAID: {
        status: 'error',
        text: 'Unpaid',
    },
    PAID: {
        status: 'success',
        text: 'Paid',
    },
};
