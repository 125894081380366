import React from 'react';
import { Box, Typography } from '@mui/material';
import CreateNewContactWidget from './CreateNewContactWidget';
import FindExistingContactWidget from './FindExistingContactWidget';
import theme from '../../../styles/theme';
interface OwnProps {
    parentContactId: string;
}
export default function RelationContactForm({ parentContactId }: OwnProps) {
    return (
        <Box
            display={'flex'}
            width={'100%'}
            justifyContent={'center'}
            alignItems={'center'}>
            <Box
                display={'flex'}
                flexDirection={'column'}
                rowGap={5}
                sx={{
                    backgroundColor: theme.palette.backgroundB.backgroundB1,
                    padding: theme.spacing(5, 8),
                }}>
                <Typography variant={'font20'} fontWeight={'medium'}>
                    Would you like to
                </Typography>
                <Box display={'flex'} columnGap={1} alignItems={'center'}>
                    <CreateNewContactWidget parentContactId={parentContactId} />
                    <Typography fontWeight={'medium'}>- OR -</Typography>
                    <FindExistingContactWidget
                        parentContactId={parentContactId}
                    />
                </Box>
            </Box>
        </Box>
    );
}
