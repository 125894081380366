import { Form } from 'react-final-form';
import FormPage from '../../../reusable/FormUI/FormPage';
import { Box, Grid } from '@mui/material';
import SubmitButton from '../../../design/Buttons/SubmitButton';
import theme from '../../../styles/theme';
import { DocumentRequest } from './UploadDocument';
import { fileDataConfig } from './utils';
import { InitialDocumentFormValues } from './ModifyDocumentModal';
import { useGetCurrentOrganizationConfigQuery } from '../../../api/users/organizationsAPI';
import Loader from '../../../design/BaseLoader';
import React from 'react';

interface OwnProps {
    handleSubmit: (values: DocumentRequest) => void;
    initialValues?: InitialDocumentFormValues;
    buttonLabel?: string;
    isLoading: boolean;
}

export default function UploadOneDocumentForm({
    initialValues,
    handleSubmit,
    buttonLabel = 'Upload',
    isLoading,
}: OwnProps) {
    const {
        data,
        isLoading: isConfigLoading,
        isFetching: isConfigFetching,
    } = useGetCurrentOrganizationConfigQuery();

    if (isConfigLoading || isConfigFetching) {
        return (
            <Grid
                width={'100%'}
                container
                alignItems={'center'}
                justifyContent={'center'}>
                <Loader />
            </Grid>
        );
    }

    return (
        <Form initialValues={initialValues || {}} onSubmit={handleSubmit}>
            {({ handleSubmit, values }) => (
                <form onSubmit={handleSubmit}>
                    <Box
                        pl={2}
                        pr={2}
                        pb={2}
                        width={'100%'}
                        bgcolor={theme.palette.backgroundB.backgroundB1}>
                        <FormPage
                            formConfig={fileDataConfig({
                                documentCategory: values?.documentCategory,
                                allowedCategories:
                                    data?.documentCategories || [],
                            })}
                        />
                    </Box>
                    <Box
                        display={'flex'}
                        justifyContent={'flex-end'}
                        p={3}
                        bgcolor={theme.palette.neutralWhite}>
                        <SubmitButton
                            text={buttonLabel}
                            isDisabled={isLoading}
                        />
                    </Box>
                </form>
            )}
        </Form>
    );
}
