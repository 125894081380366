import WidgetCard, { Widget } from '../../reusable/WidgetCard';
import { Grid, Typography } from '@mui/material';
import { useGetPaymentStatsQuery } from '../../api/payment/paymentAPI';
import { formatCurrency } from '../../utils/fieldMasks';

interface SummaryPayments {
    dueWithin30Days: number;
    yearToDateTotalPaid: number;
    averageRentPerAcre: number;
}

export default function PaymentOverviewWidgets() {
    const { data, isLoading } = useGetPaymentStatsQuery({});
    if (isLoading) return null;

    return (
        <Grid container justifyContent={'space-between'} rowGap={1}>
            <WidgetCard {...dueForMonth(data)} />
            <WidgetCard {...yearToDate(data)} />
            <WidgetCard {...averageRents(data)} />
        </Grid>
    );
}

const dueForMonth = (data: SummaryPayments): Widget => ({
    title: (
        <Typography variant={'font14'} fontWeight={'bold'}>
            DUE IN NEXT 30 DAYS
        </Typography>
    ),
    data: data?.dueWithin30Days,
    description: '',
});

const yearToDate = (data: SummaryPayments): Widget => ({
    title: (
        <Typography variant={'font14'} fontWeight={'bold'}>
            YEAR TO DATE
        </Typography>
    ),
    data: formatCurrency(data?.yearToDateTotalPaid),
    description: 'TOTAL PAID',
});
const averageRents = (data: SummaryPayments): Widget => ({
    title: (
        <Typography variant={'font14'} fontWeight={'bold'}>
            AVERAGE RENT
        </Typography>
    ),
    data: formatCurrency(data?.averageRentPerAcre),
    description: 'PER ACRE',
});
