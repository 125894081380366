import FormScreenWrapper from './FormScreenWrapper';
import { Box } from '@mui/material';
import AppTypography from '../../design/AppTypography';
import FormPage from '../../reusable/FormUI/FormPage';
import { FormConfig } from '../../design/Forms/interfaces';
import { composeValidators } from '../../utils/validation';
import {
    invalidFileType,
    tooLargeFile,
    tooManyFiles,
} from '../../utils/fileValidation';
import { MAX_FILE_SIZE } from '../../constants';

export default function EvacuationSafetyForm() {
    return (
        <FormScreenWrapper title="Evacuation and Safety">
            <Box display={'flex'} flexDirection={'column'} rowGap={2}>
                <AppTypography
                    variant="font16"
                    fontWeight={'medium'}
                    text={
                        'What is the evacuation plan for farm workers and family members?'
                    }
                    color={'text.neutral9'}
                />
                <FormPage formConfig={evacuationFormConfig} />
                <AppTypography
                    variant="font16"
                    fontWeight={'medium'}
                    text={
                        'What is the designated safe area or shelter on the property?'
                    }
                    color={'text.neutral9'}
                />{' '}
                <FormPage formConfig={safeAreaFormConfig} />
                <AppTypography
                    variant="font16"
                    fontWeight={'medium'}
                    text={
                        'How will you account for all personnel during an emergency?'
                    }
                    color={'text.neutral9'}
                />{' '}
                <FormPage formConfig={emergencySuppliesFormConfig} />
                <AppTypography
                    variant="font16"
                    fontWeight={'medium'}
                    text={
                        'Where are emergency supplies (first aid kits, fire extinguishers) located?'
                    }
                    color={'text.neutral9'}
                />
                <FormPage formConfig={personalAccountConfig} />
            </Box>
        </FormScreenWrapper>
    );
}
const evacuationFormConfig: FormConfig[] = [
    {
        formField: {
            scheme: 'twoThird',
            name: 'evacuationSafety.evacuationPlan',
            type: 'textarea',
            label: '',
        },
        renderProps: {
            resize: 'none',
            isDisabled: false,
            minRows: 3,
        },
    },
    {
        formField: {
            scheme: 'third',
            name: 'evacuationFile',
            type: 'fileupload',
            validation: composeValidators(
                tooLargeFile(MAX_FILE_SIZE),
                tooManyFiles(1),
                invalidFileType([
                    'image/png',
                    'image/jpg',
                    'image/jpeg',
                    'image/bmp',
                    'application/pdf',
                ]),
            ),
        },
        renderProps: {
            filesAccepted: ['.png', '.jpg', '.jpeg', '.bmp', '.pdf'],
            placeholder: 'Drag a document here',
            helperText: 'Attach Document',
            multipleFiles: false,
            isDisabled: false,
        },
    },
];
const safeAreaFormConfig: FormConfig[] = [
    {
        formField: {
            scheme: 'twoThird',
            name: 'evacuationSafety.safeArea',
            type: 'textarea',
            label: '',
        },
        renderProps: {
            resize: 'none',
            isDisabled: false,
            minRows: 3,
        },
    },
    {
        formField: {
            scheme: 'third',
            name: 'safeAreaFile',
            type: 'fileupload',
            validation: composeValidators(
                tooLargeFile(MAX_FILE_SIZE),
                tooManyFiles(1),
                invalidFileType([
                    'image/png',
                    'image/jpg',
                    'image/jpeg',
                    'image/bmp',
                    'application/pdf',
                ]),
            ),
        },
        renderProps: {
            filesAccepted: ['.png', '.jpg', '.jpeg', '.bmp', '.pdf'],
            placeholder: 'Drag a document here',
            helperText: 'Attach Document',
            multipleFiles: false,
            isDisabled: false,
        },
    },
];
const personalAccountConfig: FormConfig[] = [
    {
        formField: {
            scheme: 'twoThird',
            name: 'evacuationSafety.personnelAccounting',
            type: 'textarea',
            label: '',
        },
        renderProps: {
            resize: 'none',
            isDisabled: false,
            minRows: 3,
        },
    },
];
const emergencySuppliesFormConfig: FormConfig[] = [
    {
        formField: {
            scheme: 'twoThird',
            name: 'evacuationSafety.emergencySuppliesLocation',
            type: 'textarea',
            label: '',
        },
        renderProps: {
            resize: 'none',
            isDisabled: false,
            minRows: 3,
        },
    },
];
