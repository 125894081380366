import FarmTeamTree, { FarmTeamCard } from './FarmTeamTree';
import { makeStyles } from 'tss-react/mui';

export default function FarmTeam() {
    const { classes } = useStyles();
    return (
        <div className={classes.container}>
            <FarmTeamTree cardData={farmTeamCards} />
        </div>
    );
}
const useStyles = makeStyles()((theme) => ({
    container: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        minHeight: '70vh',
        backgroundColor: theme.palette.neutralWhite,
        padding: theme.spacing(2, 4, 2, 5),
    },
}));
export const farmTeamCards: FarmTeamCard = {
    id: '2',
    firstName: 'Dream Farm Holdings',
    lastName: '',
    role: '',
    isRoot: true,
    isDisabled: false,
    farmLogo:
        'https://t3.ftcdn.net/jpg/04/56/08/24/240_F_456082473_DYDUDKaRZnmxz1dNVnIxe9xnUXnBdNQu.jpg',
    children: [
        {
            id: '1',
            firstName: 'John',
            lastName: 'Doe',
            role: 'Farm Manager',
            isDisabled: false,
            farmLogo: 'https://i.pravatar.cc/150?img=1',
        },
        {
            id: '3',
            firstName: 'Bob',
            lastName: 'Johnson',
            role: 'Field Worker',
            isDisabled: false,
            farmLogo: 'https://i.pravatar.cc/150?img=3',
            children: [
                {
                    id: '4',
                    firstName: 'Clara',
                    lastName: 'Brown',
                    role: 'Field Worker',
                    isDisabled: false,
                    farmLogo: 'https://i.pravatar.cc/150?img=4',
                },
                {
                    id: '5',
                    firstName: 'David',
                    lastName: 'White',
                    role: 'Field Worker',
                    isDisabled: false,
                    farmLogo: 'https://i.pravatar.cc/150?img=5',
                },
                {
                    id: '6',
                    firstName: 'Evelyn',
                    lastName: 'Green',
                    role: 'Field Worker',
                    isDisabled: false,
                    farmLogo: 'https://i.pravatar.cc/150?img=6',
                },
                {
                    id: '7',
                    firstName: 'Frank',
                    lastName: 'Travis',
                    role: 'Equipment Operator',
                    isDisabled: false,
                    farmLogo: 'https://i.pravatar.cc/150?img=7',
                },
                {
                    id: '8',
                    firstName: 'Grace',
                    lastName: 'Hall',
                    role: 'Field Supervisor',
                    isDisabled: false,
                    farmLogo: 'https://i.pravatar.cc/150?img=8',
                },
                {
                    id: '9',
                    firstName: 'Henry',
                    lastName: 'Young',
                    role: 'Irrigation Specialist',
                    isDisabled: false,
                    farmLogo: 'https://i.pravatar.cc/150?img=9',
                },
                {
                    id: '10',
                    firstName: 'Isla',
                    lastName: 'Miller',
                    role: 'Pest Control Specialist',
                    isDisabled: false,
                    farmLogo: 'https://i.pravatar.cc/150?img=10',
                },
            ],
        },
    ],
};
