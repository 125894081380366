import { EmailStatus } from './AllProfilesTable';
import useVerificateEmail from './useVerificateEmail';
import { Box, Button, Typography } from '@mui/material';

interface OwnProps {
    id: string;
    status?: EmailStatus;
    isSecondaryEmail?: boolean;
    isHidden?: boolean;
}
export default function SendVerifyButton({
    id,
    status,
    isHidden,
    isSecondaryEmail,
}: OwnProps) {
    const { handleVerifyEmail } = useVerificateEmail({
        isSecondaryEmail,
    });
    if (isHidden) return null;
    return (
        <Box display={'flex'} flexDirection={'column'} rowGap={1}>
            <Typography variant={'font14'} fontWeight={'regular'}>
                Send Reminder
            </Typography>
            <Button
                sx={{ width: '100%' }}
                fullWidth
                variant={'contained'}
                onClick={() => handleVerifyEmail(id)}>
                {status && buttonNameMapper[status]}
            </Button>
        </Box>
    );
}
const buttonNameMapper = {
    FAILED: 'Resend Invite',
    UNVERIFIED: 'Verify Now',
    VERIFIED: '',
    PENDING: '',
};
