import React from 'react';

import Tree from 'react-d3-tree';
import { makeStyles } from 'tss-react/mui';
import TreeCard from './TreeCard';
import { useCenteredTree } from './useCenteredTree';

interface OwnProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    cardData: any;
    landPartnerId?: string;
    contactIdForMarking?: string;
}

export default function RelationshipsOrganizationTree({
    cardData,
    landPartnerId,
    contactIdForMarking,
}: OwnProps) {
    const { classes } = useStyles();
    const [translate, containerRef] = useCenteredTree();

    return (
        <div className={classes.container} ref={containerRef}>
            <Tree
                separation={{ siblings: 2, nonSiblings: 2 }}
                data={cardData}
                translate={translate}
                zoom={0.9}
                depthFactor={250}
                orientation="vertical"
                pathFunc={'step'}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                renderCustomNodeElement={(rd3tProps: any) => (
                    <TreeCard
                        {...rd3tProps}
                        landPartnerId={landPartnerId}
                        contactIdForMarking={contactIdForMarking}
                    />
                )}
            />
        </div>
    );
}

const useStyles = makeStyles()((theme) => ({
    container: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        minHeight: '70vh',
        backgroundColor: theme.palette.neutralWhite,
    },
}));
