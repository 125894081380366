import React, { useState } from 'react';
import { Box, Grid } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import theme from '../../../styles/theme';
import AppTypography from '../../../design/AppTypography';
import SmileLabel from '../../../design/Labels/SmileLabel';
import { Form } from 'react-final-form';
import FormPage from '../../../reusable/FormUI/FormPage';
import SubmitButton from '../../../design/Buttons/SubmitButton';

import { makeStyles } from 'tss-react/mui';
import { FormConfig } from '../../../design/Forms/interfaces';
import { composeValidators } from '../../../utils/validation';
import {
    invalidFileType,
    tooLargeFile,
    tooManyFiles,
} from '../../../utils/fileValidation';
import { MAX_FILE_SIZE } from '../../../constants';
import useHandleSubmitRTK from '../../../hooks/useHandleSubmit';
import {
    useGetDocumentDataByIdQuery,
    useUploadRequestedDocumentMutation,
} from '../../../api/documents/documentsAPI';
import Loader from '../../../design/BaseLoader';

import Paths from '../../../router/paths';
import { convertDateFromStr } from '../../../utils/dateFormat';

interface FormValues {
    documents: File[];
    notes: string;
}

export default function RedirectPageUploadDocument() {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [file, setFile] = useState<File | null>(null);
    const token = searchParams.get('requestId');
    const contact = searchParams.get('contact');
    const note = searchParams.get('note');
    const time = searchParams.get('time');
    const user = searchParams.get('user');
    const organizationId = searchParams.get('organizationId');
    const landPartnerId = searchParams.get('landPartnerId');

    const { isLoading: isDataLoading } = useGetDocumentDataByIdQuery({
        documentId: token,
    });

    const { handleSubmit, isLoading } = useHandleSubmitRTK({
        useRtkHook: useUploadRequestedDocumentMutation,
        successMessage: 'File uploaded successfully',
        onSuccess: () =>
            navigate(`/${Paths.shared}/${Paths.success}`, {
                state: {
                    fileName: file?.name,
                    name: contact,
                },
            }),
    });

    function handleUpload(values: FormValues) {
        handleSubmit({
            requestId: token,
            organizationId,
            landPartnerId,
            ...values,
        });
    }

    const { classes } = useStyles();
    if (isDataLoading) {
        return (
            <Grid
                container
                sx={{ height: '100vh' }}
                alignItems={'center'}
                justifyContent={'center'}
                flexDirection={'column'}>
                <Loader />
            </Grid>
        );
    }

    return (
        <Box
            width={'100wv'}
            height={'100vh'}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            bgcolor={theme.palette.backgroundB.backgroundB3}>
            <Box
                rowGap={3}
                p={theme.spacing(3, 4, 2)}
                display={'flex'}
                flexDirection={'column'}
                width={'820px'}
                bgcolor={theme.palette.neutralWhite}
                borderRadius={'16px'}>
                <Box
                    display={'flex'}
                    columnGap={4}
                    width={'100%'}
                    alignItems={'center'}>
                    <SmileLabel />
                    <AppTypography
                        variant="font36"
                        fontWeight={'light'}
                        color="text.neutral9"
                        text={`Hi, ${contact}`}
                    />
                </Box>
                <Box
                    display={'flex'}
                    columnGap={4}
                    width={'70%'}
                    flexDirection={'column'}>
                    <AppTypography
                        flexWrap={true}
                        variant="font20"
                        textAlign={'left'}
                        fontWeight={'medium'}
                        color="text.neutral9"
                        text={`${user} has requested a document: ${note}`}
                    />
                    <AppTypography
                        flexWrap={true}
                        variant="font20"
                        textAlign={'left'}
                        fontWeight={'medium'}
                        color="text.neutral9"
                        text={` Please note the requestor has asked this document to be sent by  ${convertDateFromStr(time)}`}
                        isHidden={!time}
                    />
                </Box>
                <Form onSubmit={handleUpload} initialValues={{}}>
                    {({ handleSubmit, values }) => (
                        <form onSubmit={handleSubmit}>
                            <FormPage formConfig={uploadFormConfig} />
                            <div className={classes.bottomContainer}>
                                <SubmitButton
                                    onClick={() =>
                                        setFile(values?.documents?.[0] || null)
                                    }
                                    variant={'contained'}
                                    isLoading={isLoading}
                                    text={'Save Document'}
                                />
                            </div>
                        </form>
                    )}
                </Form>
            </Box>
        </Box>
    );
}

const uploadFormConfig: FormConfig[] = [
    {
        formField: {
            scheme: 'full',
            name: 'documents',
            type: 'fileupload',
            validation: composeValidators(
                tooLargeFile(MAX_FILE_SIZE),
                tooManyFiles(1),
                invalidFileType([
                    'image/png',
                    'image/jpg',
                    'image/jpeg',
                    'image/bmp',
                    'application/pdf',
                ]),
            ),
        },
        renderProps: {
            size: 'medium',
            filesAccepted: ['.png', '.jpg', '.jpeg', '.bmp', '.pdf'],
            placeholder: 'Drag a document here',
            helperText: 'Or select from your computer',
            multipleFiles: false,
            isDisabled: false,
        },
    },
];

const useStyles = makeStyles()((theme) => ({
    bottomContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        gap: '16px',
        height: 70,
        width: '100%',
        padding: theme.spacing(3),
        backgroundColor: theme.palette.neutralWhite,
    },
}));
