import { leaseType } from '../../pages/LandPortfolio/SelectLeaseTypeModal';
import { format } from 'date-fns';
import customToastWithAlert from '../../design/CustomToastWithAlert';
import { FSA } from './LeaseDetailForm';
import { DATE_FORMAT } from '../../utils/dateFormat';
import {
    Lease,
    PaymentDistributionsDates,
    PaymentDistributionsLandowners,
} from '../../pages/LandPortfolio/TableConfig';
import { LandPartnerUpdate } from '../ViewLandownersDetails/LandPartnerDetails';
import { sortBy } from 'lodash';

export function convertUploadDataToField(data: Lease) {
    return [
        {
            title: 'Landowner',
            value: `${
                data?.landowners?.length
                    ? data.landowners
                          ?.map((i: { name: string }) => i.name)
                          .join(', ')
                    : 'N/A'
            }`,
        },
        {
            title: 'Lease Type',
            value: leaseType.find((i) => i.id === data.leaseType)?.name,
        },
        {
            title: 'Number of Payments per Year',
            value: data?.numberOfPaymentsPerYear || 'N/A',
        },
        {
            title: 'Lease Start Date',
            value: data.startDate
                ? format(+data.startDate, DATE_FORMAT)
                : 'N/A',
        },
        {
            title: 'Lease End Date',
            value: data.endDate ? format(+data.endDate, DATE_FORMAT) : 'N/A',
        },
    ];
}

export function validateFsaStep(pageNumber: number, fsaData: FSA[]) {
    const isFsaExist = fsaData.length && fsaData.some((i) => i.fsaId);
    if (pageNumber === 1 && !isFsaExist) {
        customToastWithAlert({
            type: 'error',
            message: 'FSA field cannot be empty.',
        });
        return false;
    }
    return true;
}
export function convertArrToObjectLandowners(
    arr: PaymentDistributionsLandowners[],
): {
    [key: string]: string;
} {
    const result: { [key: string]: string } = {};
    for (const obj of arr) {
        result[obj.landownerId] = obj.paymentPercent;
    }
    return result;
}

export function convertArrToObjectDates(arr: PaymentDistributionsDates[]): {
    [key: string]: string;
} {
    const result: { [key: string]: string } = {};
    arr.forEach((item, index) => {
        result[`paymentDate${index + 1}`] = item.paymentPercent;
    });

    return result;
}
export function getCommonDraftInitialValues(
    data: LandPartnerUpdate,
    state: Lease,
) {
    const paymentDates = state?.paymentDistributions?.paymentDates || [];

    const sortedPaymentSchedule = sortBy(
        state?.paymentSchedule || [],
        (date: string) => new Date(date),
    );
    const sortedPaymentDates = sortBy(
        paymentDates,
        (item: PaymentDistributionsDates) => new Date(item.paymentDate),
    );
    return {
        grower: data.organization.id || '',
        numberOfPaymentsPerYear:
            state?.numberOfPaymentsPerYear ||
            state?.paymentSchedule?.length ||
            '',
        startDate: state?.startDate && new Date(state?.startDate),
        endDate: state?.endDate && new Date(state?.endDate),
        bonusSchedule: state?.bonusSchedule && new Date(state?.bonusSchedule),
        doLumpSum: state?.lumpSum ? 'lumpSum' : 'paymentsByField',
        paymentSchedule: sortedPaymentSchedule.map(
            (date: string) => new Date(date),
        ),
        paymentDistributions: {
            landowners:
                state?.paymentDistributions?.landowners &&
                convertArrToObjectLandowners(
                    state?.paymentDistributions?.landowners,
                ),
            paymentDates:
                sortedPaymentDates.length > 0 &&
                convertArrToObjectDates(sortedPaymentDates),
        },
        ...initialPaymentDates(sortedPaymentSchedule),
    };
}
export function getCommonInitialValues(initData: Lease) {
    const paymentDates = initData?.paymentDistributions?.paymentDates || [];

    const sortedPaymentSchedule = sortBy(
        initData?.paymentSchedule || [],
        (date: string) => new Date(date),
    );
    const sortedPaymentDates = sortBy(
        paymentDates,
        (item: PaymentDistributionsDates) => new Date(item.paymentDate),
    );
    return {
        grower: initData?.grower?.id || '',
        numberOfPaymentsPerYear: initData?.paymentSchedule?.length || '',
        startDate: initData?.startDate && new Date(initData?.startDate),
        endDate: initData?.endDate && new Date(initData?.endDate),
        bonusSchedule:
            initData?.bonusSchedule && new Date(initData?.bonusSchedule),
        paymentSchedule: sortedPaymentSchedule.map(
            (date: string) => new Date(date),
        ),
        doLumpSum: initData?.lumpSum ? 'lumpSum' : 'paymentsByField',
        paymentDistributions: {
            landowners:
                initData?.paymentDistributions?.landowners &&
                convertArrToObjectLandowners(
                    initData?.paymentDistributions?.landowners,
                ),
            paymentDates:
                sortedPaymentDates.length > 0 &&
                convertArrToObjectDates(sortedPaymentDates),
        },
        ...initialPaymentDates(sortedPaymentSchedule),
    };
}
function initialPaymentDates(paymentDates: string[]): { [p: string]: Date } {
    const result: { [key: string]: Date } = {};
    paymentDates.forEach((date, index) => {
        result[`paymentSchedule${index}`] = new Date(date);
    });
    return result;
}
