import React from 'react';
import { Box, Step, StepLabel, Stepper, Typography } from '@mui/material';
import CustomStepIcon from '../../design/Stepper/CustomStepIcon';
import { makeStyles } from 'tss-react/mui';
interface OwnProps {
    stepNumber: number;
    stepName: string;
}
export default function InProgressStepper({ stepNumber, stepName }: OwnProps) {
    const { classes } = useStyles();
    return (
        <Box display={'flex'} columnGap={2}>
            <Stepper
                alternativeLabel
                activeStep={stepNumber}
                orientation="horizontal"
                sx={{ backgroundColor: 'transparent' }}>
                {Array.from({ length: 5 }, (_, index) => (
                    <Step key={index} completed={index < stepNumber}>
                        <StepLabel
                            className={classes.stepLabel}
                            StepIconComponent={CustomStepIcon}
                        />
                    </Step>
                ))}
            </Stepper>
            <Typography variant="font14" fontWeight="bold">
                {stepName}
            </Typography>
        </Box>
    );
}
const useStyles = makeStyles()(() => ({
    stepLabel: {
        '& .MuiStepLabel-label': {
            fontSize: 20,
            fontWeight: 600,
        },
    },
}));
