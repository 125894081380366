import React, { useState } from 'react';

import { Box } from '@mui/material';

import TwoColumnLayout from '../../layouts/TwoColumnLayout';
import Stepper from '../../design/Stepper/Stepper';
import LandPartnerForm from './LandPartnerForm';
import {
    SocialMedia,
    Status,
} from '../ViewLandownersDetails/LandPartnerDetails';
import { useLocation, useParams } from 'react-router-dom';
import { PhoneType } from '../../reusable/FormUI/PhoneNumberField';

export interface LandPartner {
    id: string;
    landPartnerLogo?: File[];
    name: string;
    status: Status;
    taxId: string;
    email: string;
    associatedAcres: number;
    primaryContact: PrimaryContact;
    address: Address;
    billingAddress: Address;
    socialMedia: SocialMedia;
    relationshipStartDate: string;
}

export interface PrimaryContact {
    existingPrimaryContactId: string;
    newPrimaryContact: Contact;
    childContacts: Contact[];
}

export interface Contact {
    id: string;
    landPartner: string;
    parentContactId: string;
    preferredContactMethod: string;
    firstName: string;
    lastName: string;
    primaryEmail: string;
    secondaryEmail: string;
    contactNumbers: PhoneType[];
    relationship: string;
    relationshipDescription: string;
    keyDates: KeyDates;
    advocate: typeOfAdvocacy[];
    addresses: Address[];
    socialMedia: SocialMedia;
    notes: Note[];
}

export interface KeyDates {
    birthDate: string;
    relationshipStartDate: string;
    anniversary: string;
    dateOfDeath: string;
}

export interface Address {
    id: string;
    type: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    zipCode: string;
}

export interface Note {
    content: string;
    contactId: string;
}

export type typeOfAdvocacy =
    | 'BLOCKER'
    | 'CHAMPION'
    | 'INFLUENCER'
    | 'KEY_DECISION_MAKER'
    | 'DO_NOT_CONTACT';

export default function LandPartnerWizard() {
    const [pageNumber, setPageNumber] = useState(0);
    const { id } = useParams<{ id: string }>();
    const [isValid, setIsValid] = useState(false);
    const isEdit = !!id;
    const location = useLocation();
    const isGrower = location.pathname.includes('growers');
    const headerTitleCreate = [
        isGrower ? 'Grower Detail' : 'Land Partner Detail',
        'Primary Contact',
        'Members',
        'Billing',
        'Summary',
    ];

    const headerTitleUpdate = [
        isGrower ? 'Grower Detail' : 'Land Partner Detail',
        'Primary Contact',
        'Billing',
        'Summary',
    ];
    return (
        <Box m={1} bgcolor={'white'}>
            <TwoColumnLayout
                header={
                    isEdit
                        ? headerTitleUpdate[pageNumber]
                        : headerTitleCreate[pageNumber]
                }
                type={'third'}
                leftComponent={
                    <Box p={4}>
                        <Stepper
                            key={'land-partner'}
                            activeStep={pageNumber}
                            isNavigable={isValid && isEdit}
                            setActiveStep={setPageNumber}
                            steps={
                                isEdit
                                    ? landPartnerStepsUpdate
                                    : landPartnerStepsCreate
                            }
                        />
                    </Box>
                }
                rightComponent={
                    <LandPartnerForm
                        setIsValid={setIsValid}
                        pageNumber={pageNumber}
                        setPageNumber={setPageNumber}
                    />
                }
            />
        </Box>
    );
}

const landPartnerStepsCreate = [
    { name: 'Step 1: Organization', description: '' },
    { name: 'Step 2: Primary Contact', description: '' },
    { name: 'Step 3: Members', description: '' },
    { name: 'Step 4: Billing', description: '' },
    { name: 'Step 5: Review', description: '' },
];
const landPartnerStepsUpdate = [
    { name: 'Step 1: Organization', description: '' },
    { name: 'Step 2: Primary Contact', description: '' },
    { name: 'Step 3: Billing', description: '' },
    { name: 'Step 4: Review', description: '' },
];
