import React from 'react';
import theme from '../../../styles/theme';
import useStyles from './Organizations.styles';
import BasicTabsPanel from '../../../design/TabsPanel/BasicTabsPanel';
import AllOrganizations from './AllOrganizations/AllOrganizations';
import Breadcrumbs from '../../../design/Breadcrumbs/Breadcrumbs';
import useBreadcrumbs from '../../../hooks/useBreadcrumbs';

const tabsConfig = [
    {
        label: 'All Organizations',
        content: <AllOrganizations />,
        value: 0,
        to: '',
    },
    {
        label: 'Billing',
        content: <div>billing</div>,
        value: 1,
        to: '',
    },
    {
        label: 'Activity ',
        content: <div>activity</div>,
        value: 2,
        to: '',
    },
];

export default function Organizations() {
    const { classes } = useStyles(theme);
    const routes = {
        '/settings/orgAndUsers/organizations': 'Organizations',
    };

    const breadcrumbs = useBreadcrumbs({
        homePath: '/settings/orgAndUsers',
        homePathName: 'Settings',
        routes,
    });
    return (
        <div className={classes.organizationsWrapper}>
            <Breadcrumbs collapsed={false} links={breadcrumbs} />
            <BasicTabsPanel tabItems={tabsConfig} />
        </div>
    );
}
