import { Grid } from '@mui/material';
import React from 'react';
import { useGetDashboardTmpQuery } from '../../../../api/sharedData/sharedDataTempAccessAPI';
import Loader from '../../../../design/BaseLoader';
import SharedDataDashboardMetrics from './SharedDataDashboardMetrics';

interface OwnProps {
    token: string;
}

export default function SharedDataDashboard({ token }: OwnProps) {
    const { data: dashboardData, isLoading: isDashboardDataLoading } =
        useGetDashboardTmpQuery({
            token,
        });

    if (isDashboardDataLoading) {
        return (
            <Grid
                container
                alignItems={'center'}
                justifyContent={'center'}
                flexDirection={'column'}>
                <Loader />
            </Grid>
        );
    }

    return <SharedDataDashboardMetrics dashboardData={dashboardData} />;
}
