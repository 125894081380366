import React, { ReactNode } from 'react';
import { NotificationType } from './NotificationCard';
import { Badge } from '@mui/material';
import { ReactComponent as BirthdayIcon } from '../../assets/icons/birthday_icon.svg';
import { ReactComponent as PaymentCardIcon } from '../../assets/icons/payment_card_icon.svg';
import { ReactComponent as CalendarReminderIcon } from '../../assets/icons/calendar_reminder_icon.svg';
import { ReactComponent as CheckIcon } from '../../assets/icons/check_icon.svg';
import theme from '../../styles/theme';

interface OwnProps {
    type: NotificationType;
    children: ReactNode;
}
export default function BadgeForNotificationAvatar({
    type,
    children,
}: OwnProps) {
    return (
        <Badge
            overlap="circular"
            badgeContent={badgeMapper[type]?.icon}
            sx={{
                '& .MuiBadge-badge': {
                    backgroundColor: badgeMapper[type]?.color,
                    padding: theme.spacing(0.5),
                    border: 'solid 2px white',
                    '& svg': {
                        width: 12,
                        height: 12,
                        fill: theme.palette.neutralWhite,
                    },
                },
            }}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
            {children}
        </Badge>
    );
}

const badgeMapper: Record<
    NotificationType,
    { color: string; icon: React.JSX.Element }
> = {
    BIRTHDAY: {
        icon: <BirthdayIcon />,
        color: `${theme.palette.brandLightTurq}`,
    },
    ANNIVERSARY: {
        icon: <CalendarReminderIcon />,
        color: `${theme.palette.limeGreen.limeGreenShade2}`,
    },

    LEASE_RENEWALS: {
        icon: <CalendarReminderIcon />,
        color: `${theme.palette.brandLightTurq}`,
    },
    PAYMENT: {
        icon: <PaymentCardIcon />,
        color: `${theme.palette.brandDarkTurq}`,
    },
    TASKS: {
        icon: <CheckIcon />,
        color: `${theme.palette.yellowGreen.yellowGreenShade7}`,
    },
    REMINDER: {
        icon: <CalendarReminderIcon />,
        color: `${theme.palette.limeGreen.limeGreenShade2}`,
    },
    ACTIVITY_FAILED: {
        icon: <CalendarReminderIcon />,
        color: `${theme.palette.limeGreen.limeGreenShade2}`,
    },
};
