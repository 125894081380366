import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material/styles/createTheme';

const useStyles = makeStyles<Theme>()((theme) => ({
    signInWrapper: {
        overflow: 'hidden',
        display: 'flex',
        height: '100vh',
        width: '100vw',
    },
    mainContainer: {
        padding: '120px 130px 0 110px',
        display: 'flex',
        flexDirection: 'column',
        width: '50vw',
    },
    formContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
    },
    logo: {
        width: '225px',
        height: '75px',
    },
    link: {
        fontSize: '11px',
        fontWeight: 500,
        lineHeight: '1.27',
        color: theme.palette.brandLightTurq,
        textDecoration: 'unset',
    },
    imageContainer: {
        height: '100vh',
        overflowY: 'hidden',
        width: '50vw',
        display: 'flex',
        alignItems: 'flex-end',
    },
    loginImage: {
        width: '100%',
        maxWidth: '50vw',
    },
}));

export default useStyles;
