import { Box } from '@mui/material';
import AppTypography from '../../design/AppTypography';

export interface TextTitle {
    title: string;
    value?: string;
    isHidden?: boolean;
}

export default function TextWithTitle({ title, value, isHidden }: TextTitle) {
    if (isHidden) return null;
    return (
        <Box display={'flex'} flexDirection={'column'} rowGap={1}>
            <AppTypography
                text={title}
                variant={'font12'}
                fontWeight={'medium'}
                color={'text.secondary'}
            />
            <AppTypography
                flexWrap={true}
                text={value}
                variant={'font14'}
                fontWeight={'regular'}
                color={'text.neutral7'}
            />
        </Box>
    );
}
