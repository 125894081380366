import { LeaseType } from './LeaseWizard';
import { Button, Typography } from '@mui/material';
import { ReactComponent as ShuffleIcon } from '../../assets/icons/shuffle_icon.svg';
import { ReactComponent as MoneyIcon } from '../../assets/icons/money_icon.svg';
import { ReactComponent as PieChartIcon } from '../../assets/icons/pie-chart_icon.svg';
import { ReactNode } from 'react';
import { makeStyles } from 'tss-react/mui';
import theme from '../../styles/theme';
interface OwnProps {
    type: LeaseType;
    onClick: (type: LeaseType) => void;
}
interface TileSettings {
    title: string;
    icon: ReactNode;
}

export default function LeaseTile({ type, onClick }: OwnProps) {
    const { classes } = useStyles();
    return (
        <Button
            className={classes.button}
            variant="contained"
            color={'success'}
            onClick={() => {
                onClick(type);
            }}
            startIcon={getLeaseType[type].icon}>
            <Typography variant={'font20'} fontWeight={'medium'}>
                {getLeaseType[type].title}
            </Typography>
        </Button>
    );
}

const useStyles = makeStyles()(() => ({
    button: {
        display: 'flex',
        justifyContent: 'flex-start',
        columnGap: theme.spacing(2),
        padding: theme.spacing(4),
        width: '100%',
        '&:hover': {
            backgroundColor: theme.palette.lightTurq.lightTurqTint9,
        },
        '&svg': {
            width: '24px',
            height: '24px',
            fill: theme.palette.lightTurq.lightTurqTint1,
        },
    },
}));
const getLeaseType: Record<LeaseType, TileSettings> = {
    FIXED: {
        title: 'Cash/Fixed',
        icon: <MoneyIcon />,
    },
    CROPSHARE: {
        title: 'Crop Share',
        icon: <PieChartIcon />,
    },
    FLEX: {
        title: 'Flex/Variable',
        icon: <ShuffleIcon />,
    },
};
