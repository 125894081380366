import React from 'react';
import UserAvatar from '../../design/Avatar/UserAvatar';
import { Box, Typography } from '@mui/material';
import PATHS from '../../router/paths';
import { LinkedLandPartner } from '../../pages/AllProfiles/AllProfiles';
import LinkText from '../../design/Fields/LinkText';

export interface UserMain {
    id: string;
    landPartners: LinkedLandPartner[];
    name: string;
    avatar?: string;
    role?: string;
    isPrimaryContact?: boolean;
}

export default function UserMainInfo({
    name,
    role,
    avatar,
    isPrimaryContact,
    landPartners,
}: UserMain) {
    return (
        <Box display={'flex'} alignItems={'center'}>
            <UserAvatar name={name} avatar={avatar} size={'xlarge'} />
            <Box display={'flex'} flexDirection={'column'} p={3}>
                <Typography
                    variant="font20"
                    fontWeight="medium"
                    color="text.secondary">
                    {name}
                </Typography>
                <Typography
                    textTransform={'capitalize'}
                    variant={'font14'}
                    fontWeight={'medium'}
                    color={'text.light'}>
                    {role}
                </Typography>
                {isPrimaryContact && (
                    <Typography
                        textTransform={'capitalize'}
                        variant={'font14'}
                        fontWeight={'medium'}
                        color={'text.light'}>
                        Primary Contact
                    </Typography>
                )}
                {landPartners.map(({ id: landPartnerId, name }) => (
                    <LinkText
                        key={landPartnerId}
                        to={
                            landPartnerId
                                ? `/${PATHS.landpartners}/${PATHS.profiles}/${landPartnerId}`
                                : ''
                        }
                        text={name}
                        variant={'font14'}
                    />
                ))}
            </Box>
        </Box>
    );
}
