import { ReactNode } from 'react';
import { Box, Button } from '@mui/material';

export interface CardOtherServices {
    leftSide: ReactNode;
    body: ReactNode;
    button?: {
        text: string;
        onClick: () => void;
        endIcon?: ReactNode;
    };
}

export default function OtherServicesCard({
    leftSide,
    body,
    button,
}: CardOtherServices) {
    return (
        <Box
            display={'flex'}
            columnGap={2}
            width={'100%'}
            p={2}
            borderRadius={'16px'}
            bgcolor={'#ffffff'}>
            <Box display={'flex'} alignItems={'center'}>
                {leftSide}
            </Box>
            <Box display={'flex'} flexDirection={'column'} rowGap={2}>
                {body}
                {button && (
                    <Box
                        width={'100%'}
                        display={'flex'}
                        justifyContent={'flex-end'}>
                        <Button
                            endIcon={button?.endIcon}
                            variant={'contained'}
                            onClick={button.onClick}>
                            {button.text}
                        </Button>
                    </Box>
                )}
            </Box>
        </Box>
    );
}
