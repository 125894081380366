import { Box, Divider } from '@mui/material';
import { formOrganizationConfig } from './formOrganizationConfig';
import FormPage from '../../reusable/FormUI/FormPage';
import PreviewPhotoField from '../../reusable/FormUI/PreviewPhotoField';
import { FormConfig } from '../../design/Forms/interfaces';
import { composeValidators } from '../../utils/validation';
import {
    invalidFileType,
    tooLargeFile,
    tooManyFiles,
} from '../../utils/fileValidation';
import { MAX_FILE_SIZE } from '../../constants';
import { useLocation } from 'react-router-dom';

export default function OrganizationForm() {
    const location = useLocation();
    const isGrower = location.pathname.includes('growers');
    return (
        <Box display={'flex'} flexDirection={'column'} rowGap={2}>
            <FormPage formConfig={formOrganizationConfig(isGrower)} />
            <Divider orientation={'horizontal'} variant={'fullWidth'} />
            <PreviewPhotoField
                formConfig={PhotoUploadConfig}
                photoFieldName={'landPartnerLogo'}
            />
        </Box>
    );
}

const PhotoUploadConfig: FormConfig[] = [
    {
        formField: {
            name: 'landPartnerLogo',
            type: 'fileupload',
            scheme: 'half',
            label: 'Upload Company Logo',
            validation: composeValidators(
                tooLargeFile(MAX_FILE_SIZE),
                tooManyFiles(1),
                invalidFileType([
                    'image/png',
                    'image/jpg',
                    'image/jpeg',
                    'image/bmp',
                ]),
            ),
        },
        renderProps: {
            filesAccepted: ['.png', '.jpg', '.jpeg', '.bmp'],
            placeholder: 'Or select from your computer',
            helperText: 'Drag photo here',
            multipleFiles: false,
            isDisabled: false,
        },
    },
];
