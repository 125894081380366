import React from 'react';
import { Box } from '@mui/material';
import theme from '../../styles/theme';

import ExpandComponentColumn from '../PaymentsOverview/ExpandComponentColumn';
import { PaymentScheduledData } from '../PaymentsOverview/interfaces';

interface OwnProps {
    data: PaymentScheduledData;
}
export default function ExpandedLeasePayment({ data }: OwnProps) {
    return (
        <Box
            width={'100%'}
            display={'flex'}
            sx={{
                backgroundColor: theme.palette.backgroundB.backgroundB2,
                '& :last-child': {
                    borderRight: 'none',
                },
            }}>
            <ExpandComponentColumn
                title={'LEASE'}
                text={data?.lease?.leaseName}
                isHidden={!data?.lease}
            />{' '}
            <ExpandComponentColumn
                title={'TRACT/TCP'}
                text={'N/A'}
                isHidden={false}
            />
            <ExpandComponentColumn
                title={'EXPENSES'}
                text={data?.lease?.expenses ? 'Y' : 'N'}
                isHidden={false}
            />
        </Box>
    );
}
