export type TypographyVariant =
    | 'font36'
    | 'font32'
    | 'font24'
    | 'font20'
    | 'font16'
    | 'font14'
    | 'font13'
    | 'font12'
    | 'font11';

export type TypographyWeight = 'light' | 'regular' | 'medium' | 'bold';
export type TypographyCase = 'capitalize' | 'uppercase' | 'lowercase';
declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides {
        font36: true;
        font32: true;
        font24: true;
        font20: true;
        font16: true;
        font14: true;
        font13: true;
        font12: true;
        font11: true;
        h1: false;
        h2: false;
        h3: false;
        h4: false;
        h5: false;
        h6: false;
        subtitle1: false;
        subtitle2: false;
        body1: false;
        body2: false;
        button: false;
        caption: false;
        overline: false;
    }
}

const TypographySettings = {
    color: '#2C2E30',
    useNextVariants: true,
    fontFamily: ['Montserrat', 'Coolvetica', 'sans-serif'].join(','),
    light: 300,
    regular: 500,
    medium: 600,
    bold: 700,
    font36: { fontSize: '36px', lineHeight: '40px' },
    font32: { fontSize: '32px', lineHeight: '40px' },
    font24: { fontSize: '24px', lineHeight: '32px' },
    font20: { fontSize: '20px', lineHeight: '28px' },
    font16: { fontSize: '16px', lineHeight: '24px' },
    font14: { fontSize: '14px', lineHeight: '20px' },
    font13: { fontSize: '13px', lineHeight: '18px' },
    font12: { fontSize: '12px', lineHeight: '16px' },
    font11: { fontSize: '11px', lineHeight: '14px' },
};

export default TypographySettings;
