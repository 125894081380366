import { Grid, Typography } from '@mui/material';
import WidgetCard, { Widget } from '../../reusable/WidgetCard';
import { useGetTasksSummaryQuery } from '../../api/tasks/tasksAPI';

interface SummaryTasks {
    createdLastMonth: number;
    completedLastMonth: number;
    activeTasksCount: number;
    longestTaskDays: number;
}

export default function WidgetPanel() {
    const { data, isLoading } = useGetTasksSummaryQuery('');
    if (isLoading) return null;
    return (
        <Grid container justifyContent={'space-between'} rowGap={1}>
            <WidgetCard {...last30Days(data)} />
            <WidgetCard {...last30DaysCompleted(data)} />
            <WidgetCard {...yourTask(data)} />
            <WidgetCard {...activity(data)} />
        </Grid>
    );
}

const last30Days = (data: SummaryTasks): Widget => ({
    title: (
        <Typography variant={'font14'} fontWeight={'bold'}>
            IN THE LAST{' '}
            <Typography
                variant={'font14'}
                fontWeight={'bold'}
                color={'limeGreen.limeGreenShade5'}>
                30 DAYS
            </Typography>
        </Typography>
    ),
    data: data?.createdLastMonth,
    description: 'Team Tasks Created',
});

const last30DaysCompleted = (data: SummaryTasks): Widget => ({
    title: (
        <Typography variant={'font14'} fontWeight={'bold'}>
            IN THE LAST{' '}
            <Typography
                variant={'font14'}
                fontWeight={'bold'}
                color={'limeGreen.limeGreenShade5'}>
                30 DAYS
            </Typography>
        </Typography>
    ),
    data: data?.completedLastMonth,
    description: 'Team Tasks Completed',
});

const yourTask = (data: SummaryTasks): Widget => ({
    data: data?.activeTasksCount,
    description: 'Your Tasks',
});
const activity = (data: SummaryTasks): Widget => ({
    data: data?.longestTaskDays && data.longestTaskDays + ' Days',
    description: 'Longest',
});
