import React from 'react';
import { Puff } from 'react-loader-spinner';
import palette from '../styles/palette';
import { Box } from '@mui/material';

interface OwnProps {
    size?: 'small' | 'medium' | 'large';
}

export default function Loader({ size = 'large' }: OwnProps) {
    let dimension;
    if (size === 'small') {
        dimension = 80;
    } else if (size === 'medium') {
        dimension = 100;
    } else {
        dimension = 150;
    }

    return (
        <Box display={'flex'} height={'100%'} alignItems={'center'}>
            <Puff
                height={dimension}
                width={dimension}
                color={palette.primary.main}
                ariaLabel="puff-loading"
                visible={true}
            />
        </Box>
    );
}
