import { Box } from '@mui/material';
import GrowersDashboard from './GrowersDashboard';
import GrowersTable from './GrowersTable';
export default function Growers() {
    return (
        <Box display={'flex'} flexDirection={'column'} rowGap={2}>
            <GrowersDashboard />
            <GrowersTable />
        </Box>
    );
}
