import { OAKENApi } from '../OAKENApi';
import { ENDPOINTS } from '../endpoints';
import { HTTP_METHOD } from '../auth/http';

export interface Subscription {
    id: string;
    type: string;
    maxLandPartners: number | null;
    features: SubscriptionFeatures;
    trial: boolean;
}

export interface SubscriptionFeatures {
    fieldManagement: boolean;
    landPartnerManagement: boolean;
    landPartnerSharing: boolean;
    leaseManagement: boolean;
    paymentManagement: boolean;
    taskManagement: boolean;
    quickBooks: boolean;
    johnDeereOps: boolean;
    disasterPlanning: boolean;
}

export const subscriptionsAPI = OAKENApi.injectEndpoints({
    endpoints: (builder) => ({
        getCurrentSubscription: builder.query<Subscription, void>({
            query: () => {
                return {
                    url:
                        process.env.REACT_APP_USER_API_HOST +
                        ENDPOINTS.subscriptions.root,
                    method: HTTP_METHOD.GET,
                };
            },
        }),
    }),
});

export const {
    useLazyGetCurrentSubscriptionQuery,
    useGetCurrentSubscriptionQuery,
} = subscriptionsAPI;
