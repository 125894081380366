import { OAKENApi } from '../OAKENApi';
import { TaskView } from '../../features/Tasks/utils';
import { HTTP_METHOD } from '../auth/http';
import { ENDPOINTS } from '../endpoints';
import { TASKS } from '../tags';
import { getListProvidesTags } from '../generic';

export const tasksAPI = OAKENApi.injectEndpoints({
    endpoints: (builder) => ({
        getTasks: builder.query({
            query: (params) => {
                return {
                    url:
                        process.env.REACT_APP_USER_API_HOST +
                        ENDPOINTS.tasks.root,
                    method: HTTP_METHOD.GET,
                    params,
                };
            },
            transformResponse: (
                data: TaskView[],
                meta,
            ): { data: TaskView[]; totalCount: number } => {
                const xTotalCount =
                    meta?.response?.headers.get('X-Total-Count');
                return { data, totalCount: xTotalCount ? +xTotalCount : 0 };
            },
            providesTags: (result) =>
                getListProvidesTags(result?.data, TASKS.TASKS),
        }),
        getOneTask: builder.query({
            query: (id) => {
                return {
                    url:
                        process.env.REACT_APP_USER_API_HOST +
                        ENDPOINTS.tasks.root +
                        `/${id}`,
                    method: HTTP_METHOD.GET,
                };
            },
        }),
        addTask: builder.mutation({
            query: ({ documents, ...values }) => {
                const formData = new FormData();
                if (documents) {
                    documents.forEach((file: File) => {
                        formData.append(`documents`, file, file.name);
                    });
                }
                formData.append(
                    'request',
                    new Blob([JSON.stringify(values)], {
                        type: 'application/json',
                    }),
                );

                return {
                    url:
                        process.env.REACT_APP_USER_API_HOST +
                        ENDPOINTS.tasks.root,
                    method: HTTP_METHOD.POST,
                    body: formData,
                };
            },
            invalidatesTags: [TASKS.TASKS],
        }),
        updateTask: builder.mutation({
            query: ({ documents, ...values }) => {
                const formData = new FormData();
                if (documents) {
                    documents.forEach((file: File) => {
                        formData.append(`documents`, file, file.name);
                    });
                }
                formData.append(
                    'request',
                    new Blob([JSON.stringify(values)], {
                        type: 'application/json',
                    }),
                );

                return {
                    url:
                        process.env.REACT_APP_USER_API_HOST +
                        ENDPOINTS.tasks.root +
                        `/${values.id}`,
                    method: HTTP_METHOD.PUT,
                    body: formData,
                };
            },
            invalidatesTags: [TASKS.TASKS],
        }),
        getListOfDocuments: builder.query({
            query: ({ id, ...params }) => {
                return {
                    url:
                        process.env.REACT_APP_USER_API_HOST +
                        ENDPOINTS.tasks.document +
                        `/${id}`,
                    method: HTTP_METHOD.GET,
                    params,
                };
            },
        }),
        getListOfHistory: builder.query({
            query: ({ id, ...params }) => {
                return {
                    url:
                        process.env.REACT_APP_USER_API_HOST +
                        ENDPOINTS.tasks.history +
                        `/${id}`,
                    method: HTTP_METHOD.GET,
                    params,
                };
            },
        }),
        getTasksSummary: builder.query({
            query: (params) => {
                return {
                    url:
                        process.env.REACT_APP_USER_API_HOST +
                        ENDPOINTS.tasks.summary,
                    method: HTTP_METHOD.GET,
                    params,
                };
            },
            providesTags: (result) =>
                getListProvidesTags(result?.data, TASKS.TASKS),
        }),
        markTaskAsDone: builder.mutation({
            query: (id) => {
                return {
                    url:
                        process.env.REACT_APP_USER_API_HOST +
                        ENDPOINTS.tasks.done +
                        `/${id}`,
                    method: HTTP_METHOD.POST,
                };
            },
            invalidatesTags: [TASKS.TASKS],
        }),
        assignedTasks: builder.query({
            query: (params) => {
                return {
                    url:
                        process.env.REACT_APP_USER_API_HOST +
                        ENDPOINTS.tasks.assigned,
                    method: HTTP_METHOD.GET,
                    params,
                };
            },
            transformResponse: (
                data: TaskView[],
                meta,
            ): { data: TaskView[]; totalCount: number } => {
                const xTotalCount =
                    meta?.response?.headers.get('X-Total-Count');
                return { data, totalCount: xTotalCount ? +xTotalCount : 0 };
            },
            providesTags: (result) =>
                getListProvidesTags(result?.data, TASKS.TASKS),
        }),
    }),
});

export const {
    useAssignedTasksQuery,
    useMarkTaskAsDoneMutation,
    useGetTasksQuery,
    useGetTasksSummaryQuery,
    useLazyGetTasksQuery,
    useGetOneTaskQuery,
    useLazyGetOneTaskQuery,
    useAddTaskMutation,
    useUpdateTaskMutation,
    useLazyGetListOfDocumentsQuery,
    useLazyGetListOfHistoryQuery,
} = tasksAPI;
