import React from 'react';
import PhoneInput from 'react-phone-input-2';

import 'react-phone-input-2/lib/material.css';
import theme from '../../styles/theme';
import { Typography } from '@mui/material';

export interface PhoneInput {
    onChange: (value: number | string) => void;
    name: string;
    //eslint-disable-next-line
    onBlur?: (e: any) => void;
    value?: string;
    placeholder?: string;
    isDisabled?: boolean;
    errorText?: string;
    isHidden?: boolean;
    size?: 'small' | 'medium';
}
function PhoneNumberInputField({
    onChange,
    onBlur,
    value,
    placeholder,
    isDisabled,
    errorText,
    isHidden,
    size,
    name,
}: PhoneInput) {
    if (isHidden) return null;
    return (
        <div>
            <PhoneInput
                placeholder={placeholder}
                onBlur={onBlur}
                disabled={isDisabled}
                country={'us'}
                preferredCountries={['us']}
                value={value}
                defaultErrorMessage={errorText}
                onChange={onChange}
                specialLabel={''}
                inputStyle={{
                    padding: theme.spacing(1, 1, 1.3, 7.5),
                    borderColor: errorText ? 'red' : undefined,
                }}
                buttonStyle={{
                    padding: theme.spacing(1, 0),
                }}
                inputProps={{
                    id: name,
                    size: size,
                }}
            />
            <Typography variant="font12" color="error">
                {errorText}
            </Typography>
        </div>
    );
}

export default PhoneNumberInputField;
