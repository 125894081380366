import useCompletedTable from './useCompletedTable';
import GenericTable from '../../design/GenericTable/GenericTable';
export default function SuccessionCompletedTable() {
    const { tableConfig, data, totalCount } = useCompletedTable();
    return (
        <div>
            <GenericTable
                data={data}
                tableConfig={tableConfig}
                totalCount={totalCount}
            />
        </div>
    );
}
