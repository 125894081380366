import { Switch } from '@mui/material';
import { useState } from 'react';
import { Permission } from './PermissionTableList';

interface OwnProps {
    permission: Permission;
    setIds: (ids: (prev: string[]) => string[]) => void;
}

export default function PermissionSwitch({ permission, setIds }: OwnProps) {
    const [checked, setChecked] = useState<boolean>(!!permission.enabled);

    const handleChange = () => {
        setChecked(!checked);
        if (checked) {
            setIds((prev: string[]) =>
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                prev.filter((prevId: string) => prevId !== permission.id),
            );
        } else {
            //eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            setIds((prev: string[]) => [...prev, permission.id]);
        }
    };

    return <Switch checked={checked} color="primary" onChange={handleChange} />;
}
