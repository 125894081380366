import React, { useEffect, useState } from 'react';
import useModal from '../../hooks/useModal';
import PATHS from '../../router/paths';
import { ReactComponent as LayoutIcon } from '../../assets/icons/layout_icon.svg';
import { ReactComponent as BoxIcon } from '../../assets/icons/box_icon.svg';
import { ReactComponent as IntegrationsIcon } from '../../assets/icons/integrations_icon.svg';

import {
    SettingsWidgetProps,
    WidgetBackgroundColor,
} from './SettingWidget/SettingWidget';
import {
    Subscription,
    useLazyGetCurrentSubscriptionQuery,
} from '../../api/subscriptions/subscriptionsAPI';
import { useGetAccountQuery } from '../../api/users/accountAPI';

export function useGeneralManagerSettingsWidget() {
    const { isShowing, toggle } = useModal();
    const [header] = useState('');
    const [component] = useState<JSX.Element | undefined>();

    const {
        data,
        isLoading: isUserDataLoading,
        isFetching: isUserDataFetching,
    } = useGetAccountQuery({});

    const [getItems, { isLoading, isFetching, data: subscriptionInfo }] =
        useLazyGetCurrentSubscriptionQuery({});
    const roleName = data.role?.name;

    useEffect(() => {
        roleName === 'GENERAL_MANAGER' && getItems();
    }, [roleName]);

    const landPartnerSharingWidget: Array<SettingsWidgetProps> = [
        {
            color: WidgetBackgroundColor.darkNeutral,
            info: 'Share with Land Partners and 3rd Party Consultants like Agronomists. Manage visibility type, duration',
            path: `/${PATHS.generalManagerSettings.root}/${PATHS.generalManagerSettings.landPartnerSharing}`,
            linkText: 'Configure >',
            title: 'Dashboard Sharing',
            icon: <LayoutIcon />,
            isHidden: !isLPSharingEnabled(subscriptionInfo),
        },
        {
            color: WidgetBackgroundColor.darkNeutral,
            info: 'Restore or delete permanently previous archived Contacts, Leases or Land Partners',
            path: `/${PATHS.generalManagerSettings.root}/${PATHS.archive}`,
            linkText: 'Manage Archive >',
            title: 'Archive',
            icon: <BoxIcon />,
        },
    ];
    const integrationsWidget: Array<SettingsWidgetProps> = [
        {
            color: WidgetBackgroundColor.turq,
            info: 'See active and inactive integrations',
            path: `/${PATHS.generalManagerSettings.root}/${PATHS.integrations}`,
            linkText: 'Manage Integrations >',
            title: 'Integrations',
            icon: <IntegrationsIcon />,
        },
    ];
    const isAllLoading =
        isLoading || isFetching || isUserDataLoading || isUserDataFetching;
    return {
        isShowing,
        toggle,
        header,
        component,
        isLoading: isAllLoading,
        landPartnerSharingWidget,
        integrationsWidget,
    };
}

const isLPSharingEnabled = (subscriptionInfo: Subscription | undefined) => {
    return subscriptionInfo?.features?.landPartnerSharing;
};
