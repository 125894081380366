import React from 'react';
import { FieldData, FieldDataPerCrop } from './CollapsedDetails';
import { Stack } from '@mui/material';
import { TitleWithValueProps } from '../TitleWithValue';
import TitleWithValueList from '../TitleWithValueList';
import ThreeColumnLayout from '../../../layouts/ThreeColumnLayout';
import theme from '../../../styles/theme';

interface OwnProps {
    data: FieldData;
    isCollapsed: boolean;
}
export default function ExpandedFieldData({ data, isCollapsed }: OwnProps) {
    return (
        <Stack
            flexDirection={'column'}
            width={'100%'}
            sx={{
                borderBottom: isCollapsed
                    ? `1px solid ${theme.palette.strokeS1}`
                    : 'none',
            }}>
            <ThreeColumnLayout
                leftComponent={<p>{''}</p>}
                middleComponent={data.cropData.map((cropData, index) => (
                    <Stack
                        display={'flex'}
                        flexDirection={'column'}
                        pt={2}
                        key={index}>
                        <TitleWithValueList
                            data={convertDataToView(cropData, index)}
                        />
                    </Stack>
                ))}
                rightComponent={<p>{''}</p>}
                leftColumnWidth={'compact'}
                rightColumnWidth={'compact'}
            />
        </Stack>
    );
}

function convertDataToView(
    data: FieldDataPerCrop,
    index: number,
): TitleWithValueProps[] {
    return [
        { title: `CROP ${index + 1}`, value: data.crop, isHidden: index === 0 },
        {
            title: `YIELD ${index + 1}`,
            value: data.yield,
            isHidden: index === 0,
        },
        {
            title: 'PLANTING START',
            value: data.plantingStart,
            isHidden: index === 0,
        },
        {
            title: 'PLANTING END',
            value: data.plantingEnd,
            isHidden: index === 0,
        },
        { title: 'HARVEST', value: data.harvest, isHidden: index === 0 },
        { title: 'PESTICIDE APPLICATION', value: data.pesticideApplication },
        { title: 'PESTICIDE TRADE NAME', value: data.pesticideTradeName },
        { title: 'LICENSE NAME', value: data.licenseName },
        { title: 'TEMPERATURE', value: data.temperature },
        { title: 'WIND DIRECTION', value: data.windDirection },
        { title: 'WIND SPEED', value: data.windSpeed },
        { title: 'EPA REGULATION #', value: data.epaRegulation },
        { title: 'RATE (FL OZ/ACRE)', value: data.rate },
        { title: 'OPERATOR NAME', value: data.operatorName },
    ];
}
