import React, { ChangeEvent, useState } from 'react';

import { ConnectableElement, useDrag, useDrop } from 'react-dnd';
import DragIndicatorOutlinedIcon from '@mui/icons-material/DragIndicatorOutlined';
import { Box, Checkbox, Typography } from '@mui/material';
import theme from '../../styles/theme';

interface OwnProps {
    id: string;
    text: string;
    index: number;
    isHidden?: boolean;
    moveItem: (dragIndex: number, hoverIndex: number) => void;
    handleChangeChecked: (
        event: ChangeEvent<HTMLInputElement>,
        id: string,
    ) => void;
    isSelectAll?: boolean;
}

export default function DraggableItem({
    id,
    text,
    index,
    isHidden = true,
    moveItem,
    handleChangeChecked,
}: OwnProps) {
    const [checked, setChecked] = useState(!isHidden);

    function handleChange(event: ChangeEvent<HTMLInputElement>) {
        handleChangeChecked(event, id);
        setChecked(event.target.checked);
    }
    const [{ isDragging }, drag] = useDrag({
        type: 'ITEM',
        item: { id, index },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    const [, drop] = useDrop({
        accept: 'ITEM',
        hover: (draggedItem: { id: string; index: number }) => {
            if (draggedItem.index !== index) {
                moveItem(draggedItem.index, index);
                draggedItem.index = index;
            }
        },
    });
    return (
        <Box
            ref={(node) => drag(drop(node as ConnectableElement))}
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                opacity: isDragging ? 0.5 : 1,
                margin: '4px',
                border: `1px solid ${theme.palette.strokeS1}`,
                backgroundColor: checked
                    ? theme.palette.yellowGreen.yellowGreenTint8
                    : theme.palette.yellowGreen.yellowGreenShade1,
                cursor: 'pointer',
            }}>
            <Box
                display={'flex'}
                rowGap={1}
                sx={{
                    color: theme.palette.neutral.neutral6,
                }}>
                <DragIndicatorOutlinedIcon />
                <Typography color="text.neutral6" textTransform={'capitalize'}>
                    {text}
                </Typography>
            </Box>
            <Checkbox
                checked={checked}
                onChange={handleChange}
                size={'medium'}
            />
        </Box>
    );
}
