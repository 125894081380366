import palette from './palette';
import { createTheme } from '@mui/material/styles';
import theme from './theme';

const localTheme = {
    ...theme,
    components: {
        MuiTable: {
            styleOverrides: {
                root: {
                    backgroundColor: palette.darkTurq.darkTurqTint1,
                },
            },
        },
        MuiTableRow: {
            styleOverrides: {
                root: {
                    borderBottom: 'none',
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                head: {
                    color: palette.neutral.neutral10,
                    fontWeight: 600,
                    borderBottom: `1px solid ${palette.strokeS1}`,
                },
                root: {
                    verticalAlign: 'top',
                    backgroundColor: palette.darkTurq.darkTurqTint1,
                    margin: 8,
                    padding: 8,
                },
            },
        },
    },
};

//eslint-disable-next-line
// @ts-ignore
export const subscriptionTableTheme = createTheme(localTheme);
