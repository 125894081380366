import { FormConfig } from '../../design/Forms/interfaces';
import { useForm } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { Box, Button, IconButton } from '@mui/material';
import FormPage from '../../reusable/FormUI/FormPage';
import AddIcon from '@mui/icons-material/Add';
import theme from '../../styles/theme';
import CloseIcon from '@mui/icons-material/Close';
export interface Config {
    name: string;
}
interface OwnProps {
    fieldName: string;
    config: ({ name }: Config) => FormConfig[];
    isHidden?: boolean;
    addButtonLabel?: string;
}

export default function GenericArrayField({
    fieldName,
    config,
    isHidden,
    addButtonLabel,
}: OwnProps) {
    const { mutators } = useForm();
    if (isHidden) return null;

    return (
        <>
            <FieldArray name={fieldName}>
                {({ fields }) => {
                    return (
                        <>
                            {fields.map((name, index) => (
                                <Box
                                    key={name}
                                    display={'flex'}
                                    gap={2}
                                    alignItems={'center'}
                                    width={'100%'}>
                                    <Box width={'100%'} pb={2}>
                                        <FormPage
                                            formConfig={config({ name })}
                                        />
                                    </Box>
                                    <IconButton
                                        onClick={() => fields.remove(index)}>
                                        <CloseIcon
                                            sx={{
                                                marginTop: '8px',
                                                fontSize: '14px',
                                                color: theme.palette
                                                    .brandLightTurq,
                                            }}
                                        />
                                    </IconButton>
                                </Box>
                            ))}
                        </>
                    );
                }}
            </FieldArray>
            <Button
                sx={{
                    alignSelf: 'flex-end',
                    fontSize: '12px',
                    color: theme.palette.brandLightTurq,
                }}
                endIcon={<AddIcon />}
                variant={'text'}
                onClick={() => mutators.push(fieldName, undefined)}>
                {addButtonLabel || 'Add'}
            </Button>
        </>
    );
}
