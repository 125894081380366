import { useState } from 'react';

import { ReactComponent as ConfigureIcon } from '../../assets/icons/configure_icon.svg';

import { ReactComponent as ManageGroupsIcon } from '../../assets/icons/manage_groups_icon.svg';
import { ReactComponent as ManageOrgIcon } from '../../assets/icons/manage_org_icon.svg';
import { ReactComponent as ManageRolesIcon } from '../../assets/icons/manage_roles_icon.svg';
import { ReactComponent as AlertIcon } from '../../assets/icons/notification_icon.svg';
import { ReactComponent as ViewUsersIcon } from '../../assets/icons/view_users_icon.svg';
import useModal from '../../hooks/useModal';
import PATHS from '../../router/paths';
import {
    SettingsWidgetProps,
    WidgetBackgroundColor,
} from './SettingWidget/SettingWidget';

export function useSettingsWidget() {
    const { isShowing, toggle } = useModal();
    const [header] = useState('');
    const [component] = useState<JSX.Element | undefined>();

    const organizationManagementWidgets: Array<SettingsWidgetProps> = [
        {
            color: WidgetBackgroundColor.darkTurq,
            info: 'Manage organizations, their roles, primary contact and billing',
            path: `/${PATHS.settings}/${PATHS.orgAndUsers}/${PATHS.organizations}`,
            linkText: 'Get started >',
            title: 'Manage Org',
            icon: <ManageOrgIcon />,
        },
    ];

    const userManagementWidgets: Array<SettingsWidgetProps> = [
        {
            color: WidgetBackgroundColor.green,
            info: 'Specify roles, permissions or modify and existing role',
            path: `/${PATHS.settings}/${PATHS.orgAndUsers}/${PATHS.roles}`,
            linkText: 'Get started >',
            title: 'Manage Roles',
            icon: <ManageRolesIcon />,
        },
        {
            color: WidgetBackgroundColor.green,
            info: 'See active and inactive users and manage their access',
            path: `/${PATHS.settings}/${PATHS.orgAndUsers}/${PATHS.users}`,
            linkText: 'Get started >',
            title: 'Manage Users',
            icon: <ViewUsersIcon />,
        },
        {
            color: WidgetBackgroundColor.green,
            info: 'See active and inactive users and manage their access',
            linkText: 'Get started >',
            path: `/${PATHS.settings}/${PATHS.orgAndUsers}/${PATHS.users}/${PATHS.groups}`,
            title: 'Manage Groups',
            icon: <ManageGroupsIcon />,
        },
    ];

    const integrationsWidgets: Array<SettingsWidgetProps> = [
        {
            color: WidgetBackgroundColor.turq,
            info: 'Configure APIs',
            path: ' ',
            linkText: 'Configure >',
            title: 'Configure',
            icon: <ConfigureIcon />,
        },
    ];

    const notificationsWidgets: Array<SettingsWidgetProps> = [
        {
            color: WidgetBackgroundColor.turq,
            info: 'In-app, push and email',
            path: ' ',
            linkText: 'Manage Alert >',
            title: 'Alerts',
            icon: <AlertIcon />,
        },
    ];

    return {
        isShowing,
        toggle,
        header,
        component,
        organizationManagementWidgets,
        userManagementWidgets,
        integrationsWidgets,
        notificationsWidgets,
    };
}
