import React, { useEffect, useState } from 'react';
import { Box, Button } from '@mui/material';

import AddRowModal from './AddRowModal';
import GenericTable from '../../design/GenericTable/GenericTable';

import { FormConfig } from '../../design/Forms/interfaces';
import {
    composeValidators,
    isNumber,
    maxLength,
    required,
} from '../../utils/validation';
import { tableExpensesConfig } from './tableExpensesConfig';
import createDecorator from 'final-form-calculate';
import { useLocation } from 'react-router-dom';
import { nanoid } from 'nanoid';

export interface Expenses {
    id: string;
    tableId: string;
    description: string;
    landownerSharePercent?: string;
    growerSharePercent?: string;
}
interface OwnProps {
    tableData: Expenses[];
    setTableData: (data: Expenses[]) => void;
}

export default function ExpensesDetailForm({
    tableData,
    setTableData,
}: OwnProps) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [header, setHeader] = useState<string>('Add Expenses');
    const [initialValues, setInitialValues] = useState<Expenses>();
    const { state } = useLocation();
    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setInitialValues(undefined);
        setIsModalOpen(false);
    };
    const handleSaveRow = (rowData: Expenses) => {
        const newTableData = tableData.filter((row) =>
            !row?.tableId
                ? row.id !== rowData.id
                : row.tableId !== rowData.tableId,
        );
        const newRowData = {
            ...rowData,
            tableId: rowData.tableId || nanoid(),
        };
        setTableData([...newTableData, newRowData]);
        handleCloseModal();
    };

    useEffect(() => {
        if (state?.isCopy && !tableData?.length) {
            const expensesIds = state?.expenses
                ? state?.expenses?.map((item: Expenses) => {
                      return {
                          ...item,
                          tableId: item.id,
                      };
                  })
                : [];
            setTableData(expensesIds);
        }
    }, [state]);
    const handleDeleteRow = (id: string) => {
        const newTableData = tableData.filter((row) => row.tableId !== id);
        setTableData(newTableData);
    };
    const handleEditRow = (id: string) => {
        const row = tableData.find((row) => row.tableId === id);
        setInitialValues(row);
        setHeader('Edit Expenses');
        setIsModalOpen(true);
    };

    return (
        <Box
            display={'flex'}
            flexDirection={'column'}
            gap={2}
            alignItems={'flex-end'}>
            <Button
                type="button"
                variant={'outlined'}
                onClick={handleOpenModal}>
                Add a Row
            </Button>
            <GenericTable
                data={tableData}
                totalCount={tableData?.length}
                tableConfig={tableExpensesConfig({
                    handleEdit: handleEditRow,
                    handleDelete: handleDeleteRow,
                })}
            />
            <AddRowModal
                initialValues={initialValues}
                header={header}
                formConfig={formConfig}
                isOpen={isModalOpen}
                onClose={handleCloseModal}
                onSave={handleSaveRow}
                decorator={calculateExpenses}
            />
        </Box>
    );
}

const calculateExpenses = createDecorator(
    {
        field: 'landownerSharePercent',
        updates: {
            growerSharePercent: (landownerSharePercent) => {
                const value = Number(landownerSharePercent);
                if (isNaN(value)) return 100;
                if (value >= 100) return 0;
                if (value <= 0) return 100;
                return 100 - value;
            },
        },
    },
    {
        field: 'growerSharePercent',
        updates: {
            landownerSharePercent: (growerSharePercent) => {
                const value = Number(growerSharePercent);
                if (isNaN(value)) return 100;
                if (value >= 100) return 0;
                if (value <= 0) return 100;

                return 100 - value;
            },
        },
    },
);
const formConfig: FormConfig[] = [
    {
        formField: {
            name: 'description',
            type: 'input',
            label: 'Description',
            validation: composeValidators(maxLength(256), required),
            tooltip: {
                text: 'Description of the expense',
            },
        },
        renderProps: {
            placeholder: 'Use of barn for Combine Harvestor storage',
            isDisabled: false,
            size: 'small',
        },
    },
    {
        formField: {
            name: 'landownerSharePercent',
            type: 'input',
            label: 'Landowner Share',
            validation: composeValidators(isNumber, required),
        },
        renderProps: {
            placeholder: 'Enter Landowner Share',
            isDisabled: false,
            size: 'small',
            postfix: '%',
        },
    },
    {
        formField: {
            name: 'growerSharePercent',
            type: 'input',
            label: 'Grower Share',
            validation: composeValidators(isNumber, required),
        },
        renderProps: {
            placeholder: 'Enter Grower Share',
            isDisabled: false,
            size: 'small',
            postfix: '%',
        },
    },
];
