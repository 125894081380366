import React from 'react';
import { Box, Checkbox, Divider, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import theme from '../../styles/theme';
import UploadInfo from './UploadInfo';
import UserAvatar from '../../design/Avatar/UserAvatar';
import DocumentInfo from './DocumentInfo';
import useAvatar from '../../hooks/useAvatar';

export interface DocumentItem {
    id: string;
    fileLocation: string;
    fileName: string;
    createdAt: string;
    createdByName: string;
    visibility?: string;
    documentType: DocumentType;
    avatarFileType?: string;
    avatar?: string;
}

export type DocumentType = 'PAYMENT' | 'LEASE';

interface OwnProps {
    document: DocumentItem;
    isChecked?: boolean;
    onCheckChange?: (isChecked: boolean) => void;
    token?: string;
}

export default function DocumentListItem({
    document,
    isChecked,
    onCheckChange,
    token,
}: OwnProps) {
    const { classes } = useStyles();
    const { avatarUrl } = useAvatar(document.avatar, document.avatarFileType);

    const handleCheckboxStateChange = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        onCheckChange?.(event.target.checked);
    };

    return (
        <Box className={classes.wrapper}>
            <Box display={'flex'} alignItems={'center'}>
                <Checkbox
                    color={'success'}
                    checked={isChecked}
                    onChange={handleCheckboxStateChange}
                    className={classes.checkbox}
                    sx={{
                        '& .MuiSvgIcon-root': {
                            color: theme.palette.lightTurq.lightTurqTint4,
                        },
                        '&.Mui-checked .MuiSvgIcon-root': {
                            color: theme.palette.lightTurq.lightTurqTint4,
                        },
                    }}
                />
                <Typography
                    variant="font16"
                    fontWeight={'medium'}
                    color={'text.secondary'}>
                    {document.fileName}
                </Typography>
            </Box>
            <Box display={'flex'} columnGap={4}>
                <Box
                    display={'flex'}
                    flexDirection={'column'}
                    rowGap={1}
                    justifyContent={'center'}>
                    <Typography
                        variant="font12"
                        color={'text.secondary'}
                        fontWeight={'bold'}>
                        {'Uploaded By'}
                    </Typography>
                    <Box display={'flex'} columnGap={2} alignItems={'center'}>
                        <UserAvatar
                            size={'small'}
                            name={document.createdByName}
                            avatar={avatarUrl}
                        />
                        <Typography
                            variant="font12"
                            color={'text.neutral9'}
                            fontWeight={'medium'}>
                            {document.createdByName}
                        </Typography>
                    </Box>
                </Box>
                <UploadInfo title={'Date'} data={document.createdAt} />
                {document.visibility && (
                    <UploadInfo
                        title={'Visibility'}
                        data={document.visibility}
                    />
                )}
                <Divider
                    orientation={'vertical'}
                    flexItem
                    variant="fullWidth"
                    sx={{
                        width: 2,
                        backgroundColor: theme.palette.neutralWhite,
                    }}
                />
                <DocumentInfo renderData={document} token={token} />
            </Box>
        </Box>
    );
}
const useStyles = makeStyles()(() => ({
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: theme.spacing(2),
        backgroundColor: theme.palette.lightTurq.lightTurqTint1,
        width: '100%',
        padding: theme.spacing(2, 2),
        borderBottom: `solid 1px ${theme.palette.strokeS1}`,
    },
    checkbox: {
        marginRight: theme.spacing(2),
    },
}));
