import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { ReactComponent as SnoozeIcon } from '../../assets/icons/snooze_icon.svg';
import { ReactComponent as RemoveIcon } from '../../assets/icons/remove_icon.svg';
import { ReactComponent as Alert } from '../../assets/icons/alert_icon.svg';
import { makeStyles } from 'tss-react/mui';
import theme from '../../styles/theme';
import clsx from 'clsx';
import { isToday } from '../../utils/dateFormat';

interface OwnProps {
    onSnooze?: () => void;
    onDelete: () => void;
    snoozed: boolean;
    overdue?: boolean;
    isSnoozeHidden?: boolean;
    eventDate: string;
}

export default function ActionButtonsNotificationList({
    onSnooze,
    onDelete,
    snoozed,
    overdue,
    isSnoozeHidden,
    eventDate,
}: OwnProps) {
    const { classes } = useStyles();
    return (
        <Box display={'flex'} columnGap={1}>
            {!isSnoozeHidden && overdue && (
                <Box
                    display={'flex'}
                    columnGap={1}
                    alignItems={'center'}
                    pr={2}>
                    <Alert className={classes.alertIcon} />
                    <Typography
                        variant="font12"
                        color="text.secondaryContrast"
                        fontWeight="medium">
                        Overdue
                    </Typography>
                </Box>
            )}
            {!isSnoozeHidden && !overdue && !isToday(eventDate) && (
                <Button
                    disabled={snoozed}
                    className={clsx(
                        classes.button,
                        snoozed && classes.snoozeIcon,
                    )}
                    onClick={onSnooze}
                    startIcon={<SnoozeIcon />}>
                    {snoozed ? 'Snoozed' : 'Snooze'}
                </Button>
            )}
            <Box display="flex" justifyContent="flex-end" flexGrow={1}>
                <Button
                    className={classes.button}
                    onClick={onDelete}
                    startIcon={<RemoveIcon />}>
                    Delete
                </Button>
            </Box>
        </Box>
    );
}
const useStyles = makeStyles()(() => ({
    button: {
        padding: theme.spacing(0.5, 1),
        color: theme.palette.brandLightTurq,
        '& svg': {
            fill: theme.palette.brandLightTurq,
        },
    },
    alertIcon: {
        width: 20,
        height: 20,
        fill: theme.palette.neutral.neutral6,
    },
    snoozeIcon: {
        '& svg': {
            fill: theme.palette.neutral.neutral3,
        },
    },
}));
