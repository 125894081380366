import React from 'react';
import { Grid, Typography } from '@mui/material';
import * as _ from 'lodash';
import SettingsWidget from './SettingWidget/SettingWidget';
import { makeStyles } from 'tss-react/mui';

import { useSettingsWidget } from './useSettingsWidget';
import theme from '../../styles/theme';

export default function NotificationsAndViews() {
    const { notificationsWidgets } = useSettingsWidget();
    const { classes } = useStyles();
    return (
        <div className={classes.settingsWrapper}>
            <Typography className={classes.widgetGroupTitle}>
                Notifications
            </Typography>
            <Grid container spacing={2} columnGap={2}>
                {_.map(notificationsWidgets, (widget) => (
                    <Grid item xs={3} key={widget.title}>
                        <SettingsWidget {...widget} />
                    </Grid>
                ))}
            </Grid>
        </div>
    );
}
const useStyles = makeStyles()(() => ({
    settingsWrapper: {
        padding: '40px 24px 24px',
    },
    settingsInfoTitle: {
        fontSize: '32px',
        lineHeight: 1.25,
        color: theme.palette.neutral.neutral4,
    },
    settingsInfo: {
        fontSize: '16px',
        lineHeight: 1.5,
        color: theme.palette.neutral.neutral10,
    },
    widgetGroupTitle: {
        margin: '16px 0 8px',
        fontSize: '24px',
        fontWeight: 300,
        lineHeight: 1.33,
        color: theme.palette.neutral.neutral4,
    },
}));
