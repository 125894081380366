import React, { useEffect } from 'react';
import GenericTable, { Table } from '../../design/GenericTable/GenericTable';
import { Typography } from '@mui/material';
import { useSorting } from '../../design/GenericTable/useSorting';
import { useLazyGetListOfHistoryQuery } from '../../api/tasks/tasksAPI';
import { useFormState } from 'react-final-form';
import { dateTimeConverterFromUTC } from '../../utils/dateFormat';

export interface HistoryTable {
    id: string;
    createdAt: string;
    changedBy: ChangedBy;
    historyMessage: string;
}

export interface ChangedBy {
    id: string;
    firstName: string;
    lastName: string;
}

export default function TaskFormTabHistory() {
    const { sortKey, sortOrder, handleSort } = useSorting('dateTime', 'desc');

    const [getItems, { data, isLoading: isHistoryLoading }] =
        useLazyGetListOfHistoryQuery();

    const { values } = useFormState();

    useEffect(() => {
        if (values.id) {
            getItems({ id: values.id, sort: `createdAt,desc` });
        }
    }, [values.id]);

    if (isHistoryLoading) {
        return null;
    }
    const tableHistoryConfig: Table<HistoryTable> = {
        columns: [
            {
                header: {
                    sortable: true,
                    sorting: {
                        id: 'dateTime',
                        direction: sortKey === 'dateTime' ? sortOrder : 'desc',
                        isSorted: sortKey === 'dateTime',
                    },
                    onClick: handleSort,
                    label: 'Date and Time',
                },
                cellRender: (tableData) => (
                    <Typography variant={'font14'} fontWeight={'medium'}>
                        {dateTimeConverterFromUTC(tableData.createdAt)}
                    </Typography>
                ),
                format: { align: 'left', color: { color: 'primary' } },
                width: 'auto',
            },
            {
                header: {
                    sortable: true,
                    sorting: {
                        id: 'changedBy.firstName',
                        direction:
                            sortKey === 'changedBy.firstName'
                                ? sortOrder
                                : 'desc',
                        isSorted: sortKey === 'changedBy.firstName',
                    },
                    onClick: handleSort,
                    label: 'Changed by',
                },
                cellRender: (tableData) => (
                    <Typography variant={'font14'} fontWeight={'medium'}>
                        {`${tableData.changedBy.firstName} ${tableData.changedBy.lastName}`}
                    </Typography>
                ),
                format: { align: 'left', color: { color: 'primary' } },
                width: 'auto',
            },
            {
                header: {
                    sortable: true,
                    sorting: {
                        id: 'action',
                        direction: sortKey === 'action' ? sortOrder : 'desc',
                        isSorted: sortKey === 'action',
                    },
                    onClick: handleSort,
                    label: 'Action',
                },
                cellRender: (tableData) => (
                    <Typography variant={'font14'} fontWeight={'medium'}>
                        {tableData.historyMessage}
                    </Typography>
                ),
                format: { align: 'left', color: { color: 'primary' } },
                width: 'auto',
            },
        ],
        multiselect: false,
        pageable: false,
        expandable: false,
    };

    return <GenericTable tableConfig={tableHistoryConfig} data={data || []} />;
}
