import React, { ChangeEvent, useCallback } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import DraggableItem from './DraggableItem';
import { Box, Typography } from '@mui/material';
import theme from '../../styles/theme';
import palette from '../../styles/palette';
import { ColumnConfig } from '../../api/users/customizeConfigAPI';

interface OwnProps {
    items: ColumnConfig[];
    setItems: (item: ColumnConfig[]) => void;
}

export default function DraggableList({ items, setItems }: OwnProps) {
    const moveItem = useCallback((dragIndex: number, hoverIndex: number) => {
        //TODO remove all eslint comments
        //eslint-disable-next-line
        //@ts-ignore
        setItems((prevCards: ColumnConfig[]) => {
            const newItems = [...prevCards];
            const [draggedCard] = newItems.splice(dragIndex, 1);
            newItems.splice(hoverIndex, 0, draggedCard);
            return newItems;
        });
    }, []);
    const handleChangeChecked = (
        event: ChangeEvent<HTMLInputElement>,
        id: string,
    ) => {
        const newIsHidden = event.target.checked;
        const newState = items.map((obj) => {
            if (obj.id === id) {
                return { ...obj, isHidden: !newIsHidden };
            }
            return obj;
        });
        setItems(newState);
    };

    return (
        <Box
            width={'100%'}
            display={'flex'}
            flexDirection={'column'}
            rowGap={1}
            p={2}
            sx={{
                flexGrow: 1,
                borderTop: `1px solid ${theme.palette.backgroundB.backgroundB4}`,
                borderBottom: `1px solid ${theme.palette.backgroundB.backgroundB4}`,
                backgroundColor: palette.backgroundB.backgroundB1,
            }}>
            <DndProvider backend={HTML5Backend}>
                <div style={{ margin: '20px' }}>
                    <Box
                        display={'flex'}
                        width={'100%'}
                        p={1}
                        justifyContent={'space-between'}>
                        <Typography
                            variant={'font12'}
                            fontWeight={'medium'}
                            color={'text.secondaryContrast'}>
                            Drag to arrange columns
                        </Typography>
                        <Typography
                            variant={'font12'}
                            fontWeight={'medium'}
                            color={'text.secondaryContrast'}>
                            View On/Off
                        </Typography>
                    </Box>
                    {items.map((item, index) => (
                        <DraggableItem
                            key={item.id}
                            id={item.id}
                            text={item.text}
                            isHidden={item.isHidden}
                            index={index}
                            moveItem={moveItem}
                            handleChangeChecked={handleChangeChecked}
                        />
                    ))}
                </div>
            </DndProvider>
        </Box>
    );
}
