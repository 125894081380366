import { IntegrationSettings } from './IntegrationCard';
import { Stack } from '@mui/material';
import IntegrationSettingStatus from './IntegrationSettingStatus';

interface OwnProps {
    settingsList: IntegrationSettings[];
}

export default function IntegrationsSettingStatusList({
    settingsList,
}: OwnProps) {
    return (
        <Stack columnGap={2} rowGap={2} pt={2} width={'100%'}>
            {settingsList.map((setting, index) => (
                <IntegrationSettingStatus key={index} settings={setting} />
            ))}
        </Stack>
    );
}
