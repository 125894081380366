import palette from './palette';
import { createTheme } from '@mui/material/styles';
import theme from './theme';

const localTheme = {
    ...theme,
    components: {
        MuiTable: {
            styleOverrides: {
                root: {
                    backgroundColor: palette.neutralWhite,
                    borderSpacing: '8px 0',
                    borderCollapse: 'separate',
                    borderBottom: `8px solid ${palette.brandYellowGreen}`,
                },
            },
        },
        MuiTableRow: {
            styleOverrides: {
                root: {
                    borderBottom: 'none',
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                head: {
                    borderBottom: `8px solid ${palette.brandYellowGreen}`,
                },
                root: {
                    backgroundColor: palette.neutralWhite,
                    borderBottom: 'none',
                    margin: 8,
                    padding: 8,
                },
            },
        },
    },
};

//eslint-disable-next-line
// @ts-ignore
export const tableTheme = createTheme(localTheme);
