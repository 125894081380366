import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import useStyles from './Communications.styles';
import theme from '../../styles/theme';
import PATHS from '../../router/paths';

export default function Communications() {
    const { classes } = useStyles(theme);

    const { pathname } = useLocation();

    if (pathname === `/${PATHS.communications}`)
        return <Navigate to={`/${PATHS.communications}/${PATHS.calendar}`} />;

    return (
        <div className={classes.communicationsWrapper}>
            <Outlet />
        </div>
    );
}
