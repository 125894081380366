import { PhoneType } from '../../../reusable/FormUI/PhoneNumberField';
import LeftColumnFarmInfo from './LeftColumnFarmInfo';
import { Stack } from '@mui/material';
import RightColumnFarmInfo from './RightColumnFarmInfo';

export interface OtherInfo {
    primaryFarmInfo: PrimaryFarmInfo;
    secondaryFarmInfo: SecondaryFarmInfo;
    remindersInfo: RemindersInfo;
    mailAddress: string;
    billingAddress: string;
}

export interface PrimaryFarmInfo {
    primaryEmail: string;
    phone: PhoneType[];
    address: string;
}
export interface SecondaryFarmInfo {
    secondaryEmail: string;
    phone: PhoneType[];
}
export interface RemindersInfo {
    lastOutreachDate: string;
}

export default function FarmOtherInfo({
    primaryFarmInfo,
    secondaryFarmInfo,
    remindersInfo,
    mailAddress,
    billingAddress,
}: OtherInfo) {
    return (
        <Stack direction={'row'} columnGap={8}>
            <LeftColumnFarmInfo
                primaryFarmInfo={primaryFarmInfo}
                remindersInfo={remindersInfo}
                mailAddress={mailAddress}
            />
            <RightColumnFarmInfo
                secondaryFarmInfo={secondaryFarmInfo}
                billingAddress={billingAddress}
            />
        </Stack>
    );
}
