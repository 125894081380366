import { useFormState } from 'react-final-form';

import PreviewMemberCard, {
    MemberCard,
} from '../CreateLandPartner/PreviewMemberCard';
import { format } from 'date-fns';
import { Box } from '@mui/material';
import { DATE_FORMAT } from '../../utils/dateFormat';

interface OwnProps {
    setPageNumber: (page: number) => void;
}

export default function PreviewContact({ setPageNumber }: OwnProps) {
    const { values: data } = useFormState();

    const handleModify = (page: number) => {
        setPageNumber(page);
    };

    const contactDataCreate: MemberCard = data && {
        firstName: data.firstName,
        lastName: data.lastName,
        avatar: data?.contactAvatar?.[0]
            ? URL.createObjectURL(data?.contactAvatar?.[0])
            : undefined,
        primaryEmail: data.primaryEmail || 'N/A',
        secondaryEmail: data.secondaryEmail || 'N/A',
        advocacy: data?.advocate,
        phoneNumber: data?.contactNumbers,
        address:
            (data?.addresses &&
                [
                    data.addresses[0]?.address1,
                    data.addresses[0]?.address2,
                    data.addresses[0]?.city,
                    data.addresses[0]?.state,
                    data.addresses[0]?.zipCode,
                ]
                    .filter(Boolean)
                    .join(', ')) ||
            'N/A',

        birthday:
            data?.keyDates?.birthDate &&
            format(data?.keyDates?.birthDate, DATE_FORMAT),
        dateOfDeath:
            data?.keyDates?.dateOfDeath &&
            format(data?.keyDates?.dateOfDeath, DATE_FORMAT),
        anniversary:
            data?.keyDates?.anniversary &&
            format(data?.keyDates?.anniversary, DATE_FORMAT),
        relationshipStartDate:
            data?.keyDates?.relationshipStartDate &&
            format(data?.keyDates?.relationshipStartDate, DATE_FORMAT),
        socialMedia: data?.socialMedia,
        isPrimaryContact: data?.primaryContact,
        onClick: () => handleModify(1),
    };

    return (
        <Box display={'flex'} flexDirection={'column'} p={2} rowGap={2}>
            <Box display={'flex'} flexDirection={'column'} rowGap={1} mb={4}>
                <PreviewMemberCard {...contactDataCreate} />
            </Box>
        </Box>
    );
}
