import React from 'react';
import * as _ from 'lodash';
import { Grid, Typography } from '@mui/material';

import useStyles from './Settings.styles';
import theme from '../../styles/theme';
import { useSettingsWidget } from './useSettingsWidget';
import SettingsWidget from './SettingWidget/SettingWidget';
import Modal from '../../layouts/Modal/Modal';

export default function OrganizationsAndUsers() {
    const { classes } = useStyles(theme);
    const {
        isShowing,
        toggle,
        header,
        component,
        organizationManagementWidgets,
        userManagementWidgets,
    } = useSettingsWidget();

    return (
        <div className={classes.settingsWrapper}>
            <Typography className={classes.widgetGroupTitle}>
                Organization Management
            </Typography>
            <Grid container spacing={2}>
                {_.map(organizationManagementWidgets, (widget) => (
                    <Grid item xs={3} key={widget.title}>
                        <SettingsWidget {...widget} />
                    </Grid>
                ))}
            </Grid>

            <Typography className={classes.widgetGroupTitle}>
                User Management
            </Typography>
            <Grid container spacing={2} columnGap={2}>
                {_.map(userManagementWidgets, (widget) => (
                    <Grid item xs={3} key={widget.title}>
                        <SettingsWidget {...widget} />
                    </Grid>
                ))}
            </Grid>

            <Modal
                header={header}
                isShowing={isShowing}
                hide={toggle}
                component={component}
            />
        </div>
    );
}
