import { createContext, Dispatch, SetStateAction } from 'react';
import { SearchResult, SearchType } from './SearchCard';

export const SearchContext = createContext<{
    data: SearchResult[];
    view: SearchResult[];
    type: SearchType;
    setType: Dispatch<SetStateAction<SearchType>>;
    setData: Dispatch<SetStateAction<SearchResult[]>>;
    setView: Dispatch<SetStateAction<SearchResult[]>>;
}>({
    data: [],
    view: [],
    type: 'ALL',
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setData: () => {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setView: () => {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setType: () => {},
});
