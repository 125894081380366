import { useContext, useEffect, useState } from 'react';
import { DocumentsType, DocumentTypeContext } from './DocumentTypeContext';

interface DocumentGroup {
    id: string;
    name: string;
}

export function useDocumentGroupsList() {
    const { type } = useContext(DocumentTypeContext);
    const [documentsGroup, setDocumentsGroup] = useState<DocumentGroup[]>([]);

    useEffect(() => {
        setDocumentsGroup(mockDocumentsGroupMapper[type]);
    }, [type]);

    const isLoading = false;
    return {
        documentsGroup,
        isLoading,
    };
}

const mockDocumentsGroupMapper: Record<DocumentsType, DocumentGroup[]> = {
    JOB_DESCRIPTION: [
        { id: '1', name: 'Job Description 1' },
        { id: '2', name: 'Job Description 2' },
    ],
    INSURANCE_POLICIES: [
        { id: '1', name: 'Insurance Policy 1' },
        { id: '2', name: 'Insurance Policy 2' },
    ],
    FARM_VISION: [
        { id: '1', name: 'Farm Vision 1' },
        { id: '2', name: 'Farm Vision 2' },
    ],
    DISASTER_PLANNING: [
        { id: '1', name: 'Disaster Planning 1' },
        { id: '2', name: 'Disaster Planning 2' },
    ],
};
