import React from 'react';
import FormPage from '../../reusable/FormUI/FormPage';
import { formMembersConfig } from './formMembersConfig';
import { useDynamicPreferredContactMethod } from '../../hooks/useDynamicPreferredContactMethod';
import PhoneNumberField from '../../reusable/FormUI/PhoneNumberField';

interface OwnProps {
    name: string;
}

export default function MembersForm({ name }: OwnProps) {
    const { preferredContactMethodList } =
        useDynamicPreferredContactMethod(name);

    return (
        <>
            <FormPage
                key={name}
                formConfig={formMembersConfig(name, preferredContactMethodList)}
            />
            <PhoneNumberField fieldName={`${name}.contactNumbers`} />
        </>
    );
}
