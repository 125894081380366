import FarmDetailsDashboard from './FarmDetailsDashboard';
import { MainInfo } from './FarmMainInfo';
import { OtherInfo } from './FarmOtherInfo';

export default function FarmDetailsDashboardWrapper() {
    return <FarmDetailsDashboard mainInfo={mainInfo} otherInfo={otherInfo} />;
}

const mainInfo: MainInfo = {
    avatar: 'https://t3.ftcdn.net/jpg/04/56/08/24/240_F_456082473_DYDUDKaRZnmxz1dNVnIxe9xnUXnBdNQu.jpg',
    name: 'Dream Farm Holding LLC',
    relationshipStartDate: '2012/11/10',
    taxId: '1234567890',
    status: 'ACTIVE',
    socialMedia: {
        facebook: 'https://www.facebook.com',
        instagram: 'https://www.instagram.com',
        twitter: 'https://www.twitter.com',
        linkedin: 'https://www.linkedin.com',
    },
};
const otherInfo: OtherInfo = {
    primaryFarmInfo: {
        primaryEmail: 'primary.farm@example.com',
        phone: [
            { type: 'OFFICE_PHONE', number: '15551234567' },
            { type: 'HOME_PHONE', number: '15559876543' },
        ],
        address: '123 Farm Lane, Rural Town, Country',
    },
    secondaryFarmInfo: {
        secondaryEmail: 'secondary.farm@example.com',
        phone: [
            { type: 'OFFICE_PHONE', number: '15551112222' },
            { type: 'HOME_PHONE', number: '15553334444' },
        ],
    },
    remindersInfo: {
        lastOutreachDate: '2024-08-10',
    },
    mailAddress: '456 Mailing Ave, Capital City, Country',
    billingAddress: '789 Billing Blvd, Finance District, Country',
};
