import FarmUploadDocument from './FarmUploadDocument';
import GroupDocumentDropdown from './GroupDocumentDoropdown';
import { useState } from 'react';
import { DocumentsType, DocumentTypeContext } from './DocumentTypeContext';
import DocumentGroupList from './DocumentGroupList';
import { Stack } from '@mui/material';

export default function FarmDocuments() {
    const [type, setType] = useState<DocumentsType>('JOB_DESCRIPTION');
    return (
        <DocumentTypeContext.Provider value={{ type, setType }}>
            <Stack rowGap={3}>
                <GroupDocumentDropdown />
                <DocumentGroupList />
                <FarmUploadDocument />
            </Stack>
        </DocumentTypeContext.Provider>
    );
}
