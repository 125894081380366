import { Box } from '@mui/material';

export default function TableSkeleton() {
    return (
        <Box>
            <Box
                display="flex"
                justifyContent="space-between"
                p={1}
                bgcolor="grey.200">
                {[...Array(5)].map((_, index) => (
                    <Box
                        key={index}
                        bgcolor="grey.300"
                        width={`${10 * (index + 1)}%`}
                        height={30}></Box>
                ))}
            </Box>
            {[...Array(7)].map((_, rowIndex) => (
                <Box
                    key={rowIndex}
                    display="flex"
                    justifyContent="space-between"
                    p={1}
                    bgcolor="grey.100">
                    {[...Array(5)].map((_, colIndex) => (
                        <Box
                            key={colIndex}
                            bgcolor="grey.200"
                            width={`${10 * (colIndex + 1)}%`}
                            height={30}></Box>
                    ))}
                </Box>
            ))}
            <Box
                display="flex"
                justifyContent="center"
                p={1}
                bgcolor="grey.200">
                {[...Array(5)].map((_, index) => (
                    <Box
                        key={index}
                        bgcolor="grey.300"
                        width="10%"
                        height={20}></Box>
                ))}
            </Box>
        </Box>
    );
}
