import { FormConfig } from '../../../design/Forms/interfaces';
import { composeValidators, maxLengthOfArray } from '../../../utils/validation';

const contactRoleList = [
    { id: 'Landowner', name: 'Landowner' },
    { id: 'Contractor', name: 'Contractor' },
    { id: 'Associate - Banking', name: 'Associate - Banking' },
    { id: 'Associate -  Legal', name: 'Associate -  Legal' },
    { id: 'Employee - Operations', name: 'Employee - Operations' },
    { id: 'Family Member', name: 'Family Member' },
    { id: 'Machinery Dealer', name: 'Machinery Dealer' },
    { id: 'Associate - Insurance', name: 'Associate - Insurance' },
    { id: 'Agronomy Services', name: 'Agronomy Services' },
    { id: 'Employee - Backoffice', name: 'Employee - Backoffice' },
    { id: 'Personal Contact', name: 'Personal Contact' },
    { id: 'General Business Contact', name: 'General Business Contact' },
    { id: 'Farm Manager', name: 'Farm Manager' },
];
interface Option {
    id: string;
    name: string;
}
interface Props {
    organization: Option[];
    isEdit: boolean;
}
export const relationshipTreeConfig = ({
    organization,
    isEdit,
}: Props): FormConfig[] => [
    {
        formField: {
            name: 'subEntityId',
            type: 'select',
            label: '',
        },
        renderProps: {
            placeholder: 'Select from list',
            isDisabled: isEdit,
            size: 'small',
            options: organization,
        },
    },
];

export const relationshipContactRoles: FormConfig[] = [
    {
        formField: {
            scheme: 'full',
            name: `contactRoles`,
            type: 'autocomplete',
            label: '',
            validation: composeValidators(maxLengthOfArray(2)),
        },
        renderProps: {
            freeSolo: true,
            placeholder: '',
            isDisabled: false,
            size: 'half',
            options: contactRoleList,
        },
    },
];
