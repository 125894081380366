import { useSettingsFromStorage } from '../../hooks/useSettingsFromStorage';
import { useParams } from 'react-router-dom';
import { useSorting } from '../../design/GenericTable/useSorting';
import { usePagination } from '../../design/GenericTable/useBackendPagination';
import { useSaveSettings } from '../../hooks/useSaveSettings';
import { TableColumn } from '../../design/GenericTable/GenericTable';
import { dateTimeConverterFromUTC } from '../../utils/dateFormat';
import { useLandPartnersHistoryQuery } from '../../api/landpartners/landPartnersAPI';
import { LandPartner } from '../CreateLandPartner/LandPartnerWizard';

type Action =
    | 'CREATE_LP'
    | 'UPDATE_LP'
    | 'UPLOAD_FILE'
    | 'DOWNLOAD_FILE'
    | 'CREATE_NOTE'
    | 'UPDATE_NOTE'
    | 'CREATE_CONTACT'
    | 'UPDATE_CONTACT';
export interface LandPartnerHistory {
    id: string;
    entityName: string;
    entityId: string;
    action: Action;
    createdBy: string;
    createdByName: string;
    valueFrom: LandPartner;
    valueTo: LandPartner;
    createdAt: string;
}

export default function useLPContactHistoryTable() {
    const { id } = useParams();
    const { settings, saveSettings } = useSettingsFromStorage(
        'LEASE_HISTORY_TABLE',
    );
    const { sort, page, sortDirection, size } = settings;
    const { sortKey, sortOrder, handleSort } = useSorting(
        sort || 'leaseName',
        sortDirection || 'asc',
    );

    const {
        currentPage,
        itemsPerPage,
        handlePageChange,
        handleItemsPerPageChange,
    } = usePagination(page || 1, size || 25);

    useSaveSettings({
        settings,
        saveSettings,
        sortKey,
        sortOrder,
        currentPage,
        itemsPerPage,
    });

    const { data, isLoading } = useLandPartnersHistoryQuery({
        id,
        page: currentPage - 1,
        size: itemsPerPage,
    });

    const columns: TableColumn<LandPartnerHistory>[] = [
        {
            header: {
                label: 'Date',
                sortable: false,
                sorting: {
                    id: 'createdAt',
                    direction: sortKey === 'createdAt' ? sortOrder : 'desc',
                    isSorted: sortKey === 'createdAt',
                },
                onClick: handleSort,
            },
            cellRender: (data) => (
                <>{dateTimeConverterFromUTC(data?.createdAt)}</>
            ),
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
        },
        {
            header: {
                label: 'User Name',
                sortable: false,
                sorting: {
                    id: 'createdBy',
                    direction: sortKey === 'createdBy' ? sortOrder : 'desc',
                    isSorted: sortKey === 'createdBy',
                },
                onClick: handleSort,
            },
            cellRender: (data) => <>{data?.createdByName}</>,
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
        },
        {
            header: {
                label: 'Action',
                sortable: false,
                sorting: {
                    id: 'action',
                    direction: sortKey === 'action' ? sortOrder : 'desc',
                    isSorted: sortKey === 'action',
                },
                onClick: handleSort,
            },
            cellRender: (data) => <>{LandPartnerActionMapper[data?.action]}</>,
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
        },
        {
            header: {
                label: 'Note',
                sortable: false,
            },
            cellRender: (data) => (
                <>{LandPartnerNoteActionMapper[data?.action]}</>
            ),
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
        },
    ];
    const tableConfig = {
        columns,
        pageable: true,
        handlePageChange,
        handleItemsPerPageChange,
        initialPageSize: size,
        initialPage: page,
    };

    const totalCount = data?.totalCount || 0;
    return {
        data: data?.data || [],
        totalCount,
        tableConfig,
        isLoading,
    };
}

const LandPartnerActionMapper: Record<Action, string> = {
    CREATE_LP: 'Create a Land Partner',
    UPDATE_LP: 'Edit the Land Partner',
    UPLOAD_FILE: 'Upload a file',
    DOWNLOAD_FILE: 'Download a file',
    CREATE_NOTE: 'Create a note',
    UPDATE_NOTE: 'Update a note',
    CREATE_CONTACT: 'Create a Contact',
    UPDATE_CONTACT: 'Update a Contact',
};
const LandPartnerNoteActionMapper: Record<Action, string> = {
    CREATE_LP: 'Create a Land Partner',
    UPDATE_LP: 'Edit the Land Partner',
    UPLOAD_FILE: 'Upload a file',
    DOWNLOAD_FILE: 'Download a file',
    CREATE_NOTE: 'Create a note',
    UPDATE_NOTE: 'Update a note',
    CREATE_CONTACT: 'Create a Contact',
    UPDATE_CONTACT: 'Update a Contact',
};
