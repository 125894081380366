import React, { useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { Box, Collapse, Divider, IconButton, Typography } from '@mui/material';
import PrimaryContactMainInfo, {
    PrimaryContactStatus,
} from './PrimaryContactMainInfo';
import { UserMain } from '../../../reusable/UserInfoPanel/UserMainInfo';
import { SocialMedia } from '../../ViewLandownersDetails/LandPartnerDetails';
import { ReactComponent as BuildingIcon } from '../../../assets/icons/building_icon.svg';
import { ReactComponent as LabelIcon } from '../../../assets/icons/label_icon.svg';
import { ReactComponent as BirthdayIcon } from '../../../assets/icons/birthday_icon.svg';
import { ReactComponent as ExpandIcon } from '../../../assets/icons/expand_icon.svg';
import { ReactComponent as DateOfDeathIcon } from '../../../assets/icons/spa_icon.svg';
import InfoWithLabel from '../../../reusable/InfoWithLabel';
import clsx from 'clsx';
import { getAdvocateNameById } from '../../CreateContact/FormConfigs/contactDetailsConfig';
import { PhoneType } from '../../../reusable/FormUI/PhoneNumberField';
import PrimaryContactInfo from './PrimaryContactInfo';
import SecondaryContactInfo from './SecondaryContactInfo';
import { useNavigate } from 'react-router-dom';
import PATHS from '../../../router/paths';
import DeactivateContactModal from './DeactivateContactModal';
import ActionButton, {
    ActionMenuItem,
} from '../../../design/Buttons/ActionButton';
import { EmailStatus } from '../../../pages/AllProfiles/AllProfilesTable';
import useManagePermission from '../../../hooks/useManagePermission';

interface OwnProps {
    userMain: UserMain;
    status: PrimaryContactStatus;
    socialMedia?: SocialMedia;
    primaryContactData?: PrimaryContactData;
    secondaryContactData?: SecondaryContactData;
    remindersData?: RemindersData;
    advocacyData?: string[];
    mailingAddress?: string;
    billingAddress?: string;
}

export interface PrimaryContactData {
    id: string;
    email?: string;
    phone?: PhoneType[];
    address?: string;
    emailStatus: EmailStatus;
}

export interface SecondaryContactData {
    id: string;
    email?: string;
    emailStatus: EmailStatus;
}

export interface RemindersData {
    lastOutreach?: string;
    birthday?: string;
    anniversary?: string;
    customerSince?: string;
    dateOfDeath?: string;
}

//TODO: refactor this component. Extract the primary contact info and secondary contact info into separate components
export default function PrimaryContactInfoPanel({
    userMain,
    status,
    socialMedia,
    primaryContactData,
    secondaryContactData,
    mailingAddress,
    billingAddress,
    advocacyData,
    remindersData,
}: OwnProps) {
    const { classes } = useStyles();
    const navigate = useNavigate();

    const [expandedReminders, setExpandedReminders] = useState(true);
    const [expandedAdvocacy, setExpandedAdvocacy] = useState(true);

    const [isDeactivateModalShowing, setIsDeactivateModalShowing] =
        useState(false);

    const handleExpandClickAdvocacy = () => {
        setExpandedAdvocacy(!expandedAdvocacy);
    };
    const handleExpandClickReminders = () => {
        setExpandedReminders(!expandedReminders);
    };
    const { checkPermission } = useManagePermission();
    const actionButtonItems: ActionMenuItem[] = [
        {
            label: `Edit`,
            onClick: () =>
                navigate(
                    `/${PATHS.landpartners}/${PATHS.allProfiles}/${userMain?.id}/${PATHS.update}`,
                ),
            hidden: !checkPermission('contacts', 'modify'),
        },
        {
            label: 'Archive',
            onClick: () => setIsDeactivateModalShowing(true),
            hidden: !checkPermission('contacts', 'modify'),
        },
    ];
    return (
        <Box className={classes.containerWrapper}>
            <Box display={'flex'}>
                <PrimaryContactMainInfo
                    userMain={userMain}
                    status={status}
                    {...socialMedia}
                />
                <Divider orientation={'vertical'} flexItem variant="middle" />
                <Box display={'flex'} flexDirection={'column'}>
                    <Box display={'flex'} columnGap={4} p={2} width={'100%'}>
                        <Box
                            flexDirection={'column'}
                            display={'flex'}
                            width={'50%'}
                            columnGap={2}
                            rowGap={2}>
                            {primaryContactData && (
                                <PrimaryContactInfo
                                    primaryContactData={primaryContactData}
                                />
                            )}
                            <Box
                                display={'flex'}
                                flexDirection={'column'}
                                rowGap={1}>
                                <Typography
                                    variant={'font12'}
                                    color={'text.neutral9'}>
                                    Mailing
                                </Typography>
                                <InfoWithLabel
                                    icon={BuildingIcon}
                                    text={mailingAddress}
                                />
                            </Box>
                        </Box>
                        <Box
                            flexDirection={'column'}
                            display={'flex'}
                            columnGap={2}
                            rowGap={2}
                            width={'50%'}>
                            {secondaryContactData && (
                                <SecondaryContactInfo
                                    secondaryContactData={secondaryContactData}
                                />
                            )}
                            <Box
                                display={'flex'}
                                flexDirection={'column'}
                                rowGap={1}
                                pt={3}>
                                <Typography
                                    variant={'font12'}
                                    color={'text.neutral9'}>
                                    Billing
                                </Typography>
                                <InfoWithLabel
                                    icon={BuildingIcon}
                                    text={billingAddress}
                                />
                            </Box>
                        </Box>
                    </Box>
                    <Box display={'flex'} width={'100%'} columnGap={4} p={2}>
                        <Box
                            width={'50%'}
                            display={'flex'}
                            flexDirection={'column'}
                            rowGap={1}>
                            <Box
                                display={'flex'}
                                justifyContent={'space-between'}
                                alignItems={'center'}
                                columnGap={2}>
                                <Typography
                                    variant={'font12'}
                                    color={'text.neutral9'}>
                                    Reminders
                                </Typography>
                                <div>
                                    <Typography
                                        variant={'font12'}
                                        color={'text.link'}
                                        fontWeight={'medium'}>
                                        {expandedReminders
                                            ? 'Collapse'
                                            : 'Expand'}
                                    </Typography>
                                    <IconButton
                                        onClick={handleExpandClickReminders}>
                                        <ExpandIcon
                                            className={clsx(
                                                expandedReminders &&
                                                    classes.expanded,
                                            )}
                                        />
                                    </IconButton>
                                </div>
                            </Box>
                            <Collapse
                                in={expandedReminders}
                                timeout="auto"
                                unmountOnExit>
                                <Box
                                    display={'flex'}
                                    flexDirection={'column'}
                                    rowGap={1}>
                                    <InfoWithLabel
                                        icon={LabelIcon}
                                        text={`Last Outreach: ${remindersData?.lastOutreach}`}
                                    />
                                    <InfoWithLabel
                                        icon={BirthdayIcon}
                                        text={`Birthday: ${remindersData?.birthday}`}
                                    />
                                    <InfoWithLabel
                                        isHidden={!remindersData?.dateOfDeath}
                                        icon={DateOfDeathIcon}
                                        text={`Date of Death: ${remindersData?.dateOfDeath}`}
                                    />
                                    <InfoWithLabel
                                        icon={LabelIcon}
                                        text={`Wedding Anniversary: ${remindersData?.anniversary}`}
                                    />
                                    <InfoWithLabel
                                        icon={LabelIcon}
                                        text={`Customer Since: ${remindersData?.customerSince}`}
                                    />
                                </Box>
                            </Collapse>
                        </Box>
                        <Box
                            display={'flex'}
                            flexDirection={'column'}
                            rowGap={1}
                            width={'50%'}>
                            <Box
                                display={'flex'}
                                justifyContent={'space-between'}
                                alignItems={'center'}
                                columnGap={2}>
                                <Typography
                                    variant={'font12'}
                                    color={'text.neutral9'}>
                                    Advocacy
                                </Typography>
                                <div>
                                    <Typography
                                        variant={'font12'}
                                        color={'text.link'}
                                        fontWeight={'medium'}>
                                        {expandedAdvocacy
                                            ? 'Collapse'
                                            : 'Expand'}
                                    </Typography>
                                    <IconButton
                                        onClick={handleExpandClickAdvocacy}>
                                        <ExpandIcon
                                            className={clsx(
                                                expandedAdvocacy &&
                                                    classes.expanded,
                                            )}
                                        />
                                    </IconButton>
                                </div>
                            </Box>
                            <Collapse
                                in={expandedAdvocacy}
                                timeout="auto"
                                unmountOnExit>
                                <Box
                                    display={'flex'}
                                    flexDirection={'column'}
                                    rowGap={1}>
                                    {advocacyData &&
                                        advocacyData.map((advocate) => (
                                            <InfoWithLabel
                                                key={advocate}
                                                icon={LabelIcon}
                                                text={getAdvocateNameById(
                                                    advocate,
                                                )}
                                            />
                                        ))}
                                </Box>
                            </Collapse>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <ActionButton label={'Actions'} items={actionButtonItems} />
            {isDeactivateModalShowing && (
                // eslint-disable-next-line max-lines
                <DeactivateContactModal
                    isShowing={isDeactivateModalShowing}
                    contactId={userMain?.id}
                    onClose={() => setIsDeactivateModalShowing(false)}
                />
            )}
        </Box>
    );
}

const useStyles = makeStyles()((theme) => ({
    // eslint-disable-next-line max-lines
    containerWrapper: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2, 3),
        backgroundColor: theme.palette.neutralWhite,
        borderRadius: 16,
    },
    expanded: {
        width: 24,
        height: 24,
        transform: 'rotate(180deg)',
    },
}));
