import React from 'react';
import { FieldArray } from 'react-final-form-arrays';
import FormPage from '../../../../reusable/FormUI/FormPage';
import { Box, Button, Divider, Grid } from '@mui/material';
import { useForm } from 'react-final-form';
import { formSubEntitiesConfig } from './formSubEntitiesConfig';
import PhoneNumberField from '../../../../reusable/FormUI/PhoneNumberField';

export default function SubOrganizationFormPage() {
    const { mutators } = useForm();

    return (
        <Box display={'flex'} flexDirection={'column'} gap={2}>
            <FieldArray name="subOrganizations">
                {({ fields }) => {
                    return fields.map((name, index) => (
                        <Box
                            key={name}
                            display={'flex'}
                            flexDirection={'column'}
                            rowGap={1}>
                            <Grid container spacing={1} gap={1} key={name}>
                                <FormPage
                                    formConfig={formSubEntitiesConfig(name)}
                                />
                                <PhoneNumberField
                                    fieldName={`${name}?.contactNumbers`}
                                />
                            </Grid>
                            <Button onClick={() => fields.remove(index)}>
                                Remove Sub Entity
                            </Button>
                            <Divider />
                        </Box>
                    ));
                }}
            </FieldArray>

            <Button
                type="button"
                onClick={() => mutators.push('subOrganizations')}>
                Add Another Sub Entity
            </Button>
        </Box>
    );
}
