import theme from '../../../styles/theme';
import useStyles from './Users.styles';
import AllRoles from '../Roles/AllRoles/AllRoles';
import BasicTabsPanel from '../../../design/TabsPanel/BasicTabsPanel';
import AllUsers from './AllUsers/AllUsers';
import AllInactiveUsers from './AllInactiveUsers/AllInactiveUsers';

const tabsConfig = [
    {
        label: 'All Active Users',
        content: <AllUsers />,
        value: 0,
        to: '',
    },
    {
        label: 'All Inactive Users',
        content: <AllInactiveUsers />,
        value: 1,
        to: '',
    },
    {
        label: 'Define Roles',
        content: <AllRoles />,
        value: 2,
        to: '',
    },
    {
        label: 'Groups',
        content: <div>groups</div>,
        value: 3,
        to: '',
    },
    {
        label: 'Activity ',
        content: <div>activity</div>,
        value: 4,
        to: '',
    },
];

export default function Users() {
    const { classes } = useStyles(theme);

    return (
        <div className={classes.usersWrapper}>
            <BasicTabsPanel tabItems={tabsConfig} />
        </div>
    );
}
