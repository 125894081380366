import React from 'react';
import { useGetOneLandPartnerQuery } from '../../../api/landpartners/landPartnersAPI';
import useAvatar from '../../../hooks/useAvatar';
import { ReactComponent as PhoneIcon } from '../../../assets/icons/phone_icon.svg';
import { ReactComponent as EmailIcon } from '../../../assets/icons/communications_icon.svg';
import { ReactComponent as LocationIcon } from '../../../assets/icons/pin_location_icon.svg';
import { Box, CircularProgress, Paper } from '@mui/material';
import UserAvatar from '../../../design/Avatar/UserAvatar';
import InfoWithLabel from '../../../reusable/InfoWithLabel';
import LinkText from '../../../design/Fields/LinkText';
import { phoneTypeMapper } from '../../../features/CreateLandPartner/PreviewLandPartner';
import { phoneMaskForString } from '../../../utils/fieldMasks';
import { makeStyles } from 'tss-react/mui';
import theme from '../../../styles/theme';
import CheckboxRow from '../../../design/Fields/CheckboxRow';
import {
    emergencyResponseAndSafety,
    farmAndCropManagement,
    legalAndFinancial,
} from './utils';
import { FieldProps } from 'react-final-form';
import AppTypography from '../../../design/AppTypography';

interface OwnProps {
    id: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    input: FieldProps<any, any>;
}

export default function EmergencyContactCard({ id, input }: OwnProps) {
    const { onChange, value } = input;

    const { data, isLoading, isFetching } = useGetOneLandPartnerQuery(id);
    const { classes } = useStyles();
    const { avatarUrl: landPartnerLogo } = useAvatar(
        data?.landPartnerLogo?.companyLogo,
        data?.landPartnerLogo?.fileType,
    );
    if (isLoading || isFetching) {
        return <CircularProgress />;
    }
    if (!data) return null;
    const address =
        data?.billingAddress &&
        [
            data.billingAddress.address1,
            data.billingAddress.address2,
            data.billingAddress.city,
            data.billingAddress.state,
            data.billingAddress.zipCode,
        ]
            .filter(Boolean)
            .join(', ');

    return (
        <Paper className={classes.container}>
            <Box display={'flex'} justifyContent={'space-between'}>
                <Box display={'flex'} columnGap={1}>
                    <UserAvatar name={data?.name} avatar={landPartnerLogo} />
                    <Box display={'flex'} flexDirection={'column'}>
                        <AppTypography
                            variant={'font20'}
                            fontWeight="medium"
                            color="text.secondary"
                            text={data?.name}
                        />
                    </Box>
                </Box>
            </Box>
            <Box
                width={'100%'}
                display={'flex'}
                flexDirection={'column'}
                rowGap={2}>
                <AppTypography
                    variant={'font12'}
                    fontWeight="medium"
                    color="text.neutral7"
                    text={'Select Contact Type'}
                />
                <CheckboxRow
                    size={'third'}
                    value={value.lefalAndFinancial}
                    name="lefalAndFinancial"
                    options={legalAndFinancial}
                    onChange={onChange}
                />
                <AppTypography
                    variant={'font12'}
                    fontWeight="medium"
                    color="text.neutral7"
                    text={'Emergency Response + Safety'}
                />
                <CheckboxRow
                    size={'auto'}
                    value={value.emergencyResponseAndSafety}
                    name="emergencyResponseAndSafety"
                    options={emergencyResponseAndSafety}
                    onChange={onChange}
                />
                <AppTypography
                    variant={'font12'}
                    fontWeight="medium"
                    color="text.neutral7"
                    text={'Farm + Crop Management'}
                />
                <CheckboxRow
                    size={'auto'}
                    value={value.farmAndCropManagement}
                    name="farmAndCropManagement"
                    options={farmAndCropManagement}
                    onChange={onChange}
                />
            </Box>
            <InfoWithLabel
                icon={EmailIcon}
                text={
                    data.email ? (
                        <LinkText
                            to={`mailto:${data.email}`}
                            text={data.email}
                            fontWeight={'medium'}
                        />
                    ) : (
                        'N/A'
                    )
                }
            />
            {data.phoneNumber?.map((phone) => {
                return (
                    <InfoWithLabel
                        key={phone.type}
                        icon={PhoneIcon}
                        text={`${phoneTypeMapper[phone?.type]}
                                        : ${phoneMaskForString(phone?.number)}`}
                    />
                );
            })}
            <InfoWithLabel icon={LocationIcon} text={address || 'N/A'} />
        </Paper>
    );
}
const useStyles = makeStyles()(() => ({
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: 500,
        border: ` solid 1px ${theme.palette.neutral.neutral3}`,
        gap: theme.spacing(1),
        padding: theme.spacing(2),
        boxShadow: theme.shadows[1],
    },
}));
