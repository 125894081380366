import React, { useState } from 'react';
import { Grid } from '@mui/material';
import BasePieChart, {
    PieChartConfig,
} from '../../design/Charts/PieChart/BasePieChart';
import ActionButton, {
    ActionMenuItem,
} from '../../design/Buttons/ActionButton';

import { useGetLandPartnersSummaryQuery } from '../../api/landpartners/landPartnersAPI';

import { useNavigate } from 'react-router-dom';
import PATHS from '../../router/paths';

import LastOutreachTable from './LastOutreachTable';
import LandPartnersTable, { LandPartnerTableView } from './LandPartnersTable';
import { initCustomViewForLandPartners } from '../customizeViewInitialStates';
import {
    useGetOneConfigQuery,
    useLazyGetOneConfigQuery,
} from '../../api/users/customizeConfigAPI';
import Loader from '../../design/BaseLoader';
import CustomizeViewModal from '../CustomizeViewModal';
import { useSelection } from '../../design/GenericTable/useSelection';
import {
    createDownloadLinkFromBlob,
    getFilenameFromContentDisposition,
} from '../../utils/fileHandler';
import { useLazyDownloadBulkLandPartnerQuery } from '../../api/fileApiSlice';
import useManagePermission from '../../hooks/useManagePermission';
import useCustomizeView from '../../hooks/useCustomizeView';

export interface chartValue {
    totalLandPartners: number;
    activeLandPartners: number;
    inactiveLandPartners: number;
    prospectLandPartners: number;
    totalRelationships: number;
    activeRelationships: number;
    inactiveRelationships: number;
    prospectRelationships: number;
}

export default function LandPartnerProfilesPage() {
    const navigate = useNavigate();
    const [isShowingCustomizeView, setIsShowingCustomizeView] = useState(false);
    const [getDocuments] = useLazyDownloadBulkLandPartnerQuery();
    const { data: chartData, isLoading: isDashboardsLoading } =
        useGetLandPartnersSummaryQuery({});

    const { data: customizeConfig, isLoading: isLoadingCustomizeConfig } =
        useGetOneConfigQuery({ viewType: 'LAND_PARTNERS' });
    const [
        getData,
        { data: customizeConfigLazy, isLoading: isLoadingLazyCustomizeConfig },
    ] = useLazyGetOneConfigQuery();
    const { configData, isLoading, isFetched } = useCustomizeView({
        fetchedData: customizeConfigLazy || customizeConfig,
        isLoading: isLoadingLazyCustomizeConfig || isLoadingCustomizeConfig,
        initialTableSettings: initCustomViewForLandPartners,
        viewType: 'LAND_PARTNERS',
    });

    const { checkPermission } = useManagePermission();
    const {
        selected: selectedRows,
        isSelected,
        toggleSelection,
        toggleSelectionAll,
        isSelectedAll,
    } = useSelection<LandPartnerTableView>();

    const documentsIds = () => {
        const ids = selectedRows.map((i) => `${i.id}`);
        return `landPartnerIds=${ids.join(',')} `;
    };
    const handleDownload = async ({
        documentIds,
    }: {
        documentIds?: string;
    }) => {
        getDocuments(documentIds)
            .unwrap()
            .then(({ data, headers }) => {
                createDownloadLinkFromBlob(
                    data,
                    getFilenameFromContentDisposition(headers),
                );
            })
            .then(() => toggleSelectionAll([]));
    };

    const actionButtonItems: ActionMenuItem[] = [
        {
            label: 'Add a Land Partner',
            onClick: () => {
                navigate(`${PATHS.create}`, { state: 'LAND_PARTNER' });
            },
            hidden: !checkPermission('landPartners', 'modify'),
        },
        {
            label: `Download LandPartners (${selectedRows.length})`,
            onClick: () => handleDownload({ documentIds: documentsIds() }),
            hidden: !selectedRows.length,
        },
        {
            label: `Download All LandPartners`,
            onClick: () => handleDownload({}),
        },
        {
            label: 'Customize View',
            onClick: () => {
                setIsShowingCustomizeView(true);
            },
        },

        {
            label: 'Create a Task',
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            onClick: () => {},
        },

        {
            label: 'Set a Reminder',
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            onClick: () => {},
        },
    ];
    if (
        isDashboardsLoading ||
        isLoadingCustomizeConfig ||
        isLoadingLazyCustomizeConfig ||
        isLoading
    ) {
        return (
            <Grid
                container
                sx={{ height: '100vh' }}
                alignItems={'center'}
                justifyContent={'center'}
                flexDirection={'column'}>
                <Loader />
            </Grid>
        );
    }
    return (
        <>
            <Grid container item xs={12} justifyContent={'space-between'}>
                <BasePieChart
                    data={totalLandPartners(chartData)}
                    config={chartConfigTotalLandPartners(chartData)}
                />
                <BasePieChart
                    data={totalContacts(chartData)}
                    config={chartConfigTotalContacts(chartData)}
                />
                <Grid item xs={5}>
                    <LastOutreachTable />
                </Grid>
            </Grid>
            <Grid
                container
                justifyContent={'flex-end'}
                width={'100%'}
                sx={{
                    padding: 2,
                }}>
                <ActionButton label={'Actions'} items={actionButtonItems} />
            </Grid>
            <LandPartnersTable
                config={configData}
                isSelected={isSelected}
                toggleSelection={toggleSelection}
                toggleSelectionAll={toggleSelectionAll}
                isSelectedAll={isSelectedAll}
            />
            <CustomizeViewModal
                config={configData}
                viewType={'LAND_PARTNERS'}
                isShowing={isShowingCustomizeView}
                onClose={() => {
                    setIsShowingCustomizeView(false);
                    getData({ viewType: 'LAND_PARTNERS' });
                }}
                isFetched={isFetched}
            />
        </>
    );
}

function totalLandPartners(summaryData: chartValue) {
    return [
        { name: 'Active', value: summaryData?.activeLandPartners || 0 },
        { name: 'Inactive', value: summaryData?.inactiveLandPartners || 0 },
        { name: 'Prospect', value: summaryData?.prospectLandPartners || 0 },
    ];
}

function totalContacts(summaryData: chartValue) {
    return [
        { name: 'Active', value: summaryData?.activeRelationships || 0 },
        { name: 'Inactive', value: summaryData?.inactiveRelationships || 0 },
        { name: 'Prospect', value: summaryData?.prospectRelationships || 0 },
    ];
}

function chartConfigTotalLandPartners(summaryData: chartValue): PieChartConfig {
    return {
        summaryTitle: `Total Land Partners`,
        summaryValue: `${summaryData?.totalLandPartners || 0}`,
        showLegend: true,
        dataKey: 'value',
        chartTitle: 'LAND PARTNERS',
        showTooltip: true,
    };
}

function chartConfigTotalContacts(summaryData: chartValue): PieChartConfig {
    return {
        summaryTitle: 'Total Contacts',
        summaryValue: `${summaryData?.totalRelationships || 0}`,
        showLegend: true,
        dataKey: 'value',
        chartTitle: 'RELATIONSHIPS',
        showTooltip: true,
    };
}
