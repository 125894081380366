import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material/styles/createTheme';

const useStyles = makeStyles<Theme>()((theme) => ({
    table: {
        backgroundColor: theme.palette.neutral.neutral1,
        minWidth: '650px',
    },
    headerRow: {
        height: '40px',
    },
    groupHeaderCell: {
        backgroundColor: theme.palette.brandYellowGreen,
        fontSize: '13px',
        fontWeight: 600,
        borderRight: '2px solid white',
    },
    centredCell: {
        textAlign: 'center',
        borderRight: '2px solid white',
    },
    roleButton: {
        backgroundColor: theme.palette.lightTurq.lightTurqTint7,
        color: theme.palette.neutralWhite,
        fontSize: '14px',
        '&:hover': {
            backgroundColor: theme.palette.lightTurq.lightTurqTint5,
        },
    },
    additionalPadding: {
        paddingTop: '16px',
    },
    alternatingRowWhite: {
        backgroundColor: theme.palette.neutralWhite,
    },
    alternatingRowBlue: {
        backgroundColor: theme.palette.neutral.neutral1,
    },
}));

export default useStyles;
