import React, { useEffect } from 'react';

import arrayMutators from 'final-form-arrays';

import { useNavigate, useParams } from 'react-router-dom';
import PATHS from '../../../../router/paths';
import WizardForm from '../../../../design/Forms/WizardForm';

import useHandleSubmitRTK from '../../../../hooks/useHandleSubmit';
import {
    useAddOrganizationMutation,
    useLazyGetOneOrganizationQuery,
    useUpdateOrganizationMutation,
} from '../../../../api/users/organizationsAPI';
import FormPage from '../../../../reusable/FormUI/FormPage';
import { formBillingConfig } from './formBillingConfig';
import SubOrganizationFormPage from './SubOrganizationFormPage';
import PreviewOrganizationPage from './PreviewOrganizationPage';
import { Grid } from '@mui/material';
import Loader from '../../../../design/BaseLoader';
import { OrganizationFormPage } from './OrganizationFormPage';
import OrganizationSubscriptionPage from './OrganizationSubscriptionPage';

export interface OrganizationValue {
    name: string;
    type: string;
    primaryContact: PrimaryContact;
    primaryAddress: Address;
    billingAddress: Address;
    subOrganizations: SubOrganization[];
    email: string;
    phoneNumber: string;
    maxUsersCount: number;
    status: string;
}

export interface PrimaryContact {
    firstName: string;
    lastName: string;
    primaryEmail: string;
}

export interface Address {
    type: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    zipCode: string;
}

export interface SubOrganization {
    name: string;
    type: string;
    primaryContact: PrimaryContact;
    address: Address;
    email: string;
    phoneNumber: string;
}

interface OwnProps {
    pageNumber: number;
    setPageNumber: (page: number) => void;
    setIsValid: (isValid: boolean) => void;
}

export default function OrganizationForm({
    pageNumber,
    setPageNumber,
    setIsValid,
}: OwnProps) {
    const { id } = useParams();
    const navigate = useNavigate();

    const isEdit = !!id;
    const [
        getItems,
        { isFetching: isFetchingOne, isLoading: isLoadingOne, data: initData },
    ] = useLazyGetOneOrganizationQuery();

    useEffect(() => {
        id && getItems(id);
    }, [id]);

    const { handleSubmit: handleSubmitCreate, isLoading: isCreating } =
        useHandleSubmitRTK({
            useRtkHook: useAddOrganizationMutation,
            onSuccess: () =>
                navigate(
                    `/${PATHS.settings}/${PATHS.orgAndUsers}/${PATHS.organizations}`,
                ),
            successMessage: 'Organization added successfully',
        });
    const { handleSubmit: handleSubmitUpdate, isLoading: isUpdating } =
        useHandleSubmitRTK({
            useRtkHook: useUpdateOrganizationMutation,
            onSuccess: () =>
                navigate(
                    `/${PATHS.settings}/${PATHS.orgAndUsers}/${PATHS.organizations}`,
                ),
            successMessage: 'Organization updated successfully',
        });

    if (isFetchingOne || isLoadingOne) {
        return (
            <Grid
                width={'100%'}
                height={'100%'}
                container
                alignItems={'center'}
                justifyContent={'center'}>
                <Loader />
            </Grid>
        );
    }
    function handleSubmitLandPartner(value: OrganizationValue) {
        const convertedValue = {
            ...value,
            email: value.primaryContact.primaryEmail,
        };
        if (isEdit) {
            handleSubmitUpdate(convertedValue);
            return;
        }
        handleSubmitCreate(convertedValue);
    }

    return (
        <WizardForm
            setIsValid={setIsValid}
            isLoading={isCreating || isUpdating}
            mutators={arrayMutators as never}
            initialValues={
                isEdit ? initData : { type: 'LANDOWNER', status: 'ACTIVE' }
            }
            onSubmit={handleSubmitLandPartner}
            setStep={setPageNumber}
            step={pageNumber}>
            <OrganizationFormPage />
            <OrganizationSubscriptionPage />
            <FormPage formConfig={formBillingConfig} />
            <SubOrganizationFormPage />
            <PreviewOrganizationPage setPageNumber={setPageNumber} />
        </WizardForm>
    );
}
