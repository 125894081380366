import { ReactNode } from 'react';
import { Box, Typography } from '@mui/material';
import theme from '../../styles/theme';

interface OwnProps {
    title: string;
    children: ReactNode;
}

export default function FormScreenWrapper({ title, children }: OwnProps) {
    return (
        <Box
            display={'flex'}
            flexDirection={'column'}
            bgcolor={theme.palette.neutralWhite}
            p={2}
            rowGap={1}>
            <Typography
                variant={'font20'}
                color={'color.neutral9'}
                fontWeight={'medium'}>
                {title}
            </Typography>

            {children}
        </Box>
    );
}
