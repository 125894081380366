import { Divider, Grid } from '@mui/material';
import IntegrationCardActions from './IntegrationCardActions';

interface OwnProps {
    logo: string;
    isConnected: boolean;
    isDisabled: boolean;
    onInstall: () => void;
    onSwitch: () => void;
    condition?: boolean;
}

export default function IntegrationCardHeader({
    logo,
    isConnected,
    isDisabled,
    onInstall,
    onSwitch,
    condition,
}: OwnProps) {
    return (
        <Grid container item xs={12}>
            <Grid container item xs={7}>
                <img src={logo} style={{ maxHeight: 60 }} alt="logo" />
            </Grid>
            <Grid
                container
                direction={'row'}
                justifyContent={'space-between'}
                columnGap={1}
                alignItems={'flex-start'}
                item
                xs={5}>
                <Divider orientation={'vertical'} variant={'middle'} />
                <IntegrationCardActions
                    isConnected={isConnected}
                    isDisabled={isDisabled}
                    onInstall={onInstall}
                    onSwitch={onSwitch}
                    condition={condition}
                />
            </Grid>
        </Grid>
    );
}
