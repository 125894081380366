import { FormConfig } from '../../../../design/Forms/interfaces';
import { usaStates } from '../../../../utils/vocabulary';
import {
    composeValidators,
    maxLength,
    OnlyDigitsAndSpecialChars,
} from '../../../../utils/validation';

export const formBillingConfig: FormConfig[] = [
    {
        formField: {
            scheme: 'half',
            name: `billingAddress.address1`,
            type: 'input',
            label: 'Billing Address Line 1',
            validation: composeValidators(maxLength(256)),
        },
        renderProps: {
            placeholder: 'Street',
            isDisabled: false,
            size: 'small',
        },
    },
    {
        formField: {
            scheme: 'half',
            name: `billingAddress.address2`,
            type: 'input',
            label: 'Billing Address Line 2',
            validation: composeValidators(maxLength(256)),
        },
        renderProps: {
            placeholder: 'Street',
            isDisabled: false,
            size: 'small',
        },
    },
    {
        formField: {
            scheme: 'half',
            name: `billingAddress.city`,
            type: 'input',
            label: 'City',
            validation: composeValidators(maxLength(256)),
        },
        renderProps: {
            placeholder: 'City',
            isDisabled: false,
            size: 'small',
        },
    },
    {
        formField: {
            scheme: 'half',
            name: `billingAddress.state`,
            type: 'select',
            label: 'State',
        },
        renderProps: {
            placeholder: 'State',
            isDisabled: false,
            size: 'small',
            options: usaStates,
        },
    },
    {
        formField: {
            scheme: 'half',
            name: `billingAddress.zipCode`,
            type: 'input',
            label: 'Zip Code/Postal Code',
            validation: composeValidators(
                OnlyDigitsAndSpecialChars(
                    'Only digits and special characters are allowed',
                ),
                maxLength(256),
            ),
        },
        renderProps: {
            placeholder: '00000',
            isDisabled: false,
            size: 'small',
        },
    },
];
