import { useEffect } from 'react';
import { Settings } from './useSettingsFromStorage';

interface OwnProps {
    settings: Settings;
    saveSettings: (settings: Settings) => void;
    sortKey: string;
    sortOrder: 'asc' | 'desc';
    currentPage: number;
    itemsPerPage: number;
}
export function useSaveSettings({
    settings,
    saveSettings,
    sortKey,
    sortOrder,
    currentPage,
    itemsPerPage,
}: OwnProps) {
    useEffect(() => {
        saveSettings({
            ...settings,
            sort: `${sortKey}`,
            sortDirection: sortOrder,
        });
    }, [sortKey, sortOrder]);

    useEffect(() => {
        saveSettings({
            ...settings,
            page: currentPage,
        });
    }, [currentPage]);

    useEffect(() => {
        saveSettings({
            ...settings,
            size: itemsPerPage,
            page: 1,
        });
    }, [itemsPerPage]);
}
