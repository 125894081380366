import React, { useState } from 'react';
import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import clsx from 'clsx';
import InfoTooltip from '../Tooltip/InfoTooltip';

interface CardCheckbox {
    label: string;
    checked?: boolean;
    onChange: () => void;
    disabled?: boolean;
}
export interface PromptCardProps {
    type: Severity;
    title: string;
    description: string;
    date?: string | null;
    cardCheckbox?: CardCheckbox;
    checkboxLabel?: string;
    tooltip?: string;
}

export type Severity = 'success' | 'error' | 'warning' | 'info' | 'default';
export default function PromptCard({
    type,
    title,
    date,
    description,
    cardCheckbox,
    tooltip,
}: PromptCardProps) {
    const { classes } = useStyles();
    const [checked, setChecked] = useState(false);

    return (
        <div
            className={clsx(
                classes.container,
                type === 'success' && classes.typeSuccess,
                type === 'error' && classes.typeError,
                type === 'warning' && classes.typeWarning,
                type === 'info' && classes.typeInfo,
                type === 'default' && classes.typeDefault,
            )}>
            <Box display={'flex'} gap={1}>
                <Typography variant={'font14'} fontWeight={'bold'}>
                    {title}
                </Typography>
                {tooltip && <InfoTooltip text={tooltip} />}
            </Box>
            <Typography
                variant={'font14'}
                fontWeight={'medium'}
                color="text.light">
                {date}
            </Typography>
            <Typography variant={'font12'} color="text.light">
                {description}
            </Typography>
            {cardCheckbox && (
                <FormControlLabel
                    id={cardCheckbox.label}
                    className={classes.checkboxLabel}
                    onChange={() => {
                        setChecked(true);
                        cardCheckbox.onChange();
                        setTimeout(() => setChecked(false), 800);
                    }}
                    checked={cardCheckbox.checked || checked}
                    disabled={cardCheckbox.disabled}
                    control={<Checkbox />}
                    label={cardCheckbox.label}
                />
            )}
        </div>
    );
}
const useStyles = makeStyles()((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        padding: theme.spacing(0, 2),
        gap: theme.spacing(1),
    },
    checkboxLabel: {
        display: 'flex',
        alignItems: 'center',
        '& .MuiFormControlLabel-label ': {
            color: theme.palette.neutral.neutral4,
        },
        '& .MuiCheckbox-root': {
            padding: theme.spacing(0, 2, 0, 0),
        },
    },
    typeSuccess: {
        borderLeft: `2px solid ${theme.palette.brandLimeGreen}`,
    },
    typeError: {
        borderLeft: `2px solid ${theme.palette.statusRedTint}`,
    },
    typeWarning: {
        borderLeft: `2px solid ${theme.palette.statusYellow}`,
    },
    typeInfo: {
        borderLeft: `2px solid ${theme.palette.brandLightTurq}`,
    },
    typeDefault: {
        borderLeft: `2px solid ${theme.palette.neutral.neutral5}`,
    },
}));
