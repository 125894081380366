import React from 'react';
import { Box, TextField } from '@mui/material';
import ActionButtons from './ActionButtons';
import { NoteDetailsProps } from '../Chat/NoteDetails';

interface OwnProps {
    handleEdit: (note: NoteDetailsProps) => void;
    handleCancel: () => void;
    setEditedNote: React.Dispatch<React.SetStateAction<NoteDetailsProps>>;
    editedNote: NoteDetailsProps;
}

export default function EditNoteField({
    handleEdit,
    handleCancel,
    setEditedNote,
    editedNote,
}: OwnProps) {
    function onChange(note: string) {
        setEditedNote((prevState) => {
            return {
                ...prevState,
                content: note,
            };
        });
    }
    return (
        <Box
            width={'100%'}
            display={'flex'}
            flexDirection={'column'}
            alignItems={'flex-end'}>
            <TextField
                multiline
                rows={3}
                fullWidth
                value={editedNote.content}
                onChange={(e) => onChange(e.target.value)}
            />
            <ActionButtons
                submitButtonLabel={'Submit'}
                handleAddNote={() => {
                    handleEdit(editedNote);
                    handleCancel();
                }}
                handleCancel={handleCancel}
            />
        </Box>
    );
}
