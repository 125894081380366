import CopiedReadField, { ReadOnlyField } from './CopeiedReadField';
import React from 'react';

interface OwnProps {
    items: ReadOnlyField[];
}
export default function CopiedReadFieldList({ items }: OwnProps) {
    return (
        <>
            {items.map(({ title, value, hasCopied, text }, index) => (
                <CopiedReadField
                    key={index}
                    title={title}
                    value={value}
                    hasCopied={hasCopied}
                    text={text}
                />
            ))}
        </>
    );
}
