import { Grid, Typography } from '@mui/material';
import theme from '../../styles/theme';
import React, { ReactNode } from 'react';

interface ExpandComponentColumnProps {
    title: string;
    text?: string;
    secondaryText?: string;
    isHidden: boolean;
    children?: ReactNode;
}
export default function ExpandComponentColumn({
    title,
    text,
    secondaryText,
    isHidden,
    children,
}: ExpandComponentColumnProps) {
    if (isHidden) {
        return null;
    }
    return (
        <Grid
            display={'flex'}
            flexDirection={'column'}
            p={2}
            sx={{
                borderRight: `solid 2px ${theme.palette.neutralWhite}`,
                '& :last-child': {
                    borderRight: 'none',
                },
            }}>
            <Typography
                variant={'font12'}
                fontWeight={'medium'}
                color={'text.secondary'}>
                {title}
            </Typography>
            <Typography variant={'font14'} color={'text.neutral6'}>
                {text}
            </Typography>
            <Typography variant={'font14'} color={'text.neutral6'}>
                {secondaryText}
            </Typography>
            {children}
        </Grid>
    );
}
