import { HTTP_METHOD } from '../auth/http';
import { ENDPOINTS } from '../endpoints';
import { OAKENApi } from '../OAKENApi';
import { DOCUMENTS } from '../tags';
import { getListProvidesTags } from '../generic';
import { GUEST_KEY } from '../../constant/localStorageKeys';

export const guestAPI = OAKENApi.injectEndpoints({
    endpoints: (builder) => ({
        createGuest: builder.mutation({
            query: (payload) => {
                return {
                    url:
                        process.env.REACT_APP_USER_API_HOST +
                        ENDPOINTS.guest.root,
                    method: HTTP_METHOD.POST,
                    body: payload,
                };
            },
        }),
        setPassword: builder.mutation({
            query: (payload) => {
                return {
                    url:
                        process.env.REACT_APP_USER_API_HOST +
                        ENDPOINTS.guest.setPassword,
                    method: HTTP_METHOD.POST,
                    body: payload,
                };
            },
        }),
        loginGuest: builder.mutation({
            query: (payload) => {
                return {
                    url:
                        process.env.REACT_APP_USER_API_HOST +
                        ENDPOINTS.guest.login,
                    method: HTTP_METHOD.POST,
                    body: payload,
                };
            },
        }),
        guestsDocument: builder.query({
            query: ({ id, ...params }) => {
                const token = localStorage.getItem(GUEST_KEY) || null;
                return {
                    url:
                        process.env.REACT_APP_DOCUMENT_API_HOST +
                        ENDPOINTS.sharedData.guestDocuments +
                        `/${id}`,
                    method: HTTP_METHOD.GET,
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    params,
                };
            },
            providesTags: (result) =>
                getListProvidesTags(result?.data, DOCUMENTS.DOCUMENTS),
        }),
    }),
});

export const {
    useLazyGuestsDocumentQuery,
    useCreateGuestMutation,
    useSetPasswordMutation,
    useLoginGuestMutation,
} = guestAPI;
