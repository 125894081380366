import { HTTP_METHOD } from '../auth/http';
import { ENDPOINTS } from '../endpoints';
import { OAKENApi } from '../OAKENApi';
import { getListProvidesTags } from '../generic';
import { DOCUMENTS } from '../tags';

import { DocumentTable } from '../../features/ViewLandownersDetails/LandPartnerDocuments/useDocumentsTable';

export const landPartnersDocumentsAPI = OAKENApi.injectEndpoints({
    endpoints: (builder) => ({
        getLandPartnerDocuments: builder.query({
            query: (params) => {
                return {
                    url:
                        process.env.REACT_APP_USER_API_HOST +
                        ENDPOINTS.users.documents +
                        `/${params.landPartnerId}`,
                    method: HTTP_METHOD.GET,
                    params,
                };
            },
            providesTags: (result) =>
                getListProvidesTags(result?.data, DOCUMENTS.DOCUMENTS),
        }),
        addLandPartnerDocument: builder.mutation({
            query: (payload) => {
                const formData = new FormData();
                formData.append('document', payload.document[0]);
                return {
                    url:
                        process.env.REACT_APP_USER_API_HOST +
                        ENDPOINTS.users.documents +
                        `/${payload.landPartnerId}`,
                    method: HTTP_METHOD.POST,
                    body: formData,
                };
            },
            invalidatesTags: [DOCUMENTS.DOCUMENTS],
        }),
        documentsByYearGroup: builder.query({
            query: ({ landPartnerId, ...params }) => {
                return {
                    url:
                        process.env.REACT_APP_USER_API_HOST +
                        ENDPOINTS.landpartners.documentByYear +
                        `/${landPartnerId}`,
                    method: HTTP_METHOD.GET,
                    params,
                };
            },
            providesTags: (result) =>
                getListProvidesTags(result?.data, DOCUMENTS.DOCUMENTS),
        }),
        documentsListByLPID: builder.query({
            query: ({ landPartnerId, ...params }) => {
                return {
                    url:
                        process.env.REACT_APP_USER_API_HOST +
                        ENDPOINTS.users.documentsList +
                        `/${landPartnerId}`,
                    method: HTTP_METHOD.GET,
                    params,
                };
            },
            transformResponse: (
                data: DocumentTable[],
                meta,
            ): { data: DocumentTable[]; totalCount: number } => {
                const xTotalCount =
                    meta?.response?.headers.get('X-Total-Count');
                return { data, totalCount: xTotalCount ? +xTotalCount : 0 };
            },
            providesTags: (result) =>
                getListProvidesTags(result?.data, DOCUMENTS.GROUPS),
        }),
    }),
});
export const {
    useLazyDocumentsListByLPIDQuery,
    useDocumentsListByLPIDQuery,
    useDocumentsByYearGroupQuery,
    useLazyDocumentsByYearGroupQuery,
    useGetLandPartnerDocumentsQuery,
    useAddLandPartnerDocumentMutation,
} = landPartnersDocumentsAPI;
