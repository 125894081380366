import React, { useState } from 'react';
import { Box } from '@mui/material';
import TwoColumnLayout from '../../layouts/TwoColumnLayout';
import Stepper from '../../design/Stepper/Stepper';
import { useLocation, useParams } from 'react-router-dom';
import ContactForm from './ContactForm';

export default function ContactWizard() {
    const [pageNumber, setPageNumber] = useState(0);
    const { id } = useParams<{ id: string }>();
    const [isValid, setIsValid] = useState(false);
    const isEdit = !!id;
    const { state } = useLocation();

    return (
        <Box m={1} bgcolor={'white'}>
            <TwoColumnLayout
                header={
                    isEdit
                        ? headerTitleUpdate[pageNumber]
                        : headerTitleCreate[pageNumber]
                }
                type={'third'}
                leftComponent={
                    <Box p={4}>
                        <Stepper
                            key={'land-partner'}
                            activeStep={pageNumber}
                            isNavigable={isValid && isEdit}
                            setActiveStep={setPageNumber}
                            steps={
                                isEdit
                                    ? contactsStepsUpdate
                                    : contactsStepsCreate
                            }
                        />
                    </Box>
                }
                rightComponent={
                    <ContactForm
                        treeData={state}
                        setIsValid={setIsValid}
                        pageNumber={pageNumber}
                        setPageNumber={setPageNumber}
                    />
                }
            />
        </Box>
    );
}

const headerTitleCreate = [
    'Relationship Tree',
    'Contact Details',
    'Addresses',
    'Social Media + Agridata',
    'Summary',
];
const headerTitleUpdate = [
    'Relationship Tree',
    'Contact Details',
    'Addresses',
    'Social Media + Agridata',
    'Notes',
    'Summary',
];
const contactsStepsCreate = [
    { name: 'Step 1: Relationship Tree', description: '' },
    { name: 'Step 2: Contact Details', description: '' },
    { name: 'Step 3: Addresses', description: '' },
    { name: 'Step 4: Social Media + Agridata', description: '' },
    { name: 'Step 5: Review', description: '' },
];

const contactsStepsUpdate = [
    { name: 'Step 1: Relationship Tree', description: '' },
    { name: 'Step 2: Contact Details', description: '' },
    { name: 'Step 3: Addresses', description: '' },
    { name: 'Step 4: Social Media + Agridata', description: '' },
    { name: 'Step 5: Notes', description: '' },
    { name: 'Step 6: Review', description: '' },
];
