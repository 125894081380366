import React, { useEffect, useMemo } from 'react';
import FormPage from '../../reusable/FormUI/FormPage';
import {
    formUploadConfig,
    Option,
} from './WizardPageConfigs/leaseUploadConfig';
import {
    composeValidators,
    isNumber,
    notMoreThan,
    required,
} from '../../utils/validation';
import { useForm, useFormState } from 'react-final-form';
import { FormConfig } from '../../design/Forms/interfaces';
import { Box, Grid } from '@mui/material';
import { useGetLandPartnersShortQuery } from '../../api/landpartners/landPartnersAPI';
import Loader from '../../design/BaseLoader';
import { useGetListSuborganizationsQuery } from '../../api/users/organizationsAPI';
import { useLocation, useParams } from 'react-router-dom';
import { landPartnersConfig } from './WizardPageConfigs/landPartnersConfig';
import { paymentFrequencyConfig } from './WizardPageConfigs/paymentFrequencyConfig';
import { Lease } from '../../pages/LandPortfolio/TableConfig';
import { useIsMount } from '../../hooks/useIsMount';
import { leaseBonusPaymentConfig } from './WizardPageConfigs/leaseBonusPaymentConfig';
import { createArrayOfNumbers } from '../../utils/dataConvertor';
import LeaseAgentForm from './LeaseAgentForm';
import { formLeaseDurationConfig } from './WizardPageConfigs/leaseDurationConfig';

interface OwnProps {
    data: Option;
}

export default function LeaseUploadFormPageWrapper({ data }: OwnProps) {
    const isMount = useIsMount();
    const { values } = useFormState();
    const form = useForm();
    const { id } = useParams();
    const { state } = useLocation();
    const isEdit = !!id;
    const { isCopy } = state;
    const { isLoading, data: initData } = useGetLandPartnersShortQuery({
        sort: `name,asc`,
        page: 0,
        size: 1000,
    });
    const isAutoRenewal =
        values?.autoRenewal && values?.autoRenewal.toString() === 'true';

    const { data: orgData, isLoading: isOrgDataLoading } =
        useGetListSuborganizationsQuery({
            id: data?.id,
            sort: 'name,ASC',
            page: 0,
            size: 1000,
        });

    const landPartners = useMemo(() => {
        return initData?.data || [];
    }, [initData]);

    useEffect(() => {
        if (!isAutoRenewal) {
            form.change('notificationDeadline', null);
        }
    }, [isAutoRenewal]);

    useEffect(() => {
        if (isMount) return;
        form.change(`paymentDistributions.landowners`, {});
        values?.landowners &&
            values?.landowners?.map((i: Option) =>
                form.change(
                    `paymentDistributions.landowners.${i?.id}`,
                    (100 / values.landowners.length).toFixed(2),
                ),
            );
    }, [values?.landowners]);

    useEffect(() => {
        if (isMount) return;
        if (isEdit || isCopy) {
            form.change('paymentSchedule', []);
            return;
        }
        form.change('paymentSchedule', null);
    }, [values?.numberOfPaymentsPerYear, isEdit, isCopy]);

    useEffect(() => {
        if (isMount) return;
        form.change(`paymentDistributions.paymentDates`, {});
        createArrayOfNumbers(values.numberOfPaymentsPerYear).map((i: number) =>
            form.change(
                `paymentDistributions.paymentDates.paymentDate${i}`,
                (100 / values.numberOfPaymentsPerYear).toFixed(2),
            ),
        );
    }, [values?.numberOfPaymentsPerYear]);

    if (isLoading || isOrgDataLoading) {
        return (
            <Grid
                container
                sx={{ height: '100vh' }}
                alignItems={'center'}
                justifyContent={'center'}
                flexDirection={'column'}>
                <Loader />
            </Grid>
        );
    }

    const paymentConfig = (number: number): FormConfig[] =>
        createArrayOfNumbers(number).map((i, index) => ({
            formField: {
                scheme: 'quarter',
                name:
                    isEdit || isCopy
                        ? `paymentSchedule${index}`
                        : `paymentSchedule.paymentDate${i}`,
                type: 'datepicker',
                label: `Payment Date № ${i}`,
                isRequired: true,
                validation: composeValidators(required),
            },
            renderProps: {
                placeholder: `Enter ${i} Payment Date`,
                isDisabled: false,
                size: 'small',
                isDateWithoutYear: true,
                views: ['day', 'month'],
                format: 'MMM dd',
            },
        }));

    const landownersDistrConfig = (landowners: Option[]): FormConfig[] =>
        landowners.map((landowner) => ({
            formField: {
                scheme: 'quarter',
                name: `paymentDistributions.landowners.${landowner?.id}`,
                type: 'input',
                label: landowner?.name,
                isRequired: true,
                validation: composeValidators(
                    required,
                    isNumber,
                    notMoreThan(100),
                    totalSumLandowners,
                ),
            },
            renderProps: {
                isDisabled: false,
                size: 'small',
                postfix: '%',
            },
        }));

    const paymentDistributionConfig = (number: number): FormConfig[] => {
        return createArrayOfNumbers(number).map((i) => ({
            formField: {
                scheme: 'quarter',
                name: `paymentDistributions.paymentDates.paymentDate${i}`,
                type: 'input',
                label: `Payment Date № ${i}`,
                isRequired: true,
                validation: composeValidators(
                    required,
                    isNumber,
                    notMoreThan(100),
                    totalSumPaymentsDate,
                ),
            },
            renderProps: {
                placeholder: `Enter ${i} Payment Date`,
                isDisabled: false,
                size: 'small',
                postfix: '%',
            },
        }));
    };

    return (
        <Box display="flex" flexDirection="column" gap={2}>
            <LeaseAgentForm landPartners={landPartners} />
            <FormPage
                title={'Document Upload + Identification'}
                formConfig={formUploadConfig({
                    organization: orgData?.data || [],
                    isEdit,
                })}
            />
            <FormPage
                title={'Lease Duration'}
                formConfig={formLeaseDurationConfig({
                    isAutoRenewal,
                })}
            />
            <FormPage
                title={'Land Partners'}
                formConfig={landPartnersConfig(landPartners)}
            />
            {values.landowners && values.landowners.length > 1 && (
                <FormPage
                    title={
                        'Are payments equally distributed amongst the Land Partners?'
                    }
                    formConfig={landownersDistrConfig(values.landowners)}
                />
            )}
            <FormPage
                title={'Payment + Frequency Dates'}
                formConfig={paymentFrequencyConfig}
            />
            {values.numberOfPaymentsPerYear && (
                <FormPage
                    formConfig={paymentConfig(values.numberOfPaymentsPerYear)}
                />
            )}
            {values.numberOfPaymentsPerYear &&
                +values.numberOfPaymentsPerYear > 1 && (
                    <FormPage
                        title={
                            'Are payments equally split on the payment dates?'
                        }
                        formConfig={paymentDistributionConfig(
                            values.numberOfPaymentsPerYear,
                        )}
                    />
                )}
            {values?.type === 'FLEX' && (
                <FormPage
                    title={'Bonus Payment Date*'}
                    formConfig={leaseBonusPaymentConfig}
                />
            )}
        </Box>
    );
}

function totalSumPaymentsDate(values: Lease, allValues: Lease) {
    let totalSum = 0;
    for (const key in allValues?.paymentDistributions?.paymentDates) {
        if (key.startsWith('paymentDate')) {
            totalSum += Number(
                allValues?.paymentDistributions?.paymentDates[key],
            );
        }
    }
    return Math.round(totalSum) > 100 || Math.round(totalSum) < 100
        ? 'Total sum should be 100'
        : undefined;
}

function totalSumLandowners(values: Lease, allValues: Lease) {
    let totalSum = 0;
    Object.values(allValues?.paymentDistributions?.landowners || {}).forEach(
        (value) => {
            totalSum += Number(value);
        },
    );
    return Math.round(totalSum) > 100 || Math.round(totalSum) < 100
        ? 'Total sum should be 100'
        : undefined;
}
