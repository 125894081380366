import React, {
    Dispatch,
    SetStateAction,
    useEffect,
    useMemo,
    useState,
} from 'react';

import { useGetOrganizationsQuery } from '../../../../api/users/organizationsAPI';
import {
    useAddUserMutation,
    useLazyGetOneUserQuery,
    useUpdateUserMutation,
} from '../../../../api/users/usersAPI';
import FormModal from '../../../../reusable/Modal/FormModal';
import { User } from '../AllUsers/UsersList';
import { FormConfig } from '../../../../design/Forms/interfaces';
import {
    composeValidators,
    isEmail,
    maxLength,
    required,
} from '../../../../utils/validation';
import customToastWithAlert from '../../../../design/CustomToastWithAlert';
import { useGetRolesByPOSTMutation } from '../../../../api/users/rolesAPI';
import { Role } from '../../Roles/AllRoles/RoleList';

export interface ManageModal {
    isShowing: boolean;
    setIsShowing: Dispatch<SetStateAction<boolean>>;
}

interface OwnProps extends ManageModal {
    id?: string;
}

export default function AddUser({ id, isShowing, setIsShowing }: OwnProps) {
    const [selectedOrgId, setSelectedOrgId] = useState('');
    const [addUser] = useAddUserMutation();
    const [updateUser, { isLoading: updating, reset, data: updatedData }] =
        useUpdateUserMutation();
    const [
        getItems,
        { isFetching: fetchingOne, isLoading: loadingOne, data: initData },
    ] = useLazyGetOneUserQuery();

    const { data: orgData } = useGetOrganizationsQuery({
        sort: 'name,ASC',
        page: 0,
        size: 1000,
    });

    const [getRolesByPOST] = useGetRolesByPOSTMutation();

    const [orgRoles, setOrgRoles] = useState<Role[]>([]);

    useEffect(() => {
        if (!selectedOrgId) {
            setOrgRoles([]);
        } else {
            getRolesByPOST({
                organizationId: selectedOrgId,
            })
                .unwrap()
                .then((data) => {
                    setOrgRoles(data);
                })
                .catch(() => {
                    setOrgRoles([]);
                });
        }
    }, [selectedOrgId]);

    useEffect(() => {
        reset();
        id && getItems(id);
    }, [id]);

    const data = useMemo(() => {
        return updatedData || initData;
    }, [updatedData, initData]);

    useEffect(() => {
        if (data?.organization?.id) {
            setSelectedOrgId(data?.organization?.id);
        }
    }, [data]);

    function handleSubmit(value: User) {
        if (id) {
            updateUser({
                organizationId: data.organization?.id,
                roleId: data.role?.id,
                ...value,
            })
                .unwrap()
                .then(() => {
                    return (
                        customToastWithAlert({
                            type: 'success',
                            message: 'User updated successfully',
                        }),
                        setIsShowing(false)
                    );
                })
                .catch((error) => {
                    return customToastWithAlert({
                        type: 'error',
                        message:
                            error?.data?.description || 'Something went wrong',
                    });
                });
            return;
        }
        addUser(value)
            .unwrap()
            .then(() => {
                return (
                    customToastWithAlert({
                        type: 'success',
                        message: 'User added successfully',
                    }),
                    setIsShowing(false)
                );
            })
            .catch((error) => {
                return customToastWithAlert({
                    type: 'error',
                    message: error?.data?.description || 'Something went wrong',
                });
            });
    }

    const formConfig: FormConfig[] = [
        {
            formField: {
                scheme: 'half',
                name: 'firstName',
                type: 'input',
                label: 'First Name',
                isRequired: true,
                validation: composeValidators(required, maxLength(256)),
            },
            renderProps: {
                placeholder: '',
                isDisabled: false,
                size: 'small',
            },
        },
        {
            formField: {
                scheme: 'half',
                name: 'lastName',
                type: 'input',
                label: 'Last Name',
                isRequired: true,
                validation: composeValidators(required, maxLength(256)),
            },
            renderProps: {
                placeholder: '',
                isDisabled: false,
                size: 'small',
            },
        },
        {
            formField: {
                scheme: 'half',
                name: id ? 'organizationName' : 'organizationId',
                type: id ? 'input' : 'select',
                label: 'Organization',
                isRequired: !id,
                validation: !id && composeValidators(required),
                onChange: (e: string) => setSelectedOrgId(e),
            },
            renderProps: {
                placeholder: 'Organization',
                isDisabled: !!id,
                size: 'small',
                options: orgData?.data || [],
            },
        },
        {
            formField: {
                scheme: 'half',
                name: 'roleId',
                type: 'select',
                label: 'Role Type',
                isRequired: true,
                validation: composeValidators(required),
            },
            renderProps: {
                placeholder: 'Role Type',
                isDisabled: false,
                size: 'small',
                options: orgRoles,
            },
        },
        {
            formField: {
                scheme: 'half',
                name: 'primaryEmail',
                type: 'input',
                label: 'Primary Email Address',
                isRequired: true,
                validation: composeValidators(
                    required,
                    maxLength(256),
                    isEmail,
                ),
            },
            renderProps: {
                placeholder: 'Enter Email Address',
                isDisabled: !!id,
                size: 'small',
            },
        },
        {
            formField: {
                scheme: 'half',
                name: 'secondaryEmail',
                type: 'input',
                label: 'Secondary Email Address',
                validation: composeValidators(maxLength(256), isEmail),
            },
            renderProps: {
                placeholder: 'Enter Email Address',
                isDisabled: false,
                size: 'small',
            },
        },
        {
            formField: {
                scheme: 'half',
                name: 'phoneNumber',
                type: 'input',
                label: 'Phone Number',
                isRequired: true,
                validation: composeValidators(required),
            },
            renderProps: {
                placeholder: '(000)',
                isDisabled: false,
                size: 'small',
            },
        },
    ];

    return (
        <FormModal
            header={id ? 'Edit user' : 'Add user'}
            isShowing={isShowing}
            formConfig={formConfig}
            handleSubmit={handleSubmit}
            submitButtonText={id ? 'Update user' : 'Send Invite'}
            onClose={() => setIsShowing(false)}
            initialValues={
                id
                    ? {
                          organizationName: data?.organization?.name,
                          roleId: data?.role?.id,
                          ...data,
                      }
                    : data
            }
            isLoading={fetchingOne || loadingOne || updating}
        />
    );
}
