import React from 'react';
import TabPanelWithoutNavigation from '../../../design/TabsPanel/TabPanelWithoutNavigation';
import SharedLeasesPage from './Lease/SharedLeasesPage';
import SharedPaymentsPage from './Payment/SharedPaymentsPage';
import SharedDocumentsPage from './Document/SharedDocumentsPage';
import { Box } from '@mui/material';

const tabsConfig = (token: string) => [
    {
        label: 'Leases',
        content: <SharedLeasesPage token={token} />,
        value: 0,
    },
    {
        label: 'Payments',
        content: <SharedPaymentsPage token={token} />,
        value: 1,
    },
    {
        label: 'Documents',
        content: <SharedDocumentsPage token={token} />,
        value: 2,
    },
];

interface OwnProps {
    token: string;
}

export default function SharedDataAllTabs({ token }: OwnProps) {
    return (
        <Box sx={{ padding: '20px', paddingLeft: '5px' }} borderRadius={2}>
            <TabPanelWithoutNavigation tabItems={tabsConfig(token)} />
        </Box>
    );
}
