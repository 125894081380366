import React, { useEffect, useMemo } from 'react';
import GenericTable, { Table } from '../../design/GenericTable/GenericTable';
import { useSorting } from '../../design/GenericTable/useSorting';
import { usePagination } from '../../design/GenericTable/useBackendPagination';

import { Lease } from './TableConfig';
import {
    useGetLeaseListQuery,
    useLazyGetLeaseListQuery,
} from '../../api/leases/leaseAPI';
import ExpandComponent from './ExpandedComponent';
import { useSettingsFromStorage } from '../../hooks/useSettingsFromStorage';
import { useSaveSettings } from '../../hooks/useSaveSettings';
import useTableLeaseConfig from './useTableLeaseConfig';
import { ColumnConfig } from '../../api/users/customizeConfigAPI';

interface OwnProps {
    isSelected: (items: Lease) => boolean;
    toggleSelection: (items: Lease) => void;
    toggleSelectionAll: (items: Lease[]) => void;
    isSelectedAll: (items: Lease[]) => boolean;
    config: ColumnConfig[];
}

export default function ExpiredLeaseTable({
    isSelected,
    toggleSelection,
    toggleSelectionAll,
    isSelectedAll,
    config,
}: OwnProps) {
    const { settings, saveSettings } = useSettingsFromStorage('EXPIRED_LEASES');

    const { sort, page, sortDirection, size } = settings;

    const { sortKey, sortOrder, handleSort } = useSorting(
        sort || 'endDate',
        sortDirection || 'asc',
    );
    const {
        currentPage,
        itemsPerPage,
        handlePageChange,
        handleItemsPerPageChange,
    } = usePagination(page || 1, size || 25);

    useSaveSettings({
        settings,
        saveSettings,
        sortKey,
        sortOrder,
        currentPage,
        itemsPerPage,
    });

    const { data: initData } = useGetLeaseListQuery({
        sort: `${sortKey},${sortOrder}`,
        page: currentPage - 1,
        size: itemsPerPage,
        activeOnly: false,
        expiredOnly: true,
    });

    const [getItems, { data: sortedData }] = useLazyGetLeaseListQuery();

    useEffect(() => {
        getItems({
            sort: `${sortKey},${sortOrder}`,
            page: currentPage - 1,
            size: itemsPerPage,
            activeOnly: false,
            expiredOnly: true,
        });
    }, [sortKey, sortOrder, currentPage, itemsPerPage]);

    const data = useMemo(() => {
        return sortedData?.data || initData?.data || [];
    }, [sortedData, initData]);

    const totalCount = useMemo(() => {
        return sortedData?.totalCount || initData?.totalCount || 0;
    }, [sortedData, initData]);
    const { orderedColumns } = useTableLeaseConfig({
        handleSort,
        config,
        sortKey,
        sortOrder,
    });
    const tableConfig: Table<Lease> = {
        columns: orderedColumns,
        multiselect: true,
        expandable: true,
        ExpandComponent: ExpandComponent,
        handlePageChange: handlePageChange,
        handleItemsPerPageChange: handleItemsPerPageChange,
        isSelected: isSelected,
        toggleSelection: toggleSelection,
        toggleSelectionAll: toggleSelectionAll,
        isSelectedAll: isSelectedAll,
        // eslint-disable-next-line max-lines
        initialPage: page,
        initialPageSize: size,
    };
    return (
        <GenericTable
            data={data}
            totalCount={totalCount}
            tableConfig={tableConfig}
        />
    );
}
