import { Box } from '@mui/material';
import RestoreButton from './RestoreButton';
import DeleteButton from './DeleteButton';
import useManagePermission from '../../../hooks/useManagePermission';

interface OwnProps {
    id: string;
    onRestore: (id: string) => void;
    onDelete: (id: string) => void;
    isProcessing: boolean;
}

export default function ActionsColumn({
    id,
    onRestore,
    onDelete,
    isProcessing,
}: OwnProps) {
    const { checkPermission } = useManagePermission();

    if (!checkPermission('settings', 'modify')) {
        return null;
    }

    return (
        <Box display={'flex'} columnGap={1}>
            <RestoreButton
                onClick={() => onRestore(id)}
                isDisabled={isProcessing}
            />
            <DeleteButton
                onClick={() => onDelete(id)}
                isDisabled={isProcessing}
            />
        </Box>
    );
}
