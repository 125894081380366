import React from 'react';
import BasePieChart, {
    PieChartConfig,
} from '../../design/Charts/PieChart/BasePieChart';
import { Grid } from '@mui/material';
import LastOutreachTable from '../../pages/LandPartners/LastOutreachTable';
import theme from '../../styles/theme';

import { LeaseDashboard } from '../../pages/LandPortfolio/ManageLeasesPage';
import { useGetNotificationsSummaryQuery } from '../../api/landpartners/landPartnersAPI';
import { useOrgDashboardQuery } from '../../api/users/organizationsAPI';

export interface notificationsChart {
    totalCount: number;
    birthdayCount: number;
    paymentCount: number;
    taskCount: number;
    leaseRenewalCount: number;
}

export default function DashboardOverviewCharts() {
    const { data: orgDashboard } = useOrgDashboardQuery(null);
    const { data: notificationsChartData } = useGetNotificationsSummaryQuery(
        {},
    );

    return (
        <Grid
            container
            item
            flexWrap={'wrap'}
            columnGap={1}
            sx={{
                padding: theme.spacing(2),
                backgroundColor: theme.palette.neutralWhite,
                borderRadius: 2,
            }}>
            <Grid item>
                <BasePieChart
                    data={createLeaseRenewal(orgDashboard)}
                    config={pieChartConfigALlProfiles(orgDashboard)}
                />
            </Grid>
            <Grid item>
                <BasePieChart
                    data={totalUpEvents(notificationsChartData)}
                    config={pieChartConfigTotalUpEvents(notificationsChartData)}
                />
            </Grid>
            <Grid item xs={5}>
                <LastOutreachTable />
            </Grid>
        </Grid>
    );
}

function totalUpEvents(notificationsChartData: notificationsChart) {
    return [
        {
            name: 'Birthdays',
            value: notificationsChartData?.birthdayCount || 0,
        },
        {
            name: 'Lease Renewals',
            value: notificationsChartData?.leaseRenewalCount || 0,
        },
        {
            name: 'Payments',
            value: notificationsChartData?.paymentCount || 0,
        },
        {
            name: 'Tasks',
            value: notificationsChartData?.taskCount || 0,
        },
    ];
}

function createLeaseRenewal(dashboardData: LeaseDashboard) {
    return [
        { name: 'Land Partner', value: dashboardData?.landPartnersCount },
        { name: 'Grower', value: dashboardData?.growersCount },
    ];
}

function pieChartConfigTotalUpEvents(
    notificationsChartData: notificationsChart,
): PieChartConfig {
    return {
        summaryTitle: 'Events Total',
        summaryValue: `${notificationsChartData?.totalCount || 0}`,
        showLegend: true,
        dataKey: 'value',
        chartTitle: 'UPCOMING EVENTS',
        showTooltip: true,
    };
}

function pieChartConfigALlProfiles(orgData: LeaseDashboard): PieChartConfig {
    return {
        summaryTitle: 'Total Contacts',
        summaryValue: orgData?.totalCount,
        showLegend: true,
        dataKey: 'value',
        chartTitle: 'ALL PROFILES',
        showTooltip: true,
    };
}
