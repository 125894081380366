import { Box, Typography } from '@mui/material';
import UploadAttachments from './UploadAttachments';
import BasedTooltip from '../../design/BasedTooltip';
import React from 'react';

interface OwnProps {
    handleFileUpload: (event: React.ChangeEvent<HTMLInputElement>) => void;
    fileName: string;
    fileValidation: string;
}

export default function FileUpload({
    handleFileUpload,
    fileName,
    fileValidation,
}: OwnProps) {
    return (
        <Box
            display={'flex'}
            columnGap={2}
            alignItems={'center'}
            maxWidth={'50%'}>
            <UploadAttachments handleClick={handleFileUpload} />
            {fileName && (
                <Box width={'80%'}>
                    <BasedTooltip text={fileName}>
                        <Typography noWrap variant={'font12'} color="text.link">
                            {fileName}
                        </Typography>
                    </BasedTooltip>
                </Box>
            )}
            {fileValidation && (
                <Typography variant={'font12'} color="text.error">
                    {fileValidation}
                </Typography>
            )}
        </Box>
    );
}
