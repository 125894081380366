import React from 'react';
import TabPanelWithoutNavigation from '../../../design/TabsPanel/TabPanelWithoutNavigation';
import SharedLeasesPage from './Lease/SharedLeasesPage';
import SharedPaymentsPage from './Payment/SharedPaymentsPage';
import SharedDocumentsPage from './Document/SharedDocumentsPage';
import { Box } from '@mui/material';

const tabsConfig = () => [
    {
        label: 'Leases',
        content: <SharedLeasesPage />,
        value: 0,
    },
    {
        label: 'Payments',
        content: <SharedPaymentsPage />,
        value: 1,
    },
    {
        label: 'Documents',
        content: <SharedDocumentsPage />,
        value: 2,
    },
];

export default function SharedDataAllTabs() {
    return (
        <Box sx={{ padding: '20px', paddingLeft: '5px' }} borderRadius={2}>
            <TabPanelWithoutNavigation tabItems={tabsConfig()} />
        </Box>
    );
}
