import ManageSettingsTable, { LPSharingContact } from './ManageSettingsTable';
import { useSelection } from '../../../design/GenericTable/useSelection';
import { Box, Grid, Typography } from '@mui/material';
import ActionButton, {
    ActionMenuItem,
} from '../../../design/Buttons/ActionButton';
import React, { useState } from 'react';
import customToastWithAlert from '../../../design/CustomToastWithAlert';
import useHandleSubmitRTK from '../../../hooks/useHandleSubmit';
import {
    useRevokeShareDataAccessMutation,
    useShareLandPartnerDataMutation,
} from '../../../api/contacts/contactsAPI';
import Loader from '../../../design/BaseLoader';
import useBreadcrumbs from '../../../hooks/useBreadcrumbs';
import Breadcrumbs from '../../../design/Breadcrumbs/Breadcrumbs';

export interface ShareDataRequest {
    shareDataRequests: {
        id: string;
        contactId?: string;
        landPartnerId: string;
        visibilityLevel?: string;
        period?: string;
    }[];
}

const initialShareDataRequest: ShareDataRequest = {
    shareDataRequests: [],
};

export default function ManageLandPartnerSharing() {
    const {
        selected: selectedRows,
        isSelected,
        toggleSelection,
        toggleSelectionAll,
        isSelectedAll,
        clearSelection,
    } = useSelection<LPSharingContact>();

    const [shareDataRequest, setShareDataRequest] = useState<ShareDataRequest>(
        initialShareDataRequest,
    );

    const { handleSubmit: submitShareData, isLoading: isShareDataLoading } =
        useHandleSubmitRTK({
            useRtkHook: useShareLandPartnerDataMutation,
            successMessage: 'Data shared successfully',
        });

    const {
        handleSubmit: submitRevokeAccess,
        isLoading: isRevokeAccessLoading,
    } = useHandleSubmitRTK({
        useRtkHook: useRevokeShareDataAccessMutation,
        successMessage: 'Data access revoked successfully',
    });

    const ids = selectedRows.map((i) => i.id);

    const clearContactsSelection = () => {
        setShareDataRequest(initialShareDataRequest);
        clearSelection();
    };

    const actionButtonItems: ActionMenuItem[] = [
        {
            label: 'Send Invite',
            onClick: () => {
                const isShareDataRequestValid =
                    shareDataRequest.shareDataRequests.every(
                        (request) =>
                            request.visibilityLevel &&
                            request.period &&
                            request.contactId &&
                            request.landPartnerId,
                    );
                if (!isShareDataRequestValid) {
                    customToastWithAlert({
                        type: 'error',
                        message:
                            'Please enter the Sharing Type and Duration for all selected Contacts',
                    });
                    return;
                }

                submitShareData(shareDataRequest);

                clearContactsSelection();
            },
        },
        {
            label: 'Revoke Access',
            onClick: () => {
                const revokeAccessRequests =
                    shareDataRequest.shareDataRequests.map(
                        ({ contactId, landPartnerId }) => ({
                            contactId,
                            landPartnerId,
                        }),
                    );
                submitRevokeAccess({
                    revokeAccessRequests,
                });

                clearContactsSelection();
            },
        },
    ];
    const routes = {
        'generalManagerSettings/landPartnerSharing/': 'Sharing Settings',
    };
    const breadcrumbs = useBreadcrumbs({
        routes,
        homePathName: 'Settings',
        homePath: '/generalManagerSettings',
    });
    if (isShareDataLoading || isRevokeAccessLoading) {
        return (
            <Grid
                container
                alignItems={'center'}
                justifyContent={'center'}
                flexDirection={'column'}>
                <Loader />
            </Grid>
        );
    }

    return (
        <div>
            <Box pl={4} pb={3}>
                <Breadcrumbs collapsed={false} links={breadcrumbs} />
                <Box
                    display={'flex'}
                    flexDirection={'column'}
                    rowGap={1}
                    pl={4}>
                    <Typography variant={'font20'} fontWeight={'medium'}>
                        Sharing Settings
                    </Typography>
                    <Typography variant={'font16'}>
                        When you are ready to share information beyond your
                        organization these settings provide read-only access to
                        your dashboard. Whether you&apos;re sharing with a
                        partner, client, or stakeholder, you can customize what
                        they see, for how long, and how they access it. Use the
                        check box to select sharing options or update an
                        existing share.
                    </Typography>
                </Box>
            </Box>
            <Grid
                container
                justifyContent={'flex-end'}
                width={'100%'}
                sx={{
                    padding: 2,
                }}>
                <ActionButton
                    disabled={!ids?.length}
                    label={'Actions'}
                    items={actionButtonItems}
                />
            </Grid>
            <ManageSettingsTable
                shareDataRequest={shareDataRequest}
                setShareDataRequest={setShareDataRequest}
                selectedIds={ids}
                isSelected={isSelected}
                toggleSelection={toggleSelection}
                toggleSelectionAll={toggleSelectionAll}
                isSelectedAll={isSelectedAll}
            />
        </div>
    );
}
