import React, { ReactNode } from 'react';
import { makeStyles } from 'tss-react/mui';

interface OwnProps {
    children: ReactNode;
    isHidden?: boolean;
}
export default function DashboardWrapper({ children, isHidden }: OwnProps) {
    const { classes } = useStyles();
    if (isHidden) return null;
    return <div className={classes.containerWrapper}>{children}</div>;
}
const useStyles = makeStyles()((theme) => ({
    containerWrapper: {
        width: '100%',
        display: 'flex',
        columnGap: theme.spacing(3),
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2, 3),
        backgroundColor: theme.palette.neutralWhite,
        borderRadius: 16,
    },
}));
