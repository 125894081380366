import { HTTP_METHOD } from '../auth/http';
import { ENDPOINTS } from '../endpoints';
import { OAKENApi } from '../OAKENApi';
import { GROWERS } from '../tags';
import { LandPartnerTableView } from '../../pages/LandPartners/LandPartnersTable';

export const growersAPI = OAKENApi.injectEndpoints({
    endpoints: (builder) => ({
        getGrowers: builder.query({
            query: (params) => ({
                url:
                    process.env.REACT_APP_USER_API_HOST +
                    ENDPOINTS.growers.root,
                method: HTTP_METHOD.GET,
                params,
            }),
            transformResponse: (
                data: LandPartnerTableView[],
                meta,
            ): { data: LandPartnerTableView[]; totalCount: number } => {
                const xTotalCount =
                    meta?.response?.headers.get('X-Total-Count');
                return { data, totalCount: xTotalCount ? +xTotalCount : 0 };
            },
            providesTags: [GROWERS.GROWERS],
        }),
        getSummary: builder.query({
            query: () => ({
                url:
                    process.env.REACT_APP_USER_API_HOST +
                    ENDPOINTS.growers.summary,
                method: HTTP_METHOD.GET,
            }),
            providesTags: [GROWERS.GROWERS],
        }),
        downloadBulkGrower: builder.query({
            query: (documentIds) => {
                const baseUrl =
                    process.env.REACT_APP_USER_API_HOST +
                    ENDPOINTS.growers.download;
                const url = documentIds ? `${baseUrl}?${documentIds}` : baseUrl;
                return {
                    url: url,
                    method: HTTP_METHOD.GET,
                    cache: 'no-cache',
                    responseHandler: async (response: Response) => {
                        const headers = response.headers.get(
                            'content-disposition',
                        );

                        const data = await response.blob();
                        return { data, headers };
                    },
                };
            },
        }),
    }),
});

export const {
    useLazyDownloadBulkGrowerQuery,
    useGetGrowersQuery,
    useGetSummaryQuery,
} = growersAPI;
