import { FormConfig } from '../../../design/Forms/interfaces';
import {
    composeValidators,
    isDateGreaterOrEqualThan,
    isDateLessOrEqualThan,
    required,
} from '../../../utils/validation';

const gracePeriodOptions = [
    {
        id: 30,
        name: '30 Days',
    },
    {
        id: 60,
        name: '60 Days',
    },
    {
        id: 90,
        name: '90 Days',
    },
    {
        id: 120,
        name: '120 Days',
    },
    {
        id: 180,
        name: '180 Days',
    },
];

const yesNoOptions = [
    {
        id: true,
        name: 'Yes',
    },
    {
        id: false,
        name: 'No',
    },
];

interface OwnProps {
    isAutoRenewal: boolean;
}

export const formLeaseDurationConfig = ({
    isAutoRenewal,
}: OwnProps): FormConfig[] => [
    {
        formField: {
            scheme: 'quarter',
            name: 'startDate',
            type: 'datepicker',
            label: 'Lease Start Date',
            isRequired: true,
            validation: composeValidators(
                required,
                isDateLessOrEqualThan(
                    'endDate',
                    'Start date cannot be greater than end date',
                ),
            ),
        },
        renderProps: {
            placeholder: 'Enter Lease Start Date',
            isDisabled: false,
            size: 'small',
            format: 'MMM dd, yyyy',
        },
    },
    {
        formField: {
            scheme: 'quarter',
            name: 'endDate',
            type: 'datepicker',
            label: 'Lease End Date',
            isRequired: true,
            validation: composeValidators(
                required,
                isDateGreaterOrEqualThan(
                    'startDate',
                    'End date cannot be less than start date',
                ),
            ),
        },
        renderProps: {
            placeholder: 'Enter Lease End  Date',
            isDisabled: false,
            size: 'small',
            format: 'MMM dd, yyyy',
        },
    },
    {
        formField: {
            scheme: 'quarter',
            name: 'autoRenewal',
            type: 'radio',
            label: 'Autorenewal',
        },
        renderProps: {
            size: 'half',
            options: yesNoOptions,
            isDisabled: false,
        },
    },
    {
        formField: {
            scheme: 'quarter',
            name: 'notificationDeadline',
            type: 'select',
            label: 'Notification Deadline',
            isRequired: isAutoRenewal,
            validation: composeValidators(deadlineRequiredIfAutoRenewal),
        },
        renderProps: {
            placeholder: 'Select from list',
            isDisabled: !isAutoRenewal,
            size: 'small',
            options: gracePeriodOptions,
        },
    },
];
function deadlineRequiredIfAutoRenewal(
    value: string,
    allValues: Record<string, string>,
) {
    const isAutoRenewal =
        allValues?.autoRenewal && allValues?.autoRenewal.toString() === 'true';

    if (
        isAutoRenewal &&
        (value === null ||
            value === undefined ||
            value === '' ||
            value.length === 0)
    ) {
        return 'This field is required';
    }
    return undefined;
}
