import { Box, Stack } from '@mui/material';
import FarmDetailsDashboardWrapper from './FarmProfileDasboard/FarmDetailsDashboardWrapper';
import FarmProfileDetails from './FarmProfileDetails';
import Breadcrumbs from '../../design/Breadcrumbs/Breadcrumbs';
import React from 'react';
import useBreadcrumbs from '../../hooks/useBreadcrumbs';

export default function FarmProfile() {
    const breadcrumbs = useBreadcrumbs({
        routes,
    });
    return (
        <Stack>
            <Breadcrumbs collapsed={false} links={breadcrumbs} />
            <Box display={'flex'} flexDirection={'column'} rowGap={2}>
                <FarmDetailsDashboardWrapper />
                <FarmProfileDetails />
            </Box>
        </Stack>
    );
}
const routes = {
    '/farmProfile/': 'John Doe', //TODO might be dynamic with real data
    '/farmProfile/planning': 'Farm Planning',
    '/farmProfile/team': 'Farm Team',
    '/farmProfile/documents': 'Farm Documents',
};
