import * as _ from 'lodash';
import { createTheme } from '@mui/material/styles';

import palette from './palette';
import typography from './typography';
import breakpoints from './breakpoints';
import components from './components';
import fonts from './fonts';

const theme = {
    breakpoints,
    palette,
    typography,
    shape: {
        borderRadius: 6,
    },
    components: {
        ...components,
        MuiCssBaseline: {
            styleOverrides:
                fonts +
                `
            a {
            text-decoration: none;
            color: inherit;
            }`,
        },

        MuiFormLabel: {
            styleOverrides: {
                asterisk: {
                    color: palette.primary.main,
                    '&$error': {
                        color: palette.primary.main,
                    },
                },
            },
        },
    },
};

const mergedTheme = _.merge(theme);
const createdTheme = createTheme(mergedTheme);

export default createdTheme;
