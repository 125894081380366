import React, { useEffect, useState } from 'react';
import SideBarImage from '../../assets/images/pipe.png';
import { makeStyles } from 'tss-react/mui';
import { Box, Typography, Link as NavLink, Grid } from '@mui/material';
import { ReactComponent as Logo } from '../../assets/icons/logo2.svg';
import theme from '../../styles/theme';
import { useSearchParams } from 'react-router-dom';
import useHandleSubmitRTK from '../../hooks/useHandleSubmit';
import { useVerifyEmailByTokenMutation } from '../../api/contacts/contactsAPI';

import Loader from '../../design/BaseLoader';
import InfoOAKENBlock from './InfoOAKENBlock';

export default function VerifyEmailScreen() {
    const [message, setMessage] = useState({
        title: '',
        subtitle: '',
    });
    const { classes } = useStyles();
    const currentYear = new Date().getFullYear();
    const [searchParams] = useSearchParams();
    const token = searchParams.get('token');
    const { handleSubmit, isLoading } = useHandleSubmitRTK({
        useRtkHook: useVerifyEmailByTokenMutation,
        onSuccess: () =>
            setMessage({
                title: 'Thank you',
                subtitle:
                    'Your email has been verified. No further action is needed.',
            }),
        onError: () =>
            setMessage({
                title: 'Error',
                subtitle: 'Email verification failed.',
            }),
        successMessage: 'Email verified successfully',
    });

    useEffect(() => {
        handleSubmit(token);
    }, [token]);

    if (isLoading)
        return (
            <Grid
                container
                sx={{ height: '100vh' }}
                alignItems={'center'}
                justifyContent={'center'}
                flexDirection={'column'}>
                <Loader />
            </Grid>
        );

    return (
        <Box
            boxSizing={'border-box'}
            display={'flex'}
            width={'100%'}
            height={'100vh'}>
            <div className={classes.mainContainer}>
                <Logo className={classes.logo} />
                <Box pt={10} pb={5} display={'flex'} flexDirection={'column'}>
                    <Typography
                        variant={'font36'}
                        color={'text.neutral10'}
                        fontWeight={'light'}>
                        {message.title}
                    </Typography>
                    <Typography
                        variant={'font14'}
                        color={'text.neutral6'}
                        fontWeight={'light'}>
                        {message.subtitle}
                    </Typography>
                </Box>
                <Box
                    width={'100%'}
                    display={'flex'}
                    flexDirection={'column'}
                    rowGap={3}>
                    <Box
                        display={'flex'}
                        flex={1}
                        justifyContent={'space-between'}
                        alignItems={'flex-end'}>
                        <Typography variant={'font12'} color={'text.neutral6'}>
                            © {currentYear} OAKEN, INC. ALL RIGHTS RESERVED.
                        </Typography>
                        <NavLink
                            target={'_blank'}
                            href={'https://www.oaken.ag/end-user-terms-1'}
                            className={classes.link}>
                            PRIVACY POLICY
                        </NavLink>
                    </Box>
                    <InfoOAKENBlock />
                </Box>
            </div>
            <Box boxSizing={'border-box'} width={'50%'}>
                <img
                    src={SideBarImage}
                    alt={'background'}
                    className={classes.image}
                />
            </Box>
        </Box>
    );
}

const useStyles = makeStyles()(() => ({
    mainContainer: {
        padding: '120px 130px 40px 110px',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '50%',
        justifyContent: 'space-between',
    },
    logo: {
        width: '225px',
        height: '75px',
    },
    link: {
        fontSize: '11px',
        fontWeight: 500,
        lineHeight: '1.27',
        color: theme.palette.brandLightTurq,
        textDecoration: 'unset',
    },
    imageContainer: {
        height: '100vh',
        overflowY: 'hidden',
        width: '50vw',
        display: 'flex',
        alignItems: 'flex-end',
    },
    image: {
        display: 'block',
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        overflowY: 'hidden',
    },
}));
