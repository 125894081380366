import { Box } from '@mui/material';
import AppTypography from '../../design/AppTypography';
import OtherServicesWrappedCardList from './OtherServicesWrappedCardList';

export default function OtherServices() {
    return (
        <Box display={'flex'} flexDirection={'column'} rowGap={3}>
            <AppTypography
                variant={'font36'}
                fontWeight={'light'}
                text={'Additional Custom Solutions for you'}
            />
            <AppTypography
                flexWrap={true}
                variant={'font16'}
                fontWeight={'regular'}
                text={
                    'Oaken is committed to improving your farmland operations, finances, record-keeping, and relationships. Your operations may benefit from additional services from Oaken or one of our partners. Below is a list of extra services that may benefit your operations. \n' +
                    'Click here to see the settings that trigger these recommendations.'
                }
            />
            <OtherServicesWrappedCardList />
        </Box>
    );
}
