import React from 'react';
import { Button } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import CopiedReadField from '../../design/CopiedReadField/CopeiedReadField';
import { Account } from './UserProfile';
import { ChangeTypeOfKeys } from '../../types/utils';

interface OwnProps {
    data: AccountForMap;
    onClick: () => void;
}
interface KeyDatesResp {
    birthDate?: number;
    relationshipStartDate?: number;
}
type AccountForMap = ChangeTypeOfKeys<Account, 'keyDates', KeyDatesResp>;
export default function ReadOnlyUserProfile({ data, onClick }: OwnProps) {
    const { classes } = useStyles();
    return (
        <div className={classes.container}>
            <Button variant={'outlined'} onClick={onClick}>
                Edit
            </Button>
            <div className={classes.viewWrapper}>
                {convertDataToField(data).map(
                    (item, index) =>
                        item.value && <CopiedReadField key={index} {...item} />,
                )}
            </div>
        </div>
    );
}
const useStyles = makeStyles()((theme) => ({
    container: {
        display: 'flex',
        alignItems: 'flex-end',
        flexDirection: 'column',
        width: '100%',
        height: '60vh',
        gap: theme.spacing(3),
        padding: theme.spacing(2, 3),
        backgroundColor: theme.palette.neutralWhite,
    },
    viewWrapper: {
        display: 'flex',
        height: '40%',
        flexDirection: 'column',
        alignContent: 'space-between',
        flexWrap: 'wrap',
        width: '100%',
        padding: theme.spacing(2),
    },
}));

function convertDataToField(data: AccountForMap) {
    return [
        {
            title: 'Name',
            value: `${data.firstName} ${data.lastName}`,
        },
        {
            title: 'Role Type',
            value: data.role.name,
        },
        {
            title: 'Birthday',
            value: data.keyDates?.birthDate,
        },
        {
            title: 'Primary Email',
            value: data.primaryEmail,
        },
        {
            title: 'Secondary Email',
            value: data.secondaryEmail,
        },
        {
            title: 'Linkedin Profile',
            value: data.socialMedia?.linkedin,
        },
        {
            title: 'Twitter / X Profile',
            value: data.socialMedia?.twitter,
        },
        {
            title: 'Facebook Profile',
            value: data.socialMedia?.facebook,
        },
        {
            title: 'Instagram Profile',
            value: data.socialMedia?.instagram,
        },
        {
            title: 'Phone Number',
            value: data.phoneNumber,
        },
        {
            title: 'Organization',
            value: data.organization?.name,
        },
    ];
}
