import React from 'react';
import { useFormState } from 'react-final-form';
import { Box, Grid } from '@mui/material';
import PreviewCard, { PreviewCardProps } from './PreviewCard';
import { OrganizationValue, SubOrganization } from './OrganizationForm';
import PreviewSubscriptions from './PreviewSubscriptions';

interface OwnProps {
    setPageNumber: (page: number) => void;
}
export default function PreviewOrganizationPage({ setPageNumber }: OwnProps) {
    const { values: data } = useFormState();
    const { subscription } = data;
    const handleModify = (page: number) => {
        setPageNumber(page);
    };

    function organizationConvertor(data: OrganizationValue): PreviewCardProps {
        return {
            name: data.name,
            label: 'Organization',
            isLandOwner: true,
            firstName: data.primaryContact.firstName,
            lastName: data.primaryContact.lastName,
            email: data?.primaryContact?.primaryEmail,
            phoneNumber: data.phoneNumber,
            address1: data?.primaryAddress?.address1,
            address2: data?.primaryAddress?.address2,
            city: data?.primaryAddress?.city,
            state: data?.primaryAddress?.state,
            zipCode: data?.primaryAddress?.zipCode,
            onClick: () => handleModify(0),
        };
    }
    function billingConvertor(data: OrganizationValue): PreviewCardProps {
        return {
            label: 'Billing',
            name: data.name,
            address1: data?.billingAddress?.address1,
            address2: data?.billingAddress?.address2,
            city: data?.billingAddress?.city,
            state: data?.billingAddress?.state,
            zipCode: data?.billingAddress?.zipCode,
            onClick: () => handleModify(2),
        };
    }
    return (
        <Box display={'flex'} flexDirection={'column'} p={2} gap={1}>
            <PreviewCard
                {...organizationConvertor(data as OrganizationValue)}
            />
            {subscription && (
                <PreviewSubscriptions
                    data={subscription}
                    handleModify={() => handleModify(1)}
                />
            )}
            <PreviewCard {...billingConvertor(data as OrganizationValue)} />

            <Box
                display={'flex'}
                flexDirection={'column'}
                rowGap={2}
                sx={{
                    flexGrow: 1,
                }}>
                <Grid container columnSpacing={1}>
                    {data?.subOrganizations &&
                        data?.subOrganizations?.map(
                            (
                                subOrganization: SubOrganization,
                                index: number,
                            ) => (
                                <Grid item xs={6} key={index}>
                                    <PreviewCard
                                        label={'Sub Entities'}
                                        name={subOrganization.name}
                                        firstName={
                                            subOrganization?.primaryContact
                                                ?.firstName
                                        }
                                        lastName={
                                            subOrganization?.primaryContact
                                                ?.lastName
                                        }
                                        email={
                                            subOrganization?.primaryContact
                                                ?.primaryEmail
                                        }
                                        phoneNumber={
                                            subOrganization?.phoneNumber
                                        }
                                        address1={
                                            subOrganization.address?.address1
                                        }
                                        address2={
                                            subOrganization?.address?.address2
                                        }
                                        city={subOrganization?.address?.city}
                                        state={subOrganization?.address?.state}
                                        zipCode={
                                            subOrganization?.address?.zipCode
                                        }
                                        onClick={() => handleModify(3)}
                                    />
                                </Grid>
                            ),
                        )}
                </Grid>
            </Box>
        </Box>
    );
}
