import React, { useEffect, useMemo, useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import GenericTable, { Table } from '../../design/GenericTable/GenericTable';
import ExpandComponent from './ExpandComponent';
import { useSorting } from '../../design/GenericTable/useSorting';
import { usePagination } from '../../design/GenericTable/useBackendPagination';
import { useSelection } from '../../design/GenericTable/useSelection';
import ActionButtonsNotificationList from './ActionButtonsNotificationList';
import { Notification, NotificationType } from './NotificationCard';
import { ReactComponent as HopperAvatar } from '../../assets/icons/avatar_hopper_icon.svg';
import {
    useDeleteNotificationMutation,
    useGetNotificationsListQuery,
    useLazyGetNotificationsListQuery,
    useReadNotificationsMutation,
    useSnoozeNotificationMutation,
} from '../../api/notifications/notificationAPI';
import Loader from '../../design/BaseLoader';
import UserAvatar from '../../design/Avatar/UserAvatar';
import { useSettingsFromStorage } from '../../hooks/useSettingsFromStorage';
import { useSaveSettings } from '../../hooks/useSaveSettings';
import AssignedToField from './AssignedToField';
import {
    useAssignActivityMutation,
    useLandPartnersListQuery,
} from '../../api/landpartners/landPartnersAPI';
import ConfirmModal from '../../layouts/ConfirmModal/ConfirmModal';
import { Option } from '../CreateLeaseForm/WizardPageConfigs/leaseUploadConfig';
import useHandleSubmitRTK from '../../hooks/useHandleSubmit';

export default function NotificationsList() {
    const { settings, saveSettings } = useSettingsFromStorage('NOTIFICATIONS');
    const { sort, page, sortDirection, size } = settings;
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
    const [idForAssign, setIdForAssign] = useState<string>('');
    const {
        currentPage,
        itemsPerPage,
        handlePageChange,
        handleItemsPerPageChange,
    } = usePagination(page || 1, size || 25);

    const { sortKey, sortOrder, handleSort } = useSorting(
        sort || 'createdAt',
        sortDirection || 'desc',
    );

    useSaveSettings({
        settings,
        saveSettings,
        sortKey,
        sortOrder,
        currentPage,
        itemsPerPage,
    });
    const [getItems, { data: sortedData }] = useLazyGetNotificationsListQuery();
    const { data: landPartnersList, isLoading: isLandPartnersLoading } =
        useLandPartnersListQuery({
            page: 0,
            size: 2000,
        });
    const [snoozeNotification] = useSnoozeNotificationMutation();
    const [deleteNotification] = useDeleteNotificationMutation();
    const [readNotifications] = useReadNotificationsMutation();
    const [confirmModalInfo, setConfirmModalInfo] = useState({
        id: '',
        name: '',
    });
    const { handleSubmit: assignActivity, isLoading: isAssigning } =
        useHandleSubmitRTK({
            useRtkHook: useAssignActivityMutation,
            onSuccess: () => {
                setIdForAssign('');
                setConfirmModalInfo({ id: '', name: '' });
            },
            successMessage: 'Activity assigned successfully',
        });
    const handleSelectChange = (value: Option, referenceId: string) => {
        setIdForAssign(referenceId);
        setConfirmModalInfo(value);
        setIsConfirmModalOpen(true);
    };

    const handleModalConfirm = () => {
        assignActivity({ id: idForAssign, landPartnerId: confirmModalInfo.id });
        setIsConfirmModalOpen(false);
    };
    const handleDelete = (id: string) => {
        deleteNotification(id);
    };
    const handleSnooze = (id: string) => {
        snoozeNotification(id);
    };

    const { data: initData, isLoading } = useGetNotificationsListQuery({
        sort: `${sortKey},${sortOrder}`,
        page: currentPage - 1,
        size: itemsPerPage,
    });

    useEffect(() => {
        getItems({
            sort: `${sortKey},${sortOrder}`,
            page: currentPage - 1,
            size: itemsPerPage,
        });
    }, [sortKey, sortOrder, currentPage, itemsPerPage]);

    const data = useMemo(() => {
        return sortedData?.data || initData?.data || [];
    }, [sortedData, initData]);

    const totalCount = useMemo(() => {
        return sortedData?.totalCount || initData?.totalCount || 0;
    }, [sortedData, initData]);

    useEffect(() => {
        if (data.length > 0) {
            const ids = data
                .filter((notification) => !notification.read)
                .map((notification) => notification.id);
            if (ids.length > 0) {
                readNotifications({ notificationIds: ids });
            }
        }
    }, [data]);

    const { isSelected, toggleSelection, toggleSelectionAll, isSelectedAll } =
        useSelection<Notification>();

    if (isLoading || isLandPartnersLoading || isAssigning) {
        return (
            <Grid
                container
                sx={{ height: '100vh' }}
                alignItems={'center'}
                justifyContent={'center'}
                flexDirection={'column'}>
                <Loader />
            </Grid>
        );
    }

    const tableConfig: Table<Notification> = {
        columns: [
            {
                header: {
                    label: 'Name',
                    sortable: false,
                },
                cellRender: (notification) => {
                    const { firstName, lastName, contactAvatar } =
                        notification.contact || {};
                    if (!firstName && !lastName)
                        return (
                            <Box display={'flex'} alignItems={'center'}>
                                <HopperAvatar />
                                <Box ml={2}>
                                    {NotificationTypeMapper[notification.type]}
                                </Box>
                            </Box>
                        );
                    return (
                        <Box display={'flex'} alignItems={'center'}>
                            <UserAvatar
                                name={`${firstName} ${lastName} `}
                                avatar={contactAvatar?.contactAvatar}
                            />
                            <Box ml={2}>
                                <Typography color={'text.link'}>
                                    {firstName} {lastName}
                                </Typography>
                            </Box>
                        </Box>
                    );
                },
                format: { align: 'left', color: { color: 'primary' } },
                width: 'auto',
            },
            {
                header: {
                    label: 'Description',
                    sortable: true,
                    sorting: {
                        id: 'message',
                        direction: sortKey === 'message' ? sortOrder : 'desc',
                        isSorted: sortKey === 'message',
                    },
                    onClick: handleSort,
                },
                cellRender: (notification) => notification.message,
                format: { align: 'left', color: { color: 'primary' } },
                width: 'auto',
            },
            {
                header: {
                    label: 'Type',
                    sortable: true,
                    sorting: {
                        id: 'type',
                        direction: sortKey === 'type' ? sortOrder : 'desc',
                        isSorted: sortKey === 'type',
                    },
                    onClick: handleSort,
                },
                cellRender: (notification) =>
                    NotificationTypeMapper[notification.type],
                format: { align: 'left', color: { color: 'primary' } },
                width: 'auto',
            },
            {
                header: {
                    label: 'Assign To',
                    sortable: false,
                },
                cellRender: (notification) => {
                    return (
                        <AssignedToField
                            isHidden={notification.type !== 'ACTIVITY_FAILED'}
                            options={landPartnersList?.data || []}
                            onChange={(value) =>
                                notification?.referenceId &&
                                handleSelectChange(
                                    value,
                                    notification?.referenceId,
                                )
                            }
                        />
                    );
                },
                format: { align: 'left', color: { color: 'primary' } },
                width: 'auto',
            },
            {
                header: {
                    label: '',
                    sortable: false,
                },
                cellRender: (notification) => (
                    <ActionButtonsNotificationList
                        isSnoozeHidden={notification.type === 'ACTIVITY_FAILED'}
                        overdue={notification?.overdue}
                        eventDate={notification?.eventDate || ''}
                        snoozed={notification.snoozed}
                        onSnooze={() => handleSnooze(notification.id)}
                        onDelete={() => handleDelete(notification.id)}
                    />
                ),
                format: { align: 'left', color: { color: 'primary' } },
                width: 'auto',
            },
        ],
        multiselect: true,
        expandable: true,
        ExpandComponent: ExpandComponent,
        handlePageChange: handlePageChange,
        handleItemsPerPageChange: handleItemsPerPageChange,
        isSelected: isSelected,
        toggleSelection: toggleSelection,
        toggleSelectionAll: toggleSelectionAll,
        isSelectedAll: isSelectedAll,
        initialPage: page,
        initialPageSize: size,
    };

    return (
        <Box
            width={'100%'}
            display={'flex'}
            flexDirection={'column'}
            rowGap={2}
            alignItems={'flex-end'}>
            <GenericTable
                data={data}
                tableConfig={tableConfig}
                totalCount={totalCount}
            />
            <ConfirmModal
                isShowing={isConfirmModalOpen}
                hide={() => {
                    setIdForAssign('');
                    setConfirmModalInfo({ id: '', name: '' });
                    setIsConfirmModalOpen(false);
                }}
                title={``}
                info={`Please confirm you want to assign ${confirmModalInfo?.name} to activity exception?`}
                onSubmit={handleModalConfirm}
            />
        </Box>
    );
}
const NotificationTypeMapper: Record<NotificationType, string> = {
    REMINDER: 'Reminder',
    ACTIVITY_FAILED: 'Activity Assignment',
    BIRTHDAY: 'Birthday',
    ANNIVERSARY: 'Anniversary',
    TASKS: 'Tasks',
    LEASE_RENEWALS: 'Lease Renewals',
    PAYMENT: 'Payment',
};
