import React, { useContext, useState } from 'react';
import { TableColumn } from '../../../../design/GenericTable/GenericTable';

import { useSorting } from '../../../../design/GenericTable/useSorting';
import { usePagination } from '../../../../design/GenericTable/useBackendPagination';

import { Tooltip, Typography } from '@mui/material';
import ActionsColumn from '../ActionsColumn';
import { SelectedRowContext } from '../SelectedRowContext';
import useHandleSubmitRTK from '../../../../hooks/useHandleSubmit';
import { dateTimeConverterFromUTC } from '../../../../utils/dateFormat';
import { makeStyles } from 'tss-react/mui';
import {
    ArchivedLeaseNote,
    useDeleteLeaseNoteMutation,
    useGetArchivedLeaseNotesQuery,
    useRestoreLeaseNoteMutation,
} from '../../../../api/leases/leaseNotesAPI';
import { communicationTypeMapper } from '../../../../reusable/Chat/NoteDetails';

const useStyles = makeStyles()(() => ({
    truncatedText: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: 150,
        '&:hover': {
            overflow: 'visible',
            whiteSpace: 'normal',
        },
    },
}));

export interface ArchivedLeaseNoteData {
    id: string;
    note: string;
    archiveDate: string | number;
    archivedBy: string;
    communicationType?: string;
    actualContactDate?: string | number;
    createdAt: string | number;
}

export default function useTableArchivedLeaseNotesConfig() {
    const { classes } = useStyles();
    const [isShowing, setIsShowing] = useState(false);
    const [deleteData, setDeleteData] = useState<ArchivedLeaseNoteData | null>(
        null,
    );

    const { sortKey, sortOrder, handleSort } = useSorting('updatedAt', 'desc');
    const { setSelectedRow } = useContext(SelectedRowContext);
    const handleRowClick = (rowData: ArchivedLeaseNoteData) => {
        setSelectedRow(rowData);
    };
    const {
        currentPage,
        itemsPerPage,
        handlePageChange,
        handleItemsPerPageChange,
    } = usePagination(1, 100);

    const { handleSubmit, isLoading: isRestoreProcessing } = useHandleSubmitRTK(
        {
            onSuccess: () => setSelectedRow(null),
            useRtkHook: useRestoreLeaseNoteMutation,
            successMessage: 'Lease note restored successfully',
        },
    );
    const { data, isLoading } = useGetArchivedLeaseNotesQuery({
        sort: `${sortKey},${sortOrder}`,
        page: currentPage - 1,
        size: itemsPerPage,
    });
    const { handleSubmit: handleDelete, isLoading: isDeleteProcessing } =
        useHandleSubmitRTK({
            useRtkHook: useDeleteLeaseNoteMutation,
            onSuccess: () => {
                setIsShowing(false);
                setSelectedRow(null);
            },
            successMessage: 'Lease note deleted successfully',
        });

    function handleDeleteClick(data: ArchivedLeaseNoteData) {
        setIsShowing(true);
        setDeleteData(data);
    }

    const columns: TableColumn<ArchivedLeaseNoteData>[] = [
        {
            header: {
                label: 'Name',
                sortable: true,
                sorting: {
                    id: 'note',
                    direction: sortKey === 'note' ? sortOrder : 'desc',
                    isSorted: sortKey === 'note',
                },
                onClick: handleSort,
            },
            cellRender: (leaseNote) => (
                <Tooltip title={leaseNote.note} arrow>
                    <Typography
                        color={'text.link'}
                        className={classes.truncatedText}>
                        {leaseNote.note.length > 10
                            ? `${leaseNote.note.substring(0, 10)}...`
                            : leaseNote.note}
                    </Typography>
                </Tooltip>
            ),
            maxColumnWidth: 200,
            format: { align: 'left', color: { color: 'primary' } },
            width: '20%',
        },
        {
            header: {
                customizeKey: 'archiveDate',
                label: 'Archived Date',
            },
            cellRender: (leaseNote) => <>{leaseNote?.archiveDate}</>,
            format: { align: 'left', color: { color: 'primary' } },
            width: '20%',
        },
        {
            header: {
                customizeKey: 'archivedBy',
                label: 'Archived By',
            },
            cellRender: (leaseNote) => <>{leaseNote?.archivedBy}</>,
            format: { align: 'left', color: { color: 'primary' } },
            width: '20%',
        },
        {
            header: {
                customizeKey: 'actions',
                label: 'Actions',
                sortable: false,
            },
            cellRender: (leaseNote) => (
                <ActionsColumn
                    id={leaseNote.id}
                    onDelete={() => handleDeleteClick(leaseNote)}
                    onRestore={() => handleSubmit(leaseNote.id)}
                    isProcessing={isRestoreProcessing || isDeleteProcessing}
                />
            ),
            format: { align: 'left', color: { color: 'primary' } },
            width: '40%',
        },
    ];

    const tableConfig = {
        columns,
        pageable: false,
        handlePageChange,
        onClick: handleRowClick,
        handleItemsPerPageChange,
        initialPageSize: 5,
        initialPage: 1,
    };
    const totalCount = data?.totalCount || 0;
    return {
        data: data?.data?.map(dataConvertorFromResponse) || [],
        totalCount,
        tableConfig,
        isLoading,
        isProcessing: isRestoreProcessing || isDeleteProcessing,
        deleteData,
        isShowing,
        setIsShowing,
        handleDelete,
    };
}

function dataConvertorFromResponse(
    data: ArchivedLeaseNote,
): ArchivedLeaseNoteData {
    return {
        id: data?.id,
        note: data?.note,
        archiveDate: dateTimeConverterFromUTC(data?.updatedAt),
        createdAt: dateTimeConverterFromUTC(data?.createdAt),
        archivedBy: data?.archivedBy?.id
            ? `${data?.archivedBy?.firstName} ${data?.archivedBy?.lastName}`
            : 'N/A',
        communicationType:
            data?.communicationContainer?.communicationType &&
            communicationTypeMapper[
                data.communicationContainer.communicationType
            ],
        actualContactDate:
            data?.communicationContainer?.actualContactDate &&
            dateTimeConverterFromUTC(
                data?.communicationContainer?.actualContactDate,
            ),
    };
}
