import theme from '../../../styles/theme';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { Box, IconButton } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import DndItem from './DndItem';
import { FieldProps } from 'react-final-form';
import AddIcon from '@mui/icons-material/Add';
import TextField from '@mui/material/TextField';

import AppTypography from '../../../design/AppTypography';
interface OwnProps {
    input: FieldProps<string[], never>;
    addButtonLabel?: string;
}

export default function DndItemsList({
    input,
    addButtonLabel = 'Add Item',
}: OwnProps) {
    const { value = [], onChange } = input;

    const [items, setItems] = useState<string[]>(value || []);
    const [addItem, setAddItem] = useState<string>('');

    const moveItem = useCallback((dragIndex: number, hoverIndex: number) => {
        setItems((prevCards: string[]) => {
            const newItems = [...prevCards];
            const [draggedCard] = newItems.splice(dragIndex, 1);
            newItems.splice(hoverIndex, 0, draggedCard);
            return newItems;
        });
    }, []);

    useEffect(() => {
        onChange(items);
    }, [items]);
    return (
        <Box
            width={'100%'}
            display={'flex'}
            flexDirection={'column'}
            rowGap={1}
            p={theme.spacing(4, 2)}
            sx={{
                flexGrow: 1,
                borderTop: `1px solid ${theme.palette.backgroundB.backgroundB4}`,
                borderBottom: `1px solid ${theme.palette.backgroundB.backgroundB4}`,
            }}>
            <AppTypography
                variant={'font12'}
                fontWeight={'medium'}
                color={'text.neutral7'}
                text={'Drag to prioritize (top down)'}
            />
            <DndProvider backend={HTML5Backend}>
                {items?.map((item, index) => (
                    <DndItem
                        key={item}
                        text={item}
                        index={index}
                        moveItem={moveItem}
                        handleRemoveItem={(text) => {
                            setItems(items.filter((item) => item !== text));
                        }}
                    />
                ))}
            </DndProvider>

            <Box
                width={'100%'}
                display={'flex'}
                alignItems={'center'}
                columnGap={1}
                justifyContent={'flex-end'}>
                <TextField
                    sx={{
                        '& .MuiInputBase-root': {
                            height: '30px',
                        },
                    }}
                    value={addItem}
                    onChange={(e) => setAddItem(e.target.value)}
                />
                <IconButton
                    sx={{
                        fontFamily: 'Montserrat',
                        display: 'flex',
                        columnGap: 1,
                        fontSize: '12px',
                        color: theme.palette.brandLightTurq,
                    }}
                    onClick={() => {
                        if (addItem) {
                            setItems([...items, addItem]);
                            setAddItem('');
                        }
                    }}>
                    {addButtonLabel}
                    <AddIcon
                        sx={{
                            fontSize: '12px',
                            color: theme.palette.brandLightTurq,
                        }}
                    />
                </IconButton>
            </Box>
        </Box>
    );
}
