import { Typography, Button } from '@mui/material';
import * as yup from 'yup';
import React from 'react';
import { useFormik } from 'formik';

import useStyles from './LogIn.styles';
import theme from '../../../styles/theme';
import FormTextField, {
    EndIconType,
} from '../../../design/FormUI/FormTextField';
import { passwordRegex } from '../../../constant/regex';
import { ReactComponent as ApproveIcon } from '../../../assets/icons/approve_icon.svg';
import { ReactComponent as RejectIcon } from '../../../assets/icons/reject_icon.svg';
import { useNavigate, useSearchParams } from 'react-router-dom';
import PATHS from '../../../router/paths';
import customToastWithAlert from '../../../design/CustomToastWithAlert';
import { useSubmitResetMutation } from '../../../api/auth/authAPI';
interface ResetPasswordFormProps {
    password: string;
}
const INITIAL_FORM_STATE = {
    password: '',
    repeatPassword: '',
};
const minPasswordLength = 12;

export const FORM_VALIDATION = yup.object({
    password: yup
        .string()
        .min(12, '* Password must have at least 12 characters')
        .matches(passwordRegex, '* Please provide a correct password')
        // .matches(/[0-9]/, getCharacterValidationError("digit"))
        // .matches(/[a-z]/, getCharacterValidationError("lowercase"))
        // .matches(/[A-Z]/, getCharacterValidationError("uppercase"))
        .required('* This field is required'),
    repeatPassword: yup
        .string()
        .oneOf([yup.ref('password')], '* Password must match')
        .required('* This field is required'),
});

export default function ResetPasswordForm() {
    const { classes } = useStyles(theme);
    const [submitReset] = useSubmitResetMutation();
    const [searchParams] = useSearchParams();
    const token = searchParams.get('token');
    const navigate = useNavigate();

    const onSubmit = ({ password }: ResetPasswordFormProps) => {
        submitReset({ token, password })
            .unwrap()
            .then(() => navigate(`/${PATHS.auth}/${PATHS.logIn}`))
            .catch((error) => {
                return customToastWithAlert({
                    type: 'error',
                    message: error?.data?.description || 'Something went wrong',
                });
            });
    };
    const formik = useFormik({
        enableReinitialize: true,
        validateOnChange: true,
        initialValues: { ...INITIAL_FORM_STATE },
        validationSchema: FORM_VALIDATION,
        onSubmit,
    });

    return (
        <div className={classes.formWrapper}>
            <Typography
                className={classes.title}
                variant="font32"
                fontWeight="regular">
                Reset your password
            </Typography>
            <Typography className={classes.details}></Typography>
            <form onSubmit={formik.handleSubmit} noValidate>
                <FormTextField
                    name={'password'}
                    label={'Password'}
                    required
                    className={classes.textField}
                    formik={formik}
                    endIconType={EndIconType.password}
                />

                <div className={classes.validationContainer}>
                    <div className={classes.validationItem}>
                        {formik.values.password?.length >= minPasswordLength ? (
                            <ApproveIcon />
                        ) : (
                            <RejectIcon />
                        )}
                        At least 12 characters long but 14 or more is better.
                    </div>
                    <div className={classes.validationItem}>
                        {formik.values.password.match(passwordRegex) ? (
                            <ApproveIcon />
                        ) : (
                            <RejectIcon />
                        )}
                        A combination of 1 uppercase letter minimum, 1 lowercase
                        letter minimum, 1 number and 1 special character
                        minimum.
                    </div>
                    <div className={classes.validationItem}>
                        <ApproveIcon /> Not a word that can be found in a
                        dictionary or the name of a person, character, product,
                        or organization.
                    </div>
                </div>

                <FormTextField
                    name={'repeatPassword'}
                    label={'Repeat Password'}
                    required
                    className={classes.textField}
                    formik={formik}
                    endIconType={EndIconType.password}
                />

                <div className={classes.bottomContainer}>
                    <Button className={classes.button} type="submit">
                        Reset Password
                    </Button>
                </div>
            </form>
        </div>
    );
}
