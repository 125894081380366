import { ReactNode } from 'react';
import { makeStyles } from 'tss-react/mui';
import theme from '../styles/theme';
import { Box, Typography } from '@mui/material';

export interface Widget {
    title?: ReactNode;
    data?: string | number;
    description?: string;
    isHidden?: boolean;
}

export default function WidgetCard({
    title,
    data,
    description,
    isHidden,
}: Widget) {
    const { classes } = useStyles();

    if (isHidden) return null;

    return (
        <div className={classes.widget}>
            <Box>{title}</Box>
            <Typography
                color={'text.link'}
                variant={'font36'}
                fontWeight={'light'}>
                {data}
            </Typography>
            <Typography
                color={'text.neutral9'}
                variant={'font12'}
                fontWeight={'medium'}>
                {description}
            </Typography>
        </div>
    );
}
const useStyles = makeStyles()(() => ({
    widget: {
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(2),
        minWidth: 250,
        borderRadius: 16,
        backgroundColor: theme.palette.neutralWhite,
        border: `1px solid ${theme.palette.brandYellowGreen}`,

        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
    },
}));
