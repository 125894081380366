import React from 'react';
import Chat from '../../reusable/Chat/Chat';
import {
    useAddNoteAttachmentMutation,
    useAddNoteMutation,
    useGetAllNotesQuery,
    useLazyGetAllNotesQuery,
} from '../../api/contacts/contactNotesAPI';
import { useParams } from 'react-router-dom';
import customToastWithAlert from '../../design/CustomToastWithAlert';
import { CommunicationContainer } from '../../reusable/NotesField/NotesField';
import { convertResponseToNote } from '../CreateContact/AddNotes';

export default function AllProfilesNotes() {
    const { id } = useParams();

    const { data: notes, isLoading: isLoadingNotes } = useGetAllNotesQuery({
        sort: `createdAt,desc`,
        contactId: id,
    });
    const [addNotes, { isLoading: isAddingNote }] = useAddNoteMutation();
    const [addNoteAttachment, { isLoading: isAddingNoteAttachment }] =
        useAddNoteAttachmentMutation();
    const [lazyGetNotes] = useLazyGetAllNotesQuery();

    async function handleAddNote(
        note: string,
        file: File | null,
        communicationContainer: CommunicationContainer,
    ) {
        try {
            //eslint-disable-next-line
            // @ts-ignore
            const { data } = await addNotes({
                contactId: id,
                content: note,
                communicationContainer,
            });

            if (file && data.id) {
                await addNoteAttachment({
                    id: data.id,
                    attachment: file,
                });
            }

            lazyGetNotes({
                sort: `createdAt,desc`,
                contactId: id,
            });
        } catch {
            customToastWithAlert({
                type: 'error',
                message: 'Note attachment upload failed',
            });
        }
    }

    return (
        <Chat
            cards={(notes?.data && convertResponseToNote(notes?.data)) || []}
            addNote={handleAddNote}
            contactId={id}
            type={'CONTACT'}
            isAddingNote={isAddingNote || isAddingNoteAttachment}
            isLoadingNotes={isLoadingNotes}
        />
    );
}
