import { Box, IconButton, Typography } from '@mui/material';
import theme from '../../../styles/theme';
import clsx from 'clsx';
import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { ReactComponent as ExpandIcon } from '../../../assets/icons/expand_icon.svg';
import { FieldDataPerCrop } from './CollapsedDetails';
import AppTypography from '../../../design/AppTypography';
import { TitleWithValueProps } from '../TitleWithValue';
import TitleWithValueList from '../TitleWithValueList';
import ThreeColumnLayout from '../../../layouts/ThreeColumnLayout';
interface OwnProps {
    data: FieldDataPerCrop;
    year: string;
    isCollapsed: boolean;
    handleCollapse: () => void;
}
export default function ShortCollapsedData({
    data,
    year,
    isCollapsed,
    handleCollapse,
}: OwnProps) {
    const { classes } = useStyles();
    return (
        <Box
            display={'flex'}
            className={classes.expandHeader}
            sx={{
                borderBottom: !isCollapsed
                    ? `1px solid ${theme.palette.strokeS1}`
                    : 'none',
            }}>
            <ThreeColumnLayout
                leftComponent={
                    <Box display={'flex'} flexDirection={'column'} rowGap={1}>
                        <AppTypography
                            variant={'font14'}
                            fontWeight={'medium'}
                            color={'text.neutral10'}
                            text={year}
                        />
                        <AppTypography
                            isHidden={!isCollapsed}
                            variant={'font14'}
                            fontWeight={'regular'}
                            color={'text.neutral10'}
                            text={'Source'}
                        />
                    </Box>
                }
                middleComponent={
                    <TitleWithValueList data={convertDataToView(data)} />
                }
                rightComponent={
                    <Box
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'flex-end'}>
                        <Typography
                            variant={'font12'}
                            color={'text.link'}
                            fontWeight={'medium'}>
                            {isCollapsed ? 'Collapse' : 'Expand'}
                        </Typography>{' '}
                        <IconButton onClick={handleCollapse}>
                            <ExpandIcon
                                className={clsx(
                                    isCollapsed && classes.expanded,
                                )}
                            />
                        </IconButton>
                    </Box>
                }
                leftColumnWidth={'compact'}
                rightColumnWidth={'compact'}
            />
        </Box>
    );
}

function convertDataToView(data: FieldDataPerCrop): TitleWithValueProps[] {
    return [
        { title: 'CROP 1', value: data.crop },
        { title: 'YIELD 1', value: data.yield },
        { title: 'PLANTING START', value: data.plantingStart },
        { title: 'PLANTING END', value: data.plantingEnd },
        { title: 'HARVEST', value: data.harvest },
    ];
}
const useStyles = makeStyles()(() => ({
    expandHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        padding: theme.spacing(1.5, 1),
    },
    expanded: {
        width: 24,
        height: 24,
        transform: 'rotate(180deg)',
    },
}));
