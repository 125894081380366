export const getStorageItem = <T>(
    key: string,
    defaultValue: T = {} as T,
): T => {
    try {
        const value = localStorage.getItem(key);
        return value ? (JSON.parse(value) as T) : defaultValue;
    } catch (e) {
        return defaultValue;
    }
};

export const setToLocalStorage = <T>(key: string, data: T): T => {
    return localStorage.setItem(key, JSON.stringify(data)) as T;
};
export const getSessionStorageItem = <T>(
    key: string,
    defaultValue: T = {} as T,
): T => {
    try {
        const value = sessionStorage.getItem(key);
        return value ? (JSON.parse(value) as T) : defaultValue;
    } catch (e) {
        return defaultValue;
    }
};

export const clearLocalStorage = () => {
    return localStorage.clear();
};

export const clearSessionStorage = () => {
    return sessionStorage.clear();
};
