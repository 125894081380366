import React, { useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import {
    useGetRolesQuery,
    useLazyPermissionsByRoleQuery,
} from '../../../../api/users/rolesAPI';
import RolesDropdown from '../RolesDropdown';
import Loader from '../../../../design/BaseLoader';
import RoleButtons from './RoleButtons';
import PermissionsTable from './PermissionsTable';
import customToastWithAlert from '../../../../design/CustomToastWithAlert';

export interface Role {
    id: string;
    name: string;
}

export interface Permission {
    id: string;
    displayName: string;
    enabled: boolean;
}

export interface RolePermissions {
    roleId: string;
    data: Record<string, Permission[]>;
}

export default function CompareRoles() {
    const [options, setOptions] = useState<Role[]>([]);
    const { data: rolesData, isLoading: isOptionsLoading } = useGetRolesQuery({
        sort: 'name,asc',
        page: 0,
        size: 2000,
    });
    const [getPermissions, { isLoading: isPermissionsDataLoading }] =
        useLazyPermissionsByRoleQuery();
    const [selectedRoles, setSelectedRoles] = useState<RolePermissions[]>([]);

    useEffect(() => {
        if (rolesData?.data) {
            setOptions(
                rolesData.data.filter((role: Role) => role.name !== 'ADMIN'),
            );
        }
    }, [rolesData]);

    const handleRoleChange = async (roleId: string) => {
        if (selectedRoles.some((r) => r.roleId === roleId)) {
            customToastWithAlert({
                type: 'warning',
                message: 'Role already selected',
            });
            return;
        }
        if (selectedRoles.length >= 3) {
            customToastWithAlert({
                type: 'warning',
                message: 'You can only compare up to 3 roles',
            });
            return;
        }
        const { data } = await getPermissions({ id: roleId });
        setSelectedRoles((prev) => [...prev, { roleId, data }]);
    };

    const handleRemoveRole = (roleId: string) => {
        setSelectedRoles((prev) =>
            prev.filter((role) => role.roleId !== roleId),
        );
    };

    return (
        <Box width="100%" display={'flex'} flexDirection={'column'} rowGap={2}>
            <Box
                minWidth={'15%'}
                display={'flex'}
                width={'100%'}
                justifyContent={'flex-end'}>
                <RolesDropdown
                    placeholder={
                        selectedRoles.length > 0
                            ? 'Select Another Role'
                            : 'Select First Role'
                    }
                    options={options}
                    setRole={handleRoleChange}
                    isLoading={isOptionsLoading}
                />
            </Box>
            {(isPermissionsDataLoading || isOptionsLoading) && (
                <Grid
                    width={'100%'}
                    height={'50vh'}
                    container
                    alignItems={'center'}
                    justifyContent={'center'}>
                    <Loader />
                </Grid>
            )}
            {selectedRoles && selectedRoles.length > 0 && (
                <>
                    <RoleButtons
                        selectedRoles={selectedRoles}
                        options={options}
                        handleRemoveRole={handleRemoveRole}
                    />
                    <PermissionsTable
                        selectedRoles={selectedRoles}
                        options={options}
                    />
                </>
            )}
        </Box>
    );
}
