import palette from './palette';

const buttonOverrides = {
    MuiButton: {
        variants: [
            {
                props: { variant: 'text' },
                style: { color: palette.brandLightTurq },
            },
        ],

        sizes: [
            {
                props: { size: 'large' },
                style: { fontSize: '14px', padding: '12px 16px' },
            },
            {
                props: { size: 'medium' },
                style: { fontSize: '13px', padding: '8px 16px' },
            },
            {
                props: { size: 'small' },
                style: { fontSize: '12px', padding: '4px 8px' },
            },
        ],
        styleOverrides: {
            root: {
                maxHeight: '40px',
                width: 'fit-content',
                textTransform: 'unset',
                borderRadius: '8px',
                fontWeight: 500,
                lineHeight: '1.43',
                color: palette.neutralWhite,
            },
            outlined: {
                color: palette.neutral.neutral10,
                border: `solid 1px ${palette.backgroundB.backgroundB4}`,
                backgroundColor: palette.neutralWhite,
            },
        },
    },
};

export default buttonOverrides;
