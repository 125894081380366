export function tooLargeFile(size: number) {
    const readableSize = size / 1024 / 1024;
    return function (value: File[]) {
        if (value && value.length > 0) {
            const invalidFiles = value.filter((file) => file.size > size);
            if (invalidFiles.length > 0) {
                return `Max file size supported is ${parseInt(
                    readableSize.toString(),
                )} MB`;
            }
        }
    };
}

export function fileRequired(value: File[]) {
    if ((value && value.length === 0) || !value) {
        return 'File is required';
    }
}
export function tooSmallFile(size: number) {
    return function (value: File[]) {
        if (value && value.length > 0) {
            const invalidFiles = value.filter((file) => file.size < size);
            if (invalidFiles.length > 0) {
                return `File size must be greater than ${size} bytes`;
            }
        }
    };
}

export function tooManyFiles(max: number) {
    return function (value: File[]) {
        if (value && value.length > max) {
            return `You can upload only ${max} files`;
        }

        return undefined;
    };
}

export function invalidFileType(accept: string[]) {
    return function (value: File[]) {
        if (value && value.length > 0) {
            const invalidFiles = value.filter(
                (file) => !accept.includes(file.type),
            );
            if (invalidFiles.length > 0) {
                return `Invalid file type: ${invalidFiles[0].name}`;
            }
        }
    };
}
