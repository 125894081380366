import GenericTable from '../../../design/GenericTable/GenericTable';
import useApproachingYearlyCheckupsTable from './useApproachingYearlyCheckupsTable';
import { Typography } from '@mui/material';
import React from 'react';

export default function ApproachingYearlyCheckupsTable() {
    const { tableConfig, data } = useApproachingYearlyCheckupsTable();
    return (
        <>
            <Typography variant={'font16'} fontWeight={'bold'}>
                Approaching Yearly Checkups
            </Typography>
            <GenericTable data={data} tableConfig={tableConfig} />
        </>
    );
}
