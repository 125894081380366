import { Box, Typography, Button } from '@mui/material';
import theme from '../../../styles/theme';
import { useNavigate, useParams } from 'react-router-dom';
import PATHS from '../../../router/paths';

interface OwnProps {
    parentContactId: string;
}
export default function CreateNewContactWidget({ parentContactId }: OwnProps) {
    const navigate = useNavigate();
    const { id: landPartnerId } = useParams();
    return (
        <Box
            display={'flex'}
            alignItems={'center'}
            flexDirection={'column'}
            rowGap={3}
            width={290}
            borderRadius={1}
            sx={{
                backgroundColor: theme.palette.lightTurq.lightTurqTint2,
                padding: theme.spacing(4.5, 3),
            }}>
            <Button
                variant="outlined"
                color="primary"
                onClick={() =>
                    navigate(
                        `/${PATHS.landpartners}/${PATHS.allProfiles}/${PATHS.create}`,
                        {
                            state: {
                                landPartnerId,
                                parentContactId:
                                    landPartnerId === parentContactId
                                        ? null
                                        : parentContactId,
                            },
                        },
                    )
                }>
                Create a New Contact
            </Button>
            <Typography fontWeight={'medium'}>
                We’ll guide you through the necessary steps or save time by
                using your contacts vCard
            </Typography>
        </Box>
    );
}
