import AppButton from '../../../design/Buttons/AppButton';

interface OwnProps {
    id: string;
    isDisabled: boolean;
}

export default function ConfigureButton({ id, isDisabled }: OwnProps) {
    return (
        <AppButton
            isDisabled={isDisabled}
            variant="contained"
            title={'Configure'}
            onClick={() => {
                // eslint-disable-next-line no-console
                console.log(
                    'Configure button clicked for integration with id:',
                    id,
                );
            }}
        />
    );
}
