import { Chip, Tooltip } from '@mui/material';
import theme from '../../styles/theme';
import { phoneMaskForString } from '../../utils/fieldMasks';

interface OwnProps {
    phone: string;
    phoneType: string;
}
export default function ChipForPhone({ phone, phoneType }: OwnProps) {
    return (
        <a href={`tel:${phone}`}>
            <Tooltip title={typeMapper[phoneType]} placement="top">
                <Chip
                    label={phoneMaskForString(phone)}
                    variant="outlined"
                    size="small"
                    sx={{
                        border: `2px solid ${colorMap[phoneType]}`,
                        color: colorMap[phoneType],
                    }}
                />
            </Tooltip>
        </a>
    );
}
const colorMap: Record<string, string> = {
    CELL_PHONE: theme.palette.primary.main,
    OFFICE_PHONE: theme.palette.limeGreen.limeGreenShade1,
    HOME_PHONE: theme.palette.yellowGreen.yellowGreenShade7,
    FAX: theme.palette.statusRedTint,
};
const typeMapper: Record<string, string> = {
    CELL_PHONE: 'Cell',
    OFFICE_PHONE: 'Office',
    HOME_PHONE: 'Home',
    FAX: 'Fax',
};
