import { Box, Button, Typography } from '@mui/material';
import * as yup from 'yup';
import React from 'react';
import { useFormik } from 'formik';

import useStyles from './LogIn.styles';
import theme from '../../../styles/theme';
import FormTextField, {
    EndIconType,
} from '../../../design/FormUI/FormTextField';
import { passwordRegex } from '../../../constant/regex';
import { ReactComponent as ApproveIcon } from '../../../assets/icons/approve_icon.svg';
import { ReactComponent as RejectIcon } from '../../../assets/icons/reject_icon.svg';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { clearLocalStorage } from '../../../utils/localStorageUtils';
import { TOKEN_KEY } from '../../../constant/localStorageKeys';
import PATHS from '../../../router/paths';
import { OAKENApi } from '../../../api/OAKENApi';
import customToastWithAlert from '../../../design/CustomToastWithAlert';
import { useDispatch } from 'react-redux';
import { useActivateMutation } from '../../../api/auth/authAPI';
import CheckboxField from '../../../design/FormUI/CheckboxField';

const INITIAL_FORM_STATE = {
    password: '',
    repeatPassword: '',
    acceptPolicy: false,
};

const minPasswordLength = 12;
interface SubmitValue {
    password: string;
    repeatPassword: string;
    acceptPolicy: boolean;
}
export const FORM_VALIDATION = yup.object({
    password: yup
        .string()
        .min(minPasswordLength, '* Password must have at least 12 characters')
        .matches(passwordRegex, '* Please provide a correct password')
        .required('* This field is required'),
    repeatPassword: yup
        .string()
        .oneOf([yup.ref('password')], '* Password must match')
        .required('* This field is required'),
});

export default function ActivateAccountForm() {
    const [searchParams] = useSearchParams();
    const token = searchParams.get('token');

    clearLocalStorage();

    const navigate = useNavigate();

    const dispatch = useDispatch();
    const [activate] = useActivateMutation();

    const { classes } = useStyles(theme);

    const formik = useFormik({
        enableReinitialize: true,
        validateOnChange: true,
        initialValues: { ...INITIAL_FORM_STATE },
        validationSchema: FORM_VALIDATION,
        onSubmit,
    });

    function onSubmit(values: SubmitValue) {
        clearLocalStorage();
        activate({
            token: token,
            password: values.password,
        })
            .unwrap()
            .then((data) => {
                dispatch(OAKENApi.util?.resetApiState());
                localStorage.setItem(TOKEN_KEY, data.accessToken);
                navigate(PATHS.dashboard);
            })
            .catch((error) => {
                return customToastWithAlert({
                    type: 'error',
                    message: error?.data?.description || 'Something went wrong',
                });
            });
    }

    return (
        <div className={classes.formWrapper}>
            <Typography
                className={classes.title}
                variant="font32"
                fontWeight="regular">
                Welcome to Oaken
            </Typography>
            <Typography className={classes.details}>
                Please set a new password to activate your account
            </Typography>
            <form onSubmit={formik.handleSubmit} noValidate>
                <FormTextField
                    name={'password'}
                    label={'Password'}
                    required
                    className={classes.textField}
                    formik={formik}
                    endIconType={EndIconType.password}
                />

                <div className={classes.validationContainer}>
                    <div className={classes.validationItem}>
                        {formik.values.password?.length >= minPasswordLength ? (
                            <ApproveIcon />
                        ) : (
                            <RejectIcon />
                        )}
                        At least 12 characters long but 14 or more is better.
                    </div>
                    <div className={classes.validationItem}>
                        {formik.values.password.match(passwordRegex) ? (
                            <ApproveIcon />
                        ) : (
                            <RejectIcon />
                        )}
                        A combination of 1 uppercase letter minimum, 1 lowercase
                        letter minimum, 1 number and 1 special character
                        minimum.
                    </div>
                    <div className={classes.validationItem}>
                        <ApproveIcon /> Not a word that can be found in a
                        dictionary or the name of a person, character, product,
                        or organization.
                    </div>
                </div>

                <FormTextField
                    name={'repeatPassword'}
                    label={'Repeat Password'}
                    required
                    className={classes.textField}
                    formik={formik}
                    endIconType={EndIconType.password}
                />
                <CheckboxField
                    text={
                        'I have read and accept Terms of use and Privacy Policy'
                    }
                    name={'acceptPolicy'}
                    formik={formik}
                />

                <Box
                    display={'flex'}
                    flexDirection={'column'}
                    alignItems={'flex-end'}
                    pt={3}
                    rowGap={5}>
                    <Button
                        variant={'contained'}
                        className={classes.button}
                        type="submit"
                        disabled={
                            !formik.isValid || !formik.values.acceptPolicy
                        }>
                        Save
                    </Button>
                </Box>
            </form>
        </div>
    );
}
