import React from 'react';
import BaseModal from './BaseModal';
import AddNotes from '../features/CreateContact/AddNotes';
import { Attachment } from '../features/ViewLandownersDetails/LandPartnerActivity';
import { TreeCardAvatar } from '../features/ViewLandownersDetails/LandPartnerRelationship/TreeCard';
import { PhoneType } from './FormUI/PhoneNumberField';
import { CommunicationContainer } from './NotesField/NotesField';

export interface Note {
    id: string;
    content: string;
    fromUser: FromUser;
    createdAt: string;
    updatedAt: string;
    attachments?: Attachment[];
    communicationContainer: CommunicationContainer;
    pinned: boolean;
}

export interface FromUser {
    id: string;
    firstName: string;
    lastName?: string;
    primaryEmail?: string;
    profilePhoto?: ProfilePhoto;
}

export interface ProfilePhoto {
    id: string;
    fileName?: string;
    fileType: string;
    profilePhoto: string;
}

export interface ContactNotesData {
    id?: string;
    firstName: string;
    lastName: string;
    phoneNumber?: PhoneType[];
    organization?: string;
    avatar?: TreeCardAvatar;
}

interface OwnProps {
    isShowing: boolean;
    onClose: () => void;
    id?: string;
    contact: ContactNotesData;
    avatar?: TreeCardAvatar;
}

export default function AddNotesModal({
    isShowing,
    onClose,
    id,
    contact,
}: OwnProps) {
    const headerText = `Notes: ${contact.firstName} ${contact.lastName}`;
    return (
        <BaseModal
            isShowing={isShowing}
            onClose={onClose}
            header={headerText}
            height={'large'}
            size={'smallMedium'}>
            <AddNotes id={id} />
        </BaseModal>
    );
}
