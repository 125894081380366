import React from 'react';

import { Stack, Typography } from '@mui/material';

import LeaseRenewals from './LeaseRenewals';
import RecentUploads from './RecentUploads';

import StubDummyComponent from '../../reusable/StubDummyComponent';
import DashboardCharts from './DashboardCharts';

export default function ViewDashboard() {
    return (
        <Stack rowGap={4} paddingLeft={2} paddingRight={2}>
            {/*<Typography variant={'font16'} fontWeight={'bold'}>*/}
            {/*    Payments Overview*/}
            {/*</Typography>*/}
            {/*<PaymentsOverview />*/}
            {/*<Typography variant={'font16'} fontWeight={'bold'}>*/}
            {/*    Upcoming Events*/}
            {/*</Typography>*/}
            {/*<UpcomingEvents />*/}
            <DashboardCharts />
            <Typography variant={'font16'} fontWeight={'bold'}>
                Lease Renewals
            </Typography>
            <LeaseRenewals />
            <Typography variant={'font16'} fontWeight={'bold'}>
                Recent Uploads
            </Typography>
            <RecentUploads />
            <StubDummyComponent
                title={
                    'This feature will be available once Payment is integrated!'
                }
            />
            <StubDummyComponent
                title={
                    'This feature will be available once Payment is integrated!'
                }
            />
            {/*<Typography variant={'font16'} fontWeight={'bold'}>*/}
            {/*    Marketing*/}
            {/*</Typography>*/}
            {/*<Marketing />*/}
        </Stack>
    );
}
