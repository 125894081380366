import React from 'react';

import { Box, Grid } from '@mui/material';
import MessageList from './MessageList';
import NotesImage from '../../assets/images/oaken_notes.png';

import { useGetActivitiesListQuery } from '../../api/landpartners/activityAPI';
import Loader from '../../design/BaseLoader';
import { useParams } from 'react-router-dom';
import EmptyList from '../../reusable/EmptyList';
import { dateTimeConverterFromUTC } from '../../utils/dateFormat';

export interface ActivityCard {
    id: string;
    organizationId: string;
    landPartnerId: string;
    from: string;
    subject: string;
    createdBy: string;
    createdAt: string;
    type: ActivityVariants;
}

export interface ActivityPost {
    id: string;
    organizationId: string;
    landPartnerId: string;
    subject: string;
    location: string;
    eventDate: string;
    guests: string[];
    body: string;
    from: string;
    to: string;
    createdBy: string;
    createdAt: string;
    type: ActivityVariants;
    comments: Comments[];
    attachments: Attachment[];
}

export interface Comments {
    id: string;
    landPartnerId: string;
    comment: string;
    from: string;
    createdAt: string;
    attachments: Attachment[];
}

export interface Attachment {
    id: string;
    name: string;
    size: number;
    date: string;
    link: string;
}

export type ActivityVariants = 'EMAIL' | 'VOICE' | 'NOTE' | 'EVENT';
export default function LandPartnerActivity() {
    const { id } = useParams();
    const { data, isLoading, isFetching } = useGetActivitiesListQuery({
        id,
    });

    if (!data?.length) {
        return (
            <EmptyList image={NotesImage} title={'No Activities Available'} />
        );
    }

    if (isFetching || isLoading) {
        return (
            <Grid
                container
                sx={{ height: '100vh' }}
                alignItems={'center'}
                justifyContent={'center'}
                flexDirection={'column'}>
                <Loader />
            </Grid>
        );
    }
    return (
        <Box bgcolor={'white'}>
            <MessageList activities={sortByDate(data)} />
        </Box>
    );
}

export function sortByDate(activity: ActivityCard[]): ActivityCard[] {
    const activityCopy = [...activity];

    return activityCopy.sort((a, b) => {
        return (
            new Date(dateTimeConverterFromUTC(b.createdAt)).getTime() -
            new Date(dateTimeConverterFromUTC(a.createdAt)).getTime()
        );
    });
}
