import { OAKENApi } from '../OAKENApi';
import { ENDPOINTS } from '../endpoints';
import { HTTP_METHOD } from '../auth/http';
import { SharedLease } from '../../pages/SharedData/TemporaryAccess/Lease/SharedLeasesShortTable';
import { SharedPayment } from '../../pages/SharedData/TemporaryAccess/Payment/SharedPaymentsAllTable';
import { DocumentResponseItem } from '../../reusable/DocumentsList/DocumentsList';

export const sharedDataTempAccessAPI = OAKENApi.injectEndpoints({
    endpoints: (builder) => ({
        getSharedGrowerTmp: builder.query({
            query: (payload) => {
                return {
                    url:
                        process.env.REACT_APP_USER_API_HOST +
                        ENDPOINTS.sharedData.temporary.grower,
                    method: HTTP_METHOD.GET,
                    headers: {
                        'X-Share-Data-Token': payload.token,
                    },
                    params: payload.params,
                };
            },
        }),
        getSharedGrowerPreview: builder.query({
            query: () => {
                return {
                    url:
                        process.env.REACT_APP_USER_API_HOST +
                        ENDPOINTS.sharedData.preview.grower,
                    method: HTTP_METHOD.GET,
                };
            },
        }),
        getDashboardTmp: builder.query({
            query: (payload) => {
                return {
                    url:
                        process.env.REACT_APP_LEASE_API_HOST +
                        ENDPOINTS.sharedData.temporary.dashboard,
                    method: HTTP_METHOD.GET,
                    headers: {
                        'X-Share-Data-Token': payload.token,
                    },
                    params: payload.params,
                };
            },
        }),
        getDashboardPreview: builder.query({
            query: (params) => {
                return {
                    url:
                        process.env.REACT_APP_LEASE_API_HOST +
                        ENDPOINTS.sharedData.preview.dashboard,
                    method: HTTP_METHOD.GET,
                    params: params,
                };
            },
        }),
        getSharedLeasesTmp: builder.query({
            query: (payload) => {
                return {
                    url:
                        process.env.REACT_APP_LEASE_API_HOST +
                        ENDPOINTS.sharedData.temporary.leases,
                    method: HTTP_METHOD.GET,
                    headers: {
                        'X-Share-Data-Token': payload.token,
                    },
                    params: payload.params,
                };
            },
            transformResponse: (
                data: SharedLease[],
                meta,
            ): { data: SharedLease[]; totalCount: number } => {
                const xTotalCount =
                    meta?.response?.headers.get('X-Total-Count');
                return { data, totalCount: xTotalCount ? +xTotalCount : 0 };
            },
        }),
        getSharedLeasesPreview: builder.query({
            query: (params) => {
                return {
                    url:
                        process.env.REACT_APP_LEASE_API_HOST +
                        ENDPOINTS.sharedData.preview.leases,
                    method: HTTP_METHOD.GET,
                    params: params,
                };
            },
            transformResponse: (
                data: SharedLease[],
                meta,
            ): { data: SharedLease[]; totalCount: number } => {
                const xTotalCount =
                    meta?.response?.headers.get('X-Total-Count');
                return { data, totalCount: xTotalCount ? +xTotalCount : 0 };
            },
        }),
        getSharedPaymentsTmp: builder.query({
            query: (payload) => {
                return {
                    url:
                        process.env.REACT_APP_PAYMENT_API_HOST +
                        ENDPOINTS.sharedData.temporary.payments,
                    method: HTTP_METHOD.GET,
                    headers: {
                        'X-Share-Data-Token': payload.token,
                    },
                    params: payload.params,
                };
            },
            transformResponse: (
                data: SharedPayment[],
                meta,
            ): { data: SharedPayment[]; totalCount: number } => {
                const xTotalCount =
                    meta?.response?.headers.get('X-Total-Count');
                return { data, totalCount: xTotalCount ? +xTotalCount : 0 };
            },
        }),
        getSharedPaymentsPreview: builder.query({
            query: (params) => {
                return {
                    url:
                        process.env.REACT_APP_PAYMENT_API_HOST +
                        ENDPOINTS.sharedData.preview.payments,
                    method: HTTP_METHOD.GET,
                    params: params,
                };
            },
            transformResponse: (
                data: SharedPayment[],
                meta,
            ): { data: SharedPayment[]; totalCount: number } => {
                const xTotalCount =
                    meta?.response?.headers.get('X-Total-Count');
                return { data, totalCount: xTotalCount ? +xTotalCount : 0 };
            },
        }),
        getSharedDocumentsTmp: builder.query({
            query: (payload) => {
                return {
                    url:
                        process.env.REACT_APP_USER_API_HOST +
                        ENDPOINTS.sharedData.temporary.documents.root,
                    method: HTTP_METHOD.GET,
                    headers: {
                        'X-Share-Data-Token': payload.token,
                    },
                    params: payload.params,
                };
            },
            transformResponse: (
                data: DocumentResponseItem[],
                meta,
            ): { data: DocumentResponseItem[]; totalCount: number } => {
                const xTotalCount =
                    meta?.response?.headers.get('X-Total-Count');
                return { data, totalCount: xTotalCount ? +xTotalCount : 0 };
            },
        }),
        getSharedDocumentsPreview: builder.query({
            query: (params) => {
                return {
                    url:
                        process.env.REACT_APP_USER_API_HOST +
                        ENDPOINTS.sharedData.preview.documents,
                    method: HTTP_METHOD.GET,
                    params: params,
                };
            },
            transformResponse: (
                data: DocumentResponseItem[],
                meta,
            ): { data: DocumentResponseItem[]; totalCount: number } => {
                const xTotalCount =
                    meta?.response?.headers.get('X-Total-Count');
                return { data, totalCount: xTotalCount ? +xTotalCount : 0 };
            },
        }),
        downloadSharedDocument: builder.query({
            query: (payload) => ({
                url:
                    process.env.REACT_APP_DOCUMENT_API_HOST +
                    ENDPOINTS.sharedData.temporary.documents.download +
                    `/${payload.id}`,
                headers: {
                    'X-Share-Data-Token': payload.token,
                },
                cache: 'no-cache',
                responseHandler: async (response: Response) => {
                    const headers = response.headers.get('content-disposition');
                    const data = await response.blob();
                    return { data, headers };
                },
            }),
        }),
        downloadSharedDocumentsAsZip: builder.mutation({
            query: (payload) => {
                return {
                    url:
                        process.env.REACT_APP_DOCUMENT_API_HOST +
                        ENDPOINTS.sharedData.temporary.documents.downloadZip,
                    method: HTTP_METHOD.POST,
                    body: payload.body,
                    headers: {
                        'X-Share-Data-Token': payload.token,
                    },
                    responseHandler: async (response: Response) => {
                        return await response.blob();
                    },
                };
            },
        }),
        activateShareDataLink: builder.mutation({
            query: (payload) => {
                return {
                    url:
                        process.env.REACT_APP_USER_API_HOST +
                        ENDPOINTS.sharedData.tokens,
                    method: HTTP_METHOD.PUT,
                    body: payload.body,
                    headers: {
                        'X-Share-Data-Token': payload.token,
                    },
                };
            },
        }),
        inviteGrowerTmp: builder.mutation({
            query: (payload) => {
                return {
                    url:
                        process.env.REACT_APP_USER_API_HOST +
                        ENDPOINTS.sharedData.temporary.inviteGrower,
                    method: HTTP_METHOD.POST,
                    body: payload.body,
                    headers: {
                        'X-Share-Data-Token': payload.token,
                    },
                };
            },
        }),
    }),
});

export const {
    useGetDashboardTmpQuery,
    useGetDashboardPreviewQuery,
    useGetSharedGrowerTmpQuery,
    useGetSharedGrowerPreviewQuery,
    useGetSharedDocumentsTmpQuery,
    useGetSharedDocumentsPreviewQuery,
    useGetSharedLeasesTmpQuery,
    useLazyGetSharedLeasesTmpQuery,
    useGetSharedLeasesPreviewQuery,
    useLazyGetSharedLeasesPreviewQuery,
    useGetSharedPaymentsTmpQuery,
    useLazyGetSharedPaymentsTmpQuery,
    useGetSharedPaymentsPreviewQuery,
    useLazyGetSharedPaymentsPreviewQuery,
    useLazyDownloadSharedDocumentQuery,
    useDownloadSharedDocumentsAsZipMutation,
    useActivateShareDataLinkMutation,
    useInviteGrowerTmpMutation,
} = sharedDataTempAccessAPI;
