import React from 'react';
import { Box, Divider } from '@mui/material';
import { useParams } from 'react-router-dom';
import FormPage from '../../reusable/FormUI/FormPage';
import PreviewPhotoField from '../../reusable/FormUI/PreviewPhotoField';
import PhoneNumberField from '../../reusable/FormUI/PhoneNumberField';
import { contactDetailsConfig } from './FormConfigs/contactDetailsConfig';
import { FormConfig } from '../../design/Forms/interfaces';
import { composeValidators } from '../../utils/validation';
import { invalidFileType, tooManyFiles } from '../../utils/fileValidation';
import { useDynamicPreferredContactMethod } from '../../hooks/useDynamicPreferredContactMethod';

export default function ContactDetailsPage() {
    const { id } = useParams<{ id: string }>();
    const isEdit = Boolean(id);
    const { preferredContactMethodList } = useDynamicPreferredContactMethod();

    return (
        <Box display={'flex'} flexDirection={'column'} rowGap={2}>
            <FormPage
                formConfig={contactDetailsConfig({
                    isEdit,
                    preferredContactMethodOptions: preferredContactMethodList,
                })}
            />
            <PhoneNumberField fieldName="contactNumbers" />
            <Divider orientation={'horizontal'} variant={'fullWidth'} />
            <PreviewPhotoField
                formConfig={PhotoUploadConfig}
                photoFieldName={'contactAvatar'}
            />
        </Box>
    );
}

const PhotoUploadConfig: FormConfig[] = [
    {
        formField: {
            scheme: 'full',
            name: 'contactAvatar',
            type: 'fileupload',
            label: 'Upload Profile Photo',
            validation: composeValidators(
                tooManyFiles(1),
                invalidFileType([
                    'image/png',
                    'image/jpg',
                    'image/jpeg',
                    'image/bmp',
                ]),
            ),
        },
        renderProps: {
            filesAccepted: ['.png', '.jpg', '.jpeg', '.bmp'],
            placeholder: 'Or select from your computer',
            helperText: 'Drag photo here',
            multipleFiles: false,
            isDisabled: false,
        },
    },
];
