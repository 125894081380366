import { Box } from '@mui/material';
import { FormConfig } from '../../design/Forms/interfaces';
import {
    composeValidators,
    isNumber,
    notMoreThan,
    required,
} from '../../utils/validation';
import FormPage from '../../reusable/FormUI/FormPage';
import CropShareArrayForm from '../CropShareArrayForm';
import { useEffect, useState } from 'react';
import { useForm, useFormState } from 'react-final-form';

const cropDeliveryList = [
    { id: 'CROP_DELIVERY', name: 'Crop Delivery' },
    { id: 'PROCEEDS_FROM_SALE', name: 'Proceeds from sale' },
];
const whereCropDeliveryList = [
    { id: 'GROWER_BIN', name: 'Grower Bin' },
    { id: 'LP_BIN', name: 'Landowner Bin' },
    { id: 'THIRD_PARTY', name: 'Third Party' },
];
const doSplitVary = [
    { id: 'YES', name: 'Yes' },
    { id: 'NO', name: 'No' },
];

export default function CropSplit() {
    const form = useForm();
    const { change } = form;
    const { values } = useFormState();
    const [isSplitVary, setIsSplitVary] = useState<undefined | string>(
        undefined,
    );

    const isCropDelivery =
        values?.cropDeliveryType &&
        values?.cropDeliveryType === 'CROP_DELIVERY';

    useEffect(() => {
        if (values?.doSplitVary === 'NO') {
            setIsSplitVary('NO');
            change('cropShareDistributions', [
                {
                    crop: 'ALL',
                    growerSharePercent: 50,
                    landownerSharePercent: 50,
                },
            ]);
        } else if (values?.doSplitVary === 'YES') {
            setIsSplitVary('YES');
        }
    }, [values?.doSplitVary, change]);

    const handleGrowerShareChange = (value: string) => {
        const landownerValue = isNaN(+value)
            ? 0
            : 100 - Math.min(Math.max(+value, 0), 100);
        change(
            `cropShareDistributions[0].landownerSharePercent`,
            landownerValue,
        );
    };
    const handleLandownerShareChange = (value: string) => {
        const growerValue = isNaN(+value)
            ? 0
            : 100 - Math.min(Math.max(+value, 0), 100);
        change(`cropShareDistributions[0].growerSharePercent`, growerValue);
    };
    return (
        <Box display={'flex'} flexDirection={'column'} rowGap={2}>
            <FormPage formConfig={formCropConfig({ isCropDelivery })} />
            {isSplitVary === 'NO' && (
                <FormPage
                    formConfig={varyFormConfig({
                        handleGrowerShareChange,
                        handleLandownerShareChange,
                    })}
                />
            )}
            {isSplitVary === 'YES' && <CropShareArrayForm />}
        </Box>
    );
}

export const varyFormConfig = ({
    handleGrowerShareChange,
    handleLandownerShareChange,
}: {
    handleGrowerShareChange: (value: string) => void;
    handleLandownerShareChange: (value: string) => void;
}): FormConfig[] => [
    {
        formField: {
            scheme: 'half',
            name: 'cropShareDistributions[0].growerSharePercent',
            type: 'input',
            label: 'Grower Share',
            validation: composeValidators(required, isNumber, notMoreThan(100)),
            onChange: (e) => handleGrowerShareChange(e),
        },
        renderProps: {
            isDisabled: false,
            size: 'small',
            fieldType: 'number',
            postfix: '%',
        },
    },
    {
        formField: {
            scheme: 'half',
            name: 'cropShareDistributions[0].landownerSharePercent',
            type: 'input',
            label: 'Landowner Share',
            validation: composeValidators(required, isNumber, notMoreThan(100)),
            onChange: (e) => handleLandownerShareChange(e),
        },
        renderProps: {
            isDisabled: false,
            size: 'small',
            fieldType: 'number',
            postfix: '%',
        },
    },
];
export const formCropConfig = ({
    isCropDelivery,
}: {
    isCropDelivery?: boolean;
}): FormConfig[] => [
    {
        formField: {
            scheme: 'full',
            name: 'cropDeliveryType',
            type: 'radio',
            label: 'How should the crop be delivered?',
            isRequired: true,
            validation: composeValidators(required),
        },
        renderProps: {
            options: cropDeliveryList,
            isDisabled: false,
            size: 'third',
        },
    },
    {
        formField: {
            scheme: 'full',
            name: 'whereCropDeliveryType',
            type: 'radio',
            label: 'Where should we deliver the crop?',
            isRequired: true,
            validation: composeValidators(required),
            isHidden: !isCropDelivery,
        },
        renderProps: {
            options: whereCropDeliveryList,
            isDisabled: false,
            size: 'third',
        },
    },
    {
        formField: {
            scheme: 'full',
            name: 'doSplitVary',
            type: 'radio',
            label: 'Do share splits vary by crop?',
            isRequired: true,
            validation: composeValidators(required),
        },
        renderProps: {
            options: doSplitVary,
            isDisabled: false,
            size: 'third',
        },
    },
];
