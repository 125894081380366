import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material/styles/createTheme';

const useStyles = makeStyles<Theme>()(() => ({
    landPartnersWrapper: {
        padding: '8px 16px',
    },
}));

export default useStyles;
