import { Typography, Button } from '@mui/material';
import * as yup from 'yup';
import React from 'react';
import { useFormik } from 'formik';

import { ReactComponent as ApproveIcon } from '../../assets/icons/approve_icon.svg';
import { ReactComponent as RejectIcon } from '../../assets/icons/reject_icon.svg';

import { useSetPasswordMutation } from '../../api/users/guestAPI';
import { emailRegex, passwordRegex } from '../../constant/regex';
import useStyles from '../SignIn/Forms/LogIn.styles';
import theme from '../../styles/theme';
import { useNavigate } from 'react-router-dom';
import customToastWithAlert from '../../design/CustomToastWithAlert';
import FormTextField, { EndIconType } from '../../design/FormUI/FormTextField';
interface ResetPasswordFormProps {
    password: string;
    email: string;
    repeatPassword: string;
}
const INITIAL_FORM_STATE = {
    email: '',
    password: '',
    repeatPassword: '',
};
const minPasswordLength = 12;

export const FORM_VALIDATION = yup.object({
    email: yup
        .string()
        .matches(emailRegex, '* Please provide a correct email address')
        .required('* This field is required'),
    password: yup
        .string()
        .min(12, '* Password must have at least 12 characters')
        .matches(passwordRegex, '* Please provide a correct password')
        .required('* This field is required'),
    repeatPassword: yup
        .string()
        .oneOf([yup.ref('password')], '* Password must match')
        .required('* This field is required'),
});

export default function SetPasswordForm() {
    const { classes } = useStyles(theme);
    const [submitReset] = useSetPasswordMutation();
    const navigate = useNavigate();

    const onSubmit = (values: ResetPasswordFormProps) => {
        submitReset(values)
            .unwrap()
            .then(() => navigate(`/guests/login`))
            .catch((error) => {
                return customToastWithAlert({
                    type: 'error',
                    message: error?.data?.description || 'Something went wrong',
                });
            });
    };
    const formik = useFormik({
        enableReinitialize: true,
        validateOnChange: true,
        initialValues: { ...INITIAL_FORM_STATE },
        validationSchema: FORM_VALIDATION,
        onSubmit,
    });

    return (
        <div className={classes.formWrapper}>
            <Typography
                className={classes.title}
                variant="font32"
                fontWeight="regular">
                Set your password
            </Typography>
            <Typography className={classes.details}></Typography>
            <form onSubmit={formik.handleSubmit} noValidate>
                <FormTextField
                    name={'email'}
                    label={'Email Address'}
                    required
                    className={classes.textField}
                    formik={formik}
                />
                <FormTextField
                    name={'password'}
                    label={'Password'}
                    required
                    className={classes.textField}
                    formik={formik}
                    endIconType={EndIconType.password}
                />

                <div className={classes.validationContainer}>
                    <div className={classes.validationItem}>
                        {formik.values.password?.length >= minPasswordLength ? (
                            <ApproveIcon />
                        ) : (
                            <RejectIcon />
                        )}
                        At least 12 characters long but 14 or more is better.
                    </div>
                    <div className={classes.validationItem}>
                        {formik.values.password.match(passwordRegex) ? (
                            <ApproveIcon />
                        ) : (
                            <RejectIcon />
                        )}
                        A combination of 1 uppercase letter minimum, 1 lowercase
                        letter minimum, 1 number and 1 special character
                        minimum.
                    </div>
                    <div className={classes.validationItem}>
                        <ApproveIcon /> Not a word that can be found in a
                        dictionary or the name of a person, character, product,
                        or organization.
                    </div>
                </div>

                <FormTextField
                    name={'repeatPassword'}
                    label={'Repeat Password'}
                    required
                    className={classes.textField}
                    formik={formik}
                    endIconType={EndIconType.password}
                />

                <div className={classes.bottomContainer}>
                    <Button className={classes.button} type="submit">
                        Set Password
                    </Button>
                </div>
            </form>
        </div>
    );
}
