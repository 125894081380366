import React, { useState } from 'react';
import { Switch } from '@mui/material';

export interface Switcher {
    onChange: (value: boolean) => void;
    value?: boolean;
    name?: string;
    isDisabled?: boolean;
}

export default function SwitcherField({
    onChange,
    value,
    isDisabled,
    name,
}: Switcher) {
    const [isChecked, setChecked] = useState(value || false);

    const handleChange = () => {
        const newValue = !isChecked;
        setChecked(newValue);
        onChange(newValue);
    };

    return (
        <Switch
            id={name}
            disabled={isDisabled}
            checked={isChecked}
            onChange={handleChange}
        />
    );
}
