import { SharedWith } from './useDocumentsTable';
import { Box, Typography, Link } from '@mui/material';

import PATHS from '../../../router/paths';
import React from 'react';
import InfoTooltip from '../../../design/Tooltip/InfoTooltip';

interface OwnProps {
    data: SharedWith[];
}

export default function SharedWithCell({ data }: OwnProps) {
    const nameArray = data?.map((i) => (
        <Link
            key={i.id}
            color={'inherit'}
            underline={'hover'}
            href={`/${PATHS.landpartners}/${PATHS.allProfiles}/${i.id}`}>
            {`${i.firstName} ${i.lastName}`}
        </Link>
    ));
    function LinkList() {
        return (
            <Box
                display={'flex'}
                flexDirection={'column'}
                rowGap={1}
                flexWrap={'wrap'}
                maxHeight={400}>
                {nameArray.map((i, index) => (
                    <Typography key={index} color={'text.neutralWhite'}>
                        {i}
                    </Typography>
                ))}
            </Box>
        );
    }
    return (
        <Box display={'flex'} flexDirection={'column'} rowGap={1}>
            <Typography color={'text.link'}>{nameArray?.[0]}</Typography>
            {nameArray && nameArray.length > 1 && (
                <Box display={'flex'} gap={1} alignItems={'center'}>
                    <span>{`+${nameArray.length - 1}`}</span>
                    <InfoTooltip text={<LinkList />} />
                </Box>
            )}
        </Box>
    );
}
