import { Box, CircularProgress, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import theme from '../../styles/theme';
import { useGetOneLandPartnerQuery } from '../../api/landpartners/landPartnersAPI';
import UserAvatar from '../../design/Avatar/UserAvatar';
import React from 'react';
import useAvatar from '../../hooks/useAvatar';
import InfoWithLabel from '../../reusable/InfoWithLabel';
import { phoneTypeMapper } from '../CreateLandPartner/PreviewLandPartner';
import { phoneMaskForString } from '../../utils/fieldMasks';
import { ReactComponent as PhoneIcon } from '../../assets/icons/phone_icon.svg';
import { ReactComponent as EmailIcon } from '../../assets/icons/communications_icon.svg';
import { ReactComponent as LocationIcon } from '../../assets/icons/pin_location_icon.svg';
import LinkText from '../../design/Fields/LinkText';
interface OwnProps {
    id: string;
}
export default function AgentCard({ id }: OwnProps) {
    const { data, isLoading, isFetching } = useGetOneLandPartnerQuery(id);
    const { classes } = useStyles();
    const { avatarUrl: landPartnerLogo } = useAvatar(
        data?.landPartnerLogo?.companyLogo,
        data?.landPartnerLogo?.fileType,
    );
    if (isLoading || isFetching) {
        return <CircularProgress />;
    }
    if (!data) return null;
    const address =
        data?.billingAddress &&
        [
            data.billingAddress.address1,
            data.billingAddress.address2,
            data.billingAddress.city,
            data.billingAddress.state,
            data.billingAddress.zipCode,
        ]
            .filter(Boolean)
            .join(', ');
    return (
        <Box className={classes.container}>
            <Box display={'flex'} justifyContent={'space-between'}>
                <Box display={'flex'} columnGap={1}>
                    <UserAvatar name={data?.name} avatar={landPartnerLogo} />
                    <Box display={'flex'} flexDirection={'column'}>
                        <Typography
                            variant={'font20'}
                            fontWeight="medium"
                            color="text.secondary">
                            {data?.name}
                        </Typography>
                        <Typography
                            color={'text.secondaryContrast'}
                            variant={'font12'}>
                            Agent
                        </Typography>
                    </Box>
                </Box>
            </Box>

            <InfoWithLabel
                icon={EmailIcon}
                text={
                    data.email ? (
                        <LinkText
                            to={`mailto:${data.email}`}
                            text={data.email}
                            fontWeight={'medium'}
                        />
                    ) : (
                        'N/A'
                    )
                }
            />
            {data.phoneNumber?.map((phone) => {
                return (
                    <InfoWithLabel
                        key={phone.type}
                        icon={PhoneIcon}
                        text={`${phoneTypeMapper[phone?.type]}
                                        : ${phoneMaskForString(phone?.number)}`}
                    />
                );
            })}
            <InfoWithLabel icon={LocationIcon} text={address || 'N/A'} />
        </Box>
    );
}
const useStyles = makeStyles()(() => ({
    container: {
        width: '50%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: 150,
        backgroundColor: theme.palette.darkTurq.darkTurqTint1,
        gap: theme.spacing(1),
        padding: theme.spacing(2),
    },
}));
