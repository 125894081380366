import React from 'react';
import LeaseDetails from '../../features/ViewLeaseDetails/LeaseDetails';
import LeaseMap from '../../features/ViewLeaseDetails/LeaseMap';
import LeasePayments from '../../features/ViewLeaseDetails/LeasePayments';
import BasicTabsPanel from '../../design/TabsPanel/BasicTabsPanel';
import PATHS from '../../router/paths';
import LeaseHistory from '../../features/ViewLeaseHistory/LeaseHistory';
import { useParams } from 'react-router-dom';
import { useGetOneLeaseQuery } from '../../api/leases/leaseAPI';
import { Lease } from './TableConfig';
import useBreadcrumbs from '../../hooks/useBreadcrumbs';
import Breadcrumbs from '../../design/Breadcrumbs/Breadcrumbs';

interface TabsConfigProps {
    data: Lease;
    isLoading: boolean;
}
const tabsConfig = ({ data, isLoading }: TabsConfigProps) => [
    {
        label: 'Detail',
        content: <LeaseDetails data={data} isLoading={isLoading} />,
        value: 0,
        to: '',
    },
    {
        label: 'Fields',
        content: <LeaseMap />,
        value: `${PATHS.fields}`,
        to: `${PATHS.fields}`,
    },
    {
        label: 'Payments',
        content: <LeasePayments />,
        value: `${PATHS.payments}`,
        to: `${PATHS.payments}`,
    },
    {
        label: 'History ',
        content: <LeaseHistory />,
        value: `${PATHS.history}`,
        to: `${PATHS.history}`,
    },
];

const routes = {
    '/': 'Home',
    'landPortfolio/manage/': 'Leases',
    'landPortfolio/manage/:id': '',
    'landPortfolio/manage/:id/fields': 'Fields',
    'landPortfolio/manage/:id/payments': 'Payments',
    'landPortfolio/manage/:id/history': 'History',
};
export default function LeaseDetailPage() {
    const { id } = useParams();
    const { isFetching, isLoading, data } = useGetOneLeaseQuery(id);
    const breadcrumbs = useBreadcrumbs({
        dynamicName: data?.leaseName?.toUpperCase() || '',
        routes,
    });
    return (
        <div>
            <Breadcrumbs collapsed={false} links={breadcrumbs} />
            <BasicTabsPanel
                tabItems={tabsConfig({
                    data,
                    isLoading: isLoading || isFetching,
                })}
            />
        </div>
    );
}
