import { Box, Typography } from '@mui/material';

interface OwnProps {
    title?: string;
    message?: string;
    image?: string;
}
export default function EmptyList({ title, message, image }: OwnProps) {
    return (
        <Box
            display={'flex'}
            p={4}
            boxSizing={'border-box'}
            columnGap={3}
            justifyContent={'center'}
            alignItems={'center'}
            width={'100%'}>
            <img src={image} alt="No Activities Available" />
            <Box
                boxSizing={'border-box'}
                display={'flex'}
                flexDirection="column"
                alignItems="center"
                justifyContent="center">
                <Typography variant="font32" color="text.secondary">
                    {title}
                </Typography>
                <Typography variant="font20" color="text.secondaryContrast">
                    {message}
                </Typography>
            </Box>
        </Box>
    );
}
