import { Stack } from '@mui/material';
import AppTypography from '../../../design/AppTypography';
import PreviewValueWithTitle from './PreviewValueWithTitle';

interface OwnProps {
    relationshipStartDate?: string;
    preferredContactMethod?: string;
    primaryEmail?: string;
    secondaryEmail?: string;
    phone?: string;
    mailAddress?: string;
    billingAddress?: string;
    isPrimary?: boolean;
}

export default function PreviewColumnData({
    relationshipStartDate,
    preferredContactMethod,
    isPrimary,
    primaryEmail,
    secondaryEmail,
    phone,
    mailAddress,
    billingAddress,
}: OwnProps) {
    const partnerSince = relationshipStartDate
        ? new Date(relationshipStartDate).toLocaleString('default', {
              month: 'long',
              year: 'numeric',
          })
        : undefined;
    return (
        <Stack rowGap={3}>
            <PreviewValueWithTitle
                isHidden={!partnerSince}
                value={partnerSince}
                title={'Customer Since'}
            />
            <PreviewValueWithTitle
                isHidden={!preferredContactMethod}
                value={preferredContactMethod}
                title={'Preferred Contact'}
            />
            <Stack rowGap={2}>
                <AppTypography
                    text={isPrimary ? 'Primary Contact' : 'Secondary Contact'}
                    variant={'font14'}
                    fontWeight={'medium'}
                    color={'text.neutral9'}
                />
                <AppTypography
                    isHidden={!primaryEmail}
                    text={primaryEmail}
                    variant={'font12'}
                    fontWeight={'medium'}
                />
                <AppTypography
                    isHidden={!secondaryEmail}
                    text={secondaryEmail}
                    variant={'font12'}
                    fontWeight={'medium'}
                />
                <AppTypography
                    isHidden={!phone}
                    text={phone}
                    variant={'font12'}
                    fontWeight={'medium'}
                />

                <AppTypography
                    isHidden={!mailAddress}
                    text={mailAddress}
                    variant={'font12'}
                    fontWeight={'medium'}
                />
                <AppTypography
                    isHidden={!billingAddress}
                    text={billingAddress}
                    variant={'font12'}
                    fontWeight={'medium'}
                />
            </Stack>
        </Stack>
    );
}
