import React from 'react';
import { FieldArray } from 'react-final-form-arrays';
import { Box, Button, Divider } from '@mui/material';
import { useForm } from 'react-final-form';
import MembersForm from './MembersForm';

export default function MembersFormPage() {
    const { mutators } = useForm();

    return (
        <Box display={'flex'} flexDirection={'column'} gap={2}>
            <FieldArray name="primaryContact.childContacts">
                {({ fields }) => {
                    return fields.map((name, index) => (
                        <div key={name}>
                            <MembersForm name={name} />
                            <Button onClick={() => fields.remove(index)}>
                                Remove Contact
                            </Button>
                            <Divider />
                        </div>
                    ));
                }}
            </FieldArray>
            <Button
                type="button"
                onClick={() => mutators.push('primaryContact.childContacts')}>
                Add Another Contact
            </Button>
        </Box>
    );
}
