import { Box, Button, Divider, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import theme from '../../../../styles/theme';
import React from 'react';
import { FeaturesOption } from './OrganizationSubscriptionPage';
import FeatureSwitch from './FeatureSwitch';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { mapTypeIntegrations, returnFeaturesMap } from './utils';
import { useFormState } from 'react-final-form';

export interface SubscriptionOrganization {
    type: SubscriptionType;
    features: FeaturesOption;
    isMostPopular?: boolean;
    isActive?: boolean;
    handleActivePlan: (
        type: SubscriptionType,
        name?: string,
        value?: boolean,
    ) => void;
}

export type SubscriptionType = 'BASIC' | 'PREMIUM' | 'ENTERPRISE';

const planNamesMap = {
    BASIC: 'Basic',
    PREMIUM: 'Premium',
    ENTERPRISE: 'Enterprise',
};
const planCountOfSeats = {
    BASIC: '5 – 50 SEATS',
    PREMIUM: '50 – 100 SEATS',
    ENTERPRISE: '100+ SEATS',
};
export default function SubscriptionCard({
    type,
    features,
    handleActivePlan,
    isActive,
    isMostPopular,
}: SubscriptionOrganization) {
    const { classes } = useStyles();
    const { values } = useFormState();
    return (
        <Box
            bgcolor={
                isActive
                    ? theme.palette.backgroundB.backgroundB3
                    : theme.palette.backgroundB.backgroundB1
            }
            className={classes.cardWrapper}
            onClick={() => handleActivePlan(type)}>
            <Box display={'flex'} flexDirection={'column'} rowGap={2}>
                <Typography
                    variant={'font32'}
                    color={'text.darkTurqTint8'}
                    fontWeight={'medium'}>
                    {planNamesMap[type]}
                </Typography>
                <Typography
                    variant={'font12'}
                    color={'text.secondaryContrast'}
                    fontWeight={'bold'}>
                    {planCountOfSeats[type]}
                </Typography>
                <Button
                    sx={{ width: '100%' }}
                    variant={'contained'}
                    onClick={() => handleActivePlan(type)}
                    endIcon={<KeyboardArrowDownIcon />}>
                    Select {planNamesMap[type]}
                </Button>
                {renderFeatureSwitches({
                    features,
                    type,
                    mapTypeFeatures: returnFeaturesMap(values?.type),
                    handleActivePlan,
                })}
                {renderFeatureSwitches({
                    isIntegrations: true,
                    features,
                    type,
                    mapTypeFeatures: mapTypeIntegrations,
                    handleActivePlan,
                })}
            </Box>
            {isMostPopular && (
                <Box className={classes.adLabel}>
                    <Typography
                        textOverflow={'ellipsis'}
                        textTransform={'uppercase'}
                        variant={'font14'}
                        color={'text.darkTurqTint8'}
                        fontWeight={'medium'}>
                        Most Popular
                    </Typography>
                </Box>
            )}
        </Box>
    );
}

const useStyles = makeStyles()(() => ({
    cardWrapper: {
        position: 'relative',
        width: 250,
        minHeight: 400,
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(2),
        borderRadius: theme.spacing(1.5),
    },
    adLabel: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        transform: `translate(50%, 50%)`,
        zIndex: 100,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: theme.palette.limeGreen.limeGreenTint8,
        padding: theme.spacing(0.5, 1),
    },
}));

interface RenderSwitch {
    features: FeaturesOption;
    type: SubscriptionType;
    mapTypeFeatures: Record<SubscriptionType, string[]>;
    handleActivePlan: (
        type: SubscriptionType,
        name?: string,
        value?: boolean,
    ) => void;
    isIntegrations?: boolean;
}

function renderFeatureSwitches({
    isIntegrations,
    features,
    type,
    handleActivePlan,
    mapTypeFeatures,
}: RenderSwitch) {
    return (
        <>
            {isIntegrations && (
                <Divider variant={'fullWidth'} orientation={'horizontal'} />
            )}
            {Object?.keys(features)
                .filter((feature) => mapTypeFeatures[type].includes(feature))
                .map((feature) => (
                    <FeatureSwitch
                        key={feature}
                        feature={feature}
                        onChange={(event) =>
                            handleActivePlan(
                                type,
                                event.target.name,
                                event.target.checked,
                            )
                        }
                        checked={features[feature]}
                    />
                ))}
        </>
    );
}
