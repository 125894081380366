import React, { MouseEvent, useState } from 'react';
import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    IconButton,
} from '@mui/material';
import { ReactComponent as NotificationIcon } from '../../assets/icons/notification_icon.svg';
import { ReactComponent as CloseIcon } from '../../assets/icons/close_icon.svg';
import PATHS from '../../router/paths';
import { makeStyles } from 'tss-react/mui';
import theme from '../../styles/theme';
import BasePopover from '../../reusable/BasePopover';
import { useNavigate } from 'react-router-dom';
import NotificationCard, { Notification } from './NotificationCard';
import {
    useDeleteNotificationMutation,
    useLazyGetInfiniteNotificationsListQuery,
    useReadNotificationsMutation,
    useSnoozeNotificationMutation,
} from '../../api/notifications/notificationAPI';
import { useInfiniteScroller } from '../../hooks/useInfiniteScroller';

interface OwnProps {
    cards: Notification[];
}

export default function NotificationPopover({ cards }: OwnProps) {
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const open = Boolean(anchorEl);
    const id = open ? 'notification-popover' : undefined;
    const [snoozeNotification] = useSnoozeNotificationMutation();
    const [deleteNotification] = useDeleteNotificationMutation();
    const [readNotifications] = useReadNotificationsMutation();

    const { InfiniteItems, rootRef, infinityData, setInfinityData } =
        useInfiniteScroller<Notification>({
            apiHook: useLazyGetInfiniteNotificationsListQuery,
            options: {
                sort: `createdAt,desc`,
                page: 0,
                size: 5,
            },
            renderItem: (item: Notification) => {
                return (
                    <NotificationCard
                        key={item.id}
                        onSnooze={() => handleSnooze(item.id)}
                        onDelete={() => handleDelete(item.id)}
                        {...item}
                    />
                );
            },
        });
    const handleDelete = (id: string) => {
        deleteNotification(id);
        setInfinityData(infinityData.filter((item) => item.id !== id));
    };
    const handleSnooze = (id: string) => {
        snoozeNotification(id);
        setInfinityData((prevState) =>
            prevState.map((item) => {
                if (item.id === id) {
                    return { ...item, snoozed: true };
                }
                return item;
            }),
        );
    };
    const handlePopoverOpen = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handlePopoverClose = () => {
        if (cards.length > 0) {
            const ids = cards
                .filter((card) => !card.read)
                .map((card) => card.id);
            if (ids.length > 0) {
                readNotifications({ notificationIds: ids });
            }
        }
        setAnchorEl(null);
    };
    const { classes } = useStyles();
    return (
        <>
            <IconButton
                aria-describedby={id}
                onClick={handlePopoverOpen}
                className={classes.iconButton}>
                <NotificationIcon />
            </IconButton>
            <BasePopover
                open={open}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}>
                <Card className={classes.root}>
                    <CardHeader
                        title="Notifications"
                        action={
                            <IconButton
                                onClick={handlePopoverClose}
                                size="large">
                                <CloseIcon className={classes.closeButton} />
                            </IconButton>
                        }
                        className={classes.header}
                    />
                    <Box
                        display={'flex'}
                        pt={2}
                        pr={2}
                        width={'100%'}
                        justifyContent={'flex-end'}
                        alignItems={'center'}>
                        <Button
                            variant={'text'}
                            onClick={() => {
                                navigate(`/${PATHS.notifications}`);
                                handlePopoverClose();
                            }}>
                            See All Notifications
                        </Button>
                    </Box>
                    <CardContent
                        ref={rootRef}
                        id="notificationsContent"
                        className={classes.content}>
                        {InfiniteItems()}
                    </CardContent>
                </Card>
            </BasePopover>
        </>
    );
}

const useStyles = makeStyles()(() => ({
    root: {
        width: 600,
        borderBottomRightRadius: 8,
        borderBottomLeftRadius: 8,
        color: theme.palette.darkTurq.darkTurqTint2,
        padding: theme.spacing(1),
    },
    header: {
        backgroundColor: theme.palette.primary.main,
        display: 'flex',
        justifyContent: 'space-between',
    },
    closeButton: {
        padding: 0,
        cursor: 'pointer',
        border: 'none',
        fill: theme.palette.neutralWhite,
        '& svg': {
            width: 24,
            height: 24,
        },
    },
    content: {
        height: 400,
        overflowY: 'auto',
    },
    iconButton: {
        '&:hover': {
            backgroundColor: theme.palette.brandLightTurq,
            padding: theme.spacing(1),
            borderRadius: 12,
            fill: theme.palette.neutralWhite,
            transition: 'all 0.2s ease-in-out',
            '& svg': {
                cursor: 'pointer',
                fill: theme.palette.neutralWhite,
            },
        },
        '& svg': {
            cursor: 'pointer',
        },
    },
}));
