import { formPrimaryContactConfig } from './formPrimaryContactConfig';
import { formMembersConfig } from './formMembersConfig';
import FormPage from '../../reusable/FormUI/FormPage';
import PhoneNumberField from '../../reusable/FormUI/PhoneNumberField';
import theme from '../../styles/theme';
import SearchExistingContact, {
    FetchedSearchData,
} from '../../reusable/SearchExistingContact/SearchExistingContact';
import { Box, IconButton, Typography } from '@mui/material';
import { useForm, useFormState } from 'react-final-form';
import React, { useEffect, useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { useDynamicPreferredContactMethod } from '../../hooks/useDynamicPreferredContactMethod';

interface OwnProps {
    isEdit: boolean;
}

export default function PrimaryContactFormPage({ isEdit }: OwnProps) {
    const form = useForm();
    const { values } = useFormState();
    const [value, setValue] = useState('');
    const [isOptionSelected, setIsOptionSelected] = useState(false);

    const { preferredContactMethodList } = useDynamicPreferredContactMethod(
        isEdit ? 'primaryContact' : 'primaryContact.newPrimaryContact',
    );

    function handleSelect(option: FetchedSearchData) {
        form.change('primaryContact.newPrimaryContact', null);
        form.change('draftName', option);
        setValue(`${option.firstName} ${option.lastName}`);
        form.change('primaryContact.existingPrimaryContactId', option.id);
        setIsOptionSelected(true);
    }

    function handleReset() {
        setIsOptionSelected(false);
        setValue('');
        form.change('draftName', null);
    }

    useEffect(() => {
        setValue(
            values.draftName
                ? `${values.draftName.firstName} ${values.draftName.lastName}`
                : '',
        );
        setIsOptionSelected(!!values.draftName);
    }, []);

    useEffect(() => {
        if (values?.primaryContact?.newPrimaryContact) {
            form.change('draftName', null);
            form.change('primaryContact.existingPrimaryContactId', null);
        }
    }, [value, values.primaryContact?.newPrimaryContact]);

    return (
        <Box display="flex" flexDirection="column" rowGap={2}>
            {!isEdit && (
                <Box
                    display="flex"
                    flexDirection="column"
                    width="100%"
                    borderRadius={1}
                    sx={{
                        backgroundColor: theme.palette.limeGreen.limeGreenTint3,
                        padding: theme.spacing(2),
                    }}>
                    <Box width="50%">
                        <Typography fontWeight="regular">
                            Search for an existing contact
                        </Typography>
                        <Box display={'flex'} alignItems={'center'}>
                            <SearchExistingContact
                                onSelect={handleSelect}
                                setValue={setValue}
                                value={value}
                                isDisabled={isOptionSelected}
                            />
                            {isOptionSelected && (
                                <IconButton onClick={handleReset}>
                                    <CloseIcon />
                                </IconButton>
                            )}
                        </Box>
                    </Box>
                </Box>
            )}

            {!isOptionSelected && (
                <>
                    <FormPage
                        formConfig={
                            isEdit
                                ? formPrimaryContactConfig(
                                      preferredContactMethodList,
                                  )
                                : formMembersConfig(
                                      'primaryContact.newPrimaryContact',
                                      preferredContactMethodList,
                                  )
                        }
                    />
                    <PhoneNumberField
                        fieldName={
                            isEdit
                                ? 'primaryContact?.contactNumbers'
                                : 'primaryContact.newPrimaryContact?.contactNumbers'
                        }
                    />
                </>
            )}
        </Box>
    );
}
