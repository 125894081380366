import PreviewFormWrapper from '../../../../features/CreateLeaseForm/PreviewFormWrapper';
import GenericTable, {
    Table,
} from '../../../../design/GenericTable/GenericTable';
import React from 'react';
import { Box, ThemeProvider, Typography } from '@mui/material';
import { Features } from './OrganizationSubscriptionPage';
import { subscriptionTableTheme } from '../../../../styles/subscriptionTableTheme';
import StatusTag from '../../../../design/StatusTag/StatusTag';
import { messagesFieldLabels } from './utils';

interface OwnProps {
    handleModify: (page: number) => void;
    data: Features;
}

export default function PreviewSubscriptions({ handleModify, data }: OwnProps) {
    return (
        <PreviewFormWrapper
            label={'Subscription'}
            onClick={handleModify}
            pageNumber={1}>
            <ThemeProvider theme={subscriptionTableTheme}>
                <GenericTable
                    data={[data]}
                    totalCount={1}
                    tableConfig={tableSubscriptionsConfig}
                />
            </ThemeProvider>
        </PreviewFormWrapper>
    );
}
const planCountOfSeats = {
    BASIC: '50',
    PREMIUM: '100',
    ENTERPRISE: '100+',
};

const tableSubscriptionsConfig: Table<Features | undefined> = {
    columns: [
        {
            header: {
                label: 'Status',
            },
            cellRender: () => (
                <Typography
                    variant={'font12'}
                    color={'text.neutral9'}
                    fontWeight={'medium'}>
                    Active
                </Typography>
            ),
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
        },
        {
            header: {
                label: 'Plan',
            },
            cellRender: (tableData) => (
                <Typography
                    variant={'font12'}
                    color={'text.neutral9'}
                    fontWeight={'medium'}>
                    {tableData?.type}
                </Typography>
            ),
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
        },
        {
            header: {
                label: 'Max Users',
            },
            cellRender: (tableData) => (
                <Typography
                    variant={'font12'}
                    color={'text.neutral9'}
                    fontWeight={'medium'}>
                    {tableData?.type && planCountOfSeats[tableData?.type]}
                </Typography>
            ),
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
        },
        {
            header: {
                label: 'Options',
            },
            cellRender: (tableData) => {
                return (
                    tableData?.features &&
                    Object.keys(tableData?.features).map((feature) => (
                        <Box
                            key={feature}
                            display={'flex'}
                            flexDirection={'column'}
                            justifyContent={'space-between'}>
                            <Typography
                                variant={'font12'}
                                color={'text.neutral9'}>
                                {tableData?.features[feature] &&
                                    messagesFieldLabels[feature]}
                            </Typography>
                        </Box>
                    ))
                );
            },
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
        },
        {
            header: {
                label: 'Status',
            },
            cellRender: () => {
                return <StatusTag status={'smallSuccess'} text={'Active'} />;
            },
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
        },
    ],
    multiselect: false,
    pageable: false,
    expandable: false,
};
