import FormScreenWrapper from './FormScreenWrapper';
import { Box } from '@mui/material';
import AppTypography from '../../design/AppTypography';
import FormPage from '../../reusable/FormUI/FormPage';
import { FormConfig } from '../../design/Forms/interfaces';
import HazardousForm, { Guideline } from './HazardousForm/HazardousForm';
import HazardousPreview from './HazardousForm/HazardousPreview';
import { useState } from 'react';

export default function EnvironmentalConcernsForm() {
    const [guidelines, setGuidelines] = useState<Guideline[]>([]);
    return (
        <FormScreenWrapper title="Environmental Concerns">
            <Box display={'flex'} flexDirection={'column'} rowGap={2}>
                <AppTypography
                    variant="font16"
                    fontWeight={'medium'}
                    text={
                        'What is the plan for managing hazardous materials during a disaster?'
                    }
                    color={'text.neutral9'}
                />
                <HazardousForm
                    guidelines={guidelines}
                    setGuidelines={setGuidelines}
                />
                {guidelines.map((guideline, index) => (
                    <HazardousPreview key={index} guideline={guideline} />
                ))}
                <AppTypography
                    variant="font16"
                    fontWeight={'medium'}
                    text={
                        'How will wastewater or chemical runoff be handled in case of flooding?'
                    }
                    color={'text.neutral9'}
                />{' '}
                <FormPage formConfig={wasteWaterFormConfig} />
            </Box>
        </FormScreenWrapper>
    );
}
const wasteWaterFormConfig: FormConfig[] = [
    {
        formField: {
            scheme: 'twoThird',
            name: 'environmentalConcerns.wastewaterPlan',
            type: 'textarea',
            label: '',
        },
        renderProps: {
            resize: 'none',
            isDisabled: false,
            minRows: 3,
        },
    },
];
