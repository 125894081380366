import { Box, Typography } from '@mui/material';
import InfoWithLabel from '../../../reusable/InfoWithLabel';
import LinkText from '../../../design/Fields/LinkText';

import React from 'react';
import { ReactComponent as EmailIcon } from '../../../assets/icons/communications_icon.svg';
import { ReactComponent as PhoneIcon } from '../../../assets/icons/phone_icon.svg';
import { ReactComponent as BuildingIcon } from '../../../assets/icons/building_icon.svg';
export interface PrimaryAdditionalInfoProps {
    primaryEmail: string;
    address?: string;
}

export default function PrimaryAdditionalInfo({
    primaryEmail,
    address,
}: PrimaryAdditionalInfoProps) {
    return (
        <Box display={'flex'} flexDirection={'column'} rowGap={1}>
            <Typography
                variant={'font12'}
                color={'text.neutral9'}
                fontWeight={'medium'}>
                Primary
            </Typography>
            <InfoWithLabel
                icon={EmailIcon}
                text={
                    primaryEmail ? (
                        <LinkText
                            to={`mailto:${primaryEmail}`}
                            text={primaryEmail}
                            fontWeight={'medium'}
                        />
                    ) : (
                        'N/A'
                    )
                }
            />
            <InfoWithLabel icon={PhoneIcon} text={`N/A`} />
            <Typography
                variant={'font12'}
                color={'text.neutral9'}
                fontWeight={'medium'}>
                Billing
            </Typography>
            <InfoWithLabel icon={BuildingIcon} text={address} />
        </Box>
    );
}
