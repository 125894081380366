import { useRef } from 'react';

const useScrollToElement = () => {
    const scrollToRef = useRef<HTMLDivElement | null>(null);

    const scrollTo = () => {
        if (scrollToRef.current) {
            scrollToRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return { scrollToRef, scrollTo };
};

export default useScrollToElement;
