import React, { MouseEvent, useEffect, useState } from 'react';
import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    IconButton,
    OutlinedInput,
} from '@mui/material';
import { ReactComponent as SearchIcon } from '../../assets/icons/search_icon.svg';
import { ReactComponent as CloseIcon } from '../../assets/icons/close_icon.svg';
import { makeStyles } from 'tss-react/mui';
import theme from '../../styles/theme';
import BasePopover from '../../reusable/BasePopover';
import { useLazySearchResultQuery } from '../../api/search/searchAPI';
import SearchContent from './SearchContent';
import { debounce } from 'lodash';
import { SearchResult, SearchType } from './SearchCard';
import SearchToolbar from './SearchToolbar';
import { SearchContext } from './SearchContext';

export default function SearchPopover() {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [query, setQuery] = useState<string>('');
    const [data, setData] = useState<SearchResult[]>([]);
    const [type, setType] = useState<SearchType>('ALL');
    const [view, setView] = useState<SearchResult[]>([]);

    const open = Boolean(anchorEl);
    const id = open ? 'search-popover' : undefined;

    const handlePopoverOpen = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handlePopoverClose = () => {
        setAnchorEl(null);
    };
    const { classes } = useStyles();

    const [getItems, { isFetching, isLoading, data: fetchedData }] =
        useLazySearchResultQuery();
    const debouncedSearch = debounce((value: string) => {
        if (value.length === 0) {
            setQuery('');
            setData([]);
        }
        if (value.length >= 3) {
            setQuery(value);
            setData([]);
            getItems(value);
        }
    }, 300);
    useEffect(() => {
        setData(fetchedData);
    }, [fetchedData]);

    return (
        <SearchContext.Provider
            value={{ data, setData, view, setView, type, setType }}>
            <>
                <Button
                    aria-describedby={id}
                    onClick={handlePopoverOpen}
                    endIcon={<SearchIcon />}
                    className={classes.iconButton}>
                    Search
                </Button>
                <BasePopover
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handlePopoverClose}>
                    <Card className={classes.root}>
                        <CardHeader
                            title="Search"
                            action={
                                <IconButton
                                    onClick={handlePopoverClose}
                                    size="large">
                                    <CloseIcon
                                        className={classes.closeButton}
                                    />
                                </IconButton>
                            }
                            className={classes.header}
                        />
                        <Box
                            display={'flex'}
                            p={2}
                            width={'100%'}
                            justifyContent={'flex-end'}
                            alignItems={'center'}>
                            <OutlinedInput
                                autoFocus={true}
                                className={classes.searchInput}
                                placeholder={'Search'}
                                endAdornment={<SearchIcon />}
                                onChange={(e) =>
                                    debouncedSearch(e.target.value)
                                }
                            />
                        </Box>
                        <CardContent>
                            <SearchToolbar resultLength={fetchedData?.length} />
                            <SearchContent
                                query={query}
                                isLoading={isFetching || isLoading}
                            />
                        </CardContent>
                    </Card>
                </BasePopover>
            </>
        </SearchContext.Provider>
    );
}

const useStyles = makeStyles()(() => ({
    root: {
        width: 600,
        borderBottomRightRadius: 8,
        borderBottomLeftRadius: 8,
        color: theme.palette.darkTurq.darkTurqTint2,
    },
    header: {
        backgroundColor: theme.palette.primary.main,
        display: 'flex',
        justifyContent: 'space-between',
    },
    closeButton: {
        padding: 0,
        cursor: 'pointer',
        border: 'none',
        fill: theme.palette.neutralWhite,
        '& svg': {
            width: 24,
            height: 24,
        },
    },
    searchInput: {
        width: '100%',
        '& .MuiInputBase-input': {
            padding: theme.spacing(2),
        },
        paddingBottom: theme.spacing(0),
    },
    iconButton: {
        fontWeight: 500,
        padding: theme.spacing(2, 3),
        color: theme.palette.limeGreen.limeGreenShade7,
        '&:hover': {
            fontWeight: 500,
            padding: theme.spacing(2, 3),
            color: theme.palette.neutralWhite,
            backgroundColor: theme.palette.brandLightTurq,
            borderRadius: 12,
            fill: theme.palette.neutralWhite,
            transition: 'all 0.2s ease-in-out',
            '& svg': {
                cursor: 'pointer',
                fill: theme.palette.neutralWhite,
            },
        },
        '&:active': {
            fontWeight: 500,
            padding: theme.spacing(2, 3),
            color: theme.palette.limeGreen.limeGreenShade7,
            backgroundColor: theme.palette.brandLightTurq,
            borderRadius: 12,
            fill: theme.palette.neutralWhite,
        },
        '& svg': {
            cursor: 'pointer',
            fill: theme.palette.limeGreen.limeGreenShade7,
        },
    },
}));
