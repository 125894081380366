import React, { useEffect, useState } from 'react';
import BaseModal from '../reusable/BaseModal';
import DraggableList from '../reusable/DraggableList/DraggableList';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import theme from '../styles/theme';
import useHandleSubmitRTK from '../hooks/useHandleSubmit';
import {
    ColumnConfig,
    useAddConfigMutation,
    useUpdateConfigMutation,
    ViewType,
} from '../api/users/customizeConfigAPI';

interface OwnProps {
    isShowing: boolean;
    onClose: () => void;
    config: ColumnConfig[];
    viewType: ViewType;
    isFetched: boolean;
}

export default function CustomizeViewModal({
    isShowing,
    onClose,
    config,
    viewType,
    isFetched,
}: OwnProps) {
    const [items, setItems] = useState<ColumnConfig[]>(config);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        setItems(config);
    }, [config]);

    function handleClose() {
        onClose();
        setError(null);
        setItems(config);
    }

    const { handleSubmit: updateConfig, isLoading: isUpdating } =
        useHandleSubmitRTK({
            useRtkHook: useUpdateConfigMutation,
            onSuccess: () => handleClose(),
            successMessage: 'Config updated successfully',
        });

    const { handleSubmit: addConfig, isLoading: isAdding } = useHandleSubmitRTK(
        {
            useRtkHook: useAddConfigMutation,
            onSuccess: () => handleClose(),
            successMessage: 'Config updated successfully',
        },
    );

    function handleSubmit() {
        const isAllItemsHidden = items.every((item) => item.isHidden);
        if (isAllItemsHidden) {
            setError('At least one column must be visible');
            return;
        }
        setError(null);
        const value = {
            viewType: viewType,
            viewSettings: { table: [...items] },
        };

        if (!isFetched) {
            addConfig(value);
            return;
        }
        updateConfig(value);
    }

    return (
        <BaseModal
            header={`Customize View`}
            isShowing={isShowing}
            size={'medium'}
            onClose={handleClose}>
            <Box width={'100%'}>
                <DraggableList items={items} setItems={setItems} />
                {error && (
                    <Box
                        display={'flex'}
                        width={'100%'}
                        p={2}
                        justifyContent={'center'}>
                        <Typography variant={'font12'} color={'text.error'}>
                            {error}
                        </Typography>
                    </Box>
                )}
                <Box
                    display={'flex'}
                    width={'100%'}
                    p={4}
                    justifyContent={'flex-end'}>
                    <Button
                        sx={{
                            padding: theme.spacing(2, 2),
                        }}
                        disabled={isUpdating || isAdding}
                        variant="contained"
                        onClick={handleSubmit}>
                        {!isUpdating || !isAdding ? (
                            'Update'
                        ) : (
                            <CircularProgress
                                size={30}
                                color="primary"
                                style={{ position: 'absolute' }}
                            />
                        )}
                    </Button>
                </Box>
            </Box>
        </BaseModal>
    );
}
