import React from 'react';
import { Box, Grid } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import CopiedReadFieldList from '../../design/CopiedReadField/CopiedReadFieldList';
import PATHS from '../../router/paths';
import { typeOfAdvocacy } from '../CreateLandPartner/LandPartnerWizard';
import { PhoneType } from '../../reusable/FormUI/PhoneNumberField';
import LinkText from '../../design/Fields/LinkText';
import { Option } from '../CreateLeaseForm/WizardPageConfigs/leaseUploadConfig';

export interface SocialMedia {
    linkedin?: string;
    twitter?: string;
    facebook?: string;
    instagram?: string;
}

export interface KeyDates {
    birthDate?: string;
    anniversary?: string;
    relationshipStartDate?: string;
    dateOfDeath?: string;
}

export type Status = 'ACTIVE' | 'INACTIVE' | 'PROSPECT' | 'SUSPENDED';

export interface LandPartnerUpdate {
    type: 'LAND_PARTNER' | 'GROWER';
    organization: Option;
    landPartnerLogo: LandPartnerLogo;
    email: string;
    id: string;
    name: string;
    taxId: string;
    status: Status;
    primaryContact: PrimaryContactUpdate;
    phoneNumber: PhoneType[];
    socialMedia: SocialMedia;
    billingAddress: Address;
    relationshipStartDate: string;
    associatedAcres?: number;
}

export interface PrimaryContactUpdate {
    id: string;
    landPartnerDetails: LandPartnerDetails;
    parentContactId: string;
    primaryContact: boolean;
    firstName: string;
    lastName: string;
    contactRole: string;
    primaryEmail: string;
    secondaryEmail: string;
    contactNumbers: PhoneType[];
    preferredContactMethod: string;
    relationship: string;
    relationshipDescription: string;
    powerOfAttorney: boolean;
    keyDates: KeyDates;
    age: number;
    advocate: typeOfAdvocacy[];
    addresses: Address[];
    primaryAddress: Address;
    socialMedia: SocialMedia;
    agriData: AgriData;
    avatar: Avatar;
}

export interface LandPartnerDetails {
    id: string;
    name: string;
    type: string;
    primaryContactFirstName: string;
    primaryContactLastName: string;
    address1: string;
    zipCode: string;
    phoneNumber: string;
    email: string;
    status: string;
}

export interface Address {
    id: string;
    organizationId: string;
    type: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    zipCode: string;
}

export interface AgriData {
    associatedFarms: AssociatedFarm[];
    associatedAcres: number;
    primaryLeaseType: string;
    primaryCrop: string;
}

export interface AssociatedFarm {
    id: string;
    name: string;
}

export interface Avatar {
    id: string;
    fileName: string;
    fileType: string;
    contactAvatar: string;
    contactId: string;
}

export interface LandPartnerLogo {
    id: string;
    fileName: string;
    fileType: string;
    companyLogo: string;
    contactId: string;
}

interface OwnProps {
    landPartnerData?: LandPartnerUpdate;
}
type OrganizationType = 'LAND_PARTNER' | 'GROWER';

export default function LandPartnerDetails({ landPartnerData }: OwnProps) {
    const { classes } = useStyles();

    const FIELDS = [
        {
            title: `${typeMapper[landPartnerData?.type as OrganizationType]} Name`,
            value: landPartnerData?.name,
        },
        {
            title: `Type`,
            value: typeMapper[landPartnerData?.type as OrganizationType],
        },
        {
            title: 'Status',
            value: landPartnerData?.status,
        },
        {
            title: 'Primary Contact First Name',
            value: landPartnerData?.primaryContact?.firstName ? (
                <LinkText
                    to={`/${PATHS.landpartners}/${PATHS.allProfiles}/${landPartnerData?.primaryContact?.id}`}
                    text={landPartnerData?.primaryContact?.firstName}
                    fontWeight={'normal'}
                />
            ) : (
                'N/A'
            ),
        },
        {
            title: 'Primary Contact Last Name',
            value: landPartnerData?.primaryContact?.lastName ? (
                <LinkText
                    to={`/${PATHS.landpartners}/${PATHS.allProfiles}/${landPartnerData?.primaryContact?.id}`}
                    text={landPartnerData?.primaryContact?.lastName}
                    fontWeight={'normal'}
                />
            ) : (
                'N/A'
            ),
        },
        {
            title: 'Tax ID',
            value: landPartnerData?.taxId || 'N/A',
        },
        {
            title: 'Associated Acres',
            value: landPartnerData?.associatedAcres || 'N/A',
        },
        {
            title: 'Relationship Start Date',
            value: landPartnerData?.relationshipStartDate || 'N/A',
        },
        {
            title: 'Address 1',
            value: landPartnerData?.billingAddress?.address1 || 'N/A',
        },
        {
            title: 'Address 2',
            value: landPartnerData?.billingAddress?.address2 || 'N/A',
        },
        {
            title: 'City',
            value: landPartnerData?.billingAddress?.city || 'N/A',
        },
        {
            title: 'State',
            value: landPartnerData?.billingAddress?.state || 'N/A',
        },

        {
            title: 'Zip Code',
            value: landPartnerData?.billingAddress?.zipCode || 'N/A',
        },
        {
            title: 'Phone Number',
            value: 'N/A',
        },
        {
            title: 'Primary Email Address',
            value: landPartnerData?.primaryContact?.primaryEmail ? (
                <LinkText
                    to={`mailto:${landPartnerData?.primaryContact?.primaryEmail}`}
                    text={landPartnerData?.primaryContact?.primaryEmail}
                    fontWeight={'normal'}
                />
            ) : (
                'N/A'
            ),
            text: landPartnerData?.primaryContact?.primaryEmail || 'N/A',
            hasCopied: true,
        },
        {
            title: 'LinkedIn',
            value: landPartnerData?.socialMedia?.linkedin || 'N/A',
        },
        {
            title: 'Twitter',
            value: landPartnerData?.socialMedia?.twitter || 'N/A',
        },
        {
            title: 'Facebook',
            value: landPartnerData?.socialMedia?.facebook || 'N/A',
        },
        {
            title: 'Instagram',
            value: landPartnerData?.socialMedia?.instagram || 'N/A',
        },
        {
            title: 'Primary Contact Birth Date',
            value:
                (landPartnerData?.primaryContact?.keyDates?.birthDate &&
                    new Date(
                        landPartnerData?.primaryContact?.keyDates?.birthDate,
                    ).toLocaleDateString()) ||
                'N/A',
        },
        {
            title: 'Primary Contact Relationship Start Date',
            value:
                landPartnerData?.primaryContact?.keyDates
                    ?.relationshipStartDate || 'N/A',
        },
    ];

    return (
        <Box
            display={'flex'}
            flexDirection={'column'}
            width={'100%'}
            alignItems={'flex-end'}>
            <Grid className={classes.viewWrapper}>
                <CopiedReadFieldList items={FIELDS} />
            </Grid>
        </Box>
    );
}

const useStyles = makeStyles()((theme) => ({
    containerWrapper: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '50vh',
        backgroundColor: theme.palette.neutralWhite,
    },

    viewWrapper: {
        display: 'flex',
        height: '80%',
        flexWrap: 'wrap',
        width: '100%',
        columnGap: theme.spacing(2),
        padding: theme.spacing(2),
        backgroundColor: theme.palette.neutralWhite,
    },
}));
const typeMapper: Record<OrganizationType, string> = {
    LAND_PARTNER: 'Land Partner',
    GROWER: 'Grower',
};
