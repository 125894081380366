import { Box, Button } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { SearchContext } from './SearchContext';

export default function SearchSorting() {
    const { view, setView } = useContext(SearchContext);
    const SORT_KEY = 'updatedAt';
    const [sortDirection, setSortDirection] = useState(
        'desc' as 'asc' | 'desc',
    );
    useEffect(() => {
        if (view && view.length > 0) {
            const sortedData = [...view].sort((a, b) =>
                sortDirection === 'asc'
                    ? ascSorting(a[SORT_KEY], b[SORT_KEY])
                    : descSorting(a[SORT_KEY], b[SORT_KEY]),
            );
            setView(sortedData);
        }
    }, [sortDirection]);
    return (
        <Box display={'flex'} columnGap={2}>
            <Button
                sx={{
                    fontSize: 12,
                    height: 24,
                }}
                onClick={() => setSortDirection('desc')}
                variant={sortDirection === 'desc' ? 'contained' : 'text'}>
                Newest
            </Button>
            <Button
                sx={{
                    fontSize: 12,
                    height: 24,
                }}
                variant={sortDirection === 'asc' ? 'contained' : 'text'}
                onClick={() => setSortDirection('asc')}>
                Oldest
            </Button>
        </Box>
    );
}

const ascSorting = (a: string | number, b: string | number) => {
    if (a < b) return -1;
    if (a > b) return 1;
    return 0;
};
const descSorting = (a: string | number, b: string | number) => {
    if (a < b) return 1;
    if (a > b) return -1;
    return 0;
};
