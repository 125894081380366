import FormScreenWrapper from './FormScreenWrapper';
import { Box } from '@mui/material';
import AppTypography from '../../design/AppTypography';
import FormPage from '../../reusable/FormUI/FormPage';
import { FormConfig } from '../../design/Forms/interfaces';
import { composeValidators } from '../../utils/validation';
import {
    invalidFileType,
    tooLargeFile,
    tooManyFiles,
} from '../../utils/fileValidation';
import { MAX_FILE_SIZE } from '../../constants';

export default function InsuranceFinancialPreparednessForm() {
    return (
        <FormScreenWrapper title="Evacuation and Safety">
            <Box display={'flex'} flexDirection={'column'} rowGap={2}>
                <AppTypography
                    variant="font16"
                    fontWeight={'medium'}
                    text={
                        'What types of insurance coverage do you have for property?'
                    }
                    color={'text.neutral9'}
                />
                <FormPage formConfig={propertyCoverageFormConfig} />
                <AppTypography
                    variant="font16"
                    fontWeight={'medium'}
                    text={'How will you document damages for insurance claims?'}
                    color={'text.neutral9'}
                />{' '}
                <FormPage formConfig={damageDocPlanFormConfig} />
                <AppTypography
                    variant="font16"
                    fontWeight={'medium'}
                    text={
                        'Where are insurance policies and financial records stored?'
                    }
                    color={'text.neutral9'}
                />
                <FormPage formConfig={financialRecordsConfig} />
            </Box>
        </FormScreenWrapper>
    );
}
const propertyCoverageFormConfig: FormConfig[] = [
    {
        formField: {
            scheme: 'twoThird',
            name: 'insurancePreparedness.propertyCoverage',
            type: 'textarea',
            label: '',
        },
        renderProps: {
            resize: 'none',
            isDisabled: false,
            minRows: 3,
        },
    },
    {
        formField: {
            scheme: 'third',
            name: 'propertyCoverageFile',
            type: 'fileupload',
            validation: composeValidators(
                tooLargeFile(MAX_FILE_SIZE),
                tooManyFiles(1),
                invalidFileType([
                    'image/png',
                    'image/jpg',
                    'image/jpeg',
                    'image/bmp',
                    'application/pdf',
                ]),
            ),
        },
        renderProps: {
            filesAccepted: ['.png', '.jpg', '.jpeg', '.bmp', '.pdf'],
            placeholder: 'Drag a document here',
            helperText: 'Attach Document',
            multipleFiles: false,
            isDisabled: false,
        },
    },
];
const damageDocPlanFormConfig: FormConfig[] = [
    {
        formField: {
            scheme: 'twoThird',
            name: 'insurancePreparedness.damageDocumentationPlan',
            type: 'textarea',
            label: '',
        },
        renderProps: {
            resize: 'none',
            isDisabled: false,
            minRows: 3,
        },
    },
    {
        formField: {
            scheme: 'third',
            name: 'damageDocumentationPlanFile',
            type: 'fileupload',
            validation: composeValidators(
                tooLargeFile(MAX_FILE_SIZE),
                tooManyFiles(1),
                invalidFileType([
                    'image/png',
                    'image/jpg',
                    'image/jpeg',
                    'image/bmp',
                    'application/pdf',
                ]),
            ),
        },
        renderProps: {
            filesAccepted: ['.png', '.jpg', '.jpeg', '.bmp', '.pdf'],
            placeholder: 'Drag a document here',
            helperText: 'Attach Document',
            multipleFiles: false,
            isDisabled: false,
        },
    },
];
const financialRecordsConfig: FormConfig[] = [
    {
        formField: {
            scheme: 'full',
            name: 'evacuationSafety.financialRecordsLocation',
            type: 'textarea',
            label: '',
        },
        renderProps: {
            resize: 'none',
            isDisabled: false,
            minRows: 3,
        },
    },
];
