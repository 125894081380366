import React from 'react';
import { Box } from '@mui/material';

import TwoColumnLayout from '../../../layouts/TwoColumnLayout';
import AsideGrowerTab from './Grower/AsideGrowerTab';
import SharedDataDashboard from './Dashboard/SharedDataDashboard';
import SharedDataAllTabs from './SharedDataAllTabs';

export default function LandPartnerPortalDashboard() {
    const renderLeftComponent = () => (
        <>
            <SharedDataDashboard />
            <SharedDataAllTabs />
        </>
    );

    const renderRightComponent = () => (
        <AsideGrowerTab hideSwitchGrower={false} />
    );

    return (
        <Box bgcolor={'backgroundB.backgroundB2'}>
            <TwoColumnLayout
                type={'fiveSixths'}
                leftComponent={renderLeftComponent()}
                rightComponent={renderRightComponent()}
            />
        </Box>
    );
}
