import { HTTP_METHOD } from '../auth/http';
import { ENDPOINTS } from '../endpoints';
import { OAKENApi } from '../OAKENApi';
import { getListProvidesTags } from '../generic';
import { DOCUMENTS } from '../tags';

export const documentsAPI = OAKENApi.injectEndpoints({
    endpoints: (builder) => ({
        downloadByIdsAsZip: builder.mutation({
            query: (payload) => {
                return {
                    url:
                        process.env.REACT_APP_DOCUMENT_API_HOST +
                        ENDPOINTS.documents.download.zip,
                    method: HTTP_METHOD.POST,
                    body: payload,
                    responseHandler: async (response: Response) => {
                        return await response.blob();
                    },
                };
            },
        }),
        documentsListByParentID: builder.query({
            query: ({ parentId, ...params }) => {
                return {
                    url:
                        process.env.REACT_APP_DOCUMENT_API_HOST +
                        ENDPOINTS.documents.root +
                        `/${parentId}`,
                    method: HTTP_METHOD.GET,
                    params,
                };
            },
            providesTags: (result) =>
                getListProvidesTags(result?.data, DOCUMENTS.GROUPS),
        }),
        documentUpload: builder.mutation({
            query: ({ documents, ...payload }) => {
                const formData = new FormData();
                formData.append('documents', documents);
                formData.append(
                    'request',
                    new Blob([JSON.stringify(payload)], {
                        type: 'application/json',
                    }),
                );

                return {
                    url:
                        process.env.REACT_APP_DOCUMENT_API_HOST +
                        ENDPOINTS.documents.upload,
                    method: HTTP_METHOD.POST,
                    body: formData,
                };
            },
            invalidatesTags: [DOCUMENTS.DOCUMENTS, DOCUMENTS.GROUPS],
        }),
        documentsUpdate: builder.mutation({
            query: (payload) => {
                return {
                    url:
                        process.env.REACT_APP_DOCUMENT_API_HOST +
                        ENDPOINTS.documents.root +
                        `/${payload.documentId}`,
                    method: HTTP_METHOD.PUT,
                    body: payload,
                };
            },
            invalidatesTags: [DOCUMENTS.GROUPS],
        }),
        downloadDocument: builder.query({
            query: (documentId) => {
                return {
                    url:
                        process.env.REACT_APP_DOCUMENT_API_HOST +
                        ENDPOINTS.documents.root +
                        `/${documentId}/download`,
                    cache: 'no-cache',
                    responseHandler: async (response: Response) => {
                        const headers = response.headers.get(
                            'content-disposition',
                        );
                        const data = await response.blob();
                        return { data, headers };
                    },
                };
            },
        }),
        shareDocument: builder.mutation({
            query: (payload) => {
                return {
                    url: `${process.env.REACT_APP_DOCUMENT_API_HOST}${ENDPOINTS.documents.shared}`,
                    method: HTTP_METHOD.POST,
                    body: payload,
                };
            },
            invalidatesTags: [DOCUMENTS.DOCUMENT],
        }),
        requestDocument: builder.mutation({
            query: (payload) => {
                return {
                    url: `${process.env.REACT_APP_DOCUMENT_API_HOST}${ENDPOINTS.documents.request}`,
                    method: HTTP_METHOD.POST,
                    body: payload,
                };
            },
            invalidatesTags: [DOCUMENTS.DOCUMENT],
        }),
        documentDataById: builder.query({
            query: ({ documentId }) => {
                return {
                    url:
                        process.env.REACT_APP_DOCUMENT_API_HOST +
                        ENDPOINTS.documents.shared +
                        `/${documentId}`,
                    method: HTTP_METHOD.GET,
                };
            },
            providesTags: [DOCUMENTS.DOCUMENT],
        }),
        revokeDocument: builder.mutation({
            query: ({ permissionId }) => {
                return {
                    url: `${process.env.REACT_APP_DOCUMENT_API_HOST}${ENDPOINTS.documents.revoke}/${permissionId}`,
                    method: HTTP_METHOD.POST,
                };
            },
            invalidatesTags: [DOCUMENTS.DOCUMENT],
        }),
        uploadRequestedDocument: builder.mutation({
            query: ({
                requestId,
                organizationId,
                documents,
                landPartnerId,
            }) => {
                const formData = new FormData();
                documents.forEach((document: File) => {
                    formData.append('documents', document);
                });
                formData.append(
                    'requestId',
                    new Blob([JSON.stringify(requestId)], {
                        type: 'application/json',
                    }),
                );
                formData.append(
                    'landPartnerId',
                    new Blob([JSON.stringify(landPartnerId)], {
                        type: 'application/json',
                    }),
                );
                formData.append(
                    'organizationId',
                    new Blob([JSON.stringify(organizationId)], {
                        type: 'application/json',
                    }),
                );
                return {
                    url: `${process.env.REACT_APP_DOCUMENT_API_HOST}${ENDPOINTS.documents.request}/upload`,
                    method: HTTP_METHOD.POST,
                    body: formData,
                };
            },
        }),
        getDocumentDataById: builder.query({
            query: ({ documentId }) => {
                return {
                    url: `${process.env.REACT_APP_DOCUMENT_API_HOST}${ENDPOINTS.documents.request}/${documentId}`,
                    method: HTTP_METHOD.GET,
                };
            },
            providesTags: [DOCUMENTS.DOCUMENT],
        }),
        requestEsign: builder.mutation({
            query: (payload) => {
                return {
                    url: `${process.env.REACT_APP_DOCUMENT_API_HOST}${ENDPOINTS.documents.esign}`,
                    method: HTTP_METHOD.POST,
                    body: payload,
                };
            },
        }),
        downloadDocumentCustomToken: builder.query({
            query: ({ documentId, token }) => {
                return {
                    url:
                        process.env.REACT_APP_DOCUMENT_API_HOST +
                        ENDPOINTS.documents.root +
                        `/${documentId}/download`,
                    cache: 'no-cache',
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    responseHandler: async (response: Response) => {
                        const headers = response.headers.get(
                            'content-disposition',
                        );
                        const data = await response.blob();
                        return { data, headers };
                    },
                };
            },
        }),
    }),
});

export const {
    useLazyDownloadDocumentCustomTokenQuery,
    useRequestEsignMutation,
    useGetDocumentDataByIdQuery,
    useUploadRequestedDocumentMutation,
    useRevokeDocumentMutation,
    useDocumentDataByIdQuery,
    useRequestDocumentMutation,
    useShareDocumentMutation,
    useLazyDownloadDocumentQuery,
    useDocumentsUpdateMutation,
    useDocumentUploadMutation,
    useDownloadByIdsAsZipMutation,
    useLazyDocumentsListByParentIDQuery,
} = documentsAPI;
