import React from 'react';
import { Modal as MUIModal } from '@mui/material';

import { makeStyles } from 'tss-react/mui';
import RemoveTreeNodeWithChildren from './RemoveTreeNodeWithChildren';
import ConfirmModal from '../../../layouts/ConfirmModal/ConfirmModal';
import { useRemoveContactFromRelationshipTreeMutation } from '../../../api/contacts/contactsAPI';
import { useParams } from 'react-router-dom';

interface OwnProps {
    isShowing: boolean;
    onClose: () => void;
    currentId: string;
    hasChildren: boolean;
}

export default function RemoveTreeNode({
    isShowing,
    currentId,
    onClose,
    hasChildren,
}: OwnProps) {
    const { classes } = useStyles();
    const [removeContactFromTree] =
        useRemoveContactFromRelationshipTreeMutation();
    const { id: landPartnerId } = useParams();
    const handleDelete = () => {
        removeContactFromTree({
            landPartnerId: landPartnerId,
            contactId: currentId,
            reassignAllChildren: false,
        });
    };
    if (hasChildren) {
        return (
            <MUIModal
                open={isShowing}
                onClose={onClose}
                className={classes.modalWrapper}>
                <RemoveTreeNodeWithChildren
                    onClose={onClose}
                    currentId={currentId}
                />
            </MUIModal>
        );
    }

    return (
        <ConfirmModal
            isShowing={isShowing}
            hide={onClose}
            title={`Please confirm you want to delete this contact?`}
            info={''}
            onSubmit={handleDelete}
        />
    );
}
const useStyles = makeStyles()(() => ({
    modalWrapper: {
        display: 'flex',
        position: 'fixed',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 1102,
    },
}));
