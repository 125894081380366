import React, { useState } from 'react';
import NotesButton from '../../../pages/AllProfiles/NotesButton';
import AddNotesModal, {
    ContactNotesData,
} from '../../../reusable/AddNotesModal';

interface OwnProps {
    notesCount?: number;
    attachmentCount?: number;
}

export default function NotesCell({
    notesCount = 0,
    attachmentCount = 0,
}: OwnProps) {
    const [openNotesModal, setOpenNotesModal] = useState(false);
    const [currentContact, setCurrentContact] =
        useState<ContactNotesData | null>(null);

    function handleModalOpen() {
        setCurrentContact(contactMockData());
        setOpenNotesModal(true);
    }

    const handleNotesClose = () => {
        setCurrentContact(null);
        setOpenNotesModal(false);
    };

    return (
        <>
            <NotesButton
                notesCount={notesCount}
                attachmentCount={attachmentCount}
                handleModalOpen={() => handleModalOpen()}
            />
            {currentContact && currentContact.id && (
                <AddNotesModal
                    id={currentContact.id}
                    contact={currentContact}
                    isShowing={openNotesModal}
                    onClose={handleNotesClose}
                />
            )}
        </>
    );
}

function contactMockData(): ContactNotesData {
    return {
        id: '1',
        firstName: 'Test First Name',
        lastName: 'Test Last Name',
        organization: 'Test Organization',
    };
}
