import { useNavigate } from 'react-router-dom';
import useHandleSubmitRTK from '../../hooks/useHandleSubmit';
import PATHS from '../../router/paths';
import ConfirmModal from '../../layouts/ConfirmModal/ConfirmModal';
import React from 'react';
import { useArchiveLeaseMutation } from '../../api/leases/leaseAPI';

interface OwnProps {
    isShowing: boolean;
    onClose: () => void;
    id?: string;
}

export default function ArchivedLease({ isShowing, id, onClose }: OwnProps) {
    const navigate = useNavigate();

    const { handleSubmit } = useHandleSubmitRTK({
        useRtkHook: useArchiveLeaseMutation,
        onSuccess: () => {
            onClose();
            navigate(`/${PATHS.landPortfolio}/${PATHS.manage}`);
        },
        successMessage: 'Lease archived successfully',
    });
    const handleDeactivateLease = () => {
        id && handleSubmit(id);
    };

    return (
        <ConfirmModal
            isShowing={isShowing}
            hide={onClose}
            title={`Please confirm you want to archived this Lease?`}
            info={''}
            onSubmit={handleDeactivateLease}
        />
    );
}
