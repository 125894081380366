import { Box, Typography } from '@mui/material';
import SharedDataGrowerInfo from './SharedDataGrowerInfo';
import useAvatar from '../../../../hooks/useAvatar';
import theme from '../../../../styles/theme';
import { ProfilePhoto } from '../../../../features/Tasks/utils';
import SwitchGrowerInfoBox from './SwitchGrowerInfoBox';
import React from 'react';

interface OwnProps {
    data: GrowerInfo;
    hideSwitchGrower?: boolean;
}

interface GrowerInfo {
    id: string;
    name: string;
    companyLogo?: CompanyLogo;
    generalManager: {
        id: string;
        firstName: string;
        lastName: string;
        phoneNumber?: string;
        primaryEmail: string;
        profilePhoto?: ProfilePhoto;
    };
}

export interface CompanyLogo {
    id: string;
    fileName: string;
    fileType: string;
    companyLogo: string;
}

export default function GrowerInfoBox({ data, hideSwitchGrower }: OwnProps) {
    const { createBlobUrl } = useAvatar();

    const growerData = {
        id: data?.id,
        growerName: data?.name,
        growerAvatar: data?.companyLogo,
        manager: {
            id: data?.generalManager.id,
            name: `${data?.generalManager.firstName} ${data?.generalManager.lastName}`,
            phoneNumber: data?.generalManager.phoneNumber,
            primaryEmail: data?.generalManager.primaryEmail,
            profilePhoto: data?.generalManager.profilePhoto,
        },
    };

    return (
        <Box
            display={'flex'}
            flexDirection={'column'}
            rowGap={1}
            p={2}
            borderRadius={2}
            bgcolor={theme.palette.neutralWhite}>
            <Typography
                variant={'font20'}
                fontWeight={'medium'}
                color={'text.secondary'}>
                {growerData.growerName}
            </Typography>
            <Box
                display={'flex'}
                alignItems={'center'}
                flexDirection={'column'}
                rowGap={1}
                width={'100%'}>
                {growerData.growerAvatar && (
                    <img
                        src={createBlobUrl(
                            growerData.growerAvatar.companyLogo,
                            growerData.growerAvatar.fileType,
                        )}
                        alt={'grower-image'}
                        width={160}
                        height={160}
                    />
                )}
                <SharedDataGrowerInfo {...growerData.manager} />
            </Box>
            {!hideSwitchGrower && (
                <Box display={'flex'} width={'100%'} flexDirection={'column'}>
                    <SwitchGrowerInfoBox
                        id={growerData.id}
                        name={growerData.growerName}
                    />
                </Box>
            )}
        </Box>
    );
}
