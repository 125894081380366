import React from 'react';
import { ReactComponent as PdfIcon } from '../assets/icons/file_pdf.svg';
import { ReactComponent as ExcelIcon } from '../assets/icons/spreadsheet_icon.svg';
import { ReactComponent as ImageIcon } from '../assets/icons/image_icon.svg';
import { extractExtensionFromFileName } from '../utils/fileHandler';

interface OwnProps {
    name: string;
}

export default function FileIconView({ name }: OwnProps) {
    const extension = extractExtensionFromFileName(name);
    switch (extension) {
        case 'pdf':
            return <PdfIcon />;
        case 'xls':
        case 'xlsx':
            return <ExcelIcon />;
        case 'png':
        case 'jpg':
        case 'jpeg':
            return <ImageIcon />;
        default:
            return <PdfIcon />;
    }
}
