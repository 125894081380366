import FormPage from '../../../../reusable/FormUI/FormPage';
import { formOrganizationConfig } from './formOrganizationConfig';
import PhoneNumberField from '../../../../reusable/FormUI/PhoneNumberField';
import React, { useEffect } from 'react';
import { useIsMount } from '../../../../hooks/useIsMount';
import { useForm } from 'react-final-form';

export function OrganizationFormPage() {
    const form = useForm();
    const isMount = useIsMount();
    const { values } = form.getState();
    useEffect(() => {
        if (isMount) return;
        if (values.type) {
            form.change('subscription', {});
        }
    }, [values.type]);
    return (
        <>
            <FormPage formConfig={formOrganizationConfig} />
            <PhoneNumberField fieldName="contactNumbers" />
        </>
    );
}
