import { HTTP_METHOD } from './http';
import { ENDPOINTS } from '../endpoints';
import { OAKENApi } from '../OAKENApi';

export const authAPI = OAKENApi.injectEndpoints({
    endpoints: (builder) => ({
        login: builder.mutation({
            query: (payload) => ({
                url: process.env.REACT_APP_USER_API_HOST + ENDPOINTS.login,
                method: HTTP_METHOD.POST,
                body: payload,
            }),
        }),
        activate: builder.mutation({
            query: (payload) => ({
                url: process.env.REACT_APP_USER_API_HOST + ENDPOINTS.activate,
                method: HTTP_METHOD.POST,
                body: payload,
            }),
        }),
        resetPassword: builder.mutation({
            query: (payload) => ({
                url:
                    process.env.REACT_APP_USER_API_HOST +
                    ENDPOINTS.account.resetPassword,
                method: HTTP_METHOD.POST,
                body: payload,
            }),
        }),
        submitReset: builder.mutation({
            query: (payload) => {
                return {
                    url:
                        process.env.REACT_APP_USER_API_HOST +
                        ENDPOINTS.account.finishResetPassword,
                    method: HTTP_METHOD.POST,
                    body: payload,
                };
            },
        }),
        validateToken: builder.mutation({
            query: ({ token }) => {
                return {
                    url:
                        process.env.REACT_APP_USER_API_HOST +
                        ENDPOINTS.validateToken,
                    method: HTTP_METHOD.POST,
                    body: { token },
                };
            },
        }),
        reInvite: builder.mutation({
            query: ({ id }) => {
                return {
                    url:
                        process.env.REACT_APP_USER_API_HOST +
                        ENDPOINTS.reInvite +
                        `/${id}`,
                    method: HTTP_METHOD.POST,
                };
            },
        }),

        changePassword: builder.mutation({
            query: (payload) => {
                return {
                    url:
                        process.env.REACT_APP_USER_API_HOST +
                        ENDPOINTS.account.changePassword,
                    method: HTTP_METHOD.POST,
                    body: payload,
                };
            },
        }),
    }),
});

export const {
    useLoginMutation,
    useReInviteMutation,
    useChangePasswordMutation,
    useValidateTokenMutation,
    useActivateMutation,
    useResetPasswordMutation,
    useSubmitResetMutation,
} = authAPI;
