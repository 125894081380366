import { Form } from 'react-final-form';
import { Button, Divider, Grid } from '@mui/material';
import SubmitButton from '../../design/Buttons/SubmitButton';
import React from 'react';
import FormPage from '../../reusable/FormUI/FormPage';
import useHandleSubmitRTK from '../../hooks/useHandleSubmit';
import { useAddPaymentInfoMutation } from '../../api/payment/paymentAPI';
import { makeStyles } from 'tss-react/mui';
import { FormConfig } from '../../design/Forms/interfaces';
import {
    composeValidators,
    isNumber,
    maxLength,
    minLength,
    required,
} from '../../utils/validation';

import { numberMask } from '../../utils/fieldMasks';
import { Option } from '../CreateLeaseForm/WizardPageConfigs/leaseUploadConfig';
import { PaymentScheduledData } from './interfaces';
import { convertDateToString } from '../../utils/dateFormat';
import { useDocumentUploadMutation } from '../../api/documents/documentsAPI';
import {
    invalidFileType,
    tooLargeFile,
    tooManyFiles,
} from '../../utils/fileValidation';
import { MAX_FILE_SIZE } from '../../constants';
import customToastWithAlert from '../../design/CustomToastWithAlert';

interface PaymentLog {
    paymentDate: Date;
    paymentMethod: string;
    amount: number;
    note: string;
    documents: File[];
}

interface OwnProps {
    id: string;
    data: PaymentScheduledData;
    handleClose: () => void;
}

export default function LogPaymentsForm({ handleClose, data, id }: OwnProps) {
    const { handleSubmit: handleUpload } = useHandleSubmitRTK({
        useRtkHook: useDocumentUploadMutation,
        onSuccess: () => {
            handleClose();
        },
    });

    const [handleSubmit, { isLoading }] = useAddPaymentInfoMutation();
    const { classes } = useStyles();

    async function handleLogPayment(value: PaymentLog) {
        try {
            const response = await handleSubmit({
                id,
                ...value,
                paymentDate: convertDateToString(value.paymentDate),
            });

            if ('data' in response && value.documents) {
                handleUpload({
                    documents: value.documents[0],
                    parentId: response?.data.id,
                    documentType: 'PAYMENT',
                    sharingAllowed: true,
                });
            }

            if ('error' in response) {
                const errorMessage =
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    response.error.data?.description || 'Something went wrong';
                customToastWithAlert({
                    type: 'error',
                    message: errorMessage,
                });
            } else {
                customToastWithAlert({
                    type: 'success',
                    message: 'Payment logged successfully',
                });
            }
        } catch {
            customToastWithAlert({
                type: 'error',
                message: 'Payment attachment upload failed',
            });
        } finally {
            handleClose();
        }
    }

    return (
        <Form
            onSubmit={handleLogPayment}
            initialValues={{
                paymentDate: new Date(),
                amount: data.amount,
            }}>
            {({ handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                    <Grid
                        container
                        columnSpacing={2}
                        rowSpacing={2}
                        className={classes.formContainer}>
                        <FormPage formConfig={formPaymentLogConfig} />
                    </Grid>
                    <Divider orientation="horizontal" />
                    <div className={classes.bottomContainer}>
                        <Button onClick={handleClose} color={'secondary'}>
                            Cancel
                        </Button>
                        <SubmitButton
                            variant={'text'}
                            isLoading={isLoading}
                            text={'Save'}
                        />
                    </div>
                </form>
            )}
        </Form>
    );
}
const useStyles = makeStyles()((theme) => ({
    formContainer: {
        width: '100%',
        backgroundColor: theme.palette.neutral.neutral1,
        padding: theme.spacing(4),
        marginLeft: theme.spacing(0),
        overflowY: 'auto',
        overflowX: 'hidden',
    },

    bottomContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        gap: '16px',
        height: 70,
        width: '100%',
        padding: theme.spacing(3),
        backgroundColor: theme.palette.neutralWhite,
        borderRadius: '0px 0px 12px 12px',
    },
}));

const paymentMethodOptions: Option[] = [
    { id: 'ACH', name: 'ACH' },
    { id: 'CHECK', name: 'Check' },
    { id: 'WIRE', name: 'Wire' },
];

export const formPaymentLogConfig: FormConfig[] = [
    {
        formField: {
            scheme: 'full',
            name: 'paymentDate',
            type: 'datepicker',
            label: 'Date',
            isRequired: true,
            validation: composeValidators(required),
        },
        renderProps: {
            isDisabled: false,
            size: 'small',
            format: 'MMM dd, yyyy',
        },
    },

    {
        formField: {
            name: 'paymentMethod',
            type: 'select',
            label: 'Payment Method',
            isRequired: true,
            validation: composeValidators(required),
        },
        renderProps: {
            placeholder: 'Select from list',
            isDisabled: false,
            size: 'small',
            options: paymentMethodOptions,
        },
    },
    {
        formField: {
            name: 'amount',
            type: 'maskedInput',
            label: 'Payment Amount',
            isRequired: true,
            validation: composeValidators(
                minLength(1),
                maxLength(256),
                isNumber,
                required,
            ),
        },
        renderProps: {
            prefix: '$',
            mask: numberMask,
            placeholder: '',
            size: 'small',
        },
    },
    {
        formField: {
            name: 'note',
            type: 'textarea',
            label: 'Notes',
            isRequired: false,
            validation: composeValidators(minLength(1), maxLength(1024)),
        },
        renderProps: {
            placeholder: '',
            resize: 'none',
            isDisabled: false,
        },
    },
    {
        formField: {
            name: 'documents',
            type: 'fileupload',
            validation: composeValidators(
                tooLargeFile(MAX_FILE_SIZE),
                tooManyFiles(10),
                invalidFileType([
                    'image/png',
                    'image/jpg',
                    'image/jpeg',
                    'image/bmp',
                    'application/pdf',
                ]),
            ),
        },
        renderProps: {
            filesAccepted: ['.png', '.jpg', '.jpeg', '.bmp', '.pdf'],
            placeholder: 'Drag a document here',
            helperText: 'Attach Document',
            multipleFiles: true,
            isDisabled: false,
        },
    },
];
