import React from 'react';
import { Box } from '@mui/material';
import theme from '../../styles/theme';
import { ReactComponent as OAKENLogo } from '../../assets/icons/logo2.svg';
import { ReactComponent as QuickBookLogo } from '../../assets/icons/intuitQuickbooks.svg';
import SyncLabel from '../../design/Labels/SyncLabel';

export default function ImportPaymentModalHeader() {
    return (
        <Box
            p={3}
            borderRadius={1}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
            bgcolor={theme.palette.lightTurq.lightTurqTint2}>
            <Box
                display={'flex'}
                alignItems={'center'}
                columnGap={3}
                justifyContent={'center'}>
                <QuickBookLogo />
                <SyncLabel />
                <OAKENLogo
                    style={{
                        width: '72px',
                        height: '30px',
                    }}
                />
            </Box>
        </Box>
    );
}
