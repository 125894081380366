import React from 'react';
import UserInfoPanel, {
    UserInfo,
} from '../../reusable/UserInfoPanel/UserInfoPanel';
import { useGetAccountQuery } from '../../api/users/accountAPI';
import { Grid, Stack } from '@mui/material';
import Loader from '../../design/BaseLoader';
import useAvatar from '../../hooks/useAvatar';
import UserProfile from './UserProfile';
import UserProfilePreferences from './UserProfilePreferences';
import BasicTabsPanel from '../../design/TabsPanel/BasicTabsPanel';

export default function UserPersonalInfo() {
    const { data, isLoading: isUserDataLoading } = useGetAccountQuery({});
    const { avatarUrl } = useAvatar(
        data?.profilePhoto?.profilePhoto,
        data?.profilePhoto?.fileType,
    );

    const mainInfo = {
        id: data.id,
        name: `${data.firstName} ${data.lastName}`,
        description: data.organization?.name,
        avatar: avatarUrl,
        role: data.role?.name,
        landPartners: [],
    };

    const userData = {
        date: data.keyDates?.relationshipStartDate,
        birthday: data.keyDates?.birthDate,
        location: data?.address1,
        email: data.email,
        phone: data.phoneNumber,
    };

    const userProfileConfig: UserInfo = {
        id: data.id,
        mainInfo,
        userData,
        isEditable: false,
    };
    if (isUserDataLoading) {
        return (
            <Grid
                container
                sx={{ height: '100vh' }}
                alignItems={'center'}
                justifyContent={'center'}
                flexDirection={'column'}>
                <Loader />
            </Grid>
        );
    }
    return (
        <Stack spacing={2}>
            <UserInfoPanel {...userProfileConfig} />
            <div>
                <BasicTabsPanel tabItems={tabsConfig} />
            </div>
        </Stack>
    );
}

const tabsConfig = [
    {
        label: 'Profile ',
        content: <UserProfile />,
        value: 0,
        to: '',
    },
    {
        label: 'Preferences',
        content: <UserProfilePreferences />,
        value: 1,
        to: '',
    },
];
