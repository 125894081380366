import React from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '../../assets/ckEditor/build/ckeditor';
import { Typography } from '@mui/material';
export interface RichText {
    onChange: (value: string) => void;
    //eslint-disable-next-line
    onBlur?: (e: any) => void;
    value?: string;
    placeholder?: string;
    isDisabled?: boolean;
    isValidationError?: boolean;
    errorText?: string;
    name?: string;
    size?: 'small' | 'medium';
    postfix?: React.ReactNode | string;
    isHidden?: boolean;
}

export default function RichTextField({
    onChange,
    onBlur,
    isDisabled,
    value,
    isHidden,
    name,
    errorText,
}: RichText) {
    if (isHidden) return null;

    return (
        <div>
            <CKEditor
                id={name}
                disabled={isDisabled}
                //eslint-disable-next-line
                //@ts-ignore
                editor={ClassicEditor}
                data={value}
                onChange={(event, editor) => {
                    //eslint-disable-next-line
                    //@ts-ignore
                    const data = editor.getData();
                    onChange(data);
                }}
                onBlur={(event, editor) => {
                    //eslint-disable-next-line
                    //@ts-ignore
                    onBlur && onBlur(editor.getData());
                }}
                onReady={(editor) => {
                    editor.editing.view.change((writer) => {
                        writer.setStyle(
                            'height',
                            '400px',
                            //eslint-disable-next-line
                            //@ts-ignore
                            editor.editing.view.document.getRoot(),
                        );
                    });
                }}
            />
            {errorText && (
                <Typography color={'text.error'}>{errorText}</Typography>
            )}
        </div>
    );
}
