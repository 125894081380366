import React from 'react';

import { Outlet } from 'react-router-dom';
import { Box, useTheme } from '@mui/material';

export default function GeneralManagerSettingsWrapper() {
    const theme = useTheme();

    return (
        <Box sx={{ padding: theme.spacing(1, 2) }}>
            <Outlet />
        </Box>
    );
}
