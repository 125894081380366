import theme from '../../../styles/theme';
import useStyles from './Roles.styles';

import React from 'react';
import AllRoles from './AllRoles/AllRoles';
import ManageRole from './ManageRole';
import BasicTabsPanel from '../../../design/TabsPanel/BasicTabsPanel';
import PATHS from '../../../router/paths';
import useBreadcrumbs from '../../../hooks/useBreadcrumbs';
import Breadcrumbs from '../../../design/Breadcrumbs/Breadcrumbs';
import CompareRoles from './CompareRolesTable/CompareRoles';

const tabsConfig = [
    {
        label: 'All Roles',
        content: <AllRoles />,
        value: 0,
        to: `${PATHS.allRoles}`,
    },
    {
        label: 'Manage Roles',
        content: <ManageRole />,
        value: `${PATHS.manageRoles}`,
        to: `${PATHS.manageRoles}`,
    },
    {
        label: 'Compare Roles',
        content: <CompareRoles />,
        value: `${PATHS.compareRoles}`,
        to: `${PATHS.compareRoles}`,
    },
];
const routes = {
    '/settings/orgAndUsers/roles': 'All Roles',
    '/settings/orgAndUsers/roles/manageRoles': 'Manage Roles',
    '/settings/orgAndUsers/roles/compareRoles': 'Compare Roles',
};

export default function Roles() {
    const { classes } = useStyles(theme);
    const breadcrumbs = useBreadcrumbs({
        homePath: '/settings/orgAndUsers',
        homePathName: 'Settings',
        routes,
    });
    return (
        <div className={classes.rolesWrapper}>
            <Breadcrumbs collapsed={false} links={breadcrumbs} />
            <BasicTabsPanel tabItems={tabsConfig} />
        </div>
    );
}
