import { Grid } from '@mui/material';
import React from 'react';
import { ReactComponent as AlertIcon } from '../../assets/icons/alert_icon.svg';
import { makeStyles } from 'tss-react/mui';
export default function AlertLabel() {
    const { classes } = useStyles();
    return (
        <Grid className={classes.alertIconCircle1}>
            <Grid className={classes.alertIconCircle2}>
                <AlertIcon />
            </Grid>
        </Grid>
    );
}
const useStyles = makeStyles()((theme) => ({
    alertIconCircle1: {
        width: '72px',
        height: '72px',
        backgroundColor: theme.palette.statusRed,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50%',
    },
    alertIconCircle2: {
        width: '56px',
        height: '56px',
        backgroundColor: theme.palette.brandDarkTurq,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50%',
        paddingBottom: '6px',
    },
}));
