import React from 'react';
import { Box, Button } from '@mui/material';

import useStyles from './CompareRoles.styles';
import theme from '../../../../styles/theme';
import { Role, RolePermissions } from './CompareRoles';

interface OwnProps {
    selectedRoles: RolePermissions[];
    options: Role[];
    handleRemoveRole: (roleId: string) => void;
}

export default function RoleButtons({
    selectedRoles,
    options,
    handleRemoveRole,
}: OwnProps) {
    const { classes } = useStyles(theme);

    return (
        <Box
            display={'flex'}
            flexWrap={'wrap'}
            gap={1}
            justifyContent={'flex-end'}>
            {selectedRoles &&
                selectedRoles.map((role) => (
                    <Button
                        key={role.roleId}
                        variant="contained"
                        onClick={() => handleRemoveRole(role.roleId)}
                        className={classes.roleButton}>
                        {options.find((opt) => opt.id === role.roleId)?.name} X
                    </Button>
                ))}
        </Box>
    );
}
