import { NavLink } from 'react-router-dom';
import theme from '../../styles/theme';
import clsx from 'clsx';
import useStyles from './NavigationPanel.styles';
import SubNavigationItem from './SubNavigationItem';
import { NavigationItemProps } from './config';

interface OwnProps {
    item: NavigationItemProps;
    activePath: string;
    setActivePath: (path: string) => void;
    isActivePath: (path: string, activePath: string) => boolean;
}

export default function NavigationItem({
    item,
    activePath,
    setActivePath,
    isActivePath,
}: OwnProps) {
    const { classes } = useStyles(theme);
    if (item.isHidden) return null;
    return (
        <div
            key={item.label}
            className={clsx(
                classes.navigationItem,
                isActivePath(item.path, activePath) &&
                    classes.activeNavigationItem,
            )}>
            <NavLink
                id={item.label}
                to={item.path}
                className={classes.navigationItemTitle}
                onClick={() =>
                    setActivePath(
                        item.subItems?.length
                            ? item.subItems[0].path
                            : item.path,
                    )
                }>
                {item.icon}
                {item.label}
            </NavLink>
            {!!item.subItems?.length &&
                isActivePath(item.path, activePath) &&
                //   filter out hidden subItems
                item.subItems
                    .filter((subItem) => !subItem.isHidden)
                    .map((subItem) => (
                        <SubNavigationItem
                            isActivePath={isActivePath}
                            key={subItem.label}
                            subItem={subItem}
                            activePath={activePath}
                            setActivePath={setActivePath}
                        />
                    ))}
        </div>
    );
}
