import React from 'react';

import { TableCell, TableSortLabel } from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from 'tss-react/mui';

import { ReactComponent as sortIcon } from '../../assets/icons/sort_table_icon.svg';
import { Sorting } from './GenericTable';

export interface TableHeaderCell {
    customizeKey?: string;
    label?: string;
    isHidden?: boolean;
    sortable?: boolean;
    sorting?: Sorting;
    onClick?: (key?: string) => void;
}

export default function GenericTableHeaderCell({
    label,
    sortable,
    sorting,
    isHidden,
    onClick,
}: TableHeaderCell) {
    const { classes } = useStyles();

    if (isHidden) return null;

    return (
        <TableCell className={clsx(classes.header, classes.cell)}>
            {sortable ? (
                <TableSortLabel
                    active={sortable}
                    onClick={onClick && (() => onClick(sorting?.id))}
                    direction={sorting?.direction}
                    className={clsx(
                        classes?.orderLabel,
                        sorting?.isSorted && classes?.activeOrderLabel,
                    )}
                    IconComponent={sortIcon}>
                    {label}
                </TableSortLabel>
            ) : (
                label
            )}
        </TableCell>
    );
}

const useStyles = makeStyles()((theme) => ({
    header: {
        fontSize: 14,
        fontWeight: 700,
        alignItems: 'flex-start',
        maxWidth: 300,
        color: '#2C2E30',
    },
    cell: {},
    actions: {
        '& svg': {
            width: '16px',
            height: '16px',
            transition: 'transform 0.3s',
        },
    },
    orderLabel: {
        color: theme.palette.neutral.neutral4,
        alignItem: 'center',
        '& .MuiTableSortLabel-icon': {
            height: 18,
            marginLeft: theme.spacing(0.5),
        },
    },
    activeOrderLabel: {
        '& svg': {
            margin: 0,
            width: 'inherit',
            height: 'inherit',
            '& path:first-of-type': {
                fill: theme.palette.text.primary,
            },
        },
    },
}));
