import React from 'react';
import { toast } from 'react-toastify';

interface OwnProps {
    type: 'info' | 'success' | 'warning' | 'error';
    message: string;
}
export default function customToastWithAlert({ type, message }: OwnProps) {
    return toast[type](<>{message}</>, {
        theme: 'colored',
    });
}
