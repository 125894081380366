import {
    ColumnConfig,
    CustomizedView,
    useUpdateConfigMutation,
    ViewType,
} from '../api/users/customizeConfigAPI';
import useHandleSubmitRTK from './useHandleSubmit';
import { useEffect } from 'react';

interface OwnProps {
    fetchedData: CustomizedView;
    isLoading: boolean;
    initialTableSettings: ColumnConfig[];
    viewType: ViewType;
}

interface CustomizedViewConfig {
    configData: ColumnConfig[];
    isLoading: boolean;
    isFetched: boolean;
}

function useCustomizeView({
    fetchedData,
    isLoading,
    initialTableSettings,
    viewType,
}: OwnProps): CustomizedViewConfig {
    const { handleSubmit: updateConfig, isLoading: isUpdating } =
        useHandleSubmitRTK({
            useRtkHook: useUpdateConfigMutation,
            isShowSuccessToast: false,
        });

    useEffect(() => {
        if (isLoading || !fetchedData) {
            return;
        }

        const data = updateCustomizedViewConfig(
            fetchedData.viewSettings.table,
            initialTableSettings,
        );

        if (!areColumnConfigsEqual(fetchedData.viewSettings.table, data)) {
            updateConfig({
                viewType: viewType,
                viewSettings: { table: data },
            });
        }
    }, [isLoading, fetchedData]);

    return {
        configData: fetchedData
            ? fetchedData?.viewSettings?.table
            : initialTableSettings,
        isLoading: isUpdating,
        isFetched: !!fetchedData,
    };
}

function updateCustomizedViewConfig(
    data: ColumnConfig[],
    initialTableSettings: ColumnConfig[],
) {
    const updatedData = addMissedColumnsFromInitialTableSettings(
        data,
        initialTableSettings,
    );
    return removeKeysThatNotExistInitialTableSettings(
        updatedData,
        initialTableSettings,
    );
}

// eslint-disable-next-line id-length
function addMissedColumnsFromInitialTableSettings(
    data: ColumnConfig[],
    initialTableSettings: ColumnConfig[],
) {
    const existingIds = new Set(data.map((column) => column.id));
    const newColumns = initialTableSettings.filter(
        (column) => !existingIds.has(column.id),
    );
    return [...data, ...newColumns];
}

// eslint-disable-next-line id-length
function removeKeysThatNotExistInitialTableSettings(
    data: ColumnConfig[],
    initialTableSettings: ColumnConfig[],
) {
    const initialTableSettingsIds = new Set(
        initialTableSettings.map((column) => column.id),
    );
    return data.filter((column) => initialTableSettingsIds.has(column.id));
}

function areColumnConfigsEqual(a: ColumnConfig[], b: ColumnConfig[]) {
    if (a.length !== b.length) return false;
    const sortedA = a.slice().sort((x, y) => x.id.localeCompare(y.id));
    const sortedB = b.slice().sort((x, y) => x.id.localeCompare(y.id));
    return sortedA.every(
        (column, index) =>
            column.id === sortedB[index].id &&
            column.text === sortedB[index].text &&
            column.isHidden === sortedB[index].isHidden,
    );
}

export default useCustomizeView;
