import React, { Dispatch, SetStateAction } from 'react';
import FormModal from '../reusable/Modal/FormModal';
import { useAddReportMutation } from '../api/users/bugReports';
import { FormConfig } from '../design/Forms/interfaces';
import {
    composeValidators,
    maxLength,
    minLength,
    required,
} from '../utils/validation';
import {
    invalidFileType,
    tooLargeFile,
    tooManyFiles,
} from '../utils/fileValidation';
import customToastWithAlert from '../design/CustomToastWithAlert';
import { MAX_FILE_SIZE } from '../constants';

export interface OwnProps {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
}

export interface BugReport {
    screenshot?: File | null;
    request: {
        reportType: ReportType;
        description: string;
        impact: Impact;
        canAskUser: boolean;
    };
}

type Impact = 'LOW' | 'MEDIUM' | 'HIGH';
type ReportType = 'BUG' | 'FEATURE';
export default function BugReportForm({ open, setOpen }: OwnProps) {
    const [addReport, { isLoading }] = useAddReportMutation();

    const handleSubmit = (value: BugReport) => {
        addReport(value)
            .unwrap()
            .then(() => {
                return (
                    customToastWithAlert({
                        type: 'success',
                        message: 'Thank you for bug report submission',
                    }),
                    setOpen(false)
                );
            })
            .catch((error) => {
                return customToastWithAlert({
                    type: 'error',
                    message: error?.data?.description || 'Something went wrong',
                });
            });
    };

    if (isLoading) {
        return <></>;
    }

    const initialValues: BugReport = {
        screenshot: null,
        request: {
            reportType: reportTypeOptions[0].id as ReportType,
            description: '',
            impact: impactOptions[0].id as Impact,
            canAskUser: askUserOptions[0].id as boolean,
        },
    };

    return (
        <FormModal
            initialValues={initialValues}
            header={'Bug Report or Feature Request'}
            isShowing={open}
            formConfig={formConfig}
            handleSubmit={handleSubmit}
            onClose={() => setOpen(false)}
            isLoading={isLoading}
        />
    );
}

const impactOptions = [
    {
        id: 'LOW',
        name: 'Low',
    },
    {
        id: 'MEDIUM',
        name: 'Medium',
    },
    {
        id: 'HIGH',
        name: 'High',
    },
];
const reportTypeOptions = [
    {
        id: 'BUG',
        name: 'Bug Report',
    },
    {
        id: 'FEATURE',
        name: 'Feature Request',
    },
];

const askUserOptions = [
    {
        id: true,
        name: 'Yes',
    },
    {
        id: false,
        name: 'No',
    },
];
const formConfig: FormConfig[] = [
    {
        formField: {
            name: 'request.reportType',
            type: 'radio',
            isRequired: true,
            validation: composeValidators(required),
        },
        renderProps: {
            size: 'half',
            options: reportTypeOptions,
            isDisabled: false,
        },
    },
    {
        formField: {
            name: 'request.description',
            type: 'textarea',
            label: 'Description',
            isRequired: true,
            validation: composeValidators(
                required,
                minLength(2),
                maxLength(500),
            ),
        },
        renderProps: {
            resize: 'none',
            isDisabled: false,
        },
    },
    {
        formField: {
            name: 'screenshot',
            type: 'fileupload',
            label: 'Upload Screenshot',
            validation: composeValidators(
                tooLargeFile(MAX_FILE_SIZE),
                tooManyFiles(10),
                invalidFileType([
                    'image/png',
                    'image/jpg',
                    'image/jpeg',
                    'image/bmp',
                    'application/pdf',
                    'text/csv',
                ]),
            ),
        },
        renderProps: {
            filesAccepted: ['.png', '.jpg', '.jpeg', '.bmp', '.pdf', '.csv'],
            placeholder: 'Or select from your computer',
            helperText: 'Upload a screenshot',
            multipleFiles: true,
            isDisabled: false,
        },
    },
    {
        formField: {
            name: 'request.impact',
            type: 'select',
            label: 'Impact?',
            isRequired: true,
            validation: composeValidators(required),
        },
        renderProps: {
            placeholder: 'Select from list',
            isDisabled: false,
            size: 'small',
            options: impactOptions,
        },
    },
    {
        formField: {
            name: 'request.canAskUser',
            type: 'radio',
            label: 'Can we reach out to you with questions?',
            isRequired: true,
            validation: composeValidators(required),
        },
        renderProps: {
            size: 'half',
            options: askUserOptions,
            isDisabled: false,
        },
    },
];
