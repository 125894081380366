import React, { ReactNode } from 'react';

import { IconButton, Typography } from '@mui/material';
import copy from 'copy-to-clipboard';
import { makeStyles } from 'tss-react/mui';

import { ReactComponent as CopyIcon } from '../../assets/icons/copy_icon.svg';
import BasedTooltip from '../BasedTooltip';

export interface ReadOnlyField {
    title: string;
    text?: string;
    value?: string | number | ReactNode;
    hasCopied?: boolean;
    isHidden?: boolean;
}
export default function CopiedReadField({
    title,
    value,
    hasCopied,
    text,
    isHidden,
}: ReadOnlyField) {
    const { classes } = useStyles();

    if (isHidden) return null;
    return (
        <div className={classes.container}>
            <div className={classes.titleContainer}>
                <Typography variant={'font14'} fontWeight="bold">
                    {title}
                </Typography>
                {hasCopied && value && (
                    <IconButton onClick={() => copy(text || value.toString())}>
                        <CopyIcon />
                    </IconButton>
                )}
            </div>
            <BasedTooltip text={(typeof value === 'string' && value) || ''}>
                <Typography variant="font12">{value}</Typography>
            </BasedTooltip>
        </div>
    );
}
const useStyles = makeStyles()((theme) => ({
    container: {
        display: 'flex',
        width: '100%',
        maxWidth: '20%',
        flexDirection: 'column',
        paddingBottom: theme.spacing(3),
    },
    titleContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        paddingBottom: theme.spacing(1),
    },
}));
