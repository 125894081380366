import React, { useEffect, useState } from 'react';
import { Box, Button } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useForm } from 'react-final-form';

interface OwnProps {
    isTrial: boolean;
}

export default function ToggleTrialButton({ isTrial }: OwnProps) {
    const [trial, setTrial] = useState<boolean>(isTrial);
    const form = useForm();
    const { classes } = useStyles();

    useEffect(() => {
        form.change('subscription.trial', trial);
    }, [trial]);

    return (
        <Box display={'flex'}>
            <Button
                className={classes.buttonLeft}
                disableRipple
                variant={trial ? 'contained' : 'outlined'}
                onClick={() => setTrial(true)}>
                Trial Mode On
            </Button>
            <Button
                className={classes.buttonRight}
                disableRipple
                variant={!trial ? 'contained' : 'outlined'}
                onClick={() => setTrial(false)}>
                Trial Mode Off
            </Button>
        </Box>
    );
}

const useStyles = makeStyles()(() => ({
    buttonLeft: {
        transition: 'all 0.2s 0.1s ease',
        borderRadius: '8px 0px 0px 8px',
    },

    buttonRight: {
        transition: 'all 0.2s 0.1s ease',
        borderRadius: '0px 8px 8px 0px',
    },
}));
