import React from 'react';
import PrimaryContactInfoPanel from '../../features/ViewAllProfiles/PrimaryContactInfoPanel/PrimaryContactInfoPanel';

import useAvatar from '../../hooks/useAvatar';
import { Address, ContactProfile } from './AllProfiles';

import { Grid } from '@mui/material';
import Loader from '../../design/BaseLoader';

interface OwnProps {
    contactData: ContactProfile;
    isLoading: boolean;
    isFetching: boolean;
}

export default function PrimaryContactDetails({
    contactData,
    isLoading,
    isFetching,
}: OwnProps) {
    const { createBlobUrl } = useAvatar();
    if (isLoading || isFetching) {
        return (
            <Grid
                container
                alignItems={'center'}
                justifyContent={'center'}
                flexDirection={'column'}>
                <Loader />
            </Grid>
        );
    }
    const formatAddressByType = (addressType: string) => {
        const address = contactData?.addresses?.find(
            (address: Address) => address.type === addressType,
        );
        return address
            ? [
                  address.address1,
                  address.address2,
                  address.city,
                  address.state,
                  address.zipCode,
              ]
                  .filter(Boolean)
                  .join(', ')
            : '';
    };

    const userMainData = {
        id: contactData?.id,
        name: `${contactData?.firstName} ${contactData?.lastName}`,
        avatar:
            contactData?.avatar?.contactAvatar &&
            contactData?.avatar?.fileType &&
            createBlobUrl(
                contactData?.avatar?.contactAvatar,
                contactData?.avatar?.fileType,
            ),
        isPrimaryContact: contactData?.primaryContact || false,
        landPartners: contactData?.landPartners || [],
    };

    const primaryContactData = {
        id: contactData?.id,
        emailStatus: contactData?.primaryEmailStatus,
        email: contactData?.primaryEmail,
        phone: contactData?.contactNumbers || ' N/A',
        address: formatAddressByType('PRIMARY_ADDRESS') || ' N/A',
    };

    return (
        <>
            <PrimaryContactInfoPanel
                status={
                    contactData?.active === false &&
                    !contactData?.organizationId
                        ? 'INACTIVE'
                        : 'ACTIVE'
                }
                userMain={userMainData}
                primaryContactData={primaryContactData}
                secondaryContactData={{
                    email: contactData?.secondaryEmail,
                    emailStatus: contactData?.secondaryEmailStatus,
                    id: contactData?.id,
                }}
                mailingAddress={formatAddressByType('MAIL_ADDRESS') || ' N/A'}
                billingAddress={
                    formatAddressByType('PAYMENT_ADDRESS') || ' N/A'
                }
                remindersData={{
                    lastOutreach: ' N/A',
                    birthday: contactData.keyDates?.birthDate || ' N/A',
                    anniversary: contactData.keyDates?.anniversary || ' N/A',
                    customerSince:
                        contactData.keyDates?.relationshipStartDate || ' N/A',
                    dateOfDeath: contactData.keyDates?.dateOfDeath,
                }}
                advocacyData={contactData.advocate}
                socialMedia={contactData?.socialMedia}
            />
        </>
    );
}
