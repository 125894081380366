import React from 'react';
import { Badge, IconButton } from '@mui/material';

import { ReactComponent as NotesIcon } from '../../assets/icons/book_icon.svg';
import { ReactComponent as PaperClipIcon } from '../../assets/icons/paper_clip_icon.svg';

import { makeStyles } from 'tss-react/mui';
import theme from '../../styles/theme';

interface OwnProps {
    attachmentCount?: number;
    notesCount?: number;
    handleModalOpen: () => void;
    isHideAttachment?: boolean;
}

export default function NotesButton({
    handleModalOpen,
    notesCount,
    attachmentCount,
    isHideAttachment,
}: OwnProps) {
    const { classes } = useStyles();
    return (
        <>
            <IconButton
                onClick={handleModalOpen}
                className={classes.iconButton}>
                <Badge badgeContent={notesCount} color="success">
                    <NotesIcon />
                </Badge>
                {!isHideAttachment && (
                    <Badge badgeContent={attachmentCount} color="info">
                        <PaperClipIcon />
                    </Badge>
                )}
            </IconButton>
        </>
    );
}

const useStyles = makeStyles()(() => ({
    iconButton: {
        display: 'flex',
        columnGap: 10,
        '& svg': {
            width: 16,
            height: 20,
            cursor: 'pointer',
            fill: theme.palette.brandLightTurq,
        },
    },
}));
