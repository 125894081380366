import React, { useState } from 'react';
import { IconButton } from '@mui/material';

import { ReactComponent as IconYes } from '../../assets/icons/check_yes_icon.svg';
import { ReactComponent as IconNo } from '../../assets/icons/check_no_icon.svg';

import { makeStyles } from 'tss-react/mui';
import { EmailStatus } from './AllProfilesTable';
import VerificationModal from './VerificationModal';
import useVerificateEmail from './useVerificateEmail';
import { accountAPI } from '../../api/users/accountAPI';

interface OwnProps {
    id: string;
    emailStatus: EmailStatus;
    isSecondaryEmail?: boolean;
    isHidden?: boolean;
}

export default function EmailVerificationButton({
    id,
    emailStatus,
    isHidden,
    isSecondaryEmail,
}: OwnProps) {
    const { classes } = useStyles();
    const { isLoading, emailData, handleEmail } = useVerificateEmail({
        isSecondaryEmail,
    });
    const [openVerificationModal, setOpenVerificationModal] = useState(false);
    const Icon = emailIconTableMapper[emailStatus].icon;
    const handleVerificationModalOpen = () => {
        handleEmail(id);
        setOpenVerificationModal(true);
    };
    const getAccountState = accountAPI.endpoints.getAccount.useQueryState({});
    const role = getAccountState.data?.role?.name;

    if (isHidden) return null;
    if (role !== 'GENERAL_MANAGER') {
        return <Icon />;
    }
    return (
        <>
            <IconButton
                onClick={handleVerificationModalOpen}
                className={classes.iconButton}>
                <Icon />
            </IconButton>
            <VerificationModal
                id={id}
                emailData={emailData}
                isLoading={isLoading}
                isSecondaryEmail={isSecondaryEmail}
                isShowing={!!emailData && openVerificationModal}
                handleClose={() => setOpenVerificationModal(false)}
            />
        </>
    );
}

const useStyles = makeStyles()(() => ({
    iconButton: {
        display: 'flex',
        columnGap: 10,
        '& svg': {
            width: 16,
            height: 20,
            cursor: 'pointer',
        },
    },
}));

export const emailIconTableMapper = {
    VERIFIED: {
        icon: IconYes,
    },
    UNVERIFIED: {
        icon: IconNo,
    },
    PENDING: {
        icon: IconNo,
    },
    FAILED: {
        icon: IconNo,
    },
};
