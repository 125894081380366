import { HTTP_METHOD } from '../auth/http';
import { ENDPOINTS } from '../endpoints';
import { getListProvidesTags } from '../generic';
import { ORGANIZATIONS, PERMISSIONS, REDUX_CACHE, ROLES } from '../tags';
import { OAKENApi } from '../OAKENApi';
import { Role } from '../../pages/Settings/Roles/AllRoles/RoleList';

export const rolesAPI = OAKENApi.injectEndpoints({
    endpoints: (builder) => ({
        getRoles: builder.query({
            query: (params) => {
                return {
                    url:
                        process.env.REACT_APP_USER_API_HOST +
                        ENDPOINTS.roles.root,
                    method: HTTP_METHOD.GET,
                    params,
                };
            },
            transformResponse: (
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                data: any,
                meta,
            ): { data: Role[]; totalCount: number } => {
                const xTotalCount =
                    meta?.response?.headers.get('X-Total-Count');
                return { data, totalCount: xTotalCount ? +xTotalCount : 0 };
            },
            providesTags: (result) =>
                getListProvidesTags(result?.data, ROLES.ROLES),
        }),
        getOneRole: builder.query({
            query: (id) => {
                return {
                    url:
                        process.env.REACT_APP_USER_API_HOST +
                        ENDPOINTS.roles.root +
                        `/${id}`,
                    method: HTTP_METHOD.GET,
                };
            },
        }),
        getRolesByPOST: builder.mutation({
            query: (body) => ({
                url:
                    process.env.REACT_APP_USER_API_HOST +
                    ENDPOINTS.roles.root +
                    '/search?page=0&size=200',
                method: HTTP_METHOD.POST,
                body,
            }),
        }),
        addOrganizationRole: builder.mutation({
            query: (body) => ({
                url:
                    process.env.REACT_APP_USER_API_HOST +
                    ENDPOINTS.roles.root +
                    '/organization',
                method: HTTP_METHOD.POST,
                body,
            }),
            invalidatesTags: [ROLES.ROLES, ORGANIZATIONS.ORGANIZATIONS],
        }),
        addGlobalRole: builder.mutation({
            query: (body) => ({
                url:
                    process.env.REACT_APP_USER_API_HOST +
                    ENDPOINTS.roles.root +
                    '/global',
                method: HTTP_METHOD.POST,
                body,
            }),
            invalidatesTags: [ROLES.ROLES],
        }),
        updateRole: builder.mutation({
            query: (body) => {
                return {
                    url:
                        process.env.REACT_APP_USER_API_HOST +
                        ENDPOINTS.roles.root +
                        `/${body.id}`,
                    method: HTTP_METHOD.PUT,
                    body,
                };
            },
            invalidatesTags: (result, error, arg) => [
                { type: ROLES.ROLES, id: REDUX_CACHE.LIST },
                { type: ROLES.ROLES, id: arg },
            ],
        }),
        deleteRole: builder.mutation({
            query: (id) => {
                return {
                    url:
                        process.env.REACT_APP_USER_API_HOST +
                        ENDPOINTS.roles.root +
                        `/${id}`,
                    method: HTTP_METHOD.DELETE,
                };
            },
            invalidatesTags: (result, error, arg) => [
                { type: ROLES.ROLES, id: REDUX_CACHE.LIST },
                { type: ROLES.ROLES, id: arg },
            ],
        }),
        permissionsByRole: builder.query({
            query: ({ id }) => {
                return {
                    url:
                        process.env.REACT_APP_USER_API_HOST +
                        ENDPOINTS.permissions.root +
                        `/${id}`,
                    method: HTTP_METHOD.GET,
                };
            },
            providesTags: [PERMISSIONS.PERMISSIONS],
        }),
        updatePermissionsByRole: builder.mutation({
            query: ({ id, body }) => {
                return {
                    url:
                        process.env.REACT_APP_USER_API_HOST +
                        ENDPOINTS.roles.root +
                        `/${id}/permissions`,
                    method: HTTP_METHOD.PUT,
                    body,
                };
            },
            invalidatesTags: (result, error, arg) => [
                { type: PERMISSIONS.PERMISSIONS, id: arg },
            ],
        }),
    }),
});

export const {
    useUpdatePermissionsByRoleMutation,
    useLazyPermissionsByRoleQuery,
    useGetRolesQuery,
    useLazyGetRolesQuery,
    useGetRolesByPOSTMutation,
    useLazyGetOneRoleQuery,
    useAddOrganizationRoleMutation,
    useUpdateRoleMutation,
    useDeleteRoleMutation,
} = rolesAPI;
