import FieldsTable from './FieldsTable';
import FieldsDashboard from './FieldsDashboard';

export default function Fields() {
    return (
        <div>
            <FieldsDashboard />
            <FieldsTable />
        </div>
    );
}
