import {
    SocialMedia,
    Status,
} from '../../../features/ViewLandownersDetails/LandPartnerDetails';
import { Box, Typography } from '@mui/material';
import SocialMediaList from '../../../reusable/SocialMediaList';
import StatusTag, { Tag } from '../../../design/StatusTag/StatusTag';
import UserAvatar from '../../../design/Avatar/UserAvatar';
import React from 'react';

export interface LandPartnerMainSectionProps {
    name: string;
    relationshipStartDate: string;
    status: Status;
    taxId: string;
    avatar: string;
    socialMedia: SocialMedia;
}

export default function LandPartnerMainSection({
    name,
    relationshipStartDate,
    status,
    taxId,
    avatar,
    socialMedia,
}: LandPartnerMainSectionProps) {
    return (
        <Box
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'space-between'}
            alignItems={'center'}
            width={312}
            rowGap={3}>
            <Box display={'flex'} columnGap={3}>
                <UserAvatar name={name} avatar={avatar} size={'xlarge'} />
                <Box display={'flex'} flexDirection={'column'} gap={2}>
                    <Typography
                        variant="font20"
                        fontWeight="medium"
                        color="text.secondary">
                        {name}
                    </Typography>
                    <Box display={'flex'} gap={1} flexDirection={'column'}>
                        <Typography
                            textTransform={'capitalize'}
                            variant={'font14'}
                            fontWeight={'medium'}
                            color={'text.secondaryContrast'}>
                            Tax ID: {taxId || 'N/A'}
                        </Typography>
                        {relationshipStartDate && (
                            <Typography
                                textTransform={'capitalize'}
                                variant={'font14'}
                                fontWeight={'medium'}
                                color={'text.secondaryContrast'}>
                                Partner Since {relationshipStartDate}
                            </Typography>
                        )}
                    </Box>
                    <Box
                        display={'flex'}
                        flexDirection={'column'}
                        gap={2}
                        pt={3}>
                        <SocialMediaList socialMedia={socialMedia} />
                        <StatusTag {...statusMap[status]} />
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

const statusMap: Record<Status, Tag> = {
    ACTIVE: {
        status: 'success',
        text: 'Active',
    },
    INACTIVE: {
        status: 'warning',
        text: 'Inactive',
    },
    PROSPECT: {
        status: 'info',
        text: 'Pending',
    },
    SUSPENDED: {
        status: 'error',
        text: 'Suspended',
    },
};
