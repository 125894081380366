import {
    composeValidators,
    maxLengthForArrayField,
    minLengthForArrayField,
    requiredWithMessage,
} from '../../../utils/validation';

import { FormConfig } from '../../../design/Forms/interfaces';

import { AutocompleteOption } from '../../../design/Fields/AutocompleteField';

export interface Option {
    id: string;
    name: string;
    status?: string;
}

export const landPartnersConfig = (landPartners: Option[]): FormConfig[] => [
    {
        formField: {
            name: 'landowners',
            type: 'autocomplete',
            label: 'Add Name(s)',
            isRequired: true,
            validation: composeValidators(
                requiredWithMessage('A name is required in order to proceed'),
                maxLengthForArrayField(256),
                minLengthForArrayField(1),
                activeLandownerAllowed(landPartners),
            ),
        },
        renderProps: {
            freeSolo: false,
            isDisabled: false,
            size: 'small',
            options: landPartners,
        },
    },
];

function activeLandownerAllowed(options: Option[]) {
    return function (value: AutocompleteOption[]) {
        const activeLandowners = options.filter(
            (option) => option.status === 'ACTIVE',
        );
        const landownerNames = activeLandowners.map((i) => i.name);
        const selectedLandownerNames = value.map((i) => i.name);
        const invalidLandowners = selectedLandownerNames.filter(
            (i) => !landownerNames.includes(i),
        );
        if (invalidLandowners.length) {
            return `The following landowners are not active: ${invalidLandowners.join(
                ', ',
            )}`;
        }
        return undefined;
    };
}
