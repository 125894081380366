import React from 'react';

import {
    Button,
    Divider,
    Grid,
    IconButton,
    Modal as MUIModal,
    Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { Form } from 'react-final-form';

import GenericFormInput from '../../design/FormFields/GenericFormInput';
import { ReactComponent as CloseIcon } from '../../assets/icons/close_icon.svg';

import { FormConfig } from '../../design/Forms/interfaces';
import SubmitButton from '../../design/Buttons/SubmitButton';

interface OwnProps<T> {
    isShowing: boolean;
    formConfig: FormConfig[];
    handleSubmit: (value: T) => void;
    onClose: () => void;
    header?: string;
    submitButtonText?: string;
    //eslint-disable-next-line
    decorator?: any;
    initialValues?: T;
    isLoading?: boolean;
}

export default function FormModal<T>({
    isShowing,
    handleSubmit,
    header,
    onClose,
    formConfig,
    initialValues,
    submitButtonText = 'Submit',
    isLoading,
    decorator,
}: OwnProps<T>) {
    const { classes } = useStyles();

    return (
        <MUIModal
            open={isShowing}
            onClose={onClose}
            className={classes.modalWrapper}>
            <Grid className={classes.modal}>
                <Grid className={classes.modalHeader}>
                    {header && (
                        <Typography className={classes.modalHeaderTitle}>
                            {header}
                        </Typography>
                    )}
                    <IconButton
                        className={classes.modalCloseButton}
                        onClick={() => onClose()}
                        size="large">
                        <CloseIcon />
                    </IconButton>
                </Grid>

                <Form
                    onSubmit={handleSubmit}
                    decorators={decorator ? [decorator] : []}
                    initialValues={initialValues}>
                    {({ handleSubmit }) => (
                        <form onSubmit={handleSubmit} className={classes.form}>
                            <Grid
                                container
                                columnSpacing={2}
                                rowSpacing={2}
                                className={classes.formContainer}>
                                {formConfig.map(
                                    ({ formField, renderProps }) => {
                                        return (
                                            <GenericFormInput
                                                key={formField.name}
                                                {...formField}
                                                {...renderProps}
                                            />
                                        );
                                    },
                                )}
                            </Grid>
                            <Divider orientation="horizontal" />
                            <div className={classes.bottomContainer}>
                                <Button
                                    onClick={() => onClose()}
                                    color={'secondary'}>
                                    Cancel
                                </Button>
                                <SubmitButton
                                    variant={'text'}
                                    isLoading={isLoading}
                                    text={submitButtonText}
                                />
                            </div>
                        </form>
                    )}
                </Form>
            </Grid>
        </MUIModal>
    );
}

const useStyles = makeStyles()((theme) => ({
    modalWrapper: {
        display: 'flex',
        position: 'fixed',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 1102,
    },
    modal: {
        display: 'flex',

        maxHeight: '90vh',
        flexDirection: 'column',
        alignItems: 'center',
        maxWidth: 950,
        zIndex: 100,
        background: theme.palette.background.default,
        position: 'relative',
        borderRadius: 12,
        padding: 2,
    },
    modalHeader: {
        height: 70,
        position: 'sticky',
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '24px',
        borderRadius: '12px 12px 0 0',
        backgroundColor: theme.palette.neutralWhite,
    },
    modalHeaderTitle: {
        fontWeight: 600,
        fontSize: '14px',
        fontFamily: 'Montserrat',
        color: theme.palette.neutral.neutral10,
    },
    modalCloseButton: {
        padding: 0,
        color: theme.palette.background.default,
        cursor: 'pointer',
        border: 'none',
        width: '18px',
        height: '18px',
    },
    formContainer: {
        maxHeight: 'calc(90vh - 170px)',
        width: '100%',
        backgroundColor: theme.palette.neutral.neutral1,
        padding: theme.spacing(4),
        marginLeft: theme.spacing(0),
        '&::-webkit-scrollbar': {
            width: 10,
            backgroundColor: 'transparent',
            padding: 10,
            scrollbarHeight: 10,
        },
        '&::-webkit-scrollbar-thumb': {
            width: 5,
            height: 5,
            borderRadius: '5px',
            backgroundColor: 'silver',
        },
        '&::-webkit-scrollbar-button': {
            height: 35,
        },
        overflowY: 'auto',
        overflowX: 'hidden',
    },
    form: {
        height: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: theme.spacing(1, 0),
    },
    bottomContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        gap: '16px',
        height: 70,
        width: '100%',
        padding: theme.spacing(3),
        backgroundColor: theme.palette.neutralWhite,
    },
    loaderWrapper: {
        display: 'flex',
        alignItems: 'center',
        height: 'calc(90vh - 90px)',
    },
}));
