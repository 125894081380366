import { Box, Typography } from '@mui/material';
import React from 'react';

interface OwnProps {
    progress: number;
}

export default function PercentageCell({ progress }: OwnProps) {
    if (progress === undefined) return null;

    progress = progress < 0 ? 0 : progress;
    progress = progress > 100 ? 100 : progress;

    return (
        <Box display="flex" alignItems="center">
            <Box
                width="100%"
                position="relative"
                display="flex"
                alignItems="center"
                bgcolor="#e0e0e0"
                borderRadius="4px">
                <Box
                    width={`${progress}%`}
                    bgcolor="#76c7c0"
                    height="20px"
                    borderRadius="4px"
                    position="relative"
                    zIndex={1}
                />
                <Box
                    position="absolute"
                    width="100%"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    zIndex={2}>
                    <Typography variant="font14" color="textPrimary">
                        {`${progress}%`}
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
}
