import useStyles from './NavigationPanel.styles';
import { NavLink } from 'react-router-dom';
import theme from '../../styles/theme';
import { SubNavigationItemProps } from './config';
import clsx from 'clsx';

interface OwnProps {
    subItem: SubNavigationItemProps;
    activePath: string;
    setActivePath: (path: string) => void;
    isActivePath: (path: string, activePath: string) => boolean;
}

export default function SubNavigationItem({
    subItem,
    activePath,
    setActivePath,
    isActivePath,
}: OwnProps) {
    const { classes } = useStyles(theme);
    return (
        <div key={subItem.label} className={classes.navigationSubItem}>
            <NavLink
                to={subItem.path}
                id={subItem.label}
                className={clsx(
                    classes.navigationSubItemTitle,
                    isActivePath(subItem.path, activePath) &&
                        classes.subItemActive,
                )}
                onClick={() => setActivePath(subItem.path)}>
                {subItem.label}
            </NavLink>
        </div>
    );
}
