import { Box, Grid, Typography } from '@mui/material';
import SharedLeasesShortTable from '../TemporaryAccess/Lease/SharedLeasesShortTable';
import DocumentsList from '../../../reusable/DocumentsList/DocumentsList';
import React from 'react';

import Loader from '../../../design/BaseLoader';
import SharedPaymentsShortTable from '../TemporaryAccess/Payment/SharedPaymentsShortTable';
import {
    useGetSharedDocumentsPreviewQuery,
    useGetSharedLeasesPreviewQuery,
    useGetSharedPaymentsPreviewQuery,
} from '../../../api/sharedData/sharedDataTempAccessAPI';
import theme from '../../../styles/theme';

interface OwnProps {
    landPartnerId: string;
    visibilityLevel: string;
}

export default function SharedDataMetricsPreview({
    landPartnerId,
    visibilityLevel,
}: OwnProps) {
    const { data: leases, isLoading: isSharedLeasesLoading } =
        useGetSharedLeasesPreviewQuery({
            landPartnerId,
            visibilityLevel,
        });
    const { data: payments, isLoading: isSharedPaymentsLoading } =
        useGetSharedPaymentsPreviewQuery({
            landPartnerId,
            visibilityLevel,
        });
    const { data: documents, isLoading: isSharedDocumentsLoading } =
        useGetSharedDocumentsPreviewQuery({
            landPartnerId,
            visibilityLevel,
        });

    if (
        isSharedLeasesLoading ||
        isSharedPaymentsLoading ||
        isSharedDocumentsLoading
    ) {
        return (
            <Grid
                container
                alignItems={'center'}
                justifyContent={'center'}
                flexDirection={'column'}>
                <Loader />
            </Grid>
        );
    }

    return (
        <Box sx={{ padding: '20px', paddingLeft: '5px' }} borderRadius={2}>
            <Box sx={{ marginBottom: '20px' }}>
                <Typography variant={'font16'} fontWeight={'bold'}>
                    Leases
                </Typography>
            </Box>
            <SharedLeasesShortTable data={leases?.data || []} />
            <br />
            <Box sx={{ marginBottom: '20px' }}>
                <Typography variant={'font16'} fontWeight={'bold'}>
                    Payments
                </Typography>
            </Box>
            <SharedPaymentsShortTable data={payments?.data || []} />
            <br />
            <Box sx={{ marginBottom: '20px' }}>
                <Typography variant={'font16'} fontWeight={'bold'}>
                    Recent Uploads
                </Typography>
            </Box>
            <Box bgcolor={theme.palette.neutralWhite} padding={2}>
                <DocumentsList cards={documents?.data || []} />
            </Box>
        </Box>
    );
}
