import { Box, Button } from '@mui/material';
import PATHS from '../../router/paths';
import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function SeeAllTasksButton() {
    const navigate = useNavigate();
    return (
        <Box width={'100%'} display={'flex'} justifyContent={'flex-end'}>
            <Button
                variant={'outlined'}
                onClick={() => {
                    navigate(`/${PATHS.tasks}`);
                }}>
                See All Tasks
            </Button>
        </Box>
    );
}
