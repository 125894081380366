import React from 'react';
import { Checkbox, Grid, Typography } from '@mui/material';
import { FormikValues } from 'formik/dist/types';
import { getIn } from 'formik';
import { makeStyles } from 'tss-react/mui';

import myTheme from '../../styles/theme';

interface CheckboxFieldComponentProps {
    text: string;
    name: string;
    disabled?: boolean;
    formik: FormikValues;
}

const useStyles = makeStyles<{ disabled: boolean }>()(
    (theme: typeof myTheme) => ({
        container: {
            '& .MuiCheckbox-root': {
                padding: 0,
                margin: `0 ${theme.spacing(1.5)} 0 0`,
            },
        },
        text: {
            fontSize: '13px',
            fontWeight: 500,
            color: theme.palette.neutral.neutral6,
        },
    }),
);

export default function CheckboxField({
    name,
    text,
    formik,
    disabled = false,
}: CheckboxFieldComponentProps) {
    const { classes } = useStyles({ disabled });
    return (
        <Grid display="flex" alignItems="center" className={classes.container}>
            <Checkbox
                disabled={disabled}
                checked={!!getIn(formik?.values, name)}
                onChange={() =>
                    formik.setFieldValue(name, !getIn(formik?.values, name))
                }
            />
            <Typography className={classes.text}>{text}</Typography>
        </Grid>
    );
}
