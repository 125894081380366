import React from 'react';
import { Box, Grid, IconButton, Typography } from '@mui/material';

import { ReactComponent as DownloadIcon } from '../../../assets/icons/download_icon.svg';
import { Attachment } from '../LandPartnerActivity';
import { dateTimeConverterFromUTC } from '../../../utils/dateFormat';
import FileIconView from '../../../reusable/FileIconView';

interface OwnProps {
    attachment: Attachment;
    onPreviewOpen: () => void;
    onDownload: () => void;
}

export default function AttachmentDisplay({
    attachment,
    onPreviewOpen,
    onDownload,
}: OwnProps) {
    return (
        <Grid
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
            columnGap={1}
            width={'100%'}>
            <Box display={'flex'} onClick={onPreviewOpen} maxWidth={'50%'}>
                <Typography
                    sx={{
                        '&:hover': {
                            cursor: 'pointer',
                            textDecoration: 'underline',
                        },
                    }}
                    variant="font14"
                    color="text.link"
                    noWrap={true}
                    fontWeight={'regular'}>
                    {attachment?.name}
                </Typography>
            </Box>

            <Typography variant="font14" color="text.neutral8">
                Estimate {+attachment?.size / 1000} KB
            </Typography>

            <Typography variant="font14" color="text.neutral8">
                {attachment?.date && dateTimeConverterFromUTC(attachment?.date)}
            </Typography>

            <Box display={'flex'} columnGap={1} alignItems={'center'}>
                <IconButton onClick={onPreviewOpen}>
                    <FileIconView name={attachment.name} />
                </IconButton>
                <IconButton onClick={onDownload}>
                    <DownloadIcon />
                </IconButton>
            </Box>
        </Grid>
    );
}
