import React from 'react';
import PATHS from '../../router/paths';
import { useNavigate } from 'react-router-dom';
import { LeaseType } from '../../features/CreateLeaseForm/LeaseWizard';
import BaseModal from '../../reusable/BaseModal';
import LeaseTile from '../../features/CreateLeaseForm/LeaseTile';
import { Grid, Box, Typography } from '@mui/material';
import palette from '../../styles/palette';
import theme from '../../styles/theme';

interface OwnProps {
    isShowing: boolean;
    onClose: () => void;
}

interface LeaseTypeOption {
    id: LeaseType;
    name: string;
}
export default function SelectLeaseTypeModal({ isShowing, onClose }: OwnProps) {
    const navigate = useNavigate();
    const handleSubmit = (value: LeaseType) => {
        navigate(`${PATHS.create}`, { state: { type: value } });
    };
    return (
        <BaseModal
            header={'Create a Lease'}
            isShowing={isShowing}
            size="small"
            height="small"
            onClose={onClose}>
            <Box
                display={'flex'}
                flexDirection={'column'}
                rowGap={2}
                p={2}
                mb={4}
                sx={{
                    flexGrow: 1,
                    borderRadius: '8px',
                    borderTop: `1px solid ${theme.palette.backgroundB.backgroundB4}`,
                    borderBottom: `1px solid ${theme.palette.backgroundB.backgroundB4}`,
                    backgroundColor: palette.backgroundB.backgroundB1,
                }}>
                <Typography
                    variant={'font12'}
                    color={'text.secondaryContrast'}
                    fontWeight={'medium'}>
                    Choose a Lease Type
                </Typography>

                <Grid container height="100%" spacing={2}>
                    {leaseType.map((item) => {
                        return (
                            <Grid item xs={6} key={item.id}>
                                <LeaseTile
                                    type={item.id}
                                    onClick={() => handleSubmit(item.id)}
                                />
                            </Grid>
                        );
                    })}
                </Grid>
            </Box>
        </BaseModal>
    );
}
export const leaseType: LeaseTypeOption[] = [
    { id: 'FIXED', name: 'Cash/Fixed' },
    { id: 'CROPSHARE', name: 'Crop Share' },
    { id: 'FLEX', name: 'Flex/Variable' },
];
