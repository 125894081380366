import React from 'react';
import useHandleSubmitRTK from '../../../../hooks/useHandleSubmit';
import { useInviteGrowerTmpMutation } from '../../../../api/sharedData/sharedDataTempAccessAPI';
import customToastWithAlert from '../../../../design/CustomToastWithAlert';
import GrowerInviteStaticForm from '../../Common/GrowerInviteStaticForm';

interface InviteGrowerRequest {
    growerName: string;
    growerEmail: string;
}

interface OwnProps {
    token?: string;
}

export default function GrowerInviteForm({ token }: OwnProps) {
    const { handleSubmit: inviteGrower, isLoading } = useHandleSubmitRTK({
        useRtkHook: useInviteGrowerTmpMutation,
        successMessage: 'Invite sent successfully',
    });

    const handleSendInvite = (values: InviteGrowerRequest) => {
        if (!token) {
            customToastWithAlert({
                type: 'error',
                message: 'Not Available in Preview',
            });
            return;
        }
        inviteGrower({ token, body: { ...values } });
    };

    return (
        <GrowerInviteStaticForm
            handleSendInvite={handleSendInvite}
            isLoading={isLoading}
        />
    );
}
