import React from 'react';
import ConfirmModal from '../../../layouts/ConfirmModal/ConfirmModal';

interface OwnProps {
    isShowing: boolean;
    onClose: () => void;
    handleConfirm: () => void;
    title: string;
    info: string;
    isProcessing: boolean;
}

export default function ConfirmDeleteModal({
    isShowing,
    onClose,
    handleConfirm,
    title,
    info,
    isProcessing,
}: OwnProps) {
    return (
        <ConfirmModal
            isShowing={isShowing}
            hide={onClose}
            title={title}
            info={info}
            onSubmit={handleConfirm}
            isDisabled={isProcessing}
        />
    );
}
