import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material/styles/createTheme';

const useStyles = makeStyles<Theme>()((theme) => ({
    formWrapper: {
        width: '100%',
    },
    title: {
        fontSize: '32px',
        lineHeight: '1.25',
        color: theme.palette.neutral.neutral10,
        marginBottom: '4px',
    },
    details: {
        paddingTop: theme.spacing(3),
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '1.43',
        color: theme.palette.neutral.neutral6,
        marginBottom: '48px',
    },
    textField: {
        marginBottom: '32px',
    },
    signInLineContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: '46px 0',
    },
    link: {
        fontSize: '11px',
        fontWeight: 500,
        lineHeight: '1.27',
        color: theme.palette.brandLightTurq,
        textDecoration: 'unset',
    },
    policyPrivacy: {
        width: '100%',
        textAlign: 'center',
        marginTop: '16px',
        color: theme.palette.primary.main,
        fontSize: '11px',
        lineHeight: '1.27',
        fontWeight: 600,
    },
    fontSize14: {
        fontSize: '14px',
    },
    bottomContainer: {
        display: 'flex',
        flexDirection: 'row-reverse',
        justifyContent: 'space-between',
        marginTop: '46px',
        alignItems: 'center',
    },
    button: {
        padding: '11px 40px !important',
    },
    validationContainer: {
        marginBottom: '32px',
    },
    validationItem: {
        display: 'flex',
        fontSize: '14px',
        lineHeight: '1.43',
        color: '#8f8f8f',

        '& svg': {
            minWidth: '16px',
            marginRight: '16px',
            marginTop: '2px',
        },
    },
}));

export default useStyles;
