import React, { ChangeEvent } from 'react';
import { Button, styled } from '@mui/material';
import { ReactComponent as PaperClipIcon } from '../../assets/icons/paper_clip_icon.svg';

interface OwnProps {
    handleClick: (event: ChangeEvent<HTMLInputElement>) => void;
}
export default function UploadAttachments({ handleClick }: OwnProps) {
    const VisuallyHiddenInput = styled('input')({
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    });

    return (
        <label>
            <VisuallyHiddenInput type="file" onChange={handleClick} />
            <PaperClipButton />
        </label>
    );
}

const PaperClipButton = () => (
    <Button
        component="div"
        variant="text"
        endIcon={<PaperClipIcon />}
        sx={{ textTransform: 'capitalize' }}>
        Attach
    </Button>
);
