import { Grid } from '@mui/material';
import React from 'react';
import { ReactComponent as PauseIcon } from '../../assets/icons/pause_icon.svg';
import { makeStyles } from 'tss-react/mui';
export default function PauseLabel() {
    const { classes } = useStyles();
    return (
        <Grid className={classes.alertIconCircle1}>
            <Grid className={classes.alertIconCircle2}>
                <PauseIcon />
            </Grid>
        </Grid>
    );
}
const useStyles = makeStyles()((theme) => ({
    alertIconCircle1: {
        width: '30px',
        height: '30px',
        backgroundColor: theme.palette.statusRed,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50%',
    },
    alertIconCircle2: {
        width: '24px',
        height: '24px',
        backgroundColor: theme.palette.brandDarkTurq,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50%',
    },
}));
