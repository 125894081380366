import { Link, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { ReactNode } from 'react';

export interface BreadcrumbLinkProps {
    name: string;
    path: string;
    isLast?: boolean;
    isHidden?: boolean;
    icon?: ReactNode;
}

export default function BreadcrumbLink({
    name,
    path,
    isLast,
    isHidden,
    icon,
}: BreadcrumbLinkProps) {
    if (isHidden) {
        return null;
    }

    if (isLast) {
        return (
            <Typography
                variant={'font11'}
                color="text.primary"
                columnGap={1}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    fontSize: 11,
                }}>
                {icon}
                {name}
            </Typography>
        );
    }
    return (
        <Link
            sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                fontSize: 11,
            }}
            component={RouterLink}
            underline={'hover'}
            color="inherit"
            to={path}>
            {icon}
            {name}
        </Link>
    );
}
