import React from 'react';
import DashboardWrapper from '../../../reusable/DashboardWrapper';
import FieldsDashboardMainInfo from './FieldsDashboardMainInfo';
import FieldsDashboardOtherInfo from './FieldsDashboardOtherInfo';
import { FieldsData } from '../../../pages/Fields/useFieldsTable';
interface OwnProps {
    data?: FieldsData;
}
export default function FieldsDashboard({ data }: OwnProps) {
    if (!data) return null;
    return (
        <DashboardWrapper>
            <FieldsDashboardMainInfo
                fieldName={data.fieldName}
                landPartnerName={data.landownerNames}
                location={data.location}
            />
            <FieldsDashboardOtherInfo data={data} />
        </DashboardWrapper>
    );
}
