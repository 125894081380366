import { Box } from '@mui/material';

import { Field } from 'react-final-form';
import FormScreenWrapper from './FormScreenWrapper';
import AppTypography from '../../design/AppTypography';
import DndItemsList from './DndField/DndItemsList';
import { FormConfig } from '../../design/Forms/interfaces';
import FormPage from '../../reusable/FormUI/FormPage';

export default function CriticalResourcesProcessesForm() {
    return (
        <FormScreenWrapper title="Critical Resources and Processes">
            <Box display={'flex'} flexDirection={'column'} rowGap={2}>
                <AppTypography
                    variant="font16"
                    fontWeight={'medium'}
                    text={
                        ' What are the most critical crops and at what stages of growth?'
                    }
                    color={'text.neutral9'}
                />
                <Field
                    name={'criticalResources'}
                    render={({ input }) => (
                        <DndItemsList
                            input={input}
                            addButtonLabel={'Add another crop type'}
                        />
                    )}
                />
                <AppTypography
                    variant="font16"
                    fontWeight={'medium'}
                    text={
                        'What are the essential equipment and machinery needed to maintain operations?'
                    }
                    color={'text.neutral9'}
                />
                <Field
                    name={'equipment'}
                    render={({ input }) => (
                        <DndItemsList
                            input={input}
                            addButtonLabel={
                                'Add another another essential equipment'
                            }
                        />
                    )}
                />
                <AppTypography
                    variant="font16"
                    fontWeight={'medium'}
                    text={
                        'Where are the vital records and important documents stored?'
                    }
                    color={'text.neutral9'}
                />
                <FormPage formConfig={formConfig} />
            </Box>
        </FormScreenWrapper>
    );
}

const formConfig: FormConfig[] = [
    {
        formField: {
            scheme: 'full',
            name: 'vitalRecordsLocation',
            type: 'textarea',
            label: '',
        },
        renderProps: {
            resize: 'none',
            isDisabled: false,
            minRows: 3,
        },
    },
];
