import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
    modalOverlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 1101,
        width: '100vw',
        height: '100vh',
        backgroundColor: theme.palette.text.primary,
        opacity: 0.4,
    },
    modalWrapper: {
        display: 'flex',
        position: 'fixed',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        maxHeight: '80vh',
        zIndex: 1102,
        overflowX: 'hidden',
        overflowY: 'hidden',
        outline: 0,
        left: 0,
        top: '10%',
        right: 0,
        bottom: 0,
        '&::-webkit-scrollbar': {
            width: 10,
            backgroundColor: 'transparent',
            padding: 10,
            scrollbarHeight: 10,
        },
        '&::-webkit-scrollbar-thumb': {
            width: 5,
            height: 5,
            borderRadius: '5px',
            backgroundColor: 'silver',
        },
        '&::-webkit-scrollbar-button': {
            height: 35,
        },
    },
    modal: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: 1200,
        zIndex: 100,
        background: theme.palette.background.default,
        position: 'relative',
        borderRadius: 12,
        padding: 2,
        overflowY: 'auto',
        overflowX: 'hidden',
        '&::-webkit-scrollbar': {
            width: 10,
            backgroundColor: 'transparent',
            padding: 10,
            scrollbarHeight: 10,
        },
        '&::-webkit-scrollbar-thumb': {
            width: 5,
            height: 5,
            borderRadius: '5px',
            backgroundColor: 'silver',
        },
        '&::-webkit-scrollbar-button': {
            height: 35,
        },
    },
    modalHeader: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '24px',
        height: '68px',
        backgroundColor: theme.palette.neutralWhite,
    },
    modalHeaderTitle: {
        fontWeight: 600,
        fontSize: '14px',
        fontFamily: 'Montserrat',
        color: theme.palette.neutral.neutral10,
    },
    modalCloseButton: {
        padding: 0,
        color: theme.palette.background.default,
        cursor: 'pointer',
        border: 'none',
        width: '18px',
        height: '18px',
    },
    button: {
        fontWeight: 700,
        border: 'none',
        borderRadius: 6,
        padding: '.3rem 1rem',
        marginLeft: '.5rem',
    },
    buttonDefault: {
        background: theme.palette.background.default,
        color: theme.palette.background.default,
    },
    wrapperComponent: {
        width: '100%',
        boxSizing: 'border-box',
        padding: theme.spacing(2, 1, 2, 2),
        backgroundColor: theme.palette.neutral.neutral1,
        maxHeight: `calc(80vh - 69px)`,
    },
}));

export default useStyles;
