import React from 'react';
import { FieldArray } from 'react-final-form-arrays';
import FormPage from '../../reusable/FormUI/FormPage';
import { addressesConfig } from './FormConfigs/addressesConfig';
import { Box, Button, Divider } from '@mui/material';
import { useForm, useFormState } from 'react-final-form';

interface Address {
    type: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    zipCode: string;
    country: string;
}
export default function AddressFormTab() {
    const { mutators } = useForm();
    const { values } = useFormState();
    const addressesType = values?.addresses?.map(
        (address: Address) => address?.type,
    );

    return (
        <Box display={'flex'} flexDirection={'column'} gap={2}>
            <FieldArray name="addresses">
                {({ fields }) => {
                    return fields.map((name, index) => (
                        <>
                            <FormPage
                                key={name}
                                formConfig={addressesConfig(
                                    name,
                                    addressesType,
                                    index,
                                )}
                            />

                            <Button onClick={() => fields.remove(index)}>
                                Remove Address
                            </Button>
                            <Divider />
                        </>
                    ));
                }}
            </FieldArray>

            {((values.addresses && values.addresses.length < 3) ||
                !values.addresses) && (
                <Button
                    type="button"
                    onClick={() => mutators.push('addresses')}>
                    Add Another Address
                </Button>
            )}
        </Box>
    );
}
