import React from 'react';
import { Grid } from '@mui/material';
import BasePieChart, {
    PieChartConfig,
} from '../../design/Charts/PieChart/BasePieChart';
import AvatarWithMetadata, {
    AvatarWithMetadataProps,
} from './AvatarWithMetadata';

export interface FieldsDashboard {
    corn: number;
    soy: number;
    wheat: number;
    cornBu: number;
    wheatBu: number;
    soyBu: number;
}
export default function FieldsDashboard() {
    return (
        <Grid container item justifyContent={'space-between'}>
            <BasePieChart
                data={createCrops(mockDashBoardData)}
                config={pieCrops}
            />
            <BasePieChart
                data={createTotalBu(mockDashBoardData)}
                config={pieTotalBu}
            />

            <AvatarWithMetadata {...mockAvatarWithData} />
        </Grid>
    );
}
const currentYear = new Date().getFullYear();

function createTotalBu(dashboardData: FieldsDashboard) {
    return [
        { name: 'Corn', value: dashboardData?.cornBu || 0 },
        { name: 'Soy', value: dashboardData?.soyBu || 0 },
        { name: 'Wheat', value: dashboardData?.wheatBu || 0 },
    ];
}

function createCrops(dashboardData: FieldsDashboard) {
    return [
        { name: 'Corn', value: dashboardData?.corn || 0 },
        { name: 'Soy', value: dashboardData?.soy || 0 },
        { name: 'Wheat', value: dashboardData?.wheat || 0 },
    ];
}

const pieTotalBu: PieChartConfig = {
    summaryTitle: `${currentYear}`,
    summaryValue: '14,445',
    showLegend: true,
    dataKey: 'value',
    chartTitle: 'TOTAL BU',
    showTooltip: true,
};

const pieCrops: PieChartConfig = {
    summaryTitle: 'Total Acres',
    summaryValue: '13,032',
    showLegend: true,
    dataKey: 'value',
    chartTitle: 'CROPS',
    showTooltip: true,
};
const mockDashBoardData: FieldsDashboard = {
    corn: 9400,
    soy: 2547,
    wheat: 1455,
    cornBu: 2134,
    wheatBu: 10030,
    soyBu: 1120,
};
const mockAvatarWithData: AvatarWithMetadataProps = {
    name: 'Alice Johnson',
    avatar: 'https://randomuser.me/api/portraits/women/44.jpg',
    phone: '15551234567',
    email: 'alice.johnson@example.com',
    role: 'Project Manager',
};
