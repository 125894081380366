import { Dispatch, SetStateAction, useEffect } from 'react';

import {
    useLazyGetOneRoleQuery,
    useUpdateRoleMutation,
} from '../../../../api/users/rolesAPI';
import FormModal from '../../../../reusable/Modal/FormModal';
import { Role } from '../AllRoles/RoleList';
import { formConfig } from '../config/editRoleConfig';
import customToastWithAlert from '../../../../design/CustomToastWithAlert';

export interface ManageModal {
    isShowing: boolean;
    setIsShowing: Dispatch<SetStateAction<boolean>>;
}

interface OwnProps extends ManageModal {
    id?: string;
}

export default function EditRole({ id, isShowing, setIsShowing }: OwnProps) {
    const [updateRole, { isLoading: updating, reset, data: updatedData }] =
        useUpdateRoleMutation();
    const [
        getItems,
        { isFetching: fetchingOne, isLoading: loadingOne, data: initData },
    ] = useLazyGetOneRoleQuery();

    useEffect(() => {
        reset();
        id && getItems(id);
    }, [id]);

    const data = updatedData || initData;

    function handleSubmit(value: Role) {
        if (id) {
            updateRole({
                permissionIds:
                    data.permissions &&
                    data.permissions.map((p: Permission) => p.id),
                organizationId: data.organization?.id,
                ...value,
            })
                .unwrap()
                .then(() => {
                    return (
                        customToastWithAlert({
                            type: 'success',
                            message: 'Role updated successfully',
                        }),
                        setIsShowing(false)
                    );
                })
                .catch((error) => {
                    return customToastWithAlert({
                        type: 'error',
                        message:
                            error?.data?.description || 'Something went wrong',
                    });
                });
            return;
        }
    }

    return (
        <FormModal
            header={'Edit role'}
            isShowing={isShowing}
            formConfig={formConfig}
            handleSubmit={handleSubmit}
            submitButtonText={'Edit role'}
            onClose={() => setIsShowing(false)}
            initialValues={{
                organizationName: data?.organization?.name,
                ...data,
            }}
            isLoading={fetchingOne || loadingOne || updating}
        />
    );
}

export interface Permission {
    id: string;
    name: boolean;
    description: string;
}
