import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import TaskCalendar from '../../design/Calendar/TaskCalendar';
import PromptCard, {
    PromptCardProps,
    Severity,
} from '../../design/Cards/PromptCard';

import EventCardWithPopover, { EventPopover } from './EventCardWithPopover';
import { useGetCalendarQuery } from '../../api/calendar/calendarAPI';
import { format } from 'date-fns';
import { makeStyles } from 'tss-react/mui';

interface DayEvents {
    day: string;
    events: UpcomingEvents[];
}

type MonthYear =
    | 'JANUARY'
    | 'FEBRUARY'
    | 'MARCH'
    | 'APRIL'
    | 'MAY'
    | 'JUNE'
    | 'JULY'
    | 'AUGUST'
    | 'SEPTEMBER'
    | 'OCTOBER'
    | 'NOVEMBER'
    | 'DECEMBER';

interface UpcomingEvents {
    id: string;
    type: UpcomingEventsType;
    message: string;
    referenceId: string;
    eventDate: string;
    receiver: Contact;
}

interface Contact {
    id: string;
    firstName: string;
    lastName: string;
    contactAvatar: {
        fileName: string;
        fileType: string;
        contactAvatar: string;
    };
}

export type UpcomingEventsType =
    | 'BIRTHDAY'
    | 'ANNIVERSARY'
    | 'TASKS'
    | 'PAYMENT'
    | 'LEASE_RENEWALS'
    | 'CALENDAR_EVENT';
export default function UpcomingEventsSidebar() {
    const [eventsDate, setEventsDate] = useState<Date>(new Date());
    const [highlightedDays, setHighlightedDays] = useState<number[]>([]);
    const [date, setDate] = useState(new Date());

    const [currentEvents, setCurrentEvents] = useState<
        Record<string, UpcomingEvents[]>
    >({});
    const year = format(date, 'yyyy');
    const currentMonth = format(date, 'MMMM').toUpperCase() as MonthYear;
    const currentEventDay = eventsDate && format(eventsDate, 'd');
    const { data, isLoading } = useGetCalendarQuery({ period: year });

    const { classes } = useStyles();

    useEffect(() => {
        if (data?.year) {
            const fullMonth = data?.year?.[currentMonth]
                ? filterEmptyEvents(data?.year?.[currentMonth])
                : [];

            const days = fullMonth?.map((month) => parseInt(month?.day));
            setHighlightedDays(days);
            setCurrentEvents(arrayOfObjToObj(fullMonth));
        }
    }, [data, currentMonth]);

    function handleDate(value: Date) {
        setDate(value);
    }

    return (
        <Box display={'flex'} flexDirection={'column'}>
            <TaskCalendar
                isLoading={isLoading}
                onChange={(newValue) => setEventsDate(newValue)}
                value={eventsDate}
                highlightedDays={highlightedDays}
                handleDate={handleDate}
            />
            {!isLoading ? (
                <Box
                    display={'flex'}
                    flexDirection={'column'}
                    gap={2}
                    className={classes.listWrapper}>
                    {currentEvents[currentEventDay] ? (
                        currentEvents[currentEventDay]?.map(
                            (activity, index) => (
                                <EventCardWithPopover
                                    key={index}
                                    tooltip={'Click on card to view details'}
                                    activity={activityToPromptCard(activity)}
                                    event={activityToEvents(activity)}
                                />
                            ),
                        )
                    ) : (
                        <PromptCard {...emptyPromptCard} />
                    )}
                </Box>
            ) : null}
        </Box>
    );
}

const useStyles = makeStyles()(() => ({
    listWrapper: {
        maxHeight: 400,
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
            width: 10,
            backgroundColor: 'transparent',
            padding: 10,
            scrollbarHeight: 10,
        },
        '&::-webkit-scrollbar-thumb': {
            width: 5,
            height: 5,
            borderRadius: '5px',
            backgroundColor: 'silver',
        },
        '&::-webkit-scrollbar-button': {
            height: 35,
        },
    },
}));

function filterEmptyEvents(events: DayEvents[]) {
    return events.filter((event) => event.events.length > 0);
}

function arrayOfObjToObj(events: DayEvents[]) {
    return events.reduce(
        (acc, event) => {
            acc[event.day] = event.events.filter(
                (v, i, a) => a.findIndex((t) => t.message === v.message) === i,
            );
            return acc;
        },
        {} as Record<string, UpcomingEvents[]>,
    );
}

const upcomingEventsNameMapper: Record<UpcomingEventsType, string> = {
    BIRTHDAY: 'Birthday',
    ANNIVERSARY: 'Anniversary',
    TASKS: 'Tasks',
    PAYMENT: 'Payment',
    LEASE_RENEWALS: 'Lease Renewals',
    CALENDAR_EVENT: 'Calendar Event',
};

function getUpcomingEventsName(type: UpcomingEventsType) {
    return upcomingEventsNameMapper[type];
}

const upcomingEventsTypeMapper: Record<UpcomingEventsType, Severity> = {
    BIRTHDAY: 'success',
    ANNIVERSARY: 'success',
    TASKS: 'info',
    PAYMENT: 'error',
    LEASE_RENEWALS: 'warning',
    CALENDAR_EVENT: 'info',
};

function getUpcomingEventsType(type: UpcomingEventsType) {
    return upcomingEventsTypeMapper[type];
}

function activityToPromptCard(activity: UpcomingEvents): PromptCardProps {
    return {
        type: getUpcomingEventsType(activity.type),
        title: getUpcomingEventsName(activity.type),
        description: activity?.message,
    };
}

const emptyPromptCard: PromptCardProps = {
    type: 'success',
    title: 'No Events Available',
    description: 'Waiting for data',
};

function activityToEvents(activity: UpcomingEvents): EventPopover {
    return {
        name: getUpcomingEventsName(activity.type),
        type: activity.type,
        dateTime: activity.eventDate,
        description: activity?.message,
        scheduledBy: activity?.receiver && {
            name: `${activity.receiver?.firstName} ${activity.receiver?.lastName}`,
            avatar: activity.receiver?.contactAvatar?.contactAvatar,
            fileName: activity.receiver?.contactAvatar?.fileName,
            fileType: activity.receiver?.contactAvatar?.fileType,
        },
        referenceId: activity.referenceId,
    };
}
