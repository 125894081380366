import React, { useState } from 'react';

import DocumentListItem, {
    DocumentItem,
    DocumentType,
} from './DocumentListItem';
import { Box, Grid } from '@mui/material';
import StubDummyComponent from '../StubDummyComponent';
import { useDownloadByIdsAsZipMutation } from '../../api/documents/documentsAPI';
import { createDownloadLinkFromBlob } from '../../utils/fileHandler';
import customToastWithAlert from '../../design/CustomToastWithAlert';
import ActionButton, {
    ActionMenuItem,
} from '../../design/Buttons/ActionButton';
import { useDownloadSharedDocumentsAsZipMutation } from '../../api/sharedData/sharedDataTempAccessAPI';
import { dateTimeConverterFromUTC } from '../../utils/dateFormat';

export interface DocumentResponseItem {
    id: string;
    link: string;
    fileName: string;
    createdAt: string;
    type: DocumentType;
    createdBy: CreatedBy;
    visibility?: string;
}

interface CreatedBy {
    id: string;
    firstName: string;
    lastName: string;
    profilePhoto?: ProfilePhoto;
}

interface ProfilePhoto {
    id: string;
    fileName: string;
    fileType: string;
    profilePhoto: string;
}

interface OwnProps {
    cards: DocumentResponseItem[];
    token?: string;
}

export default function DocumentsList({ cards, token }: OwnProps) {
    const [checkedIds, setCheckedIds] = useState<string[]>([]);

    const [downloadDocumentsAsZip] = useDownloadByIdsAsZipMutation();
    const [downloadSharedDocumentsAsZip] =
        useDownloadSharedDocumentsAsZipMutation();

    const handleCheck = (id: string, isChecked: boolean) => {
        if (isChecked) {
            setCheckedIds((prevIds) => [...prevIds, id]);
        } else {
            setCheckedIds((prevIds) =>
                prevIds.filter((prevId) => prevId !== id),
            );
        }
    };

    const handleDownloadZip = () => {
        let downloadZip = downloadDocumentsAsZip({ documentIds: checkedIds });

        if (token) {
            downloadZip = downloadSharedDocumentsAsZip({
                token,
                body: { documentIds: checkedIds },
            });
        }

        downloadZip
            .unwrap()
            .then((blob) => {
                createDownloadLinkFromBlob(blob, 'documents.zip');
            })
            .catch((error) => {
                return customToastWithAlert({
                    type: 'error',
                    message: error?.data?.description || 'Something went wrong',
                });
            });
    };

    if (!cards.length)
        return <StubDummyComponent title={'No documents available'} />;

    const actionButtonItems: ActionMenuItem[] = [
        {
            label: `Download (${checkedIds.length}) documents as ZIP`,
            onClick: handleDownloadZip,
            hidden: !checkedIds.length,
        },
    ];

    return (
        <Box
            display={'flex'}
            flexDirection={'column'}
            maxHeight={'60vh'}
            rowGap={1}
            sx={{
                overflowY: 'auto',
                overflowX: 'hidden',
                '&::-webkit-scrollbar': {
                    width: 10,
                    backgroundColor: 'transparent',
                },
                '&::-webkit-scrollbar-thumb': {
                    width: 5,
                    borderRadius: '5px',
                    backgroundColor: 'silver',
                },
            }}>
            <Grid
                container
                justifyContent={'flex-end'}
                width={'100%'}
                sx={{
                    padding: 2,
                }}>
                <ActionButton label={'Actions'} items={actionButtonItems} />
            </Grid>
            {cards?.map((card, index: number) => (
                <DocumentListItem
                    token={token}
                    key={index}
                    document={documentCardConvertor(card)}
                    onCheckChange={(isChecked) =>
                        handleCheck(card.id, isChecked)
                    }
                />
            ))}
        </Box>
    );
}

function documentCardConvertor(data: DocumentResponseItem): DocumentItem {
    return {
        id: data.id,
        fileName: data.fileName,
        createdByName: `${data.createdBy.firstName} ${data.createdBy.lastName}`,
        createdAt: dateTimeConverterFromUTC(data.createdAt),
        avatar: data.createdBy.profilePhoto?.profilePhoto,
        avatarFileType: data.createdBy.profilePhoto?.fileType,
        documentType: data.type,
        fileLocation: data.link,
        visibility: data?.visibility || 'PUBLIC',
    };
}
