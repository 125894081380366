import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import PATHS from '../../router/paths';

export default function LandPortfolio() {
    const { pathname } = useLocation();

    if (pathname === `/${PATHS.landPortfolio}`)
        return <Navigate to={`/${PATHS.landPortfolio}/${PATHS.manage}`} />;

    return (
        <div>
            <Outlet />
        </div>
    );
}
