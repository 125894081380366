import { useNavigate } from 'react-router-dom';
import useHandleSubmitRTK from '../../hooks/useHandleSubmit';
import PATHS from '../../router/paths';
import ConfirmModal from '../../layouts/ConfirmModal/ConfirmModal';
import React from 'react';
import { useArchiveLandPartnerMutation } from '../../api/landpartners/landPartnersAPI';

interface OwnProps {
    isShowing: boolean;
    onClose: () => void;
    id?: string;
}

export default function ArchivedLandPartner({
    isShowing,
    id,
    onClose,
}: OwnProps) {
    const navigate = useNavigate();

    const { handleSubmit } = useHandleSubmitRTK({
        useRtkHook: useArchiveLandPartnerMutation,
        onSuccess: () => {
            onClose();
            navigate(`/${PATHS.landpartners}/${PATHS.profiles}`);
        },
        successMessage: 'Land Partner archived successfully',
    });
    const handleDeactivateLP = () => {
        id && handleSubmit(id);
    };

    return (
        <ConfirmModal
            isShowing={isShowing}
            hide={onClose}
            title={`Please confirm you want to archived this Land Partner?`}
            info={''}
            onSubmit={handleDeactivateLP}
        />
    );
}
