import CollapsedDetails, { FieldData } from './CollapsedDetails';
import { Stack } from '@mui/material';

interface OwnProps {
    data: FieldData[];
}
export default function ExpandedFieldData({ data }: OwnProps) {
    return (
        <Stack rowGap={2}>
            {data.map((field) => (
                <CollapsedDetails key={field.id} data={field} />
            ))}
        </Stack>
    );
}
