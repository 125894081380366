import React, { ReactNode, useEffect, useState } from 'react';
import { Tab, Tabs } from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from 'tss-react/mui';
import theme from '../../styles/theme';
import { Link, useParams } from 'react-router-dom';

export interface TabItem {
    label: string;
    value: number | string;
    content: ReactNode;
    isHidden?: boolean;
    to: string;
}
interface OwnProps {
    tabItems: TabItem[];
}

export default function BasicTabsPanel({ tabItems }: OwnProps) {
    const params = useParams();
    function getActiveTab() {
        return tabItems.find((tab) => tab.to === params['*'])?.value || 0;
    }
    const [activeTab, setActiveTab] = useState<string | number>(getActiveTab());
    const { classes } = useStyles();
    useEffect(() => {
        setActiveTab(getActiveTab());
    }, [params['*']]);
    return (
        <div className={classes.container}>
            <Tabs
                className={classes.root}
                value={activeTab}
                selectionFollowsFocus={true}>
                {tabItems.map(
                    (tab, index) =>
                        !tab.isHidden && (
                            <Tab
                                key={index}
                                value={activeTab}
                                label={tab.label}
                                onClick={() => setActiveTab(tab.value)}
                                className={clsx(
                                    classes.tab,
                                    activeTab === tab.value &&
                                        classes.activeTab,
                                )}
                                to={tab.to}
                                component={Link}
                            />
                        ),
                )}
            </Tabs>
            <div className={classes.content}>
                {tabItems.find((tab) => tab.value === activeTab)?.content}
            </div>
        </div>
    );
}

const useStyles = makeStyles()(() => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        backgroundColor: theme.palette.neutralWhite,
    },
    root: {
        height: '53px',
        backgroundColor: theme.palette.backgroundB.backgroundB4,
        borderRadius: '8px 8px 0 0',

        '& .MuiTabs-indicator': {
            display: 'none',
        },
    },
    content: {
        padding: theme.spacing(4, 5),
    },
    tab: {
        height: '53px',
        flex: 1,
        color: theme.palette.brandLightTurq,
        fontSize: '14px',
        fontWeight: 500,
        borderRadius: '8px 8px 0 0',
        textTransform: 'unset',
    },
    activeTab: {
        backgroundColor: theme.palette.neutralWhite,
    },
}));
