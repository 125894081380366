import { Box, Divider } from '@mui/material';
import SearchSorting from './SearchSorting';
import FilterOptionList from './FilterOptionList';
import theme from '../../styles/theme';
import React from 'react';

export default function SearchFilter() {
    return (
        <Box
            width={'100'}
            display={'flex'}
            columnGap={2}
            p={1}
            bgcolor={theme.palette.backgroundB.backgroundB3}>
            <SearchSorting />
            <Divider
                variant={'fullWidth'}
                orientation={'vertical'}
                flexItem
                color={theme.palette.neutral.neutral4}
            />
            <FilterOptionList />
        </Box>
    );
}
