import CardWrapper from './CardWrapper';
import FormPage from '../../reusable/FormUI/FormPage';
import { FormConfig } from '../../design/Forms/interfaces';
import { Form } from 'react-final-form';
import { Box } from '@mui/material';

interface OwnProps {
    formConfig: FormConfig[];
}
export default function AppFormCard({ formConfig }: OwnProps) {
    function handleSubmit() {
        // do something
    }
    return (
        <CardWrapper>
            <Box width={'540px'}>
                <Form onSubmit={handleSubmit}>
                    {({ handleSubmit }) => (
                        <form onSubmit={handleSubmit}>
                            <FormPage formConfig={formConfig} />
                        </form>
                    )}
                </Form>
            </Box>
        </CardWrapper>
    );
}
