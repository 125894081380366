import { useForm, useFormState } from 'react-final-form';
import { Box, Button, Divider, Typography } from '@mui/material';
import { FieldArray } from 'react-final-form-arrays';
import FormPage from './FormPage';
import React from 'react';
import { Add } from '@mui/icons-material';
import { phoneNumbersConfig, phoneNumbersType } from './phoneNumbersConfig';

import { getLodashValue } from '../../utils/wrapperLodash';

export interface PhoneType {
    type: string;
    number: string;
}
interface OwnProps {
    fieldName: string;
}
export default function PhoneNumberField({ fieldName }: OwnProps) {
    const { mutators } = useForm();
    const { values } = useFormState();
    const phoneNumbers = getLodashValue(values, fieldName);
    const phoneTypes = phoneNumbers?.map((phone: PhoneType) => phone?.type);

    return (
        <Box
            display={'flex'}
            flexDirection={'column'}
            gap={2}
            p={2}
            width={'100%'}>
            <Box width={'100%'} display={'flex'} alignItems={'flex-start'}>
                <Typography variant={'font13'} fontWeight={'bold'}>
                    Phone Numbers
                </Typography>
            </Box>
            <Divider />
            <FieldArray name={fieldName}>
                {({ fields }) => {
                    return fields.map((name, index) => (
                        <Box
                            key={name}
                            display={'flex'}
                            gap={2}
                            alignItems={'center'}
                            width={'100%'}>
                            <Box width={'100%'} pb={2}>
                                <FormPage
                                    formConfig={phoneNumbersConfig(
                                        name,
                                        phoneTypes,
                                        index,
                                    )}
                                />
                            </Box>
                            <Button
                                variant={'contained'}
                                onClick={() => fields.remove(index)}>
                                Remove
                            </Button>
                        </Box>
                    ));
                }}
            </FieldArray>

            {((phoneNumbers && phoneNumbers.length < phoneNumbersType.length) ||
                !phoneNumbers) && (
                <Button
                    endIcon={<Add />}
                    type="button"
                    variant={'contained'}
                    onClick={() => mutators.push(fieldName)}>
                    Add
                </Button>
            )}
            <Divider />
        </Box>
    );
}
