import React from 'react';
import FormModal from '../../reusable/Modal/FormModal';
import { FormConfig } from '../../design/Forms/interfaces';

interface OwnProps<T> {
    isOpen: boolean;
    onClose: () => void;
    onSave: (value: T) => void;
    initialValues?: T;
    formConfig: FormConfig[];
    header?: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    decorator?: any;
}

export default function AddRowModal<T>({
    isOpen,
    onClose,
    onSave,
    formConfig,
    header,
    decorator,
    initialValues,
}: OwnProps<T>) {
    const handleSave = (value: T) => {
        onSave(value);
        onClose();
    };

    return (
        <FormModal
            initialValues={initialValues}
            decorator={decorator}
            submitButtonText={'Add'}
            header={header}
            isShowing={isOpen}
            formConfig={formConfig}
            handleSubmit={handleSave}
            onClose={onClose}
        />
    );
}
