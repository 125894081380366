import React from 'react';

import { Box, Divider, Typography } from '@mui/material';
import { ReactComponent as ArrowUp } from '../../assets/icons/arrrow_status_up_icon.svg';
export interface ActiveAccount {
    name: string;
    amount: number;
    dynamic?: 'increase' | 'decrease';
    dynamicDescription?: string;
    lastItem?: boolean;
    isHidden?: boolean;
}
export default function ActiveAccountWidget({
    name,
    amount,
    dynamic,
    dynamicDescription,
    lastItem,
    isHidden,
}: ActiveAccount) {
    if (isHidden) return null;
    return (
        <Box display={'flex'} gap={2}>
            <Box display={'flex'} flexDirection={'column'} gap={1}>
                <Typography
                    variant="font12"
                    fontWeight="bold"
                    textTransform={'uppercase'}>
                    {name}
                </Typography>
                <Box display={'flex'} gap={2} alignItems={'center'}>
                    <Typography
                        variant="font32"
                        fontWeight="bold"
                        color={'text.neutral6'}
                        textTransform={'uppercase'}>
                        {amount}
                    </Typography>
                    {dynamic === 'increase' ? <ArrowUp /> : null}
                    {dynamicDescription && (
                        <Typography variant={'font12'} color={'text.neutral6'}>
                            {dynamicDescription}
                        </Typography>
                    )}
                </Box>
            </Box>
            {!lastItem && <Divider orientation={'vertical'} />}
        </Box>
    );
}
