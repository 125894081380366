import React from 'react';

import { Grid, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { tableConfig } from './TableConfig';
import GenericTable from '../../design/GenericTable/GenericTable';

import StubDummyComponent from '../../reusable/StubDummyComponent';
import { useParams } from 'react-router-dom';
import { useGetOneLeaseQuery } from '../../api/leases/leaseAPI';
import { Lease } from '../PaymentsOverview/interfaces';

export default function LeaseMap() {
    const { classes } = useStyles();

    const { id } = useParams();

    const { data } = useGetOneLeaseQuery(id);

    const loadFsaIds = (lease: Lease) => {
        if (lease) return lease.fsaIds;
        return [];
    };

    return (
        <div className={classes.containerWrapper}>
            <div>
                <Typography
                    className={classes.titleWrapper}
                    variant="font20"
                    color="text.secondaryContrast"
                    fontWeight="medium">
                    50 Acres Northwest NW
                </Typography>
                <Grid className={classes.viewWrapper}>
                    <StubDummyComponent
                        title={
                            'This feature will be available once Maps are integrated!'
                        }
                    />
                </Grid>
                <Grid className={classes.detailWrapper}>
                    <GenericTable
                        data={loadFsaIds(data)}
                        totalCount={loadFsaIds(data)?.length}
                        tableConfig={tableConfig}
                    />
                </Grid>
            </div>
        </div>
    );
}
const useStyles = makeStyles()((theme) => ({
    containerWrapper: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100vh',
        backgroundColor: theme.palette.neutralWhite,
    },
    mapImage: {
        height: 390,
    },
    titleWrapper: {
        paddingLeft: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    viewWrapper: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        flexWrap: 'wrap',
        width: '100%',
        padding: theme.spacing(2),
    },
    detailWrapper: {
        display: 'flex',
        flex: 1,
        width: '100%',
        gap: theme.spacing(2),
    },
}));
