import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { Box } from '@mui/material';

import TwoColumnLayout from '../../../layouts/TwoColumnLayout';
import AsideGrowerPreviewTab from './Grower/AsideGrowerPreviewTab';
import SharedDataMetricsPreview from './SharedDataMetricsPreview';
import SharedDataPreviewBanner from './SharedDataPreviewBanner';
import SharedDataDashboardPreview from './Dashboard/SharedDataDashboardPreview';
import SharedDataAllPreviewTabs from './SharedDataAllPreviewTabs';
import StubDummyComponent from '../../../reusable/StubDummyComponent';

export default function SharedDataPreviewPage() {
    const [searchParams] = useSearchParams();
    const landPartnerName = searchParams.get('landPartnerName');
    const landPartnerId = searchParams.get('landPartnerId');
    const visibilityLevel = searchParams.get('visibilityLevel');

    if (!landPartnerName || !landPartnerId || !visibilityLevel) {
        return (
            <StubDummyComponent
                title={
                    'The necessary parameters are missing, make sure to provide landPartnerName, landPartnerId and visibilityLevel'
                }
            />
        );
    }

    const renderLeftComponent = () => (
        <>
            <SharedDataDashboardPreview landPartnerId={landPartnerId} />
            {visibilityLevel === 'METRICS_ONLY' ? (
                <SharedDataMetricsPreview
                    landPartnerId={landPartnerId}
                    visibilityLevel={visibilityLevel}
                />
            ) : (
                <SharedDataAllPreviewTabs
                    landPartnerId={landPartnerId}
                    visibilityLevel={visibilityLevel}
                />
            )}
        </>
    );

    const renderRightComponent = () => (
        <AsideGrowerPreviewTab
            hideSwitchGrower={visibilityLevel === 'METRICS_ONLY'}
        />
    );

    return (
        <Box bgcolor={'backgroundB.backgroundB2'}>
            <SharedDataPreviewBanner landPartnerName={landPartnerName} />
            <TwoColumnLayout
                type={'fiveSixths'}
                leftComponent={renderLeftComponent()}
                rightComponent={renderRightComponent()}
            />
        </Box>
    );
}
