import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import useStyles from './Payments.styles';
import theme from '../../styles/theme';
import PATHS from '../../router/paths';

export default function Payments() {
    const { classes } = useStyles(theme);
    const { pathname } = useLocation();

    if (pathname === `/${PATHS.payments}`)
        return <Navigate to={`/${PATHS.payments}/${PATHS.overview}`} />;

    return (
        <div className={classes.paymentsWrapper}>
            <Navigate to={`/${PATHS.payments}/${PATHS.overview}`} />
            <Outlet />
        </div>
    );
}
