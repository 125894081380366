import React, { ReactElement } from 'react';
import { SpecialZoomLevel, Viewer, Worker } from '@react-pdf-viewer/core';
import {
    toolbarPlugin,
    ToolbarSlot,
    ToolbarProps,
} from '@react-pdf-viewer/toolbar';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material/styles/createTheme';
import theme from '../styles/theme';
import { Divider } from '@mui/material';

interface OwnProps {
    fileUrl: string;
}

export default function PDFViewer({ fileUrl }: OwnProps) {
    const toolbarPluginInstance = toolbarPlugin();
    const { classes } = useToolbarStyles(theme);
    const { Toolbar } = toolbarPluginInstance;
    const renderToolbar = (Toolbar: (props: ToolbarProps) => ReactElement) => (
        <Toolbar>
            {(props: ToolbarSlot) => {
                const {
                    Download,
                    GoToFirstPage,
                    GoToLastPage,
                    CurrentPageInput,
                    EnterFullScreen,
                    GoToNextPage,
                    GoToPreviousPage,
                    Print,
                    Zoom,
                    ZoomIn,
                    ZoomOut,
                } = props;
                return (
                    <div className={classes.toolbar}>
                        <div className={classes.navContainer}>
                            <div className={classes.item}>
                                <GoToFirstPage />
                            </div>
                            <GoToPreviousPage />
                            <div className={classes.currentPage}>
                                <CurrentPageInput />
                            </div>
                            <GoToNextPage />
                            <div className={classes.item}>
                                <GoToLastPage />
                            </div>
                        </div>
                        <Divider className={classes.divider} />
                        <div className={classes.item}>
                            <ZoomOut />
                            <div className={classes.item}>
                                <Zoom
                                    levels={[
                                        0.25, 0.5, 0.75, 1, 1.6, 2, 3, 4, 5,
                                    ]}
                                />
                            </div>
                            <div className={classes.item}>
                                <ZoomIn />
                            </div>
                        </div>
                        <Divider className={classes.divider} />
                        <div className={classes.item}>
                            <EnterFullScreen />
                        </div>
                        <Divider className={classes.divider} />
                        <div className={classes.item}>
                            <Print />
                        </div>
                        <Divider className={classes.divider} />
                        <div className={classes.item}>
                            <Download />
                        </div>
                    </div>
                );
            }}
        </Toolbar>
    );

    return (
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
            <div className={classes.pdfContainer}>
                {renderToolbar(Toolbar)}
                <Viewer
                    fileUrl={fileUrl}
                    defaultScale={SpecialZoomLevel.PageFit}
                    plugins={[toolbarPluginInstance]}
                />
            </div>
        </Worker>
    );
}
const useToolbarStyles = makeStyles<Theme>()((theme) => ({
    toolbar: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(1),
        justifyContent: 'space-between',
        backgroundColor: theme.palette.neutral.neutral2,
        '& svg': {
            fill: 'transparent',
            stroke: theme.palette.brandLightTurq,
            color: theme.palette.brandLightTurq,
            strokeWidth: 2,
        },
    },
    item: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',

        '& button:hover': {
            backgroundColor:
                'var(--rpv-core__minimal-button--hover-background-color)',
        },
        '--rpv-zoom__popover-target-color': 'white',
        '--rpv-zoom__popover-target-arrow-border-color': 'white',
    },
    navContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    currentPage: {
        textAlign: 'center',
        width: 32,
        color: theme.palette.brandLightTurq,
    },

    title: {
        fontSize: '1rem',
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    button: {
        borderRadius: 4,
        paddingLeft: 4,
        height: 32,
        '& svg': {
            fill: 'white',
            marginBottom: theme.spacing(0.5),
        },
    },

    pdfContainer: {
        display: 'flex',
        height: '100%',
        width: '100%',
        color: 'white',
        borderBottomRightRadius: 12,
        borderBottomLeftRadius: 12,
        '&::-webkit-scrollbar': {
            width: 10,
            backgroundColor: 'transparent',
            padding: 10,
            scrollbarHeight: 10,
        },
        '&::-webkit-scrollbar-thumb': {
            width: 5,
            height: 5,
            borderRadius: '5px',
            backgroundColor: 'silver',
        },
        '&::-webkit-scrollbar-button': {
            height: 35,
        },
        overflowY: 'auto',
        overflowX: 'hidden',
    },
    viewer: {
        backgroundColor: theme.palette.neutral.neutral1,
    },
    divider: {
        width: '100%',
        height: 2,
        backgroundColor: theme.palette.neutral.neutral3,
    },
}));
