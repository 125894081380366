import React, { useState } from 'react';
import {
    FormControl,
    Typography,
    Grid,
    IconButton,
    MenuItem,
    Select,
} from '@mui/material';

import { useDeletePaymentFilterMutation } from '../../api/payment/paymentAPI';
import { ReactComponent as TrashIcon } from '../../assets/icons/remove_icon.svg';
import { makeStyles } from 'tss-react/mui';
import theme from '../../styles/theme';
import { FilterItem } from './PaymentFilter';

interface OwnProps {
    filterList: FilterItem[];
    filter: string;
    setFilter: (value: string) => void;
}

export default function FilterList({
    filterList,
    filter,
    setFilter,
}: OwnProps) {
    const [deletePaymentFilter] = useDeletePaymentFilterMutation();
    const { classes } = useStyles();
    const [isOpen, setIsOpen] = useState(false);
    return (
        <Grid item alignItems={'center'} display={'flex'} columnGap={2}>
            <Typography variant="font20" fontWeight="medium">
                Active Filter:
            </Typography>
            <FormControl variant="outlined">
                <Select
                    id={'filterList'}
                    sx={{
                        minWidth: 350,
                        height: 32,
                    }}
                    fullWidth
                    value={filter}
                    onOpen={() => setIsOpen(true)}
                    onClose={() => setIsOpen(false)}
                    onChange={(e) =>
                        e.target.value && setFilter(e.target.value)
                    }
                    displayEmpty>
                    <MenuItem disabled value="">
                        <p>Select Filter</p>
                    </MenuItem>
                    <MenuItem value="none" className={classes.menuItem}>
                        None
                    </MenuItem>
                    {filterList?.map((option: FilterItem) => (
                        <MenuItem
                            className={classes.menuItem}
                            key={option.id}
                            value={option.id}>
                            {option.name}
                            {isOpen && (
                                <IconButton
                                    className={classes.iconButton}
                                    onClick={() =>
                                        deletePaymentFilter({ id: option.id })
                                    }>
                                    <TrashIcon />
                                </IconButton>
                            )}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Grid>
    );
}

const useStyles = makeStyles()(() => ({
    menuItem: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
    },
    iconButton: {
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.1)',
            padding: theme.spacing(1),
            borderRadius: 12,
            transition: 'all 0.2s ease-in-out',
            '& svg': {
                cursor: 'pointer',
            },
        },
        '& svg': {
            fill: theme.palette.brandLightTurq,
            cursor: 'pointer',
        },
    },
}));
