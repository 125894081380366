import { Theme } from '@mui/material/styles/createTheme';
import { makeStyles } from 'tss-react/mui';

import { HeaderHeight, NavigationWidth } from '../../styles/constants';

const useStyles = makeStyles<Theme>()((theme) => ({
    navigationWrapper: {
        width: NavigationWidth,
        minWidth: NavigationWidth,
        backgroundColor: theme.palette.brandDarkTurq,
        height: '100vh',
    },
    logoWrapper: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        height: HeaderHeight,
    },
    navigationItemsContainer: {
        height: `calc(100vh - ${HeaderHeight})`,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        paddingBottom: '18px',
    },
    navigationItem: {
        minHeight: '44px',
        padding: '10px 0 10px 12px',
        marginLeft: '12px',
    },
    activeNavigationItem: {
        backgroundColor: theme.palette.lightTurq.lightTurqShade2,

        '& > a': {
            color: `${theme.palette.brandLimeGreen} !important`,
        },
    },
    navigationItemTitle: {
        display: 'flex',
        gap: '12px',
        alignItems: 'center',
        color: theme.palette.limeGreen.limeGreenTint2,
        fontSize: '13px',
        fontWeight: 500,
        lineHeight: '18px',
        textDecoration: 'unset',
        width: '100%',
    },
    navigationSubItem: {
        borderBottom: `1px solid transparent`,
        margin: '4px 0 4px 35px',
    },

    navigationSubItemTitle: {
        fontSize: '13px',
        fontWeight: 500,
        lineHeight: '18px',
        height: '22px',
        color: theme.palette.limeGreen.limeGreenTint2,
        textDecoration: 'unset',
    },
    subItemActive: {
        borderBottom: `1px solid ${theme.palette.brandLimeGreen} !important`,
    },

    navigationSettingsItemTitle: {
        paddingTop: theme.spacing(1),
        color: `${theme.palette.limeGreen.limeGreenTint5} !important`,
    },
    bottomBlock: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '44px',
        padding: '10px 0 10px 24px',
    },
    activeNavigationSettingsItem: {
        backgroundColor: theme.palette.lightTurq.lightTurqShade2,

        '& > a': {
            color: `${theme.palette.brandLimeGreen} !important`,
        },

        '& svg path': {
            fill: theme.palette.brandLimeGreen,
        },
    },
}));

export default useStyles;
