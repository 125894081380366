import React, { Dispatch, SetStateAction } from 'react';
import { composeValidators, required } from '../../../utils/validation';
import FormModal from '../../../reusable/Modal/FormModal';
import { FormConfig } from '../../../design/Forms/interfaces';
import {
    invalidFileType,
    tooLargeFile,
    tooManyFiles,
} from '../../../utils/fileValidation';
import { useUploadPaymentFileMutation } from '../../../api/payment/paymentAPI';
import useHandleSubmitRTK from '../../../hooks/useHandleSubmit';
import { MAX_FILE_SIZE } from '../../../constants';

export interface ManageModal {
    leaseId: string | undefined;
    isShowing: boolean;
    setIsShowing: Dispatch<SetStateAction<boolean>>;
}

interface ImportPaymentRequest {
    file: File;
    leaseId: string | undefined;
}

export default function UploadPaymentFile({
    leaseId,
    isShowing,
    setIsShowing,
}: ManageModal) {
    const { handleSubmit: handleSubmitRTK, isLoading } = useHandleSubmitRTK({
        useRtkHook: useUploadPaymentFileMutation,
        onSuccess: () => {
            setIsShowing(false);
        },
        successMessage: 'Your payment file has been successfully uploaded',
    });

    function handleSubmit(value: ImportPaymentRequest) {
        handleSubmitRTK({ ...value, leaseId });
    }

    const formConfig: FormConfig[] = [
        {
            formField: {
                name: 'file',
                type: 'fileupload',
                validation: composeValidators(
                    required,
                    tooLargeFile(MAX_FILE_SIZE),
                    tooManyFiles(1),
                    invalidFileType(['application/vnd.ms-excel']),
                ),
            },
            renderProps: {
                filesAccepted: ['.xls'],
                placeholder: 'Please select file from your computer',
                helperText: 'Click here to upload a file',
                multipleFiles: false,
                isDisabled: false,
            },
        },
    ];

    return (
        <FormModal
            header={'Upload Payment file, allowed file types: .xls'}
            isShowing={isShowing}
            formConfig={formConfig}
            handleSubmit={handleSubmit}
            submitButtonText={'Upload'}
            onClose={() => setIsShowing(false)}
            isLoading={isLoading}
        />
    );
}
