import React, { Dispatch, SetStateAction } from 'react';
import { TaskPriority, TaskRelatesToType } from './TasksTable';
import { Button, Divider, Grid } from '@mui/material';
import useHandleSubmitRTK from '../../hooks/useHandleSubmit';
import { useAddTaskMutation } from '../../api/tasks/tasksAPI';
import { dateConverterFromUTC } from '../../utils/dateFormat';
import BaseModal from '../../reusable/BaseModal';
import TaskFormCreate from './TaskFormCreate';
import { Form } from 'react-final-form';
import SubmitButton from '../../design/Buttons/SubmitButton';
import { makeStyles } from 'tss-react/mui';
import { Option } from '../CreateLeaseForm/WizardPageConfigs/leaseUploadConfig';

export interface ManageModal {
    isShowing: boolean;
    setIsShowing: Dispatch<SetStateAction<boolean>>;
}

interface OwnProps extends ManageModal {
    id?: string | null;
}

export interface RelatesToType {
    id: TaskRelatesToType;
    name: string;
}
export interface TaskFormValue {
    name: string;
    dueDate: string;
    assignTo: string;
    priority: TaskPriority;
    description: string;
    relatesTo: {
        type: RelatesToType;
        id: Option;
    };
    relatesToName?: string;
    watchers?: { id: string; name: string }[];
    documents?: File[];
}

export default function TaskFormModalCreate({
    isShowing,
    setIsShowing,
}: OwnProps) {
    const { classes } = useStyles();
    const { handleSubmit: handleCreateSubmit, isLoading: isCreating } =
        useHandleSubmitRTK({
            useRtkHook: useAddTaskMutation,
            onSuccess: () => setIsShowing(false),
            successMessage: 'Task added successfully',
        });
    function handleClose() {
        setIsShowing(false);
    }
    function handleSubmit(values: TaskFormValue) {
        const watchersId = values.watchers?.map((watcher) => watcher.id);
        const dueDateToStringinFormat = values.dueDate
            ? dateConverterFromUTC(values.dueDate)
            : null;
        const convertedData = {
            ...values,
            relatesTo: {
                type: values.relatesTo?.type?.id,
                id: values.relatesTo?.id?.id,
            },
            dueDate: dueDateToStringinFormat,
            watchers: watchersId,
        };
        handleCreateSubmit(convertedData);
    }

    return (
        <BaseModal
            size={'medium'}
            header={'Create a Task'}
            isShowing={isShowing}
            onClose={handleClose}
            isLoading={isCreating}>
            <Form onSubmit={handleSubmit}>
                {({ handleSubmit }) => (
                    <form onSubmit={handleSubmit} className={classes.form}>
                        <Grid
                            container
                            columnSpacing={2}
                            className={classes.formContainer}>
                            <TaskFormCreate />
                        </Grid>
                        <Divider orientation="horizontal" />
                        <div className={classes.bottomContainer}>
                            <Button onClick={handleClose} color={'secondary'}>
                                Cancel
                            </Button>

                            <SubmitButton
                                isDisabled={isCreating}
                                variant={'text'}
                                isLoading={isCreating}
                                text={'Save'}
                            />
                        </div>
                    </form>
                )}
            </Form>
        </BaseModal>
    );
}
const useStyles = makeStyles()((theme) => ({
    form: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        padding: theme.spacing(1, 0),
    },
    formContainer: {
        height: 'calc(90vh - 170px)',
        width: '100%',
        backgroundColor: theme.palette.neutral.neutral1,
        padding: theme.spacing(4),
        marginLeft: theme.spacing(0),
        overflowY: 'auto',
        overflowX: 'hidden',
    },

    bottomContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        gap: '16px',
        height: 70,
        width: '100%',
        padding: theme.spacing(3),
        backgroundColor: theme.palette.neutralWhite,
    },
}));
