import React from 'react';
import { Box, IconButton } from '@mui/material';
import { ReactComponent as EditIcon } from '../../assets/icons/edit_icon.svg';
import { ReactComponent as RemoveIcon } from '../../assets/icons/remove_icon.svg';

interface OwnProps {
    onEdit: () => void;
    onDelete: () => void;
}
export default function TableActions({ onEdit, onDelete }: OwnProps) {
    return (
        <Box>
            <IconButton onClick={onEdit}>
                <EditIcon />
            </IconButton>
            <IconButton onClick={onDelete}>
                <RemoveIcon />
            </IconButton>
        </Box>
    );
}
