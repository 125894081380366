import React, { useEffect, useState } from 'react';

import { Box, Typography } from '@mui/material';

import Uploader from './Uploader';

export type FileExtension =
    | '.png'
    | '.jpg'
    | '.jpeg'
    | '.bmp'
    | '.pdf'
    | '.csv'
    | '.zip'
    | '.xls'
    | '.x-zip-compressed';
export interface UploadFile {
    onChange: (value: File[]) => void;
    filesAccepted?: FileExtension[];
    maxFileSize?: number;
    maxFile?: number;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onDrop?: (e: any) => void;
    value?: File[];
    placeholder?: string;
    isDisabled?: boolean;
    errorText?: string;
    name?: string;
    helperText?: string;
    multipleFiles?: boolean;
    isHidden?: boolean;
}

export default function UploadFileField({
    onChange,
    errorText,
    isHidden,
    value,
    ...uploadProps
}: UploadFile) {
    const [uploadedFiles, setUploadedFiles] = useState<File[]>(value || []);

    useEffect(() => {
        value ? setUploadedFiles(value) : setUploadedFiles([]);
    }, [value]);

    if (isHidden) return null;

    return (
        <Box display={'flex'} flexDirection={'column'} gap={1}>
            <Uploader
                onChange={onChange}
                uploadedFiles={uploadedFiles}
                setUploadedFiles={setUploadedFiles}
                {...uploadProps}
            />
            {!!errorText && (
                <Typography variant={'font12'} color={'text.error'}>
                    {errorText}
                </Typography>
            )}
        </Box>
    );
}
