import FarmMainInfo, { MainInfo } from './FarmMainInfo';
import React from 'react';
import FarmOtherInfo, { OtherInfo } from './FarmOtherInfo';
import DashboardWrapper from '../../../reusable/DashboardWrapper';

interface OwnProps {
    mainInfo: MainInfo;
    otherInfo: OtherInfo;
}
export default function FarmDetailsDashboard({
    mainInfo,
    otherInfo,
}: OwnProps) {
    return (
        <DashboardWrapper>
            <FarmMainInfo {...mainInfo} />
            <FarmOtherInfo {...otherInfo} />
        </DashboardWrapper>
    );
}
