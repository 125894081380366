import { Box } from '@mui/material';
import AppTypography from '../../design/AppTypography';
import IntegrationList from './IntegrationList';
import theme from '../../styles/theme';

export default function QuickBookDataImport() {
    return (
        <Box display={'flex'} flexDirection={'column'} rowGap={2}>
            <AppTypography
                variant={'font36'}
                fontWeight={'light'}
                color={'text.neutral7'}
                text={'QuickBooks Data Import'}
            />
            <AppTypography
                variant={'font16'}
                fontWeight={'light'}
                color={'text.neutral7'}
                text={'[additional instructions]'}
            />
            <Box
                display={'flex'}
                flexDirection={'column'}
                rowGap={2}
                borderRadius={2}
                p={2}
                bgcolor={theme.palette.neutralWhite}>
                <IntegrationList />
            </Box>
        </Box>
    );
}
