import React, { ReactNode } from 'react';
import clsx from 'clsx';
import { makeStyles } from 'tss-react/mui';
import { TreeNodeDatum } from 'react-d3-tree';

export interface OwnProps<T> {
    nodeDatum: TreeNodeDatum & T;
    isDisabled?: boolean;
    children?: ReactNode;
}
export default function TreeNode<T>({ isDisabled, children }: OwnProps<T>) {
    const { classes } = useStyles();
    return (
        <>
            <g style={{ fill: 'none', stroke: 'none' }}>
                <foreignObject className={classes.treeNode}>
                    <div
                        className={clsx(
                            classes.container,
                            isDisabled && classes.disabled,
                        )}>
                        {children}
                    </div>
                </foreignObject>
            </g>
        </>
    );
}

const useStyles = makeStyles()(() => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        borderStyle: 'none',
    },
    disabled: {
        opacity: 0.6,
    },
    treeNode: {
        width: 220,
        height: 250,
        y: -110,
        x: -110,
    },
}));
