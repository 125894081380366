import React, { useState } from 'react';
import { Box, Collapse, IconButton, Typography } from '@mui/material';
import PrimaryAdditionalInfo, {
    PrimaryAdditionalInfoProps,
} from './PrimaryAdditionalInfo';
import clsx from 'clsx';

import { ReactComponent as ExpandIcon } from '../../../assets/icons/expand_icon.svg';
import theme from '../../../styles/theme';
import { Member } from './MemberProfile';
import MembersList from './MembersList';

import { makeStyles } from 'tss-react/mui';

interface OwnProps {
    primaryInfo: PrimaryAdditionalInfoProps;
    primaryContact: Member[];
    members?: Member[];
    membersCount: number;
    landPartnerId?: string;
}

export default function LandPartnerAdditionalSection({
    primaryInfo,
    primaryContact,
    members,
    membersCount,
    landPartnerId,
}: OwnProps) {
    const [expanded, setExpanded] = useState(true);
    const { classes } = useStyles();
    function handleExpand() {
        setExpanded(!expanded);
    }
    return (
        <Box width={'100%'}>
            <PrimaryAdditionalInfo {...primaryInfo} />
            <Box
                width={'100%'}
                display={'flex'}
                justifyContent={'flex-end'}
                alignItems={'center'}
                columnGap={2}>
                <div>
                    <Typography
                        variant={'font12'}
                        color={'text.link'}
                        fontWeight={'medium'}>
                        {expanded ? 'Collapse' : 'Expand'}
                    </Typography>
                    <IconButton onClick={handleExpand}>
                        <ExpandIcon
                            className={clsx(expanded && classes.expanded)}
                        />
                    </IconButton>
                </div>
            </Box>
            <Collapse
                in={expanded}
                timeout="auto"
                unmountOnExit
                sx={{
                    width: '100%',
                    paddingBottom: theme.spacing(4),
                }}>
                <Box display={'flex'} columnGap={3} width={'100%'}>
                    {primaryContact && (
                        <MembersList
                            members={primaryContact}
                            title={'Primary Contact'}
                        />
                    )}
                    {members && (
                        <MembersList
                            landPartnerId={landPartnerId}
                            members={members}
                            title={'Members'}
                            membersCount={membersCount}
                        />
                    )}
                </Box>
            </Collapse>
        </Box>
    );
}
const useStyles = makeStyles()(() => ({
    expanded: {
        width: 24,
        height: 24,
        transform: 'rotate(180deg)',
    },
}));
