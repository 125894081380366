import { Box, Typography } from '@mui/material';
import FormPage from '../../reusable/FormUI/FormPage';
import {
    composeValidators,
    isNumber,
    maxLength,
    minLength,
    required,
} from '../../utils/validation';
import { useForm, useFormState } from 'react-final-form';
import { useEffect } from 'react';
import { FormConfig } from '../../design/Forms/interfaces';
import { Option } from './WizardPageConfigs/leaseUploadConfig';

export default function LumpSumForm() {
    const form = useForm();
    const { change } = form;
    const { values } = useFormState();
    const isLumpSum = values?.doLumpSum === 'lumpSum';

    const unregisterFormField = (fieldName: string) => {
        change(fieldName, undefined);
    };

    useEffect(() => {
        if (values?.doLumpSum === 'paymentsByField') {
            unregisterFormField('lumpSum');
        }
    }, [values?.doLumpSum]);

    return (
        <Box
            display={'flex'}
            width={'100%'}
            flexDirection={'column'}
            rowGap={2}>
            <Typography variant={'font16'} fontWeight={'medium'}>
                Does the lease include one lump sum payment or payments by
                fields?
            </Typography>
            <FormPage formConfig={lumpSumFormConfig} />
            {isLumpSum && <FormPage formConfig={paymentConfig} />}
        </Box>
    );
}

const lumpSumOptions: Option[] = [
    {
        id: 'lumpSum',
        name: 'Lump Sum',
    },
    {
        id: 'paymentsByField',
        name: 'Payments by Field',
    },
];
const lumpSumFormConfig: FormConfig[] = [
    {
        formField: {
            scheme: 'full',
            name: 'doLumpSum',
            type: 'radio',
            label: '',
            isRequired: true,
            validation: composeValidators(required),
        },
        renderProps: {
            options: lumpSumOptions,
            isDisabled: false,
            size: 'third',
        },
    },
];
const paymentConfig: FormConfig[] = [
    {
        formField: {
            scheme: 'half',
            name: 'lumpSum',
            type: 'input',
            label: 'Payment',
            validation: composeValidators(
                required,
                isNumber,
                minLength(1),
                maxLength(256),
            ),
        },
        renderProps: {
            isDisabled: false,
            prefix: '$',
            size: 'small',
        },
    },
];
