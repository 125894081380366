import { TableColumn } from '../../../design/GenericTable/GenericTable';
import { solutionsInProgressMockData } from './solutionsInProgressMock';
import React from 'react';
import LinkText from '../../../design/Fields/LinkText';
import PercentageCell from './PercentageCell';

export interface SolutionInProgress {
    id: string;
    name: string;
    solution: string;
    totalDays: number;
    lastActiveDate: string;
    progress: number;
}

export default function useSolutionsInProgressTable() {
    function renderContinueButton(
        id: string | undefined,
        progress: number | undefined,
    ) {
        if (!id) return null;
        progress = progress || 0;
        const stepNumber = Math.floor((progress / 100) * 12);
        return (
            <LinkText
                to={`/services/disasterPlanning/create`}
                text={'Continue'}
                variant={'font14'}
                state={{ stepNumber: stepNumber }}
                sx={{ textDecoration: 'underline' }}
            />
        );
    }

    const columns: TableColumn<SolutionInProgress>[] = [
        {
            header: {
                label: 'Name',
                sortable: false,
            },
            cellRender: (data) => <>{data?.name}</>,
            format: { align: 'left', color: { color: 'primary' } },
            width: '20%',
        },
        {
            header: {
                label: 'Solution',
                sortable: false,
            },
            cellRender: (data) => <>{data?.solution}</>,
            format: { align: 'left', color: { color: 'primary' } },
            width: '19%',
        },
        {
            header: {
                label: 'Total Days',
                sortable: false,
            },
            cellRender: (data) => <>{data?.totalDays}</>,
            format: { align: 'left', color: { color: 'primary' } },
            width: '16%',
        },
        {
            header: {
                label: 'Last Active',
                sortable: false,
            },
            cellRender: (data) => <>{data?.lastActiveDate}</>,
            format: { align: 'left', color: { color: 'primary' } },
            width: '19%',
        },
        {
            header: {
                label: 'Progress',
                sortable: false,
            },
            cellRender: (data) => (
                <PercentageCell progress={data?.progress || 0} />
            ),
            format: { align: 'left', color: { color: 'primary' } },
            width: '16%',
        },

        {
            cellRender: (data) =>
                renderContinueButton(data?.id, data?.progress),
            format: { align: 'left', color: { color: 'primary' } },
            width: '10%',
        },
    ];
    const tableConfig = {
        columns,
        pageable: false,
        expandable: false,
    };
    return { data: solutionsInProgressMockData || [], tableConfig };
}
