import { Box, Typography } from '@mui/material';
import UserAvatar from '../../design/Avatar/UserAvatar';
interface OwnProps {
    name: string;
    description: string;
    avatar?: string;
}
export default function PersonAvatarWithDescription({
    avatar,
    name,
    description,
}: OwnProps) {
    return (
        <Box display={'flex'} gap={1}>
            <UserAvatar size={'small'} name={name} avatar={avatar} />
            <Box display={'flex'} flexDirection={'column'}>
                <Typography color={'text.link'}> {name}</Typography>
                <Typography variant={'font12'} color={'text.secondaryContrast'}>
                    {description}
                </Typography>
            </Box>
        </Box>
    );
}
