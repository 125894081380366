import CollapsedComponent from '../../../design/CollapsedComponent';

interface OwnProps {
    title?: string;
}
export default function DocumentGroupListItem({ title }: OwnProps) {
    return (
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        <CollapsedComponent title={title} onClick={() => {}}>
            some data
        </CollapsedComponent>
    );
}
