import { Features } from './OrganizationSubscriptionPage';

export const messagesFieldLabels: Record<string, string> = {
    fieldManagement: 'Field Management',
    landPartnerManagement: 'Land Partner Management',
    landPartnerSharing: 'Land Partner Sharing',
    leaseManagement: 'Lease Management',
    paymentManagement: 'Payment Management',
    taskManagement: 'Task Management',
    contentLockbox: 'Content Lockbox & Sharing ',
    successionPlanning: 'Succession Planning ',
    disasterPlanning: 'Disaster Planning',
    quickBooks: 'QuickBooks',
    johnDeereOps: 'John Deere Ops',
};
export const mapTypeFeatures = {
    BASIC: [
        'fieldManagement',
        'landPartnerManagement',
        'leaseManagement',
        'paymentManagement',
    ],
    PREMIUM: [
        'fieldManagement',
        'landPartnerManagement',
        'landPartnerSharing',
        'leaseManagement',
        'paymentManagement',
        'taskManagement',
    ],
    ENTERPRISE: [
        'fieldManagement',
        'landPartnerManagement',
        'landPartnerSharing',
        'leaseManagement',
        'paymentManagement',
        'taskManagement',
    ],
};
export const farmLandManagerMapTypeFeatures = {
    BASIC: ['landPartnerManagement', 'contentLockbox'],
    PREMIUM: ['landPartnerManagement', 'taskManagement', 'contentLockbox'],
    ENTERPRISE: ['landPartnerManagement', 'taskManagement', 'contentLockbox'],
};
export const successionPlannersMapTypeFeatures = {
    BASIC: ['landPartnerManagement', 'contentLockbox', 'successionPlanning'],
    PREMIUM: [
        'landPartnerManagement',
        'taskManagement',
        'contentLockbox',
        'successionPlanning',
    ],
    ENTERPRISE: [
        'landPartnerManagement',
        'taskManagement',
        'contentLockbox',
        'successionPlanning',
    ],
};
export const mapTypeIntegrations = {
    BASIC: [],
    PREMIUM: ['disasterPlanning', 'quickBooks', 'johnDeereOps'],
    ENTERPRISE: ['disasterPlanning', 'quickBooks', 'johnDeereOps'],
};
export const mockFeatures: Features[] = [
    {
        type: 'BASIC',
        features: {
            fieldManagement: true,
            landPartnerManagement: true,
            leaseManagement: true,
            paymentManagement: true,
        },
    },
    {
        type: 'PREMIUM',
        features: {
            fieldManagement: true,
            landPartnerManagement: true,
            landPartnerSharing: false,
            leaseManagement: true,
            paymentManagement: true,
            taskManagement: true,
            disasterPlanning: true,
            quickBooks: true,
            johnDeereOps: true,
        },

        isMostPopular: true,
    },
    {
        type: 'ENTERPRISE',
        features: {
            fieldManagement: true,
            landPartnerManagement: true,
            landPartnerSharing: false,
            leaseManagement: true,
            paymentManagement: true,
            taskManagement: true,
            disasterPlanning: true,
            quickBooks: true,
            johnDeereOps: true,
        },
    },
];
export const farmLandManagerMockFeatures: Features[] = [
    {
        type: 'BASIC',
        features: {
            landPartnerManagement: true,
            contentLockbox: true,
        },
    },
    {
        type: 'PREMIUM',
        features: {
            landPartnerManagement: true,
            contentLockbox: true,
            taskManagement: true,
            disasterPlanning: true,
            quickBooks: true,
            johnDeereOps: true,
        },

        isMostPopular: true,
    },
    {
        type: 'ENTERPRISE',
        features: {
            landPartnerManagement: true,
            contentLockbox: true,
            taskManagement: true,
            disasterPlanning: true,
            quickBooks: true,
            johnDeereOps: true,
        },
    },
];
export const successionPlannersMockFeatures: Features[] = [
    {
        type: 'BASIC',
        features: {
            landPartnerManagement: true,
            contentLockbox: true,
            successionPlanning: true,
        },
    },
    {
        type: 'PREMIUM',
        features: {
            landPartnerManagement: true,
            contentLockbox: true,
            successionPlanning: true,
            taskManagement: true,
            disasterPlanning: true,
            quickBooks: true,
            johnDeereOps: true,
        },

        isMostPopular: true,
    },
    {
        type: 'ENTERPRISE',
        features: {
            landPartnerManagement: true,
            contentLockbox: true,
            successionPlanning: true,
            taskManagement: true,
            disasterPlanning: true,
            quickBooks: true,
            johnDeereOps: true,
        },
    },
];
export function returnFeatures(type: string) {
    if (type === 'FARMLAND_MANAGER') {
        return farmLandManagerMockFeatures;
    }
    if (type === 'SUCCESSION_PLANNERS') {
        return successionPlannersMockFeatures;
    }
    return mockFeatures;
}

export function returnFeaturesMap(type: string) {
    if (type === 'FARMLAND_MANAGER') {
        return farmLandManagerMapTypeFeatures;
    }
    if (type === 'SUCCESSION_PLANNERS') {
        return successionPlannersMapTypeFeatures;
    }
    return mapTypeFeatures;
}
