import GenericTable from '../../design/GenericTable/GenericTable';
import { usePaymentOverviewPaid } from './usePaymentOverviewPaid';
import { Grid } from '@mui/material';
import Loader from '../../design/BaseLoader';

export default function PaymentOverviewPaid() {
    const { data, totalCount, tableConfig, isLoading } =
        usePaymentOverviewPaid();
    if (isLoading) {
        return (
            <Grid
                container
                sx={{ height: '100vh' }}
                alignItems={'center'}
                justifyContent={'center'}
                flexDirection={'column'}>
                <Loader />
            </Grid>
        );
    }

    return (
        <GenericTable
            data={data}
            totalCount={totalCount}
            tableConfig={tableConfig}
        />
    );
}
