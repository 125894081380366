import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useHandleSubmitRTK from '../../../hooks/useHandleSubmit';
import { useDocumentUploadMutation } from '../../../api/documents/documentsAPI';
import BaseModal from '../../../reusable/BaseModal';
import { Box, Button } from '@mui/material';
import BulkUploadTable from './BulkUploadTable';
import { nanoid } from 'nanoid';
import { DocumentCategory } from './useDocumentsTable';
import theme from '../../../styles/theme';

interface OwnProps {
    removeFile: (name: string) => void;
    formReset: () => void;
    isShowing: boolean;
    handleClose: () => void;
    files?: File[];
}

export interface TableDocumentData {
    id: string;
    documentName: string;
    documentSize: string;
    documentCategory: DocumentCategory | null;
    documentPurpose: string;
    sharingAllowed: boolean;
}

export default function UploadBulkDocumentsModal({
    isShowing,
    removeFile,
    handleClose,
    formReset,
    files,
}: OwnProps) {
    const { id } = useParams();
    const [tableData, setTableData] = useState<TableDocumentData[]>([]);

    const { handleSubmit: handleUpload, isLoading: isDocumentUploading } =
        useHandleSubmitRTK({
            useRtkHook: useDocumentUploadMutation,
            successMessage: 'File uploaded successfully',
            onSuccess: () => {
                handleClose();
                formReset();
            },
        });

    function handleSubmit() {
        tableData.forEach((data) => {
            handleUpload({
                documents:
                    files &&
                    (files.find(
                        (file) => file.name === data.documentName,
                    ) as File),
                parentId: id,
                documentPurpose: data.documentPurpose,
                documentType: 'LAND_PARTNER',
                documentCategory: data.documentCategory,
                sharingAllowed: data.sharingAllowed,
            });
        });
        setTableData([]);
    }

    useEffect(() => {
        if (files) {
            setTableData((prev) => {
                const existingFiles = prev.filter((prevFile) =>
                    files.some((file) => file.name === prevFile.documentName),
                );

                const newFiles = files
                    .filter(
                        (file) =>
                            !prev.some(
                                (prevFile) =>
                                    prevFile.documentName === file.name,
                            ),
                    )
                    .map((file) => ({
                        id: nanoid(),
                        documentName: file.name,
                        documentSize: file.size.toString(),
                        documentPurpose: '',
                        documentCategory: null,
                        sharingAllowed: true,
                    }));

                return [...existingFiles, ...newFiles];
            });
        } else {
            setTableData([]);
        }
    }, [files]);

    return (
        <BaseModal
            size={'small'}
            isShowing={isShowing}
            onClose={handleClose}
            header={'Document Uploads'}>
            <>
                <Box
                    display={'flex'}
                    flexDirection={'column'}
                    rowGap={2}
                    pt={2}
                    pb={2}
                    border={`1px solid ${theme.palette.backgroundB.backgroundB4}`}
                    width={'100%'}>
                    <BulkUploadTable
                        removeFile={removeFile}
                        tableData={tableData}
                        setTableData={setTableData}
                    />
                </Box>
                <Box
                    width={'100%'}
                    display={'flex'}
                    justifyContent={'flex-end'}
                    p={3}
                    bgcolor={theme.palette.neutralWhite}>
                    <Button
                        variant={'contained'}
                        onClick={handleSubmit}
                        disabled={isDocumentUploading}>
                        Upload
                    </Button>
                </Box>
            </>
        </BaseModal>
    );
}
