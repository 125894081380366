import { Box, Typography } from '@mui/material';

import React from 'react';
import { Attachment } from '../../features/ViewLandownersDetails/LandPartnerActivity';
import {
    dateTimeConverterFromTimestamp,
    dateTimeConverterFromUTC,
} from '../../utils/dateFormat';

export interface NoteDetailsProps {
    id: string;
    pinned: boolean;
    content: string;
    name: string;
    actualContactDate: string | number;
    communicationType: string;
    createdAt: string;
    updatedAt: string;
    attachment?: Attachment;
}

interface OwnProps {
    data: NoteDetailsProps;
}

export default function NoteDetails({
    data: { name, actualContactDate, createdAt, communicationType, updatedAt },
}: OwnProps) {
    return (
        <Box
            display={'flex'}
            columnGap={2}
            width={'100%'}
            flexDirection={'column'}>
            <Typography
                variant="font16"
                color="text.secondary"
                fontWeight="medium">
                {name}
            </Typography>
            <Typography variant="font11" color={'text.neutral6'}>
                Created: {createdAt}
            </Typography>
            <Typography variant="font11" color={'text.neutral6'}>
                Modified: {updatedAt}
            </Typography>
            {actualContactDate && (
                <Typography variant="font11" color={'text.neutral6'}>
                    Actual contact date:{' '}
                    {/* eslint-disable-next-line no-nested-ternary */}
                    {actualContactDate
                        ? typeof actualContactDate === 'number'
                            ? dateTimeConverterFromTimestamp(actualContactDate)
                            : dateTimeConverterFromUTC(actualContactDate)
                        : 'N/A'}
                </Typography>
            )}
            {communicationType && (
                <Typography variant="font11">
                    {communicationTypeMapper[communicationType]}
                </Typography>
            )}
        </Box>
    );
}

export const communicationTypeMapper: Record<string, string> = {
    IN_PERSON: 'In Person',
    PHONE_CALL: 'Phone Call',
    TEXT_MESSAGE: 'Text Message',
    GENERAL_NOTE: 'General Note',
    EMAIL: 'Email',
    POSTAL_MAIL: 'Postal Mail',
};
