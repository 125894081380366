import IntegrationCard, { IntegrationCardProps } from './IntegrationCard';
import { Grid } from '@mui/material';

interface IntegrationCardListProps {
    integrations: IntegrationCardProps[];
}

export default function IntegrationCardList({
    integrations,
}: IntegrationCardListProps) {
    return (
        <Grid container columnGap={2} flexWrap={'wrap'}>
            {integrations.map((integration, index) => (
                <IntegrationCard key={index} {...integration} />
            ))}
        </Grid>
    );
}
