import React from 'react';
import { Grid, Typography } from '@mui/material';
import useStyles from './Settings.styles';
import theme from '../../styles/theme';
import * as _ from 'lodash';
import SettingsWidget from './SettingWidget/SettingWidget';
import { useGeneralManagerSettingsWidget } from './useGeneralManagerSettingsWidget';
import Loader from '../../design/BaseLoader';

export default function GeneralManagerSettings() {
    const { classes } = useStyles(theme);
    const { landPartnerSharingWidget, integrationsWidget, isLoading } =
        useGeneralManagerSettingsWidget();

    if (isLoading) {
        return (
            <Grid
                width={'100%'}
                height={'50vh'}
                container
                alignItems={'center'}
                justifyContent={'center'}>
                <Loader />
            </Grid>
        );
    }
    return (
        <div className={classes.settingsWrapper}>
            <Typography className={classes.widgetGroupTitle}>
                General Manager Settings
            </Typography>
            <Grid container columnGap={2} flexWrap={'wrap'}>
                {_.map(
                    landPartnerSharingWidget,
                    (widget) =>
                        !widget.isHidden && (
                            <Grid item key={widget.title} columnGap={2}>
                                <SettingsWidget {...widget} />
                            </Grid>
                        ),
                )}
            </Grid>
            <Typography className={classes.widgetGroupTitle}>
                Integrations
            </Typography>
            <Grid container spacing={2}>
                {_.map(
                    integrationsWidget,
                    (widget) =>
                        !widget.isHidden && (
                            <Grid item xs={3} key={widget.title} columnGap={2}>
                                <SettingsWidget {...widget} />
                            </Grid>
                        ),
                )}
            </Grid>
        </div>
    );
}
