import { SearchContext } from './SearchContext';
import { useContext } from 'react';
import { SearchResult, SearchType } from './SearchCard';
import FilterOptionButton from './FilterOptionButton';
import { Box } from '@mui/material';

export default function FilterOptionList() {
    const {
        data,
        setView,
        setType,
        type: filterType,
    } = useContext(SearchContext);

    if (!data) {
        return null;
    }
    return (
        <Box display={'flex'} flexWrap={'wrap'} columnGap={1} maxWidth={'60%'}>
            <FilterOptionButton
                isChosen={filterType === 'ALL'}
                setChosen={() => {
                    setType('ALL');
                    setView(data);
                }}
                label={`All (${data?.length})`}
            />

            {Object.keys(reduceDataByType(data)).map((type) => {
                return (
                    <FilterOptionButton
                        key={type}
                        isChosen={filterType === type}
                        setChosen={() => {
                            setType(type as SearchType);
                            setView(reduceDataByType(data)[type as SearchType]);
                        }}
                        label={`${labelMapper[type as SearchType]} (${reduceDataByType(data)[type as SearchType]?.length})`}
                    />
                );
            })}
        </Box>
    );
}

function reduceDataByType(
    data: SearchResult[],
): Record<SearchType, SearchResult[]> {
    return data.reduce(
        (acc, item) => {
            if (!acc[item.type]) {
                acc[item.type] = [];
            }
            acc[item.type].push(item);
            return acc;
        },
        {} as Record<SearchType, SearchResult[]>,
    );
}

const labelMapper: Record<SearchType, string> = {
    ALL: 'All',
    LEASE: 'Lease',
    GROWER: 'Grower',
    NOTE: 'Note',
    PAYMENT: 'Payment',
    TASK: 'Task',
    PROFILE: 'Profile',
    LEASE_NOTE: 'Lease Note',
    LAND_PARTNER: 'Land Partner',
    DOCUMENT: 'Document',
};
