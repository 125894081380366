import { Box, Typography } from '@mui/material';
import theme from '../../../styles/theme';
import SearchExistingContact, {
    FetchedSearchData,
} from '../../../reusable/SearchExistingContact/SearchExistingContact';
import useHandleSubmitRTK from '../../../hooks/useHandleSubmit';
import { useAssignContactMutation } from '../../../api/contacts/contactsAPI';

import { useParams } from 'react-router-dom';
interface OwnProps {
    parentContactId: string;
}

export default function FindExistingContactWidget({
    parentContactId,
}: OwnProps) {
    const { id: landPartnerId } = useParams();
    const { handleSubmit } = useHandleSubmitRTK({
        useRtkHook: useAssignContactMutation,

        successMessage: 'Contact created successfully',
    });

    function handleSelect(option: FetchedSearchData) {
        const submitValue = {
            landPartnerId,
            contactId: option.id,
            parentContactId:
                landPartnerId === parentContactId ? null : parentContactId,
        };
        handleSubmit(submitValue);
    }
    return (
        <Box
            display={'flex'}
            alignItems={'center'}
            flexDirection={'column'}
            rowGap={3}
            width={290}
            borderRadius={1}
            sx={{
                backgroundColor: theme.palette.limeGreen.limeGreenTint3,
                padding: theme.spacing(4.5, 3),
            }}>
            <SearchExistingContact
                onSelect={handleSelect}
                landPartnerId={landPartnerId}
            />
            <Typography fontWeight={'medium'}>
                Search for an existing contact
            </Typography>
        </Box>
    );
}
