import { FormConfig } from '../../../design/Forms/interfaces';
import { composeValidators, required } from '../../../utils/validation';

export const leaseBonusPaymentConfig: FormConfig[] = [
    {
        formField: {
            scheme: 'quarter',
            name: 'bonusSchedule',
            type: 'datepicker',
            label: '',
            isRequired: true,
            validation: composeValidators(required),
        },
        renderProps: {
            placeholder: `MMM, dd`,
            isDisabled: false,
            size: 'small',
            isDateWithoutYear: true,
            views: ['day', 'month'],
            format: 'MMM dd',
        },
    },
];
