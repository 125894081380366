import React, { useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';

import { Grid } from '@mui/material';

import Loader from '../../design/BaseLoader';
import { useLazyGetRelationshipByLandPartnerIdQuery } from '../../api/contacts/contactsAPI';

import ContactOrganizationTree from './ContactOrganizationTree';
import ContactLandPartnerTiles from './ContactLandPartnerTiles';
import { Option } from '../CreateLeaseForm/WizardPageConfigs/leaseUploadConfig';
import { ContactProfile } from '../../pages/AllProfiles/AllProfiles';

interface OwnProps {
    contactData: ContactProfile;
}

export default function ContactRelationship({ contactData }: OwnProps) {
    const [linkedLandPartners, setLinkedLandPartners] = useState<Option>(
        contactData?.landPartners[0],
    );

    const { classes } = useStyles();

    const [
        getRelationships,
        {
            isFetching: fetchingRelationshipTree,
            isLoading: loadingRelationshipTree,
            data: relationshipData,
        },
    ] = useLazyGetRelationshipByLandPartnerIdQuery();
    useEffect(() => {
        linkedLandPartners?.id && getRelationships(linkedLandPartners?.id);
    }, [linkedLandPartners?.id]);
    if (!linkedLandPartners?.id) return null;
    if (fetchingRelationshipTree || loadingRelationshipTree) {
        return (
            <Grid
                container
                sx={{ height: '100vh' }}
                alignItems={'center'}
                justifyContent={'center'}
                flexDirection={'column'}>
                <Loader />
            </Grid>
        );
    }

    return (
        <div className={classes.container}>
            <ContactOrganizationTree
                cardData={{
                    ...relationshipData,
                    isRoot: true,
                }}
            />
            <ContactLandPartnerTiles
                setSelectedOption={setLinkedLandPartners}
                options={contactData?.landPartners}
            />
        </div>
    );
}

const useStyles = makeStyles()((theme) => ({
    container: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        minHeight: '70vh',
        backgroundColor: theme.palette.neutralWhite,
        padding: theme.spacing(2, 4, 2, 5),
    },
}));
