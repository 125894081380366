import React from 'react';
import ConfirmModal from '../../../layouts/ConfirmModal/ConfirmModal';
import { useParams } from 'react-router-dom';
import { useChangePrimaryContactMutation } from '../../../api/landpartners/landPartnersAPI';

interface OwnProps {
    isShowing: boolean;
    onClose: () => void;
    currentId: string;
}

export default function MakeContactPrimaryModal({
    isShowing,
    currentId,
    onClose,
}: OwnProps) {
    const [makePrimary] = useChangePrimaryContactMutation();
    const { id: landPartnerId } = useParams();
    const handleMakePrimary = () => {
        makePrimary({
            landPartnerId: landPartnerId,
            newPrimaryContactId: currentId,
        });
    };

    return (
        <ConfirmModal
            isShowing={isShowing}
            hide={onClose}
            title={`Please confirm you want to make this contact primary?`}
            info={''}
            onSubmit={handleMakePrimary}
        />
    );
}
