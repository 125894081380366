import { Button, Typography } from '@mui/material';

import theme from '../../../../styles/theme';
import useStyles from './AllUsers.styles';

import useModal from '../../../../hooks/useModal';
import AddUser from '../AddUser/AddUser';
import UsersList from './UsersList';

export default function AllUsers() {
    const { classes } = useStyles(theme);
    const { isShowing, setIsShowing } = useModal();
    return (
        <div className={classes.allUsersWrapper}>
            <Typography variant={'font16'}>
                Modify access based on your teams roles, or modify permissions
                for specific users. [additional descriptive text to be
                determinded]
            </Typography>
            <div className={classes.controlPanel}>
                <Button
                    onClick={() => setIsShowing(true)}
                    color={'secondary'}
                    size={'medium'}>
                    Add User +
                </Button>
            </div>
            <UsersList />
            <AddUser isShowing={isShowing} setIsShowing={setIsShowing} />
        </div>
    );
}
