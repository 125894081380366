import { PhoneType } from '../../reusable/FormUI/PhoneNumberField';
import ChipForPhone from './ChipForPhone';
import { Box, Typography } from '@mui/material';
import { useState } from 'react';

interface OwnProps {
    phoneList: PhoneType[];
}
export default function ExpandablePhoneList({ phoneList }: OwnProps) {
    const [showMore, setShowMore] = useState(false);
    return (
        <Box display={'flex'} flexDirection={'column'} rowGap={1} p={1}>
            <ChipForPhone
                key={phoneList[0]?.type}
                phone={phoneList[0]?.number}
                phoneType={phoneList[0]?.type}
            />
            {showMore
                ? phoneList
                      .slice(1)
                      .map((phone) => (
                          <ChipForPhone
                              key={phone.type}
                              phone={phone.number}
                              phoneType={phone.type}
                          />
                      ))
                : phoneList.length > 1 && (
                      <Typography
                          variant={'font12'}
                          component={'span'}
                          color={'text.link'}
                          sx={{ cursor: 'pointer' }}
                          onClick={() => setShowMore(true)}>
                          ...More {`(${phoneList.length - 1})`}
                      </Typography>
                  )}
        </Box>
    );
}
