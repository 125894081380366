import React, { useEffect } from 'react';
import { Box, Skeleton } from '@mui/material';
import theme from '../../styles/theme';
import { PaymentScheduledData } from './interfaces';
import ExpandComponentColumn from './ExpandComponentColumn';
import { dateConverterFromUTC } from '../../utils/dateFormat';

import FileWithDownloadButton from '../../reusable/FileWithDownloadButton';
import { useLazyDocumentsListByParentIDQuery } from '../../api/documents/documentsAPI';
interface OwnProps {
    data: PaymentScheduledData;
}

export interface Attachment {
    id: string;
    fileName: string;
    fileSize: number;
    documentType: string;
    sharingAllowed: boolean;
    documentCategory: string;
    year: number;
    createdAt: string;
    createdBy: CreatedBy;
}

export interface CreatedBy {
    id: string;
    firstName: string;
    lastName: string;
    profilePhoto: ProfilePhoto;
}

export interface ProfilePhoto {
    id: string;
    fileName: string;
    fileType: string;
    profilePhoto: string[];
}
export default function ExpandedPaymentPaid({ data }: OwnProps) {
    const [getDocumentsByLease, { isLoading, data: files }] =
        useLazyDocumentsListByParentIDQuery();

    useEffect(() => {
        getDocumentsByLease({
            parentId: data.id,
            sort: 'createdAt,asc',
            page: 0,
            size: 1000,
        });
    }, [data.id]);

    if (isLoading) {
        return (
            <Box
                display={'flex'}
                flexDirection={'column'}
                alignItems={'center'}>
                <Skeleton animation="wave" width="80%" />
                <Skeleton animation="wave" width="80%" />
                <Skeleton animation="wave" width="80%" />
            </Box>
        );
    }

    return (
        <Box
            width={'100%'}
            display={'flex'}
            sx={{
                backgroundColor: theme.palette.backgroundB.backgroundB2,
                '& :last-child': {
                    borderRight: 'none',
                },
            }}>
            <ExpandComponentColumn
                title={'DUE DATE'}
                text={data.dueDate}
                isHidden={!data.dueDate}
            />{' '}
            <ExpandComponentColumn
                title={'PAYMENT TYPE'}
                text={data?.paymentType}
                isHidden={!data?.paymentType}
            />
            <ExpandComponentColumn
                title={'FIXED PAYMENT'}
                text={data?.note}
                isHidden={!data?.note}>
                {files?.map((file: Attachment, index: number) => (
                    <Box key={index}>
                        <FileWithDownloadButton
                            id={file.id}
                            name={file.fileName}
                            size={file?.fileSize}
                            date={dateConverterFromUTC(file.createdAt)}
                        />
                    </Box>
                ))}
            </ExpandComponentColumn>
        </Box>
    );
}
