import React, { ComponentType, ReactNode } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import BasedTooltip from '../design/BasedTooltip';

interface OwnProps {
    icon?: ComponentType;
    text?: string | ReactNode;
    isHidden?: boolean;
}
export default function InfoWithLabel({ icon, text, isHidden }: OwnProps) {
    const theme = useTheme();
    if (isHidden) return null;
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    const IconComponent = icon as ComponentType<any>;
    return (
        <Box display={'flex'} columnGap={2} alignItems={'center'}>
            <Box display={'flex'} alignItems={'center'}>
                {IconComponent && (
                    <IconComponent
                        style={{
                            fill: theme.palette.neutral.neutral7,
                            width: '16px',
                            height: '16px',
                        }}
                    />
                )}
            </Box>
            <BasedTooltip text={text as string}>
                <Typography
                    noWrap={true}
                    variant="font12"
                    fontWeight={'medium'}
                    color={'text.text.neutral9'}>
                    {text}
                </Typography>
            </BasedTooltip>
        </Box>
    );
}
