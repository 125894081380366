import React from 'react';
import { Box } from '@mui/material';
import UserMainInfo, {
    UserMain,
} from '../../../reusable/UserInfoPanel/UserMainInfo';
import StatusTag, { Tag } from '../../../design/StatusTag/StatusTag';
import { ReactComponent as LinkedInIcon } from '../../../assets/icons/linkedin_icon.svg';
import { ReactComponent as TwitterIcon } from '../../../assets/icons/twitter_icon.svg';
import { ReactComponent as FacebookIcon } from '../../../assets/icons/facebook_icon.svg';
import { ReactComponent as InstagramIcon } from '../../../assets/icons/instagram_icon.svg';
import { NavLink } from 'react-router-dom';

interface PrimaryContactMain {
    userMain: UserMain;
    status: PrimaryContactStatus;
    linkedin?: string;
    twitter?: string;
    facebook?: string;
    instagram?: string;
}
export type PrimaryContactStatus =
    | 'ACTIVE'
    | 'INACTIVE'
    | 'PROSPECT'
    | 'SUSPENDED';

export default function PrimaryContactMainInfo({
    userMain,
    status,
    linkedin,
    twitter,
    facebook,
    instagram,
}: PrimaryContactMain) {
    return (
        <Box
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'space-between'}
            width={312}>
            <UserMainInfo {...userMain} />
            <Box display={'flex'} flexDirection={'column'} p={3} rowGap={1}>
                <Box display={'flex'} justifyContent={'center'} gap={2}>
                    {linkedin && (
                        <NavLink
                            to={linkedin}
                            target={'_blank'}
                            rel="noopener noreferrer">
                            <LinkedInIcon />
                        </NavLink>
                    )}
                    {twitter && (
                        <NavLink
                            to={twitter}
                            target={'_blank'}
                            rel="noopener noreferrer">
                            <TwitterIcon />
                        </NavLink>
                    )}
                    {facebook && (
                        <NavLink
                            to={facebook}
                            target={'_blank'}
                            rel="noopener noreferrer">
                            <FacebookIcon />
                        </NavLink>
                    )}
                    {instagram && (
                        <NavLink
                            to={instagram}
                            target={'_blank'}
                            rel="noopener noreferrer">
                            <InstagramIcon />
                        </NavLink>
                    )}
                </Box>
                <Box display={'flex'} justifyContent={'center'}>
                    <StatusTag {...statusMap[status]} />
                </Box>
            </Box>
        </Box>
    );
}

export const statusMap: Record<PrimaryContactStatus, Tag> = {
    ACTIVE: {
        status: 'success',
        text: 'Active',
    },
    INACTIVE: {
        status: 'warning',
        text: 'Inactive',
    },
    PROSPECT: {
        status: 'info',
        text: 'Pending',
    },
    SUSPENDED: {
        status: 'error',
        text: 'Suspended',
    },
};
