import { composeValidators, required } from '../../utils/validation';
import { FormConfig } from '../../design/Forms/interfaces';

export const phoneNumbersType = [
    { id: 'CELL_PHONE', name: 'Cell/Mobile' },
    { id: 'OFFICE_PHONE', name: 'Office' },
    { id: 'HOME_PHONE', name: 'Home' },
    { id: 'FAX', name: 'Fax' },
];

const filteredPhoneNumbersType = (type: string[], index: number) => {
    return phoneNumbersType.filter(
        (phone) => !type?.slice(0, index).includes(phone.id),
    );
};

export const phoneNumbersConfig = (
    name: string,
    phoneType: string[],
    index: number,
): FormConfig[] => [
    {
        formField: {
            scheme: 'half',
            name: name ? `${name}.type` : 'type',
            type: 'select',
            label: 'Phone Type',
            isRequired: true,
            validation: composeValidators(required),
            tooltip: {
                text: 'Only one phone type can be selected',
                position: 'top',
            },
        },
        renderProps: {
            placeholder: 'Select',
            options: filteredPhoneNumbersType(phoneType, index),
            isDisabled: false,
            size: 'small',
        },
    },

    {
        formField: {
            scheme: 'half',
            name: name ? `${name}.number` : 'number',
            type: 'phoneInput',
            label: 'Phone Number',
            isRequired: true,
            validation: composeValidators(required),
        },
        renderProps: {
            placeholder: '(0000)',
            isDisabled: false,
            size: 'small',
        },
    },
];
