import {
    useLazyGetPrimaryEmailVerificationStatusQuery,
    useLazyGetSecondaryEmailVerificationStatusQuery,
    useVerifyPrimaryEmailMutation,
    useVerifySecondaryEmailMutation,
} from '../../api/contacts/contactsAPI';
import { useEffect, useState } from 'react';
import { EmailStatus } from './AllProfilesTable';
import useHandleSubmitRTK from '../../hooks/useHandleSubmit';

export interface EmailVerificationResponse {
    email: string;
    emailType: string;
    status: EmailStatus;
    expiredAt: string;
    createdAt: string;
    updatedAt: string;
    requestedBy: RequestedBy;
}

export interface RequestedBy {
    id: string;
    firstName: string;
    lastName: string;
}

interface OwnProps {
    isSecondaryEmail?: boolean;
}

export default function useVerificateEmail({
    isSecondaryEmail = false,
}: OwnProps) {
    const [emailData, setEmailData] = useState<
        EmailVerificationResponse | undefined
    >(undefined);
    const { handleSubmit: verifyPrimary, isLoading: isPrimaryVerified } =
        useHandleSubmitRTK({
            useRtkHook: useVerifyPrimaryEmailMutation,
            successMessage: 'Verify email sent successfully',
        });
    const { handleSubmit: verifySecondary, isLoading: isSecondaryVerified } =
        useHandleSubmitRTK({
            useRtkHook: useVerifySecondaryEmailMutation,

            successMessage: 'Verify email sent successfully',
        });
    const [
        getPrimaryEmail,
        { isLoading: isLoadingPrimaryEmail, data: primaryEmail },
    ] = useLazyGetPrimaryEmailVerificationStatusQuery();

    const [
        getSecondaryEmail,
        { isLoading: isLoadingSecondaryEmail, data: secondaryEmail },
    ] = useLazyGetSecondaryEmailVerificationStatusQuery();

    function handleEmail(id: string) {
        if (isSecondaryEmail) {
            getSecondaryEmail(id);
        } else {
            getPrimaryEmail(id);
        }
    }
    function handleVerifyEmail(id: string) {
        if (isSecondaryEmail) {
            verifySecondary(id);
        } else {
            verifyPrimary(id);
        }
    }
    useEffect(() => {
        if (primaryEmail) {
            setEmailData(primaryEmail);
            return;
        }
        if (secondaryEmail) {
            setEmailData(secondaryEmail);
            return;
        }
    }, [primaryEmail, secondaryEmail]);

    const isLoading =
        isLoadingPrimaryEmail ||
        isLoadingSecondaryEmail ||
        isPrimaryVerified ||
        isSecondaryVerified;
    return {
        isLoading,
        emailData,
        handleEmail,
        handleVerifyEmail,
    };
}
