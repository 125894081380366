import { Stack } from '@mui/material';
import UserAvatar from '../../design/Avatar/UserAvatar';
import AppTypography from '../../design/AppTypography';
import { phoneMaskForString } from '../../utils/fieldMasks';
import AppButton from '../../design/Buttons/AppButton';
import theme from '../../styles/theme';

export interface AvatarWithMetadataProps {
    name: string;
    avatar: string;
    phone: string;
    email: string;
    role: string;
}

export default function AvatarWithMetadata({
    name,
    avatar,
    phone,
    email,
    role,
}: AvatarWithMetadataProps) {
    return (
        <Stack rowGap={2} pt={10} pr={6}>
            <Stack rowGap={2}>
                <UserAvatar name={name} avatar={avatar} size={'large'} />
                <AppTypography
                    textTransform={'uppercase'}
                    variant={'font16'}
                    fontWeight={'medium'}
                    color={'text.secondary'}
                    text={role}
                />
            </Stack>
            <AppTypography
                variant={'font14'}
                fontWeight={'regular'}
                color={'text.neutral7'}
                text={name}
            />
            <AppTypography
                variant={'font14'}
                fontWeight={'regular'}
                color={'text.neutral7'}
                text={phoneMaskForString(phone)}
            />
            <AppButton
                sx={{ marginTop: theme.spacing(2) }}
                variant={'outlined'}
                title={'Send email'}
                size={'small'}
                onClick={() => window.open(`mailto:${email}`)}
            />
        </Stack>
    );
}
