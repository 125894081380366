import { TableColumn } from '../../../design/GenericTable/GenericTable';
import { potentialClientsMockData } from './potentialClientsMock';
import NotesCell from './NotesCell';

export interface PotentialClient {
    id: string;
    name: string;
    phone: string;
    email: string;
    solution: string;
    lastContactedDate: string;
    notesCount?: number;
    attachmentCount?: number;
}

export default function usePotentialClientsTable() {
    const columns: TableColumn<PotentialClient>[] = [
        {
            header: {
                label: 'Name',
                sortable: false,
            },
            cellRender: (data) => <>{data?.name}</>,
            format: { align: 'left', color: { color: 'primary' } },
            width: '20%',
        },
        {
            header: {
                label: 'Phone',
                sortable: false,
            },
            cellRender: (data) => <>{data?.phone}</>,
            format: { align: 'left', color: { color: 'primary' } },
            width: '19%',
        },
        {
            header: {
                label: 'Email',
                sortable: false,
            },
            cellRender: (data) => <>{data?.email}</>,
            format: { align: 'left', color: { color: 'primary' } },
            width: '16%',
        },
        {
            header: {
                label: 'Solution',
                sortable: false,
            },
            cellRender: (data) => <>{data?.solution}</>,
            format: { align: 'left', color: { color: 'primary' } },
            width: '16%',
        },
        {
            header: {
                label: 'Last Contact',
                sortable: false,
            },
            cellRender: (data) => <>{data?.lastContactedDate}</>,
            format: { align: 'left', color: { color: 'primary' } },
            width: '19%',
        },
        {
            header: {
                label: 'Notes',
                sortable: false,
            },
            cellRender: (data) => (
                <NotesCell
                    notesCount={data?.notesCount}
                    attachmentCount={data?.attachmentCount}
                />
            ),
            format: { align: 'left', color: { color: 'primary' } },
            width: '10%',
        },
    ];
    const tableConfig = {
        columns,
        pageable: false,
        expandable: false,
    };
    return { data: potentialClientsMockData || [], tableConfig };
}
