import { Grid } from '@mui/material';
import React from 'react';
import { useGetDashboardPreviewQuery } from '../../../../api/sharedData/sharedDataTempAccessAPI';
import Loader from '../../../../design/BaseLoader';
import SharedDataDashboardMetrics from '../../TemporaryAccess/Dashboard/SharedDataDashboardMetrics';

interface OwnProps {
    landPartnerId: string;
}

export default function SharedDataDashboardPreview({
    landPartnerId,
}: OwnProps) {
    const { data: dashboardData, isLoading: isDashboardDataLoading } =
        useGetDashboardPreviewQuery({
            landPartnerId,
        });

    if (isDashboardDataLoading) {
        return (
            <Grid
                container
                alignItems={'center'}
                justifyContent={'center'}
                flexDirection={'column'}>
                <Loader />
            </Grid>
        );
    }

    return <SharedDataDashboardMetrics dashboardData={dashboardData} />;
}
