import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
interface OwnProps {
    onClick: () => void;
    isHidden?: boolean;
}

export default function CloseButton({ onClick, isHidden }: OwnProps) {
    if (isHidden) return null;

    return (
        <IconButton onClick={onClick} size={'small'}>
            <CloseIcon />
        </IconButton>
    );
}
