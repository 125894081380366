import { ActivityCard } from '../LandPartnerActivity';

import { Box, Collapse } from '@mui/material';

import { useEffect, useState } from 'react';
import ShortCollapsedActivity from './ShortCollapsedActivity';
import { useLazyGetActivityByIdQuery } from '../../../api/landpartners/activityAPI';
import Activity from './Activity';
interface OwnProps {
    activity: ActivityCard;
    isExpanded?: boolean;
    onClick?: () => void;
    isHidden?: boolean;
}

export default function CollapsedActivity({
    activity,
    isExpanded,
    isHidden,
}: OwnProps) {
    const [getItems, { isFetching: fetchingOne, isLoading: loadingOne, data }] =
        useLazyGetActivityByIdQuery();
    const [expanded, setExpanded] = useState(isExpanded || false);

    const handleCollapse = () => {
        setExpanded(!expanded);
    };
    useEffect(() => {
        expanded && getItems({ id: activity.id, type: activity.type });
    }, [activity, expanded, getItems]);
    if (isHidden) return null;

    return (
        <Box display={'flex'} flexDirection={'column'}>
            <ShortCollapsedActivity
                handleCollapse={handleCollapse}
                activity={activity}
                isCollapsed={expanded}
            />
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <Activity
                    activity={data}
                    isLoading={fetchingOne || loadingOne}
                />
            </Collapse>
        </Box>
    );
}
