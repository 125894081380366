import React, { useCallback } from 'react';
import { Box, Typography } from '@mui/material';
import Dropzone, { Accept } from 'react-dropzone';
import { makeStyles } from 'tss-react/mui';
import { FileExtension } from './UploadFileField';
import FilesList from './FilesList';

interface OwnProps {
    filesAccepted?: FileExtension[];
    maxFileSize?: number;
    onChange: (value: File[]) => void;
    uploadedFiles: File[];
    maxFiles?: number;
    multipleFiles?: boolean;
    setUploadedFiles: (value: (prevFiles: File[]) => File[]) => void;
    helperText?: string;
    isDisabled?: boolean;
    name?: string;
    placeholder?: string;
}
const fileExtensionToMimeMap: Record<FileExtension, string> = {
    '.png': 'image/png',
    '.jpg': 'image/jpeg',
    '.jpeg': 'image/jpeg',
    '.bmp': 'image/bmp',
    '.csv': 'text/csv',
    '.pdf': 'application/pdf',
    '.zip': 'application/zip',
    '.xls': 'application/vnd.ms-excel',
    '.x-zip-compressed': 'application/x-zip-compressed',
};

function generateAccept(filesAccepted?: FileExtension[]): Accept {
    if (!filesAccepted) return {};

    const acceptTypes: Accept = {};

    filesAccepted.forEach((ext) => {
        const mimeType = fileExtensionToMimeMap[ext];
        if (mimeType) {
            acceptTypes[mimeType] = [];
        }
    });

    return acceptTypes;
}
export default function Uploader({
    filesAccepted,
    maxFileSize,
    multipleFiles,
    maxFiles,
    setUploadedFiles,
    helperText,
    placeholder,
    isDisabled,
    name,
    onChange,
    uploadedFiles,
}: OwnProps) {
    const { classes } = useStyles();
    const accept = generateAccept(filesAccepted);
    const onChangeHandler = (fileList: File[]) => {
        onChange(fileList);
        setUploadedFiles(() => fileList);
    };
    const onDropMultiple = useCallback(
        (acceptedFiles: File[]) => {
            setUploadedFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
        },
        [setUploadedFiles],
    );
    const onDropSingle = (acceptedFiles: File[]) => {
        setUploadedFiles(() => acceptedFiles);
    };

    return (
        <Dropzone
            onDrop={multipleFiles ? onDropMultiple : onDropSingle}
            multiple={multipleFiles}
            maxFiles={maxFiles}
            disabled={isDisabled}
            accept={accept}
            maxSize={maxFileSize}>
            {({ getRootProps, getInputProps }) => {
                return (
                    <Box
                        display={'flex'}
                        flexDirection={'column'}
                        gap={1}
                        {...getRootProps()}
                        className={classes.dropzoneContainer}>
                        <input id={name} {...getInputProps()} type="file" />

                        <Typography
                            variant="font16"
                            fontWeight="medium"
                            color="text.secondary">
                            {helperText}
                        </Typography>
                        <Typography
                            variant="font12"
                            fontWeight={'medium'}
                            color={'text.light'}>
                            {placeholder}
                        </Typography>
                        {!!uploadedFiles.length && (
                            <FilesList
                                uploadedFiles={uploadedFiles}
                                onChange={onChangeHandler}
                            />
                        )}
                    </Box>
                );
            }}
        </Dropzone>
    );
}

const useStyles = makeStyles()((theme) => ({
    dropzoneContainer: {
        padding: theme.spacing(2, 2),
        border: `dashed 2px ${theme.palette.brandLightTurq}`,
        borderRadius: 4,
        cursor: 'grab',
    },
}));
