import { Box, IconButton, Typography } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import {
    useDeleteOrganizationMutation,
    useGetOrganizationsQuery,
    useLazyGetOrganizationsQuery,
} from '../../../../api/users/organizationsAPI';
import { ReactComponent as EditIcon } from '../../../../assets/icons/edit_icon.svg';
import { ReactComponent as RemoveIcon } from '../../../../assets/icons/remove_icon.svg';
import GenericTable, {
    Table,
} from '../../../../design/GenericTable/GenericTable';
import { useSorting } from '../../../../design/GenericTable/useSorting';
import StatusTag, { Tag } from '../../../../design/StatusTag/StatusTag';
import useModal from '../../../../hooks/useModal';
import ConfirmModal from '../../../../layouts/ConfirmModal/ConfirmModal';
import { usePagination } from '../../../../design/GenericTable/useBackendPagination';
import { useNavigate } from 'react-router-dom';
import Paths from '../../../../router/paths';
import { Features } from '../AddOrganization/OrganizationSubscriptionPage';
import { useSaveSettings } from '../../../../hooks/useSaveSettings';
import { useSettingsFromStorage } from '../../../../hooks/useSettingsFromStorage';
import ExpandablePhoneList from '../../../AllProfiles/ExpandablePhoneList';
import { PhoneType } from '../../../../reusable/FormUI/PhoneNumberField';
import SettingsIcon from '@mui/icons-material/Settings';
import OrganizationConfigModal from './OrganizationConfigModal';

interface Role {
    id: string;
    name: string;
}

type Status = 'ACTIVE' | 'INACTIVE' | 'PROSPECT' | 'SUSPENDED';
type Type = 'GROWER' | 'LANDOWNER' | 'FARMLAND_MANAGER' | 'SUCCESSION_PLANNERS';

export interface Organization {
    id: string;
    name: string;
    type: Type;
    email: string;
    primaryContact: PrimaryContact;
    contactNumbers?: PhoneType[];
    keyDates: KeyDatesResp;
    status: Status;
    active: boolean;
    maxUsersCount?: number;
    roles: Role[];
    subscription?: Features;
}

interface PrimaryContact {
    firstName: string;
    lastName: string;
}

interface KeyDatesResp {
    birthDate?: string;
    relationshipStartDate?: string;
}

export default function OrganizationsList() {
    const { settings, saveSettings } = useSettingsFromStorage('ORGANIZATIONS');
    const { sort, page, sortDirection, size } = settings;

    const { isShowing: deleteIsShowing, setIsShowing: setDeleteIsShowing } =
        useModal();
    const [id, setId] = useState<string | null>(null);
    const [configModalIsShowing, setConfigModalIsShowing] = useState(false);
    const [deleteOrganization] = useDeleteOrganizationMutation();
    const navigate = useNavigate();
    const { sortKey, sortOrder, handleSort } = useSorting(
        sort || 'name',
        sortDirection || 'asc',
    );
    const {
        currentPage,
        itemsPerPage,
        handlePageChange,
        handleItemsPerPageChange,
    } = usePagination(page || 1, size || 25);

    useSaveSettings({
        settings,
        saveSettings,
        sortKey,
        sortOrder,
        currentPage,
        itemsPerPage,
    });
    const { data: initData } = useGetOrganizationsQuery({
        sort: `${sortKey},${sortOrder}`,
        page: currentPage - 1,
        size: itemsPerPage,
    });
    const [getItems, { data: sortedData }] = useLazyGetOrganizationsQuery();

    useEffect(() => {
        getItems({
            sort: `${sortKey},${sortOrder}`,
            page: currentPage - 1,
            size: itemsPerPage,
        });
    }, [sortKey, sortOrder, currentPage, itemsPerPage]);

    const onDelete = (id: string) => {
        id && setId(id);
        setDeleteIsShowing(true);
    };

    const handleDelete = (id: string) => {
        deleteOrganization(id);
        setDeleteIsShowing(false);
        setId(null);
    };

    const onEdit = (id: string) => {
        id && navigate(`./${id}/${Paths.update}`);
    };

    const data = useMemo(() => {
        return sortedData?.data || initData?.data || [];
    }, [sortedData, initData]);

    const totalCount = useMemo(() => {
        return sortedData?.totalCount || initData?.totalCount || 0;
    }, [sortedData, initData]);

    const findOrganization = (id: string) => {
        return data?.find((item: Organization) => item.id === id);
    };

    const onConfigSettings = (id: string) => {
        setId(id);
        setConfigModalIsShowing(true);
    };

    const organizationTableConfig: Table<Organization> = {
        columns: [
            {
                header: {
                    label: 'Organization',
                    sortable: true,
                    sorting: {
                        id: 'name',
                        direction: sortKey === 'name' ? sortOrder : 'desc',
                        isSorted: sortKey === 'name',
                    },
                    onClick: handleSort,
                },
                cellRender: (organization: Organization) => (
                    <Typography
                        variant={'font14'}
                        color={'text.link'}
                        fontWeight={'medium'}>
                        {organization.name}
                    </Typography>
                ),
                format: { align: 'left', color: { color: 'primary' } },
                width: '20%',
            },
            {
                header: {
                    label: 'Primary',
                    sortable: true,
                    sorting: {
                        id: 'primaryContact.firstName',
                        direction:
                            sortKey === 'primaryContact.firstName'
                                ? sortOrder
                                : 'desc',
                        isSorted: sortKey === 'primaryContact.firstName',
                    },
                    onClick: handleSort,
                },
                cellRender: (organization: Organization) => (
                    <Typography
                        variant={'font14'}
                        color={'text.link'}
                        fontWeight={'medium'}>
                        {organization?.primaryContact?.firstName}{' '}
                        {organization?.primaryContact?.lastName}
                    </Typography>
                ),
                format: { align: 'left', color: { color: 'primary' } },
                width: '20%',
            },
            {
                header: {
                    label: 'Email',
                    sortable: true,
                    sorting: {
                        id: 'email',
                        direction: sortKey === 'email' ? sortOrder : 'desc',
                        isSorted: sortKey === 'email',
                    },
                    onClick: handleSort,
                },
                cellRender: (organization: Organization) => (
                    <Typography
                        variant={'font14'}
                        color={'text.link'}
                        fontWeight={'medium'}>
                        {organization.email}
                    </Typography>
                ),
                format: { align: 'left', color: { color: 'primary' } },
                width: '10%',
            },
            {
                header: {
                    label: 'Phone',
                    sortable: true,
                    sorting: {
                        id: 'cellPhoneNumber,officePhoneNumber,homePhoneNumber,homePhoneNumber',
                        direction:
                            sortKey ===
                            'cellPhoneNumber,officePhoneNumber,homePhoneNumber,homePhoneNumber'
                                ? sortOrder
                                : 'desc',
                        isSorted:
                            sortKey ===
                            'cellPhoneNumber,officePhoneNumber,homePhoneNumber,homePhoneNumber',
                    },
                    onClick: handleSort,
                },
                cellRender: (item) =>
                    !!item?.contactNumbers?.length && (
                        <ExpandablePhoneList phoneList={item.contactNumbers} />
                    ),
                format: { align: 'left', color: { color: 'primary' } },
                width: '10%',
            },
            {
                header: {
                    label: 'Organization Type',
                    sortable: true,
                    sorting: {
                        id: 'type',
                        direction: sortKey === 'type' ? sortOrder : 'desc',
                        isSorted: sortKey === 'type',
                    },
                    onClick: handleSort,
                },
                cellRender: (organization: Organization) => (
                    <Typography>
                        {organizationTypeMap[organization.type]}
                    </Typography>
                ),
                format: { align: 'left', color: { color: 'primary' } },
                width: '15%',
            },
            {
                header: {
                    label: 'Status',
                    sortable: true,
                    sorting: {
                        id: 'status',
                        direction: sortKey === 'status' ? sortOrder : 'desc',
                        isSorted: sortKey === 'status',
                    },
                    onClick: handleSort,
                },
                cellRender: (organization: Organization) => (
                    <StatusTag {...statusMap[organization.status]} />
                ),
                format: { align: 'left', color: { color: 'primary' } },
                width: '10%',
            },
            {
                header: {
                    label: 'Actions',
                    sortable: false,
                },
                cellRender: (organization: Organization) => (
                    <Box>
                        <IconButton onClick={() => onEdit(organization.id)}>
                            <EditIcon />
                        </IconButton>
                        <IconButton
                            onClick={() => onConfigSettings(organization.id)}>
                            <SettingsIcon />
                        </IconButton>
                        <IconButton onClick={() => onDelete(organization.id)}>
                            <RemoveIcon />
                        </IconButton>
                    </Box>
                ),
                format: { align: 'left', color: { color: 'primary' } },
                width: '15%',
            },
        ],
        multiselect: false,
        expandable: false,
        handlePageChange: handlePageChange,
        handleItemsPerPageChange: handleItemsPerPageChange,
        initialPage: page,
        initialPageSize: size,
    };
    return (
        <>
            <GenericTable
                data={data}
                totalCount={totalCount}
                tableConfig={organizationTableConfig}
            />
            {id && deleteIsShowing && (
                <ConfirmModal
                    isShowing={deleteIsShowing}
                    hide={() => setDeleteIsShowing(false)}
                    title={`Are you sure you want to delete ${
                        findOrganization(id)?.name
                    } ?`}
                    info={`You are about to delete  ${
                        findOrganization(id)?.name
                    }. This action cannot be undone`}
                    onSubmit={() => handleDelete(id)}
                />
                // eslint-disable-next-line max-lines
            )}
            {id && configModalIsShowing && (
                <OrganizationConfigModal
                    organizationId={id}
                    isShowing={configModalIsShowing}
                    onClose={() => setConfigModalIsShowing(false)}
                />
            )}
        </>
    );
}

const statusMap: Record<Status, Tag> = {
    ACTIVE: {
        status: 'success',
        text: 'Active',
    },
    INACTIVE: {
        status: 'warning',
        text: 'Inactive',
    },
    PROSPECT: {
        status: 'info',
        text: 'Pending',
    },
    SUSPENDED: {
        status: 'error',
        text: 'Suspended',
    },
};
const organizationTypeMap: Record<Type, string> = {
    GROWER: 'Grower',
    LANDOWNER: 'Landowner',
    FARMLAND_MANAGER: 'Farmland Manager',
    SUCCESSION_PLANNERS: 'Succession Planners',
};
