import { useState } from 'react';
import { Switch } from '@mui/material';

interface OwnProps {
    isDisabled: boolean;
    condition?: boolean;
    onSwitch: () => void;
}

export default function IntegrationSwitch({
    isDisabled,
    condition,
    onSwitch,
}: OwnProps) {
    const [checked, setChecked] = useState(condition);
    return (
        <Switch
            disabled={isDisabled}
            checked={checked}
            onChange={() => {
                setChecked(!checked);
                onSwitch();
            }}
            color={isDisabled ? 'default' : 'primary'}
        />
    );
}
