import React, { ReactNode } from 'react';
import { Popover } from '@mui/material';

interface OwnProps {
    open: boolean;
    anchorEl: HTMLElement | null;
    onClose: () => void;
    children: ReactNode;
    disableAutoFocus?: boolean;
}
export default function BasePopover({
    open,
    anchorEl,
    onClose,
    children,
    disableAutoFocus,
}: OwnProps) {
    return (
        <Popover
            disableAutoFocus={disableAutoFocus}
            disableRestoreFocus
            open={open}
            sx={{
                zIndex: 1000,
            }}
            anchorEl={anchorEl}
            onClose={onClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}>
            {children}
        </Popover>
    );
}
