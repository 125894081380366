import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { ReactComponent as EmailIcon } from '../../../assets/icons/communications_icon.svg';
import OakenLogo from '../../../assets/icons/logo.svg';
import { FormConfig } from '../../../design/Forms/interfaces';
import { Form } from 'react-final-form';
import FormPage from '../../../reusable/FormUI/FormPage';
import { makeStyles } from 'tss-react/mui';
import SubmitButton from '../../../design/Buttons/SubmitButton';
import {
    composeValidators,
    isEmail,
    maxLength,
    required,
} from '../../../utils/validation';
import theme from '../../../styles/theme';

export interface InviteGrowerRequest {
    growerName: string;
    growerEmail: string;
}

interface OwnProps {
    handleSendInvite: (values: InviteGrowerRequest) => void;
    isLoading: boolean;
}

export default function GrowerInviteStaticForm({
    handleSendInvite,
    isLoading,
}: OwnProps) {
    const { classes } = useStyles();

    return (
        <Box
            display={'flex'}
            flexDirection={'column'}
            rowGap={1}
            p={2}
            borderRadius={2}
            marginTop={theme.spacing(2)}
            bgcolor={theme.palette.neutralWhite}>
            <Box className={classes.container}>
                <Box display="flex" alignItems="flex-start">
                    <img
                        src={OakenLogo}
                        alt="Oaken Logo"
                        className={classes.logo}
                    />
                </Box>
                <Box my={2}>
                    <Typography variant="font20" className={classes.title}>
                        Introduce your growers to Oaken
                    </Typography>
                </Box>
                <Typography variant="font14" className={classes.description}>
                    Strengthen your grower relationships and be automatically
                    entered into our monthly giveaway.
                </Typography>
                <Form initialValues={{}} onSubmit={handleSendInvite}>
                    {({ handleSubmit }) => (
                        <form onSubmit={handleSubmit}>
                            <Grid my={2}>
                                <FormPage formConfig={formGrowerInvite()} />
                            </Grid>
                            <Box className={classes.submitContainer}>
                                <SubmitButton
                                    text="Send Invite"
                                    startIcon={<EmailIcon />}
                                    variant="contained"
                                    isDisabled={isLoading}
                                    isLoading={isLoading}
                                />
                            </Box>
                        </form>
                    )}
                </Form>
            </Box>
        </Box>
    );
}

const useStyles = makeStyles()((theme) => ({
    container: {
        maxWidth: 400,
        padding: 16,
        borderRadius: 8,
        background: `linear-gradient(145deg, #0e6073, ${theme.palette.lightTurq.lightTurqTint5})`,
        color: theme.palette.neutralWhite,
        boxShadow: theme.shadows[4],
    },
    logo: {
        marginBottom: 16,
    },
    title: {
        color: theme.palette.limeGreen.limeGreenTint8,
        textAlign: 'left',
        fontWeight: 500,
    },
    description: {
        marginBottom: 16,
        color: theme.palette.neutralWhite,
        textAlign: 'left',
    },
    submitContainer: {
        textAlign: 'right',
    },
}));

const formGrowerInvite = (): FormConfig[] => [
    {
        formField: {
            scheme: 'full',
            name: 'growerName',
            type: 'input',
            label: 'Name',
            labelColor: 'white',
            isRequired: true,
            validation: composeValidators(required, maxLength(256)),
        },
        renderProps: {
            placeholder: 'Grower Name',
            isDisabled: false,
            size: 'small',
        },
    },
    {
        formField: {
            scheme: 'full',
            name: 'growerEmail',
            type: 'input',
            label: 'Email',
            labelColor: 'white',
            isRequired: true,
            validation: composeValidators(required, isEmail, maxLength(256)),
        },
        renderProps: {
            placeholder: 'Add Email',
            isDisabled: false,
            size: 'small',
        },
    },
];
