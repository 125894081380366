import React, { useContext, useState } from 'react';
import { TableColumn } from '../../../../design/GenericTable/GenericTable';

import { useSorting } from '../../../../design/GenericTable/useSorting';
import { usePagination } from '../../../../design/GenericTable/useBackendPagination';

import { Typography } from '@mui/material';
import ActionsColumn from '../ActionsColumn';
import { SelectedRowContext } from '../SelectedRowContext';
import {
    useDeleteContactMutation,
    useGetArchiveContactsQuery,
    useRestoreContactMutation,
} from '../../../../api/contacts/contactsAPI';
import useHandleSubmitRTK from '../../../../hooks/useHandleSubmit';
import { dateTimeConverterFromUTC } from '../../../../utils/dateFormat';
import { Address } from '../../../AllProfiles/AllProfiles';

export interface ContactArchive {
    id: string;
    contactName: string;
    primaryAddress: string;
    primaryEmail: string;
    archiveDate: string;
    archivedBy: string;
    createdDate: string;
    createdBy: string;
}

export interface ContactArchiveResponse {
    id: string;
    firstName: string;
    lastName: string;
    primaryEmail: string;
    primaryAddress: Address;
    createdAt: string;
    createdBy: CreatedBy;
    archivedBy: ArchivedBy;
    archivedAt: string;
}

export interface CreatedBy {
    id: string;
    firstName: string;
    lastName: string;
}

export interface ArchivedBy {
    id: string;
    firstName: string;
    lastName: string;
}

export default function useTableArchiveContactConfig() {
    const [isShowing, setIsShowing] = useState(false);
    const [deleteData, setDeleteData] = useState<ContactArchive | null>(null);

    const { sortKey, sortOrder, handleSort } = useSorting('firstName', 'asc');
    const { setSelectedRow } = useContext(SelectedRowContext);
    const handleRowClick = (rowData: ContactArchive) => {
        setSelectedRow(rowData);
    };
    const {
        currentPage,
        itemsPerPage,
        handlePageChange,
        handleItemsPerPageChange,
    } = usePagination(1, 100);

    const { handleSubmit, isLoading: isRestoreProcessing } = useHandleSubmitRTK(
        {
            onSuccess: () => setSelectedRow(null),
            useRtkHook: useRestoreContactMutation,
            successMessage: 'Contact restored successfully',
        },
    );
    const { data, isLoading } = useGetArchiveContactsQuery({
        sort: `${sortKey},${sortOrder}`,
        page: currentPage - 1,
        size: itemsPerPage,
    });
    const { handleSubmit: handleDelete, isLoading: isDeleteProcessing } =
        useHandleSubmitRTK({
            useRtkHook: useDeleteContactMutation,
            onSuccess: () => {
                setIsShowing(false);
                setSelectedRow(null);
            },
            successMessage: 'Contact deleted successfully',
        });

    function handleDeleteClick(data: ContactArchive) {
        setIsShowing(true);
        setDeleteData(data);
    }

    const columns: TableColumn<ContactArchive>[] = [
        {
            header: {
                label: 'Name',
                sortable: true,
                sorting: {
                    id: 'firstName',
                    direction: sortKey === 'firstName' ? sortOrder : 'desc',
                    isSorted: sortKey === 'firstName',
                },
                onClick: handleSort,
            },
            cellRender: (contact) => (
                <Typography color={'text.link'}>
                    {contact.contactName}
                </Typography>
            ),
            format: { align: 'left', color: { color: 'primary' } },
            width: '20%',
        },
        {
            header: {
                customizeKey: 'archiveDate',
                label: 'Archived Date',
                sortable: false,
                sorting: {
                    id: 'archiveDate',
                    direction: sortKey === 'archiveDate' ? sortOrder : 'desc',
                    isSorted: sortKey === 'archiveDate',
                },
                onClick: handleSort,
            },
            cellRender: (contact) => <>{contact.archiveDate}</>,
            format: { align: 'left', color: { color: 'primary' } },
            width: '20%',
        },
        {
            header: {
                customizeKey: 'archivedBy',
                label: 'Archived By',
                sortable: false,
                sorting: {
                    id: 'archivedBy',
                    direction: sortKey === 'archivedBy' ? sortOrder : 'desc',
                    isSorted: sortKey === 'archivedBy',
                },
                onClick: handleSort,
            },
            cellRender: (contact) => <>{contact?.archivedBy}</>,
            format: { align: 'left', color: { color: 'primary' } },
            width: '20%',
        },
        {
            header: {
                customizeKey: 'actions',
                label: 'Actions',
                sortable: false,
            },
            cellRender: (contact) => (
                <ActionsColumn
                    id={contact.id}
                    onDelete={() => handleDeleteClick(contact)}
                    onRestore={() => handleSubmit(contact.id)}
                    isProcessing={isRestoreProcessing || isDeleteProcessing}
                />
            ),
            format: { align: 'left', color: { color: 'primary' } },
            width: '40%',
        },
    ];

    const tableConfig = {
        columns,
        pageable: false,
        handlePageChange,
        onClick: handleRowClick,
        handleItemsPerPageChange,
        initialPageSize: 5,
        initialPage: 1,
    };
    const totalCount = data?.totalCount || 0;
    return {
        data: data?.data ? data?.data.map(dataConvertorFromResponse) : [],
        totalCount,
        tableConfig,
        isLoading,
        isProcessing: isRestoreProcessing || isDeleteProcessing,
        deleteData,
        isShowing,
        setIsShowing,
        handleDelete,
    };
}

function dataConvertorFromResponse(
    data: ContactArchiveResponse,
): ContactArchive {
    return {
        id: data?.id,
        contactName: `${data?.firstName} ${data?.lastName}`,
        primaryAddress: data?.primaryAddress
            ? `${data?.primaryAddress?.address1} ${data?.primaryAddress?.address2} ${data?.primaryAddress?.city} ${data?.primaryAddress?.state} ${data?.primaryAddress?.zipCode}`
            : 'N/A',
        primaryEmail: data?.primaryEmail || 'N/A',
        archiveDate: dateTimeConverterFromUTC(data?.archivedAt),
        archivedBy: `${data?.archivedBy?.firstName} ${data?.archivedBy?.lastName}`,
        createdDate: dateTimeConverterFromUTC(data?.createdAt),
        createdBy: `${data?.createdBy?.firstName} ${data?.createdBy?.lastName}`,
    };
}
