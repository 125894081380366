import React from 'react';

import BasicTabsPanel from '../../design/TabsPanel/BasicTabsPanel';
import AllProfilesDetails from '../../features/ViewAllProfiles/AllProfilesDetails';
import { Grid } from '@mui/material';
import { useParams } from 'react-router-dom';

import ContactRelationship from '../../features/ContactRelationshipTree/ContactRelationship';
import PrimaryContactDetails from './PrimaryContactDetails';
import AllProfilesNotes from '../../features/ViewAllProfiles/AllProfilesNotes';
import { useGetOneContactQuery } from '../../api/contacts/contactsAPI';
import PATHS from '../../router/paths';
import LPContactHistory from '../../features/ViewLPContactHistory/LPContactHistory';
import useBreadcrumbs from '../../hooks/useBreadcrumbs';
import Breadcrumbs from '../../design/Breadcrumbs/Breadcrumbs';

export default function AllProfilesDetailsPage() {
    const { id } = useParams<{ id: string }>();
    const {
        data: contactData,
        isLoading,
        isFetching,
    } = useGetOneContactQuery(id);

    const routes = {
        '/': 'Home',
        'landpartners/allProfiles/': 'All Profiles',
        'landpartners/allProfiles/:id': '',
        'landpartners/allProfiles/:id/relationship': 'Relationship',
        'landpartners/allProfiles/:id/notes': 'Notes',
        'landpartners/allProfiles/:id/history': 'History',
    };

    const breadcrumbs = useBreadcrumbs({
        dynamicName:
            `${contactData?.firstName} ${contactData?.lastName}`?.toUpperCase() ||
            '',
        routes,
    });
    const tabsConfig = [
        {
            label: 'Detail',
            content: <AllProfilesDetails contactData={contactData} />,
            value: 0,
            to: '',
        },
        {
            label: 'Relationships',
            content: <ContactRelationship contactData={contactData} />,
            value: `${PATHS.relationship}`,
            to: `${PATHS.relationship}`,
        },
        {
            label: 'Notes',
            content: <AllProfilesNotes />,
            value: `${PATHS.notes}`,
            to: `${PATHS.notes}`,
        },
        {
            label: 'History',
            content: <LPContactHistory />,
            value: `${PATHS.history}`,
            to: `${PATHS.history}`,
        },
    ];

    return (
        <Grid container rowGap={2}>
            <Breadcrumbs collapsed={false} links={breadcrumbs} />
            <PrimaryContactDetails
                contactData={contactData}
                isLoading={isLoading}
                isFetching={isFetching}
            />
            <BasicTabsPanel tabItems={tabsConfig} />
        </Grid>
    );
}
