import { useEffect, useState } from 'react';
import { createDownloadLinkFromBlob } from '../utils/fileHandler';
import { useLazyDownloadDocumentQuery } from '../api/documents/documentsAPI';

export function useDownloadDocuments() {
    const [isShowing, setIsShowing] = useState(false);
    const [file, setFile] = useState<string | null>(null);
    const [
        getItems,
        { data: responsePromise, isLoading: isDocumentLoading, isFetching },
    ] = useLazyDownloadDocumentQuery();
    const handleClose = () => {
        setIsShowing(false);
    };

    useEffect(() => {
        if (!responsePromise) return;
        const { data }: { data: Blob; headers: string } = responsePromise;
        const fileData = data && URL.createObjectURL(data);
        fileData && setFile(fileData);
    }, [responsePromise]);

    const handlePreviewOpen = (documentId: string) => {
        getItems(documentId);
        setIsShowing(true);
    };
    const handleDownload = (documentId: string, fileName: string) => {
        getItems(documentId)
            .unwrap()
            .then(({ data }) => {
                createDownloadLinkFromBlob(data, fileName);
            });
    };
    const extension = (fileName: string) => fileName.split('.').pop();
    const isLoading = isDocumentLoading || isFetching;
    return {
        isShowing,
        file,
        isLoading,
        handleClose,
        handlePreviewOpen,
        handleDownload,
        extension,
    };
}
