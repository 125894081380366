import React from 'react';
import { makeStyles } from 'tss-react/mui';
import theme from '../../styles/theme';
import { Chip } from '@mui/material';
import clsx from 'clsx';
//TODO refactor component by size and color
export interface Tag {
    status:
        | 'error'
        | 'warning'
        | 'success'
        | 'info'
        | 'disabled'
        | 'filterInfo'
        | 'filterEvent'
        | 'primaryInfo'
        | 'filterSuccess'
        | 'smallSuccess'
        | 'smallWarning'
        | 'filterWarning'
        | 'PAID'
        | 'UNPAID'
        | 'UPCOMING'
        | 'OVERDUE';
    text: string;
    size?: 'small' | 'medium' | 'large';
}

type StyleProps = ReturnType<typeof useStyles>['classes'];
export default function StatusTag({ status, text, size }: Tag) {
    const { classes } = useStyles();

    return (
        <Chip
            label={text}
            className={clsx(
                size && tagClassMapper[size](classes),
                tagClassMapper[status](classes),
            )}
        />
    );
}

const tagClassMapper = {
    error: (classes: StyleProps) => classes.errorStatus,
    warning: (classes: StyleProps) => classes.warningStatus,
    success: (classes: StyleProps) => classes.successStatus,
    info: (classes: StyleProps) => classes.infoStatus,
    primaryInfo: (classes: StyleProps) => classes.primaryInfoStatus,
    disabled: (classes: StyleProps) => classes.disabledStatus,
    filterInfo: (classes: StyleProps) => classes.filterStatusInfo,
    filterWarning: (classes: StyleProps) => classes.filterStatusWarning,
    filterSuccess: (classes: StyleProps) => classes.filterStatusSuccess,
    filterEvent: (classes: StyleProps) => classes.filterStatusEvent,
    smallSuccess: (classes: StyleProps) => classes.smallSuccessStatus,
    smallWarning: (classes: StyleProps) => classes.smallWarningStatus,
    PAID: (classes: StyleProps) => classes.successStatus,
    UNPAID: (classes: StyleProps) => classes.infoStatus,
    UPCOMING: (classes: StyleProps) => classes.warningStatus,
    OVERDUE: (classes: StyleProps) => classes.errorStatus,
    small: (classes: StyleProps) => classes.small,
    medium: (classes: StyleProps) => classes.medium,
    large: (classes: StyleProps) => classes.large,
};

const useStyles = makeStyles()(() => ({
    errorStatus: {
        fontWeight: 600,
        backgroundColor: theme.palette.statusRedTint,
    },
    warningStatus: {
        fontWeight: 600,
        backgroundColor: theme.palette.statusYellow,
    },
    infoStatus: {
        fontWeight: 600,
        backgroundColor: theme.palette.lightTurq.lightTurqTint4,
    },
    primaryInfoStatus: {
        fontWeight: 600,
        backgroundColor: theme.palette.primaryBlue,
    },
    successStatus: {
        fontWeight: 600,
        backgroundColor: theme.palette.limeGreen.limeGreenShade2,
    },
    disabledStatus: {
        fontWeight: 600,
        backgroundColor: theme.palette.neutral.neutral4,
    },
    filterStatusInfo: {
        width: 60,
        fontSize: 12,
        fontWeight: 600,
        padding: theme.spacing(0.5, 0),
        backgroundColor: theme.palette.lightTurq.lightTurqTint4,
        '& .MuiChip-label': {
            padding: 0,
        },
    },
    filterStatusSuccess: {
        width: 60,
        fontSize: 12,
        fontWeight: 600,
        padding: theme.spacing(0.5, 0),
        backgroundColor: theme.palette.limeGreen.limeGreenTint5,
        '& .MuiChip-label': {
            padding: 0,
        },
    },
    filterStatusWarning: {
        width: 60,
        fontSize: 12,
        fontWeight: 600,
        padding: theme.spacing(0.5, 0),
        backgroundColor: theme.palette.brandYellowGreen,
        '& .MuiChip-label': {
            padding: 0,
        },
    },
    filterStatusEvent: {
        width: 60,
        fontSize: 12,
        fontWeight: 600,
        padding: theme.spacing(0.5, 0),
        backgroundColor: theme.palette.lightTurq.lightTurqTint5,
        '& .MuiChip-label': {
            padding: 0,
        },
    },
    smallSuccessStatus: {
        width: 60,
        backgroundColor: theme.palette.limeGreen.limeGreenShade2,
        fontSize: 11,
        fontWeight: 600,
        padding: 0,
        '& .MuiChip-label': {
            padding: 0,
        },
    },
    smallWarningStatus: {
        width: 60,
        backgroundColor: theme.palette.statusYellow,
        fontSize: 11,
        fontWeight: 600,
        padding: 0,
        '& .MuiChip-label': {
            padding: 0,
        },
    },
    small: {
        width: 'auto',
        fontSize: 11,
        height: 16,
        fontWeight: 600,
        padding: 4,
        '& .MuiChip-label': {
            padding: 4,
        },
    },
    medium: {
        width: 'auto',
        fontSize: 12,
        height: 18,
        fontWeight: 600,
    },
    large: {
        width: 'auto',
        fontSize: 14,
        height: 22,
        fontWeight: 600,
    },
}));
