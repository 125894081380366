import { TabItem } from './BasicTabsPanel';
import { ChangeEvent, useState } from 'react';
import { Tab, Tabs } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import theme from '../../styles/theme';
import clsx from 'clsx';

export type TabConfig = Omit<TabItem, 'to'>;

interface OwnProps {
    tabItems: TabConfig[];
}

export default function TabPanelWithoutNavigation({ tabItems }: OwnProps) {
    const [activeTab, setActiveTab] = useState(tabItems[0].value);
    const { classes } = useStyles();
    const handleChange = (
        event: ChangeEvent<NonNullable<unknown>>,
        newValue: number,
    ) => {
        setActiveTab(newValue);
    };

    return (
        <div className={classes.container}>
            <Tabs
                value={activeTab}
                onChange={handleChange}
                className={classes.root}>
                {tabItems.map((tab, index) => (
                    <Tab
                        className={clsx(
                            classes.tab,
                            activeTab === tab.value && classes.activeTab,
                        )}
                        label={tab.label}
                        value={tab.value}
                        key={index}
                    />
                ))}
            </Tabs>
            <div className={classes.content}>
                {tabItems.find((tab) => tab.value === activeTab)?.content}
            </div>
        </div>
    );
}
const useStyles = makeStyles()(() => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: theme.palette.neutralWhite,
    },
    root: {
        height: '53px',
        backgroundColor: theme.palette.backgroundB.backgroundB4,
        borderRadius: '8px 8px 0 0',
        '& .MuiTabs-indicator': {
            display: 'none',
        },
    },
    content: {
        padding: theme.spacing(4, 5),
    },
    tab: {
        width: '100%',
        height: '53px',
        flex: 1,
        color: theme.palette.brandLightTurq,
        fontSize: '14px',
        fontWeight: 500,
        borderRadius: '8px 8px 0 0',
        textTransform: 'unset',
    },
    activeTab: {
        backgroundColor: theme.palette.neutralWhite,
    },
}));
