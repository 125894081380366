import {
    Box,
    Card,
    CardContent,
    CardHeader,
    IconButton,
    Typography,
} from '@mui/material';
import { ReactComponent as CloseIcon } from '../../assets/icons/close_icon.svg';
import PromptCard, { PromptCardProps } from '../../design/Cards/PromptCard';
import React, { MouseEvent, useState } from 'react';
import BasePopover from '../../reusable/BasePopover';
import { makeStyles } from 'tss-react/mui';
import theme from '../../styles/theme';
import { UpcomingEventsType } from './UpcomingEventsSidebar';
import UserAvatar from '../../design/Avatar/UserAvatar';

import useAvatar from '../../hooks/useAvatar';

export interface EventPopover {
    name: string;
    type: UpcomingEventsType;
    dateTime: string;
    description: string;
    scheduledBy: {
        name: string;
        avatar: string;
        fileName: string;
        fileType: string;
    };
    referenceId?: string;
    receiverId?: string;
}
interface OwnProps {
    activity: PromptCardProps;
    event: EventPopover;
    tooltip?: string;
}
export default function EventCardWithPopover({
    event,
    activity,
    tooltip,
}: OwnProps) {
    const { classes } = useStyles();

    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
    const open = Boolean(anchorEl);
    const id = open ? 'upcoming-events-popover' : undefined;

    const handlePopoverOpen = (event: MouseEvent<HTMLDivElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const { avatarUrl } = useAvatar(
        event?.scheduledBy?.avatar,
        event?.scheduledBy?.fileType,
    );
    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <div aria-describedby={id} onClick={handlePopoverOpen}>
                <PromptCard {...activity} tooltip={tooltip} />
            </div>
            <BasePopover
                open={open}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}>
                <Card className={classes.root}>
                    <CardHeader
                        title={
                            <Typography variant={'font14'}>
                                {event?.name}
                            </Typography>
                        }
                        action={
                            <IconButton
                                onClick={handlePopoverClose}
                                size="small">
                                <CloseIcon className={classes.closeButton} />
                            </IconButton>
                        }
                        className={classes.header}
                    />
                    <CardContent className={classes.content}>
                        <Box
                            display={'flex'}
                            width={'100%'}
                            justifyContent={'space-between'}>
                            <Typography variant={'font12'}>
                                {event?.dateTime}
                            </Typography>
                        </Box>
                        <div>
                            <Typography variant={'font12'}>
                                <Typography
                                    variant={'font12'}
                                    color={'brandLimeGreen'}
                                    component={'span'}>
                                    Description:{' '}
                                </Typography>
                                {event?.description}
                            </Typography>
                        </div>
                        {event?.scheduledBy && (
                            <Box
                                display={'flex'}
                                columnGap={1}
                                alignItems={'center'}>
                                <Typography
                                    variant={'font12'}
                                    color={'brandLimeGreen'}
                                    component={'span'}>
                                    Scheduled by:{' '}
                                </Typography>
                                <UserAvatar
                                    size={'small'}
                                    name={event?.scheduledBy.name}
                                    avatar={avatarUrl}
                                />
                                <Typography
                                    variant={'font12'}
                                    component={'span'}>
                                    {event?.scheduledBy.name}
                                </Typography>
                            </Box>
                        )}
                    </CardContent>
                </Card>
            </BasePopover>
        </>
    );
}
const useStyles = makeStyles()(() => ({
    root: {
        width: 320,
        backgroundColor: theme.palette.neutral.neutral8,
        color: theme.palette.neutralWhite,
    },
    header: {
        color: theme.palette.neutralWhite,
        backgroundColor: theme.palette.neutral.neutral8,
        display: 'flex',

        justifyContent: 'space-between',
    },
    content: {
        color: theme.palette.neutralWhite,
        backgroundColor: theme.palette.neutral.neutral8,
    },
    closeButton: {
        padding: 0,
        cursor: 'pointer',
        border: 'none',
        fill: theme.palette.neutralWhite,
        '& svg': {
            width: 24,
            height: 24,
        },
    },
}));
