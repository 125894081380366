import { ReactComponent as CommentIcon } from '../../../assets/icons/message_icon.svg';
import { ReactComponent as MoreIcon } from '../../../assets/icons/more_icon.svg';
import { Box, Collapse, IconButton, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import clsx from 'clsx';
import React, { ReactNode, useState } from 'react';

interface OwnProps {
    numberOfComments: number;
    isExpanded: boolean;
    children: ReactNode;
}
export default function CollapsedComments({
    numberOfComments,
    isExpanded,
    children,
}: OwnProps) {
    const { classes } = useStyles();
    const [expanded, setExpanded] = useState(isExpanded || false);
    function handleCollapse() {
        setExpanded(!expanded);
    }
    return (
        <Box display={'flex'} flexDirection={'column'} onClick={handleCollapse}>
            <Box
                width={'100%'}
                display={'flex'}
                alignItems={'center'}
                columnGap={1}>
                <CommentIcon />
                <Typography variant="font14" color={'text.secondary'}>
                    {numberOfComments} Comments
                </Typography>
                <IconButton onClick={handleCollapse}>
                    <MoreIcon
                        className={clsx(isExpanded && classes.expanded)}
                    />
                </IconButton>
            </Box>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                {children}
            </Collapse>
        </Box>
    );
}
const useStyles = makeStyles()(() => ({
    expanded: {
        width: 24,
        height: 24,
        transform: 'rotate(180deg)',
    },
}));
