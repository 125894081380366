import { useState, useEffect } from 'react';
import { useGetAccountQuery } from '../../api/users/accountAPI';
import { useLazyGetOneLeaseQuery } from '../../api/leases/leaseAPI';
import { FSA } from './LeaseDetailForm';
import { validateFsaStep } from './utils';
import { useLocation, useParams } from 'react-router-dom';
import { Expenses } from './ExpensesDetailForm';

export const useLeaseWizard = (
    pageNumber: number,
    setIsNavigable: (isNavigable: boolean) => void,
) => {
    const { state } = useLocation();
    const [fsaData, setFsaData] = useState<FSA[] | []>([]);
    const [expensesData, setExpensesData] = useState<Expenses[] | []>([]);
    const { data, isLoading, isFetching } = useGetAccountQuery({});
    const { id } = useParams();
    const isEdit = !!id;
    const [
        getItems,
        { isFetching: isFetchingOne, isLoading: isLoadingOne, data: initData },
    ] = useLazyGetOneLeaseQuery();

    useEffect(() => {
        isEdit && setIsNavigable(validateFsaStep(pageNumber, fsaData));
    }, [fsaData, pageNumber, setIsNavigable]);

    useEffect(() => {
        id && getItems(id);
    }, [id]);

    useEffect(() => {
        if (initData) {
            const fsaIds = initData.fsaIds.map((item: FSA) => {
                return {
                    ...item,
                    tableId: item.id,
                };
            });
            setFsaData(fsaIds);
        }
    }, [initData]);

    return {
        state,
        fsaData,
        setFsaData,
        expensesData,
        setExpensesData,
        data,
        isLoading,
        isFetching,
        isEdit,
        isFetchingOne,
        isLoadingOne,
        initData,
    };
};
