import { OAKENApi } from '../OAKENApi';
import { ENDPOINTS } from '../endpoints';
import { HTTP_METHOD } from '../auth/http';
import { getListProvidesTags } from '../generic';
import { CONTACT_NOTES, CONTACTS } from '../tags';
import { Note } from '../../reusable/AddNotesModal';

export interface ArchivedContactNote {
    id: string;
    content: string;
    fromUser: {
        id: string;
        firstName: string;
        lastName: string;
    };
    communicationContainer?: {
        communicationType: string;
        actualContactDate: string;
    };
    updatedAt: string;
    createdAt: string;
    archivedBy: {
        id: string;
        firstName: string;
        lastName: string;
    };
}

export const contactNotesAPI = OAKENApi.injectEndpoints({
    endpoints: (builder) => ({
        getAllNotes: builder.query({
            query: (params) => {
                return {
                    url:
                        process.env.REACT_APP_USER_API_HOST +
                        ENDPOINTS.contacts.notes.root,
                    method: HTTP_METHOD.GET,
                    params,
                };
            },
            transformResponse: (
                data: Note[],
                meta,
            ): { data: Note[]; totalCount: number } => {
                const xTotalCount =
                    meta?.response?.headers.get('X-Total-Count');
                return { data, totalCount: xTotalCount ? +xTotalCount : 0 };
            },
            providesTags: (result) =>
                getListProvidesTags(result?.data, CONTACT_NOTES.CONTACT_NOTES),
        }),
        addNote: builder.mutation({
            query: (payload) => ({
                url:
                    process.env.REACT_APP_USER_API_HOST +
                    ENDPOINTS.contacts.notes.root,
                method: HTTP_METHOD.POST,
                body: payload,
            }),
            invalidatesTags: [CONTACT_NOTES.CONTACT_NOTES, CONTACTS.CONTACTS],
        }),
        updateNote: builder.mutation({
            query: (payload) => {
                return {
                    url:
                        process.env.REACT_APP_USER_API_HOST +
                        ENDPOINTS.contacts.notes.root +
                        `/${payload.id}`,
                    method: HTTP_METHOD.PUT,
                    body: payload,
                };
            },
            invalidatesTags: [CONTACT_NOTES.CONTACT_NOTES],
        }),
        addNoteAttachment: builder.mutation({
            query: (payload) => {
                const formData = new FormData();
                if (payload.attachment) {
                    formData.append('attachment', payload.attachment);
                }
                return {
                    url:
                        process.env.REACT_APP_USER_API_HOST +
                        ENDPOINTS.contacts.notes.attachments +
                        `/${payload.id}`,
                    method: HTTP_METHOD.POST,
                    body: formData,
                };
            },
            invalidatesTags: [CONTACT_NOTES.CONTACT_NOTES, CONTACTS.CONTACTS],
        }),
        pinContactNote: builder.mutation({
            query: ({ id }) => {
                return {
                    url:
                        process.env.REACT_APP_USER_API_HOST +
                        ENDPOINTS.contacts.notes.pin +
                        `/${id}`,
                    method: HTTP_METHOD.POST,
                };
            },
            invalidatesTags: [CONTACT_NOTES.CONTACT_NOTES],
        }),
        unpinContactNote: builder.mutation({
            query: ({ id }) => {
                return {
                    url:
                        process.env.REACT_APP_USER_API_HOST +
                        ENDPOINTS.contacts.notes.unpin +
                        `/${id}`,
                    method: HTTP_METHOD.POST,
                };
            },
            invalidatesTags: [CONTACT_NOTES.CONTACT_NOTES],
        }),
        archiveContactNote: builder.mutation({
            query: (id) => {
                return {
                    url:
                        process.env.REACT_APP_USER_API_HOST +
                        ENDPOINTS.contacts.notes.archive +
                        `/${id}`,
                    method: HTTP_METHOD.POST,
                };
            },
            invalidatesTags: [CONTACT_NOTES.CONTACT_NOTES, CONTACTS.CONTACTS],
        }),
        getArchivedContactNotes: builder.query({
            query: (params) => {
                return {
                    url:
                        process.env.REACT_APP_USER_API_HOST +
                        ENDPOINTS.contacts.notes.archive,
                    method: HTTP_METHOD.GET,
                    params,
                };
            },
            transformResponse: (
                data: ArchivedContactNote[],
                meta,
            ): { data: ArchivedContactNote[]; totalCount: number } => {
                const xTotalCount =
                    meta?.response?.headers.get('X-Total-Count');
                return { data, totalCount: xTotalCount ? +xTotalCount : 0 };
            },
            providesTags: (result) =>
                getListProvidesTags(result?.data, CONTACT_NOTES.CONTACT_NOTES),
        }),
        restoreContactNote: builder.mutation({
            query: (id) => {
                return {
                    url:
                        process.env.REACT_APP_USER_API_HOST +
                        ENDPOINTS.contacts.notes.restore +
                        `/${id}`,
                    method: HTTP_METHOD.POST,
                };
            },
            invalidatesTags: [CONTACT_NOTES.CONTACT_NOTES, CONTACTS.CONTACTS],
        }),
        deleteContactNote: builder.mutation({
            query: (id) => {
                return {
                    url:
                        process.env.REACT_APP_USER_API_HOST +
                        ENDPOINTS.contacts.notes.root +
                        `/${id}`,
                    method: HTTP_METHOD.DELETE,
                };
            },
            invalidatesTags: [CONTACT_NOTES.CONTACT_NOTES],
        }),
    }),
});

export const {
    usePinContactNoteMutation,
    useUnpinContactNoteMutation,
    useArchiveContactNoteMutation,
    useGetAllNotesQuery,
    useLazyGetAllNotesQuery,
    useAddNoteMutation,
    useUpdateNoteMutation,
    useAddNoteAttachmentMutation,
    useGetArchivedContactNotesQuery,
    useRestoreContactNoteMutation,
    useDeleteContactNoteMutation,
} = contactNotesAPI;
