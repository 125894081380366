import React from 'react';
import { Box, Button } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

interface OwnProps {
    view: string;
    handleView: (newView: string) => void;
}
export default function ToggleLeaseTableButtons({
    view,
    handleView,
}: OwnProps) {
    const { classes } = useStyles();
    return (
        <Box display={'flex'}>
            <Button
                className={classes.buttonLeft}
                disableRipple
                variant={view === 'active_leases' ? 'contained' : 'outlined'}
                onClick={() => handleView('active_leases')}>
                Active Leases
            </Button>
            <Button
                className={classes.centerLeft}
                disableRipple
                variant={view === 'expired_leases' ? 'contained' : 'outlined'}
                onClick={() => handleView('expired_leases')}>
                Expired Leases
            </Button>
            <Button
                className={classes.buttonRight}
                disableRipple
                variant={view === 'draft' ? 'contained' : 'outlined'}
                onClick={() => handleView('draft')}>
                Draft Leases
            </Button>
        </Box>
    );
}

const useStyles = makeStyles()(() => ({
    buttonLeft: {
        transition: 'all 0.2s 0.1s ease',
        borderRadius: '8px 0px 0px 8px',
    },
    centerLeft: {
        transition: 'all 0.2s 0.1s ease',
        borderRadius: '0px 0px 0px 0px',
    },
    buttonRight: {
        transition: 'all 0.2s 0.1s ease',
        borderRadius: '0px 8px 8px 0px',
    },
}));
