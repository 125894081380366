import useInfiniteScroll from 'react-infinite-scroll-hook';
import React, { useState, useCallback, JSX } from 'react';

interface OwnProps<T> {
    apiHook: //eslint-disable-next-line @typescript-eslint/no-explicit-any
    any;
    options: Record<string, string | number>;
    renderItem: (item: T) => JSX.Element;
}

export function useInfiniteScroller<T>({
    apiHook,
    options,
    renderItem,
}: OwnProps<T>) {
    const [getItems, { data: scrollData, isLoading }] = apiHook(options);
    const [pages, setPages] = useState(0);
    const [hasNextPage, setHasNextPage] = useState(true);
    const [infinityData, setInfinityData] = useState<T[] | []>([]);
    const { data, totalCount } = scrollData || { data: [], totalCount: 0 };

    const fetchMore = useCallback(() => {
        getItems({
            ...options,
            page: pages,
            size: options.size,
        }).then(
            (res: {
                data: {
                    data: T[];
                    totalCount: number;
                };
            }) => {
                if (res) {
                    setInfinityData(res.data.data);
                }
                if (pages > 0 && data.length === totalCount) {
                    setHasNextPage(false);
                }
                setPages(pages + 1);
            },
        );
    }, [data.length, getItems, options, pages, totalCount]);

    const [sentryRef, { rootRef }] = useInfiniteScroll({
        loading: isLoading,
        delayInMs: 100,
        hasNextPage,
        onLoadMore: fetchMore,
    });
    function InfiniteItems() {
        return (
            <>
                {infinityData.map(renderItem)}
                <div>{isLoading && <h4>Loading...</h4>}</div>
                <div ref={sentryRef}>
                    {!hasNextPage && <h4>No more data for showing</h4>}
                </div>
            </>
        );
    }

    return {
        InfiniteItems,
        rootRef,
        infinityData,
        setInfinityData,
    };
}
