import { HTTP_METHOD } from '../auth/http';
import { ENDPOINTS } from '../endpoints';
import { OAKENApi } from '../OAKENApi';
import { CALENDAR } from '../tags';

export const calendarAPI = OAKENApi.injectEndpoints({
    endpoints: (builder) => ({
        getCalendar: builder.query({
            query: ({ period }) => {
                return {
                    url:
                        process.env.REACT_APP_NOTIFICATION_API_HOST +
                        ENDPOINTS.calendar.root +
                        `/${period}`,
                    method: HTTP_METHOD.GET,
                };
            },
            providesTags: () => [{ type: CALENDAR.CALENDAR }],
        }),
    }),
});
export const { useGetCalendarQuery, useLazyGetCalendarQuery } = calendarAPI;
