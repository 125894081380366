import React from 'react';
import { Box } from '@mui/material';
import { SocialMedia } from '../features/ViewLandownersDetails/LandPartnerDetails';
import { ReactComponent as LinkedInIcon } from '../assets/icons/linkedin_icon.svg';
import { ReactComponent as TwitterIcon } from '../assets/icons/twitter_icon.svg';
import { ReactComponent as FacebookIcon } from '../assets/icons/facebook_icon.svg';
import { ReactComponent as InstagramIcon } from '../assets/icons/instagram_icon.svg';
import { NavLink } from 'react-router-dom';

interface OwnProps {
    socialMedia?: SocialMedia;
}

export default function SocialMediaList({ socialMedia }: OwnProps) {
    return (
        <Box display={'flex'} gap={2}>
            {socialMedia?.linkedin && (
                <NavLink
                    to={socialMedia?.linkedin}
                    target={'_blank'}
                    rel="noopener noreferrer">
                    <LinkedInIcon />
                </NavLink>
            )}
            {socialMedia?.twitter && (
                <NavLink
                    to={socialMedia?.twitter}
                    target={'_blank'}
                    rel="noopener noreferrer">
                    <TwitterIcon />
                </NavLink>
            )}
            {socialMedia?.facebook && (
                <NavLink
                    to={socialMedia?.facebook}
                    target={'_blank'}
                    rel="noopener noreferrer">
                    <FacebookIcon />
                </NavLink>
            )}
            {socialMedia?.instagram && (
                <NavLink
                    to={socialMedia?.instagram}
                    target={'_blank'}
                    rel="noopener noreferrer">
                    <InstagramIcon />
                </NavLink>
            )}
        </Box>
    );
}
