import { Box, Grid, Typography } from '@mui/material';
import { FormConfig } from '../../design/Forms/interfaces';
import { composeValidators, requiredWithMessage } from '../../utils/validation';

import { Option } from './WizardPageConfigs/landPartnersConfig';
import FormPage from '../../reusable/FormUI/FormPage';
import { useForm, useFormState } from 'react-final-form';
import React, { useEffect, useState } from 'react';

import RadiobuttonRow from '../../design/Fields/RadiobuttonRow';
import { useIsMount } from '../../hooks/useIsMount';
import AgentCard from './AgentCard';

interface OwnProps {
    landPartners: Option[];
}
export default function LeaseAgentForm({ landPartners }: OwnProps) {
    const isMount = useIsMount();
    const [isAgent, setIsAgent] = useState<string>('false');
    const { values } = useFormState();
    const form = useForm();
    useEffect(() => {
        if (values?.agent) {
            setIsAgent('true');
        }
    }, [values?.agent]);

    useEffect(() => {
        if (isMount) return;
        if (isAgent === 'false') {
            form.change('agent', undefined);
        }
    }, [isAgent]);

    return (
        <Box display={'flex'} flexDirection={'column'} rowGap={1}>
            <Typography variant={'font14'} fontWeight={'medium'}>
                Is there an agent for this Lease?
            </Typography>
            <Grid container item>
                <Grid item xs={6} alignItems={'center'} pt={2}>
                    <RadiobuttonRow
                        size={'third'}
                        value={isAgent}
                        onChange={(value) => setIsAgent(value)}
                        options={yesNoOptions}
                    />
                </Grid>
                <Grid item xs={6} alignItems={'center'}>
                    {isAgent === 'true' && (
                        <FormPage
                            formConfig={leaseAgentFormConfig({
                                landPartners,
                            })}
                        />
                    )}
                </Grid>
            </Grid>
            {values.agent && (
                <AgentCard id={values.agent?.id || values.agent} />
            )}
        </Box>
    );
}
interface Config {
    landPartners: Option[];
}
const yesNoOptions = [
    {
        id: true,
        name: 'Yes',
    },
    {
        id: false,
        name: 'No',
    },
];
function leaseAgentFormConfig({ landPartners }: Config): FormConfig[] {
    return [
        {
            formField: {
                scheme: 'full',
                name: 'agent',
                type: 'searchAutocomplete',
                label: 'Search Contacts for Agent',
                isRequired: true,
                validation: composeValidators(
                    requiredWithMessage(
                        'A name is required in order to proceed',
                    ),
                ),
            },
            renderProps: {
                freeSolo: false,
                isDisabled: false,
                size: 'small',
                options: landPartners,
            },
        },
    ];
}
