import React from 'react';
import {
    DateCalendar,
    DayCalendarSkeleton,
    PickersDay,
    PickersDayProps,
} from '@mui/x-date-pickers';

import theme from '../../styles/theme';

interface OwnProps {
    onChange: (value: Date) => void;
    handleDate: (value: Date) => void;
    value: Date;
    highlightedDays: number[];
    isLoading?: boolean;
}
export default function TaskCalendar({
    onChange,
    value,
    highlightedDays,
    handleDate,
    isLoading,
}: OwnProps) {
    function ServerDay(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        props: PickersDayProps<any> & { highlightedDays?: number[] },
    ) {
        const { highlightedDays = [], day, ...other } = props;
        const isSelected = highlightedDays.includes(day.getDate());

        return (
            <PickersDay
                sx={{
                    ...(isSelected && {
                        backgroundColor: theme.palette.neutral.neutral2,
                        color: theme.palette.neutral.neutral10,
                        '&:hover': {
                            backgroundColor: theme.palette.neutral.neutral6,
                        },
                    }),
                }}
                {...other}
                day={day}
            />
        );
    }

    return (
        <DateCalendar
            loading={isLoading}
            onMonthChange={handleDate}
            sx={{
                margin: 0,
                width: '100%',
            }}
            fixedWeekNumber={6}
            views={['year', 'month', 'day']}
            value={value}
            renderLoading={() => <DayCalendarSkeleton />}
            slots={{
                day: ServerDay,
            }}
            slotProps={{
                day: {
                    highlightedDays,
                } as never,
            }}
            onChange={onChange}
        />
    );
}
