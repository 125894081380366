import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { Tooltip, Box } from '@mui/material';

interface OwnProps {
    children: ReactNode;
    text?: string;
}

export default function BasedTooltip({ text, children }: OwnProps) {
    const textRef = useRef<HTMLDivElement>(null);
    const [isTruncated, setIsTruncated] = useState(false);

    useEffect(() => {
        const node = textRef.current;
        if (node) {
            setIsTruncated(node.offsetWidth < node.scrollWidth);
        }
    }, [children]);

    return (
        <Tooltip
            title={typeof children === 'string' ? children : text || ''}
            arrow
            disableHoverListener={!isTruncated}>
            <Box
                ref={textRef}
                sx={{
                    display: 'block',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                }}>
                {children}
            </Box>
        </Tooltip>
    );
}
